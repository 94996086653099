import React, { useState, useEffect } from "react";
import APIService from "../services/APIService";
import { Card, Modal, Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { connect, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

let windowWidth = window.innerWidth,
  ChatSetting = (props) => {
    let [chatSettings, setChatSettings] = React.useState([]),
      [updateData, setUpdateData] = useState(null),
      [show, setShow] = useState(false),
      handleClose = () => setShow(false),
      handleShow = (rowData) => {
        setUpdateData(rowData);
        setShow(true);
      },
      chatSettingType = {
        51: "INDIVIDUAL",
        52: "GROUP",
        53: "BOTH",
      },
      getSettings = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/chat-settings")
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setChatSettings(
                response.data.data.map((setting) => {
                  setting.name = setting.name.split("_").join(" ");
                  setting.name =
                    setting.name.slice(0, 1) +
                    "" +
                    setting.name.toLowerCase().slice(1, setting.name.length);
                  return setting;
                })
              );
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      updateSetting = (formValues) => {
        let details = { value: formValues.value },
          setting = updateData.name.split(" ").join("_").toUpperCase();
        APIService.patch("admin/setting/" + setting, details).then(
          (response) => {
            if (response.data && response.data.statusCode === 200) {
              toast.success("Setting updated successfully");
              handleClose();
              getSettings();
            }
          }
        );
      },
      actionTemplate = (rowData) => {
        return (
          <div className="button p-table-button">
            <PrimeButton
              tooltip="Edit"
              tooltipOptions={{ position: "bottom" }}
              onClick={() => handleShow(rowData)}
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                  fill="#03053D"
                />
              </svg>
            </PrimeButton>
          </div>
        );
      };

    useEffect(() => {
      getSettings();
    }, []);

    return (
      <>
        <div className="rider-listing">
          <span className="title">Chat Settings</span>
          <Card className="mt-3">
            <DataTable
              value={chatSettings}
              scrollable
              scrollHeight="70vh"
              loading={useSelector((state) => state.isGridLoader)}
              loadingIcon="fa fa-spinner"
            >
              <Column
                style={{
                  width: windowWidth < 768 ? "200px" : "",
                }}
                field="name"
                header="Title"
              ></Column>
              <Column
                field="description"
                header="Description"
                style={{ width: windowWidth < 768 ? "350px" : "" }}
              ></Column>
              <Column
                field="subCategory"
                header="Type"
                body={(setting) => chatSettingType[setting.subCategory]}
                style={{ width: windowWidth < 768 ? "350px" : "" }}
              ></Column>
              <Column
                className="text-center"
                style={{
                  width: windowWidth < 768 ? "150px" : "",
                }}
                field="value"
                header="Value"
              ></Column>
              <Column
                className="text-center"
                style={{ width: "100px" }}
                header="Action"
                headerClassName="text-center"
                body={actionTemplate}
                filterPlaceholder="Minimum"
              />
            </DataTable>
          </Card>
        </div>

        <Modal show={show} size="" onHide={handleClose} centered>
          <Formik
            initialValues={{
              ...updateData,
            }}
            validationSchema={Yup.object().shape({
              value: Yup.string()
                .trim("Please remove whitespaces")
                .strict(false)
                .required("Please enter value")
                .nullable(),
            })}
            onSubmit={updateSetting}
          >
            {() => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      {" "}
                      Update Chat Setting
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="shadow-none form-control modal-input br-10"
                        disabled
                      />
                    </div>
                    <div className="form-group form-label-group">
                      <Field
                        type="textarea"
                        id="description"
                        name="description"
                        className="shadow-none form-control modal-input br-10"
                        disabled
                      />
                    </div>

                    <div className="form-group form-label-group">
                      <Field
                        type="text"
                        id="value"
                        name="value"
                        className="shadow-none form-control modal-input br-10"
                      />
                      <ErrorMessage
                        name="value"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      className="btn-save save-button mb-3 border-radius-15"
                      type="submit"
                    >
                      Update
                    </Button>

                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </>
    );
  };

export default React.memo(connect()(ChatSetting));
