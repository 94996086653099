import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import APIService from "../../services/APIService";
import moment from "moment";
import DeleteModal from "../Common/DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

let Roles = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [globalFilter, setGlobalFilter] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/admin/roles/')),
    dt = useRef(null),
    [roles, setRoles] = useState([]),
    [role, setRole] = useState({}),
    getRoles = () => {
      APIService.get("admin/role").then((response) => {
        if (response.status === 200) {
          let roles = response.data.data.map((role) => {
            role.status = role.status ? "Active" : "Inactive";
            return role;
          });
          setRoles(roles);
        }
      });
    },
    removeRole = () => {
      APIService.delete("admin/role/" + role.id)
        .then((response) => {
          if (response.status === 200) {
            getRoles();
            toast.success("Role deleted successfully");
            setRole({});
          }
        })
        .catch(() => {
          setRole({});
          // toast.success("Failed to deleted role");
        });
    };

  useEffect(() => {
    getRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let statuses = ["Active", "Inactive"],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
          <i className="pi pi-search " style={{marginTop:'-10px'}} />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <Link to="/role" className="btn btn-primary float-left d-flex align-items-center">
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </Link>}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
    },
    actionTemplate = (role) => {
      return (
        <div className="button p-table-button">
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && <Link
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            className="p-button p-component"
            to={"/role/" + role.id}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Link>}
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => setRole(role)}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#03053D"
              />
            </svg>
          </Button>}
          {!currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && !currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <span>No permission</span>}
        </div>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={roles}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          paginator
          rows={25}
          emptyMessage="No Role(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="Role Name"
            field="title"
            // body={(role) =>
            //   // <Link to={"role/" + role.id}>
            //     {role.title}
            //   // </Link>
            // }
            sortable
            filter={showFilter}
            filterMatchMode="contains"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statuses}
                onChange={(e) => {
                  dt.current.filter(e.value, "status", "equals");
                  setSelectedStatus(e.value);
                }}
                placeholder="Status"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Created Date"
            field="createdAt"
            sortable
            body={(role) => {
              return (
                <React.Fragment>
                  <span className="dataFnt-size">
                    <span className="p-column-title">Created Date</span>
                    {moment(role.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </span>
                </React.Fragment>
              );
            }}
            filter={false}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          />
        </DataTable>
      </div>
      <DeleteModal
        showDelete={Boolean(role.id)}
        clickYes={removeRole}
        handleCloseDelete={() => setRole({})}
      />
    </div>
  );
};

export default React.memo(withRouter(Roles));
