import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";

let CancelTrip = (props) => {

  let cancelTrip = (formFields) => {
    let payload = props.cancelTrip.byDriver ? {
      declinedReason: formFields.declinedReason,
      driverId: props.cancelTrip.driverID
    }
      : {
        declinedReason: formFields.declinedReason,
        riderId: props.cancelTrip.riderID,
        dropAddress: {
          latitude: props.cancelTrip.addressDetail.latitude,
          longitude: props.cancelTrip.addressDetail.longitude
        }
      }
    let url = props?.cancelTrip?.byDriver ? 'cancel-driver-trip' : 'cancel-rider-trip'
    APIService.patch(`admin/${url}/` + props.cancelTrip?.id, payload)
      .then((response) => {
        props.manageTrip(null);
        props.setShow(false);
        if (response.status === 200) {
          props.refreshTrips();
          toast.success(response.data.data.message);
        }
      })
      .catch((error) => props.manageTrip(null));
  },
    validationSchema = Yup.object().shape({
      declinedReason: Yup.string().required("Please enter Cancel Reason"),
    });

  return (
    <>
      <Modal show={true} onHide={() => props.manageTrip(null)} centered>
        <Formik
          initialValues={{ declinedReason: "" }}
          validationSchema={validationSchema}
          onSubmit={cancelTrip}
        >
          {() => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                    Cancelling Ride?
                  </Modal.Title>
                  <div className="divclose" onClick={props.cancelRideHandleClose}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="">
                  <Row>
                    <Col className="px-1">
                      <p className="field-text mt-2">
                        Please select reason of cancelling
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center ">
                    <Col md={12}>
                      <div role="group" aria-labelledby="my-radio-group" className="radio-trip-cancel mb-3">
                        <label>
                          <Field
                            type="radio"
                            name="declinedReason"
                            value="Some personal issue comes up with the driver."

                          />
                          Some personal issue comes up with the driver.
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="declinedReason"
                            value="Distance factor and rush hour."
                          />
                          Distance factor and rush hour.
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="declinedReason"
                            value="The passenger is unreachable or is not ready."

                          />
                          The passenger is unreachable or is not ready.
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="declinedReason"
                            value=" Low Rider Score."
                          />
                          Low Rider Score.
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="declinedReason"
                            value=" Educate your drivers"

                          />
                          Educate your drivers
                        </label>

                      </div>
                    </Col>

                    <Col md={12} className="mt-3 px-0">
                      <Button
                        variant="primary"
                        type="submit"

                        className="btn-save btn-block btn-red "
                      >
                        Cancel Ride
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default React.memo(CancelTrip);
