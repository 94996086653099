import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Line } from "react-chartjs-2";
import droparrow from "./images/droparrow.svg";
import APIService from "../../services/APIService";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { barChartOptions , convertDataBasedOnRequirementForTimeZone } from "./linechartdata/linechartdata";

function Timezone(props) {
  const { cancelledTripData } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [timeZoneGraph,setTimeZoneGraph]=useState({}),
    [statusCounts,setStatusCounts]=useState({}),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;
console.log(props)
  useEffect(()=>{
    setStatusCounts(graphCountList
      ? graphCountList
      : props?.sendgraphTripData?.timeZoneTotalStats)
  },[props?.sendgraphTripData?.timeZoneTotalStats])

  useEffect(() => {
    setTimeRange(props.timeRange);
    // manageTimeRange()
  }, [props.timeRange]);

  let manageTimeRange = () => {

    setTimeRange(props.timeRange);
    APIService.get("admin/dashboard/trip-stats?type=" + props.timeRange)
      .then((response) => {
        if (response.data.statusCode === 200) {
 
          setDateRange(response?.data?.data?.dateRange)

          setGraphTripList(
            response.data && response.data.data && response.data.data.timeZoneGraphList
            );
          setgraphCountList(
            response.data &&
              response.data.data &&
              response.data.data.timeZoneTotalStats

          );
        }
      })
      .catch((error) => {

      });
  };
  useEffect(()=>{
    // setLineGraphData(props?.sendgraphTripData?.graphList)
    let dataGraphList = props?.sendgraphTripData?.timeZoneGraphList
    if(dataGraphList!==undefined){
      const convertedData = convertDataBasedOnRequirementForTimeZone(dataGraphList);
      setTimeZoneGraph(convertedData);
    }
  },[props.sendgraphTripData])

  const [dateRange,setDateRange] =  useState(null);
  useEffect(()=>{
    setDateRange(graphData?.dateRange);
  },[JSON.stringify(graphData)])
  return (
    <div class="tab-pane fade active show" id="All">
    <div className="col-12">
      <h6 className=" graph-label left-text rounded text-capitalize">
        Trips
      </h6>
      {Object.keys(timeZoneGraph).length > 0 && (
      <Line data={timeZoneGraph} options={barChartOptions} />
      )}
      <h6 className=" graph-label-2 left-text rounded text-capitalize">
      {dateRange && `${moment(dateRange?.startDate).format('DD MMM YYYY')} - ${moment(dateRange?.endDate).format('DD MMM YYYY')}`}
      </h6>
    </div>
    {console.log(statusCounts)}

    <div className="col-12 graph-menu g-menu">
      <div className="main-percent line-main">
        <div className="percentage">
          <div className="green"></div>
          <div className="percentext">Morning</div>
          <div className="percenPoint">{statusCounts?.morning
}</div>
        </div>

        <div className="percentage">
          <div className="red"></div>
          <div className="percentext">Afternoon</div>
          <div className="percenPoint">{statusCounts?.afternoon}</div>
        </div>

        <div className="percentage">
          <div
            className="green"
            style={{ background: "#13A5B5" }}
          ></div>
          <div className="percentext">Evening</div>
          <div className="percenPoint">{statusCounts?.evening}</div>
        </div>
        <div className="percentage">
          <div
            className="red"
            style={{ background: "purple" }}
          ></div>
          <div className="percentext">Night</div>
          <div className="percenPoint">{statusCounts?.night}</div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Timezone;
