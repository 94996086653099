import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

let ReadNotifications = (props) => {
  return (
    <>
      <Card>
        <div className="notifications-list">
          <Row className="inactive-notification">
            <Col md="auto">
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20.8911" r="20" fill="#FAFAFA" />
                <g clip-path="url(#clip0)">
                  <path
                    d="M13.333 25.0576H11.6663V26.7243H28.333V25.0576H26.6663V19.2243C26.6663 17.4562 25.964 15.7605 24.7137 14.5102C23.4635 13.26 21.7678 12.5576 19.9997 12.5576C18.2316 12.5576 16.5359 13.26 15.2856 14.5102C14.0354 15.7605 13.333 17.4562 13.333 19.2243V25.0576ZM14.9997 25.0576V19.2243C14.9997 17.8982 15.5265 16.6264 16.4641 15.6888C17.4018 14.7511 18.6736 14.2243 19.9997 14.2243C21.3258 14.2243 22.5975 14.7511 23.5352 15.6888C24.4729 16.6264 24.9997 17.8982 24.9997 19.2243V25.0576H14.9997ZM22.4997 28.391H17.4997V30.0576H22.4997V28.391Z"
                    fill="#969BAB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="matrix(-1 0 0 1 30 10.8911)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Col>
            <Col>
              <Row className="d-inline-block">
                <span className="title-notification">Title here</span>
                <span className="time pl-0">Today 12:30PM</span>
              </Row>
              <Row>
                <div className="message">{props.message}</div>
              </Row>
            </Col>
            <Col xl="3" md="4" className="text-right">
              <svg
                className="mt-1 mr-2 delete-icon"
                width="20"
                height="20"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M17 6.9458H22V8.9458H20V21.9458C20 22.211 19.8946 22.4654 19.7071 22.6529C19.5196 22.8404 19.2652 22.9458 19 22.9458H5C4.73478 22.9458 4.48043 22.8404 4.29289 22.6529C4.10536 22.4654 4 22.211 4 21.9458V8.9458H2V6.9458H7V3.9458C7 3.68058 7.10536 3.42623 7.29289 3.23869C7.48043 3.05116 7.73478 2.9458 8 2.9458H16C16.2652 2.9458 16.5196 3.05116 16.7071 3.23869C16.8946 3.42623 17 3.68058 17 3.9458V6.9458ZM18 8.9458H6V20.9458H18V8.9458ZM9 4.9458V6.9458H15V4.9458H9Z"
                    fill="#03053D"
                  />
                </g>
              </svg>

              <Form className="d-inline-block">
                <Form.Group controlId="formBasicCheckbox" className="mb-0">
                  <Form.Check type="checkbox" className="margin-l-5" label="" />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default React.memo(ReadNotifications);
