import React, { useState, useEffect } from "react";
import cardIcon from "../Dashboard_2/images/cardIcon.svg"
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import APIService from "../../services/APIService";
function CustomerCare() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [riderTickets, setRiderTickets] = useState(null);
 const currentDate =  moment().format('YYYY-MM-DD');

  const [driverTickets, setDriverTickets] = useState(null);
  const [pickupDataset,setPickupDataset] =useState( {});
  
 
  const  windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [10],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };
//   const secret_key = "xBJbW0S1OIFJl9bsjsdZ";
//   const _getAllTicketsURL =
//   `https://cmsride.xintdev.com/api/v1/ticket/get-status-wise-tickets?secret_key=xBJbW0S1OIFJl9bsjsdZ&from_date=2022-10-29&to_date=${currentDate}&type=1`
   
//   let _getAllTickets = async () => {
//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "X-Requested-With": "XMLHttpRequest",
//         "Access-Control-Allow-Origin": "*",
//       },
//       // body: JSON.stringify({ secret_key: secret_key }),
//     };
//     try{
//     const response = await fetch(_getAllTicketsURL, requestOptions);
//     let final_data = await response.json();
//     setRiderTickets(final_data.data);
//     console.log(final_data.data)
//     }catch(error){
//       console.log(error)
//     }
 
//   };
//   const _getAllTicketsDriverURL =
//     `https://cmsride.xintdev.com/api/v1/ticket/get-status-wise-tickets?secret_key=xBJbW0S1OIFJl9bsjsdZ&from_date=2022-10-29&to_date=${currentDate}&type=2`;
//   let _getAllTicketsDriver = async () => {
//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "X-Requested-With": "XMLHttpRequest",
//         "Access-Control-Allow-Origin": "*",
//       },
   
//     };
//     try{
//     const response = await fetch(_getAllTicketsDriverURL, requestOptions);
//     let final_data = await response.json();
//     setDriverTickets(final_data.data);
//     }catch(error){
//     console.log(error)
//   }

//   };
  

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Tickets",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  useEffect(() => {

    setPickupDataset( {
      datasets: [
        {
          data: [10,102,13],
          backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          borderWidth: 0,
        },
      ],
    })
    
  }, []);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 class="box-title text-wrap">Customer Care Tickets</h2>
          <div>
            <div className="dropdown">
              <img src={cardIcon} alt="Celender icon" />
            </div>
          </div>
        </div>
    
            {" "}
          
              <Doughnut
                type="doughnut"
                data={pickupDataset?pickupDataset:emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
              
            
            <div className="info-section">
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main">
                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="green"></div>
                        <div className="percentext flex-grow-1">Open</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        40%
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="red"></div>
                        <div className="percentext flex-grow-1">Closed</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        05%
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div
                          className="green"
                          style={{ background: "#FFB065" }}
                        ></div>
                        <div className="percentext flex-grow-1">Progress</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        40%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
      </div>
    </>
  );
}

export default CustomerCare;
