import React from "react";
import EmergencyRequestListing from "../components/Emergency/EmergencyRequestListing";

const EmergencyRequestPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Emergency Request</span>
        <EmergencyRequestListing />
      </div>
    </>
  );
};

export default React.memo(EmergencyRequestPage);
