import React from 'react'
import removeIcon from '../../assest/images/remove.png'
const Failure = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'aliceblue',
      height: '100vh',
      padding: '20px'
    }}>
      <img src={removeIcon} width='150px' height='150px' />
      <div style={{
        padding: '20px',
        textAlign: 'center'
      }}>
        <h3 style={{ color: 'red' }}>Failure</h3>
        <p style={{ color: '#888888' }}>Unfortunately Payment was rejected</p>
      </div>
    </div>
  )
}

export default Failure
