import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import APIService from "../../services/APIService";
import saveAs from "save-as";
import moment from "moment";

let ReportsVehicleRegistrationsListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [vehicles, setVehicles] = useState([]),
    [createdAt, setCreatedAt] = useState([]),
    [selectedVehicle, setSelectedVehicle] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "modelYear",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getVehicleRegistrations = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/car-info/all", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setVehicles(response.data.data.vehicles);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageVehicles = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getVehicleRegistrations();
    },
    exportVehicles = () => {
      let vehicles = [
        "Driver ID",
        "Owner Name",
        "Colour",
        "Vehicle Maker",
        "Vehicle Model",
        "Model Year",
        "Reg Place",
        "Vehicle Capacity",
        "Chassis Number",
        "Cylinder",
        "License Expiry Date",
        "Plate Number",
        "Plate Text 1",
        "Plate Text 2",
        "Plate Text 3",
        "Plate Type Code",
        "Vehicle Class",
        "Log Id",
      ];
      vehicles = vehicles.join(",") + "\r\n";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      if (selectedVehicle.length) {
        selectedVehicle.forEach((vehicle) => {
          vehicles +=
            [
              vehicle.driverNationalId,
              vehicle.ownerName,
              vehicle.majorColor,
              vehicle.vehicleMaker,
              vehicle.vehicleModel,
              vehicle.modelYear,
              vehicle.regplace,
              vehicle.vehicleCapacity,
              vehicle.chassisNumber,
              vehicle.cylinders,
              moment(vehicle.licenseExpiryDate).format("DD/MM/YYYY hh:mm A"),
              vehicle.plateNumber,
              vehicle.plateText1,
              vehicle.plateText2,
              vehicle.plateText3,
              vehicle.plateTypeCode,
              vehicle.lkVehicleClass,
              vehicle.logId,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([vehicles], {
            type: "text/csv;charset=utf-8",
          }),
          "Vehicle-Registrations-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.take = 100000000000;
        filterParams.skip = 0;
        APIService.post("admin/reports/car-info", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });

              response.data.data.vehicles.forEach((vehicle) => {
                vehicles +=
                  [
                    vehicle.driverNationalId,
                    vehicle.ownerName,
                    vehicle.majorColor,
                    vehicle.vehicleMaker,
                    vehicle.vehicleModel,
                    vehicle.modelYear,
                    vehicle.regplace,
                    vehicle.vehicleCapacity,
                    vehicle.chassisNumber,
                    vehicle.cylinders,
                    moment(vehicle.licenseExpiryDate).format(
                      "DD/MM/YYYY hh:mm A"
                    ),
                    vehicle.plateNumber,
                    vehicle.plateText1,
                    vehicle.plateText2,
                    vehicle.plateText3,
                    vehicle.plateTypeCode,
                    vehicle.lkVehicleClass,
                    vehicle.logId,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([vehicles], {
                  type: "text/csv;charset=utf-8",
                }),
                "Vehicle-Registrations-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            console.log("error", error);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getVehicleRegistrations();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span
            className="m-1"
            style={{ maxWidth: "230px", width: "100%" }}
          ></span>
          <span className="m-1">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportVehicles}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setSelectedVehicle([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getVehicleRegistrations();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={vehicles}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Vehicle(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageVehicles}
          onSort={manageVehicles}
          onFilter={manageVehicles}
          selection={selectedVehicle}
          onSelectionChange={(e) => setSelectedVehicle(e.value)}
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Car Sequence"
            field="carSequenceNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by car sequence"
            sortField="carSequenceNo"
            filterField="carSequenceNo"
            body={(vehicle) => (
              <Link to={"/registered-vehicle/" + vehicle.id}>
                {vehicle.carSequenceNo}
              </Link>
            )}
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "120px" }}
            header="Driver Id"
            field="driver.externalId"
            sortField="externalId"
            filterField="externalId"
            filter={showFilter}
            body={(driver) =>
              driver.driver && (
                <Link to={"/driver-details/driver-earning/" + driver.id}>
                  {driver.driver.externalId}
                </Link>
              )
            }
            sortable
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            header="Owner Name"
            field="ownerName"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Name"
            body={(vehicle) =>
              vehicle?.ownerNameEnglish
                ? vehicle.ownerNameEnglish
                : vehicle.ownerName
            }
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "140px" }}
            header="Colour"
            field="majorColor"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Colour"
            body={(vehicle) =>
              vehicle?.majorColorEnglish
                ? vehicle.majorColorEnglish
                : vehicle.majorColor
            }
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Vehicle Maker"
            field="vehicleMaker"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Maker"
            body={(vehicle) =>
              vehicle?.vehicleMakerEnglish
                ? vehicle.vehicleMakerEnglish
                : vehicle.vehicleMaker
            }
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Vehicle Model"
            field="vehicleModel"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Model"
            body={(vehicle) =>
              vehicle?.vehicleModelEnglish
                ? vehicle.vehicleModelEnglish
                : vehicle.vehicleModel
            }
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "130px" }}
            header="Model Year"
            field="modelYear"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Year"
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Reg Place"
            field="regplace"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Place"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "170px" }}
            header="Vehicle Capacity"
            field="vehicleCapacity"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Capacity"
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            header="Chassis Number"
            field="chassisNumber"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Chassis Number"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "120px" }}
            header="Cylinder"
            field="cylinders"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Cylinders"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "190px" }}
            header="License Expiry Date"
            field="licenseExpiryDate"
            sortable
            body={(vehicle) =>
              vehicle.licenseExpiryDateEnglish
                ? moment(vehicle.licenseExpiryDateEnglish).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : ""
            }
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "190px" }}
            header="Plate Number"
            field="plateNumber"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Plate Number"
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "190px" }}
            header="Plate Text 1"
            field="plateText1"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Plate Text 1"
            body={(vehicle) =>
              vehicle?.plateText1English
                ? vehicle.plateText1English
                : vehicle.plateText1English
            }
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "190px" }}
            header="Plate Text 2"
            field="plateText2"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Plate Text 2"
            body={(vehicle) =>
              vehicle?.plateText2English
                ? vehicle.plateText2English
                : vehicle.plateText2English
            }
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "190px" }}
            header="Plate Text 3"
            field="plateText3"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Plate Text 3"
            body={(vehicle) =>
              vehicle?.plateText3English
                ? vehicle.plateText3English
                : vehicle.plateText3English
            }
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "170px" }}
            header="Plate Type Code"
            field="plateTypeCode"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Code"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "170px" }}
            header="Vehicle Class"
            field="lkVehicleClass"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Class"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "140px" }}
            header="Log Id"
            field="logId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Log"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsVehicleRegistrationsListing));
