import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import { connect } from "react-redux";
import calender from "../../assest/images/calender.svg";
import redCard from "../../assest/images/red-card.svg";
import greenCard from "../../assest/images/green-card.png";
import { logDOM } from "@testing-library/react";
import { TabView, TabPanel } from "primereact/tabview";
import PickupEarning from "./AverageEarning";
// import AverageEarning from "./AverageEarning";

let barChartOptions = {
  responsive: true,
  type: "line",
  barPercentage: 5,
  borderRadius: 5,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};
let PickupTopupHistoryChart = (props) => {
  const { riderId } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [summaryList, setSummaryList] = useState([]),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;




  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

  useEffect(() => {
    const data = {
        labels: [23, 24, 25, 26, 27, 28, 29],
        datasets: [
          {
            data: [23, 24, 25, 26, 27, 28, 29],
    
            backgroundColor: "rgba(75,192,192,0.2)",
          },
          {
            label: "Second dataset",
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: "#742774",
          },
          {
            label: "Second dataset",
            data: [12, 21, 25, 51, 34, 26],
            fill: false,
            borderColor: "#742774",
          },
        ],
      };

      
     

      setGraphTripList({
        labels: data.labels,
        datasets: data.datasets,
      });
    },[]);

  let manageTimeRange = (event) => {
    // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setTimeRange(event.target.value);
  
  };  
  

  return (
    <div className="drivers d-box h-100">
      <div className="head d-lg-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
        <div style={{ display: "flex" }}>
          <div className="title">
            <h2 className="box-title">
              Captain Summary &nbsp;
              {/* <span style={{ color: "#333333", fontWeight: 700 }}>
              {totalRiders}
            </span> */}
            </h2>
          </div>
          <div className="selectbox d-flex align-items-left">
            {/* <span className="show-by-title">Show by </span> */}
            <select
              value={timeRange}
              className="select outline-none border-0 "
              onChange={manageTimeRange}
            >
              <option value="day">Today</option>
              <option value="week">Last 7 Days</option>
              <option value="month">By Week</option>
              <option value="year">By Month</option>
            </select>
          </div>
          <div>
            <img src={calender} alt="ride" style={{ marginLeft: "26px" }} />
          </div>
        </div>
      </div>
      <TabView
        className="mt-2"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Earning">
          <div className="mt-24">
            <Line data={graphTripList} options={barChartOptions} />
          </div>
          <div
            className="bg-light"
            style={{
              borderRadius: "6px",
              padding: "16px",
              // marginTop: "30px",
            }}
          >
            <div className=" d-flex justify-content-between flex-wrap">
             
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#13B542" }}
                    ></div>
                    <div class="trips-title-label mr-4">Total Earning</div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    13,568 SAR.
                  </div>
                </div>
             

             
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#F1F1F1" }}
                    ></div>
                    <div class="trips-title-label mr-4">Max Earning </div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    3,568 SAR.
                  </div>
                </div>
             

            
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#F1F1F1" }}
                    ></div>
                    <div class="trips-title-label mr-4">Min Earning</div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    1,568 SAR.
                  </div>
                </div>
            
            </div>
          </div>
        </TabPanel>
        <TabPanel header="avg. Earning per ride">
          <PickupEarning/>
        </TabPanel>
      </TabView>

      {/* <div className="view-report">
          <Link to="/reports-riders">View Report</Link>
        </div> */}
    </div>
  );
};
export default PickupTopupHistoryChart;
