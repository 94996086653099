import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//src
import { getNotificationsDetail } from './action'
import PrimeDataTable from "./PrimeDataTable";

const columns = [
  { field: 'userId', header: 'UserId' },
  { field: 'emailId', header: 'Email' },
  { field: 'mobileNo', header: 'Mobile No' },
  { field: 'status', header: 'Status' },
]

let EmailNotificationListing = (props) => {
  const { id } = props
  let [notificationData, setNotificationData] = useState(null);
  let [notificationStatus, setNotificationStatus] = useState({ success: 0, failure: 0 });
  let permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/notification/push')),
    updatePermission = currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update'),
    getNotifications = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      getNotificationsDetail({
        id, type: 'email', callback: (response) => {
          if (response.data && response.data.statusCode === 200) {
            setNotificationData(response.data?.data?.data)
            setNotificationStatus({
              success: response.data?.data?.success ?? 0,
              failure: response.data?.data?.failure ?? 0,
            })
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
          else {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        }
      })
    }
  useEffect(() => {
    getNotifications();
  }, []);

  return <PrimeDataTable notificationData={notificationData} columns={columns} />
};

export default React.memo(connect()(EmailNotificationListing))
