import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Map from "./Map";
import moment from "moment";
import Rating from "react-rating";
import logo from "../../assest/images/riderLogo.svg";
import CaptainInfo from "./CaptainInfo";
import RiderInfo from "./RiderInfo";
import OtherInfo from "./OtherInfo";
import { Doughnut } from "react-chartjs-2";
import TotalTrips from "./TotalTripsChart";
import EmptyTotalTrips from "./EmptyTripsChart";
import TotalTripsDriver from "./EmptyTripsChartDriver";
import EmptyTotalTripsDriver from "./EmptyTripsChartDriver";
import APIService from "../../services/APIService";
import TotalTripsDriverChart from "./TotalTripDriverChart";
import { toast } from "react-toastify";
let googleMapsApiKey = "AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg";

let RidesDetails = (props) => {
  const [userStatusRider, setUserStatusRider] = useState(null);
  const [userStatusDriver, setUserStatusDriver] = useState(null);
  let [show, setcancelRide] = useState(false);
  let [showCancelTrip,setShowCancelTrip] = useState(false)
  let getStatusTexts = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = <span className="status-text-pending">Pending</span>;
        break;
      case 2:
        statusText = <span className="status-text">Accepted by Driver</span>;
        break;
      case 3:
        statusText = <span className="status-text-inactive">Rejected by Driver</span>;

        break;
      case 4:
        statusText = <span className="status-text-inactive">Cancelled by Driver</span>;
        break;
      case 5:
        statusText = <span className="status-text">Driver Arrived</span>;
        break;
      case 6:
        statusText = <span className="status-text-inactive">Cancelled by Rider</span>;
        break;
      case 7:
        statusText = <span className="status-text-pending">Started</span>;
        break;
      case 8:
        statusText = <span className="status-text">Completed</span>;
        break;
      case 9:
        statusText = <span className="status-text-pending">No Driver</span>;
        break;
      case 10:
        statusText = <span className="status-text-inactive">Expired</span>;
        break;
      case 11:
    statusText = <span className="status-text-inactive">Cancelled by Admin</span>;
    break;
       case 12:
        statusText = <span className="status-text-inactive">Booking Cancelled</span>;
        break;
      case 13:
        statusText = <span className="status-text">Stop and Assigned</span>;
        break;
        case 14:
        statusText = <span className="status-text">Stop and Completed</span>;
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  let cancelRideHandleClose = () => setcancelRide(false),
    cancelRideHandleShow = () => {
      setcancelRide(true);
    };
  let tripViewData = props && props.tripView;
  let statusText = getStatusTexts(tripViewData?.status);
  let tripViewRiderData = props && props.tripView && props.tripView.rider;
  let tripViewDriverData = props && props.tripView && props.tripView.driver;
  let tripID = props && props.tripView.id;
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  if (tripViewDriverData && !tripViewDriverData.ratingCounts) {
    tripViewDriverData.ratingCounts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
  }
  if (tripViewData.addresses) {
    tripViewData.locations = !tripViewData.locations
      ? []
      : tripViewData.locations;
    tripViewData.locations = [
      {
        latitude: tripViewData.addresses[0].latitude,
        longitude: tripViewData.addresses[0].longitude,
      },
      ...tripViewData.locations,
    ];
    tripViewData.locations.push({
      latitude:
        tripViewData.addresses[tripViewData.addresses.length - 1].latitude,
      longitude:
        tripViewData.addresses[tripViewData.addresses.length - 1].longitude,
    });
  }
  let {
      loadingElement,
      containerElement,
      mapElement,
      defaultCenter,
      defaultZoom,
    } = props,
    getStatusText = (value) => {
      let statusText;
      switch (value) {
        case 1:
          statusText = "Pending";
          break;
        case 2:
          statusText = "Accepted by Driver";
          break;
        case 3:
          statusText = "Rejected by Driver";
          break;
        case 4:
          statusText = "Cancelled by Driver";
          break;
        case 5:
          statusText = "Driver Arrived";
          break;
        case 6:
          statusText = "Cancelled by Rider";
          break;
        case 7:
          statusText = "Started";
          break;
        case 8:
          statusText = "Completed";
          break;
        case 9:
          statusText = "No Driver";
          break;
        case 10:
          statusText = "Expired";
          break;
        default:
          statusText = "-";
          break;
      }
      return statusText;
    };
let transactionStatus=(value)=>{
  let statusText;
  switch(value){
    case 1:
      statusText='BLOCKED';
      break;
  case 2:
  statusText='PAID';
  break;
case 3:
statusText='RELEASED';
break;
case 4:
statusText='FAILED';
break;}
return statusText;

}
  let getCarLicenseTypeText = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "Private";
        break;
      case 2:
        statusText = "Public Transport";
        break;
      case 3:
        statusText = "Taxi";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };

  let getDrivingModeText = (modes) => {
    let listOfModesName = [];
    if (modes) {
      modes.forEach((mode) => {
        if (mode.drivingMode === 1) {
          listOfModesName.push("Passenger ride");
        } else {
          listOfModesName.push("Delivery");
        }
      });
    }
    return listOfModesName.length ? listOfModesName.join(", ") : "-";
  };

  const calculateDuration = (time) => {
    if (!time) {
      return "-";
    }
    let decimalValue = time.toString().split(".");
    if (decimalValue.length < 2) {
      return time + " Min";
    }
    let seconds = (parseInt(decimalValue[1]) * 60) / 100;
    return decimalValue[0] + " Min " + seconds.toFixed(0) + " Sec";
  };

  const toFixedWithoutRound = (num, fixed) => {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  };

  const data = {
    datasets: [
      {
        data: [12, 19],
        backgroundColor: ["Green", "#EB3D3D"],
      },
    ],
  };

  let totalTrips = {
    graphList: [
      {
        key: "completed",
        value: tripViewRiderData?.totalRides,
      },
      {
        key: "cancelled",
        value: tripViewRiderData?.ridesCancelled,
      },
    ],
    total: tripViewRiderData?.totalRides + tripViewRiderData?.ridesCancelled,
  };
  let emptyTrips = {
    graphList: [
      {
        key: "completed",
        value: 10,
      },
    ],
    total: 0,
  };

  let totalTripsDriver = {
    graphList: [
      {
        key: "completed",
        value: tripViewDriverData?.totalTrips,
      },
      {
        key: "cancelled",
        value: tripViewDriverData?.tripsCancelled,
      },
      {
        key: "Declined",
        value: tripViewDriverData?.totalTrips,
      },
    ],
    total: tripViewDriverData?.totalTrips + tripViewDriverData?.tripsCancelled,
  };
  let riderOnlineStatus = () => {
    if (tripViewRiderData?.userId) {
      APIService.get(
        "admin/user/user-loc-and-status/" + tripViewRiderData?.userId
      )
        .then((response) => {
          var result = response.data.data;
          setUserStatusRider(result);
        })
        .catch((e) =>
      console.log(e)
          // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    }
  };
  let driverOnlineStatus = () => {
    if (tripViewDriverData?.userId) {
      APIService.get(
        "admin/user/user-loc-and-status/" + tripViewDriverData?.userId
      )
        .then((response) => {
          var result = response.data.data;
          setUserStatusDriver(result);
        })
        .catch((e) =>
        console.log(e)
        );
    }
  };
  useEffect(() => {
    riderOnlineStatus();
    driverOnlineStatus();
   const tripLocationApiInterval= setInterval(() => {
      riderOnlineStatus();
      driverOnlineStatus();
      if (!window.location?.href?.includes('trip-details')) {
        clearInterval(tripLocationApiInterval)
      }
    }, 10000);
  }, [tripViewData]);

  const releasePaymentFunction = ()=>{
    try{
      APIService.get("admin/confirm-trip-payment/"+tripID)
      .then((response)=>{
        if(response){
          toast.success("Payment Released Successfully");
        }
      }).catch((e)=>{
        toast.warn("Something Went Wrong");
      })

    }catch(e){
      console.warn(e)
    }
  }
  return (
    <>
      <div className="rider-details trip-details">
        <Row className="row-eq-height">
          <Col md={4}>
            <RiderInfo
              riderInfo={tripViewRiderData}
              userStatusRider={userStatusRider}
            />

            <Col
              xl={12}
              className="mt-2 driver-details px-0 row-flex"
              style={{ height: "425px" }}
            >
              {tripViewRiderData?.totalRides +
                tripViewRiderData?.ridesCancelled ===
              0 ? (
                <EmptyTotalTrips
                  tripsData={emptyTrips}
                  captainData={tripViewRiderData}
                  emptyTrips={emptyTrips}
                />
              ) : (
                <TotalTrips
                  tripsData={totalTrips}
                  captainData={tripViewRiderData}
                  // emptyTrips={emptyTrips}
                />
              )}
            </Col>
            {/* <Col md={12}>
            <div
              class="mt-2 driver-details row-flex row"
              style={{ height: "425px" }}
            >
              <div class="px-0 col-xl-12">
                <div class="drivers h-100 d-box card">
                  <div class="head d-lg-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                    <div>
                      <h2 class="box-title">
                        Total Trips: &nbsp;
                        <span
                          style={{ color: "rgb(0, 0, 0)", fontWweight: "600" }}
                        >
                          12
                        </span>
                      </h2>
                    </div>
                  </div>
                  <Doughnut type="doughnut" data={data} plugins={plugins} />
                  <div class="mt-24 d-flex align-items-baseline justify-content-between flex-wrap">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div class="green-box"></div>
                      <div class="ml-2 d-flex align-items-center">
                        <div class="trips-title-label mr-2">Completed</div>
                        <div
                          class="trips-count text-success font-weight-bolder"
                          style={{ color: "rgb(0, 0, 0)" }}
                        >
                          03
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div class="red-box"></div>
                      <div class="ml-2 d-flex align-items-center">
                        <div class="trips-title-label-cancelled mr-2">
                          Cancelled
                        </div>
                        <div
                          class="trips-count text-success font-weight-bolder"
                          style={{ color: "rgb(0, 0, 0)" }}
                        >
                          03
                        </div>

                        <div
                          class="trips-count"
                          style={{ color: "rgb(19, 181, 66)" }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
          </Col>

          <Col md={4}>
            <CaptainInfo
              captainInfo={tripViewDriverData}
              status={tripViewData?.status}
              userStatusDriver={userStatusDriver}
              tripData = {tripViewData}
            />
            {tripViewData?.driver ? (
              <Col
                xl={12}
                className="mt-2 driver-details px-0 row-flex"
                style={{ height: "425px" }}
              >
                {!tripViewData?.driver ||
                tripViewData?.driver?.totalTrips +
                  tripViewData?.driver?.tripsCancelled ==
                  0 ? (
                  <EmptyTotalTripsDriver
                    tripsData={emptyTrips}
                    captainData={tripViewDriverData}
                    emptyTrips={emptyTrips}
                  />
                ) : (
                  <TotalTripsDriverChart
                    tripsData={totalTripsDriver}
                    captainData={tripViewDriverData}
                    // emptyTrips={emptyTrips}
                  />
                )}
              </Col>
            ) : (
              ""
            )}

            {/* <Col md={12}>
            <div
              class="mt-2 driver-details row-flex row"
              style={{ height: "425px" }}
            >
              <div class="px-0 col-xl-12">
                <div class="drivers h-100 d-box card">
                  <div class="head d-lg-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                    <div>
                      <h2 class="box-title">
                        Total Trips: &nbsp;
                        <span
                          style={{ color: "rgb(0, 0, 0)", fontWweight: "600" }}
                        >
                          12
                        </span>
                      </h2>
                    </div>
                  </div>
                  <Doughnut type="doughnut" data={data} plugins={plugins2} />
                  <div class="mt-24 d-flex align-items-baseline justify-content-between flex-wrap">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div class="green-box"></div>
                      <div class="ml-2 d-flex align-items-center">
                        <div class="trips-title-label mr-2">Completed</div>
                        <div
                          class="trips-count text-success font-weight-bolder"
                          style={{ color: "rgb(0, 0, 0)" }}
                        >
                          03
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div class="red-box"></div>
                      <div class="ml-2 d-flex align-items-center">
                        <div class="trips-title-label-cancelled mr-2">
                          Cancelled
                        </div>
                        <div
                          class="trips-count text-success font-weight-bolder"
                          style={{ color: "rgb(0, 0, 0)" }}
                        >
                          03
                        </div>

                        <div
                          class="trips-count"
                          style={{ color: "rgb(19, 181, 66)" }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
          </Col>

          {/* 3rd column  */}
          <Col md={4}>
            <div className="mt-3">
              <Card className="d-box">
                <div>
                  <h2 className="box-title">Ride Details</h2>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "18px 0px",
                  }}
                >
                  <div className="title-text-detail">
                    <div className="detail-list-1-id">
                      {/* {`#${rideViewData?.userId}`} */}#
                      {tripViewData && tripViewData.tripNo}
                    </div>
                  </div>
                  <div>{statusText}</div>
                </div>

                {/* <div className="mr-12">
                <div className="rideId">
                  <span className="text-gray rideid-fnt">Trip ID:</span>
                  <h5 className="idNumber">
                    #{tripViewData && tripViewData.tripNo}
                  </h5>
                  <span className="trip-status text-capitalize">
                    {getStatusText(tripViewData && tripViewData.status)}
                  </span>
                </div>
              </div> */}
                {/* {tripViewData && tripViewData.tripStartedAt === null ? (
                ""
              ) : (
                <div>
                  <span className="fnt-size-title">Date :</span>
                  <span className="time-fnt fnt-date-size pl-2">
                    {tripViewData && tripViewData.tripStartedAt === null
                      ? ""
                      : moment
                          .utc(tripViewData && tripViewData.tripStartedAt)
                          .format("Do MMM YYYY, dddd")}
                  </span>
                </div>
              )} */}
                <div className="rider-box">
                  <div className="mart-15">
                    <Row>
                      <Col md="1" xs={2} className="mtb-auto">
                        <div className="sizeLenght"></div>
                        <div className="size-height-rod"></div>
                        <div
                          className="sizeLenght"
                          style={{ border: "2.60725px solid #EF4242" }}
                        ></div>
                      </Col>
                      <Col md="10" xs={10}>
                        {tripViewData.addresses &&
                          tripViewData.addresses.map((addr, index) => {
                            return (
                              <>
                                <h6 className="current-loc">{addr.address}</h6>
                                {tripViewData.addresses.length - 1 !== index ? (
                                  <div className="bdrLine"></div>
                                ) : null}
                              </>
                            );
                          })}
                      </Col>
                    </Row>
                  </div>
                </div>
                <Row className="mt-24 mx-0 inner-box ">
                  <Col md={12} className="px-0">
                    <div className="card-mt-13">
                      <div className="card-label-13">Duration</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {tripViewData.status === 6 || tripViewData.status === 8
                          ? calculateDuration(tripViewData?.tripTime)
                          : "-"}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Distance</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {tripViewData?.tripDistance ||
                        tripViewData?.tripDistanceCovered
                          ? tripViewData.status === 6 ||
                            tripViewData.status === 8
                            ? tripViewData?.tripDistance?tripViewData.tripDistance:"0" + " km "
                            : // : tripViewData?.tripDistance
                              tripViewData?.tripDistanceCovered?tripViewData.tripDistanceCovered:"0" + " km "
                          : "--"}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Rating</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {" "}
                        {ratingCount(tripViewData?.driverReview?.rating)}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Rider Amount</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                            {tripViewData?.riderAmount
                          ? tripViewData?.riderAmount.toFixed(2) + " SAR"
                          : "-"}{" "}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Driver Amount</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                      {tripViewData?.driverAmount
                          ? tripViewData?.driverAmount.toFixed(2) + " SAR"
                          : "-"}{" "}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Trip Base Amount</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                      {tripViewData?.tripBaseAmount
                          ? tripViewData?.tripBaseAmount.toFixed(2) + " SAR"
                          : "-"}{" "}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Trip cost</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                      {(tripViewData?.riderAmount - tripViewData?.taxAmount).toFixed(2) } SAR{" "}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Fee</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {" "}
                        {tripViewData?.taxAmount
                          ? tripViewData?.taxAmount.toFixed(2) + " SAR"
                          : "-"}{" "}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Promo code</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {tripViewData?.promoCodeAmount ||
                        tripViewData?.promoCodeAmount === 0
                          ? tripViewData?.promoCodeAmount.toFixed(2) + " SAR"
                          : "-"}{" "}
                      </div>
                    </div>

                    {/* <div className="card-mt-13 mt-2">
                    <div className="card-label-13">Processing Fee</div>
                    <div className="card-label-14">
                      {" "}
                      {tripViewData?.processingFee
                        ? tripViewData?.processingFee.toFixed(2) + " SAR"
                        : "-"}{" "}
                    </div>
                  </div>
                  <div className="card-mt-13 mt-2">
                    <div className="card-label-13">wasl Fee</div>
                    <div className="card-label-14">
                      {" "}
                      {tripViewData?.waslFee
                        ? tripViewData?.waslFee.toFixed(2) + " SAR"
                        : "-"}{" "}
                    </div>
                  </div> */}
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-13">Waiting charges</div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {" "}
                        {tripViewData?.waitingCharge ||
                        tripViewData?.waitingCharge === 0
                          ? tripViewData?.waitingCharge.toFixed(2) + " SAR"
                          : "-"}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-14 text-dark font-weight-bolder">
                        Total fare
                      </div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {tripViewData?.tripBaseAmount || tripViewData?.waslFee ? (
                          <b
                            style={{
                              fontFamily: "'Manrope', sans-serif !important",
                            }}
                          >
                            {" "}
                            {(tripViewData?.tripBaseAmount + tripViewData?.waslFee).toFixed(2)} SAR{" "}
                          </b>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="card-mt-13 mt-2">
                      <div className="card-label-14 text-dark font-weight-bolder">
                        Payment status
                      </div>
                      <div className="card-label-14 text-dark font-weight-bolder">
                        {tripViewData?.transactionStatus ? (
                          <>
                          <b
                            style={{
                              fontFamily: "'Manrope', sans-serif !important",
                            }}
                          >
                            {" "}
                            {transactionStatus(tripViewData?.transactionStatus)}
                          </b>
                          {(tripViewData?.transactionStatus===1 && tripViewData?.status===8)&&(
                            <Button className="d-block bg-success border-0" onClick={releasePaymentFunction}>Confirm Payment</Button>
                          )}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <div className="pdi-35 mt-30"> */}

                {/* {tripViewData && tripViewData.tripStartedAt === null ? (
                  ""
                ) : (
                  <div className="pt-2">
                    <span className="fnt-size-title">Start :</span>
                    <span className="calender-txt fnt-date-size pl-2">
                      {tripViewData && tripViewData.tripStartedAt === null
                        ? ""
                        : moment
                            .utc(tripViewData && tripViewData.tripStartedAt)
                            .format("LT")}
                    </span>
                  </div>
                )} */}
                {/* <div className="pdi-15"> */}
                {/* {tripViewData && tripViewData?.tripTime === null ? (
                    ""
                  ) : tripViewData && tripViewData.tripFinishedAt === null ? (
                    ""
                  ) : (
                    <div>
                      <span className="fnt-size-title">End :</span>

                      <span className="calender-txt fnt-date-size pl-2">
                        {tripViewData && tripViewData?.tripTime === null
                          ? ""
                          : tripViewData && tripViewData.tripFinishedAt === null
                          ? ""
                          : moment
                              .utc(tripViewData && tripViewData.tripFinishedAt)
                              .format("LT")}
                      </span>
                    </div>
                  )} */}
                <div>
                  {/* <span className="fnt-size-title">Duration :</span> */}
                  {/* time-fnt */}
                  {/* <span className="calender-txt fnt-date-size pl-2">
                      {tripViewData.status === 6 || tripViewData.status === 8
                        ? calculateDuration(tripViewData?.tripTime)
                        : "-"}
                    </span> */}
                </div>
                <div>
                  {/* <span className="fnt-size-title">Distance :</span> */}
                  {/* km-time-txt  */}
                  {/* <span className="calender-txt fnt-date-size pl-2">
                      {tripViewData.status === 6 || tripViewData.status === 8
                        ? tripViewData?.tripDistanceCovered
                          ? tripViewData?.tripDistanceCovered + " km "
                          : " "
                        : tripViewData?.tripDistance
                        ? tripViewData?.tripDistance + " km "
                        : " "}
                    </span> */}
                </div>
                {/* {tripViewData.status === 6 ||
                  tripViewData.status === 11 ? null : (
                    <>
                      <div
                        className="font-size-13 mt-3 "
                        // className="mr-3 ml-3"
                      >
                        <Row className="mb-1">
                          <Col md="" xs={6}>
                            Base Fare
                          </Col>
                          <Col xs={6} className="text-right">
                            {" "}
                            {tripViewData && tripViewData.baseFare === null
                              ? "-"
                              : tripViewData?.baseFare
                              ? tripViewData.baseFare.toFixed(2) + " SAR"
                              : "-"}
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col md="" xs={6}>
                            Distance Fare
                          </Col>
                          <Col xs={6} className="text-right">
                            {" "}
                            {tripViewData &&
                            tripViewData.tripDistanceCovered === null
                              ? "-"
                              : tripViewData?.tripDistanceCovered
                              ? toFixedWithoutRound(
                                  tripViewData.tripDistanceCovered *
                                    tripViewData.costPerKm,
                                  2
                                ) + " SAR"
                              : "-"}
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col md="" xs={6}>
                            Time Fare
                          </Col>
                          <Col xs={6} className="text-right">
                            {" "}
                            {tripViewData &&
                            tripViewData.tripTime &&
                            tripViewData.costPerMin === null
                              ? "-"
                              : tripViewData?.tripTime
                              ? (
                                  tripViewData?.tripTime *
                                  tripViewData.costPerMin
                                ).toFixed(2) + " SAR"
                              : "-"}
                          </Col>
                        </Row>
                        {tripViewData.status === 8 ? null : (
                          <Row className="mb-1">
                            <Col md="" xs={6}>
                              Estimated Cost
                            </Col>
                            <Col xs={6} className="text-right">
                              {" "}
                              {tripViewData &&
                              tripViewData.estimatedBaseAmount === null
                                ? "-"
                                : tripViewData?.estimatedBaseAmount
                                ? tripViewData.estimatedBaseAmount.toFixed(2) +
                                  " SAR"
                                : "-"}
                            </Col>
                          </Row>
                        )}
                      </div>
                    </>
                  )}
                  {tripViewData.status === 1 ||
                  tripViewData.status === 2 ||
                  tripViewData.status === 3 ||
                  tripViewData.status === 4 ||
                  tripViewData.status === 5 ||
                  tripViewData.status === 7 ||
                  tripViewData.status === 9 ? null : (
                    <div className="font-size-13 mt-3 ">
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          Trip Cost
                        </Col>
                        <Col xs={6} className="text-right">
                          {tripViewData?.tripBaseAmount
                            ? tripViewData?.tripBaseAmount.toFixed(2) + " SAR"
                            : "-"}{" "}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          Service Tax {`(${tripViewData.taxPercentage}%)`}
                        </Col>
                        <Col xs={6} className="text-right">
                          {" "}
                          {tripViewData?.taxAmount
                            ? tripViewData?.taxAmount.toFixed(2) + " SAR"
                            : "-"}{" "}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          Promo Code
                        </Col>
                        <Col xs={6} className="text-right">
                          {" "}
                          {tripViewData?.promoCodeAmount ||
                          tripViewData?.promoCodeAmount === 0
                            ? tripViewData?.promoCodeAmount.toFixed(2) + " SAR"
                            : "-"}{" "}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          Waiting Charges
                        </Col>
                        <Col xs={6} className="text-right">
                          {" "}
                          {tripViewData?.waitingCharge ||
                          tripViewData?.waitingCharge === 0
                            ? tripViewData?.waitingCharge.toFixed(2) + " SAR"
                            : "-"}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          MOT Charges
                        </Col>
                        <Col xs={6} className="text-right">
                          {" "}
                          {tripViewData?.motAmount ||
                          tripViewData?.motAmount === 0
                            ? tripViewData?.motAmount.toFixed(2) + " SAR"
                            : "-"}
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md="" xs={6}>
                          <b>Total Fare </b>
                        </Col>
                        <Col xs={6} className="text-right">
                          {tripViewData?.riderAmount ? (
                            <b> {tripViewData?.riderAmount.toFixed(2)} SAR </b>
                          ) : (
                            "-"
                          )}
                        </Col>
                      </Row>
                    </div>
                  )} */}
                {/* </div> */}
                {/* </div> */}
              </Card>
            </div>

            <OtherInfo captainInfo={tripViewData} />

            <Card className="d-box mtc-10">
              <h2 className="box-title">Path</h2>
              <div className="mt-3">
                <Card className="card-map p-0">
                  {tripViewData && tripViewData.locations && (
                    <Map
                      googleMapURL={
                        "https://maps.googleapis.com/maps/api/js?key=" +
                        googleMapsApiKey +
                        "&libraries=geometry,drawing,places"
                      }
                      markers={tripViewData.locations}
                      loadingElement={
                        loadingElement || <div style={{ height: `100%` }} />
                      }
                      containerElement={
                        containerElement || <div className="map-height" />
                      }
                      mapElement={
                        mapElement || <div style={{ height: `100%` }} />
                      }
                      defaultCenter={
                        defaultCenter || {
                          lat:
                            tripViewData &&
                            tripViewData.locations &&
                            tripViewData.locations[1] &&
                            tripViewData.locations[1].latitude,
                          lng:
                            tripViewData &&
                            tripViewData.locations &&
                            tripViewData.locations[1] &&
                            tripViewData.locations[1].longitude,
                        }
                      }
                      defaultZoom={defaultZoom || 11}
                    />
                  )}
                </Card>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        className="modal-promotion"
        show={showCancelTrip}
        size="md"
        onHide={cancelRideHandleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik>
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                    Cancelling Ride?
                  </Modal.Title>
                  <div class="divclose" onClick={cancelRideHandleClose}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="">
                  <Row>
                    <Col className="px-1">
                      <p className="field-text mt-2">
                        Please select reason of cancelling
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center ">
                    <Col md={12}>
                    <div role="group" aria-labelledby="my-radio-group" className="radio-trip-cancel mb-3">
                          <label>
                            <Field
                              type="radio"
                              name="cancel-trip"
                              value="One"
                              checked
                            />
                            One
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="cancel-trip"
                              value="Two"
                            />
                            Two
                          </label>
                        </div>
                    </Col>

                    <Col md={12} className="mt-3 px-0">
                      <Button
                        variant="primary"
                        // type="submit"

                        className="btn-save btn-block btn-red "
                      >
                        Cancel Ride
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(RidesDetails);
