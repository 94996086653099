import React from "react";
import ReportsTripsListing from "../components/Reports/ReportsTripsListing";

let ReportsTripsPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Trips Report</span>
        <ReportsTripsListing />
      </div>
    </>
  );
};

export default React.memo(ReportsTripsPage);
