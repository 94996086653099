import React, { Component } from "react";
import { withGoogleMap, GoogleMap, Polygon, Marker } from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import data from "./data.json";
const {
  SearchBox,
} = require("react-google-maps/lib/components/places/SearchBox");

let polygonRef = [],
  getGeoLocation = () => {
    let result = { lat: 24.712095, lng: 46.672019 }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((re) => {
        let { latitude, longitude } = re.coords
        result = { lat: latitude, lng: longitude }
      });
    }
    return result
  };
class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawingControlEnabled: true,
      polygon: null,
      visible: true,
      polygonData: null,
      zoneIndex: null,
      center: { lat: 31.4544132, lng: 74.2766072 },
      // center: { lat: 24.712095, lng: 46.672019 },
      addNewZone: true,
      polygonData: null,
    };
  }
  componentDidMount = () => {
    // this.state.center = !navigator.geolocation ? { lat: 24.712095, lng: 46.672019 } : getGeoLocation()
  };
  zoneBtnClicked = (zone, index) => {

    this.setState({
      polygon: zone.polygon,
      //I just use random coordionagtes from the polygon path to set as the center. You can also put a zone center coordinate in your json data and used it as the center of the zone where you want your map to recenter when you clicked a zone button.
      center: zone.polygon[0].coord[2],
      zoneIndex: index,
    });
  };

  //function will be called when the add new zone button will be clicked. You can put your code on how you can pass new details of the Zone base on your json data.
  newZoneBtnClicked = () => {
    //this will set the value of addNewZone state that will make the Drawing Manager visible.
    this.setState({
      addNewZone: true,
    });
  };

  _onMousedown = (ref) => {
    //get the reference of the polygon then used the ref as it's index so that you can get the specific polygon
    const polygon = polygonRef[ref].getPath();
    //add event listeners for the polygon changes and pass the polygon as parameter to the function you need, you also need to pass the ref (or index)
    new window.google.maps.event.addListener(polygon, "set_at", () => {
      this._getPolygonNewPaths(polygon, ref);
    });
    new window.google.maps.event.addListener(polygon, "insert_at", () => {
      this._getPolygonNewPaths(polygon, ref);
    });
    new window.google.maps.event.addListener(polygon, "remove_at", () => {
      this._getPolygonNewPaths(polygon, ref);
    });
  };
  _getPolygonNewPaths = (polygon, ref) => {
    let polygonPaths = [];
    polygon.getArray().forEach((path) => {
      const line = {
        lat: path.lat(),
        long: path.lng(),
      };
      polygonPaths.push(line);
    });
    //this is the new polygon paths which includes what you editted

    //you will see the current value of that polygon paths in your state
    //put this current value of your polygonData to a variable holder
    const newPolygon = this.state.polygonData;
    //change the value of the polygon path to the variable that holds yout current polygonData
    newPolygon[this.state.zoneIndex].polygon[ref].coord = polygonPaths;
    //This is now the value of your changed polygonData
    //now change the polygon Data state to the changed polygonData
    this.setState({
      polygonData: newPolygon,
    });
  };
  getPaths = (polygon) => {
    // var polygonCount = localCoordinates.length == 0 ? 1 : localCoordinates.length + 1
    var polygonBounds = polygon.getPath();
    var bounds = [];
    for (var i = 0; i < polygonBounds.length; i++) {
      var point = {
        lat: polygonBounds.getAt(i).lat(),
        long: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }


    this.props.dispatch({ type: "POLYGON", payload: bounds });
    this.props.getPolygon(bounds);

  };
  //function that will put all the polygon ref in the array since polygons will be iterated base on the json data
  _polyRef = (ref) => {
    polygonRef.push(ref);
  };

  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap defaultZoom={13} center={getGeoLocation()}>

        {this.state.addNewZone === true && (
          <DrawingManager
            defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
              polygonOptions: { editable: true, draggable: true },
            }}
            onPolygonComplete={(value) => this.getPaths(value)}
          />
        )}

        {this.state.polygon !== null &&
          this.state.polygon.map((poly, index) => (
            <Polygon
              ref={(ref) => {
                this._polyRef(ref);
              }}
              paths={poly.coord}
              onMouseDown={() => {
                this._onMousedown(index);
              }}
              editable
            />
          ))}
      </GoogleMap>
    ));

    return (
      <div>

        <GoogleMapExample
          containerElement={<div style={{ height: '500px', width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Map;
