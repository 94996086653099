import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const UnreadNotifications = (props) => {
  const [isSelect, setIsSelect] = useState(false);

  return (
    <>
      <Card>
        <div className="notifications-list">
          <Row className="active-notification">
            <Col md="auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#EEF7FF" />
                <path
                  d="M13.333 24.1665H11.6663V25.8332H28.333V24.1665H26.6663V18.3332C26.6663 16.5651 25.964 14.8694 24.7137 13.6191C23.4635 12.3689 21.7678 11.6665 19.9997 11.6665C18.2316 11.6665 16.5359 12.3689 15.2856 13.6191C14.0354 14.8694 13.333 16.5651 13.333 18.3332V24.1665ZM22.4997 27.4998H17.4997V29.1665H22.4997V27.4998Z"
                  fill="#4271DB"
                />
              </svg>
            </Col>
            <Col>
              <Row className="d-inline-block">
                <span className="title-notification">Title here</span>
                <span className="time pl-0">Today 12:30PM</span>
              </Row>
              <Row>
                <div className="message">{props.message}</div>
              </Row>
            </Col>
            <Col xl="3" md="4" className="text-right">
              <span className="mr-3 mark-read">Mark as read</span>
              <svg
                className="mr-2 delete-icon"
                width="20"
                height="20"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M17 6.9458H22V8.9458H20V21.9458C20 22.211 19.8946 22.4654 19.7071 22.6529C19.5196 22.8404 19.2652 22.9458 19 22.9458H5C4.73478 22.9458 4.48043 22.8404 4.29289 22.6529C4.10536 22.4654 4 22.211 4 21.9458V8.9458H2V6.9458H7V3.9458C7 3.68058 7.10536 3.42623 7.29289 3.23869C7.48043 3.05116 7.73478 2.9458 8 2.9458H16C16.2652 2.9458 16.5196 3.05116 16.7071 3.23869C16.8946 3.42623 17 3.68058 17 3.9458V6.9458ZM18 8.9458H6V20.9458H18V8.9458ZM9 4.9458V6.9458H15V4.9458H9Z"
                    fill="#03053D"
                  />
                </g>
              </svg>

              <Form className="d-inline-block">
                <Form.Group controlId="formBasicCheckbox" className="mb-0">
                  <Form.Check type="checkbox" className="margin-l-5" label="" />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default React.memo(UnreadNotifications);
