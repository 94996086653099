import React from "react";
import SMSNotificationListing from "../components/NotificationManage/SMSNotificationListing";

const NotificationSMSPage = () => {
  return (
    <>
      
        <SMSNotificationListing />
      
    </>
  );
};

export default React.memo(NotificationSMSPage);
