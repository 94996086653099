import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import saveAs from "save-as";

let ReportsTripCancelledSummaryListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [tripCancelledSummary, setTripCancelledSummary] = useState(null),
    [selectedTripsCancelled, setSelectedTripsCancelled] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [createdAt, setCreatedAt] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "cancellation",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getTripCancelledSummary = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/reports/trips-cancelled-by-captain", filterParams)
        .then((response) => {
          if (response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            setTripCancelledSummary(response.data.data.trips);
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTripCancelledSummary();
    },
    exportTripCancelledSummary = () => {
      let tripCancel = [
        "Driver Id",
        "Driver name",
        "No. Of Total Trips",
        "No. Of Total Trips Completed",
        "No. Of Total Trips Cancelled",
        "Overall Rating",
        "Cancellation %",
      ];
      tripCancel = tripCancel.join(",") + "\r\n";
      if (selectedTripsCancelled.length) {
        selectedTripsCancelled.forEach((tripCancelled) => {
          tripCancel +=
            [
              "'" + tripCancelled.userId,
              tripCancelled.fullName,
              tripCancelled.totalTrips,
              tripCancelled.completedTrips,
              tripCancelled.tripsCancelled,
              tripCancelled.overallRating,
              tripCancelled.cancellation,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([tripCancel], {
            type: "text/csv;charset=utf-8",
          }),
          "Trip-Cancelled-Summary-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 1000000000000000;
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post(
          "admin/reports/trips-cancelled-by-captain",
          filterParams
        )
          .then((response) => {
            if (response.data.statusCode === 200) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.trips.forEach((tripCancelled) => {
                tripCancel +=
                  [
                    "'" + tripCancelled.userId,
                    tripCancelled.fullName,
                    tripCancelled.totalTrips,
                    tripCancelled.completedTrips,
                    tripCancelled.tripsCancelled,
                    tripCancelled.overallRating,
                    tripCancelled.cancellation,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([tripCancel], {
                  type: "text/csv;charset=utf-8",
                }),
                "Trip-Cancelled-Summary-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getTripCancelledSummary();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["createdAt"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTripCancelledSummary();
                      }, 500);
                    }
                  } else {
                    setCreatedAt([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getTripCancelledSummary();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportTripCancelledSummary}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    overallRatingBody = (rowData) => {
      return (
        <React.Fragment>
          <Rating
            className="ratings pl-0 d-flex justify-content-center"
            fractions={2}
            emptySymbol="fa fa-star-o fa-2x star"
            fullSymbol="fa fa-star fa-2x star"
            initialRating={rowData.overallRating}
            readonly
          />
        </React.Fragment>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setSelectedTripsCancelled([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "fullName",
          order: 1,
        },
        take: 25,
        skip: 0,

        keyword: "",
      });
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={tripCancelledSummary}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Cancelled Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          selection={selectedTripsCancelled}
          onSelectionChange={(e) => setSelectedTripsCancelled(e.value)}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          totalRecords={lazyParams.totalRecords}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Driver Id"
            field="userId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Id"
          />
          <Column
            style={{ fontSize: "12px", width: "180px" }}
            header="Driver name"
            field="fullName"
            sortable
            filter={showFilter}
            filterField="driverName"
            filterPlaceholder="Search by Name"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="No. Of Total Trips"
            field="totalTrips"
            filter={showFilter}
            sortable
            filterPlaceholder="Search by trips"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "270px" }}
            header="No. Of Total Trips Completed"
            field="completedTrips"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Total trip completed"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "270px" }}
            header="No. Of Total Trips Cancelled"
            field="tripsCancelled"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Total trip cancelled"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="riderRating"
            header="Overall Rating"
            sortable
            body={overallRatingBody}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            header="Cancellation %"
            field="cancellation"
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsTripCancelledSummaryListing));
