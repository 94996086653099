import React, { useState, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CSVLink } from "react-csv";

let SubAdmin = (props) => {
  let [selectedCustomers, setSelectedCustomers] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [lastAccessFilter, setLastAccess] = useState(null),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    [showFilter, setShowFilter] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(null),
    [selectRole, setSelectedRole] = useState(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/admin/user/')),
    dt = useRef(null),
    statuses = ["Active", "Inactive"],
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    onStatusChange = (e) => {
      dt.current.filter(e.value, "status", "equals");
      setSelectedStatus(e.value);
    },
    onRoleChange = (e) => {
      let role = props.roles.find((role) => role.value === e.value);
      dt.current.filter(
        role && role.label ? role.label : "",
        "roleName",
        "equals"
      );
      setSelectedRole(e.value);
    },
    headers = [
      { label: "Full Name", key: "fullName" },
      { label: "Role", key: "roleName" },
      { label: "Email", key: "email" },
      { label: "Last Access", key: "date" },
      { label: "Status", key: "status" },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left d-flex align-items-center ml-2" style={{height:48}}>
            <i className="pi pi-search " style={{marginTop:'-10px'}} />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename="Sub Admin.csv"
            target="_blank"
            ref={CSVdownloadRef}
          />
          <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={() => downloadCsvFile()}
          >
            Export
          </button>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={props.handleShow}
          >
            {windowWidth ? <i className="fa fa-plus" /> : "Add +"}
          </button>}
        </div>
      );
    },
    onLastAccessChange = (event) => {
      dt.current.filter(event.value, "lastAccessedAt", "custom");
      setLastAccess(event.value);
    },
    header = renderHeader(),
    toggleFilter = () => {
      dt.current.reset();
      setLastAccess(null);
      setSelectedRole(null);
      setSelectedStatus(null);
      setShowFilter(!showFilter);
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button text-center">
          {/* <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowUpdate();
              props.updateTableData(rowData);
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button> */}
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowDelete(rowData);
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#03053D"
              />
            </svg>
          </Button>}
          {!currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <span>No permission</span>}
        </div>
      );
    },
    filterDate = (value, filter) => {
      return value &&
        filter &&
        moment(value).format("DD/MM/YYYY") ===
        moment(filter).format("DD/MM/YYYY")
        ? true
        : false;
    };

  // get subAdmins data into csv file
  const downloadCsvFile = () => {
    setDownloadCsv(props.admins);
    setTimeout(() => {
      CSVdownloadRef.current.link.click();
    }, 500);
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={props.admins}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          globalFilter={globalFilter}
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          paginator
          rows={25}
          loading={props.isLoading}
          loadingIcon="fa fa-spinner"
          emptyMessage="No Admin(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          scrollable
          scrollHeight="470px"
        >
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            sortField="fullName"
            filterField="fullName"
            header="Name"
            field="fullName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by name"
            body={(rowData) => {
              return (
                <span
                  style={{
                    color: "rgb(19, 181, 66)",
                    textDecoration: " underline",
                    cursor: "pointer",
                    textTransfor: " capitalize",
                  }}
                >
                  {" "}
                  {rowData.fullName}
                </span>
              );
            }}
          />

          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="roleName"
            header="Role"
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectRole}
                options={props.roles}
                onChange={onRoleChange}
                placeholder="Select a role"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            headerClassName="text-left"
            className="text-left"
            style={{ fontSize: "12px", width: "260px" }}
            field="email"
            header="Email"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by email"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            sortField="lastAccessedAt"
            filterField="lastAccessedAt"
            header="Last Access"
            field="date"
            body={(row) => {
              return (
                <span className="dataFnt-size">
                  <span className="p-column-title">Last Access</span>
                  {row.lastAccessedAt &&
                    moment(row.lastAccessedAt).format("DD/MM/YYYY hh:mm A")}
                </span>
              );
            }}
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={filterDate}
            filterElement={
              <Calendar
                appendTo={null}
                value={lastAccessFilter}
                onChange={onLastAccessChange}
                placeholder="Search date"
                dateFormat="dd-mm-yy"
                className="p-column-filter"
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="status"
            header="Status"
            body={(row) => {
              return row.status === "Active" ? (
                <span className="status-text">Active</span>
              ) : (
                <span className="status-text-inactive">Inactive</span>
              );
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statuses}
                onChange={onStatusChange}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(SubAdmin);
