import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import PromoCodesFail from "./promoCodesFail";
import PromoCodesPass from "./promoCodesPass";
import SubscriptionPackagesSubscribe from "./subscriptionPackagesSubscribe";
import SubscriptionPackagesUnsubscribe from "./subscriptionPackagesUnsubscribe";

function SubscriptionPackagesDetail(props) {
  let [selectTab, setSelectedTab] = useState("subscribed");

  return (
    <>
      <div className="rider-listing">
        <div className=" justify-content-between">
          <Link to="/subscription-packages" title="Back">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Adjust <span className="text-secondary">(Subscription Packages)</span> </span>
          <div className="table-header"></div>
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          <Tab eventKey="subscribed" title="Subscribed">
            {selectTab === "subscribed" && <SubscriptionPackagesSubscribe />}
          </Tab>
          <Tab eventKey="unsubscribed" title="Unsubscribed">
            {selectTab === "unsubscribed" && (
              // <div>COMING SOON</div>
              <SubscriptionPackagesUnsubscribe />
            )}
          </Tab>
        </Tabs>
        {/* <DriverListingTable /> */}
      </div>
    </>
  );
}
export default SubscriptionPackagesDetail;
