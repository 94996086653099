import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Geocode from "react-geocode";
import store from "../store";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import PromotionalCouponsListing from "../components/PromotionalCoupons/PromotionalCouponsListing";
import Select, { StylesConfig } from "react-select";
import { Calendar4 } from "react-bootstrap-icons";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import IConstants from "../IConstants";
import { parseHTML } from "jquery";
import { Paginator } from "primereact/paginator";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { value } from "lodash/seq";
Geocode.setApiKey("AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg");
Geocode.enableDebug();
const storeState = store.getState();
const Google_Map_Api_Key = storeState.apiKey;

const center = {
  lat: 24.774265,
  lng: 46.738586,
};
const position = {
  lat: 24.774265,
  lng: 46.738586,
};
const center2 = {
  lat: 24.774265,
  lng: 46.738586,
};
const position2 = {
  lat: 24.774265,
  lng: 46.738586,
};
const containerStyle = {
  width: "100%",
  height: "400px",
};
let googleMapsApiKey = Google_Map_Api_Key;
const colourStyles = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#fff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};


let options = [
  { value: 1, label: "Fixed Amount" },
  { value: 2, label: "Percentage" },
],
  statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ],

  receiverOptions = [
    { value: 1, label: "Rider" },
    { value: 2, label: "Driver" },
  ],
  receiverArabicOptions = [
    { value: 1, label: "متسابق" },
    { value: 2, label: "..." },
  ],
  genderOptions = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
  ],
  userTypeOption = [
    { value: 1, label: "Rider" },
    { value: 2, label: "Captain" },
    { value: 3, label: "Both" },
  ],
  cityOptions = [
    { value: 1, label: "Riyadh" },
    { value: 2, label: "...." },
  ],
  countryOptions = [
    { value: 1, label: "Saudi Arabia" },
    { value: 2, label: "...." },
  ],

  Notifications = (props) => {
    const sendVia = [
      { label: 'Whatsapp', value: 'whatsapp' },
      { label: 'Email', value: 'email' },
      { label: 'SMS', value: 'sms' },
      { label: "In App", value: "push" },
    ],
      notificationStatus = [
        'IN_ACTIVE',
        'SCHEDULE',
        'SENT',
        'DRAFT',
        'ARCHIVE',
        'USER_NOT_FOUND',
        'INCOMPLETE',
        'PENDING'
      ]

    const [libraries] = useState(["places"]);

    const googleMapKey = "AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg";
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: googleMapKey,
      libraries,
    });
    let handleChangePickUp = async (value) => {
      let results = await geocodeByAddress(value.label),
        latLng = await getLatLng(results[0]);
      setPickupAddress(value.label);
      setPickUpLatLng(latLng);
      setPickUp(value);
    };

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      if (map.current) {
        map.current.fitBounds(bounds);
      }
      // map.fitBounds(bounds);
      setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
      setMap(null);
    }, []);
    const handleClick = () => {
      if (!pickUp) {
        toast.error("You Have not Selected Any Pickup Location");
      } else {
        setPickup(false);
      }

    };
    const onMarkerDragEnd = (event) => {
      let newLat = event.latLng.lat(),
        newLng = event.latLng.lng();

      Geocode.fromLatLng(newLat, newLng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address ? address : "");
          setPickUpLatLng({
            lat: newLat,
            lng: newLng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    };
    let [selectedUserType, setSelectedUserType] = useState(null),
      [notificationData, setNotificationData] = useState([]),
      [archive, setArchive] = useState(false),
      [pickupAddress, setPickupAddress] = useState(""),
      [windowWidth] = useState(useSelector((state) => state.windowSize)),
      [show, setShow] = useState(false),
      [showDelete, setShowDelete] = useState(false),
      [showFilter, setShowFilter] = useState(true),
      [date, setDate] = useState(null),
      [expDate, setExpDate] = useState(null),
      [showUpdate, setShowUpdate] = useState(false),
      [pickUpLatLng, setPickUpLatLng] = useState(null),
      [isCalendarOpen, setIsCalendarOpen] = useState(false),
      [pickUp, setPickUp] = useState(null),
      [showPickup, setPickup] = useState(false),
      [multipleSelected, setMultipleSelected] = useState(null),
      history = useHistory(),
      [endDate, setEndDate] = useState(null),
      [saveNmore, setSaveNmore] = useState(false),
      [updateData, setUpdateData] = useState({
        // code: "",
        // promoCodeType: 1,
        // amount: "",
        // startAt: new Date(),
        // endAt: "",
        // message: "",
        // maximumTotalUsage: "",
        // status: true,
        name: '',
        content: '',
        usertype: '',
        title: '',
        // gender: null,
        // receiver: '',
        // city: '',
        // country: '',
        // schedule: '',
        sendViaValue: [],
        scheduleTime: '',
        expiredAt: '',
        isUpdate: '',
        // fileupload: '',
        // pickUpLocation: ''
      }),
      handleClose = () => setShow(false),
      pickupHandleClose = () => setPickup(false),
      handleShow = () => {
        setUpdateData({
          // code: "",
          // promoCodeType: 1,
          // amount: "",
          // startAt: new Date(),
          // endAt: "",
          // message: "",
          // maximumTotalUsage: "",
          // status: true,
          // name: '',
          content: '',
          usertype: '',
          title: '',
          // gender: null,
          // receiver: '',
          // city: '',
          // country: '',
          // schedule: '',
          sendViaValue: [],
          scheduleTime: '',
          expiredAt: '',
          isUpdate: '',
          // fileupload: '',
          // pickUpLocation: ''
        });
        setMultipleSelected(null)
        setSelectedUserType(null)
        setShow(true);
      },
      handleCloseUpdate = () => setShowUpdate(false),
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rawData) => {
        setUpdateData(rawData);
        setShowDelete(true);
      },
      dt = useRef(null),
      permissions = JSON.parse(atob(localStorage.getItem("access"))),
      currentRoutePermissions = permissions.filter(a => a.includes('/markeeting/notifications/')),
      getNotifications = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/dashboard-notifications/users")
          .then(async (response) => {
            if (response.data && response.data.statusCode === 200) {
              let data = response?.data?.data?.notifications
              setNotificationData(data)
              const filtered = data?.slice(0, 10);
              setFilteredData(filtered);
            }
            // if (response.data && response.status === 200) {
            //   let result = response?.data?.data
            //   let data = []

            //   await result.pushNotifications.map(a =>
            //     data.push({ ...a, source: 'push' })
            //   )
            //   await result.emailNotifications.map(a =>
            //     data.push({ ...a, source: 'email' })
            //   )
            //   await result.smsNotifications.map(a =>
            //     data.push({ ...a, source: 'sms' })
            //   )
            //   const mergedObjects = Object.values(data.reduce((acc, obj) => {
            //     const { refId, source, ...rest } = obj;
            //     const existingObj = acc[refId];

            //     if (existingObj) {
            //       existingObj.source += `, ${source}`;
            //     } else {
            //       acc[refId] = { refId, source, ...rest };
            //     }

            //     return acc;
            //   }, {}));
            //   setNotificationData(mergedObjects)
            //   const filtered = mergedObjects?.slice(0, 10);
            //   setFilteredData(filtered);
            //   props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            // }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter title"),
        usertype: Yup.string().required("Please select a userType"),
        content: Yup.string().required("Please enter content"),
        sendViaValue: Yup.array().min(1, "Select atleast one option"),
        // name: Yup.string().required("Please Enter your name"),
        // gender: Yup.string().required("Select Gender").nullable(),
        // schedule: Yup.string().required("Select Date & Time"),
        // scheduleTime: Yup.string().required("Select Schedule"),
        // expiredAt: Yup.string().required("Select Expiry Date"),
        // fileupload: Yup.string().required("Upload a picture"),
        // pickUpLocation: Yup.string().required("Please select location"),
      }),
      removePromo = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.delete("admin/promo-code/" + updateData.id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              toast.success("Promo Code deleted successfully");
              getNotifications();
            } else {
              toast.error("Failed to delete Promo Code");
            }
            setShowDelete(false);
          })
          .catch((error) => {
            setShowDelete(false);
          });
      };
    useEffect(() => {
      getNotifications();
    }, []);

    let createOrUpdatePromo = (promoField, props) => {
      promoField.maximumTotalUsage = Number(promoField.maximumTotalUsage);
      promoField.amount = Number(promoField.amount);
      promoField.method = promoField.promoCodeType;
      promoField.amount =
        promoField.promoCodeType === 2
          ? promoField.percent
          : promoField.amount;
      if (updateData && updateData.id) {
        APIService.put("admin/promo-code/" + updateData.id, promoField)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Notification updated successfully");
              getNotifications();
            } else {
              toast.error("Failed to update Notification");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            handleCloseUpdate();
          });
      } else {
        // let url =
        //   promoField.promoCodeType === 2 ? "admin/voucher" : "admin/coupon";
        let url = "admin/coupon";
        APIService.post(url, promoField)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Notification sent successfully");
              getNotifications();
            } else {
              toast.error("Failed to sent Notification");
            }
            props.resetForm();

            setEndDate("");
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.resetForm();

            if (!saveNmore) {
              handleClose();
            }
            console.log(error);
          });
      }

      setShowUpdate(false);
    },
      toggleFilter = () => {
        setShowFilter(!showFilter);
      };
    const [address, setAddress] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);
    const [filteredData, setFilteredData] = useState([]);

    const onBasicPageChange = (event) => {
      setBasicFirst(event.first);
      setBasicRows(event.rows);

      const filtered = notificationData?.slice(
        event.page * event.rows,
        event.page * event.rows + event.rows
      );
      setFilteredData(filtered);
    };
    const template = {
      layout:
        "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ",
    };
    const createInstantNotification = (promoField) => {
      APIService.post('admin/dashboard-notifications/users', promoField).
        then(res => {
          if (res.status === 201) {
            toast.success("Notification sent successfully");
            getNotifications();
          } else {
            toast.error("Failed to create Notification");
          }
        })
        .catch(e => console.log(e))
    },
      createNotification = (promoField) => {
        APIService.post('admin/schedule/dashboard-notifications', promoField).
          then(res => {
            console.log("re", res)
            if (res.status === 200 || res.status === 201) {
              toast.success(`Notification ${promoField.status === '3' ? 'DRAFT' : 'Sent'} successfully`);
              getNotifications();
            } else {
              toast.error("Failed to Create Notification");
            }
          })
          .catch(e => console.log(e))
      },
      updateNotification = (promoField) => {
        APIService.patch('admin/schedule/dashboard-notifications/' + promoField.id, promoField).
          then(res => {
            if (res.status === 200) {
              toast.success("Notification Updated successfully");
              getNotifications();
            } else {
              toast.error("Failed to Update Notification");
            }
          })
          .catch(e => console.log(e))
      }
    const handleSubmit = (promoField, props) => {
      let ScheduleFill = (promoField.scheduleTime !== "" && promoField.expiredAt !== "") ? true : false
      let status = promoField.archive ? '4' : ScheduleFill ? '1' : '7'
      promoField.message = promoField.content
      promoField.refId = (new Date()).getTime()
      promoField.sendVia = multipleSelected.join(",")
      promoField.userType = promoField.usertype
      promoField.receiver = promoField.usertype
      promoField.status = status
      promoField.scheduleTime = promoField.scheduleTime === '' ? new Date() : new Date(promoField.scheduleTime)
      promoField.expiredAt = promoField.expiredAt === '' ? new Date() : new Date(promoField.expiredAt)
      if (status === '7') createInstantNotification(promoField)
      else {
        if (promoField.isUpdate === '') createNotification(promoField)
        else updateNotification(promoField)
      }
      props.resetForm();
      handleClose()
      setSelectedUserType(null)
      setMultipleSelected(null)

    },
      handleUpdate = (e, item) => {
        e.stopPropagation()
        let promo = { ...item },
          sendViaValues = promo?.sendVia?.split(",")
        promo.sendViaValue = sendViaValues
        promo.usertype = promo.receiver
        promo.scheduleTime = promo?.scheduleTime ? new Date(promo.scheduleTime) : ''
        promo.expiredAt = promo?.expiredAt ? new Date(promo.expiredAt) : ''
        promo.isUpdate = '0'
        setMultipleSelected(sendViaValues)
        setSelectedUserType(receiverOptions[promo.receiver - 1])
        setUpdateData(promo);
        setShow(true)
      },
      handleFormClose = (values) => {
        let isEmpty = Object.values(values).every(value => value === "" || (Array.isArray(value) && value.length === 0));
        if (isEmpty || values.isUpdate !== '') handleClose()
        else {
          values.message = values.content
          values.refId = (new Date()).getTime()
          values.scheduleTime = values.scheduleTime === '' ? new Date() : new Date(values.scheduleTime)
          values.expiredAt = values.expiredAt === '' ? new Date() : new Date(values.expiredAt)
          values.sendVia = multipleSelected ? multipleSelected.join(",") : ""
          values.receiver = values.usertype
          values.status = '3'

          createNotification(values)
          setSelectedUserType(null)
          setMultipleSelected(null)
          handleClose()
        }
      }

    let calendarRef = useRef(null)
    let calendarRef2 = useRef(null)
    const handleCalendarHide = () => setIsCalendarOpen(false)

    return (
      <>
        <div className="d-box-subscription mt-3">
          <div className="table-header">
            <span className="toggle-filter">
              <button className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}>
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />

                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up"></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
              </button>
            </span>
            <span className="p-input-icon-left p-notification d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText type="search" placeholder="Search..." />
            </span>
            {/* <span className="title">Subscription Package</span> */}

            {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={handleShow}
            >
              {windowWidth ? <i className="fa fa-plus"></i> : "+ Add "}
            </button>}
          </div>
          <div className="tab-pane-custom">
            <div className="row mt-2">
              {filteredData?.map((item, idx) =>
                <div key={idx} className="col-lg-6">
                  <div className="card-custom card-notification"
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push({ pathname: '/notification-detail/push', state: item.refId })}>
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Name </p>
                        <p className="sub-stats"> {item?.title} </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading">Source </p>
                        <p className="sub-stats">{item?.sendVia}</p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Receiver </p>
                        <p className="sub-stats"> {item?.receiver === 1 ? "Rider" : "Driver"} </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <span className="status-text" style={{
                          backgroundColor: item?.status === '2'
                            ? '#13B542'
                            : item?.status === '0'
                              ? '#EF4242'
                              : '#FFB065'
                        }}>{notificationStatus[item?.status]}</span>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-9">
                        <p className="sub-heading"> Content </p>
                        <p className="sub-stats my-2">{item?.content}</p>
                        {/* <div dangerouslySetInnerHTML={{ __html: item?.body ?? item?.message }} /> */}
                        {/* <p className="sub-stats-arabic mb-0">
                            {item?.content_arabic}                      </p> */}
                      </div>
                      <div className="col-md-3 text-md-right mt-auto">
                        {(item.status !== '2' && item.status !== '7') && <Link to="#" onClick={(e) => handleUpdate(e, item)} className="link-success">
                          Edit
                        </Link>}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Paginator
            ref={dt}
            template={template}
            // first={lazyParams.skip}
            first={basicFirst}
            rows={basicRows}
            value={notificationData}
            totalRecords={notificationData?.length}
            rowsPerPageOptions={[5, 10, 25, 50]}
            selection={selectedCustomers}
            onSelectionChange={(e) => setSelectedCustomers(e.value)}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            // onPageChange={manageActiveSubscription}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
        <Modal
          className="modal-promotion"
          show={show}
          size="lg"
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ handleChange, handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add/Update Notification
                    </Modal.Title>
                    <div className="divclose" onClick={() => handleFormClose(values)}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="title"
                            type="text"
                            name="title"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Title"
                            onChange={(e) => handleChange('title')(e.target.value.trimStart())}
                          // value={title}
                          // onMouseDown={e=>valchange(true)}
                          // onChange={(e) => setFieldValue("title", e.value)}
                          />
                          <label htmlFor="title">Title</label>
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="User Type"
                            // isClearable
                            name="usertype"
                            styles={colourStyles}
                            value={selectedUserType}
                            onBlur={() => {
                              handleBlur({ target: { name: "usertype" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("usertype", e.value);
                                setSelectedUserType(e);
                              }
                            }}
                            options={receiverOptions}
                          />
                          <label>User Type</label>
                          <ErrorMessage
                            name="usertype"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            as="textarea"
                            name="content"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Content"
                            onChange={(e) => handleChange('content')(e.target.value.trimStart())}
                          //  onMouseDown={e=>valchange(true)}
                          />
                          <label htmlFor="content">Content</label>

                          <ErrorMessage
                            name="content"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col className="field" md={6}>
                        <span className="p-float-label">
                          <MultiSelect
                            name="sendViaValue"
                            placeholder="Select"
                            className="shadow-none"
                            value={multipleSelected}
                            options={sendVia}
                            onChange={(e) => {
                              setFieldValue("sendViaValue", e.value)
                              setMultipleSelected(e.value)
                            }}
                            onBlur={() => {
                              handleBlur({ target: { name: "sendViaValue" } });
                            }}
                          />
                          <label htmlFor="sendViaValue">Send Via</label>
                          <ErrorMessage
                            name="sendViaValue"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </span>

                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Calendar
                            appendTo={null}
                            minDate={new Date()}
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            maxDate={values.expiredAt ? values.expiredAt : ""}
                            placeholder="Schedule Time"
                            onBlur={() => {
                              handleBlur({ target: { name: "scheduleTime" } });
                            }}
                            value={updateData?.scheduleTime?.length > 0 ? updateData?.scheduleTime : date}
                            onChange={(e) => {
                              if (e) {
                                console.log("s", e.value)
                                setFieldValue("scheduleTime", e.value);
                                setDate(e?.value)
                              }
                            }}
                            extendsTo={null}
                            ref={calendarRef}
                            onFocus={() => setTimeout(() => {
                              calendarRef.current.showOverlay()
                            }, 100)}
                            name="scheduleTime"
                            dateFormat="dd/mm/yy"
                            className="p-column-filter"
                            showTime
                            hourFormat="12"
                          />
                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="scheduleTime"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Calendar
                            appendTo={null}
                            minDate={values.scheduleTime ? values.scheduleTime : ""}
                            placeholder="Expired At"
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            onBlur={() => {
                              handleBlur({ target: { name: "expiredAt" } });
                            }}
                            value={updateData?.expiredAt?.length > 0 ? updateData?.expiredAt : expDate}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("expiredAt", e.value);
                                setExpDate(e?.value)
                              }
                            }}
                            name="expiredAt"
                            dateFormat="dd/mm/yy"
                            ref={calendarRef2}
                            onFocus={() => setTimeout(() => {
                              calendarRef2.current.showOverlay()
                            }, 100)}
                            className="p-column-filter"
                            showTime
                            hourFormat="12"
                          />
                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="expiredAt"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group" style={{ display: 'flex', gap: '5px' }}>
                          <Checkbox
                            style={{ marginTop: '3px' }}
                            checked={archive}
                            onChange={(e) => {
                              setFieldValue("archive", e.checked)
                              setArchive(e.checked)
                            }}
                            name="archive"
                            className="p-column-filter"
                          />
                          <p>Do you want to mark this as Archive</p>
                        </div>
                      </Col>
                      <Row>
                        <Col md={6}>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-save "
                          >
                            Send
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal
          className="modal-promotion"
          size="lg"
          show={showUpdate}
          onHide={handleCloseUpdate}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Formik
            enableReinitialize
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdatePromo}
          >
            {({ handleChange, handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Update Notification
                    </Modal.Title>
                    <div className="divclose" onClick={handleCloseUpdate}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="title"
                            type="text"
                            name="title"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Title"
                            onChange={(e) => handleChange('title')(e.target.value.trimStart())}
                          // value={title}
                          // onMouseDown={e=>valchange(true)}
                          // onChange={(e) => setFieldValue("title", e.value)}
                          />
                          <label htmlFor="title">Title</label>
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="User Type"
                            // isClearable
                            name="usertype"
                            styles={colourStyles}
                            // value ={selectedGender}
                            onBlur={() => {
                              handleBlur({ target: { name: "usertype" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("usertype", e.value);
                                // setFieldValue("amount", "");
                                setSelectedUserType(e);
                              }
                            }}
                            options={receiverOptions}
                          />
                          <label>User Type</label>
                          <ErrorMessage
                            name="usertype"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            as="textarea"
                            name="content"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Content"
                            onChange={(e) => handleChange('content')(e.target.value.trimStart())}
                          //  onMouseDown={e=>valchange(true)}
                          />
                          <label htmlFor="content">Content</label>

                          <ErrorMessage
                            name="content"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col className="field" md={6}>
                        <span className="p-float-label">
                          <MultiSelect
                            name="sendViaValue"
                            placeholder="Select"
                            className="shadow-none"
                            value={multipleSelected}
                            options={sendVia}
                            onChange={(e) => {
                              setFieldValue("sendViaValue", e.value)
                              setMultipleSelected(e.value)
                            }}
                            onBlur={() => {
                              handleBlur({ target: { name: "sendViaValue" } });
                            }}
                          />
                          <label htmlFor="sendViaValue">Send Via</label>
                          <ErrorMessage
                            name="sendViaValue"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </span>
                      </Col>
                      <Col md={12}>
                        <Row>
                          <Col md={6}>
                            <div className="form-group form-label-group">
                              <Calendar
                                appendTo={null}
                                minDate={new Date()}
                                maxDate={values.endAt ? values.endAt : ""}
                                placeholder="Valid From"
                                onBlur={() => {
                                  handleBlur({ target: { name: "startAt" } });
                                }}
                                value={updateData.startAt}
                                onChange={(e) => {
                                  if (e) {
                                    setFieldValue("startAt", e.value);
                                  }
                                }}
                                name="startAt"
                                dateFormat="dd/mm/yy"
                                className="p-column-filter"
                              />
                              <span className="input-box-icon">
                                <Calendar4 />
                              </span>
                              <ErrorMessage
                                name="startAt"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group form-label-group">
                              <Calendar
                                appendTo={null}
                                minDate={values.startAt ? values.startAt : ""}
                                placeholder="End Date"
                                dateFormat="dd/mm/yy"
                                className="p-column-filter"
                                name="endAt"
                                value={updateData.endAt}
                                onBlur={() => {
                                  handleBlur({ target: { name: "endAt" } });
                                }}
                                onChange={(e) => {
                                  if (e) {
                                    setFieldValue("endAt", e.value);
                                    setEndDate(e.value);
                                  }
                                }}
                              />
                              <span className="input-box-icon">
                                <Calendar4 />
                              </span>
                              <ErrorMessage
                                name="endAt"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6}>
                        <Button
                          variant="primary"
                          className="btn-save save-button"
                          type="submit"
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>

                  </Modal.Body>

                </Form>
              );
            }}
          </Formik>

        </Modal>
        <Modal
          className="modal-promotion inner-modal"
          show={showPickup}
          size="md"
          onHide={pickupHandleClose}
          centered
          backdrop="static"
          keyboard={false}
        >

          <Modal.Header className="flex-modal-header pl-20">
            <Modal.Title className="modal-title  d-flex">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setPickup(false)}
              >
                <i class="fa fa-chevron-left back-arrow "></i>
              </span>
              Use Map                      </Modal.Title>
            <div class="divclose" onClick={pickupHandleClose}>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="mt-24 p-0">
            <Row>
              <Col md={12} className="px-0">
                <div
                // className="relative-map"
                // style={{
                //   background:
                //     "url(/images/heat-map.jpg) no-repeat 0px 0px",
                //   backgrounSize: "cover",
                // }}
                >
                  {isLoaded ? (
                    <>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={pickUpLatLng ? pickUpLatLng : center}
                        // defaultCenter={mapPosition}
                        zoom={14}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        id="google-map-area"
                      >

                        <div className="marker-area">
                          <Marker
                            onLoad={onLoad}
                            position={
                              pickUpLatLng ? pickUpLatLng : center
                            }
                            draggable={true}
                            onDragEnd={onMarkerDragEnd}
                          />
                        </div>
                        <>
                          <div
                            className="search-box-for-map"

                          >

                            <GooglePlacesAutocomplete
                              apiKey={googleMapsApiKey}
                              className="form-control"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: "sa",
                                },
                              }}
                              selectProps={{
                                pickUp,

                                onChange: handleChangePickUp,
                                value: pickUp,
                                placeholder: "Search location on map",
                              }}
                            />
                            {/* </StandaloneSearchBox> */}
                          </div>
                        </>
                      </GoogleMap>
                    </>
                  ) : null}

                  <div
                    className="location-search"
                    style={{ display: "none" }}
                  >
                    <Row>
                      <Col md={12} className="mb-2 px-0">

                      </Col>

                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={12} className="mt-3 px-4">
                <Button
                  variant="primary"
                  className="btn-save btn-block"
                  onClick={handleClick}
                >
                  Proceed
                </Button>
              </Col>
            </Row>
          </Modal.Body>

        </Modal>
        <DeleteModal
          clickYes={removePromo}
          showDelete={showDelete}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(Notifications));
