import React from "react";
import Button from "react-bootstrap/Button";
import refresh from "./images/refresh.svg";
import Nav from "./Nav";

function Navbar({ refreshData }) {
  return (
    <>
      <div className="navbarr">
        <div>
          <h1 className="header mt-0">Dashboard </h1>
        </div>
        <div className="d-inline-flex flex-nowrap">
          <p className="nav-btn">
            country: <span>Saudi Arabia</span>
          </p>{" "}
          <p
            className="nav-btn"
            style={{ marginLeft: "12px", cursor: "pointer" }}
            onClick={() => refreshData(true)}
          >
            <img src={refresh} alt="" />

            <span className="text-success">Refresh</span>
          </p>{" "}
        </div>
      </div>
    </>
  );
}

export default Navbar;
