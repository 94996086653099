import { useState, useRef, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./saas/cards.css";
import moment from "moment";

const Calendar = ({ handleTimeRange }) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleDateSelect = (date) => {
    setDateRange(date.range1);

    const { startDate, endDate } = date.range1;
    const sDate = moment(startDate).format("YYYY-MM-DD");
    const eDate = moment(endDate).format("YYYY-MM-DD");
    const startEndDates = { sDate, eDate };
    if (sDate !== eDate) {
      handleTimeRange(startEndDates);
    }
  };

  const selectionRange = {
    startDate: dateRange?.startDate ? dateRange.startDate : null,
    endDate: dateRange?.endDate ? dateRange.endDate : null,
  };

  const selectedRangeStyle = {
    backgroundColor: "green",
    opacity: 0.5,
    borderRadius: "3px",
  };

  const handleClick = () => {
    setPickerOpen(!pickerOpen);
  };

  const calendarStyle = {
    borderRadius: "10px",
    backgroundColor: "#f8f9fa",
  };
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setPickerOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="d-flex flex-column" ref={pickerRef}>
      <img
        className="position-relative cursor-pointer"
        onClick={handleClick}
        src={cardIcon}
        alt="card icon"
      />
      {pickerOpen && (
        <div
          className="date-range-picker-container"
          style={{
            position: "absolute",
            right: "0",
            zIndex: "100",
            marginTop: "30px",
            borderRadius: "20px",
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleDateSelect}
            rangeColors={["#13B542"]}
            selectedRangeStyle={selectedRangeStyle}
            style={calendarStyle}
          />
        </div>
      )}
    </div>
  );
};
export default Calendar;
