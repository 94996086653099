import { toast } from "react-toastify";
import { format } from "date-fns";
import { value } from "lodash/seq";
import { Link } from "react-router-dom";
import { Column } from "primereact/column";
import IConstants from "../../IConstants";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { connect, useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { _baseURL } from "../../services/APIService";
import { secret_key } from "../../services/APIService";
import { Button, Col, Modal, Row } from "react-bootstrap";
const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#fff"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};

const _saveTicketURL = _baseURL + "/api/v1/ticket/save";
const _AssignTicketURL = _baseURL + "/api/v1/assigned-to";
// const _getAllTicketsURL = _baseURL + "/api/v1/ticket/per-customer";
// https://cmsride.xintdev.com
const _getAllTicketsURL =`${_baseURL}/api/v1/ticket/by-customer`;


const _getAllEmployeeURL =
  _baseURL + "/api/v1/employee/show?secret_key=" + secret_key;
const _allDepartmentsURL = _baseURL + "/api/v1/department/all";

const _getComplainTypes =
  _baseURL + "/api/v1/ticket/get-complaint-type?secret_key=" + secret_key;

const _assingUserTicket =
  _baseURL + "/api/v1/ticket/assign-user?secret_key" + secret_key;

let ticketType = [
  { value: 1, label: "Ride Cancellation" },
  { value: 2, label: "Subscription Issue" },
  { value: 3, label: "Payment Not Received" },
];
let assignType = [
  { value: 1, label: "Rider" },
  { value: 2, label: "Driver" },
];
let PickupTicketHistory = (props) => {
  // const { riderView } = props;
  let [status, setStatus] = useState(null),
    [showTicket, setShowTicket] = useState(false),
    [selectedAssignUser, setSelectedAssignUser] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [ticketList, setTicketList] = useState(false),
    [ticketId, setTicketId] = useState(),
    [depart, setDepart] = useState(),
    [showEmployee, setShowEmployee] = useState(false),
    [departments, setDepartments] = useState([]),
    [department, setDepartment] = useState(),
    [subDepartments, setSubDepartments] = useState([]),
    [assigneeName, setAssigneeName] = useState(),
    [assignTicket, setAssignTicket] = useState(false),
    [assigneeId, setAssigneeId] = useState(false),
    [show, setShow] = useState(false),
    [submitting, isSubmitting] = useState(false),
    dt = useRef(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "creationDate",
        order: "desc",
      },
      take: 10,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    handleClose = () => {
      setShow(false);
      getDepartmentValue(2000);
      getSubDepartmentEmploye(2000);
    },
    handleShow = (rowdata) => {

      setTicketId(rowdata);
      setShow(true);
    };

  let _getAllTickets = async () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    let filterParams = JSON.parse(JSON.stringify(lazyParams));
    filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    delete filterParams["totalRecords"];
    console.log(secret_key)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        // secret_key: "xBJbW0S1OIFJl9bsjsdZ",
        secret_key,
        // contact_no: riderView?.mobileNo,
        // ride_customer_id:riderView.userId
      }),
    };
    const response = await fetch(_getAllTicketsURL, requestOptions);
    let final_data = await response.json();

    if (final_data.data) {
      lazyParams.totalRecords = final_data?.data[0]?.tickets?.length;
      setLazyParams(lazyParams);
      const sortedData = final_data?.data[0]?.tickets?.reverse();
      setTicketList(sortedData);
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    } else props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
  };

  let _manageTickets = (event) => {
    if (event.sortField) {
      lazyParams.sort = {
        field: event.sortField,
        order: event.sortOrder,
      };
    } else if (event.first || event.first === 0) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = event.first;
    } else if (event.filters && Object.keys(event.filters).length) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = 0;
      let filters = { ...lazyParams.filters };
      for (let filter in event.filters) {
        lazyParams.filters[filter] = event.filters[filter].value;
      }
      lazyParams.filters = { ...filters, ...lazyParams.filters };
    } else if (!event.filters || !Object.keys(event.filters).length) {
      lazyParams.filters = {};
    }
    setLazyParams(lazyParams);
    _getAllTickets();
  };

  let _getAllEmployees = async (id) => {
    const _getAllEmployeeURL =
      _baseURL + `/api/v1/subdepartment/${id}/employees`;
    // const _getAllEmployeeURL = `${_baseURL}s${secret_key}`;
    const requestOptions = {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_getAllEmployeeURL, requestOptions);
    let final_data = await response.json();

    return setShowEmployee(final_data.data);
  };

  useEffect(() => {
    //  _getAllTickets();
    _getAssignTicket();
    _manageTickets(lazyParams);
    // _getAllEmployees();
  }, []);

  let statusOptions = [
      { value: "A", label: "Active" },
      { value: "B", label: "Locked" },
      { value: "I", label: "Inactive" },
    ],
    /**
     * @author Shaarif
     * @param rowData
     * @returns {JSX.Element}
     * @private
     */
    handleCloseTicket = () => {
      setShowTicket(false);
    },
    handleShowTicket = () => {
      setShowTicket(true);
    },
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>

          <span className="p-input-icon-left p-ticket">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search..."
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 10,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                _manageTickets(lazyParams);
              }}
            />
          </span>

          {/* <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={handleShowTicket}
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
          </button> */}
        </div>
      );
    },
    redirectIdTemplate = (rowData) => {

      return (
        <div className="text-left">
          {rowData.id && (
            <Link
              to={{
                pathname: `/ticket-detail/${rowData.id}`,
                state: rowData,
              }}
              className="dataFnt-size"
              style={{ color: "rgba(19, 181, 66, 1)" }}
            >
              {/* {rowData.userStatus === "A" ? <img src = {rideSource} alt = "ride" className="nav_icon" /> : <img src = {whatsappSource} alt = "ride" className="nav_icon" />}; */}

              <span className="dataFnt-size" style={{ color: "#13B542" }}>
                {"AB-0010" + rowData.id + "00"}
              </span>
            </Link>
          )}
        </div>
      );
    },
    header = renderHeader(),
    _formatTime = (value) => {
      return format(new Date(value.created_at), "dd/MM/yyyy");
    },
    _formatCreatorName = (value) => {
      return value?.created_by?.name?.toUpperCase();
    },
    _ticketStatus = (value) => {
      let ticketStatus;
      switch (value.status_id) {
        case IConstants.TICKET_PENDING:
          ticketStatus = <span className="status-text-pending">PENDING</span>;
          break;
        case IConstants.TICKET_ASSIGNED:
          ticketStatus = <span className="status-text">ASSIGNED</span>;
          break;
        case IConstants.TICKET_REJECTED:
          ticketStatus = <span className="status-text-inactive">REJECTED</span>;
          break;
        case IConstants.TICKET_RESOLVED:
          ticketStatus = <span className="status-text">RESOLVED</span>;
          break;
        case IConstants.TICKET_REVERT:
          ticketStatus = <span className="status-text">REVERT</span>;
          break;
        default:
          ticketStatus = <span className="status-text-inactive">REJECTED</span>;
          break;
      }
      return ticketStatus;
    },
    _actionTab = (rowData) => {

      return (
        <>
        {IConstants.TICKET_RESOLVED===rowData.status_id ?
        "" :
        <div className="text-center">
          <Button
            className="btn btn-success dropdown-toggle"
            onClick={() => handleShow(rowData.id)}
          >
            Assign To
          </Button>
        </div>
    }
        </>
      );
    },
    filter_roles = (value) => {
      return value.roles[0].name;
    },
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          order: -1,
        },
        take: 10,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setStatus(null);
      _getAllTickets();
    };

  let _getAssignTicket = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_allDepartmentsURL, requestOptions);
    let final_data = await response.json();

    var data = final_data.data.map((departmentData) => {
  
      var tmp = {};
      tmp.value = departmentData.id;
      tmp.label = departmentData.name;
      return tmp;
    });
    setDepartments(data);
  };

  let _getSubDepartment = async (id) => {
    const _subDepartmentsURL =
      _baseURL + `/api/v1/department/${id}/subdepartments`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_subDepartmentsURL, requestOptions);
    let final_data = await response.json();
    var subDeparts = final_data.data.map((subDepartmentData) => {
      var tmp = {};
      tmp.value = subDepartmentData.id;
      tmp.label = subDepartmentData.name;
      return tmp;
    });
    setSubDepartments(subDeparts);
  };

  function jobRole() {
    return <div className=" font-weight-bolder text-dark">::::</div>;
  }
  function getDepartmentValue(e) {
    setDepart(e.value);
    _getSubDepartment(e.value);
  }
  function getSubDepartmentEmploye(e) {
    setDepartment(e.value);
    _getAllEmployees(e.value);
  }
  function getSelectionValue(e) {
    setAssigneeName(e.value.name);
    setAssigneeId(e.value.id);
  }

  function _AssigneeValue(rowData) {
    return rowData.last_ticket_assignment?.assigned_to ? (
      <div>{rowData.last_ticket_assignment?.assigned_to?.name}</div>
    ) : (
      <div>Not assigned</div>
    );
  }

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={ticketList}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No tickets(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            // sortField={lazyParams.sort.field}
            // sortOrder={lazyParams.sort.order}
            //onPage={_manageTickets}
            // onSort={_manageTickets}
            // onFilter={_manageTickets}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "150px" }}
              field="id"
              header="ID"
              body={redirectIdTemplate}
              sortable
              filter={showFilter}
              filterField="id"
              filterMatchMode="contains"
              filterPlaceholder="Search by id"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Description"
              field="description"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Creator"
              body={_formatCreatorName}
              field="created_by.name"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="User ID"
              field="customer_id"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Date & Time"
              body={_formatTime}
              field={"created_at"}
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Comment"
              field="category.title"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Assigned To"
              body={_AssigneeValue}
              field="last_ticket_assignment.assigned_to"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Status"
              body={_ticketStatus}
              field="status_id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Resolver’s Comment"
              field="last_ticket_assignment.description"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Action"
              body={_actionTab}
            />
          </DataTable>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Formik
          initialValues={{
            department_id: 1,
          }}
          onSubmit={async (values) => {
            isSubmitting(true);
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                assigned_to: assigneeId,
                secret_key: secret_key,
                ticket_id: ticketId,
              }),
            };
            const response = await fetch(_assingUserTicket, requestOptions);
            let final_data = await response.json();

            if (final_data.success === true)
              toast.success("Ticket Assigned  successfully!");
            else toast.error("Ops! Ticket not assigned.");
            _getAllTickets();
            handleClose();

            isSubmitting(false);
            setAssignTicket();
          }}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">Assign To</Modal.Title>
                  <div className="divclose" onClick={handleClose}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-3">
                  <Row className="justify-content-center">
                  <Col
                      md={12}
                      className="d-flex justify-content-between align-items-center my-2 px-1 no-gutters"
                    >
                      <Col md={6} className="mr-1">
                      <div className="mb-3 form-label-group ">
                        <Select
                          // value={departments}
                          className = "height-50 br-10 bg-color-select"
                          options={departments}
                          onChange={(e) => getDepartmentValue(e)}
                          placeholder="Department"
                          style={{ width: "100%" }}
                        />
                        <label>Department</label>
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-3 form-label-group ">

                        <Select
                          // value={subDepartments}
                          className = "height-50 br-10 bg-color-select"
                          options={subDepartments}
                          onChange={(e) => getSubDepartmentEmploye(e)}
                          placeholder="Sub-Department"
                          style={{ width: "100%" }}
                        />
                        <label>Sub-Department</label>

                        </div>
                      </Col>
                    </Col>

                    <Col md={12} className="px-1 mb-2">
                      <div>
                        <InputText
                          type="search"
                          placeholder="Search..."
                          className="input-modal"
                          value={lazyParams.keyword}
                          onInput={(e) => {
                            lazyParams = {
                              ...lazyParams,
                              skip: 0,
                              take: 10,
                              keyword: e.target.value,
                            };
                            setLazyParams(lazyParams);
                            _getAllTickets();
                          }}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="px-1">
                      <DataTable
                        className="datatables-modal dt-custom"
                        value={showEmployee}
                        selectionMode="single"
                        selection={selectedAssignUser}
                        onSelectionChange={(e) => getSelectionValue(e)}
                        dataKey="id"
                        responsiveLayout="scroll"
                      >
                        <Column
                          selectionMode="single"
                          headerStyle={{ width: "3em" }}
                          className="modal-table-header"
                        ></Column>
                        <Column field="id" header="ID"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column
                          header="Role"
                          className="text-right arabic-fonts font-weight-bolder"
                          body={jobRole}
                        ></Column>
                      </DataTable>
                    </Col>
                    <Col md={9}>
                      {/*<Button type="submit" className="btn btn-success btn-block btn-lg">*/}
                      {/*    Assign*/}
                      {/*</Button>*/}

                      <Button
                        disabled={submitting}
                        type="submit"
                        className="btn btn-success btn-block btn-lg"
                      >
                        {submitting && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        Assign
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        className="Create-Ticket"
        show={showTicket}
        onHide={handleCloseTicket}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={{
            ticket_type: "",
            name: "",
            phone: "",
            comment: "",
            assign_type: "",
          }}
          onSubmit={async (values) => {
            isSubmitting(true);
            let dept_id = values.ticket_type;
            let description = values.comment;
            let name = values.name;
            let phone = values.phone;
            let assign_type = values.assign_type;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                category_id: dept_id,
                description: description,
                secret_key: secret_key,
                name: name,
                contact_no: phone,
                type: assign_type,
              }),
            };
            const response = await fetch(_saveTicketURL, requestOptions);
            let final_data = await response.json();
            if (final_data.success === true)
              toast.success("Ticket created successfully!");
            else toast.error("Opsss! Ticket not created.");
            isSubmitting(false);
            handleCloseTicket();
          }}
        >
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Create New Ticket
                  </Modal.Title>
                  <div className="divclose" onClick={handleCloseTicket}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Ticket Type"
                          name="ticket_type"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "ticket_type" } });
                          }}
                          onChange={async (selectedOption) =>
                            await setFieldValue(
                              "ticket_type",
                              selectedOption.value
                            )
                          }
                          options={ticketType}
                        />
                        <label>Ticket Type</label>
                        <ErrorMessage
                          name="ticket_type"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Ticket Type"
                          name="assign_type"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "assign_type" } });
                          }}
                          onChange={async (selectedOption) =>
                            await setFieldValue(
                              "assign_type",
                              selectedOption.value
                            )
                          }
                          options={assignType}
                        />
                        <label>User Type</label>
                        <ErrorMessage
                          name="assign_type"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Name"
                        />
                        <label htmlFor="content">Name</label>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="number"
                          name="phone"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Phone"
                        />
                        <label htmlFor="content">Contact</label>
                        <ErrorMessage
                          name="Phone"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="text"
                          as="textarea"
                          name="comment"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Name"
                        />
                        <label htmlFor="content">Comment</label>
                        <ErrorMessage
                          name="comment"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Button
                        disabled={submitting}
                        variant="primary"
                        type="submit"
                        className="btn-save btn-block"
                      >
                        {submitting && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        Create Ticket
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(connect()(PickupTicketHistory));

