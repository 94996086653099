import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import APIService from "../services/APIService";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";

let Users = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [users, setUsers] = useState([]),
    [user, setUser] = useState(null),
    validationSchema = Yup.object().shape({
      reason: Yup.string()
        .trim("Please remove whitespaces")
        .strict(false)
        .required("Please enter reason")
        .nullable(),
    }),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    statusOptions = [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
    [status, setStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    getUsers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post(`admin/chat-user-list`, filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            setUsers(response.data.data.users);
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageUsers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          lazyParams.filters[filter] =
            filter === "userId"
              ? Number(event.filters[filter].value)
              : event.filters[filter].value;
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getUsers();
    },
    inactiveUser = (formValues) => {
      formValues["status"] = user.status === 1 ? 0 : 1;
      APIService.patch("admin/chat-user-status/" + user._id, formValues).then(
        (response) => {
          if (response.data && response.data.statusCode === 200) {
            toast.success("User updated successfully");
            setUser(null);
            getUsers();
          }
        }
      );
    };

  useEffect(() => {
    getUsers();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              className="inputwdth"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getUsers();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn btnShowHide"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setTimeout(() => {
        getUsers();
      }, 1500);
    };
  return (
    <div className="rider-listing">
      <span className="title">Users</span>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={users}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="900px"
            paginator
            emptyMessage="No User(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={manageUsers}
            onSort={manageUsers}
            onFilter={manageUsers}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "120px" }}
              header="Id"
              field="userId"
              body={(user) => (
                <Link to={`/user/${user._id}`} className="dataFnt-size">
                  {user.userId}
                </Link>
              )}
              sortable
              filter={showFilter}
              filterPlaceholder="Search by id"
            />
            <Column
              className="text-left"
              style={{ fontSize: "12px", width: "150px" }}
              field="fullName"
              header="User Name"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by user name"
            />

            <Column
              className="text-left"
              style={{ fontSize: "12px", width: "150px" }}
              header="Email"
              field="emailId"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by email"
            />

            <Column
              className="text-left"
              style={{ fontSize: "12px", width: "150px" }}
              field="mobileNo"
              header="Mobile No"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by Mobile Number"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "150px" }}
              field="status"
              header="Status"
              body={(user) => (user.status === 0 ? "Blocked" : "Active")}
              sortable
              filter={showFilter}
              filterElement={
                <Dropdown
                  value={status}
                  options={statusOptions}
                  onChange={(event) => {
                    setStatus(event.value);
                    lazyParams.filters = {
                      ...lazyParams.filters,
                      status: event.value,
                    };
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getUsers();
                    }, 500);
                  }}
                  placeholder="Select Status"
                  className="p-column-filter"
                  showClear
                />
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "10px", width: "120px" }}
              header="Action"
              body={(user) => (
                <InputSwitch
                  checked={user.status === 0 ? false : true}
                  onChange={(e) => {
                    setUser(user);
                  }}
                />
              )}
            />
          </DataTable>
        </div>
        <Modal show={user?._id} onHide={() => setUser(null)} centered>
          <Formik
            initialValues={{ reason: "" }}
            validationSchema={validationSchema}
            onSubmit={inactiveUser}
          >
            {() => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="title">
                      Are you sure you want to{" "}
                      {user?.status ? "block" : "unblock"} this user?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group mt-1">
                      <Field
                        id="reason"
                        type="text"
                        name="reason"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Enter Reason"
                      />
                      <label htmlFor="reason">Enter Reason</label>
                      <ErrorMessage
                        name="reason"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer mb-3">
                    <Button
                      variant="primary"
                      type="submit"
                      className="save-button btn-save border-radius-12"
                    >
                      <span className="btn-text">
                        {user?.status ? "Block" : "Unblock"}{" "}
                      </span>
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </div>
  );
};

export default React.memo(connect()(Users));
