/**
 * @author Shaarif <m.shaarif@xintsolutions.com>
 * @type {{TICKET_REJECTED: number, TICKET_RESOLVED: number, TICKET_INVALID: number, TICKET_PENDING: number, TICKET_REVERT: number, TICKET_ASSIGNED: number,PROMO_CODES_DATA:any,SUCCESS_AND_FAILED_TABS_DATA:any,MARKETING_NOTIFICATIONS_DATA:any}}
 */
const IConstants = {
    TICKET_PENDING  : 1,
    TICKET_ASSIGNED : 2,
    TICKET_REJECTED : 3,
    TICKET_RESOLVED : 4,
    TICKET_INVALID  : 5,
    TICKET_REVERT   : 6,
    MARKETING_NOTIFICATIONS_DATA:[
        {
          "id": 1,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 2,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 3,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 4,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 5,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 6,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 7,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 8,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 9,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        },
        {
          "id": 10,
          "name": "John Doe",
          "name_arabic": "عَبْدُ ",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          "content_arabic": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
          "receiver": "ali",
          "receiver_arabic": "عَبْدُ ",
          "schedule": "2023-03-24T17:25",
          "sendVia": "watsapp",
          "fileupload": "Invalid Image"
        }
      ],
      
    PROMO_CODES_DATA:[
        {
          name: "Alistair",
          noOfTimesUsed: 36,
          success: 26,
          failed: 54,
        },
        {
          name: "Margery",
          noOfTimesUsed: 97,
          success: 76,
          failed: 5,
        },
        {
          name: "Renate",
          noOfTimesUsed: 22,
          success: 70,
          failed: 34,
        },
        {
          name: "Esther",
          noOfTimesUsed: 77,
          success: 98,
          failed: 30,
        },
        {
          name: "Trenna",
          noOfTimesUsed: 52,
          success: 70,
          failed: 7,
        },
        {
          name: "Marten",
          noOfTimesUsed: 20,
          success: 31,
          failed: 59,
        },
        {
          name: "Daniela",
          noOfTimesUsed: 13,
          success: 95,
          failed: 71,
        },
        {
          name: "Amery",
          noOfTimesUsed: 99,
          success: 43,
          failed: 18,
        },
        {
          name: "Gino",
          noOfTimesUsed: 15,
          success: 3,
          failed: 35,
        },
        {
          name: "Rube",
          noOfTimesUsed: 17,
          success: 66,
          failed: 98,
        },
        {
          name: "Zane",
          noOfTimesUsed: 75,
          success: 58,
          failed: 99,
        },
        {
          name: "Titos",
          noOfTimesUsed: 4,
          success: 82,
          failed: 91,
        },
        {
          name: "Cort",
          noOfTimesUsed: 35,
          success: 85,
          failed: 69,
        },
        {
          name: "Lynsey",
          noOfTimesUsed: 74,
          success: 35,
          failed: 64,
        },
        {
          name: "Bone",
          noOfTimesUsed: 47,
          success: 43,
          failed: 66,
        },
        {
          name: "Shamus",
          noOfTimesUsed: 9,
          success: 74,
          failed: 39,
        },
        {
          name: "Ardra",
          noOfTimesUsed: 59,
          success: 61,
          failed: 34,
        },
        {
          name: "Hoebart",
          noOfTimesUsed: 41,
          success: 53,
          failed: 50,
        },
        {
          name: "Nicolai",
          noOfTimesUsed: 86,
          success: 49,
          failed: 44,
        },
        {
          name: "Bradly",
          noOfTimesUsed: 4,
          success: 81,
          failed: 50,
        },
        {
          name: "Morly",
          noOfTimesUsed: 20,
          success: 68,
          failed: 63,
        },
        {
          name: "Catrina",
          noOfTimesUsed: 30,
          success: 73,
          failed: 45,
        },
      ],
       SUCCESS_AND_FAILED_TABS_DATA : [
        {
          externalId: 11,
          name: "Cart",
          user: "Bulbeck",
          mobileNo: "405-524-9007",
          createdAt: "8/1/2022",
          driver: "http://dummyimage.com/234x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 57,
          name: "Arnaldo",
          user: "Richichi",
          mobileNo: "645-660-2091",
          createdAt: "11/15/2022",
          driver: "http://dummyimage.com/166x100.png/ff4444/ffffff",
        },
        {
          externalId: 25,
          name: "Bryant",
          user: "Hurford",
          mobileNo: "618-459-8906",
          createdAt: "7/23/2022",
          driver: "http://dummyimage.com/175x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 43,
          name: "Nola",
          user: "Whal",
          mobileNo: "382-100-7318",
          createdAt: "4/26/2022",
          driver: "http://dummyimage.com/230x100.png/cc0000/ffffff",
        },
        {
          externalId: 38,
          name: "Shaine",
          user: "Kinnier",
          mobileNo: "898-600-4289",
          createdAt: "4/4/2022",
          driver: "http://dummyimage.com/170x100.png/dddddd/000000",
        },
        {
          externalId: 30,
          name: "Aida",
          user: "Luten",
          mobileNo: "643-366-3885",
          createdAt: "12/12/2022",
          driver: "http://dummyimage.com/156x100.png/cc0000/ffffff",
        },
        {
          externalId: 38,
          name: "Joela",
          user: "Golt",
          mobileNo: "225-423-6505",
          createdAt: "12/16/2022",
          driver: "http://dummyimage.com/116x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 4,
          name: "Greggory",
          user: "Moyse",
          mobileNo: "352-294-9436",
          createdAt: "8/9/2022",
          driver: "http://dummyimage.com/172x100.png/ff4444/ffffff",
        },
        {
          externalId: 12,
          name: "Maurine",
          user: "Skerrett",
          mobileNo: "480-365-6805",
          createdAt: "11/21/2022",
          driver: "http://dummyimage.com/124x100.png/cc0000/ffffff",
        },
        {
          externalId: 96,
          name: "Peri",
          user: "Plackstone",
          mobileNo: "786-490-6777",
          createdAt: "1/13/2023",
          driver: "http://dummyimage.com/150x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 64,
          name: "Heddie",
          user: "Pudney",
          mobileNo: "162-236-2879",
          createdAt: "9/16/2022",
          driver: "http://dummyimage.com/164x100.png/dddddd/000000",
        },
        {
          externalId: 1,
          name: "Saunderson",
          user: "Oliphard",
          mobileNo: "269-842-1963",
          createdAt: "10/3/2022",
          driver: "http://dummyimage.com/133x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 69,
          name: "Samuel",
          user: "Curd",
          mobileNo: "896-173-8982",
          createdAt: "6/27/2022",
          driver: "http://dummyimage.com/198x100.png/dddddd/000000",
        },
        {
          externalId: 73,
          name: "Omero",
          user: "Toun",
          mobileNo: "237-674-6158",
          createdAt: "12/19/2022",
          driver: "http://dummyimage.com/121x100.png/ff4444/ffffff",
        },
        {
          externalId: 91,
          name: "Tressa",
          user: "Lindner",
          mobileNo: "641-137-2305",
          createdAt: "3/27/2023",
          driver: "http://dummyimage.com/210x100.png/5fa2dd/ffffff",
        },
        {
          externalId: 28,
          name: "Hercule",
          user: "Renackowna",
          mobileNo: "533-905-9993",
          createdAt: "9/28/2022",
          driver: "http://dummyimage.com/134x100.png/cc0000/ffffff",
        },
        {
          externalId: 69,
          name: "Noelle",
          user: "Ciccarello",
          mobileNo: "319-638-6643",
          createdAt: "9/21/2022",
          driver: "http://dummyimage.com/200x100.png/ff4444/ffffff",
        },
        {
          externalId: 92,
          name: "Cahra",
          user: "McCrow",
          mobileNo: "568-607-6066",
          createdAt: "10/1/2022",
          driver: "http://dummyimage.com/105x100.png/cc0000/ffffff",
        },
        {
          externalId: 45,
          name: "Tulley",
          user: "Smurfitt",
          mobileNo: "643-223-5141",
          createdAt: "5/15/2022",
          driver: "http://dummyimage.com/166x100.png/cc0000/ffffff",
        },
        {
          externalId: 39,
          name: "Garland",
          user: "Clelland",
          mobileNo: "717-466-9077",
          createdAt: "9/18/2022",
          driver: "http://dummyimage.com/155x100.png/cc0000/ffffff",
        },
      ],
}

export default IConstants;