import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Logoicon from "../components/UI/Logoicon";
import "../sass/Login.sass";
// import { toast } from "react-toastify";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import AuthService from "../services/AuthService";
import logo from "../assest/images/ride.svg";
import { _baseURL } from "../services/APIService"
import { secret_key } from "../services/APIService";
import { Toast } from "bootstrap";

const _updatePassword = _baseURL + "/api/v1/employee/update-password?secret_key=" + secret_key;
const email = localStorage.getItem("email");

const _updatePass = async (formField, cp) => {
  const requestOptions = {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email,
      password: formField["password"],
      password_confirmation: cp
    }),
  };
  const response = await fetch(_updatePassword, requestOptions);
  const result = await response.json();
  if (result.success === true) {
    toast.success("Password changed successfully");
  }

}
let NewPassword = (props) => {
  let [showNewPassword, setShowNewPassword] = useState(false),
    [showConfirmPassword, setShowConfirmPassword] = useState(false),
    [changePas, setChangePass] = useState(true),
    [changeConfirm, setChangeConfirm] = useState(true),
    resetPassword = (formField) => {
      let { token } = props.match.params;
      if (token) {
        let cnfrmPass = formField["confirmPassword"];
        delete formField["confirmPassword"];
        formField["token"] = token;
        AuthService.post("admin/reset-password", formField)
          .then((response) => {
            if (response.data.statusCode === 200) {

              _updatePass(formField, cnfrmPass)

              toast.success("Password changed successfully");
              props.history.push("/login");
            } else {
              toast.error("Something went wrong try again");
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              toast.error(error.response.data.message);
            }
          });
      } else if (Boolean(localStorage.getItem("isFirstLogin"))) {
        AuthService.post(
          "admin/set-password/" + localStorage.getItem("userID"),
          formField
        )
          .then((response) => {
            if (response.data.statusCode === 200) {
              AuthService.post("admin/login", {
                email: localStorage.getItem("email"),
                password: formField.password,
              })
                .then((response) => {
                  if (response && response.data.statusCode === 200) {
                    let user = response.data.data;
                    localStorage.removeItem("isFirstLogin");
                    localStorage.setItem("userID", user.id);
                    localStorage.setItem("token", user.authToken);
                    localStorage.setItem("email", user.email);
                    localStorage.setItem("role", user.role.title);
                    localStorage.setItem(
                      "access",
                      btoa(JSON.stringify(user.role.capabilites))
                    );
                    localStorage.setItem(
                      "profileImage",
                      user.profileImageUrl ? user.profileImageUrl : ""
                    );
                    localStorage.setItem("fullName", user.fullName);
                    if (user.role?.capabilites.indexOf("/dashboard") > -1) {
                      window.location.pathname = "/dashboard";
                    } else {
                      window.location.pathname = `${user.role?.capabilites[0]}`;
                    }
                  }
                })
                .catch((error) => {
                  if (error.response && error.response.data) {
                    toast.error(error.response.data.message);
                  }
                });
            } else {
              toast.error("Something went wrong try again");
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              toast.error(error.response.data.message);
            }
          });
      }
    },
    validationSchema = Yup.object().shape({
      password: Yup.string().required("Please enter your password")
        .min(7, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password must have at least (one capital, one special symbol and one number"
        ),
      confirmPassword: Yup.string()
        .required("Please enter your confirm password")
        /* .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Min 8 and Max 15 character with at least (one capital, one special symbol and one number"
        ) */
        .when("password", {
          is: (password) => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
    });

  return (
    <div className="login__section d-flex justify-content-center align-items-center">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      {/* <svg
        className="left__top"
        xmlns="http://www.w3.org/2000/svg"
        width="631"
        height="504"
        fill="none"
        viewBox="0 0 631 504"
      >
        <g fill="#fff" opacity="0.04">
          <path d="M34.284 224.283l1.317 2.641-1.317-2.641zM225.922 326.637c-26.342-8.584-50.708-22.451-70.464-40.941l-23.708 21.131c23.708 22.452 52.025 39.621 84.294 49.526 54.66 17.169 112.612 11.886 162.662-15.188 46.757-24.433 81.66-64.714 100.099-113.579l-30.293-9.245c-15.805 40.941-45.44 73.958-84.953 95.089-41.488 23.112-90.88 27.735-137.637 13.207zM468.925 52.593C442.583 1.747 397.802-35.233 343.142-52.402c-31.61-9.905-63.879-11.886-95.489-7.264l7.244 31.037c26.342-3.962 52.684-1.321 79.026 6.603 23.049 7.264 44.123 18.49 62.562 33.678 18.439 15.188 33.586 33.677 44.781 55.469 19.757 37.639 25.684 80.562 17.123 121.503l30.293 9.245c4.61-21.791 5.927-43.583 3.951-65.374-1.976-27.735-9.878-54.809-23.708-79.902zM224.606-22.025L217.361-52.4c-12.512 3.962-25.024 9.244-36.878 15.188C129.774-10.8 92.896 34.104 75.773 88.913 58.651 143.721 63.92 201.832 90.92 252.018c5.927 11.226 12.512 21.791 20.415 31.696l23.708-21.131c-5.927-7.924-11.195-16.508-15.805-25.093-22.391-42.922-27-92.448-13.171-139.333 14.488-46.884 46.098-85.184 88.904-107.636 9.878-4.622 19.756-9.245 29.635-12.547zM126.481-164.659l-21.073 72.638v.66l-5.927 21.132v.66C35.601-16.74-4.569 62.501-4.569 152.307c0 30.376 4.61 60.091 13.17 87.166l-6.585 21.791-21.074 72.638c0-.66-.658-.66-.658-1.321-.659-1.32-1.976-2.641-2.634-4.622 0 0 0-.66-.66-.66 0 0 0-.661-.658-.661-.658-1.32-1.975-3.301-2.634-4.622l-1.976-3.962v-.661c-.658-.66-.658-1.32-1.317-2.641 0-.66-.658-1.321-.658-1.321-.659-1.32-1.317-1.981-1.976-3.301l-1.975-3.962-1.976-3.963c-.659-1.981-1.317-3.301-2.634-5.282-.659-1.321-1.317-2.642-1.317-3.302-.659-1.321-1.318-1.981-1.318-3.302l-1.975-3.962c-.659-1.32-1.317-2.641-1.317-3.962 0-.66 0-.66-.659-1.321 0-.66 0-.66-.658-1.32-.659-1.321-.659-1.981-1.317-3.302-59.928-158.483 1.975-341.399 152.783-429.224 5.927-4.623 13.171-8.585 21.073-11.887z"></path>
          <path d="M566.393 141.081l-31.61-33.017c-2.635-15.188-6.586-29.715-11.854-44.243C498.562-4.195 445.879-59.003 379.365-87.4c-13.829-5.942-28.318-10.565-43.464-13.866l-31.611-33.018-13.829-14.527h-11.854c-53.343 0-104.051 13.867-147.515 38.96l-5.927 19.15-13.171 44.243c-11.854 9.905-22.39 20.47-32.269 32.357C42.188 30.804 19.797 88.914 19.797 152.307c0 10.566.659 21.792 1.976 32.357 1.976 15.188 5.268 30.376 9.878 44.904l-13.17 43.582-5.928 19.15c25.684 49.526 65.197 90.468 112.612 118.862l19.098-4.622 44.123-10.565c13.829 5.282 28.976 9.244 43.464 11.886 14.488 2.641 30.293 3.962 45.44 3.962 58.611 0 113.27-19.811 156.735-52.828 11.853-9.245 23.049-19.15 33.586-30.376l44.122-10.565 19.098-4.623c28.318-45.563 45.44-99.051 46.099-157.162l-10.537-15.188zm-113.929 190.84c-12.512 12.547-27.001 23.112-42.147 32.357-38.196 23.772-82.319 36.979-130.393 36.979-6.585 0-13.171 0-19.098-.66-17.781-1.321-35.561-4.622-52.684-9.905-83.635-25.093-148.832-93.769-169.905-179.614-3.952-17.169-6.586-34.998-7.244-53.488v-5.943c0-52.167 15.805-100.373 42.805-139.993 9.879-15.188 21.732-28.395 34.903-40.942 44.782-42.262 104.71-68.676 171.223-68.676 23.708 0 47.416 3.302 69.148 9.906 17.122 5.282 33.586 11.886 49.391 20.47C444.561-42.495 481.44-3.534 504.489 44.01c7.903 15.849 13.83 33.018 17.781 50.187 4.61 18.489 6.586 37.639 6.586 57.45 0 71.317-28.976 134.71-76.392 180.274z"></path>
          <path d="M630.931 152.306c0 8.584 0 16.508-.659 25.093l-52.025-54.809-15.805-16.508C543.344-10.139 454.44-103.248 340.511-127.681l-15.805-16.508-52.026-54.809h7.244c194.272-.66 351.007 156.502 351.007 351.304zM587.466 322.015c-1.317 1.981-2.635 4.622-3.952 6.603-57.952 100.373-158.051 161.785-264.736 173.671-1.317 0-3.293.661-4.61.661h-8.561c-67.831 5.282-138.296-9.245-202.175-46.225-7.244-3.962-14.488-8.584-21.073-13.206l73.099-17.83 21.732-5.283c31.61 11.887 65.854 18.49 101.416 18.49 7.244 0 15.147 0 22.391-.66 3.293 0 6.585-.661 10.537-1.321 67.83-7.924 131.709-40.281 179.125-92.448h.659l21.732-5.283h.658l73.758-17.169z"></path>
        </g>
      </svg>
      <svg
        className="right__bottom"
        width="660"
        height="648"
        viewBox="0 0 660 648"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.04">
          <path
            d="M145.217 542.475L147.051 545.86L145.217 542.475Z"
            fill="white"
          />
          <path
            d="M412.076 673.651C375.395 662.649 341.464 644.877 313.953 621.181L280.94 648.262C313.953 677.036 353.386 699.04 398.321 711.734C474.435 733.738 555.135 726.967 624.83 692.269C689.939 660.957 738.543 609.333 764.22 546.707L722.036 534.859C700.027 587.329 658.76 629.644 603.738 656.725C545.964 686.345 477.186 692.269 412.076 673.651Z"
            fill="white"
          />
          <path
            d="M750.462 322.439C713.78 257.275 651.421 209.882 575.307 187.879C531.289 175.184 486.354 172.646 442.336 178.57L452.424 218.345C489.105 213.267 525.787 216.653 562.468 226.808C594.565 236.117 623.91 250.504 649.587 269.969C675.264 289.434 696.356 313.13 711.946 341.057C739.457 389.296 747.71 444.305 735.789 496.775L777.973 508.623C784.392 480.695 786.226 452.768 783.475 424.84C780.724 389.296 769.719 354.598 750.462 322.439Z"
            fill="white"
          />
          <path
            d="M410.243 226.81L400.155 187.88C382.731 192.958 365.308 199.728 348.801 207.345C278.189 241.197 226.835 298.745 202.992 368.987C179.149 439.229 186.485 513.702 224.084 578.021C232.337 592.408 241.507 605.948 252.512 618.643L285.525 591.561C277.272 581.406 269.936 570.404 263.516 559.402C232.337 504.393 225.918 440.921 245.176 380.835C265.35 320.748 309.368 271.663 368.976 242.889C382.731 236.965 396.487 231.041 410.243 226.81Z"
            fill="white"
          />
          <path
            d="M273.603 44.0115L244.258 137.104V137.95L236.005 165.031V165.877C147.052 233.581 91.1126 335.136 91.1126 450.231C91.1126 489.161 97.5319 527.244 109.453 561.942L100.283 589.869L70.9378 682.961C70.9378 682.115 70.0207 682.115 70.0207 681.269C69.1037 679.576 67.2697 677.884 66.3526 675.345C66.3526 675.345 66.3526 674.498 65.4355 674.498C65.4355 674.498 65.4355 673.652 64.5184 673.652C63.6014 671.96 61.7674 669.421 60.8503 667.728C59.9333 666.035 59.0162 664.343 58.0992 662.65V661.804C57.1821 660.958 57.1822 660.111 56.2651 658.419C56.2651 657.573 55.348 656.726 55.348 656.726C54.431 655.034 53.5141 654.187 52.597 652.495C51.68 650.802 50.7629 649.11 49.8459 647.417C48.9288 645.725 48.0118 644.032 47.0947 642.339C46.1777 639.8 45.2607 638.108 43.4266 635.569C42.5096 633.876 41.5924 632.184 41.5924 631.338C40.6754 629.645 39.7584 628.799 39.7584 627.106C38.8414 625.414 37.9243 623.721 37.0073 622.028C36.0902 620.336 35.1732 618.643 35.1732 616.951C35.1732 616.104 35.1733 616.104 34.2562 615.258C34.2562 614.412 34.2562 614.412 33.3392 613.565C32.4221 611.873 32.4221 611.027 31.5051 609.334C-51.9455 406.224 34.2562 171.801 244.258 59.2447C252.512 53.3207 262.599 48.243 273.603 44.0115Z"
            fill="white"
          />
          <path
            d="M886.187 435.844L842.169 393.53C838.501 374.065 832.998 355.447 825.662 336.828C791.732 249.66 718.368 179.418 625.747 143.028C606.49 135.411 586.315 129.487 565.223 125.256L521.205 82.941L501.947 64.3226C496.445 64.3226 490.943 64.3226 485.44 64.3226C411.16 64.3226 340.548 82.0947 280.024 114.254L271.77 138.796L253.429 195.498C236.923 208.192 222.25 221.733 208.495 236.966C156.223 294.514 125.044 368.987 125.044 450.231C125.044 463.772 125.961 478.159 127.795 491.7C130.546 511.164 135.131 530.629 141.551 549.247L123.21 605.103L114.956 629.645C150.721 693.117 205.743 745.587 271.77 781.977L298.364 776.053L359.806 762.513C379.064 769.283 400.156 774.361 420.331 777.746C440.506 781.131 462.514 782.824 483.606 782.824C565.223 782.824 641.337 757.435 701.862 715.121C718.368 703.272 733.958 690.578 748.631 676.191L810.072 662.65L836.667 656.726C876.099 598.332 899.942 529.783 900.859 455.309L886.187 435.844ZM727.539 680.423C710.115 696.502 689.94 710.043 668.848 721.891C615.66 752.357 554.218 769.283 487.275 769.283C478.104 769.283 468.934 769.283 460.68 768.437C435.92 766.744 411.16 762.513 387.317 755.742C270.853 723.583 180.066 635.569 150.721 525.551C145.219 503.548 141.551 480.698 140.634 457.002C140.634 454.463 140.634 451.924 140.634 449.385C140.634 382.528 162.643 320.749 200.241 269.971C213.997 250.507 230.503 233.581 248.844 217.501C311.203 163.339 394.654 129.487 487.275 129.487C520.288 129.487 553.301 133.718 583.564 142.181C607.407 148.952 630.333 157.415 652.342 168.416C716.534 200.575 767.889 250.507 799.985 311.44C810.989 331.751 819.243 353.754 824.745 375.758C831.164 399.454 833.915 423.996 833.915 449.385C833.915 540.785 793.566 622.028 727.539 680.423Z"
            fill="white"
          />
          <path
            d="M976.057 450.229C976.057 461.231 976.057 471.387 975.14 482.389L902.693 412.146L880.685 390.989C854.09 242.042 730.29 122.715 571.642 91.4021L549.633 70.2448L477.187 0.00265714C480.855 0.00265714 484.524 0.00265714 487.275 0.00265714C757.801 -0.843634 976.057 200.574 976.057 450.229Z"
            fill="white"
          />
        </g>
      </svg> */}

      <div className="login__box d-flex flex-column justify-content-between align-items-center">
        <div className="logo">
          <div >
            <img src={logo} alt="ride" style={{ width: "100px", height: "50px" }} />
          </div>
          {/* <Logoicon />
          <svg
            className="logo-text"
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="26"
            fill="none"
            viewBox="0 0 56 26"
          >
            <path
              fill="#4F4F4F"
              d="M.862 2.458v18.117h.217a2.265 2.265 0 002.268-2.268V.215h-.242A2.255 2.255 0 00.862 2.458zM12.682 5.112C8.316 5.112 4.77 8.658 4.77 13c0 4.366 3.546 7.888 7.888 7.888 4.342 0 7.912-3.522 7.912-7.888 0-4.342-3.522-7.888-7.888-7.888zm0 13.46a5.55 5.55 0 11.002-11.098 5.55 5.55 0 01-.002 11.098zM29.858 5.112c-4.367 0-7.913 3.546-7.913 7.888 0 4.366 3.546 7.888 7.888 7.888 4.342 0 7.888-3.546 7.888-7.888.025-4.342-3.497-7.888-7.863-7.888zm0 13.46a5.55 5.55 0 11.001-11.098 5.55 5.55 0 01-.002 11.098zM47.25 5.112c-4.294 0-7.792 3.425-7.888 7.695v12.978h.145a2.203 2.203 0 002.195-2.195v-4.945a7.881 7.881 0 005.548 2.267c4.366 0 7.888-3.546 7.888-7.888 0-4.366-3.546-7.912-7.888-7.912zm0 13.46a5.549 5.549 0 11.002-11.098 5.549 5.549 0 01-.002 11.098z"
            ></path>
          </svg> */}
        </div>
        <div className="centered">
          <h1 className="text-center">New Password</h1>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={resetPassword}
          >
            {() => {
              return (
                <Form className="login-form">
                  <div className="form-group new_password form-label-group">
                    <Field
                      type={showNewPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="New Password"
                      onFocus={() => setChangePass(false)}
                      onBlur={e => (e.target.value == "") ? setChangePass(true) : setChangePass(false)}
                    />
                    <label htmlFor="password">New Password</label>
                    {showNewPassword ? (
                      <EyeFill
                        className="eye-position"
                        style={{ color: changePas ? "white" : "black" }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    ) : (
                      <EyeSlashFill
                        className="eye-position"
                        style={{ color: changePas ? "white" : "black" }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    )}
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>

                  <div className="form-group new_password form-label-group">
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      className="form-control"
                      name="confirmPassword"
                      id="confirmPassword"
                      onFocus={() => setChangeConfirm(false)}
                      onBlur={e => (e.target.value == "") ? setChangeConfirm(true) : setChangeConfirm(false)}
                    />
                    <label htmlFor="confirmPassword">
                      Confirm New Password
                    </label>
                    {showConfirmPassword ? (
                      <EyeFill
                        className="eye-position"
                        style={{ color: changeConfirm ? "white" : "black" }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    ) : (
                      <EyeSlashFill
                        className="eye-position"
                        style={{ color: changeConfirm ? "white" : "black" }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "15px",
                      padding: "0 20px",
                      color: "white",
                    }}
                    className="text-white"
                  >
                    <b>Hint :</b> Minimum 8 characters including at least 1
                    uppercase letter, one special character, and one Numeric
                    value
                  </p>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="continue mt-0"
                    >
                      Save and Sign In
                      <svg
                        className="left__arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="27"
                        fill="none"
                        viewBox="0 0 25 27"
                      >
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          d="M6.184 13.26H18.26M12.222 6.927l6.039 6.334-6.039 6.334"
                        ></path>
                      </svg>
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NewPassword);
