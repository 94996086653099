import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { CalendarRange } from "react-bootstrap-icons";
import Rating from "react-rating";
import APIService from "../../services/APIService";
import saveAs from "save-as";
import moment from "moment";

let ReportsRiderListing = (props) => {
  let [riders, setRiders] = useState(null),
    [creationDate, setCreationDate] = useState([]),
    [captainOption, setCaptainOption] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [selectedRiders, setSelectedRiders] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    statusOptions = [
      { value: "A", label: "Active" },
      { value: "B", label: "Locked" },
      { value: "I", label: "Inactive" },
    ],
    captainOptions = [
      { label: "Yes", value: 2 },
      { label: "No", value: 1 },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "creationDate",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getRiders = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/reports/riders", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setRiders(response.data.data.riders);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getRiders();
    },
    exportRiders = () => {
      let riders = [
        "Rider Id",
        "Rider Full Name",
        "Overall Rating",
        "Email",
        "Mobile Number",
        "Date & Time",
        "Total Rides",
        "Total Upcoming Rides",
        "Captain",
        "Status",
      ];
      riders = riders.join(",") + "\r\n";

      if (selectedRiders.length) {
        selectedRiders.forEach((rider) => {
          let status = "";
          if (rider.userStatus === "A") {
            status = "Active";
          } else if (rider.userStatus === "L") {
            status = "Locked";
          } else {
            status = "Inactive";
          }
          riders +=
            [
              "'" + rider.userId,
              rider.fullName,
              rider.overallRating,
              rider.email,
              "(" +
                rider.mobileNo.slice(0, 4) +
                ") " +
                rider.mobileNo.slice(4, rider.mobileNo.length),
              " " +
                moment(rider.creationDate).format("DD/MM/YYYY hh:mm A") +
                " ",
              rider.totalRides,
              rider.upcomingRides,
              rider.userType === 2 ? "Yes" : "No",
              status,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([riders], {
            type: "text/csv;charset=utf-8",
          }),
          "Rider-Reports.csv"
        );
      } else {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 100000000000;

        APIService.post("admin/reports/riders", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.riders.forEach((rider) => {
                let status = "";
                if (rider.userStatus === "A") {
                  status = "Active";
                } else if (rider.userStatus === "L") {
                  status = "Locked";
                } else {
                  status = "Inactive";
                }
                riders +=
                  [
                    "'" + rider.userId,
                    rider.fullName,
                    rider.overallRating,
                    rider.email,
                    "(" +
                      rider.mobileNo.slice(0, 4) +
                      ") " +
                      rider.mobileNo.slice(4, rider.mobileNo.length),
                    " " +
                      moment(rider.creationDate).format("DD/MM/YYYY hh:mm A") +
                      " ",
                    rider.totalRides,
                    rider.upcomingRides,
                    rider.userType === 2 ? "Yes" : "No",
                    status,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([riders], {
                  type: "text/csv;charset=utf-8",
                }),
                "Rider-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getRiders();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={creationDate}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreationDate(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["creationDate"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getRiders();
                      }, 500);
                    }
                  } else {
                    setCreationDate([]);
                    delete lazyParams.filters["creationDate"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getRiders();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1 d-flex">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportRiders}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCaptainOption(null);
      setCreationDate([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "creationDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
      });
      getRiders();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={riders}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedRiders}
          onSelectionChange={(e) => setSelectedRiders(e.value)}
          paginator
          emptyMessage="No Rider(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Rider Photo"
            body={(rider) => {
              return (
                <React.Fragment>
                  <img
                    className="p-column-listing-photo"
                    src={
                      rider.profileImage
                        ? rider.profileImage
                        : process.env.PUBLIC_URL + `/images/user.png`
                    }
                    width="50"
                    height="50"
                    alt="driver"
                  />
                </React.Fragment>
              );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Rider Id"
            field="userId"
            filter={showFilter}
            body={(rider) => (
              <Link to={"/rider-details/details/" + rider.id}>
                {rider.userId}
              </Link>
            )}
            sortable
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="fullName"
            header="Rider Full Name"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Name"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="rating"
            header="Overall rating"
            body={(rider) => (
              <Rating
                className="ratings pl-0 d-flex justify-content-center"
                fractions={2}
                emptySymbol="fa fa-star-o fa-2x star"
                fullSymbol="fa fa-star fa-2x star"
                initialRating={rider.overallRating}
                readonly
              />
            )}
            sortable
            filter={false}
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            field="emailId"
            header="Email"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Email"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Mobile Number"
            field="mobileNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Mobile"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & time"
            field="creationDate"
            body={(rider) =>
              moment(rider.creationDate).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={false}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={creationDate}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    setCreationDate(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters = {
                        ...lazyParams.filters,
                        creationDate: [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ],
                      };
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getRiders();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "165px" }}
            header="Total rides"
            field="totalRides"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Total Rides"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "220px" }}
            header="Total Upcoming Ride"
            field="upcomingRides"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Total Upcoming Ride"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            header="Captain"
            field="userType"
            body={(rider) =>
              rider.userType === 2 ? (
                <Link
                  to={"/driver-details/details/" + rider.driverId}
                  className="dataFnt-size"
                >
                  Yes
                </Link>
              ) : (
                "No"
              )
            }
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={captainOption}
                options={captainOptions}
                onChange={(e) => {
                  setCaptainOption(e.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    userType: e.value,
                  };
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getRiders();
                  }, 500);
                }}
                placeholder="Select"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "175px" }}
            field="status"
            header="Status"
            body={(rider) => {
              if (rider.userStatus === "A") {
                return "Active";
              } else if (rider.userStatus === "L") {
                return "Locked";
              } else {
                return "Inactive";
              }
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    status: event.value,
                  };
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getRiders();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsRiderListing));
