import React from "react";
import AuditLogListing from "../components/AuditLog/AuditLogListing";

const AuditLogPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Audit Log</span>
        <AuditLogListing />
      </div>
    </>
  );
};

export default React.memo(AuditLogPage);
