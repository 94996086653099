import React, { useState } from "react";
import { Alarm, Bell, CursorFill, Calculator } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import logo from "../../assest/images/ride.svg";
let bannerImage = process.env.PUBLIC_URL + "/images/chevron-down.svg";
let SideBar = ({ isOpen, openSidebar }) => {
  let location = useLocation(),
    pathName = location.pathname,
    [showSettings, setShowSettings] = useState(false),
    [showEmergency, setShowEmergency] = useState(false),
    [showReportDropDown, setShowReportDropDown] = useState(false),
    [showMasterDropDown, setShowMasterDropDown] = useState(false),
    [showNotificationDropDown, setShowNotificationDropDown] = useState(false),
    [showDispatchDropDown, setShowDispatchDropDown] = useState(false),
    [showAppSettingDropDown, setShowAppSettingDropDown] = useState(false),
    windowWidth = window.innerWidth,
    permissions = localStorage.getItem("access") !== null ? JSON.parse(atob(localStorage.getItem("access"))) : [],
    // permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [module, setModule] = useState(
      JSON.parse(localStorage.getItem("module")) || {
        value: "transportation",
        label: "Transportation",
      }
    ),
    sidebar,
    array;
  const masterControlArray = [
    "/master-control/trip-settings",
    "/master-control/controls",
    "/master-control/audit-logs",
    "/master-control/other-settings",
    "/master-control/vehicle-makers",
    "/master-control/category-assignment",
    "/master-control/high-zone",
    "/master-control/sla-config",
    "/master-control/unassigned-category"
  ],
    userProfileArray = ['/admin/user', '/admin/role', '/admin/roles', '/admin/category'],
    subscriptionArray = ["/subscriptions/own-a-car", "/subscriptions/ride-a-ride", "/subscriptions/Rider"],
    accountingArray = [
      "/accounting/topups-accounting",
      "/accounting/earnings",
      "/accounting/vat-accounting",
      "/accounting/fees",
      "/accounting/accountancy",
      "/accounting/subscription-accounting"
    ],
    reconciliationArray = [
      "/reconciliation/topups-accounting",
      "/reconciliation/earnings",
      "/reconciliation/vat-accounting",
      "/reconciliation/fees",
      "/reconciliation/accountancy",
      "/reconciliation/subscription-accounting"
    ],
    notificationsArray = ["/notification/notification-push", "/notification/notification-sms", "/notification/notification-emails"],
    fareConfigationArray = ["/fare-configuration/calculation", "/fare-configuration/vehicle-type", "/fare-configuration/setting"]
  const allowedOrNot = (arr, tabs) => {
    let goToPath = []
    tabs.map((a, i) => a === arr.find(b => b === a) && goToPath.push(a))
    return goToPath[0]
    // return arr.find(a => a === str) ? true : false
  }
  sidebar = (
    <div className="sidebar_list">
      <ul>
        {permissions.find(a => a.includes('/dashboard')) && (
          <Link
            to="/dashboard"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/dashboard" || pathName === "/dashboard"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/dashboard-icon.svg" alt="" srcSet="" />
                Dashboard
              </span>
            </li>
          </Link>
        )}

        {permissions.find(a => a.includes('/master-control')) && (
          <Link
            // to="/master-control/trip-settings"
            to={
              allowedOrNot(permissions.filter(a => a.includes('/master-control')), masterControlArray)
            }
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/master-control/trip-settings" ||
                  pathName === "/master-control/controls" ||
                  pathName === "/master-control/audit-logs" ||
                  pathName === "/master-control/other-settings" ||
                  pathName === "/master-control/vehicle-makers" ||
                  pathName === "/master-control/sla-config" ||
                  pathName === "/master-control/category-assignment" ||
                  pathName === "/master-control/unassigned-category"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/subscription-icon.svg" alt="" srcSet="" />
                Master Controls
              </span>
            </li>
          </Link>
        )}{" "}

        {permissions.find(a => a.includes('/riders')) && (
          <Link
            to="/riders"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/riders" ||
                  pathName.includes("/rider-details/trip-history/") ||
                  pathName.includes("/rider-details/details") ||
                  pathName.includes("/rider-details/topup-history/")
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/riders-icon.svg" alt="" srcSet="" />
                Riders
              </span>
            </li>
          </Link>
        )}
        {permissions.find(a => a.includes('/drivers')) && (
          <Link
            to="/drivers"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/drivers" || pathName.includes("/driver-details/details/") || pathName.includes("/driver-details/trip-history/") || pathName.includes("/driver-details/payment-history/")
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/captain-icon.svg" alt="" srcSet="" />
                Captains
              </span>
            </li>
          </Link>
        )}



        {/* {permissions.find(a => a.includes('/driver-registration')) && (
          <Link
            to="/driver-registration"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName === "/driver-registration" || pathName.includes("/driver-registration/") ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/captain-registration.svg" alt="" srcSet="" />
                Captain Registration
              </span>
            </li>
          </Link>
        )}      */}
        {/* {permissions.find(a => a.includes('/pickups')) && (
          <Link
            to="/pickups"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName === "/pickups" || pathName.includes("/pickups/") ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                  <path d="M2.4 3.0625V6.5H6V2.75H2.7C2.535 2.75 2.4 2.89062 2.4 3.0625ZM20.475 6.5L17.4413 2.75H14.4V6.5H20.475ZM12 6.5V2.75H8.4V6.5H12ZM22.2 14H21.6C21.6 16.0703 19.9875 17.75 18 17.75C16.0125 17.75 14.4 16.0703 14.4 14H9.6C9.6 16.0703 7.9875 17.75 6 17.75C4.0125 17.75 2.4 16.0703 2.4 14H1.8C0.80625 14 0 13.1602 0 12.125V3.0625C0 1.50781 1.2075 0.25 2.7 0.25H17.4413C18.15 0.25 18.8212 0.574219 19.2787 1.14062L23.4375 6.28516C23.8013 6.73438 24 7.30469 24 7.89453V12.125C24 13.1602 23.1938 14 22.2 14ZM19.8 14C19.8 12.9648 18.9938 12.125 18 12.125C17.0063 12.125 16.2 12.9648 16.2 14C16.2 15.0352 17.0063 15.875 18 15.875C18.9938 15.875 19.8 15.0352 19.8 14ZM6 15.875C6.99375 15.875 7.8 15.0352 7.8 14C7.8 12.9648 6.99375 12.125 6 12.125C5.00625 12.125 4.2 12.9648 4.2 14C4.2 15.0352 5.00625 15.875 6 15.875Z" fill="white" />
                </svg>
                Pickups
              </span>
            </li>
          </Link>
        )} */}
        {permissions.find(a => a.includes('/trips')) && (
          <Link
            to="/trips"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName === "/trips" || pathName.includes("/trip-details/") ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/trip-icon.svg" alt="" srcSet="" />
                Trips
              </span>
            </li>
          </Link>
        )}
        {permissions.find(a => a.includes('/markeeting')) && (
          <Link
            // to="/markeeting/promotions"
            to={permissions.filter(a => a.includes('/markeeting'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/markeeting/promotions" || pathName === "/markeeting/notifications" || pathName === "/markeeting/promotions/add"
                  ? // pathName === "/subscriptions/own-a-car"
                  "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.149 2.32909C19.995 1.86409 21 2.49009 21 3.42709V20.5751C21 21.5121 19.995 22.1381 19.149 21.6731C18.012 21.0491 14.646 19.2311 12.631 18.4301C11.3815 17.9264 10.0818 17.5577 8.754 17.3301L7.981 21.1971C7.95526 21.3259 7.9044 21.4484 7.83132 21.5576C7.75824 21.6668 7.66437 21.7605 7.55508 21.8334C7.44578 21.9062 7.3232 21.9569 7.19433 21.9824C7.06546 22.0079 6.93283 22.0078 6.804 21.9821C6.67517 21.9564 6.55268 21.9055 6.44351 21.8324C6.33434 21.7593 6.24063 21.6655 6.16774 21.5562C6.09484 21.4469 6.04419 21.3243 6.01867 21.1954C5.99315 21.0666 5.99326 20.9339 6.019 20.8051L6.762 17.0901C6.49258 17.0699 6.22289 17.0536 5.953 17.0411C4.35 16.9671 3 15.6721 3 14.0011V10.0011C3 8.33009 4.35 7.03509 5.953 6.96109C6.308 6.94409 6.657 6.92209 7 6.89309V16.0011H7.034C7.12177 15.8491 7.24801 15.7229 7.40002 15.6351C7.55204 15.5473 7.72447 15.5011 7.9 15.5011C8.07553 15.5011 8.24796 15.5473 8.39998 15.6351C8.55199 15.7229 8.67823 15.8491 8.766 16.0011H9V6.62909C10.2421 6.39837 11.4582 6.04428 12.63 5.57209C14.646 4.77109 18.012 2.95209 19.149 2.32909Z"
                    fill="white"
                  />
                </svg>
                Marketing
              </span>
            </li>
          </Link>
        )}{" "}
        {permissions.find(a => a.includes('/admin')) &&
          // array = permissions.filter(a => a.includes('/admin/'))

          <Link
            // to="/admin/user"
            to={
              allowedOrNot(permissions.filter(a => a.includes('/admin/')), userProfileArray)
            }
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={["/admin/user", "/admin/role", "/admin/roles", "/admin/category"].includes(pathName) ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/user-profile.svg" alt="" srcSet="" />
                User Profile
              </span>
            </li>
          </Link>
        }
        {permissions.find(a => a.includes('/logs')) && (
          <Link
            to="/logs"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName === "/logs" ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/dashboard-icon.svg" alt="" srcSet="" />
                OTP Logs
              </span>
            </li>
          </Link>
        )}
        {permissions.find(a => a.includes('/adjust')) && (
          <Link
            to="/adjust"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName.includes("/adjust") ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/adjust.svg" alt="" srcSet="" />
                Adjust
              </span>
            </li>
          </Link>
        )}
        {permissions.find(a => a.includes('/subscriptions')) && (
          <Link
            // to="/subscriptions/own-a-car"
            to={
              allowedOrNot(permissions.filter(a => a.includes('/subscriptions')), subscriptionArray)
            }
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/subscriptions/ride-a-ride" ||
                  pathName === "/subscriptions/own-a-car" ||
                  pathName === "/subscriptions/Rider"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/subscription-icon.svg" alt="" srcSet="" />
                Subscriptions
              </span>
            </li>
          </Link>
        )}{" "}
        {permissions.find(a => a.includes('/tickets')) && (
          <Link
            to="/tickets"
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li className={pathName === "/tickets" ? "li_active" : ""}>
              <span className="li_span"></span>
              <span className="li_svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-ticket-perforated-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4-1v1h1v-1H4Zm1 3v-1H4v1h1Zm7 0v-1h-1v1h1Zm-1-2h1v-1h-1v1Zm-6 3H4v1h1v-1Zm7 1v-1h-1v1h1Zm-7 1H4v1h1v-1Zm7 1v-1h-1v1h1Zm-8 1v1h1v-1H4Zm7 1h1v-1h-1v1Z" />
                </svg>
                {/* <img src="/images/subscription-icon.svg" alt="" srcSet="" /> */}
                Tickets
              </span>
            </li>
          </Link>
        )}{" "}
        {permissions.find(a => a.includes('/accounting')) && (
          <Link
            // to="/accounting/topups-accounting"
            to={allowedOrNot(permissions.filter(a => a.includes('/accounting')), accountingArray)}
            // to={permissions.filter(a => a.includes('/accounting'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/accounting/accountancy" ||
                  pathName === "/accounting/subscription-accounting" ||
                  pathName === "/accounting/topups-accounting" ||
                  pathName === "/accounting/earning-accounting" ||
                  pathName === "/accounting/vat-accounting" ||
                  pathName === "/accounting/fees" ||
                  pathName === "/accounting/earnings"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/accounting-icon.svg" alt="" srcSet="" />
                Accounting
              </span>
            </li>
          </Link>
        )}
        {permissions.find(a => a.includes('/reconciliation')) &&
          <Link
            // to="/reconciliation/topups-accounting"
            to={allowedOrNot(permissions.filter(a => a.includes('/reconciliation')), reconciliationArray)}
            // to={permissions.filter(a=>a.includes('/accounting'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/reconciliation/accountancy" ||
                  pathName === "/reconciliation/subscription-accounting" ||
                  pathName === "/reconciliation/topups-accounting" ||
                  pathName === "/reconciliation/earning-accounting" ||
                  pathName === "/reconciliation/vat-accounting" ||
                  pathName === "/reconciliation/fees" ||
                  pathName === "/reconciliation/earnings"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/accounting-icon.svg" alt="" srcSet="" />
                Reconciliation
              </span>
            </li>
          </Link>
        }
        {permissions.find(a => a.includes('/heat-map')) && (
          <Link
            to="/heat-map/all"
            // to={permissions.filter(a=>a.includes('/heat-map'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/heat-map/all" ||
                  pathName === "/heat-map/own-a-car" ||
                  pathName === "/heat-map/ontrip-captains" ||
                  pathName === "/heat-map/free-online-drivers" ||
                  pathName === "/heat-map/offline-captains"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                <img src="/images/heat-map-icon.svg" alt="" srcSet="" />
                Heatmap
              </span>
            </li>
          </Link>
        )}{" "}
        {permissions.find(a => a.includes('/car-details')) && (
          <Link
            to="/car-details"
            // to={permissions.filter(a=>a.includes('/heat-map'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                pathName === "/car-details"
                  // pathName === "/heat-map/heat-map-own-a-car" ||
                  // pathName === "/heat-map/heat-map-display" ||
                  // pathName === "/heat-map/heat-map-display" ||
                  // pathName === "/heat-map/heat-map-display"
                  ? "li_active"
                  : ""
              }
            >
              <span className="li_span"></span>
              <span className="li_svg">
                {/* <img src="/images/heat-map-icon.svg" alt="" srcSet="" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path d="M15 0C12.24 0 10 2.24 10 5C10 7.76 12.24 10 15 10C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM15.5 6H14.5V2H15.5V6ZM15.5 7V8H14.5V7H15.5ZM14.91 12C14.97 12.16 15 12.33 15 12.5C15 13.33 14.33 14 13.5 14C12.67 14 12 13.33 12 12.5C12 12.11 12.15 11.76 12.39 11.49C10.7607 10.8312 9.43709 9.58608 8.68 8H2.81L3.85 5H8C8 4.31 8.1 3.63 8.29 3H3.5C2.84 3 2.29 3.42 2.08 4.01L0 10V18C0 18.55 0.45 19 1 19H2C2.55 19 3 18.55 3 18V17H15V18C15 18.55 15.45 19 16 19H17C17.55 19 18 18.55 18 18V11.32C16.95 11.83 15.84 12.01 14.91 12ZM4.5 14C3.67 14 3 13.33 3 12.5C3 11.67 3.67 11 4.5 11C5.33 11 6 11.67 6 12.5C6 13.33 5.33 14 4.5 14Z" fill="white" />
                </svg>
                Inventory
              </span>
            </li>
          </Link>
        )}{" "}

        {showMasterDropDown ? (
          <div className="sidebar_list">
            <ul>
              {permissions.find(a => a.includes('/roles')) && (
                <Link
                  to="/roles"
                  onClick={() => {
                    if (windowWidth < 992) {
                      openSidebar();
                    }
                  }}
                >
                  <li
                    className={
                      pathName === "/roles" || pathName === "/role-modify"
                        ? "li_active"
                        : ""
                    }
                  >
                    <span className="li_svg sub-menu-padding">
                      <i className="fa fa-angle-right fa-lg mr-2" /> Roles
                    </span>
                  </li>
                </Link>
              )}
              {permissions.find(a => a.includes('/vehicles')) && (
                <Link
                  to="/vehicles"
                  onClick={() => {
                    if (windowWidth < 992) {
                      openSidebar();
                    }
                  }}
                >
                  <li className={pathName === "/vehicles" ? "li_active" : ""}>
                    <span className="li_svg sub-menu-padding">
                      <i className="fa fa-angle-right fa-lg mr-2" /> Vehicles
                    </span>
                  </li>
                </Link>
              )}
              {permissions.find(a => a.includes('/vehicle-types')) && (
                <Link
                  to="/vehicle-types"
                  onClick={() => {
                    if (windowWidth < 992) {
                      openSidebar();
                    }
                  }}
                >
                  <li
                    className={pathName === "/vehicle-types" ? "li_active" : ""}
                  >
                    <span className="li_svg sub-menu-padding">
                      <i className="fa fa-angle-right fa-lg mr-2" /> Vehicle
                      Categories
                    </span>
                  </li>
                </Link>
              )}

              <Link
                to="/vehicle-makers"
                onClick={() => {
                  if (windowWidth < 992) {
                    openSidebar();
                  }
                }}
              >
                <li
                  className={pathName === "/vehicle-makers" ? "li_active" : ""}
                >
                  <span className="li_svg sub-menu-padding">
                    <i className="fa fa-angle-right fa-lg mr-2" /> Vehicle
                    Makers
                  </span>
                </li>
              </Link>
              <Link
                to="/vehicle-models"
                onClick={() => {
                  if (windowWidth < 992) {
                    openSidebar();
                  }
                }}
              >
                <li
                  className={pathName === "/vehicle-models" ? "li_active" : ""}
                >
                  <span className="li_svg sub-menu-padding">
                    <i className="fa fa-angle-right fa-lg mr-2" /> Vehicle
                    Models
                  </span>
                </li>
              </Link>
            </ul>
          </div>
        ) : (
          ""
        )}
        {permissions.find(a => a.includes('/notification/')) && (
          <Link
            // to="/notification/notification-push"
            to={allowedOrNot(permissions.filter(a => a.includes('/notification/')), notificationsArray)}
            // to={permissions.filter(a => a.includes('/notification/'))[0]}
            onClick={() => {
              if (windowWidth < 992) {
                openSidebar();
              }
            }}
          >
            <li
              className={
                [
                  "/notification/notification-push",
                  "/notificatiot",
                  "/notification/notification-sms",
                  "/notification-sms-edit",
                  "/notification/notification-emails",
                  "/notification-email-edit",
                ].indexOf(pathName) > -1
                  ? "li_active"
                  : ""
              }

            >
              <span className="li_span"></span>
              <span className="li_svg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.149 2.32909C19.995 1.86409 21 2.49009 21 3.42709V20.5751C21 21.5121 19.995 22.1381 19.149 21.6731C18.012 21.0491 14.646 19.2311 12.631 18.4301C11.3815 17.9264 10.0818 17.5577 8.754 17.3301L7.981 21.1971C7.95526 21.3259 7.9044 21.4484 7.83132 21.5576C7.75824 21.6668 7.66437 21.7605 7.55508 21.8334C7.44578 21.9062 7.3232 21.9569 7.19433 21.9824C7.06546 22.0079 6.93283 22.0078 6.804 21.9821C6.67517 21.9564 6.55268 21.9055 6.44351 21.8324C6.33434 21.7593 6.24063 21.6655 6.16774 21.5562C6.09484 21.4469 6.04419 21.3243 6.01867 21.1954C5.99315 21.0666 5.99326 20.9339 6.019 20.8051L6.762 17.0901C6.49258 17.0699 6.22289 17.0536 5.953 17.0411C4.35 16.9671 3 15.6721 3 14.0011V10.0011C3 8.33009 4.35 7.03509 5.953 6.96109C6.308 6.94409 6.657 6.92209 7 6.89309V16.0011H7.034C7.12177 15.8491 7.24801 15.7229 7.40002 15.6351C7.55204 15.5473 7.72447 15.5011 7.9 15.5011C8.07553 15.5011 8.24796 15.5473 8.39998 15.6351C8.55199 15.7229 8.67823 15.8491 8.766 16.0011H9V6.62909C10.2421 6.39837 11.4582 6.04428 12.63 5.57209C14.646 4.77109 18.012 2.95209 19.149 2.32909Z"
                    fill="white"
                  />
                </svg>
                Notification
              </span>
            </li>
          </Link>
        )}{" "}
        {showNotificationDropDown ? (
          <div className="sidebar_list">
            <ul>
              {permissions.find(a => a.includes('/notification/notification-push')) && (
                // <Link
                //   to="/notification-push"
                //   onClick={() => {
                //     if (windowWidth < 992) {
                //       openSidebar();
                //     }
                //   }}
                // >
                <li
                  className={
                    pathName === "/notification-push" ||
                      pathName === "/notification-push-edit"
                      ? "li_active"
                      : ""
                  }
                >
                  <span className="li_svg sub-menu-padding">
                    <i className="fa fa-angle-right fa-lg mr-2" /> Top Up
                  </span>
                </li>
                // </Link>
              )}
              {permissions.find(a => a.includes('/notification/notification-sms')) && (
                // <Link
                //   to="/notification-sms"
                //   onClick={() => {
                //     if (windowWidth < 992) {
                //       openSidebar();
                //     }
                //   }}
                // >
                <li
                  className={
                    // pathName === "/notification-sms" ||
                    pathName === "/notification-sms-edit" ? "li_active" : ""
                  }
                >
                  <span className="li_svg sub-menu-padding">
                    <i className="fa fa-angle-right fa-lg mr-2" /> Subscription
                  </span>
                </li>
                // </Link>
              )}
              {permissions.find(a => a.includes('/notification/notification-emails')) && (
                // <Link
                //   to="/notification-emails"
                //   onClick={() => {
                //     if (windowWidth < 992) {
                //       openSidebar();
                //     }
                //   }}
                // >
                <li
                  className={
                    pathName === "/notification-emails" ||
                      pathName === "/notification-email-edit"
                      ? "li_active"
                      : ""
                  }
                >
                  <span className="li_svg sub-menu-padding">
                    <i className="fa fa-angle-right fa-lg mr-2" /> Ledger
                  </span>
                </li>
                // {/* </Link> */}
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
        {permissions.find(a => a.includes('/fare-configuration')) && <Link
          // to="/fare-configuration/calculation"
          to={allowedOrNot(permissions.filter(a => a.includes('/fare-configuration')), fareConfigationArray)}
          // to={permissions.filter(a => a.includes('/fare-configuration'))[0]}
          onClick={() => {
            if (windowWidth < 992) {
              openSidebar();
            }
          }}
        >
          <li
            className={
              pathName === "/fare-configuration/calculation" || pathName === "/fare-configuration/vehicle-type" || pathName === "/fare-configuration/setting" ? "li_active" : ""
            }
          >
            <span className="li_span"></span>
            <span className="li_svg">
              <Calculator />
              Fare Calculations
            </span>
          </li>
        </Link>}
        {showAppSettingDropDown ? (
          <div className="sidebar_list">
            <ul>
              {/* <Link
                  to="/trip-setting"
                  onClick={() => {
                    if (windowWidth < 992) {
                      openSidebar();
                    }
                  }}
                > */}
              <li className={pathName === "/trip-setting" ? "li_active" : ""}>
                <span className="li_svg sub-menu-padding">
                  <i className="fa fa-angle-right fa-lg mr-2" /> Trip Setting
                </span>
              </li>
              <li
              // className={pathName === "/other-setting" ? "li_active" : ""}
              >
                <span className="li_svg sub-menu-padding">
                  <i className="fa fa-angle-right fa-lg mr-2" /> Other Setting
                </span>
              </li>
              <li className={pathName === "/audit-log" ? "li_active" : ""}>
                <span className="li_svg sub-menu-padding">
                  <i className="fa fa-angle-right fa-lg mr-2" /> Logs
                </span>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </ul>
    </div>
  );

  return (
    <div className={isOpen ? "open__sidebar sidebar sidebar_close" : "sidebar"}>
      <div className="select_box">
      </div>
      {sidebar}
    </div>
  );
};
export default React.memo(SideBar);
