import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import APIService from "../services/APIService";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import City from "../components/City/City";
import DeleteModal from "../components/Common/DeleteModal";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let CityPage = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [showCity, setShowCity] = useState(false);
  let [showDelete, setShowDelete] = useState(false);
  let [countryOptions, setCountryOptions] = useState(null);
  let [cities, setCities] = useState(null);
  let [selectedCountry, setSelectedCountry] = useState(null);
  let [updateData, setUpdateData] = useState({
    name: "",
    countryId: "",
  });
  let handleCloseCity = () => {
    setShowCity(false);
    setUpdateData({ name: "", countryId: "" });
    setSelectedCountry(null);
  };
  let handleShowCity = () => {
    setShowCity(true);
  };
  let validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    countryId: Yup.string().required("Please select Country"),
  });

  let updateTableData = (rowData) => {
    let country = { ...rowData };
    // country.countryId = country.country.id;
    setSelectedCountry({
      label: country.country.name,
      value: country.country.id,
    });
    setUpdateData(country);
  };
  let handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setUpdateData(rowData);
      setShowDelete(true);
    };

  let createOrUpdateCity = (countryInfo, formProps) => {
    if (!updateData || !updateData.id) {
      APIService.post("admin/cities", countryInfo)
        .then((response) => {
          if (response.data.statusCode === 200) {
            getCityData();
            toast.success("City created successfully");
          } else {
            toast.error("Failed to create City");
          }
          formProps.resetForm();
          setUpdateData({ name: "", countryId: "" });
          handleCloseCity();
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          formProps.resetForm();
          setUpdateData({ name: "", countryId: "" });
          handleCloseCity();
        });
    } else {
      APIService.patch("admin/cities/" + updateData.id, {
        name: countryInfo.name,
        countryId: countryInfo.countryId,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            getCityData();
            toast.success("City updated successfully");
          } else {
            toast.error("Failed to update City");
          }
          setUpdateData({ name: "", countryId: "" });
          handleCloseCity();
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          setUpdateData({ name: "", countryId: "" });
          handleCloseCity();
        });
    }
  };
  let removeCity = () => {
    APIService.delete("admin/cities/" + updateData.id)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          toast.success("City deleted successfully");
          setShowDelete(false);
          setUpdateData({ name: "", countryId: "" });
          getCityData();
        }
      })
      .catch(() => {
        toast.error("Failed to delete City");
        setShowDelete(false);
        setUpdateData({ name: "", countryId: "" });
      });
  };
  let getCountryData = useCallback(() => {
    APIService.get(`admin/countries`)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          let countries = response?.data?.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          setCountryOptions(countries);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      });
  }, []);

  let getCityData = useCallback(() => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    APIService.get(`admin/cities`)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          setCities(response.data.data);
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        }
      })
      .catch((error) => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      });
  }, [props, setCities]);

  useEffect(() => {
    getCountryData();
    getCityData();
  }, [getCityData, getCountryData]);

  return (
    <>
      <div className="rider-listing">
        <span className="title">City </span>
        <button
          className="btn btn-primary float-right mb-1"
          onClick={handleShowCity}
        >
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </button>
        <City
          isLoading={useSelector((state) => state.isGridLoader)}
          handleShow={handleShowCity}
          updateTableData={updateTableData}
          handleShowDelete={handleShowDelete}
          cities={cities}
        />
      </div>

      <Modal show={showCity} size="" onHide={handleCloseCity} centered>
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateCity}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    {updateData?.id ? "Update" : "Add New"} City
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="name"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="City Name"
                    />
                    <label htmlFor="name">City Name</label>
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>

                  <div className="mb-4">
                    <Select
                      className="height-50"
                      placeholder="Select Country"
                      name="countryId"
                      styles={colourStyles}
                      value={selectedCountry}
                      onBlur={() => {
                        handleBlur({ target: { name: "countryId" } });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("countryId", e.value);
                          setSelectedCountry(e);
                        }
                      }}
                      options={countryOptions}
                    />
                    <ErrorMessage
                      name="countryId"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  {/* <div className="mb-4">
              <Select
                className="height-50"
                placeholder="Select status"
                // isClearable
                value={selectedStatusCity}
                onChange={setSelectedStatusCity}
                options={statusOptions}
              />
            </div> */}
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save save-button mb-3 border-radius-15"
                  >
                    {updateData?.id ? "Update" : "Add"}
                  </Button>
                  {/* <Button
            variant="secondary"
            className="btn-save-add save-button mb-3 border-radius-15"
            onClick={handleCloseCity}
          >
            Save & Add more
          </Button> */}
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleCloseCity}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <DeleteModal
        showDelete={showDelete}
        clickYes={removeCity}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
};

export default React.memo(connect()(CityPage));
