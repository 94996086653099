import React, { useState } from "react";
import Roles from "../components/Roles/Roles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

let RolesPage = () => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  return (
    <>
      <Roles />
      {/* <div className="rider-listing">
        <span className="title">Roles </span>
        <Link to="/admin/role" className="btn btn-primary float-right">
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </Link>
        <Roles />
      </div> */}
    </>
  );
};

export default React.memo(RolesPage);
