import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import saveAs from "save-as";
import APIService from "../services/APIService";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

let IncompleteTripReq = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [createdAt, setCreatedAt] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    [dispatcherTrips, setDispatcherTrips] = React.useState([]),
    [status, setStatus] = useState(null),
    [selectedDispatchers, setSelectedDispatchers] = React.useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    exportDispatchers = () => {
      let dispatchers = [
        "Trip Id",
        "Date & Time",
        "Pick Up",
        "Drop Off",
        "Rider ID",
        "Driver ID",
        "Status",
      ];
      dispatchers = dispatchers.join(",") + "\r\n";
      if (selectedDispatchers.length) {
        selectedDispatchers.forEach((dispatcher) => {
          dispatchers +=
            [
              dispatcher.tripNo,
              "'" + moment(dispatcher.createdAt).format("DD/MM/YYYY hh:mm A"),
              dispatcher.pickup.address,
              dispatcher.dropoff.address,
              dispatcher.rider.userId,
              dispatcher.driver.userId,
              tripStatus.find((sts) => sts.value === dispatcher.status).label,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([dispatchers], {
            type: "text/csv;charset=utf-8",
          }),
          "Dispatcher-Trips-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 100000000000;

        APIService.post("admin/incomplete-trips", filterParams)
          .then((response) => {
            if (response.status === 201) {
              response.data.data.trips.forEach((dispatcher) => {
                dispatchers +=
                  [
                    dispatcher.tripNo,
                    "'" +
                      moment(dispatcher.createdAt).format("DD/MM/YYYY hh:mm A"),
                    dispatcher.pickup.address,
                    dispatcher.dropoff.address,
                    dispatcher.rider.userId,
                    dispatcher.driver.userId,
                    tripStatus.find((sts) => sts.value === dispatcher.status)
                      .label,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([dispatchers], {
                  type: "text/csv;charset=utf-8",
                }),
                "Dispatcher-Trip-Reports.csv"
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                setTimeout(() => {
                  getDispatcherTrips();
                }, 500);
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={exportDispatchers}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getDispatcherTrips();
    },
    getDispatcherTrips = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/incomplete-trips", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setDispatcherTrips(response.data.data.trips);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDispatcherTrips = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDispatcherTrips();
    },
    actionTemplate = () => {
      return (
        <div className="button p-table-button">
          <Button
            tooltip="View Reason"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
            
            }}
          >
            <svg
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5002 0C16.8922 0 21.3782 3.88 22.3192 9C21.3792 14.12 16.8922 18 11.5002 18C6.10815 18 1.62215 14.12 0.681152 9C1.62115 3.88 6.10815 0 11.5002 0ZM11.5002 16C13.5396 15.9996 15.5185 15.3068 17.113 14.0352C18.7075 12.7635 19.8231 10.9883 20.2772 9C19.8214 7.0133 18.7051 5.24 17.1108 3.97003C15.5165 2.70005 13.5385 2.00853 11.5002 2.00853C9.46185 2.00853 7.48384 2.70005 5.88953 3.97003C4.29521 5.24 3.17892 7.0133 2.72315 9C3.17725 10.9883 4.29283 12.7635 5.8873 14.0352C7.48177 15.3068 9.46068 15.9996 11.5002 16ZM11.5002 13.5C10.3067 13.5 9.16209 13.0259 8.31817 12.182C7.47426 11.3381 7.00015 10.1935 7.00015 9C7.00015 7.80653 7.47426 6.66193 8.31817 5.81802C9.16209 4.97411 10.3067 4.5 11.5002 4.5C12.6936 4.5 13.8382 4.97411 14.6821 5.81802C15.526 6.66193 16.0002 7.80653 16.0002 9C16.0002 10.1935 15.526 11.3381 14.6821 12.182C13.8382 13.0259 12.6936 13.5 11.5002 13.5ZM11.5002 11.5C12.1632 11.5 12.7991 11.2366 13.2679 10.7678C13.7368 10.2989 14.0002 9.66304 14.0002 9C14.0002 8.33696 13.7368 7.70107 13.2679 7.23223C12.7991 6.76339 12.1632 6.5 11.5002 6.5C10.8371 6.5 10.2012 6.76339 9.73239 7.23223C9.26355 7.70107 9.00015 8.33696 9.00015 9C9.00015 9.66304 9.26355 10.2989 9.73239 10.7678C10.2012 11.2366 10.8371 11.5 11.5002 11.5Z"
                fill="#03053D"
              />
            </svg>
          </Button>
          <Button
            tooltip="Assign Manually"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
           
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button>
        </div>
      );
    };
  React.useEffect(() => {
    getDispatcherTrips();
  }, []);
  return (
    <div className="rider-listing">
      <span className="title">Dispatcher Trip List</span>
      <Link to="/create-trip">
        <button className="btn btn-primary float-right mb-1">
          {windowWidth ? <i className="fa fa-plus"></i> : "Add Trip"}
        </button>
      </Link>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={dispatcherTrips}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Incomplete Trip(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            onSelectionChange={(e) => setSelectedDispatchers(e.value)}
            selection={selectedDispatchers}
            lazy={true}
            totalRecords={lazyParams.totalRecords}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={manageDispatcherTrips}
            onSort={manageDispatcherTrips}
            onFilter={manageDispatcherTrips}
          >
            <Column selectionMode="multiple" style={{ width: "3em" }} />
            <Column
              className="text-center"
              headerClassName="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              field="tripNo"
              header="Trip ID"
              filter={showFilter}
              body={(trip) => (
                <Link to={`/trip-details/${trip.id}`} className="dataFnt-size">
                  <span className="dataFnt-size">{trip.tripNo}</span>
                </Link>
              )}
              sortable
            />

            <Column
              className="text-center"
              headerClassName="text-center"
              style={{ fontSize: "12px", width: "230px" }}
              header="Date & Time"
              field="createdAt"
              body={(trip) =>
                moment(trip.createdAt).format("DD/MM/YYYY hh:mm A")
              }
              sortable
              filter={showFilter}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select date range"
                    dateFormat="dd/mm/yy"
                    value={createdAt}
                    hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      if (e.value) {
                        setCreatedAt(e.value);
                        if (e.value[0] && e.value[1]) {
                          lazyParams.filters["createdAt"] = [
                            moment(e.value[0]).format("YYYY-MM-DD"),
                            moment(e.value[1]).format("YYYY-MM-DD"),
                          ];

                          setLazyParams(lazyParams);
                          setTimeout(() => {
                            getDispatcherTrips();
                          }, 500);
                        }
                      } else {
                        setCreatedAt([]);
                        delete lazyParams.filters["createdAt"];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getDispatcherTrips();
                        }, 500);
                      }
                    }}
                    selectionMode="range"
                    showButtonBar
                    readOnlyInput
                  />
                  <span className="input-box-daterange-icon">
                    <CalendarRange />
                  </span>
                </div>
              }
            />
            <Column
              className="text-left"
              headerClassName="text-left"
              style={{ fontSize: "12px", width: "250px" }}
              field="pickup.address"
              sortField="pickup"
              filterField="pickup"
              header="Pick Up"
              filter={showFilter}
              filterPlaceholder="Search by pickup"
              sortable
            />
            <Column
              className="text-left"
              style={{ fontSize: "12px", width: "200px" }}
              sortable
              header="Drop off"
              field="dropoff.address"
              filter={showFilter}
              filterField="dropoff"
              sortField="dropoff"
              filterPlaceholder="Search by dropoff"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              sortable
              header="Rider ID"
              field="rider.id"
              body={(trip) => (
                <Link
                  to={`/rider-details/details/${trip.rider.id}`}
                  className="dataFnt-size"
                >
                  <span className="dataFnt-size">{trip.rider.userId}</span>
                </Link>
              )}
              filter={showFilter}
              filterField="riderId"
              sortField="riderId"
              filterPlaceholder="Search by rider id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              sortable
              header="Driver ID"
              field="driver.userId"
              body={(trip) => (
                <Link
                  to={`/driver-details/details/${trip.driver.id}`}
                  className="dataFnt-size"
                >
                  <span className="dataFnt-size">{trip.driver.userId}</span>
                </Link>
              )}
              filter={showFilter}
              filterField="driverId"
              sortField="driverId"
              filterPlaceholder="Search by driver id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "180px" }}
              sortable
              filter={showFilter}
              header="Mobile Number"
              field="mobileNumber"
            />
            <Column
              className="text-center text-capitalize"
              style={{ fontSize: "12px", width: "200px" }}
              field="status"
              header="Status"
              sortable
              filter={showFilter}
              body={(trip) =>
                tripStatus.find((trp) => trp.value === trip.status)?.label
              }
              filterElement={
                <Dropdown
                  value={status}
                  options={tripStatus}
                  onChange={(event) => {
                    setStatus(event.value);
                    if (event.value) {
                      lazyParams.filters["status"] = event.value;
                    } else {
                      delete lazyParams.filters["status"];
                    }
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getDispatcherTrips();
                    }, 500);
                  }}
                  placeholder="Select Status"
                  className="p-column-filter"
                  showClear
                />
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "140px" }}
              header="Action"
              headerClassName="text-center"
              body={actionTemplate}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default React.memo(connect()(IncompleteTripReq));
