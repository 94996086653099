import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import moment from "moment";
import APIService from "../../services/APIService";
import { TabView, TabPanel } from "primereact/tabview";

function Raiting(props) {
  let windowWidth = window.innerWidth;
  const { DriverReviewsStats, RiderReviewsStats } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [captRating , setCaptRatingData]=useState({});
  const [riderRating,setRiderRatingData]=useState({});
  let badRatingRider = riderRating?.count?.poor ? riderRating.count.poor : RiderReviewsStats?.count?.poor,
    excellentRatingRider = riderRating?.count?.excelent ? riderRating.count.excelent : RiderReviewsStats?.count?.excelent,
    goodRatingRider =  riderRating?.count?.good ? riderRating.count.good : RiderReviewsStats?.count?.good,
    bestRatingRider =  riderRating?.count?.best ? riderRating.count.best : RiderReviewsStats?.count?.best,
    averageRatingRider =  riderRating?.count?.average ? riderRating.count.average : RiderReviewsStats?.count?.average;
  let badRatingDriver = captRating?.count?.poor ? captRating.count.poor : DriverReviewsStats?.count?.poor,
    goodRatingDriver = captRating?.count?.good ? captRating.count.good :  DriverReviewsStats?.count?.good,
    bestRatingDriver = captRating?.count?.best ? captRating.count.best :  DriverReviewsStats?.count?.best,
    averageRatingDriver = captRating?.count?.average ? captRating.count.average :  DriverReviewsStats?.count?.average,
    excellentRatingDriver = captRating?.count?.excelent ? captRating.count.excelent :  DriverReviewsStats?.count?.excelent

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };

  const getTimeRange=(range)=>{
    Promise.all([
      APIService.get(`admin/dashboard/ratting?type=custom&entity=rider&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000` ),
      APIService.get(`admin/dashboard/ratting?type=custom&entity=captain&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
    ]).then(response =>{
      if(response[0].status==200){
          let data = response[0]?.data?.data ? response[0].data.data : ""
          setRiderRatingData(data)
        }
      if(response[1].status==200){
        let data = response[1].data.data ? response[1].data.data : ""
        setCaptRatingData(data)
      }
    }).catch(e => console.error(e))
  }
  const [riderRatingData, setRiderRating] = useState({
    datasets: [
      {
        data: [
          badRatingRider,
          bestRatingRider,
          averageRatingRider,
          goodRatingRider,
          excellentRatingRider,
          
        ],
        backgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
  });
  const [driverRatingData, setDriverRating] = useState({
    datasets: [
      {
        data: [
          badRatingDriver,
            bestRatingDriver,
            averageRatingDriver,
            goodRatingDriver,
            excellentRatingDriver,
          
        ],
        backgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    setRiderRating({
      datasets: [
        {
          data: [
            badRatingRider || 0.1,
            bestRatingRider,
            averageRatingRider,
            goodRatingRider,
            excellentRatingRider,
            
          ],
          backgroundColor: [
            badRatingRider ? "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          hoverBackgroundColor: [
            badRatingRider ? "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          borderWidth: 0,
        },
      ],
    });
    setDriverRating({
      datasets: [
        {
          data: [
            badRatingDriver || 0.1,
            bestRatingDriver,
            averageRatingDriver,
            goodRatingDriver,
            excellentRatingDriver,
            
          ],
          backgroundColor: [
            badRatingDriver ?  "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          hoverBackgroundColor: [
            badRatingDriver ?  "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          borderWidth: 0,
        },
      ],
    });
  }, [RiderReviewsStats, DriverReviewsStats,riderRating,captRating]);

  

  return (
    <>
      <div className="section-rider cards-section h-100">
        <div className="d-flex justify-content-between text-center align-items-center position-relative">
          <h2 className="box-title">Ratings</h2>
          <Calendar handleTimeRange={getTimeRange} />
        </div>
        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header=" Riders">
            {" "}
            <div>
              <Doughnut
                type="doughnut"
                data={riderRatingData}
                options={chartOptions}
              
              />
             
            </div>
            <div className="flex-cbb justify-content-center mt-32">
              <div>
                <div className="percentage">
                  <div
                    className="green"
                    style={{ background: "#7071BF" }}
                  ></div>
                  <div className="percentext">Excellent:</div>
                  <div className="percenPoint">
                    {" "}
                    {riderRating?.percentage?.excelent ? riderRating.percentage.excelent : RiderReviewsStats?.percentage?.excelent || 0}%
                  </div>
                </div>
                <div className="percentage">
                  <div
                    className="green"
                    style={{ background: "#13A5B5" }}
                  ></div>
                  <div className="percentext ">Good:</div>
                  <div className="percenPoint">
                    {" "}
                    {riderRating?.percentage?.good ? riderRating.percentage.good :RiderReviewsStats?.percentage?.good || 0}%
                  </div>
                </div>
                <div className="percentage">
                  <div className="green bg-red"></div>
                  <div className="percentext ">Poor:</div>
                  <div className="percenPoint">
                    {" "}
                    {riderRating?.percentage?.poor ? riderRating.percentage.poor :RiderReviewsStats?.percentage?.poor || 0}%
                  </div>
                </div>
              </div>
              <div>
                <div className="percentage">
                  <div className="green"></div>
                  <div className="percentext ">Best:</div>
                  <div className="percenPoint">
                    {" "}
                    {riderRating?.percentage?.best ? riderRating.percentage.best :RiderReviewsStats?.percentage?.best || 0}%
                  </div>
                </div>
                <div className="percentage">
                  <div
                    className="green bg-orange"
                    style={{ background: "#FFB065" }}
                  ></div>
                  <div className="percentext ">Average:</div>
                  <div className="percenPoint">
                    {" "}
                    {riderRating?.percentage?.average ? riderRating.percentage.average : RiderReviewsStats?.percentage?.average || 0}%
                  </div>
                </div>
              </div>
            </div>
          
          </TabPanel>
          <TabPanel header=" Captains">
            <div>
              <Doughnut
                type="doughnut"
                data={driverRatingData}
                options={chartOptions}
        
              />

            
            </div>
            <div className="flex-cbb mt-32">
              <div>
                <div className="percentage">
                  <div
                    className="green"
                    style={{ background: "#7071BF" }}
                  ></div>
                  <div className="percentext">Excellent:</div>
                  <div className="percenPoint">
                    {captRating?.percentage?.excelent ? captRating?.percentage?.excelent : DriverReviewsStats?.percentage?.excelent|| 0}%
                  </div>
                </div>
                <div className="percentage ">
                  <div
                    className="green"
                    style={{ background: "#13A5B5" }}
                  ></div>
                  <div className="percentext">Good:</div>
                  <div className="percenPoint">
                    {captRating?.percentage?.good ? captRating?.percentage?.good :DriverReviewsStats?.percentage?.good|| 0}%
                  </div>
                </div>
                <div className="percentage ">
                  <div className="green red"></div>
                  <div className="percentext">Poor:</div>
                  <div className="percenPoint">
                    {captRating?.percentage?.poor ? captRating?.percentage?.poor :DriverReviewsStats?.percentage?.poor|| 0}%
                  </div>
                </div>
              </div>
              <div>
                <div className="percentage">
                  <div className="green"></div>
                  <div className="percentext">Best:</div>
                  <div className="percenPoint">
                    {captRating?.percentage?.best ? captRating?.percentage?.best :DriverReviewsStats?.percentage?.best|| 0}%
                  </div>
                </div>
                <div className="percentage">
                  <div
                    className="green"
                    style={{ background: "#FFB065" }}
                  ></div>
                  <div className="percentext">Average:</div>
                  <div className="percenPoint">
                    {captRating?.percentage?.average ? captRating?.percentage?.average :DriverReviewsStats?.percentage?.average|| 0}%
                  </div>
                </div>
              </div>
            </div>

            <div className="main-percent rPercen justify-content-center"></div>
          </TabPanel>
          <TabPanel header="Pickups">Coming Soon</TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default Raiting;
