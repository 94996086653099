import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { saveAs } from "save-as";

let ReportsTripsListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [trips, setTrips] = useState([]),
    [selectedTrips, setSelectedTrips] = useState([]),
    [createdAt, setCreatedAt] = useState([]),
    [tripType, setTripType] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    tripStatus = [
      { label: "Pending", value: 1 },
      { label: "Accepted By Driver", value: 2 },
      { label: "Rejected By Driver", value: 3 },
      { label: "Cancelled By Driver", value: 4 },
      { label: "Driver Arrived", value: 5 },
      { label: "Cancelled By Rider", value: 6 },
      { label: "Started", value: 7 },
      { label: "Completed", value: 8 },
      { label: "No Driver", value: 9 },
      { label: "Expired", value: 10 },
      { label: "Cancelled By Admin", value: 11 },
      { label: "Booking Cancelled", value: 12 },
      { label: "Stop and Assigned", value: 13 },
      { label: "Stop and Completed", value: 14 },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
    }),
    getTrips = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/reports/trips", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            let responseData = response.data.data.trips.map((trip) => {
              trip.pickup = trip.pickup.address;
              trip.dropoff = trip.dropoff.address;
              trip.riderId = trip.rider.userId;
              trip.riderName = trip.rider.fullName;
              trip.driverId = trip.driver.userId;
              trip.driverName = trip.driver.fullName;
              return trip;
            });
            setTrips(responseData);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTrips();
    },
    exportTrips = () => {
      let trips = [
        "Trip Id",
        "Date Time of Booking",
        "Pick Up",
        "Drop Off",
        "Rider Id & Name",
        "Driver Id & Name",
        "Book as",
        "Total Fare",
        "Status",
      ];
      trips = trips.join(",") + "\r\n";
      if (selectedTrips.length) {
        selectedTrips.forEach((trip) => {
          let status = tripStatus.find(
            (sts) => sts.value === trip.status
          ).label;
          trips +=
            [
              trip.tripNo ? trip.tripNo : "-",
              moment(trip.createdAt).format("DD/MM/YYYY HH:MM A"),
              trip.pickup ? JSON.stringify(trip.pickup) : "-",
              trip.dropoff ? JSON.stringify(trip.dropoff) : "-",
              (trip.rider.userId ? trip.rider.userId : "-") +
                (trip.rider.fullName ? trip.rider.fullName : "-"),
              (trip.driver.userId ? +" " + trip.driver.userId : "-") +
                (trip.driver.fullName ? +" " + trip.driver.fullName : "-"),
              trip.tripType === 1 ? "Now" : "Scheduled",
              trip.riderAmount ? trip.riderAmount : "-",
              status,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([trips], {
            type: "text/csv;charset=utf-8",
          }),
          "Trips-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.take = 10000000000;
        filterParams.skip = 0;
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/reports/trips", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              let allTrips = response.data.data.trips;
              allTrips.forEach((trip) => {
                let status = tripStatus.find(
                  (sts) => sts.value === trip.status
                ).label;
                trips +=
                  [
                    trip.tripNo ? trip.tripNo : "-",
                    moment(trip.createdAt).format("DD/MM/YYYY HH:MM A"),
                    trip.pickup.address
                      ? JSON.stringify(trip.pickup.address)
                      : "-",
                    trip.dropoff.address
                      ? JSON.stringify(trip.dropoff.address)
                      : "-",
                    (trip.rider.userId ? trip.rider.userId : "-") +
                      (trip.rider.fullName ? trip.rider.fullName : "-"),
                    (trip.driver.userId ? +" " + trip.driver.userId : "-") +
                      (trip.driver.fullName
                        ? +" " + trip.driver.fullName
                        : "-"),
                    trip.tripType === 1 ? "Now" : "Scheduled",
                    trip.riderAmount ? trip.riderAmount : "-",
                    status,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([trips], {
                  type: "text/csv;charset=utf-8",
                }),
                "Trips-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getTrips();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["createdAt"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTrips();
                      }, 500);
                    }
                  } else {
                    setCreatedAt([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getTrips();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1 d-flex">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportTrips}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setTripType(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
      });
      getTrips();
    },
    bookAsTemplate = (rowData) => {
      return (
        <React.Fragment>
          <span className="p-column-title">Book as</span>
          <span>{rowData.tripType === 1 ? "Now" : "Scheduled"}</span>
        </React.Fragment>
      );
    },
    dateBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <span className="p-column-title">Date Time of booking</span>
          <span className="dataFnt-size">
            {moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")}
          </span>
        </React.Fragment>
      );
    },
    redirectIdTemplate = (rowData) => {
      return (
        <React.Fragment>
          <Link to={`/trip-details/${rowData.id}`} className="dataFnt-size">
            <span className="">{rowData.tripNo}</span>
          </Link>
        </React.Fragment>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={trips}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          onSelectionChange={(e) => setSelectedTrips(e.value)}
          selection={selectedTrips}
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Trip Id"
            field="tripNo"
            body={redirectIdTemplate}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Trip"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="createdAt"
            sortable
            header="Date Time of booking"
            body={dateBodyTemplate}
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            field="pickup"
            header="Pick Up"
            sortable
            filter={showFilter}
            filterPlaceholder="Search Pickup"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            sortField="dropoff"
            filterField="dropoff"
            header="Drop Off"
            field="dropoff"
            sortable
            filter={showFilter}
            filterPlaceholder="Search Dropoff"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "120px" }}
            header="Rider Id"
            field="riderId"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Rider Id"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Rider Name"
            field="riderName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Rider Name"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "120px" }}
            header="Driver Id"
            field="driverId"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Driver id"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Name"
            field="driverName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Driver Name"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="tripType"
            header="Book as"
            sortable
            body={bookAsTemplate}
            // sortable
            filter={showFilter}
            filterPlaceholder="Book As"
            filterElement={
              <Dropdown
                value={tripType}
                options={[
                  {
                    label: "Now",
                    value: 1,
                  },
                  {
                    label: "Schehduled",
                    value: 2,
                  },
                ]}
                onChange={(event) => {
                  event.preventDefault();
                  if (event.value) {
                    lazyParams.filters["tripType"] = event.value;
                  } else {
                    delete lazyParams.filters["tripType"];
                  }
                  setTripType(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTrips();
                  }, 500);
                }}
                placeholder="Select Triptype"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "130px" }}
            field="riderAmount"
            header="Total Fare"
            sortable
            sortField="totalAmount"
            filter={showFilter}
            filterPlaceholder="Search Fare"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            header="Rider Rating"
            sortable
            sortField="riderReview"
            body={(rowData) => (
              <Rating
                className="ratings pl-0 d-flex justify-content-center"
                fractions={2}
                emptySymbol="fa fa-star-o fa-2x star"
                fullSymbol="fa fa-star fa-2x star"
                initialRating={rowData.rider.riderReview}
                readonly
              />
            )}
            filter={false}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            header="Driver Rating"
            sortField="driverReview"
            sortable
            body={(rowData) => (
              <Rating
                className="ratings pl-0 d-flex justify-content-center"
                fractions={2}
                emptySymbol="fa fa-star-o fa-2x star"
                fullSymbol="fa fa-star fa-2x star"
                initialRating={rowData.driver.driverReview}
                readonly
              />
            )}
            filter={false}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="status"
            header="Status"
            body={(trip) =>
              tripStatus.find((sts) => sts.value === trip.status)?.label
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Status"
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={tripStatus}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTrips();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsTripsListing));
