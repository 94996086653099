import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import saveAs from "save-as";
import moment from "moment";

let ReportsTripCancelledByRiderListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [tripCancelledRider, setTripCancelledRider] = useState(null),
    [createdAt, setCreatedAt] = useState([]),
    [selectedTrips, setSelectedTrips] = useState([]),
    [globalFilter, setGlobalFilter] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "riderName",
        order: 1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getTripCancelledRider = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/reports/trips-cancelled-by-rider", filterParams)
        .then((response) => {
          if (response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setTripCancelledRider(response.data.data.trips);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows;
        lazyParams.skip = event.first;
      } else if (event.filters) {
        if (Object.keys(event.filters).length > 0) {
          lazyParams.take = event.rows ? event.rows : lazyParams.take;
          lazyParams.skip = 0;
          let filters = { ...lazyParams.filters };
          for (let filter in event.filters) {
            if (event.filters[filter].value) {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
          lazyParams.filters = { ...filters, ...lazyParams.filters };
        } else if (!Object.keys(event.filters).length) {
          lazyParams.filters = {};
        }
      }
      setLazyParams(lazyParams);
      getTripCancelledRider();
    },
    exportTripCancelled = () => {
      let tripCancelled = [
        "Rider Id",
        "Rider Name",
        "No. Of Total Trips",
        "No. Of Total Trips Completed",
        "No. Of Total Trips Cancelled",
        "Overall Rating",
        "Cancellation %",
      ];
      tripCancelled = tripCancelled.join(",") + "\r\n";
      if (selectedTrips.length) {
        selectedTrips.forEach((tripCancel) => {
          tripCancelled +=
            [
              "'" + tripCancel.userId,
              tripCancel.fullName,
              tripCancel.totalRides,
              tripCancel.completedRides,
              tripCancel.ridesCancelled,
              tripCancel.overallRating,
              tripCancel.cancellation,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([tripCancelled], {
            type: "text/csv;charset=utf-8",
          }),
          "Trip-Cancelled-By-Rider-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 10000000000;
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/reports/trips-cancelled-by-rider", filterParams)
          .then((response) => {
            if (response.data.statusCode === 200) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.trips.forEach((tripCancel) => {
                tripCancelled +=
                  [
                    "'" + tripCancel.userId,
                    tripCancel.fullName,
                    tripCancel.totalRides,
                    tripCancel.completedRides,
                    tripCancel.ridesCancelled,
                    tripCancel.overallRating,
                    tripCancel.cancellation,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([tripCancelled], {
                  type: "text/csv;charset=utf-8",
                }),
                "Trip-Cancelled-By-Rider-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getTripCancelledRider();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters = {
                        ...lazyParams.filters,
                        createdAt: [
                          moment(e.value[0]).format("MM-DD-YYYY"),
                          moment(e.value[1]).format("MM-DD-YYYY"),
                        ],
                      };
                    }
                  } else {
                    setCreatedAt(null);
                    lazyParams.filters = {
                      ...lazyParams.filters,
                    };
                    delete lazyParams.filters.createdAt;
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTripCancelledRider();
                  }, 500);
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportTripCancelled}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setSelectedTrips([]);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "riderName",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getTripCancelledRider();
    },
    overallRatingBody = (rowData) => {
      return (
        <React.Fragment>
          <Rating
            className="ratings pl-0 d-flex justify-content-center"
            fractions={2}
            emptySymbol="fa fa-star-o fa-2x star"
            fullSymbol="fa fa-star fa-2x star"
            initialRating={rowData.overallRating}
            readonly
          />
        </React.Fragment>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={tripCancelledRider}
          selection={selectedTrips}
          onSelectionChange={(e) => setSelectedTrips(e.value)}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          paginator
          emptyMessage="No cancelled trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          totalRecords={lazyParams.totalRecords}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Rider Id"
            field="userId"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Id"
          />
          <Column
            style={{ fontSize: "12px", width: "180px" }}
            field="fullName"
            header="Rider name"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Name"
            filterField="riderName"
            sortField="riderName"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="No. Of Total Trips"
            field="totalRides"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Rides"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="No. Of Total Trips Completed"
            field="completedRides"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Completed rides"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="No. Of Total Trips Cancelled"
            field="ridesCancelled"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Cancelled rides"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="riderRating"
            header="Overall Rating"
            body={overallRatingBody}
            // sortable
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Cancellation %"
            field="cancellation"
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsTripCancelledByRiderListing));
