import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function TotalRider(props) {
  const { activeRidersPercentage, topCardRider } = props;
  const [riderData ,setRiderData]=useState({})
  const [applyFilter,setApplyFilter] = useState(false)
  let windowWidth = window.innerWidth;

  let activeRider =riderData?.count?.active;
  let inActiveRider = riderData?.count?.inActive;
  let riderCount=activeRidersPercentage?.activeRiderPercentage?.count

  const getTimeRange=(range)=>{
    setApplyFilter(true)
    try{
      APIService.get(`admin/dashboard/active-riders-percentage?type=custom&entity=rider&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
      .then((res)=>{
        if(res){
        if(res.status==200){
     
          let data = res?.data?.data ? res.data.data : ""
          setRiderData(data)
        }}
      }).catch((e)=>console.log(e))
    }catch(e){
        console.log(e)
      }
  }

  const [dataResult, setDataResult] = useState({
    datasets: [
      {
        data: [
          activeRidersPercentage?.activeRiderPercentage?.count?.inActive,
          activeRidersPercentage?.activeRiderPercentage?.count?.active,
        ],
        backgroundColor: ["#EB3D3D", "#13B542"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542"],
        borderWidth: 0,
      },
    ],
  });
  let chartOptions = {
  

    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 95,
    pointStyle: "circle",
  };
 
  useEffect(() => {
    setDataResult({
      datasets: [
        {
          data: [
            applyFilter?(inActiveRider) : activeRidersPercentage?.activeRiderPercentage?.count?.inActive,
            applyFilter?(activeRider) : activeRidersPercentage?.activeRiderPercentage?.count?.active,
            // (applyFilter && inActiveRider == 0) ? 0.1: (inActiveRider)? inActiveRider: activeRidersPercentage?.count?.inActive,
            // (applyFilter && activeRider == 0) ? 0.1: (activeRider)? activeRider: activeRidersPercentage?.count?.active,

          ],
          backgroundColor: [inActiveRider==0?'#e5e5e5':"#EB3D3D",activeRider==0?'#e5e5e5': "#13B542"],
          hoverBackgroundColor: [inActiveRider==0?'#e5e5e5':"#EB3D3D",activeRider==0?'#e5e5e5': "#13B542"],
          borderWidth: 0,
        },
      ],
    });
  }, [topCardRider, activeRidersPercentage,riderData]);

  return (
    <>
 

      <div className="section-rider cards-section h-100 zoom-90">
        <div className="card-nav " style={{ marginBottom: "32px" }}>
         <h2 className="box-title mb-0 fw-bold"> Total Riders : {(applyFilter?(activeRider) : riderCount?.active)+(applyFilter?(inActiveRider) : riderCount?.inActive)} </h2>
          <div>
            <div className="dropdown">
              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "80px" }}
                >
                  <option value="day">Riyadh</option>
                </select>
              </div>
              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "80px" }}
                >
                  <option value="day">Male</option>
                </select>
              </div>
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        <Doughnut
          type="doughnut"
          data={dataResult}

          options={chartOptions}
        />
        <div className="flex-cb mt-32">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Online: 
              <span className="percenPoint">{applyFilter?(activeRider) : riderCount?.active}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Offline:
              <span className="percenPoint">{applyFilter?(inActiveRider) : riderCount?.inActive}</span>
              </span>
            </div>
        </div>
      </div>
    </>
  );
}

export default TotalRider;
