import { Col, Row } from "react-bootstrap";
const TotalOnlineCaptains = ({ totalOnlineCaptains, completed, waitingCaptains, issuesOnTrip }) => {
  return (
    <Row>
      <Col md={12}>
        <h2 className="box-title">
          Total Online captains :
          <span className="text-success font-weight-bold">{totalOnlineCaptains}</span>
        </h2>
      </Col>
      <Col md={12}>
        <div className="inner-box mt-3">
          <div className="row">
            <div className="col-lg-4 ">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="d-flex align-items-center">
                  <div className="green-box "></div>
                  <div className="trips-title-label ml-2">On trip captains</div>
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {completed}
                </div>
              </div>
            </div>
            <div className="col-lg-4 border-custom ">
              <div className="flex-sb">
                <div className="flex-d">
                  <div className="orange-box "></div>
                  <div className="trips-title-label ml-2">
                    Waiting captains
                  </div>
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {waitingCaptains}
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="flex-sb">
                <div className="flex-d">
                  <div className="red-box "></div>
                  <div className="trips-title-label ml-2">
                    Issues on trip
                  </div>
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {issuesOnTrip}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default TotalOnlineCaptains