import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import APIService from "../../services/APIService";
import { compose } from "redux";

let EmailNotificationListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [templates, setTemplates] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [receiver, setReceiver] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/notification/notification-emails/')),
    updatePermission = currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update'),
    dt = useRef(null),
    receivers = ["Rider", "Driver", "Admin", "Other"],
    [receiversFilter, setReceiversFilter] = useState([]),
    getTemplates = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/templates/email")
        .then((response) => {
          let receiversData = [];
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 200) {
            let notificationData = response.data.data.map((template) => {
              receiversData.push(receivers[template.receiver - 1]);
              template.status = template.status ? "Active" : "Inactive";
              template.receiver = receivers[template.receiver - 1];
              return template;
            });
            receiversData.length &&
              receiversData.sort(function (a, b) {
                return receivers.indexOf(a) - receivers.indexOf(b);
              });
            let unique = receiversData.filter(
              (item, i, ar) => ar.indexOf(item) === i
            );
            setReceiversFilter([...unique, ...receiversFilter]);
            setTemplates(notificationData);
          }
        })
        .catch(() => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    statuses = ["Active", "Inactive"],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          {updatePermission ? <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() =>
              props.history.push(`/notification-email-edit/${rowData.id}`)
            }
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button> : <span>No permission</span>}
        </div>
      );
    };
  useEffect(() => {
    getTemplates();
  }, []);

  let updateDataAPIcall = (e, id) => {
    APIService.patch(`admin/templates/email-status/${id}`, {
      status: e.target.checked,
    })
      .then((response) => {
        if (response && response.data.statusCode === 200) {
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      });
  };

  let activeActionTemplate = (rowData) => {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`customSwitch${rowData.id}`}
          defaultChecked={rowData.status === "Active" ? true : false}
          onChange={(e) => updateDataAPIcall(e, rowData.id)}
          disabled={!updatePermission}
        />
        <label
          className="custom-control-label"
          style={{ cursor: "pointer" }}
          for={`customSwitch${rowData.id}`}
        ></label>
      </div>
    );
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={templates}
          header={header}
          className="p-datatable-templates"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          paginator
          rows={25}
          emptyMessage="No Notification(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            style={{ fontSize: "12px", width: "270px" }}
            header="Template Name"
            field="templateName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by title"
          />
          <Column
            style={{ fontSize: "12px", width: "120px" }}
            header="Receiver"
            field="receiver"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterElement={
              <Dropdown
                value={receiver}
                options={receiversFilter}
                onChange={(e) => {
                  dt.current.filter(e.value, "receiver", "equals");
                  setReceiver(e.value);
                }}
                placeholder="Select a Receiver"
                className="p-column-filter"
                showClear
              />
            }
            filterPlaceholder="Search by receiver"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statuses}
                onChange={(e) => {
                  dt.current.filter(e.value, "status", "equals");
                  setSelectedStatus(e.value);
                }}
                placeholder="Status"
                className="p-column-filter"
                showClear
              />
            }
            body={activeActionTemplate}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
            filterPlaceholder="Minimum"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(
  compose(withRouter, connect())(EmailNotificationListing)
);
