import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import saveAs from "save-as";
import moment from "moment";

let ReportsDeclineRequestByCaptainListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [selectedDeclineRequest, setSelectedDeclineRequest] = useState([]),
    [declineRequest, setDeclineRequest] = useState(null),
    [createdAt, setCreatedAt] = useState([]),
    [selectedStatus, setSelectedStatus] = useState(null),
    [selectedDriverStatus, setSelectedDriverStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    statusOptions = [
      { value: "A", label: "Active" },
      { value: "L", label: "Locked" },
      { value: "I", label: "Inactive" },
    ],
    driverStatusOptions = [
      { value: true, label: "On" },
      { value: false, label: "Off" },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "driverName",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getDeclineRequestCaptain = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/reports/trips-declined-by-captain", filterParams)
        .then((response) => {
          if (response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setDeclineRequest(response.data.data.trips);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters) {
        if (Object.keys(event.filters).length > 0) {
          lazyParams.take = event.rows ? event.rows : lazyParams.take;
          lazyParams.skip = 0;
          let filters = { ...lazyParams.filters };
          for (let filter in event.filters) {
            if (event.filters[filter].value) {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
          lazyParams.filters = { ...filters, ...lazyParams.filters };
        } else if (!event.filters || !Object.keys(event.filters).length) {
          lazyParams.filters = {};
        }
      }
      setLazyParams(lazyParams);
      getDeclineRequestCaptain();
    },
    exportDeclineRequest = () => {
      let declineReq = [
        "Driver Id",
        "Driver Name",
        "Declined Request",
        "Overall Rating",
        "Driver status",
        "Total Trips",
        "Status",
      ];
      declineReq = declineReq.join(",") + "\r\n";
      if (selectedDeclineRequest.length) {
        selectedDeclineRequest.forEach((declineReqData) => {
          let status = "";
          if (declineReqData.userStatus === "A") {
            status = "Active";
          } else if (declineReqData.userStatus === "L") {
            status = "Locked";
          } else {
            status = "Inactive";
          }
          declineReq +=
            [
              "'" + declineReqData.userId,
              declineReqData.fullName,
              declineReqData.tripsDeclined,
              declineReqData.overallRating,
              declineReqData.overallReviews,
              declineReqData.totalTrips,
              status,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([declineReq], {
            type: "text/csv;charset=utf-8",
          }),
          "Decline-Request-By-Captain-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.take = 1000000000;
        filterParams.skip = 0;
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/reports/trips-declined-by-captain", filterParams)
          .then((response) => {
            if (response.data.statusCode === 200) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              lazyParams.totalRecords = response.data.data.totalCount;
              response.data.data.trips.forEach((declineReqData) => {
                let status = "";
                if (declineReqData.userStatus === "A") {
                  status = "Active";
                } else if (declineReqData.userStatus === "L") {
                  status = "Locked";
                } else {
                  status = "Inactive";
                }
                declineReq +=
                  [
                    "'" + declineReqData.userId,
                    declineReqData.fullName,
                    declineReqData.tripsDeclined,
                    declineReqData.overallRating,
                    declineReqData.overallReviews,
                    declineReqData.totalTrips,
                    status,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([declineReq], {
                  type: "text/csv;charset=utf-8",
                }),
                "Decline-Request-By-Captain-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getDeclineRequestCaptain();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters = {
                        ...lazyParams.filters,
                        createdAt: [
                          moment(e.value[0]).format("MM-DD-YYYY"),
                          moment(e.value[1]).format("MM-DD-YYYY"),
                        ],
                      };
                    }
                  } else {
                    setCreatedAt(null);
                    lazyParams.filters = {
                      ...lazyParams.filters,
                    };
                    delete lazyParams.filters.createdAt;
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDeclineRequestCaptain();
                  }, 500);
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportDeclineRequest}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    countryBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <img
            src={
              rowData.profileImage
                ? rowData.profileImage
                : process.env.PUBLIC_URL + `/images/user.png`
            }
            width="50"
            height="50"
            onError={(e) =>
              (e.target.src = process.env.PUBLIC_URL + `/images/user.png`)
            }
            alt="driverphoto"
          />
        </React.Fragment>
      );
    },
    overallRatingBody = (rowData) => {
      return (
        <React.Fragment>
          <Rating
            className="ratings pl-0 d-flex justify-content-center"
            fractions={2}
            emptySymbol="fa fa-star-o fa-2x star"
            fullSymbol="fa fa-star fa-2x star"
            initialRating={rowData.overallRating}
            readonly
          />
        </React.Fragment>
      );
    },
    driverStatusBody = (rowData) => {
      return (
        <React.Fragment>
          {rowData.driverModeSwitch ? "On" : "Off"}
        </React.Fragment>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setSelectedDeclineRequest([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "driverName",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
        keyword: "",
      });
      getDeclineRequestCaptain();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={declineRequest}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedDeclineRequest}
          onSelectionChange={(e) => setSelectedDeclineRequest(e.value)}
          paginator
          emptyMessage="No Decline Request(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Photo"
            body={countryBodyTemplate}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Driver Id"
            field="userId"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver name"
            field="fullName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Name"
            filterField="driverName"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Declined Request"
            // body={dateBodyTemplate}
            field="tripsDeclined"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Declined request"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Overall Rating"
            body={overallRatingBody}
            // sortable
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Driver status"
            field="driverModeSwitch"
            body={driverStatusBody}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedDriverStatus}
                options={driverStatusOptions}
                onChange={(event) => {
                  setSelectedDriverStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["driverModeSwitch"] = event.value;
                  } else if (event.value === false) {
                    lazyParams.filters["driverModeSwitch"] = event.value;
                  } else {
                    delete lazyParams.filters["driverModeSwitch"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDeclineRequestCaptain();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Trips"
            field="totalTrips"
            // body={representativeBodyTemplate}
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by trips"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="userStatus"
            header="Status"
            body={(declineReqData) => {
              if (declineReqData.userStatus === "A") {
                return "Active";
              } else if (declineReqData.userStatus === "L") {
                return "Locked";
              } else {
                return "Inactive";
              }
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Status"
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDeclineRequestCaptain();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsDeclineRequestByCaptainListing));
