import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import APIService from "../../services/APIService";

function MarketingSubscription(props) {
  let windowWidth = window.innerWidth;
  const [adjustData, setAdjustData] = useState(null);
  const [currentFilterCard, setCurrentFilterCard] = useState('');
  const [adjustFilterData, setAdjustFilterData] = useState({});
  const [show,setShow]=useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl =
  `http://uat-api.ride.sa/admin/get-adjust-data`;
  const [date, setdate] = useState('this_month');

  useEffect(() => {
    getAdjustmoduleData();

  }, []);
  useEffect(() => {
    getAdjustmoduleFilterData(currentFilterCard);
  }, [currentFilterCard,date]);
  // Set up the request headers
  const headers = {
    "Authorization": "Bearer vb5mPMy-91kxwzxdQRvM",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // Define the report dimensions and metrics
  const dimensions = "channel";
  const metrics =
  "installs,attribution_impressions,ctr,attribution_clicks,limit_ad_tracking_installs,limit_ad_tracking_install_rate,click_conversion_rate,reattributions,sessions,daus,waus,maus,apply promo_events,apply promo failure_events,apply promo success_events,bank transaction failure_events,bank transaction for subscription event_events,bank transaction success_events,car confirmation failed_events,car confirmation success_events,car not found_events,car selection failed_events,car selection success_events,card saving_events,card skipped_events,confirm pickup_events,confirm ride_events,driver signup_events,general revenue_events,iban added_events,iban added failed_events,iban added success_events,kyc attempts_events,kyc attempts failed_events,kyc attempts success_events,login sessions_events,otp failure_events,otp for payment_events,otp success_events,process after car selection_events,ride acceptance failed_events,ride acceptance success_events,ride cancelled (after confirmation)_events,ride cancelled (before confirmation)_events,rides accepted_events,select car_events,send unifornic otp_events,sequence added_events,sequence failed_events,sequence number count_events,sequence success_events,sign up event_events,subscribe package failed_events,subscribe package success_events,subscription package unsubscribed_events,subscription packages count_events,subscription transaction failed_events,subscription transaction success_events,tamm otp failure_events,tamm otp success_events,topup failed_events,topup success_events,unifonic otp failed_events,unifonic otp verified_events,wasl approval_events,wasl rejection_events";

  // Set the report period and app name
  const date_period = date;
  const app_name = "RiDE";
  const channel = "all";

  // Define the request parameters
  const params = {
    dimensions,
    metrics,
    date_period,
    App: app_name,
    channel,
    authToken: "Bearer u_-sZWyibxDAV6ThbpXW",
    apiUrl : "https://dash.adjust.com/control-center/reports-service/report"
  };
  
  const getTimeRange = async(range,cardName) => {
    setCurrentFilterCard(cardName);
    const statsObj = {
      fromDate: range.sDate,
      toDate: range.eDate,
    };
   const formattedDate=`${moment(statsObj?.fromDate,"DD/MM/YYYY").format("YYYY-MM-DD")}:${moment(statsObj?.toDate,"DD/MM/YYYY").format("YYYY-MM-DD")}`;
  setdate(formattedDate);

  };
  // Make the API request
  const getAdjustmoduleData = async() => {
    props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setLoading(true);
    await APIService
    .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          setAdjustData(response?.data?.data);
          setAdjustFilterData(response?.data?.data?.totals);
          setLoading(false);
        }
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
    setShow(true)
      })
      .catch((error) => {
        console.error(error);
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
    setShow(true)
      });
  };
  const getAdjustmoduleFilterData = async(cardName) => {
    props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setShow(false)
    setLoading(true);
    await APIService
      .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          if(cardName== 'promocodes'){
            setAdjustFilterData({...adjustFilterData,"apply promo failure_events":Number(`${response?.data?.data?.totals["apply promo failure_events"]}`),"apply promo success_events":Number(`${response?.data?.data?.totals["apply promo success_events"]}`)});
          }
          else if(cardName== 'subscription'){
            setAdjustFilterData({...adjustFilterData,"subscribe package failed_events":Number(`${response?.data?.data?.totals["subscribe package failed_events"]}`),"subscribe package success_events":Number(`${response?.data?.data?.totals["subscribe package success_events"]}`)});
          }
          setLoading(false);
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
    setShow(true)
        }
      })
      .catch((error) => {
        console.error(error);
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });

      });
  };

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth < 1200 ? 45 : 90,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: loading ? false : true,
      },
      legend: {
        display:  true,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 200,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  const successAndFailedPercentage = (success , failure ,type)=>{
    let total = Number(success) + Number(failure);
    switch(type){
      case "success":
        let successPercentage = (success*100)/total;
        // console.log(successPercentage)
        if(!successPercentage){return `0 %`}
        // return `${successPercentage.toFixed(2)} %`
        return `${Math.round(successPercentage.toFixed(2))} %`
      case "failure":
        let failedPercentage = (failure*100)/total;
        if(!failedPercentage){return `0 %`}
        // return `${failedPercentage.toFixed(2)} %`
        return `${Math.round(failedPercentage.toFixed(2))} %`
      default:
        return ""
    }
  }
  const chartDataForSubscriptionPackages = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? 0.1 : adjustFilterData["subscribe package failed_events"],
          loading ? 0 : adjustFilterData["subscribe package success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["subscribe package failed_events"] < 1 && adjustFilterData["subscribe package success_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Success `, `Failed `],
  };
  const chartDataForPromocodes = {
    datasets: [
      {
        data: [
          loading ? 0.1 : adjustFilterData["apply promo failure_events"],
          loading ? 0 : adjustFilterData["apply promo success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["apply promo failure_events"] < 1 && adjustFilterData["apply promo success_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Success `, `Failed `],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `Total : ${loading ? 0 : chart?.data?.datasets[0]?.data[0] == 0.1 ? 0 :chart?.data?.datasets[0]?.data[0] + chart?.data?.datasets[0]?.data[1]}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  return (
    <>
    {show&&(
      <div className="row m-4 justify-content-between">
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            {" "}
            <div className="  d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Subscription Packages</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'subscription')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForSubscriptionPackages}
                plugins={plugins}
                options={chartOptions}
              />
                                    <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["subscribe package success_events"],adjustFilterData["subscribe package failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["subscribe package success_events"],adjustFilterData["subscribe package failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
              {/* <Link to="/subscription-packages">View all</Link>  */}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            {" "}
            <div className="  d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Promocodes</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'promocodes')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForPromocodes}
                plugins={plugins}
                options={chartOptions}
              />
                                    <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["apply promo success_events"],adjustFilterData["apply promo failure_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["apply promo success_events"],adjustFilterData["apply promo failure_events"],"failure")}</span>

              </span>
            </div>
        </div>
              {/* <Link to="/promocodes">View all</Link> */}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10  p-4 h-100 rounded"></div>
        </div>
      </div>
    )}
    </>
  );
}
export default MarketingSubscription;
