import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import riderAvtSec from "../../assest/images/carimage.png";
import { Data , ModalData , Manufacturer , Modalyear , TotalCars , ActiveInactive , CarDetailTable , OwnACarData,manufacturerData,modelandyearData,categoryData} from "./Data";
import CarInventoryHeader from "./components/CarInventoryHeader";
import SelectOptions from "./components/SelectOptions";

let RidearideDetails = (props) => {
  let [selectedStatus, setSelectedStatus] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [fillRider, setFillRider] = useState([]),
    [subStatus, setSubStatus] = useState(null),
    [selectedRows, setSelectedRows] = useState([]),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [drivers, setDrivers] = useState([]),
    getDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/captains", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);


            const Response = response.data.data.captains;
            var filterResponse = Response.map((item) => {
              return (
                item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
                item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
                item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
              )
            })
            setFillRider(filterResponse)
            setDrivers(Response);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    renderHeader = () => {
      return (
        <div className="d-flex flex-column">
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getDrivers();
              }}
            />
          </span>
          <button className="btn btn-primary float-right mb-1 ml-12" onClick={() => {}} >
            Export
          </button>
          <button className="btn btn-danger float-right mb-1 ml-12" onClick={() => {}} >
            Block/UnBlock
          </button>
        </div>
        <div className="d-flex">
        <SelectOptions data = {manufacturerData} name="Manufacturer"/>
        <SelectOptions data = {modelandyearData} name="Model & Year"/>
        <SelectOptions data = {categoryData} name="Category"/>
        </div>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getDrivers();
    };


  useEffect(() => {
    getDrivers();
  }, []);

  const onRowSelect = (event) => {
    console.log(event)
    setSelectedRows(event.data);
  };

  const onRowUnselect = (event) => {
    console.log(event)
    setSelectedRows(selectedRows.filter((row) => row !== event.data));
  };

  const rowSelectionTemplate = (rowData) => {
    const isSelected = selectedRows.includes(rowData);
    return (
      <div className={`checkbox ${isSelected ? 'selected' : ''}`}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => handleRowCheckboxChange(rowData)}
        />
      </div>
    );
  };

  const handleRowCheckboxChange = (rowData) => {
    const isSelected = selectedRows.includes(rowData);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((row) => row !== rowData));
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  return (
    <div className="datatable-doc-demo mt-3">
        <div className="card">
        <DataTable
          ref={dt}
          value={OwnACarData}
          header={header}
          selectionMode="multiple"  
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          onRowSelect={onRowSelect}
          onRowUnselect={onRowUnselect}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Car(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={OwnACarData.length}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          // loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
<Column selectionMode="multiple" style={{ width: "2em" }} body={rowSelectionTemplate} />
<Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Car Image"
            field="carImage"
            body={() => {
              return (
                                        <img
                      src={riderAvtSec}
                      alt="ride"
                      style={{
                        marginRight: "15px",
                        width: "65px",
                        objectFit: "cover",
                      }}
                    />
              );
            }}
            sortable
          />
                    <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Manufacturer"
            field="manufacturer"
            sortable
            // filterPlaceholder="Search by Services"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Sequence no."
            field="sequenceno"
            sortable
            filterMatchMode="custom"
            // filterFunction={customFunction}
            // filterPlaceholder="Search by name"
          />
                              <Column
            className="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Model & Year"
            field="modelandyear"
            sortable
            // filterPlaceholder="Search by Services"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain/Pickup"
            field="captainpickup"
            sortable
            // filterPlaceholder="Search by Services"
          />
                    <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Category"
            field="category"
            sortable
            // filterPlaceholder="Search by Services"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Car Colour"
            field="carcolour"
            sortable
            filterPlaceholder="Search by Loyalty points"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Seating"
            field="seating"
            sortable
            filterPlaceholder="Search by mobile"
          />

<Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain ID"
            field="captainId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by mobile"
          />

<Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain Name"
            field="captainname"
            sortable
            filterPlaceholder="Search by mobile"
          />
                    <Column
            className="text-center"
            style={{ fontSize: "12px", width: "145px" }}
            field="userStatus"
            header="status"
            body={(car) => {
                // console.log(driver)
              return car.status ? (
                <span className="status-text">Active</span>
              ) : (
                <span className="status-text-inactive">InActive</span>
              );
            }}
            sortable
            />

        </DataTable>
      </div >
    </div >
  );
};

export default React.memo(connect()(withRouter(RidearideDetails)));
