import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { CalendarRange } from "react-bootstrap-icons";
import Rating from "react-rating";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import moment from "moment";
import { saveAs } from "save-as";

let ReportsDriversEarningListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [earnings, setEarnings] = useState([]);
  let [createdAt, setCreatedAt] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  let dt = useRef(null);
  let [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "totalEarned",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
    }),
    [selectedDrivers, setSelectedDrivers] = React.useState([]),
    [totalEarning, setTotalEarning] = useState(0),
    getDriverEarnings = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/reports/captains-earnings", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setEarnings(response.data.data.earnings);
            setTotalEarning(response.data.data.totalEarnings);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDriverEarnings();
    },
    exportDriversEarning = () => {
      let drivers = [
        "Driver Id",
        "Driver Name",
        "Packages Subscribed",
        "Earned",
        "Total Trips",
        "Driver Status",
      ];
      drivers = drivers.join(",") + "\r\n";
      if (selectedDrivers.length) {
        selectedDrivers.forEach((driver) => {
          let status = driver.driverModeSwitch ? "Active" : "Inactive";
          drivers +=
            [
              "'" + driver.userId,
              driver.fullName,
              driver.totalSubscriptions,
              driver.totalEarned,
              driver.totalTrips,
              status,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([drivers], {
            type: "text/csv;charset=utf-8",
          }),
          "Drivers-Earning-Report.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 100000000000;

        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/reports/captains-earnings", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              let allDrivers = response.data.data.earnings;
              allDrivers.forEach((driver) => {
                let status = driver.driverModeSwitch ? "Active" : "Inactive";
                drivers +=
                  [
                    "'" + driver.userId,
                    driver.fullName,
                    driver.totalSubscriptions,
                    driver.totalEarned,
                    driver.totalTrips,
                    status,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([drivers], {
                  type: "text/csv;charset=utf-8",
                }),
                "Drivers-Earning-Report.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getDriverEarnings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["createdAt"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getDriverEarnings();
                      }, 500);
                    }
                  } else {
                    setCreatedAt([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getDriverEarnings();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1 d-flex">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportDriversEarning}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setTotalEarning(0);
      setLazyParams({
        filters: {},
        sort: {
          field: "totalEarned",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
      });
      getDriverEarnings();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <h4 className="text-right mb-3">Overall Earnings: {totalEarning} SAR</h4>
      <div className="card">
        <DataTable
          ref={dt}
          value={earnings}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver's Earning(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          selection={selectedDrivers}
          onSelectionChange={(e) => setSelectedDrivers(e.value)}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageRiders}
          onSort={manageRiders}
          onFilter={manageRiders}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Photo"
            body={(earning) => {
              return (
                <React.Fragment>
                  <img
                    className="p-column-listing-photo"
                    src={
                      earning.profileImage
                        ? earning.profileImage
                        : process.env.PUBLIC_URL + `/images/user.png`
                    }
                    width="50"
                    height="50"
                    alt="driver"
                  />
                </React.Fragment>
              );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Driver Id"
            field="userId"
            body={(earning) => {
              return (
                <Link to={"driver-details/details/" + earning.driverId}>
                  {earning.userId}
                </Link>
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver name"
            field="fullName"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Name"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortable
            field="overallRating"
            header="Overall Rating"
            body={(driver) => (
              <Rating
                className="ratings pl-0 d-flex justify-content-center"
                fractions={2}
                emptySymbol="fa fa-star-o fa-2x star"
                fullSymbol="fa fa-star fa-2x star"
                initialRating={driver.overallRating}
                readonly
              />
            )}
            // sortable
            filter={false}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "220px" }}
            header="Packages Subscribed"
            field="totalSubscriptions"
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />
          <Column
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "150px" }}
            header="Earned"
            field="totalEarned"
            body={(rider) => rider.totalEarned?.toFixed(2)}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Earning"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Trips"
            field="totalTrips"
            sortable
            filter={showFilter}
            filterPlaceholder="Search Trips"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Driver status"
            field="approved"
            body={(driver) => (driver.approved ? "Active" : "Inactive")}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["approved"] = true;
                  } else if (event.value === false) {
                    lazyParams.filters["approved"] = false;
                  } else {
                    delete lazyParams.filters["approved"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDriverEarnings();
                  }, 500);
                }}
                placeholder="Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsDriversEarningListing));
