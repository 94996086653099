import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import { connect } from "react-redux";
import calender from "../../assest/images/calender.svg";
import redCard from "../../assest/images/red-card.svg";
import greenCard from "../../assest/images/green-card.png";
import { EmptyData } from "../Dashboard_2/linechartdata/linechartdata";
let barChartOptions = {
  responsive: true,
  type: "line",
  barPercentage: 5,
  borderRadius: 5,
  plugins: {
    legend: {
      display: false,
    },
  },
  // scales: {
  //   x: {
  //     display: true,
  //     gridLines: {
  //       display: false,
  //     },
  //   },
  //   y: {
  //     display: true,
  //     gridLines: {
  //       display: false,
  //     },
  //   },
  // },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};
let AppUsageChart = (props) => {
  const { riderId } = props;
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;

  let statusCounts = graphCountList
    ? graphCountList
    : props?.sendgraphTripData?.percentaged;
  useEffect(() => {
    tripSummaryList();
  }, [props.sendgraphTripData]);

  let tripSummaryList = () => {
    setGraphTripList(graphData && graphData?.graphList);
    let chartData = [
        graphData?.percentaged?.completed,
        graphData?.percentaged?.cancelled,
        graphData?.percentaged?.unmatched,
        graphData?.percentaged?.scheduled,
      ],
      labels = [
        "Done " + graphData?.percentaged.completed + "%",
        "Cancelled " + graphData?.percentaged?.cancelled + "%",
        "Unmatched " + graphData?.percentaged?.unmatched + "%",
      ];
  };

  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

  useEffect(() => {
    if (props.timeRange === "day") {
      if (graphTripList && graphTripList.length === 0) {
        setGraphTripList({
          labels: [],
          datasets: [],
        });
      }
    }

    if (graphTripList && graphTripList.length > 0) {
      let key = [];
      let objArr = [
        {
          label: "mints",
          value: 0,

          data: [],
          backgroundColor: "rgba(19, 181, 66, 1)",
          borderColor: "rgba(19, 181, 66, 1)",
          borderWidth: 3,
          lineTension: 0.3,
          pointRadius: 0,
          pointHitRadius: 30,
          pointHoverRadius: 7,
          hoverBackgroundColor: "rgba(19, 181, 66, 1)",
          hoverBorderColor: "rgba(19, 181, 66, 1)",
        },
      ];

      for (let arrItem of objArr) {
        for (let item of graphTripList) {
          for (let itemData of item.data) {
            if (itemData.key === arrItem.label) {
              arrItem.data.push(itemData.value);
            }
          }
        }
      }

      for (let itemLabel of graphTripList) {
        key.push(itemLabel && itemLabel.key);
      }

      setGraphTripList({
        labels: key,
        datasets: objArr,
      });
    }
  });

  let manageTimeRange = (event) => {
    // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setTimeRange(event.target.value);
    APIService.post("admin/time-consume/" + riderId, {
      type: event.target.value,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });

          setGraphTripList(
            response.data && response.data.data && response.data.data.graphList
          );
          setgraphCountList(
            response.data &&
              response.data.data &&
              response.data.data.percentaged
          );
          let chartData = [
              response.data.data.percentaged.completed,
              response.data.data.percentaged.cancelled,
              response.data.data.percentaged.unmatched,
              response.data.data.percentaged.scheduled,
            ],
            labels = [
              "Done " +
                (!response.data.data.percentaged.completed
                  ? 0
                  : response.data.data.percentaged.completed) +
                "%",
              "Cancelled " +
                (!response.data.data.percentaged.cancelled
                  ? 0
                  : response.data.data.percentaged.cancelled) +
                "%",
              "Unmatched " +
                (!response.data.data.percentaged.unmatched
                  ? 0
                  : response.data.data.percentaged.unmatched) +
                "%",
            ];
        }
      })
      .catch((error) => {
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });

    let key = [];
    let objArr = [
      {
        label: "mints",
        value: 0,
        lineTension: 0.3,
        data: [],
        backgroundColor: "rgba(19, 181, 66, 1)",
        borderColor: "rgba(19, 181, 66, 1)",
        borderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 30,
        pointHoverRadius: 7,
        hoverBackgroundColor: "rgba(19, 181, 66, 1)",
        hoverBorderColor: "rgba(19, 181, 66, 1)",
      },
    ];

    if (graphTripList && graphTripList.length > 0) {
      for (let arrItem of objArr) {
        for (let item of graphTripList) {
          for (let itemData of item.data) {
            if (itemData.key === arrItem.label) {
              arrItem.data.push(itemData.value);
            }
          }
        }
      }
      for (let itemLabel of graphTripList) {
        key.push(itemLabel && itemLabel.key);
      }
    }
    setGraphTripList({
      labels: key,
      datasets: objArr,
    });
  };

  const data = {
    labels: [23, 24, 25, 26, 27, 28, 29],
    datasets: [
      {
        data: [23, 24, 25, 26, 27, 28, 29],

        backgroundColor: "rgba(75,192,192,0.2)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774",
      },
      {
        label: "Second dataset",
        data: [12, 21, 25, 51, 34, 26],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };
  let topupData = () => {
    if (riderId) {
      APIService.post("admin/time-consume/" + riderId, {
        type: "week",
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            setGraphTripList(
              response.data &&
                response.data.data &&
                response.data.data.graphList
            );
            setgraphCountList(
              response.data &&
                response.data.data &&
                response.data.data.percentaged
            );
            let chartData = [
                response.data.data.percentaged.completed,
                response.data.data.percentaged.cancelled,
                response.data.data.percentaged.unmatched,
                response.data.data.percentaged.scheduled,
              ],
              labels = [
                "Done " +
                  (!response.data.data.percentaged.completed
                    ? 0
                    : response.data.data.percentaged.completed) +
                  "%",
                "Cancelled " +
                  (!response.data.data.percentaged.cancelled
                    ? 0
                    : response.data.data.percentaged.cancelled) +
                  "%",
                "Unmatched " +
                  (!response.data.data.percentaged.unmatched
                    ? 0
                    : response.data.data.percentaged.unmatched) +
                  "%",
              ];
          }
        })
        .catch((error) => {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        });

      let key = [];
      let objArr = [
        {
          label: "mints",
          value: 0,
          lineTension: 0.3,
          data: [],
          backgroundColor: "rgba(19, 181, 66, 1)",
          borderColor: "rgba(19, 181, 66, 1)",
          borderWidth: 3,
          pointRadius: 0,
          pointHitRadius: 30,
          pointHoverRadius: 7,
          hoverBackgroundColor: "rgba(19, 181, 66, 1)",
          hoverBorderColor: "rgba(19, 181, 66, 1)",
        },
      ];

      if (graphTripList && graphTripList.length > 0) {
        for (let arrItem of objArr) {
          for (let item of graphTripList) {
            for (let itemData of item.data) {
              if (itemData.key === arrItem.label) {
                arrItem.data.push(itemData.value);
              }
            }
          }
        }
        for (let itemLabel of graphTripList) {
          key.push(itemLabel && itemLabel.key);
        }
      }
      setGraphTripList({
        labels: key,
        datasets: objArr,
      });
    }
  };

  useEffect(() => {
    topupData();
  }, [riderId]);


  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  const date = new Date();
  let currentMonth = month[date.getMonth()];
  let currentYear = date.getFullYear()

 
  return (
    <div className="d-box h-100">
      <div className="head d-lg-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between mb-3">
        <div style={{ display: "flex" }}>
          <div className="title">
            <h2 className="box-title">
              App Usage &nbsp;
              {/* <span style={{ color: "#333333", fontWeight: 700 }}>
              {totalRiders}
            </span> */}
            </h2>
          </div>
          <div className="selectbox d-flex align-items-left">
            {/* <span className="show-by-title">Show by </span> */}
            <select
              value={timeRange}
              className="select outline-none border-0 "
              onChange={manageTimeRange}
            >
              <option value="day">Today</option>
              <option value="week">Last 7 Days</option>
              <option value="month">By Week</option>
              <option value="year">By Month</option>
            </select>
          </div>
        </div>
        <div>
          <img src={calender} alt="ride" style={{ marginLeft: "26px" }} />
        </div>
      </div>

      <div>
        <Line
          data={graphTripList!==undefined && graphTripList.length >0 ? graphTripList : EmptyData}
          options={barChartOptions}
          width={100}
          height={100}
        />
        <h6 className=" graph-label-2 left-text rounded text-capitalize">
          `{currentMonth}   {currentYear}`
        </h6>
      </div>
      {/* <div className="view-report">
          <Link to="/reports-riders">View Report</Link>
        </div> */}
    </div>
  );
};
export default AppUsageChart;
