import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Link, useParams } from "react-router-dom";
import { Slider } from "primereact/slider";
import APIService from "../../services/APIService";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { CalendarRange } from "react-bootstrap-icons";

let Spendings = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let { id } = useParams();
  let [ridersTripsData, setRidersTripsData] = useState([]);
  let [filterStatus, setFilterStatus] = useState(null);
  let [createdAt, setCreatedAt] = useState(null);
  let [showFilter, setShowFilter] = useState(false);
  let [ratingFilterRange, setRatingFilterRange] = useState([0, 5]);
  let dt = useRef(null);
  let [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: { field: "createdAt", order: -1 },
      skip: 0,
      take: 25,
      totalRecords: 0,
      keyword: "",
    }),
    getRiderTrips = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post(`admin/rider-trip-history/${id}`, filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            const filteredHomes = response.data.data.trips.filter(
              (x) => x.status === 8
            );

            setRidersTripsData(filteredHomes);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getRiderTrips();
    },
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setFilterStatus(null);
      setCreatedAt(null);
      setLazyParams({
        filters: {},
        sort: { field: "createdAt", order: -1 },
        skip: 0,
        take: 25,
        totalRecords: 0,
        keyword: "",
      });
      getRiderTrips();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getRiderTrips();
  }, []);

  let statuses = [
      //{ label: "Pending", value: 1 }
      { label: "Accepted By Driver", value: 2 },
      { label: "Rejected By Driver", value: 3 },
      { label: "Cancelled By Driver", value: 4 },
      { label: "Driver Arrived", value: 5 },
      { label: "Cancelled By Rider", value: 6 },
      { label: "Started", value: 7 },
      { label: "Completed", value: 8 },
      //{ label: "No Driver", value: 9 },
      //{ label: "Expired", value: 10 },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <i className="fa fa-filter"></i>{" "}
                  <span className="span-filter">Filters</span>
                  <i className="fa fa-caret-up ml-100"></i>
                </>
              ) : (
                <>
                  <i className="fa fa-filter"></i>{" "}
                  <span className="span-filter">Filters</span>
                  <i className="fa fa-caret-down ml-100"></i>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getRiderTrips();
              }}
              placeholder="Search"
            />
          </span>
        </div>
      );
    },
    header = renderHeader();

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={ridersTripsData}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageTripHistory}
          onSort={manageTripHistory}
          onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="overallRating"
            header="Rating"
            body={
              (rider) => {
                if (rider.overallRating == 0) {
                  return "--";
                } else {
                  return ratingCount(Math.round(rider.overallRating));
                }
              }
              // <Rating
              //   className="ratings pl-0 d-flex justify-content-center"
              //   fractions={2}
              //   emptySymbol="fa fa-star-o fa-2x star"
              //   fullSymbol="fa fa-star fa-2x star"
              //   initialRating={rider.overallRating}
              //   readonly
              // />
            }
            sortable
            filter={false}
          /> */}

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            sortField="riderId"
            filterField="riderId"
            header="User ID"
            body={(trip) => trip.rider && trip.rider.userId}
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Amount"
            field="tripBaseAmount"
            body={(trip) => {
              return trip?.riderAmount;
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search subtotal"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Currency"
            field="tripBaseAmount"
            body={(trip) => {
              return "SAR";
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            sortField="status"
            filterField="status"
            header="Status"
            // body={(trip) => {
            //   return statuses.find((sts) => sts.value === trip.status).label;
            // }}
            body={(trip) => {
              if (trip.status === 8) {
                return <span className="status-text">Completed</span>;
              } else {
                return <span className="status-text-inactive">Cancelled</span>;
              }
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={filterStatus}
                options={statuses}
                onChange={(event) => {
                  setFilterStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getRiderTrips();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(Spendings));
