import React, { memo } from 'react'
import { useRef, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { useSelector } from 'react-redux';

const PrimeDataTable = ({ notificationData, columns }) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [globalFilter, setGlobalFilter] = useState(null);
  let [showFilter, setShowFilter] = useState(false);
  let renderHeader = () => {
    return (
      <div className="table-header">
        <span>
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              <i className="fa fa-filter"></i>
            ) : showFilter ? (
              "Hide Filter"
            ) : (
              "Show Filter"
            )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </span>
      </div>
    );
  },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      dt.current.reset();
    },
    dt = useRef(null)
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={notificationData}
          header={header}
          className="p-datatable-customers"
          dataKey="userId"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          paginator
          rows={25}
          emptyMessage="No Notification(s) Detail found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          {
            columns.map(col =>
              <Column
                style={{ fontSize: "12px", width: "270px" }}
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                filter={showFilter}
                filterMatchMode="contains"
                filterPlaceholder="Search"
                body={col.field === 'userId' ? notification => {
                  return (
                    <div className="text-left" style={{ display: 'flex', alignItems: 'end' }}>
                      <img
                        src={`${notification?.profileImage ?? riderAvtSec}`}
                        alt="ride"
                        style={{
                          marginRight: "15px",
                          width: "48px",
                          height: "48px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <p>{notification.userId}</p>
                    </div>
                  );
                }
                  : col.field === "status"
                    ? notification => <p>{notification.status === 1 ? 'sent' : 'unsent'}</p>
                    : null
                }
              />
            )
          }
          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          /> */}
        </DataTable>
      </div>
    </div>
  )
}

export default memo(PrimeDataTable)
