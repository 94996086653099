import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import { Link } from "react-router-dom";
import UnifonicTables from "./unifonic-otp";
import axios from "axios";
import APIService from "../../services/APIService";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function Registration(props) {
  let windowWidth = window.innerWidth;
  const [adjustData, setAdjustData] = useState(null);
  const [currentFilterCard, setCurrentFilterCard] = useState('');
  const [adjustFilterData, setAdjustFilterData] = useState({});
  const [show , setShow]=useState(false);

  const [loading, setLoading] = useState(false);
  const apiUrl =
  `http://uat-api.ride.sa/admin/get-adjust-data`;
  const [date, setdate] = useState('this_month');
  const dispatch = useDispatch();

  useEffect(() => {
    getAdjustmoduleData();

  }, []);
  useEffect(() => {
    console.log('currentFilterCard',currentFilterCard)
    if(currentFilterCard) { getAdjustmoduleFilterData(currentFilterCard); }
  }, [currentFilterCard,date]);
  // Set up the request headers
  const headers = {
    "Authorization": "Bearer vb5mPMy-91kxwzxdQRvM",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // Define the report dimensions and metrics
  const dimensions = "channel";
  const metrics =
  "installs,attribution_impressions,ctr,attribution_clicks,limit_ad_tracking_installs,limit_ad_tracking_install_rate,click_conversion_rate,reattributions,sessions,daus,waus,maus,apply promo_events,apply promo failure_events,apply promo success_events,bank transaction failure_events,bank transaction for subscription event_events,bank transaction success_events,car confirmation failed_events,car confirmation success_events,car not found_events,car selection failed_events,car selection success_events,card saving_events,card skipped_events,confirm pickup_events,confirm ride_events,driver signup_events,general revenue_events,iban added_events,iban added failed_events,iban added success_events,kyc attempts_events,kyc attempts failed_events,kyc attempts success_events,login sessions_events,otp failure_events,otp for payment_events,otp success_events,process after car selection_events,ride acceptance failed_events,ride acceptance success_events,ride cancelled (after confirmation)_events,ride cancelled (before confirmation)_events,rides accepted_events,select car_events,send unifornic otp_events,sequence added_events,sequence failed_events,sequence number count_events,sequence success_events,sign up event_events,subscribe package failed_events,subscribe package success_events,subscription package unsubscribed_events,subscription packages count_events,subscription transaction failed_events,subscription transaction success_events,tamm otp failure_events,tamm otp success_events,topup failed_events,topup success_events,unifonic otp failed_events,unifonic otp verified_events,wasl approval_events,wasl rejection_events";

  // Set the report period and app name
  const date_period = date;
  const app_name = "RiDE";
  const channel = "all";

  // Define the request parameters
  const params = {
    dimensions,
    metrics,
    date_period,
    App: app_name,
    channel,
    authToken: "Bearer u_-sZWyibxDAV6ThbpXW",
    apiUrl : "https://dash.adjust.com/control-center/reports-service/report"
  };
  
  const getTimeRange = async(range,cardName) => {
    setCurrentFilterCard(cardName);
    const statsObj = {
      fromDate: range.sDate,
      toDate: range.eDate,
    };
    const formattedDate=`${moment(statsObj?.fromDate,"DD/MM/YYYY").format("YYYY-MM-DD")}:${moment(statsObj?.toDate,"DD/MM/YYYY").format("YYYY-MM-DD")}`;
  setdate(formattedDate);

  };
  // Make the API request
  const getAdjustmoduleData = async() => {
    props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setShow(false)
    setLoading(true);
    await APIService
      .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          setAdjustData(response?.data?.data);
          setAdjustFilterData(response?.data?.data?.totals);
          setLoading(false);
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          setShow(true)
        }
      })
      .catch((error) => {
        console.error(error);
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
    setShow(true)
      });
  };
  const getAdjustmoduleFilterData = async(cardName) => {
    console.log('called =============')
    props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setShow(false)
    setLoading(true);
    await APIService
      .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          console.log(response)
          if(cardName== 'unifonicOtp'){
            setAdjustFilterData({...adjustData?.totals,"otp failure_events":Number(`${response?.data?.data?.totals["otp failure_events"]}`),"otp success_events":Number(`${response?.data?.data?.totals["otp success_events"]}`)});
          }
          else if(cardName== 'tammOtp'){
            setAdjustFilterData({...adjustData?.totals,"tamm otp failure_events":Number(`${response?.data?.data?.totals["tamm otp failure_events"]}`),"tamm otp success_events":Number(`${response?.data?.data?.totals["tamm otp success_events"]}`)});
          }
          else if(cardName== 'kycAttempts'){
            setAdjustFilterData({...adjustData?.totals,"kyc attempts failed_events":Number(`${response?.data?.data?.totals["kyc attempts failed_events"]}`),"kyc attempts success_events":Number(`${response?.data?.data?.totals["kyc attempts success_events"]}`)});
          }
          else if(cardName== 'wasl'){
            setAdjustFilterData({...adjustData?.totals,"wasl approval_events":Number(`${response?.data?.data?.totals["wasl approval_events"]}`),"wasl rejection_events":Number(`${response?.data?.data?.totals["wasl rejection_events"]}`)});
          }
          else if(cardName== 'sequenceNo'){
            setAdjustFilterData({...adjustData?.totals,"sequence success_events":Number(`${response?.data?.data?.totals["sequence success_events"]}`),"sequence failed_events":Number(`${response?.data?.data?.totals["sequence failed_events"]}`)});
          }
          else if(cardName== 'carConfirmation'){
            setAdjustFilterData({...adjustData?.totals,"car confirmation failed_events":Number(`${response?.data?.data?.totals["car confirmation failed_events"]}`),"car confirmation success_events":Number(`${response?.data?.data?.totals["car confirmation success_events"]}`)});
          }else if(cardName== 'carSelection'){
            setAdjustFilterData({...adjustData?.totals,"car selection failed_events":Number(`${response?.data?.data?.totals["car selection failed_events"]}`),"car selection success_events":Number(`${response?.data?.data?.totals["car selection success_events"]}`)});
          }
          setLoading(false);
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          setShow(true)

        }
      })
      .catch((error) => {
        console.error(error);
        props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        setShow(true)

      });
  };

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth < 1200 ? 45 : 90,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: loading ? false : true,
        tooltip: {
          callbacks: {
            // footer: footer,
          },
        },
      },
      legend: {
        display:  true,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 200,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };

  console.log(adjustFilterData)
  const chartsDataForUnifonicOtp = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? 0.1 : adjustFilterData["otp failure_events"],
          loading ? 0 : adjustFilterData["otp success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["otp failure_events"] < 1 && adjustFilterData["otp success_events"] < 1)   ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `], 
  };
  const chartsDataForCarSelection = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1)  ? 0.1 : adjustFilterData["car selection failed_events"],
          loading ? 0 : adjustFilterData["car selection success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["car selection success_events"] < 1 && adjustFilterData["car selection failed_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };
  const chartsDataForTammOtp = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? 0.1 : adjustFilterData["tamm otp failure_events"],
          loading ? 0 : adjustFilterData["tamm otp success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["tamm otp failure_events"] < 1 && adjustFilterData["tamm otp success_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };

  const chartsDataForKycAttempts = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? 0.1 : adjustFilterData["kyc attempts failed_events"],
          loading ? 0 : adjustFilterData["kyc attempts success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
        // hoverBorderWidth: loading || (adjustFilterData["kyc attempts failed_events"] < 1 && adjustFilterData["kyc attempts success_events"] < 1) ? 0 : 1,
      },
    ],
    // labels: [`Failed `, `Success `],
  };

  const chartsDataForCarConfirmation = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? 0.1 : adjustFilterData["car confirmation failed_events"],
          loading ? 0 : adjustFilterData["car confirmation success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["car confirmation failed_events"] < 1 && adjustFilterData["car confirmation success_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };

  const chartsDataForWASL = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1)    ? 0.1 : adjustFilterData["wasl rejection_events"],
          loading ? 0 : adjustFilterData["wasl approval_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["wasl approval_events"] < 1 && adjustFilterData["wasl rejection_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Rejected `, `Approved `],
  };
  const chartsDataForSequenceNo = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? 0.1 : adjustFilterData["sequence failed_events"],
          loading ? 0 : adjustFilterData["sequence success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustFilterData["sequence failed_events"] < 1) ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["sequence success_events"] < 1 && adjustData?.totals["sequence failed_events"] < 1) ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Rejected`, `Approved`],
  };
 
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `Total : ${loading ? 0 : chart?.data?.datasets[0]?.data[0] == 0.1 ? 0 :chart?.data?.datasets[0]?.data[0] + chart?.data?.datasets[0]?.data[1]}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const successAndFailedPercentage = (success , failure ,type)=>{
    let total = success + failure
    switch(type){
      case "success":
        let successPercentage = (success*100)/total;
        if(!successPercentage){return `0 %`}
        return `${Math.round(successPercentage.toFixed(2))} %`
      case "failure":
        let failedPercentage = (failure*100)/total;
        if(!failedPercentage){return `0 %`}
        return `${Math.round(failedPercentage.toFixed(2))} %`
      default:
        return ""
    }
  }
 
  return (
    <>
          {show && (
        <div className="row m-4 justify-content-between">
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            {" "}
            <div className="  d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Unifonic OTP</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'unifonicOtp')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForUnifonicOtp}
                plugins={loading ? [] :plugins}
                options={chartOptions}
              />
        <div className="flex-cb mt-32">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["otp success_events"],adjustFilterData["otp failure_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["otp success_events"],adjustFilterData["otp failure_events"],"failure")}</span>

              </span>
            </div>
        </div>
              <Link to="/unifonic-otp">View all</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">TAMM OTP</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'tammOtp')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForTammOtp}
                plugins={loading ? [] :plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["tamm otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["tamm otp success_events"],adjustFilterData["tamm otp failure_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["tamm otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["tamm otp success_events"],adjustFilterData["tamm otp failure_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">KYC Attempts</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'kycAttempts')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForKycAttempts}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["kyc attempts success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["kyc attempts success_events"],adjustFilterData["kyc attempts failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["kyc attempts failed_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["kyc attempts success_events"],adjustFilterData["kyc attempts failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">WASL</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'wasl')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForWASL}
                plugins={loading ? [] :plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["wasl approval_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["wasl approval_events"],adjustFilterData["wasl rejection_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["wasl rejection_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["wasl approval_events"],adjustFilterData["wasl rejection_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Sequence No.</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'sequenceNo')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForSequenceNo}
                plugins={loading ? [] :plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["sequence success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["sequence success_events"],adjustFilterData["sequence failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["sequence failed_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["sequence success_events"],adjustFilterData["sequence failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Car confirmation</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'carConfirmation')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForCarConfirmation}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Confirmed: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["car confirmation success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["car confirmation success_events"],adjustFilterData["car confirmation failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Undone:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["car confirmation failed_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["car confirmation success_events"],adjustFilterData["car confirmation failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Car selection process</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'carSelection')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartsDataForCarSelection}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["car selection success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["car selection success_events"],adjustFilterData["car selection failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["car selection failed_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["car selection success_events"],adjustFilterData["car selection failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
      </div>
      )}
    </>
  );
}
export default Registration;
