import APIService from "../../services/APIService"
const data = {
  "statusCode": 200,
  "data": {
    "success": 3,
    "failure": 1,
    "data": [
      {
        "userId": "966110000056",
        "mobileNo": "966544258963",
        "deviceToken": "du2Obq11ykR1kPnxa-VgFL:APA91bGqx5oEu2iaIHpkTpiSATUO2xFpgUdIggx204IdzYgvA9gn3EkGUXbe4SY4w_eRpuppQ3amtdwdqNCNE6_IFtQkVMKCqmQVhtPqoeAEZjkR8Deh452Ptue9rTEDbm23Iezq2U2q",
        "regId": "du2Obq11ykR1kPnxa-VgFL:APA91bGqx5oEu2iaIHpkTpiSATUO2xFpgUdIggx204IdzYgvA9gn3EkGUXbe4SY4w_eRpuppQ3amtdwdqNCNE6_IFtQkVMKCqmQVhtPqoeAEZjkR8Deh452Ptue9rTEDbm23Iezq2U2q",
        "error": null,
        "errorMsg": null
      },
      {
        "userId": "966220000001",
        "mobileNo": "966544555590",
        "deviceToken": "du2Obq11ykR1kPnxa-VgFL:APA91bGqx5oEu2iaIHpkTpiSATUO2xFpgUdIggx204IdzYgvA9gn3EkGUXbe4SY4w_eRpuppQ3amtdwdqNCNE6_IFtQkVMKCqmQVhtPqoeAEZjkR8Deh452Ptue9rTEDbm23Iezq2U2q",
        "regId": "du2Obq11ykR1kPnxa-VgFL:APA91bGqx5oEu2iaIHpkTpiSATUO2xFpgUdIggx204IdzYgvA9gn3EkGUXbe4SY4w_eRpuppQ3amtdwdqNCNE6_IFtQkVMKCqmQVhtPqoeAEZjkR8Deh452Ptue9rTEDbm23Iezq2U2q",
        "error": null,
        "errorMsg": null
      },
      {
        "userId": "966110000002",
        "mobileNo": "966533670676",
        "deviceToken": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "regId": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "error": {},
        "errorMsg": "NotRegistered"
      },
      {
        "userId": "966220000004",
        "mobileNo": "966121218891",
        "deviceToken": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "regId": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "error": {},
        "errorMsg": "NotRegistered"
      },
      {
        "userId": "966110000055",
        "mobileNo": "966544789654",
        "deviceToken": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "regId": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "error": {},
        "errorMsg": "NotRegistered"
      },
      {
        "userId": "966110000006",
        "mobileNo": "966540000000",
        "deviceToken": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "regId": "eAJxG_FoSEGPQD0AXMQqjy:APA91bG2j9em087hwl0jOdBEV95ocYDPM5KZv03-49y6gBerEwFRjA-xu8RMioK5B8jRmuKFpPMiVVoLaTjxzrijs4LUoGq3shThYN3MHdSJGEO-nPt93zPpzA0RCKd5o3VzZD7M9QyW",
        "error": {},
        "errorMsg": "NotRegistered"
      }
    ]
  }
},
  emailData = {
    "statusCode": 200,
    "data": {
      "success": 2,
      "failure": 0,
      "data": [
        {
          "userId": "966220000003",
          "emailId": "a@test.com",
          "mobileNo": "966544555527",
          "profileImage": null,
          "status": 1
        },
        {
          "userId": "966220000004",
          "emailId": "a@test.com",
          "mobileNo": "966544555537",
          "profileImage": null,
          "status": 1
        },
        {
          "userId": "966110000037",
          "emailId": "a@test.com",
          "mobileNo": "966544333333",
          "profileImage": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axhzhj71rohd/b/bucket-20221107-1745/o/customer/title-3-1683283717887.jpg",
          "status": 1
        }
      ]
    }
  }
export const getNotificationsDetail = ({ id, type, callback }) => {
  APIService.get(`admin/dashboard-notification/detail/${type}/${id}`)
    .then(response => {
      callback(response)
      // callback(type === 'email' ? emailData : data)
    })
    .catch(err => {
      console.log("notification detail", err)
      callback([])
      // callback(type === 'email' ? emailData : data)
    })
}