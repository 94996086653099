import React from 'react'
import cancelIcon from '../../assest/images/cancel.png'
const Success = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'aliceblue',
      height: '100vh',
      padding: '20px'
    }}>
      <img src={cancelIcon} width='150px' height='150px' />
      <div style={{
        padding: '20px',
        textAlign: 'center'
      }}>
        <h3 style={{ color: 'gray' }}>Cancelled</h3>
        <p style={{ color: '#888888' }}>Request was cancelled</p>
      </div>
    </div>
  )
}

export default Success
