import React from "react";
import RiderRatingListing from "../components/Rating/RiderRatingListing";

let RatingRiders = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Riders Rating</span>
        <RiderRatingListing />
      </div>
    </>
  );
};

export default React.memo(RatingRiders);
