import React, { useState, useEffect } from "react";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import PromoCard from "./promoCard";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
function PromoCode({ driverPromoStats, riderPromoStats }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [newPromo, setNewPromo] = useState(null);
  const [riderData, setRiderData] = useState(riderPromoStats);
  const [captainData, setCaptainData] = useState(driverPromoStats);
  const [riderPromoChartData, setRiderPromoChartData] = useState({
    riderActivePromo: {
      datasets: [
        {
          data: [
            100 - riderPromoStats?.active?.percentage,
            riderPromoStats?.active?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#13B542"],
          hoverBackgroundColor: ["#F1F1F1", "#13B542"],
        },
      ],
    },
    riderInactivePromo: {
      datasets: [
        {
          data: [
            100 - riderPromoStats?.inactive?.percentage,
            riderPromoStats?.inactive?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#EF4242"],
          hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
        },
      ],
    },
    riderExpiredPromo: {
      datasets: [
        {
          data: [
            100 - riderPromoStats?.expired?.percentage,
            riderPromoStats?.expired?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#828282"],
          hoverBackgroundColor: ["#F1F1F1", "#828282"],
        },
      ],
    },
  });

  const [driverPromoChartData, setDriverPromoChartData] = useState({
    driverActivePromo: {
      datasets: [
        {
          data: [
            100 - driverPromoStats?.active?.percentage,
            driverPromoStats?.active?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#13B542"],
          hoverBackgroundColor: ["#F1F1F1", "#13B542"],
        },
      ],
    },
    driverInactivePromo: {
      datasets: [
        {
          data: [
            100 - driverPromoStats?.inactive?.percentage,
            driverPromoStats?.inactive?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#EF4242"],
          hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
        },
      ],
    },
    driverExpiredPromo: {
      datasets: [
        {
          data: [
            100 - driverPromoStats?.expired?.percentage,
            driverPromoStats?.expired?.percentage,
          ],
          backgroundColor: ["#F1F1F1", "#828282"],
          hoverBackgroundColor: ["#F1F1F1", "#828282"],
        },
      ],
    },
  });

  useEffect(() => {
    setRiderPromoChartData({
      riderActivePromo: {
        datasets: [
          {
            data: [
              100 - riderPromoStats?.active?.percentage,
              riderPromoStats?.active?.percentage,
            ],
            backgroundColor: ["#F1F1F1", "#13B542"],
            hoverBackgroundColor: ["#F1F1F1", "#13B542"],
          },
        ],
      },
      riderInactivePromo: {
        datasets: [
          {
            data: [
              100 - riderPromoStats?.inactive?.percentage,
              riderPromoStats?.inactive?.percentage,
            ],
            backgroundColor: ["#F1F1F1", "#EF4242"],
            hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
          },
        ],
      },
      riderExpiredPromo: {
        datasets: [
          {
            data: [
              100 - riderPromoStats?.expired?.percentage,
              riderPromoStats?.expired?.percentage,
            ],
            backgroundColor: ["#F1F1F1", "#828282"],
            hoverBackgroundColor: ["#F1F1F1", "#828282"],
          },
        ],
      },
    });
  }, [riderPromoStats]);
  useEffect(() => {
    setDriverPromoChartData({
      driverActivePromo: {
        datasets: [
          {
            data: [
              100 - driverPromoStats?.active?.percentage
              ,
              driverPromoStats?.active?.percentage
              ,
            ],
            backgroundColor: ["#F1F1F1", "#13B542"],
            hoverBackgroundColor: ["#F1F1F1", "#13B542"],
          },
        ],
      },
      driverInactivePromo: {
        datasets: [
          {
            data: [
              100 - driverPromoStats?.inactive?.percentage
              ,
              driverPromoStats?.inactive?.percentage
              ,
            ],
            backgroundColor: ["#F1F1F1", "#EF4242"],
            hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
          },
        ],
      },
      driverExpiredPromo: {
        datasets: [
          {
            data: [
              100 - driverPromoStats?.expired?.percentage
              ,
              driverPromoStats?.expired?.percentage,
            ],
            backgroundColor: ["#F1F1F1", "#828282"],
            hoverBackgroundColor: ["#F1F1F1", "#828282"],
          },
        ],
      },
    });
  }, [driverPromoStats]);



  const chart1 = {
    datasets: [
      {
        data: [600, 400],
        backgroundColor: ["#13B542", "#F1F1F1"],
        hoverBackgroundColor: ["#13B542", "#F1F1F1"],
      },
    ],
  };
  const chart2 = {
    datasets: [
      {
        data: [600, 400],
        backgroundColor: ["#EF4242", "#F1F1F1"],
        hoverBackgroundColor: ["#EF4242", "#F1F1F1"],
      },
    ],
  };

  const chart3 = {
    datasets: [
      {
        data: [600, 400],
        backgroundColor: ["#828282", "#F1F1F1"],
        hoverBackgroundColor: ["#828282", "#F1F1F1"],
      },
    ],
  };
  const getTimeRange = (range) => {
    Promise.all([
      APIService.get(`admin/dashboard/promo?type=custom&entity=rider&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`),
      APIService.get(`admin/dashboard/promo?type=custom&entity=captain&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
    ]).then(res => {
      if (res[0].status == 200) {

        let data = res[0]?.data?.data ? res[0].data.data : ""
        setRiderData(data)
        setRiderPromoChartData({
          riderActivePromo: {
            datasets: [
              {
                data: [
                  100 - data?.active?.percentage,
                  data?.active?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#13B542"],
                hoverBackgroundColor: ["#F1F1F1", "#13B542"],
              },
            ],
          },
          riderInactivePromo: {
            datasets: [
              {
                data: [
                  100 - data?.inactive?.percentage,
                  data?.inactive?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#EF4242"],
                hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
              },
            ],
          },
          riderExpiredPromo: {
            datasets: [
              {
                data: [
                  100 - data?.expired?.percentage,
                  data?.expired?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#828282"],
                hoverBackgroundColor: ["#F1F1F1", "#828282"],
              },
            ],
          },
        })
      }
      if (res[1].status == 200) {
        let data = res[1].data?.data ? res[1].data.data : ''
        setCaptainData(data)
        setDriverPromoChartData({
          driverActivePromo: {
            datasets: [
              {
                data: [
                  100 - data?.active?.percentage,
                  data?.active?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#13B542"],
                hoverBackgroundColor: ["#F1F1F1", "#13B542"],
              },
            ],
          },
          driverInactivePromo: {
            datasets: [
              {
                data: [
                  100 - data?.inactive?.percentage,
                  data?.inactive?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#EF4242"],
                hoverBackgroundColor: ["#F1F1F1", "#EF4242"],
              },
            ],
          },
          driverExpiredPromo: {
            datasets: [
              {
                data: [
                  100 - data?.expired?.percentage,
                  data?.expired?.percentage,
                ],
                backgroundColor: ["#F1F1F1", "#828282"],
                hoverBackgroundColor: ["#F1F1F1", "#828282"],
              },
            ],
          },
        })
      }



    })

  }


  return (
    <section className="section-captain position-relative">
      <div className="d-flex justify-content-between text-center align-items-center position-relative">
        <h2 className="box-title">Promo codes</h2>
        <Calendar handleTimeRange={getTimeRange} />
      </div>
      <TabView
        className="mt-2"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Riders">
          {" "}

          <div className="row tab-pane fade active show" id="promoRide">
            <PromoCard
              data={riderPromoChartData?.riderActivePromo}
              centerText="Active"
              backgroundColor="#13B542"
              title="Total active usage"
              amount={riderData ? (riderData.active ? riderData.active.totalActiveUsage : 0) : riderPromoStats?.active?.totalActiveUsage}
              totalCodes={riderData ? (riderData.active ? riderData.active.totalUnutilizedPromo : 0) : riderPromoStats?.active?.totalUnutilizedPromo}
              barText="View Active Promo Codes"
            />

            <PromoCard
              data={riderPromoChartData?.riderInactivePromo}
              centerText="Inactive"
              backgroundColor="#EF4242"
              title="Total inactive usage"
              amount={riderData ? (riderData.inactive ? riderData.inactive.totalInctiveUsage : 0) : riderPromoStats?.inactive?.totalInctiveUsage}
              totalCodes={riderData ? (riderData.inactive ? riderData.inactive.totalActiveUsage : 0) : riderPromoStats?.inactive?.totalUnutilizedPromo}
              barText="View Inactive Promo Codes"
            />

            <PromoCard
              data={riderPromoChartData?.riderExpiredPromo}
              centerText="Expired"
              backgroundColor="#828282"
              title="Total expired usage"
              amount={riderData ? (riderData.expired ? riderData.expired.totalExpiredUsage : 0) : riderPromoStats?.expired?.totalExpiredUsage}
              totalCodes={riderData ? (riderData.expired ? riderData.expired.totalUnutilizedPromo : 0) : riderPromoStats?.expired?.totalUnutilizedPromo}
              barText="View Expired Promo Codes"
            />
          </div>
        </TabPanel>
        <TabPanel header="Captains">
          {" "}

          <div className="row tab-pane fade active show" id="promoRide">
            <PromoCard
              data={driverPromoChartData?.driverActivePromo}
              centerText="Active"
              backgroundColor="#13B542"
              title="Total active usage"
              amount={captainData ? (captainData.active ? captainData.active.totalActiveUsage : 0) : driverPromoStats?.active?.totalActiveUsage}
              totalCodes={captainData ? (captainData.active ? captainData.active.totalUnutilizedPromo : 0) : driverPromoStats?.active?.totalUnutilizedPromo}
              barText="View Active Promo Codes"
            />

            <PromoCard
              data={driverPromoChartData?.driverInactivePromo}
              centerText="Inactive"
              backgroundColor="#EF4242"
              title="Total inactive usage"
              amount={captainData ? (captainData.inactive ? captainData.inactive.totalInctiveUsage : 0) : driverPromoStats?.inactive?.totalActiveUsage}
              totalCodes={captainData ? (captainData.inactive ? captainData.inactive.totalUnutilizedPromo : 0) : driverPromoStats?.inactive?.totalUnutilizedPromo}
              barText="View Inactive Promo Codes"
            />

            <PromoCard
              data={driverPromoChartData?.driverExpiredPromo}
              centerText="Expired"
              backgroundColor="#828282"
              title="Total expired usage"
              amount={captainData ? (captainData.expired ? captainData.expired.totalActiveUsage : 0) : driverPromoStats?.expired?.totalExpiredUsage}
              totalCodes={captainData ? (captainData.expired ? captainData.expired.totalUnutilizedPromo : 0) : driverPromoStats?.expired?.totalUnutilizedPromo}
              barText="View Expired Promo Codes"
            />
          </div>
        </TabPanel>
        <TabPanel header="Pickups">Coming Soon</TabPanel>
      </TabView>

    </section>
  );
}

export default PromoCode;
