import { Route, Redirect } from "react-router-dom";
import React from "react";

let permissions = localStorage.getItem("access") !== null ? JSON.parse(atob(localStorage.getItem("access"))) : []
let PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let pathName = props.location.pathname
      let allowed = permissions.some(a => pathName.includes(a))
      let isFirstLogin = localStorage.getItem("isFirstLogin")
      if (props.location.pathname === '/unauthorised') return <Component {...props} />
      else if (isFirstLogin) return <Redirect to='/new-password' />
      else
        return localStorage.getItem("token") ? allowed ? (
          <Component {...props} />
        ) :
          pathName === "/" ?
            <Redirect
              to={{
                pathname: "/dashboard",
              }}
            />
            : <Redirect
              to={{
                pathname: "/unauthorised",
              }}
            /> : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
    }
    }
  />
);

export default PrivateRoute;
