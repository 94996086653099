import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RidesDetails from "../components/Rides/Ridesdetails";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { useParams } from "react-router";
import CancelTrip from "../components/Rides/CancelTrip";
import { Row, Col } from "react-bootstrap";
//prob no 1
let RidesDetailsPage = (props) => {
  let [tripView, setTripView] = useState([]),
    [cancelTrip, setCancelTrip] = useState(null),
    [show, setShow] = useState(false),
    { tripId } = useParams(),
    viewTrip = () => {
      props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      APIService.get("admin/trips/" + tripId)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.data.trip) {
              setTripView(response.data.data.trip);
            } else {
              props.history.push("/trips");
            }
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          } else {
            props.history.push("/trips");
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          props.history.push("/trips");
        });
    };

  useEffect(() => {
    viewTrip();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <Row className="row justify-content-between">
          <div className="col-7">
            <Link to="/trips" title="Back">
              <i className="fa fa-chevron-left back-arrow"></i>
            </Link>
            <span className="title">Trip Details</span>
          </div>
          {/* {tripView && [1, 3, 7, 9].indexOf(tripView.status) > -1 && (
            <div className="col-4 text-right">
              <Button
                className="btn btn-primary"
                onClick={() => setCancelTrip(tripView)}
              >
                Cancel
              </Button>
            </div>
          )} */}
        </Row>

        {tripView && <RidesDetails tripView={tripView} />}
        {cancelTrip && (
          <CancelTrip
            manageTrip={setCancelTrip}
            cancelTrip={cancelTrip}
            show={show}
            setShow={setShow}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(connect()(RidesDetailsPage));
