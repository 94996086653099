import React, { useState, useRef } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";

let VehicleLising = (props) => {
  let { handleShow } = props
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [selectedVehicle, setSelectedVehicleType] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/fare-configuration/vehicle-type/')),
    dt = useRef(null),
    statuses = ["Active", "Inactive"],
    statusFilter = (
      <Dropdown
        value={selectedStatus}
        options={statuses}
        onChange={(e) => {
          dt.current.filter(e.value, "status", "equals");
          setSelectedStatus(e.value);
        }}
        placeholder="Status"
        className="p-column-filter"
        showClear
      />
    ),
    renderHeader = () => {
      return (
        <div className="table-header">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={handleShow}
          >
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </button>}
        </div>
      );
    },
    vehicleTypePhotoBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <img
            src={
              rowData?.categoryIconUrl
                ? rowData.categoryIconUrl
                : "showcase/demo/images/flag_placeholder.png"
            }
            width="50"
            height="50"
            onError={(e) =>
            (e.target.src = rowData?.categoryIconUrl
              ? rowData.categoryIconUrl
              : process.env.PUBLIC_URL + `/images/car.png`)
            }
            alt={rowData.name}
            className="p-column-listing-photo vehicle-photo"
          />
        </React.Fragment>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
    },
    actionTemplate = (rowData) => {
      return (
        <div >
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && <Button
            className="border-0 btn btn-theme-green "
            style={{
              backgroundColor: "#13B542 !important",
              borderRadius: "6px"
            }}
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowUpdate();
              props.updateTableData(rowData);
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#ffffff"
              />
            </svg>
          </Button>}
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <Button
            className="ml-2 border-0 btn btn-theme-red" style={{ backgroundColor: '#EF4242', borderRadius: "6px" }}
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowDelete(rowData);
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#ffffff"
              />
            </svg>
          </Button>}
          {!currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && !currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <span>No permission</span>}
        </div>

      );
    };
  let vehicleModelTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span
          className="cursor-pointer"
          onClick={() => {
            props.handleShowUpdate();
            props.updateTableData(rowData);
          }}
        >
          {rowData.name}
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={props.vehicleTypes}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          // rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          selection={selectedVehicle}
          onSelectionChange={(e) => setSelectedVehicleType(e.value)}
          paginator
          rows={25}
          sortField={"name"}
          sortOrder={1}
          emptyMessage="No Vehicle Category(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          loading={props.isLoading}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Photo"
            body={vehicleTypePhotoBodyTemplate}
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Name"
            field="name"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by type name"
            body={vehicleModelTemplate}
          />
          <Column
            className="text-right"
            style={{ fontSize: "12px", width: "100px" }}
            field="order"
            header="order"
            sortable
            filter={showFilter}
            filterMatchMode="equals"
            filterPlaceholder="Search by charge"
          />
          <Column
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "150px" }}
            sortField="passengerBaseFare"
            filterField="passengerBaseFare"
            field="passengerBaseFare"
            header="Base Fare"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by charge"
          />
          <Column
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "150px" }}
            sortField="passengerCostPerKm"
            filterField="passengerCostPerKm"
            field="passengerCostPerKm"
            header="Charge/km"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by charge"
          />
          <Column
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "150px" }}
            sortField="passengerCostPerMin"
            filterField="passengerCostPerMin"
            field="passengerCostPerMin"
            header="Charge/Min"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by charge"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            sortField="noOfSeats"
            filterField="noOfSeats"
            field="noOfSeats"
            header="Capacity"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by seats"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            filterElement={statusFilter}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
            filterPlaceholder="Minimum"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(VehicleLising);
