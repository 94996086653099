// import React from "react";
import FareConfigationListing from "../components/FareConfigation/FareConfigationListing";
import VehicleTypeListingPage from "../pages/VehicleTypeListingPage";
// let  = () => {
//   return (
//     <div className="rider-listing">
//       <span className="title">Fare Calculations</span>
//       < />
//     </div>
//   );
// };

// export default React.memo(FareConfigationListingPage);

import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";


import TripSettingDetailPage from "../components/TripSetting/TripSettingDetailPage";

// import PromotionalCoupon from "../pages/PromotionalCoupon";
let tripSettings = [
  "VISA_CARD_FEE",
  "MASTER_CARD_FEE",
  "TRIP_PROCESSING_FEE",
  "TRIP_WASL_FEE",
  "RIDER_CANCEL_AMOUNT_WITH_TAX",
  "WAITING_CHARGE_PER_MINUTE",
  "DRIVER_PICKUP_REACH_RANGE",
  // "RIDER_TRIPS_LIMIT_WITHOUT_KYC",
]
let FareConfigationListingPage = (props) => {

  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);
  return (
    <>
      <div className="rider-listing">
        <span className="title">Fare Calculations</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/fare-configuration/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

          {permissions.find(a => a.includes('/fare-configuration/calculation')) &&
            <Tab eventKey="calculation" title="Fare Calculation">
              {tab === "calculation" && <FareConfigationListing />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/fare-configuration/vehicle-type')) &&
            <Tab eventKey="vehicle-type" title="Categories">
              {tab === "vehicle-type" && <VehicleTypeListingPage />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/fare-configuration/setting')) &&
            <Tab eventKey="setting" title="Settings">
              {tab === "setting" && <TripSettingDetailPage tripSettings={tripSettings} />}
            </Tab>
          }
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(FareConfigationListingPage);
