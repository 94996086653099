import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import { TabView, TabPanel } from "primereact/tabview";
import ProgressBar from "react-bootstrap/ProgressBar";
import APIService from "../../services/APIService";
import moment from "moment";

function Subsciption({ riderSubscriptionStats, driverSubscriptionStats }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [updateSubscription,setUpdateSubscription] = useState(null);
  let activePercentage =  riderSubscriptionStats?.active?.percentage;
  let inactivePercentage = riderSubscriptionStats?.inactive?.percentage;
  let expiredPercentage = riderSubscriptionStats?.expired?.percentage;

  let activeCapPercentage = driverSubscriptionStats?.active?.percentage;
  let inactiveCapPercentage = driverSubscriptionStats?.inactive?.percentage;
  let expiredCapPercentage = driverSubscriptionStats?.expired?.percentage;
  
  const getTimeRange=(range)=>{    
      try{
        APIService.get(`admin/dashboard/subscription?type=custom&entity=captain&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
        .then((res)=>{
          if(res.status==200){
          
            let data = res?.data?.data ? res.data.data : ""
            console.log(data)
        
            setUpdateSubscription(data)
          }
        })}catch(e){
          console.log(e)
        }
    
  }
  return (
    <>
      <section className=" cards-section position-relative">
        <div className="d-flex justify-content-between text-center align-items-center position-relative">
          <h2 className="box-title">Subscription</h2>
          <Calendar handleTimeRange={getTimeRange} />
        </div>

        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
         
          <TabPanel header="Captains">
            <div
              className="tab-pane fade active show"
              id="subRider"
              style={{ marginTop: "60px" }}
            >
               <div style={{marginBottom:'50px'}} className ="container-fluid">
                <ProgressBar>
                  <ProgressBar variant="success" now={updateSubscription?(updateSubscription.active?.percentage!=null?(updateSubscription.active.percentage):0): activeCapPercentage} key={1} />
                  <ProgressBar variant="danger" now={updateSubscription?(updateSubscription.inactive?.percentage!=null?(updateSubscription.inactive.percentage):0):inactiveCapPercentage} key={2} />
                  <ProgressBar variant="secondary" now={updateSubscription?(updateSubscription.expired?.percentage!=null?(updateSubscription.expired.percentage):0):expiredCapPercentage} key={3} />
                </ProgressBar>
              </div>

              <div className="container-fluid">
                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-12 mb-3">
                    <div className="info-section p-3">
                      <div className="percentage">
                        <div className="green"></div>
                        <div className="percenPoint">Active:</div>
                        <div className="percenPoint">
                          {updateSubscription?(updateSubscription.active?.count):driverSubscriptionStats?.active?.count}
                        </div>
                      </div>

                      <div className="row justify-content-around">
                        <div className="main-content">
                          <div className="sub-content">
                            <div className="light-text">Subscribed</div>
                            <div className="text-dark font-weight-bolder">
                              {  updateSubscription?(updateSubscription?.active?.totalActiveSubscribers):driverSubscriptionStats?.active?.totalActiveSubscribers
                              }
                            </div>
                          </div>

                          <div className="sub-content">
                            <div className="light-text">Amount</div>
                            <div className="text-dark font-weight-bolder">
                              SAR.{" "}
                              { updateSubscription?(updateSubscription?.active?.amountActive!=null?(updateSubscription.active.amountActive):(0)):driverSubscriptionStats?.active?.amountActive? driverSubscriptionStats?.active?.amountActive
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="info-section p-3">
                      <div className="percentage">
                        <div className="red"></div>
                        <div className="percenPoint">InActive:</div>
                        <div className="percenPoint">
                          {updateSubscription?(updateSubscription.inactive?.count):driverSubscriptionStats?.inactive?.count}
                        </div>
                      </div>

                      <div className="row justify-content-evenly">
                        <div className="main-content">
                          <div className="sub-content">
                            <div className="light-text">Subscribed</div>
                            <div className="text-dark font-weight-bolder">
                              {
                                updateSubscription?(updateSubscription?.inactive?.totalInctiveUsage):driverSubscriptionStats?.inactive?.totalInctiveUsage
                              }
                            </div>
                          </div>

                          <div className="sub-content">
                            <div className="light-text">Amount</div>
                            <div className="text-dark font-weight-bolder">
                              SAR.
                              { updateSubscription?(updateSubscription?.inactive?.amountInactive!=null?(updateSubscription.inactive.amountInactive):(0)):driverSubscriptionStats?.inactive?.amountInactive? driverSubscriptionStats?.inactive?.amountInactive: 0}
                            </div>
                          </div>

                          <div className="sub-content">
                            <div className="light-text">
                              Under approval process
                            </div>
                            <div className="text-dark font-weight-bolder">
                              0 Subscription
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12 mb-3">
                    <div className="info-section p-3">
                      <div className="percentage">
                        <div
                          className="green"
                          style={{ background: "#828282" }}
                        ></div>
                        <div className="percenPoint">Expired:</div>
                        <div className="percenPoint">
                          {updateSubscription?(updateSubscription.expired?.count):driverSubscriptionStats?.expired?.count}
                        </div>
                      </div>

                      <div className="row">
                        <div className="main-content justify-content-around">
                          <div className="sub-content">
                            <div className="light-text">Subscribed</div>
                            <div className="text-dark font-weight-bolder">
                              {
                                 updateSubscription?(updateSubscription?.expired?.totalExpiredUsage):driverSubscriptionStats?.expired?.totalExpiredUsage
                              }
                            </div>
                          </div>

                          <div className="sub-content">
                            <div className="light-text">Amount</div>
                            <div className="text-dark font-weight-bolder">
                              SAR.
                              
                              { updateSubscription?(updateSubscription?.expired?.amountExpired!=null?(updateSubscription.expired.amountExpired):(0)):(riderSubscriptionStats?.expired?.amountExpired)?riderSubscriptionStats.expired.amountExpired:0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Pickups">Coming Soon</TabPanel>
        </TabView>
      </section>
    </>
  );
}

export default Subsciption;
