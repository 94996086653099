import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom";
import retry from "../Dashboard_2/images/retry.svg";
import { DataConversions } from "./functions/DataConversions";


let Main = (props) => {
  let [earnings, setEarnings] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [isCalendarOpen, setIsCalendarOpen] = useState(false),
    [totalEarning, setTotalEarning] = useState(0),
    [fileContent, setFileContent] = useState(""),
    [showCalendar , setShowCalendar]=useState(false),
    [toggle , setToggle]=useState(false),
    [dates,setDates]=useState(""),
    // [totalServiceTax, setTotalServiceTax] = useState(0),
    [createdAt, setCreatedAt] = useState(null),
    [status, setStatus] = useState(null),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {
        entityType: 6,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    }),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    getEarnings = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/alinma-transactions",filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 201) {
            
            lazyParams.totalCount = response.data.data.totalCount;
            setLazyParams(lazyParams);
            // setTotalEarning(response.data.data.totalEarnings.toFixed(2));
            setEarnings(response.data.data.transactions);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      // console.log(file)
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const content = event.target.result;
        const result = DataConversions(content)
        setFileContent(result);
        setToggle(true)
      };
  
      reader.readAsText(file);
    };
    const toggleCalendar = () => {
      setShowCalendar(!showCalendar);
    };
    const divRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {

        console.log('calles',divRef.current && !divRef.current.contains(event.target))
        if (divRef.current && !divRef.current.contains(event.target)) {

          setShowCalendar(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    const getSpecificDate = (e)=>{
const date = moment(e.value);
const formattedDate = date.format('YYYY-MM-DD');
// setDates(formattedDate)
lazyParams.filters["createdAt"] = [formattedDate]
setLazyParams(lazyParams);
setTimeout(() => {
getEarnings();
}, 500);
      
    }
  useEffect(() => {
    getEarnings();
  }, []);
  const verifyEarning = (rowData)=>{
    if(fileContent){
      let show = fileContent.some(a=>a.inst_ref === rowData.ftRefNum) ? <span className="status-text">Verified</span> : <span className="status-text-inactive">UnVerified</span>
      return show;
    }
  }

  let renderHeader = () => {
      return (
        <div className="table-header position-relative align-items-center">
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up  "></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>
          {/* <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getEarnings();
              }}
            />
          </span>
        </div> */}
                   <div ref={divRef}>
            <CalendarRange onClick={toggleCalendar} className="text-success opacity-75 size ms-3" style={{ "font-size": "24px" }}/>
            {showCalendar && (
                <div className="position-absolute t-0" style={{zIndex:99 , width:"400px" , height:"300px"}}>
                    <Calendar appendTo={null} value={dates} onChange={(e) =>{getSpecificDate(e)}} inline />
                </div>
            )}
          </div>
          <div className="position-absolute end-0">
          <input type="file" onChange={handleFileInputChange} />
          <button className="btn btn-success" style={{cursor:'pointer'}}>Upload File</button>
          </div>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalCount: 0,
      });
      getEarnings();
    },
    manageDriverEarning = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getEarnings();
    };
    const limitizeId = (data) => {
      let result = data.slice(0, 8);
      return result;
    };
    const retryFun =(driver)=>{
      console.log(driver.id)
      APIService.get(`/admin/alinma-transactions/retry/${driver.id}`)
      .then((res)=>{
        console.log(res)
      })
    }
    let calendarRef = useRef(null)
    const handleCalendarHide = () => setIsCalendarOpen(false)
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={earnings}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver WithDrawal found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          lazy={true}
          totalRecords={lazyParams.totalCount}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDriverEarning}
          onSort={manageDriverEarning}
          onFilter={manageDriverEarning}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Transaction Id"
            field="transactionId"
            sortable
            filter={false}
            filterPlaceholder="Search by id"
            body={(driver) => {
              return (
                driver.id && (
                  <span className="dataFnt-size" style={{ color: "#13B542" }}>
                    {limitizeId(driver?.id)}
                  </span>
                )
              );
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            header="Date & Time"
            body={(earning) =>
              moment(earning.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getEarnings();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getEarnings();
                      }, 500);
                    }
                  }}
                  showOnFocus={isCalendarOpen}
                  onHide={handleCalendarHide}
                  ref={calendarRef}
                  onFocus={()=> setTimeout(() => {
                    calendarRef.current.showOverlay()
                  }, 100)}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="amount"
            header="Amount"
          
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="amount"
            header="Amount"
            body={(driver) => {
              return (
                driver.status &&
                (driver?.status == 1 ? (
                  <span className="status-text-pending">Pending</span>
                ) : driver?.status == 2 ? (
                  <span className="status-text">Completed</span>
                ) : (
                  <span className="status-text-inactive">Failed <img onClick={()=>retryFun(driver)} src={retry} className="ml-2 cursor-pointer"/></span>
                ))
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />


          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="transactionAmount"
            header="Source Account No"
            body={(driver) => {
              return <span>{driver?.srcAccNum}</span>;
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="transactionAmount"
            header="Target Account No"
            body={(driver) => {
              return <span>{driver?.targAccNum}</span>;
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />
                    {toggle && (
            <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="transactionAmount"
            header="Verification"
            body={verifyEarning}
            // sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(Main));
