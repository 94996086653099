import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router";
import APIService from "../../services/APIService";

let DispatcherDetail = (props) => {
  let params = useParams(),
    [dispatcherAdmin, setDispatcherAdmin] = useState(null),
    getDispatcherAdmin = () => {
      APIService.get("admin/subadmin/" + params.id)
        .then((response) => {
          if (response.status === 200) {
            setDispatcherAdmin(response.data.data);
          }
        })
        .catch((error) => console.log(error));
    };
  useEffect(() => {
    getDispatcherAdmin();
  }, []);
  return (
    <div className="driver-details">
      {dispatcherAdmin && (
        <Card className="mt-3">
          <Row>
            <Col xl={3} md={6} className="pl-5 pr-5 mb-3">
              <Row className="mt-4">
                <Col md="auto" className="px-0">
                  <label className="profilelabel">Name:</label>
                </Col>
                <Col md={7} className="px-0">
                  <label className="profiletxt float-left margin-top-3 ml-1 line-height-16">
                    {dispatcherAdmin.fullName}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col xl={4} md={6} className="pl-5 pr-5 mb-3">
              <Row className="mt-4">
                <Col md="auto" className="px-0">
                  <label className="profilelabel">Email:</label>
                </Col>
                <Col md={7} className="px-0">
                  <label className="profiletxt float-left margin-top-3 ml-1 line-height-16">
                    {dispatcherAdmin.email}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col xl={3} md={6} className="pl-5 pr-5 mb-3 px-0">
              <Row className="mt-4">
                <Col md="auto" className="px-0">
                  <label className="profilelabel">Trips Created:</label>
                </Col>
                <Col md={7} className="px-0">
                  <label className="profiletxt float-left margin-top-3 ml-1 line-height-16">
                    {dispatcherAdmin.dispatcherTrips?.toString()}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col
              xl={2}
              md={6}
              className="pr-5 mb-3"
              style={{ paddingLeft: "30px" }}
            >
              <Row className="mt-4">
                <Col>
                  {dispatcherAdmin.status ? (
                    <label className="profiletxt float-left text-success">
                      Active
                    </label>
                  ) : (
                    <label className="profiletxt float-left text-danger">
                      Inactive
                    </label>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default React.memo(connect()(DispatcherDetail));
