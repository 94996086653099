import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import APIService from "../../services/APIService";
import rideSource from "../../assest/images/ride-app-source.svg";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { CSVLink } from "react-csv";
import IConstants from "../../IConstants";

let UnfonicOtpFail = (props) => {
  let [earnings, setEarnings] = useState([]),
  [windowWidth] = useState(useSelector((state) => state.windowSize)),
  [showFilter, setShowFilter] = useState(false),
  [totalEarning, setTotalEarning] = useState(0),
  // [totalServiceTax, setTotalServiceTax] = useState(0),
  [createdAt, setCreatedAt] = useState(null),
  [status, setStatus] = useState(null),
  dt = useRef(null),
  [lazyParams, setLazyParams] = useState({
    filters: {},
    sort: {
      field: "carPlateNo",
      order: -1,
    },
    take: 25,
    skip: 0,
    keyword: "",
    totalCount: 0,
  }),
  tripStatus = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Accepted By Driver" },
    { value: 3, label: "Rejected By Driver" },
    { value: 4, label: "Cancelled By Driver" },
    { value: 5, label: "Driver Arrived" },
    { value: 6, label: "Cancelled By Rider" },
    { value: 7, label: "Started" },
    { value: 8, label: "Completed" },
    { value: 9, label: "No Driver" },
    { value: 10, label: "Expired" },
  ],
  getEarnings = () => {
    let filterParams = JSON.parse(JSON.stringify(lazyParams));
    filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    APIService.post("admin/otps", filterParams)
      .then((response) => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        if (response.status === 201) {
          lazyParams.totalCount = response.data.data.totalCount;
          setLazyParams(lazyParams);
          // setTotalEarning(response.data.data.totalEarnings.toFixed(2));
          setEarnings(response.data.data);
        }
      })
      .catch(() =>
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
      );
  };
useEffect(() => {
  getEarnings();
}, []);

let renderHeader = () => {
    return (
      <div className="table-header">
        <span>
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
            showFilter ? (
              <>
                <img src="/images/filter-icon.svg" alt="" srcSet="" />
                <span className="span-filter mx-12">Filters</span>
                <i className="fa fa-caret-up  "></i>
              </>
            ) : (
              <>
                <img src="/images/filter-icon.svg" alt="" srcSet="" />
                <span className="span-filter mx-12">Filters</span>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                    fill="#828282"
                  />
                </svg>
              </>
            )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              getEarnings();
            }}
          />
        </span>
      </div>
    );
  },
  header = renderHeader(),
  toggleFilter = () => {
    setShowFilter(!showFilter);
    setCreatedAt([]);
    setStatus(null);
    setLazyParams({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    });
    getEarnings();
  },
  redirectIdTemplate = (rowData) => {
    return (
      <div className="text-left">
        {rowData?.profileImage ? (
          // <img
          //   src={`${rowData?.profileImage}`}
          //   alt="ride"
          //   style={{
          //     marginRight: "15px",
          //     width: "48px",
          //     height: "48px",
          //     borderRadius: "50%",
          //     objectFit: "cover",
          //   }}
          // />
          <img
            src={riderAvtSec}
            alt="ride"
            style={{
              marginRight: "15px",
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <img
            src={riderAvtSec}
            alt="ride"
            style={{
              marginRight: "15px",
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        )}

        {rowData.userId && (
          <Link
          to={"/rider-details/details/" + rowData.userId}
            className="dataFnt-size"
            style={{ color: "rgba(19, 181, 66, 1)" }}
          >
            

            <span className="dataFnt-size" style={{ color: "#13B542" }}>
              {rowData.userId}
            </span>
          </Link>
        )}
      </div>
    );
  },
  manageDriverEarning = (event) => {
    if (event.sortField) {
      lazyParams.sort = {
        field: event.sortField,
        order: event.sortOrder,
      };
    } else if (event.first || event.first === 0) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = event.first;
    } else if (event.filters && Object.keys(event.filters).length) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = 0;
      let filters = { ...lazyParams.filters };
      for (let filter in event.filters) {
        if (event.filters[filter].value) {
          lazyParams.filters[filter] = event.filters[filter].value;
        }
      }
      lazyParams.filters = { ...filters, ...lazyParams.filters };
    } else if (!event.filters || !Object.keys(event.filters).length) {
      lazyParams.filters = {};
    }
    setLazyParams(lazyParams);
    getEarnings();
  };
const limitizeId = (data) => {
  let result = data.slice(0, 8);
  return result;
};
return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={earnings?.filter(item=> item?.status !== 4)}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No OTP logs found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDriverEarning}
          onSort={manageDriverEarning}
          onFilter={manageDriverEarning}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            field="userId"
            header="Id"
            body={redirectIdTemplate}
            sortable
            filter={showFilter}
            filterField="userId"
            // filterElement={
            //   <InputText value={filterSearch} onChange={onSearchFilter}  placeholder="Search by id" />
            // }
            filterMatchMode="contains"
            filterPlaceholder="Search by id"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            // field="amount"
            header="User"
            body={(user) => {
              return (
                user.userType &&
                (user.userType == 1 ? (
                  <span>Rider </span>
                ) : (
                  <span>Driver </span>
                ))
              );
            }}
            sortable
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            // field="amount"
            header="Name"
            body={(user) => {
              return  user.name
                ? `${user.name}`
                : "";
            }}
            sortable
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            field="mobileNo"
            header="Mobile no."
            // body={(user) => {
            //   return (
            //     user.userType &&
            //     (user.userType == 1 ? (
            //       <span>Rider </span>
            //     ) : (
            //       <span>Driver </span>
            //     ))
            //   );
            // }}
            sortable
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="otp"
            header="OTP"
            // body={(user) => {
            //   return (
            //     user.userType &&
            //     (user.userType == 1 ? (
            //       <span>Rider </span>
            //     ) : (
            //       <span>Driver </span>
            //     ))
            //   );
            // }}
            sortable
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            header="Date & Time"
            body={(earning) =>
              moment(earning.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getEarnings();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getEarnings();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            // field="amount"
            header="Status"
            body={(user) => {
              return (
                user.status &&
                (user?.status == 2 ? (
                  <span className="status-text">Delivered</span>
                ) : user?.status == 3 ? (
                  <span className="status-text-pending">Unused</span>
                ) : user?.status == 4 ? (
                  <span className="status-text">Used</span>
                )  : user?.status == 5 ? (
                  <span className="status-text-inactive">Expired</span>
                ) : (
                  <span className="status-text-inactive">Undelivered</span>
                ))
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />

          {/* <Column
          className="text-center"
          style={{ fontSize: "12px", width: "140px" }}
          header="Service Tax"
          field="taxAmount"
          body={(driver) =>
            driver.taxAmount ? driver.taxAmount.toFixed(2) : "0.00"
          }
          sortable
          filter={showFilter}
          filterPlaceholder="Search by amount"
        /> */}
        </DataTable>
      </div>
    </div>
  
);
};
export default React.memo(connect()(withRouter(UnfonicOtpFail)));
