import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import EmergencyRequestDetailTripTab from "../components/Emergency/EmergencyTeamsTripTab";
import EmergencyTeamDetailTab from "../components/Emergency/EmergencyTeamDetailTab";
import EmergencyTeamsRequestsTab from "../components/Emergency/EmergencyTeamsRequestsTab";

let EmergencyTeamDetailTabLayout = (props) => {
  let [selectTab, setSelectedTab] = useState(null),
    [emergencyTeamDetails, setEmergencyTeamDetails] = useState(null),
    getDispatcher = () => {
      if (props.match.params.tab) {
        setSelectedTab(props.match.params.tab);
      } else {
        setSelectedTab("details");
      }
    };

  useEffect(() => {
    getDispatcher();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <Link to="/emergency-team" title="Back">
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Emergency User Details</span>
      </div>
      <Tabs
        id="controlled-tab-example"
        className="mt-40"
        activeKey={selectTab}
        onSelect={(tab) => {
          setSelectedTab(tab);
          props.history.push(
            `/emergency-team-detail/${tab}/${props.match.params.id}`
          );
        }}
      >
        <Tab eventKey="details" title="Emergency Team Detail">
          {emergencyTeamDetails && selectTab === "details" && (
            <EmergencyTeamDetailTab
              emergencyTeamDetails={emergencyTeamDetails}
            />
          )}
        </Tab>
        <Tab eventKey="request" title="Emergency Requests">
          {selectTab === "request" && (
            <EmergencyTeamsRequestsTab teamId={props.match.params.id} />
          )}
        </Tab>
        <Tab eventKey="trips" title="Trips Created">
          {selectTab === "trips" && (
            <EmergencyRequestDetailTripTab teamId={props.match.params.id} />
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default React.memo(connect()(EmergencyTeamDetailTabLayout));
