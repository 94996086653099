import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";

import PromotionalCoupon from "../../pages/PromotionalCoupon";
import Notifications from "../..//pages/Notification"
let Promotions = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <span className="title">Marketing</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/markeeting/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

          {permissions.find(a => a.includes('/markeeting/promotions')) &&
            <Tab eventKey="promotions" title="Promotion Codes">
              {tab === "promotions" && <PromotionalCoupon />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>

          }
          {permissions.find(a => a.includes('/markeeting/notifications')) &&
            <Tab eventKey="notifications" title="Notification">
              {tab === "notifications" && <Notifications />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />}  */}
            </Tab>
          }

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Promotions);
