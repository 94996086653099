import React from 'react'
import successIcon from '../../assest/images/accept.png'
import removeIcon from '../../assest/images/remove.png'
const Success = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'aliceblue',
      height: '100vh',
      padding: '20px'
    }}>
      <img src={successIcon} width='150px' height='150px' />
      <div style={{
        padding: '20px',
        textAlign: 'center'
      }}>
        <h3 style={{ color: 'green' }}>Success</h3>
        <p style={{ color: '#888888' }}>We received your purchase request</p>
      </div>
    </div>
  )
}

export default Success
