import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom";

let DriverEarningsTab = (props) => {
  let [earnings, setEarnings] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [totalEarning, setTotalEarning] = useState(0),
    // [totalServiceTax, setTotalServiceTax] = useState(0),
    [createdAt, setCreatedAt] = useState(null),
    [status, setStatus] = useState(null),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    }),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    getEarnings = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/captain-earnings/" + props.driverId, filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 201) {
            lazyParams.totalCount = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setTotalEarning(response.data.data.totalEarnings.toFixed(2));
            setEarnings(response.data.data.earnings);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };
  useEffect(() => {
    getEarnings();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getEarnings();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalCount: 0,
      });
      getEarnings();
    },
    manageDriverEarning = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getEarnings();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <h4 className="text-right mb-3">
        Overall Earnings (Incl. Tax): {totalEarning} SAR
      </h4>
      <div className="card">
        <DataTable
          ref={dt}
          value={earnings}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Earning(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDriverEarning}
          onSort={manageDriverEarning}
          onFilter={manageDriverEarning}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Payment Id"
            field="transactionId"
            sortable
            filter={false}
            filterPlaceholder="Search by id"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Trip Id"
            field="tripNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
            body={(rowData) => {
              return (
                <Link
                  to={`/trip-details/${rowData.id}`}
                  className="dataFnt-size"
                >
                  {rowData.tripNo}
                </Link>
              );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            header="Date & Time"
            body={(earning) =>
              moment(earning.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getEarnings();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getEarnings();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="riderName"
            header="Rider Id"
            body={(driver) => {
              return (
                driver.rider && (
                  <Link
                    to={`/rider-details/details/${driver.rider.id}`}
                    className="dataFnt-size"
                  >
                    {driver.rider?.userId}
                  </Link>
                )
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />

 
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Earnings (Incl. Tax)"
            field="riderAmount"
            body={(driver) =>
              driver.riderAmount ? driver.riderAmount.toFixed(2) : "0.00"
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            body={(trip) =>
              tripStatus.find((trp) => trp.value === trip.status)?.label
            }
            filterElement={
              <Dropdown
                value={status}
                options={tripStatus}
                onChange={(event) => {
                  setStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getEarnings();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(DriverEarningsTab));
