import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import { CalendarRange } from "react-bootstrap-icons";
import { Calendar } from "primereact/calendar";
import APIService from "../../services/APIService";
import moment from "moment";

let ExpiredSubscriptionsListingTable = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [expiredSubscriptions, setExpiredSubscriptions] = useState([]),
    [startDate, setStartDate] = useState([]),
    [endDate, setEndDate] = useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "startDate",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    manageExpiredSubscription = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getExpiredSubscriptions();
    },
    getExpiredSubscriptions = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/expired-subscriptions", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setExpiredSubscriptions(response.data.data.subscriptions);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };

  useEffect(() => {
    getExpiredSubscriptions();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getExpiredSubscriptions();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "startDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
    };
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={expiredSubscriptions}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Subscription(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageExpiredSubscription}
          onSort={manageExpiredSubscription}
          onFilter={manageExpiredSubscription}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortField="id"
            header="Transaction Id"
            field="transactionId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Purchased Date"
            field="startDate"
            body={(driver) => {
              return (
                <React.Fragment>
                  <span className="dataFnt-size">
                    {moment(driver.startDate).format("DD/MM/YYYY")}
                  </span>
                </React.Fragment>
              );
            }}
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={startDate}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setStartDate(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["startDate"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getExpiredSubscriptions();
                        }, 500);
                      }
                    } else {
                      setStartDate([]);
                      delete lazyParams.filters["startDate"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getExpiredSubscriptions();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="senderId"
            header="Driver Id"
            body={(subscription) => (
              <React.Fragment>
                <span className="dataFnt-size">
                  {subscription.senderId || "-"}
                </span>
              </React.Fragment>
            )}
            sortable
            sortField="senderId"
            filterField="senderId"
            filter={showFilter}
            filterPlaceholder="Search by id"
          />

          <Column
            style={{ fontSize: "12px", width: "200px" }}
            field="driverName"
            header="Driver Name"
            body={(subscription) => (
              <React.Fragment>
                <span className="dataFnt-size">
                  {subscription.driver?.driverName || "-"}
                </span>
              </React.Fragment>
            )}
            sortable
            sortField="driverName"
            filterField="driverName"
            filter={showFilter}
            filterPlaceholder="Search by name"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "220px" }}
            sortable
            sortField="endDate"
            filterField="endDate"
            header="Expired Date"
            body={(driver) => (
              <React.Fragment>
                <span className="dataFnt-size">
                  {driver.subscription.endDate &&
                    moment(
                      driver.subscription && driver.subscription?.endDate
                    ).format("DD/MM/YYYY")}
                </span>
              </React.Fragment>
            )}
            field="subscription.endDate"
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={endDate}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setEndDate(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["endDate"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getExpiredSubscriptions();
                        }, 500);
                      }
                    } else {
                      setStartDate([]);
                      delete lazyParams.filters["endDate"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getExpiredSubscriptions();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            sortField="subscriptionAmount"
            filterField="subscriptionAmount"
            field="subscription.subscriptionAmount"
            header="Package Price"
            body={(driver) => (
              <React.Fragment>
                <span className="dataFnt-size">
                  {driver.subscription?.subscriptionAmount.toFixed() || ""}
                </span>
              </React.Fragment>
            )}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by price"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ExpiredSubscriptionsListingTable));
