import React, { useState, useEffect } from "react";
import APIService from "../../services/APIService";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { connect, useSelector } from "react-redux";

let otherSettings = [
    "SUPPORT_TOLL_FREE_NO",
    "SUBSCRIPTION_TAX",
    "SUBSCRIPTION_TRANSACTION_FEE",
  ],
  windowWidth = window.innerWidth;

let OtherSettingDetailPage = (props) => {
  let [tripSettingData, setTripSettingData] = React.useState([]),
    [updateData, setUpdateData] = useState(null),
    [changeValue, setChangeValue] = useState(""),
    [show, setShow] = useState(false),
    [isTextField, setIsTextField] = useState(false),
    handleClose = () => setShow(false),
    handleShow = (rowData) => {
      if (rowData.name === "SUPPORT_TOLL_FREE_NO") {
        setIsTextField(true);
      } else {
        setIsTextField(false);
      }
      setUpdateData(rowData);
      setChangeValue(rowData.value);
      setShow(true);
    };

  useEffect(() => {
    getSettings();
  }, []);

  let getSettings = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/settings")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let settingData = response.data.data;
            setTripSettingData(
              settingData
                .filter((item) => {
                  return otherSettings.indexOf(item.name) > -1;
                })
                .map((setting, index) => {
                  setting.title = setting.name
                    .replace("_", " ")
                    .replace("_", " ")
                    .replace("_", " ");
                  setting.order = index;
                  return setting;
                })
            );
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    updateSetting = () => {
      APIService.patch("admin/setting/" + updateData.name, {
        value: changeValue,
      }).then((response) => {
        if (response.data && response.data.statusCode === 200) {
          handleClose();
          getSettings();
        }
      });
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          <PrimeButton
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => handleShow(rowData)}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </PrimeButton>
        </div>
      );
    };

  return (
    <>
      <div className="rider-listing">
        <span className="title">Other Settings</span>
        <Card className="mt-3">
          <DataTable
            value={tripSettingData}
            scrollable
            scrollHeight="470px"
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              style={{
                width: windowWidth < 768 ? "200px" : "",
              }}
              headerClassName="text-left"
              field="title"
              header="Title"
              body={(setting) =>
                setting.title?.slice(0, 1) +
                "" +
                setting.title?.toLowerCase().slice(1, setting.title.length)
              }
            ></Column>
            <Column
              field="description"
              header="Description"
              style={{
                width: windowWidth < 768 ? "300px" : "",
              }}
            ></Column>
            <Column
              className="text-center"
              style={{
                width: windowWidth < 768 ? "150px" : "",
              }}
              field="value"
              header="Value"
            ></Column>
            <Column
              className="text-center"
              style={{ width: "100px" }}
              header="Action"
              headerClassName="text-center"
              body={actionTemplate}
              filterPlaceholder="Minimum"
            />
          </DataTable>
        </Card>
      </div>

      <Modal show={show} size="" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {" "}
            Update Other Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-header-footer">
          <form>
            <div className="form-group form-label-group">
              <input
                id="couponTitle"
                type="text"
                name="couponTitle"
                className="shadow-none form-control modal-input br-10"
                placeholder="Trip Title"
                value={updateData && updateData.name.split("_").join(" ")}
                readOnly
                style={{ background: "#BABBC0" }}
              />
            </div>
            <div className="form-group form-label-group">
              <textarea
                style={{ height: "unset" }}
                id="description"
                type="text"
                name="description"
                className="shadow-none form-control modal-input br-10"
                disabled
                readOnly
              >
                {updateData?.description}
              </textarea>
            </div>
            <div className="form-group form-label-group">
              <input
                id="tripValue"
                type={isTextField ? "text" : "number"}
                name="radiusValue"
                onChange={(e) => setChangeValue(e.target.value)}
                className="shadow-none form-control modal-input br-10"
                value={changeValue}
                placeholder="Trip Value"
              />
              <label htmlFor="tripValue"> Value</label>
              {!changeValue ? (
                <div className="text-danger">Please enter value</div>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="padding-header-footer">
          <Button
            variant="primary"
            className="btn-save save-button mb-3 border-radius-15"
            onClick={updateSetting}
          >
            Update
          </Button>

          <Button
            variant="light"
            className="btn-cancel save-button mb-3 border-radius-15"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(connect()(OtherSettingDetailPage));
