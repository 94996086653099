import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MultiSelect } from "primereact/multiselect";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PromotionalCouponsListing from "../components/PromotionalCoupons/PromotionalCouponsListing";
import Select, { StylesConfig } from "react-select";
import { Calendar4 } from "react-bootstrap-icons";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";
import Map from "../components/HeatMap/Map";
import store from "../store";
import { Country, State, City } from "country-state-city";
import { googleMapApiKey } from "../services/APIService";
// import { PlacesWithStandaloneSearchBox } from "../components/HeatMap/SearchMap";
// import Map from '../components/HeatMap/Map';
// import { withScriptjs } from "react-google-maps";
const { withScriptjs } = require("react-google-maps");
const { compose, withProps, lifecycle } = require("recompose");

const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");
const polyline = require('google-polyline')
const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();

          this.setState({
            places,
          });
        },
      });
    },
  }),

  withScriptjs
)((props) => {
  const MapLoader = withScriptjs(Map);

  return (
    <div data-standalone-searchbox="">
      {/* <StandaloneSearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Customized your placeholder"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </StandaloneSearchBox>
      <ol>
        {props.places.map(
          ({ place_id, formatted_address, geometry: { location } }) => (
            props.getLat(location.lat(),location.lng())
            // <li key={place_id}>
            //   {formatted_address}
            //   {" at "}({location.lat()}, {location.lng()})
            // </li>
          )
        )}
      </ol> */}
      <MapLoader
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        getPolygon={props.getPolygon}
        dispatch={props.dispatch}
      />
    </div>
  );
});

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#fff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let options = [
  { value: 1, label: "Fixed Amount" },
  { value: 2, label: "Percentage" },
],
  statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ],
  applicableOptions = [
    { value: 1, label: "Rider" },
    { value: 2, label: "Driver" },
  ],
  cityOptions = [
    { value: 1, label: "Riyadh" },
    { value: 2, label: "..." },
  ],
  genderOptions = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Both" },
  ],
  countryOptions = [
    { value: 1, label: "Saudia" },
    { value: 2, label: "...." },
  ],
  borneOptions = [
    { value: 1, label: "RIDE" },
    { value: 2, label: "Captain" },
    { value: 3, label: "Both" },
  ],
  areaOptions = [
    { value: 1, label: "Country" },
    { value: 2, label: "City" },
    { value: 3, label: "Polygon" },
    { value: 4, label: "All" },
  ],
  PromotionalCoupon = (props) => {
    // const MapLoader = withScriptjs(Map);
    const stateData = store.getState();
    let [promos, setPromos] = useState([]),
      [windowWidth] = useState(useSelector((state) => state.windowSize)),
      [show, setShow] = useState(false),
      [showErrorPolygon, setShowErrorPolygon] = useState(false),
      [showMapError, setShowMapError] = useState(false),
      [showDelete, setShowDelete] = useState(false),
      [isCalendarOpen, setIsCalendarOpen] = useState(false),
      [showUpdate, setShowUpdate] = useState(false),
      [showMap, setShowMap] = useState(false),
      [selectedOption, setSelectedOption] = useState({
        value: 1,
        label: "Fixed Amount",
      }),
      [multipleOption, setMultipleOption] = useState([]),
      [multipleSelected, setMultipleSelected] = useState(null),
      [subscriptionOption, setSubscriptionOption] = useState([]),
      [subscriptionSelected, setSubscriptionSelected] = useState(null),
      [selectedGender, setSelectedGender] = useState({
        value: 1,
        label: "Male",
      }),
      [selectedCity, setSelectedCity] = useState({
        value: 1,
        label: "Riyadh",
      }),
      [selectedCountry, setSelectedCountry] = useState({
        value: 1,
        label: "Saudia",
      }),
      [selectedStatus, setSelectedStatus] = useState({
        value: true,
        label: "Active",
      }),
      [selectedAplicableFor, setSelectedAplicableFor] = useState({
        value: 1,
        label: "Rider",
      }),
      [selectedBorneBy, setSelectedBorneBy] = useState({
        value: 1,
        label: "RIDE",
      }),
      [selectedAreaType, setSelectedAreaType] = useState({
        value: 4,
        label: "All",
      }),
      [selectedMap, setSelectedMap] = useState(null),
      [polygonSelected, setPolygonSelected] = useState(null),
      [polygonError, setPolygonError] = useState(false),
      [endDate, setEndDate] = useState(null),
      [saveNmore, setSaveNmore] = useState(false),
      [updateData, setUpdateData] = useState({
        code: "",
        promoCodeType: null,
        amount: "",
        startAt: new Date(),
        endAt: "",
        message: "",
        maximumTotalUsage: "",
        status: null,
        borneBy: null,
        applicableFor: null,
        areaType: null,
        gender: null,
        totalBudget: null,
        city: null,
        country: null,
        minimumAmountInCart: "",
        maximumDiscountAmount: "",
        maximumUsagePerUser: "",
        cabtype: [],
        subscription: []
      }),
      handleClose = () => setShow(false),
      handleShow = () => {
        setUpdateData({
          code: "",
          promoCodeType: null,
          amount: "",
          startAt: new Date(),
          endAt: "",
          message: "",
          maximumTotalUsage: "",
          status: null,
          borneBy: null,
          applicableFor: null,
          areaType: null,
          gender: null,
          totalBudget: null,
          city: null,
          country: null,
          type: 0,
          minimumAmountInCart: "",
          maximumDiscountAmount: "",
          maximumUsagePerUser: "",
          cabtype: [],
          subscription: []
        });
        setMultipleSelected(null)
        setSelectedMap(null)
        setSubscriptionSelected(null)
        setSelectedStatus({ value: true, label: "Active" });
        setSelectedOption({ value: 1, label: "Fixed Amount" });
        localStorage.removeItem("polygon")
        setSelectedAreaType({
          value: 4,
          label: "All",
        })
        setShow(true);
      },
      handleCloseUpdate = () => setShowUpdate(false),
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rawData) => {
        setUpdateData(rawData);
        setShowDelete(true);
      },
      validationSchemaAmount = Yup.object().shape({
        code: Yup.string().required("Please enter Coupon Title"),
        message: Yup.string().required("Please enter Short Description"),
        promoCodeType: Yup.string().required("Select Discount Type").nullable(),
        amount: Yup.number().required("Please enter Amount"),
        startAt: Yup.string().required("Select Start Date"),
        endAt: Yup.string().required("Select End Date"),
        maximumTotalUsage: Yup.string().required("Please enter Usage Limit"),
        status: Yup.string().required("Select Status").nullable(),

        areaType: Yup.string().required("Select AreaType").nullable(),
        applicableFor: Yup.string().required("Select Applicable For").nullable(),
        applicableFor: Yup.string().required("Select Applicable For").nullable(),
        // gender: Yup.string().required("Select Gender").nullable(),
        borneBy: Yup.string().required("Select Borne By").nullable(),
        totalBudget: Yup.string().required("Select Total Budget").nullable(),
        ...(selectedAreaType.value === 2) && {
          city: Yup.object().required("Select City").nullable(),
          country: Yup.object().required("Select Country").nullable(),
        },
        ...(selectedAreaType.value === 1) && {
          country: Yup.object().required("Select Country").nullable(),
        },
        // ...(selectedAreaType.value === 3) && {
        //   useMap: Yup.string().required("Select Polygon").nullable()
        // },
        minimumAmountInCart: Yup.string().required("Please enter Minimum Amount"),
        maximumDiscountAmount: Yup.string().required("Please enter Maximum Amount"),
        maximumUsagePerUser: Yup.string().required("Please enter Usage per user"),
        cabtype: Yup.array().min(1, "Select atleast one option"),
        // subscription: Yup.array().min(1, "Select atleast one option"),
      }),
      validationSchemaPercentage = Yup.object().shape({
        code: Yup.string().required("Please enter Coupon Title"),
        message: Yup.string().required("Please enter Short Description"),
        promoCodeType: Yup.string().required("Select Discount Type").nullable(),
        percent: Yup.number()
          .required("Please enter Percentage")
          .lessThan(101, "Percentage not more than 100"),
        startAt: Yup.string().required("Select Start Date"),
        // select: Yup.string().required(),
        endAt: Yup.string().required("Select End Date"),
        maximumTotalUsage: Yup.string().required("Please enter Usage Limit"),
        status: Yup.string().required("Select Status").nullable(),

        // country:  Yup.object().required("Select Country"),
        // city: Yup.object().required("Select City").nullable(),
        areaType: Yup.string().required("Select AreaType").nullable(),
        applicableFor: Yup.string().required("Select Applicable For").nullable(),
        // gender: Yup.string().required("Select Gender").nullable(),
        borneBy: Yup.string().required("Select Borne By").nullable(),
        totalBudget: Yup.string().required("Select Total Budget").nullable(),
        ...(selectedAreaType.value === 2) && {
          city: Yup.object().required("Select City").nullable(),
          country: Yup.object().required("Select Country").nullable(),
        },
        ...(selectedAreaType.value === 1) && {
          country: Yup.object().required("Select Country").nullable(),
        },
        minimumAmountInCart: Yup.string().required("Please enter Minimum Amount"),
        maximumDiscountAmount: Yup.string().required("Please enter Maximum Amount"),
        maximumUsagePerUser: Yup.string().required("Please enter Usage per user"),
        cabtype: Yup.array().min(1, "Select atleast one option"),
        // subscription: Yup.array().min(1, "Select atleast one option"),
        // ...(selectedAreaType.value === 3) && {
        //   useMap: Yup.string().required("Select Polygon").nullable()
        // }
      }),
      getCabTypes = () => {
        APIService.get(`/admin/cab-type/all`).then((response) => {
          if (response?.status === 200) {
            let options = []
            response?.data?.data && response?.data?.data.map(cab => options.push({ label: cab.name, value: cab.id.trim() }))
            setMultipleOption(options)
          }
        }).catch((e) => console.log(e));
      },
      getSubscriptiobTypes = () => {
        APIService.get(`/admin/subscription`).then((response) => {
          if (response?.status === 200) {
            let options = []
            response?.data?.data && response?.data?.data.map(cab => options.push({ label: cab.packageName, value: cab.id.trim() }))
            setSubscriptionOption(options)
          }
        }).catch((e) => console.log(e));
      },
      isDateExpired = (date) => {
        const endDate = new Date(date);
        const currentDate = new Date();
        if (currentDate > endDate) {
          return true
        } else {
          return false
        }

      },
      getPromos = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/all-promo-Codes")
          .then((response) => {
            if (response.data && response.status === 200) {
              let promos = response.data.data.map((promo) => {
                promo.daysDiff = moment(promo.endAt).diff(
                  moment(promo.startAt),
                  "days"
                );
                promo.status = isDateExpired(promo.endAt) ? "Expired" : promo.status ? "Active" : "Inactive";
                return promo;
              });
              setPromos(
                promos.sort((promo, promo2) => {
                  return new Date(promo2.createdAt) - new Date(promo.createdAt);
                })
              );
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      removePromo = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.delete("admin/promo-code/" + updateData.id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              toast.success("Promo Code deleted successfully");
              getPromos();
            } else {
              toast.error("Failed to delete Promo Code");
            }
            setShowDelete(false);
          })
          .catch((error) => {
            setShowDelete(false);
          });
      };
    const getLat = (a, b) => { console.log("getlat", a, b) };
    const getPolygon = (a) => {
      let polyData = []
      a.map(b => polyData.push({ lat: b.lat, lng: b.long }))
      localStorage.setItem("polygon", JSON.stringify(polyData))
    };
    const handlePolygonSubmit = () => {
      let polygonData = localStorage.getItem("polygon")
      if (polygonData) {
        let parseData = JSON.parse(polygonData),
          dataToString = JSON.stringify(parseData.map(obj => [obj.lat, obj.lng]))
        setPolygonSelected(parseData)
        setSelectedMap(dataToString)
        setShowMap(false)
      }
      else {
        setPolygonError(true)
      }
    }
    useEffect(() => {
      getPromos();
      getCabTypes()
      getSubscriptiobTypes()
    }, []);
    let createOrUpdatePromo = (promoField, props) => {
      // if(selectedMap){
      let type = promoField.type === 0 ? true : false
      promoField.maximumTotalUsage = Number(promoField.maximumTotalUsage);
      promoField.minimumAmountInCart = Number(promoField.minimumAmountInCart);
      promoField.maximumDiscountAmount = Number(promoField.maximumDiscountAmount);
      promoField.maximumUsagePerUser = Number(promoField.maximumUsagePerUser);
      promoField.amount = Number(promoField.amount);
      promoField.method = promoField.promoCodeType;
      promoField.amount =
        promoField.promoCodeType === 2
          ? promoField.percent
          : promoField.amount;
      let cabtype = multipleSelected !== null ? multipleSelected.join(",") : multipleOption.map(item => item.value).join(',')
      let subscription = subscriptionSelected !== null ? subscriptionSelected.join(",") : subscriptionOption.map(item => item.value).join(',')
      promoField.productIds = cabtype + "," + subscription
      let url = "admin/coupon";
      if (selectedAreaType.value === 1) {
        promoField.area = promoField.country?.label;
      } else if (selectedAreaType.value === 2) {
        promoField.area = promoField.city?.label;
      } else if (selectedAreaType.value === 3) {
        promoField.area = polygonSelected;
      } else {
        promoField.area = "";
      }
      promoField.gender = 3;

      if (promoField.area !== undefined && promoField.area !== null) {
        if (type) {
          APIService.post(url, promoField)
            .then((response) => {
              if (response.status === 201) {
                toast.success("Promocode created successfully");
                getPromos();
              } else {
                toast.error("Failed to create Promocode");
              }
              props.resetForm();
              setSelectedStatus({ value: true, label: "Active" });
              setSelectedOption({ value: 1, label: "Fixed Amount" });
              setEndDate("");
              if (!saveNmore) {
                handleClose();
              }
            })
            .catch((error) => {
              props.resetForm();
              setSelectedStatus({ value: true, label: "Active" });
              if (!saveNmore) {
                handleClose();
              }
              console.log(error);
            });

        }
        else {
          if (selectedAreaType.value === 3) {
            promoField.area = polyline.encode(polygonSelected);
          }
          APIService.put("admin/promo-code/" + promoField.id, promoField)
            .then((response) => {
              if (response.status === 200) {
                toast.success("Promo Code updated successfully");
                getPromos();
              } else {
                toast.error("Failed to update Promo Code");
              }
              handleCloseUpdate();
            })
            .catch((error) => {
              handleCloseUpdate();
            });
        }
        // if (updateData && updateData.id) {

        // } else {
        // let url =
        //   promoField.promoCodeType === 2 ? "admin/voucher" : "admin/coupon";
        // }

        setShowUpdate(false);
        localStorage.removeItem("polygon")
        setShowErrorPolygon(false);
      }
      else {
        setShowMapError(true);
      }

    },
      updateTableData = (rowData) => {
        let promo = { ...rowData },
          cabtype = multipleOption.filter(op => {
            return promo.productIds && promo.productIds.split(",").map(a => a.trim()).includes(op.value.trim())
          }).map(item => item.value),
          subscription = subscriptionOption.filter(op => {
            return promo.productIds && promo.productIds.split(",").map(a => a.trim()).includes(op.value.trim())
          }).map(item => item.value);
        promo.startAt = new Date(promo.startAt);
        promo.endAt = new Date(promo.endAt);
        promo.status = promo.status.toLowerCase() === "active" ? true : false;
        promo.cabtype = cabtype
        promo.subscription = subscription
        setSelectedStatus(
          statusOptions.find((status) => {
            return status.value === promo.status;
          })
        );
        setSelectedOption(
          options.find((prm) => {
            return prm.value === promo.promoCodeType;
          })
        );
        if (promo.promoCodeType === 2) {
          promo.percent = promo.amount;
        }
        if (promo.areaType === 1) {
          let selectedCountry = countries.find(cn => cn.name === promo.area)
          promo.country = { label: selectedCountry.name, value: selectedCountry.id, ...selectedCountry }
        }
        if (promo.areaType === 2) {
          promo.city = { label: promo.area, value: promo.area }
        }
        if (promo.areaType === 3) {
          setSelectedMap(polyline.decode(promo.area))
          // promo.useMap = JSON.stringify(polyline.decode(promo.area))
          setPolygonSelected(polyline.decode(promo.area))
        }
        setSelectedAreaType(areaOptions[promo.areaType - 1])
        setMultipleSelected(cabtype)
        setSubscriptionSelected(subscription)
        setUpdateData(promo);
      };
    const countries = Country.getAllCountries();

    const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country,
    }));
    const updatedCities = (stateId) =>
      City.getCitiesOfCountry(stateId).map((city) => ({
        label: city.name,
        value: city.id,
        ...city,
      }));
      useEffect(()=>{
        if(selectedMap!==null){
          setShowMapError(false);
        }
      },[selectedMap])

    useEffect(() => {
      if (selectedMap) { selectedMap ? setShowErrorPolygon(false) : setShowErrorPolygon(true) }

    }, [selectedMap])
    let calendarRef = useRef(null)
    let calendarRef2 = useRef(null)
    const handleCalendarHide = () => setIsCalendarOpen(false)
    return (
      <>
        <PromotionalCouponsListing
          promos={promos}
          handleShowUpdate={handleShowUpdate}
          updateTableDate={updateTableData}
          handleShowDelete={handleShowDelete}
          isLoading={useSelector((state) => state.isGridLoader)}
          handleShow={handleShow}
        />

        <Modal
          className="modal-promotion"
          show={show}
          size="lg"
          onHide={handleClose}
          centered
          backdrop="static"
        // keyboard={false}
        >
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={
              selectedOption.value === 1
                ? validationSchemaAmount
                : validationSchemaPercentage
            }
            onSubmit={createOrUpdatePromo}
          >
            {({ handleChange, handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add New promotion code
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="code"
                            type="text"
                            name="code"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Name"
                            onChange={(e) => handleChange('code')(e.target.value.trimStart())}
                          />
                          <label htmlFor="code">Name</label>
                          <ErrorMessage
                            name="code"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="maximumTotalUsage"
                            type="number"
                            name="maximumTotalUsage"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Usage Limit"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumTotalUsage",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumTotalUsage",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumTotalUsage">Usage Limit</label>
                          <ErrorMessage
                            name="maximumTotalUsage"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Applicable For"
                            // isClearable
                            name="applicableFor"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "applicableFor" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("applicableFor", e.value);
                                // setFieldValue("amount", "");
                                setSelectedAplicableFor(e);
                              }
                            }}
                            // value ={selectedAplicableFor}
                            options={applicableOptions}
                          />
                          <label>Applicable For</label>
                          <ErrorMessage
                            name="applicableFor"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <MultiSelect
                            className="height-50 br-10 bg-color-select"
                            name="cabtype"
                            placeholder="select cab type"
                            value={multipleSelected}
                            options={multipleOption}
                            onChange={(e) => {
                              setFieldValue("cabtype", e.value)
                              setMultipleSelected(e.value)
                            }}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "cabtype" } });
                            }}
                          />
                          <label htmlFor="cabtype">Select Cab Type</label>
                          {/* <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Applicable For"
                            // isClearable
                            name="applicableFor"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "applicableFor" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("applicableFor", e.value);
                                // setFieldValue("amount", "");
                                setSelectedAplicableFor(e);
                              }
                            }}
                            // value ={selectedAplicableFor}
                            options={applicableOptions}
                          />
                          <label>Applicable For</label> */}
                          <ErrorMessage
                            name="cabtype"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <MultiSelect
                            className="height-50 br-10 bg-color-select"
                            name="subscription"
                            placeholder="select subscription type"
                            value={subscriptionSelected}
                            options={subscriptionOption}
                            onChange={(e) => {
                              setFieldValue("subscription", e.value)
                              setSubscriptionSelected(e.value)
                            }}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "subscription" } });
                            }}
                          />
                          <label htmlFor="subscription">Select Subscription Type</label>
                          <ErrorMessage
                            name="subscription"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Area Type"
                            // isClearable
                            name="areaType"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "areaType" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("areaType", e.value);
                                // setFieldValue("amount", "");
                                setSelectedAreaType(e);
                              }
                            }}
                            options={areaOptions}
                          // value ={selectedAreaType}
                          />
                          <label>Area Type</label>
                          <ErrorMessage
                            name="areaType"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {selectedAreaType.value === 1 ? (
                        <Col md={6}>
                          <div className="mb-4 form-label-group">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Country"
                              // isClearable
                              name="country"
                              styles={colourStyles}
                              // value ={selectedCountry}
                              // onBlur={() => {
                              //   handleBlur({ target: { name: "Country" } });
                              // }}
                              onChange={(e) => {

                                setFieldValue("country", e);
                              }}
                              options={updatedCountries}
                              value={values.country}
                            />
                            <label>Country</label>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                      ) : selectedAreaType.value === 2 ? (
                        <>
                          <Col md={6}>
                            <div className="mb-4 form-label-group">
                              <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="Country"
                                // isClearable
                                name="country"
                                styles={colourStyles}
                                // value ={selectedCountry}
                                // onBlur={() => {
                                //   handleBlur({ target: { name: "Country" } });
                                // }}
                                onChange={(e) => {

                                  setFieldValue("country", e);
                                }}
                                options={updatedCountries}
                                value={values.country}
                              />
                              <label>Country</label>
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-4 form-label-group">
                              <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="City"
                                // isClearable
                                name="city"
                                styles={colourStyles}
                                options={updatedCities(
                                  values.country ? values.country.isoCode : null
                                )}
                                value={values.city}
                                onChange={(value) =>
                                  setFieldValue("city", value)
                                }
                              // onBlur={() => {
                              //   handleBlur({ target: { name: "City" } });
                              // }}
                              // onChange={(e) => {
                              //   if (e) {
                              //     setFieldValue("city", e.value);
                              //     // setFieldValue("amount", "");
                              //     setSelectedCity(e);
                              //   }
                              // }}
                              // // value ={selectedCity}
                              // options={cityOptions}
                              />
                              <label>City</label>
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </>
                      )
                        : selectedAreaType.value === 3 ? (
                          <Col md={6}>
                            <div
                              className="mb-4 form-label-group"

                            >
                              {/* <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="Select Polygon"
                                // isClearable
                                name="useMap"
                                styles={colourStyles}
                                menuIsOpen={false}
                                // onBlur={() => {
                                //   handleBlur({ target: { name: "useMap" } });
                                // }}
                                // options={polygonOptions}
                                value={selectedMap}
                              /> */}
                              <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                border: '1.5px solid lightgray',
                                padding: '10px 5px',
                                borderRadius: '7px'
                              }}>
                                <Field
                                  id="useMap"
                                  type="text"
                                  name="useMap"
                                  label="Use Map"
                                  className="shadow-none form-control modal-input br-10"
                                  placeholder="useMap"
                                  value={selectedMap ?? "Click icon to Select"}
                                  style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
                                  readOnly
                                />
                                <i className="pi pi-map-marker" style={{ fontSize: '25px', cursor: 'pointer' }}
                                  onClick={() => setShowMap(true)}></i>
                              </div>
                              {/* <label htmlFor="useMap">Use Map</label> */}
                              {showMapError && (
                                <div className="text-danger">Select Polygon</div>
                              )}
                            </div>
                          </Col>
                        )
                          : (
                            ""
                          )}

                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Calendar
                            minDate={new Date()}
                            maxDate={values.endAt}
                            placeholder="Valid From"
                            onBlur={() => {
                              handleBlur({ target: { name: "startAt" } });
                            }}
                            value={updateData.startAt}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("startAt", e.value);
                              }
                            }}
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            ref={calendarRef}
                            onFocus={() => setTimeout(() => {
                              calendarRef.current.showOverlay()
                            }, 100)}
                            name="startAt"
                            dateFormat="dd/mm/yy"
                            className="p-column-filter"
                          />

                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="startAt"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/*<Col md={6}>
                      <div className="form-group form-label-group">
                          <Field
                            id="validFrom"
                            type="date"
                            name="validFrom"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Valid From"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "validFrom",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "validFrom",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="validFrom">Valid From</label>
                          <ErrorMessage
                            name="validFrom"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className="form-group form-label-group">
                          <Field
                            id="validTo"
                            type="date"
                            name="validTo"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Valid From"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "validTo",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "validTo",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="validFrom">Valid To</label>
                          <ErrorMessage
                            name="validTo"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Applicable For"
                            // isClearable
                            name="applicableFor"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "applicableFor" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("applicableFor", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="applicableFor"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="City"
                            // isClearable
                            name="City"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "City" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("City", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="City"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Gender"
                            // isClearable
                            name="gender"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "Gender" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("gender", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Country"
                            // isClearable
                            name="country"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "Country" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("country", e.value);
                                setFieldValue("country", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}

                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Calendar
                            appendTo={null}
                            minDate={values.startAt}
                            placeholder="Valid To"
                            dateFormat="dd/mm/yy"
                            className="p-column-filter"
                            name="endAt"
                            value={updateData.endAt}
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            ref={calendarRef2}
                            onFocus={() => setTimeout(() => {
                              calendarRef2.current.showOverlay()
                            }, 100)}
                            onBlur={() => {
                              handleBlur({ target: { name: "endAt" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("endAt", e.value);
                              }
                            }}
                          >
                            {" "}
                          </Calendar>
                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="endAt"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            type="textarea"
                            id="message"
                            name="message"
                            rows="10"
                            className="shadow-none form-control br-10 h-desc "
                            placeholder="Description"
                          />
                          <label htmlFor="message">Description</label>
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Discount Type"
                            // isClearable
                            name="promoCodeType"
                            styles={colourStyles}
                            // value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "promoCodeType" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("promoCodeType", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="promoCodeType"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          {selectedOption?.value === 2 ? (
                            <>
                              <Field
                                id="percent"
                                type="number"
                                name="percent"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                              />
                              <label htmlFor="percent">Enter Value</label>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px 5px",
                                  width: "25px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                                className="bg-lightGreen"
                              >
                                %
                              </div>
                              <ErrorMessage
                                name="percent"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </>
                          ) : (
                            <>
                              <Field
                                id="amount"
                                type="number"
                                name="amount"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                                maxLength="2"
                              />
                              <label htmlFor="amount">Enter Value</label>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px",
                                  width: "45px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                                className="bg-lightGreen"
                              >
                                SAR.
                              </div>
                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Select
                            className="height-50"
                            placeholder="Select Status"
                            // isClearable
                            name="status"
                            //  value={selectedStatus}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            options={statusOptions}
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/* <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Use Map"
                            // isClearable
                            name="useMap"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "useMap" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("useMap", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="useMap"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Borne By"
                            // isClearable
                            name="borne"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "borne" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("borne", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="borne"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Gender"
                            // isClearable
                            name="gender"
                            styles={colourStyles}
                            // value ={selectedGender}
                            onBlur={() => {
                              handleBlur({ target: { name: "gender" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("gender", e.value);
                                // setFieldValue("amount", "");
                                setSelectedGender(e);
                              }
                            }}
                            options={genderOptions}
                          />
                          <label>Gender</label>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}

                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Borne By"
                            // isClearable
                            name="borneBy"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "borneBy" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("borneBy", e.value);
                                // setFieldValue("amount", "");
                                setSelectedBorneBy(e);
                              }
                            }}
                            options={borneOptions}
                          // value ={selectedBorneBy}
                          />
                          <label htmlFor="">Borne By</label>
                          <ErrorMessage
                            name="borneBy"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id=" "
                            type="number"
                            name="totalBudget"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Enter Value"
                          />
                          <label htmlFor="">Total Budget</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="totalBudget"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="minimumAmountInCart"
                            type="number"
                            name="minimumAmountInCart"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Usage Limit"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "minimumAmountInCart",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "minimumAmountInCart",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="minimumAmountInCart">Minimum Amount In Cart</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="minimumAmountInCart"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="maximumDiscountAmount"
                            type="number"
                            name="maximumDiscountAmount"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Usage Limit"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumDiscountAmount",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumDiscountAmount",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumDiscountAmount">Maximum Discount Amount</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="maximumDiscountAmount"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="maximumUsagePerUser"
                            type="number"
                            name="maximumUsagePerUser"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Usage Limit"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumUsagePerUser",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumUsagePerUser",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumUsagePerUser">Maximum Usage PerUser</label>
                          <ErrorMessage
                            name="maximumUsagePerUser"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-save "
                          onClick={() => setSaveNmore(false)}
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal
          className="modal-promotion"
          size="lg"
          show={showUpdate}
          onHide={handleCloseUpdate}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Formik
            enableReinitialize
            initialValues={{ ...updateData }}
            validationSchema={
              selectedOption.value === 1
                ? validationSchemaAmount
                : validationSchemaPercentage
            }
            onSubmit={createOrUpdatePromo}
          >
            {({ handleChange, handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Update Promotional Coupon
                    </Modal.Title>
                    <div className="divclose" onClick={handleCloseUpdate}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Field
                            id="code"
                            type="text"
                            name="code"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Name"
                            onChange={(e) => handleChange('code')(e.target.value.trimStart())}
                          />
                          <label htmlFor="code">Name</label>
                          <ErrorMessage
                            name="code"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Field
                            id="maximumTotalUsage"
                            type="text"
                            name="maximumTotalUsage"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Usage Limit"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumTotalUsage",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumTotalUsage",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumTotalUsage">Usage Limit</label>
                          <ErrorMessage
                            name="maximumTotalUsage"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Applicable For"
                            // isClearable
                            name="applicableFor"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "applicableFor" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("applicableFor", e.value);
                                // setFieldValue("amount", "");
                                setSelectedAplicableFor(e);
                              }
                            }}
                            value={selectedAplicableFor}
                            options={applicableOptions}
                          />
                          <label>Applicable For</label>
                          <ErrorMessage
                            name="applicableFor"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <MultiSelect
                            className="height-50 br-10 bg-color-select"
                            name="cabtype"
                            placeholder="select cab type"
                            value={multipleSelected}
                            options={multipleOption}
                            onChange={(e) => {
                              setFieldValue("cabtype", e.value)
                              setMultipleSelected(e.value)
                            }}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "cabtype" } });
                            }}
                          />
                          <label htmlFor="cabtype">Select Cab Type</label>
                          <ErrorMessage
                            name="cabtype"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <MultiSelect
                            className="height-50 br-10 bg-color-select"
                            name="subscription"
                            placeholder="select Subscription type"
                            value={subscriptionSelected}
                            options={subscriptionOption}
                            onChange={(e) => {
                              setFieldValue("subscription", e.value)
                              setSubscriptionSelected(e.value)
                            }}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "subscription" } });
                            }}
                          />
                          <label htmlFor="subscription">Select Subscription Type</label>
                          <ErrorMessage
                            name="subscription"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Area Type"
                            // isClearable
                            name="areaType"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "areaType" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("areaType", e.value);
                                setFieldValue("city", "")
                                setFieldValue("country", "");
                                setSelectedMap(null);
                                // setFieldValue("amount", "");
                                setSelectedAreaType(e);
                              }
                            }}
                            options={areaOptions}
                            value={selectedAreaType}
                          />
                          <label>Area Type</label>
                          <ErrorMessage
                            name="areaType"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {selectedAreaType.value === 1 ? (
                        <Col md={6}>
                          <div className="mb-4 form-label-group">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Country"
                              // isClearable
                              name="country"
                              styles={colourStyles}
                              // value ={selectedCountry}
                              // onBlur={() => {
                              //   handleBlur({ target: { name: "Country" } });
                              // }}
                              onChange={(e) => {
                                setFieldValue("country", e);
                              }}
                              options={updatedCountries}
                              value={values.country}
                            />
                            <label>Country</label>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                      ) : selectedAreaType.value === 2 ? (
                        <>
                          <Col md={6}>
                            <div className="mb-4 form-label-group">
                              <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="Country"
                                // isClearable
                                name="country"
                                styles={colourStyles}
                                // value ={selectedCountry}
                                // onBlur={() => {
                                //   handleBlur({ target: { name: "Country" } });
                                // }}
                                onChange={(e) => {

                                  setFieldValue("country", e);
                                }}
                                options={updatedCountries}
                                value={values.country}
                              />
                              <label>Country</label>
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-4 form-label-group">
                              <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="City"
                                // isClearable
                                name="city"
                                styles={colourStyles}
                                options={updatedCities(
                                  values.country ? values.country.isoCode : null
                                )}
                                value={values.city}
                                onChange={(value) =>
                                  setFieldValue("city", value)
                                }
                              // onBlur={() => {
                              //   handleBlur({ target: { name: "City" } });
                              // }}
                              // onChange={(e) => {
                              //   if (e) {
                              //     setFieldValue("city", e.value);
                              //     // setFieldValue("amount", "");
                              //     setSelectedCity(e);
                              //   }
                              // }}
                              // // value ={selectedCity}
                              // options={cityOptions}
                              />
                              <label>City</label>
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </>
                      )
                        : selectedAreaType.value === 3 ? (
                          <Col md={6}>
                            <div
                              className="mb-4 form-label-group"
                              onClick={() => setShowMap(true)}
                            >
                              {/* <Select
                                className="height-50 br-10 bg-color-select"
                                placeholder="Select Polygon"
                                // isClearable
                                name="useMap"
                                styles={colourStyles}
                                menuIsOpen={false}
                                // onBlur={() => {
                                //   handleBlur({ target: { name: "useMap" } });
                                // }}
                                // options={polygonOptions}
                                value={selectedMap}
                              /> */}
                              <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                border: '1.5px solid lightgray',
                                padding: '10px 5px',
                                borderRadius: '7px'
                              }}>
                                <Field
                                  id="useMap"
                                  type="text"
                                  name="useMap"
                                  label="Use Map"
                                  className="shadow-none form-control modal-input br-10"
                                  placeholder="useMap"
                                  value={selectedMap ?? "Click icon to Select"}
                                  style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
                                  readOnly
                                />
                                <i className="pi pi-map-marker" style={{ fontSize: '25px', cursor: 'pointer' }}
                                  onClick={() => setShowMap(true)}></i>
                              </div>
                              {/* <label htmlFor="useMap">Use Map</label> */}
                              {showMapError && (
                                <div className="text-danger">Select Polygon</div>
                              )}
                            </div>
                          </Col>
                        )
                          : (
                            ""
                          )}
                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="City"
                            // isClearable
                            name="city"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "City" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("city", e.value);
                                // setFieldValue("amount", "");
                                setSelectedCity(e);
                              }
                            }}
                            value={selectedCity}
                            options={cityOptions}
                          />
                          <label>City</label>
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Gender"
                            // isClearable
                            name="gender"
                            styles={colourStyles}
                            value={selectedGender}
                            onBlur={() => {
                              handleBlur({ target: { name: "Gender" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("gender", e.value);
                                // setFieldValue("amount", "");
                                setSelectedGender(e);
                              }
                            }}
                            options={genderOptions}
                          />
                          <label>Gender</label>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Country"
                            // isClearable
                            name="country"
                            styles={colourStyles}
                            value={selectedCountry}
                            onBlur={() => {
                              handleBlur({ target: { name: "Country" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("country", e.value);
                                // setFieldValue("country", "");
                                setSelectedCountry(e);
                              }
                            }}
                            options={countryOptions}
                          />
                          <label>Country</label>
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                      <Col md={6}>
                        <div className="mb-4">
                          <Select
                            className="height-50"
                            placeholder="Select Status"
                            // isClearable
                            name="status"
                            value={selectedStatus}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            options={statusOptions}
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/*<Col md={6}>
                      <div className="form-group form-label-group">
                          <Field
                            id="validFrom"
                            type="date"
                            name="validFrom"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Valid From"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "validFrom",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "validFrom",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="validFrom">Valid From</label>
                          <ErrorMessage
                            name="validFrom"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className="form-group form-label-group">
                          <Field
                            id="validTo"
                            type="date"
                            name="validTo"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Valid From"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "validTo",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "validTo",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="validFrom">Valid To</label>
                          <ErrorMessage
                            name="validTo"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Applicable For"
                            // isClearable
                            name="applicableFor"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "applicableFor" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("applicableFor", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <ErrorMessage
                            name="applicableFor"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4 from-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="City"
                            // isClearable
                            name="City"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "City" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("City", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <label>City</label>
                          <ErrorMessage
                            name="City"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Gender"
                            // isClearable
                            name="gender"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "Gender" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("gender", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <label>Gender</label>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}> 
                      <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Country"
                            // isClearable
                            name="country"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "Country" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("country", e.value);
                                setFieldValue("country", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <label>Country</label>
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Calendar
                            appendTo={null}
                            minDate={new Date()}
                            maxDate={values.endAt ? values.endAt : ""}
                            placeholder="Valid From"
                            onBlur={() => {
                              handleBlur({ target: { name: "startAt" } });
                            }}
                            value={updateData.startAt}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("startAt", e.value);
                              }
                            }}
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            ref={calendarRef}
                            onFocus={() => setTimeout(() => {
                              calendarRef.current.showOverlay()
                            }, 100)}
                            name="startAt"
                            dateFormat="dd/mm/yy"
                            className="p-column-filter"
                          />
                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="startAt"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-group form-label-group">
                          <Calendar
                            appendTo={null}
                            minDate={values.startAt ? values.startAt : ""}
                            placeholder="End Date"
                            dateFormat="dd/mm/yy"
                            className="p-column-filter"
                            name="endAt"
                            value={updateData.endAt}
                            onBlur={() => {
                              handleBlur({ target: { name: "endAt" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("endAt", e.value);
                                setEndDate(e.value);
                              }
                            }}
                            showOnFocus={isCalendarOpen}
                            onHide={handleCalendarHide}
                            ref={calendarRef2}
                            onFocus={() => setTimeout(() => {
                              calendarRef2.current.showOverlay()
                            }, 100)}
                          />
                          <span className="input-box-icon">
                            <Calendar4 />
                          </span>
                          <ErrorMessage
                            name="endAt"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            type="textarea"
                            id="message"
                            name="message"
                            rows="10"
                            className="shadow-none form-control br-10 h-desc "
                            placeholder="Description"
                          />
                          <label htmlFor="message">Description</label>
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Borne By"
                            // isClearable
                            name="borne"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "borne" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("borneBy", e.value);
                                // setFieldValue("amount", "");
                                setSelectedBorneBy(e);
                              }
                            }}
                            options={borneOptions}
                            value={selectedBorneBy}
                          />
                          <label htmlFor="">Borne By</label>
                          <ErrorMessage
                            name="borne"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id=" "
                            type="number"
                            name="totalBudget"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Enter Value"
                          />
                          <label htmlFor="">Total Budget</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="totalBudget"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/* <Col md={6}>
                        <Row>
                          <Col md={6} className="pl-0">
                            <div className="form-group form-label-group">
                              <Field
                                id=" "
                                type="number"
                                name="RiDE"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                              />
                              <label htmlFor=" ">RiDE</label>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px 5px",
                                  width: "25px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                                className="bg-lightGreen"
                              >
                                %
                              </div>
                              <ErrorMessage
                                name="RiDE"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6} className="pr-0">
                            <div className="form-group form-label-group">
                              <Field
                                id=" "
                                type="number"
                                name="Captain"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                              />
                              <label htmlFor=" ">Captain</label>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px 5px",
                                  width: "25px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                                className="bg-lightGreen"
                              >
                                %
                              </div>
                              <ErrorMessage
                                name="Captain"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col> */}
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="minimumAmountInCart"
                            type="text"
                            name="minimumAmountInCart"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Minimum Amount In Cart"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "minimumAmountInCart",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "minimumAmountInCart",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="minimumAmountInCart">Minimum Amount In Cart</label>
                          <ErrorMessage
                            name="minimumAmountInCart"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="maximumDiscountAmount"
                            type="text"
                            name="maximumDiscountAmount"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Maximum Discount Amount"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumDiscountAmount",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumDiscountAmount",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumDiscountAmount">Maximum Discount Amount</label>
                          <ErrorMessage
                            name="maximumDiscountAmount"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="maximumUsagePerUser"
                            type="text"
                            name="maximumUsagePerUser"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Maximum Usage Per User"
                            onKeyUp={(event) => {
                              let re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                re.test(event.target.value)
                              ) {
                                setFieldValue(
                                  "maximumUsagePerUser",
                                  event.target.value
                                );
                              } else {
                                setFieldValue(
                                  "maximumUsagePerUser",
                                  event.target.value.slice(0, -1)
                                );
                              }
                            }}
                          />
                          <label htmlFor="maximumUsagePerUser">Maximum Usage PerUser</label>
                          <ErrorMessage
                            name="maximumUsagePerUser"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Button
                          variant="primary"
                          className="btn-save save-button"
                          type="submit"
                          style={{ minHeight: "60px" }}
                        >
                          Update
                        </Button>
                      </Col>
                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Discount Type"
                            // isClearable
                            name="promoCodeType"
                            value={selectedOption}
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "promoCodeType" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setSelectedOption(e);
                                setFieldValue("promoCodeType", e.value);
                                setFieldValue("amount", "");
                                setFieldValue("percent", "");
                              }
                            }}
                            options={options}
                          />
                          <label>Discount Type</label>
                          <ErrorMessage
                            name="promoCodeType"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          {selectedOption?.value === 2 ? (
                            <>
                              <Field
                                id="percent"
                                type="number"
                                name="percent"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                              />
                              <label htmlFor="percent">Enter Value</label>
                              <div
                                className="input-box-icon bg-lightGreen"
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px 5px",
                                  width: "28px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                              >
                                %
                              </div>
                              <ErrorMessage
                                name="percent"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </>
                          ) : (
                            <>
                              <Field
                                id="amount"
                                type="number"
                                name="amount"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Enter Value"
                                maxLength="2"
                              />
                              <label htmlFor="amount">Enter Value</label>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  position: "absolute",
                                  right: "15px",
                                  top: "12px",
                                  fontWeight: "bold",
                                  color: "#13B542",
                                  Background: "#E3F6E8 !important",
                                  padding: "9px",
                                  width: "45px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                                className="bg-lightGreen"
                              >
                                SAR.
                              </div>
                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 bg-color-select"
                            placeholder="Select Status"
                            // isClearable
                            name="status"
                            styles={colourStyles}
                            value={selectedStatus}
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            options={statusOptions}
                          />
                          <label htmlFor="">Status</label>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Use Map"
                            // isClearable
                            name="useMap"
                            styles={colourStyles}
                            value={selectedOption}
                            onBlur={() => {
                              handleBlur({ target: { name: "useMap" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("useMap", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={options}
                          />
                          <label>Map</label>
                          <ErrorMessage
                            name="useMap"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Borne By"
                            // isClearable
                            name="borne"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "borne" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("borne", e.value);
                                setFieldValue("amount", "");
                                setSelectedOption(e);
                              }
                            }}
                            options={borneOptions}
                          />
                          <label>Borne By</label>
                          <ErrorMessage
                            name="borne"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        {/* ===================x
                Select Modal
         ====================== */}
        <Modal
          className="modal-promotion modal-overlay"
          show={showMap}
          size="lg"
          // onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="flex-modal-header">
            <Modal.Title className="modal-title">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setShowMap(false)}
              >
                <i className="fa fa-chevron-left back-arrow mt-2"></i>
              </span>
              Use map
            </Modal.Title>
            <span className="text-small-modal">
              Use your mouse and draw a rectangle on map to select
            </span>
          </Modal.Header>
          <Modal.Body className="mt-24">
            <Row className="justify-content-center">
              <Col md={12} className="mb-4">
                <PlacesWithStandaloneSearchBox
                  getLat={getLat}
                  getPolygon={getPolygon}
                  dispatch={props.dispatch}
                />
                {/* <span
                          className="p-input-icon-left"
                          style={{ minWidth: "100%" }}
                        >
                          <i className="pi pi-search" />
                          <InputText
                            type="search"
                            placeholder="Search"
                            // onInput={(e) => setGlobalFilter(e.target.value)}
                            style={{ minWidth: "100%" }}
                          />
                        </span> */}
              </Col>
              {/* <Col md={12}>
                      <MapLoader
                      googleMapURL= "https://maps.googleapis.com/maps/api/js?key=AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg&libraries=geometry,drawing,places"
                      loadingElement= {<div style={{ height: `100%` }}/>}
                      />
                      </Col> */}
              <Col md={6} className="mt-3">
                {polygonError && <p style={{ color: 'red' }}>Please Select Polygon First!</p>}
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-save"
                  onClick={handlePolygonSubmit}
                >
                  Proceed
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <DeleteModal
          clickYes={removePromo}
          showDelete={showDelete}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(PromotionalCoupon));
