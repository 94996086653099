import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";

//src
import PromotionalCoupon from "./PromotionalCoupon";
import Notifications from "./Notifications"
import HeatMapDisplay from "../components/HeatMap/HeatMapDisplay";
import CaptainOwnACarHeatMap from "../components/HeatMap/CaptainOwnACarHeatMap";
import OfflineCaptains from "../components/HeatMap/OfflineCaptains";
let HeatMap = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = atob(localStorage.getItem("access"));

  useEffect(() => {
    getActiveTab();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <span className="title">Heatmap</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/heat-map/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

          <Tab eventKey="all" title="All" className={tab === "all" ? "active-heatmap-tab" : ""}>
            {tab === "all" && <HeatMapDisplay type="all" />}
          </Tab>

          {/* <Tab eventKey="heat-map-ride-a-ride" title="Ride A Ride">
            {tab === "heat-map-ride-a-ride" && <HeatMapDisplay />}
        </Tab> */}

          <Tab eventKey="own-a-car" title="Own A Car" className={tab === "own-a-car" ? "active-heatmap-tab" : ""}>
            {tab === "own-a-car" && <HeatMapDisplay type="own-a-car" />}
          </Tab>

          {/* <Tab eventKey="heat-map-pickup-ride-a-ride" title="Pickup Ride A Ride">
            {tab === "heat-map-pickup-ride-a-ride" && <HeatMapDisplay />}
          </Tab>

          <Tab eventKey="heat-map-pickup-own-a-car" title="Pickup Own-A-Car">
            {tab === "heat-map-pickup-own-a-car" && <HeatMapDisplay />}
          </Tab> */}

          <Tab eventKey="ontrip-captains" title="On Trip Captains">
            {tab === "ontrip-captains" && <HeatMapDisplay type="ontrip-captains" />}
          </Tab>

          <Tab eventKey="free-online-drivers" title="Free Online Drivers">
            {tab === "free-online-drivers" && <HeatMapDisplay type="free-online-drivers" />}
          </Tab>

          <Tab eventKey="offline-captains" title="Offline Captains">
            {tab === "offline-captains" && <OfflineCaptains />}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(HeatMap);
