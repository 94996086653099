import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";
import saveAs from "save-as";
import moment from "moment";
import { InputText } from "primereact/inputtext";
let EmergencyRequestListing = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [emergencyRequests, setEmergencyRequests] = useState(null),
    [createdAt, setCreatedAt] = useState([]),
    [selectedStatus, setSelectedStatus] = useState(null),
    [selectedTripStatus, setSelectedTripStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [selectedRows, setSelectedRows] = React.useState([]),
    dt = useRef(null),
    statusOptions = [
      { label: "Open", value: 1 },
      { label: "Resolved", value: 2 },
    ],
    tripStatusOptions = [
      { value: 1, label: "Ongoing" },
      { value: 2, label: "Assigned" },
      { value: 3, label: "Stop & Completed" },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: "desc",
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getEmergencyRequests = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/emergency-request/all", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setEmergencyRequests(response.data.data.requests);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageEmergencyRequest = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getEmergencyRequests();
    },
    exportEmergencyRequests = () => {
      let requests = [
        "Trip Id",
        "Date & Time",
        "Rider Name",
        "Reason",
        "Location",
        "Trip status",
        "Assigned by",
        "Status",
      ];
      requests = requests.join(",") + "\r\n";
      if (selectedRows.length) {
        selectedRows.forEach((request) => {
          requests +=
            [
              request.trip.tripNo,
              " " + moment(request.createdAt).format("DD/MM/YYYY") + " ",
              request.rider.fullName,
              request.reason,
              request.location,
              getStatusText(request.tripStatus),
              request.assignedBy,
              request.issueStatus ? "Active" : "Inactive",
            ].join(",") + "\r\n";
        });
        let transBlob = new Blob([requests], {
          type: "text/csv;charset=utf-8",
        });

        saveAs(transBlob, "Emergency-Request.csv");
      } else {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });

        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        APIService.post("admin/emergency-request/all", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.requests.forEach((request) => {
                requests +=
                  [
                    request.trip.tripNo,
                    " " + moment(request.createdAt).format("DD/MM/YYYY") + " ",
                    request.rider.fullName,
                    request.reason,
                    request.location,
                    getStatusText(request.tripStatus),
                    request.assignedBy,
                    request.issueStatus ? "Active" : "Inactive",
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([requests], {
                  type: "text/csv;charset=utf-8",
                }),
                "Emergency-Request.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            console.log("error", error);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getEmergencyRequests();
  }, []);

  let getStatusText = (value) => {
    let statusText;
    switch (value) {
      case 1:
        //statusText = "Pending";
        statusText = "Ongoing";
        break;
      case 2:
        //statusText = "Accepted by driver";
        statusText = "Assigned";
        break;
      case 3:
        //statusText = "Rejected by driver";
        statusText = "Stop & Completed";
        break;
      case 4:
        statusText = "Cancelled by driver";
        break;
      case 5:
        statusText = "Driver arrived";
        break;
      case 6:
        statusText = "Cancelled by rider";
        break;
      case 7:
        statusText = "Started";
        break;
      case 8:
        statusText = "Completed";
        break;
      case 9:
        statusText = "No driver";
        break;
      case 10:
        statusText = "Expired";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getEmergencyRequests();
              }}
            />
          </span>
          <span className="d-flex">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={exportEmergencyRequests}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getEmergencyRequests();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={emergencyRequests}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Request(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageEmergencyRequest}
          onSort={manageEmergencyRequest}
          onFilter={manageEmergencyRequest}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
          onSelectionChange={(e) => {
            setSelectedRows(e.value);
          }}
          selection={selectedRows}
          selectionMode="checkbox"
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Trip Id"
            field="tripId"
            filter={showFilter}
            filterField="tripNo"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <Link
                    to={`/emergency-request-detail/${rowData.id}`}
                    className="dataFnt-size"
                  >
                    <span className="dataFnt-size">{rowData.trip.tripNo}</span>
                  </Link>
                </React.Fragment>
              );
            }}
            sortable
            filterPlaceholder="Search by Id"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            body={(request) => {
              return moment(request.createdAt).format("DD/MM/YYYY hh:mm A");
            }}
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    setCreatedAt(e.value);
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getEmergencyRequests();
                        }, 500);
                      }
                    } else {
                      delete lazyParams.filters["createdAt"];
                      setCreatedAt([]);
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getEmergencyRequests();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "160px" }}
            field="rider.fullName"
            header="Rider Name"
            sortable
            filterField="riderName"
            filter={showFilter}
            filterPlaceholder="Search by Name"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Reason"
            field="reason"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by reason"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="Location"
            field="location"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Location"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Trip Status"
            filterField="tripStatus"
            sortField="tripStatus"
            field="tripStatus"
            body={(rowData) => getStatusText(rowData.tripStatus)}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Status"
            filterElement={
              <Dropdown
                value={selectedTripStatus}
                options={tripStatusOptions}
                onChange={(event) => {
                  setSelectedTripStatus(event.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    tripStatus: event.value,
                  };
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getEmergencyRequests();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Assigned by"
            field="assignedBy"
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
            filterField="assignedById"
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="issueStatus"
            header="Status"
            body={(request) => (request.issueStatus ? "Open" : "Resolved")}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  if (event.value || event.value === false) {
                    lazyParams.filters["issueStatus"] = event.value;
                  } else {
                    delete lazyParams.filters["issueStatus"];
                  }
                  setSelectedStatus(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getEmergencyRequests();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(EmergencyRequestListing));
