import React from "react";

function AnalyticCard(props) {
  return (
    <>
      <div class="col-lg-3 col-md-6 col-12 mb-3">
        <div class="card">
          <div class="card-body d-flex  justify-content-between">
            <div style={{ marginTop: "-5px" }}>
              <p className="light-text analyticName">{props.ProfileName}</p>
              <h5 class="card-title analyticsTitle font-weight-bold">
                {props.Points}
              </h5>
            </div>
            <div className="pt-3 AnalyticPoint">
              <span
                className="d-flex ml-3 text-center justify-content-between"
                style={{
                  color: `${props.percentTextColor}`,
                  backgroundColor: `${props.percentBgColor}`,
                  borderRadius: "40px",
                  padding: "4px 6px",
                }}
              >
                <small className="mr-1">
                  <img
                    src={props.arrow}
                    className="arrowImg"
                    alt="arrow image svg"
                  />
                </small>
                <small>{props.percentage}%</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyticCard;
