import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { connect, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";
import CancelRideReasonDriverListing from "../components/CancelRideReasonDriver/CancelRideReasonDriverListing";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ],
  CancelRideReasonDriverPage = (props) => {
    let [windowWidth] = useState(useSelector((state) => state.windowSize)),
      [cancelReasons, setCancelReasons] = useState([]),
      [show, setShow] = useState(false),
      [showUpdate, setShowUpdate] = useState(false),
      [showDelete, setShowDelete] = useState(false),
      [selectedStatus, setSelectedStatus] = useState(null),
      [updateData, setUpdateData] = useState({
        reason: "",
        reasonArabic: "",
        status: true,
      }),
      [saveNmore, setSaveNmore] = useState(false),
      handleClose = () => setShow(false),
      handleShow = () => {
        setShow(true);
        setSelectedStatus({ value: true, label: "Active" });
        setUpdateData({
          reason: "",
          reasonArabic: "",
          status: true,
        });
      },
      handleCloseUpdate = () => setShowUpdate(false),
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rowData) => {
        setUpdateData(rowData);
        setShowDelete(true);
      },
      updateTableData = (rowData) => {
        let reason = { ...rowData };
        reason.status = reason.status === "Active" ? true : false;
        setUpdateData(reason);
        setSelectedStatus({
          value: rowData.status === "Active" ? true : false,
          label: rowData.status,
        });
      },
      createOrUpdateCancelReason = (reason, props) => {
        reason.status = Number(selectedStatus.value);
        reason.reasonType = 1;
        if (!updateData || !updateData.id) {
          APIService.post("admin/rejected-reason", reason)
            .then((response) => {
              if (response.status === 201) {
                toast.success("Reason created successfully");
                getRejectedReasons();
              } else {
                toast.error("Failed to create reason");
              }
              props.resetForm();
              setSelectedStatus({ value: true, label: "Active" });
              if (!saveNmore) {
                handleClose();
              }
            })
            .catch((error) => {
              props.resetForm();
              setSelectedStatus({ value: true, label: "Active" });
              if (!saveNmore) {
                handleClose();
              }
              console.log(error);
            });
        } else {
          APIService.patch("admin/rejected-reason/" + updateData.id, reason)
            .then((response) => {
              if (response.status === 200) {
                toast.success("Reason updated successfully");
                getRejectedReasons();
              } else {
                toast.error("Failed to update reason");
              }
              handleCloseUpdate();
            })
            .catch((error) => {
              toast.error("Failed to update reason");
              handleCloseUpdate();
            });
        }
      },
      removeCancelReason = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.delete("admin/rejected-reason/" + updateData.id)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Reason deleted successfully");
              getRejectedReasons();
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
        handleCloseDelete();
      },
      validationSchema = Yup.object().shape({
        reason: Yup.string().required("Please enter reason").nullable(),
        reasonArabic: Yup.string().required("Please enter reason").nullable(),
        status: Yup.string().required("Select Status"),
      }),
      getRejectedReasons = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/rejected-reason/type/1")
          .then((response) => {
            if (response.data.statusCode === 200) {
              let reasons = response.data.data.map((reason) => {
                reason.status = reason.status ? "Active" : "Inactive";
                return reason;
              });
              setCancelReasons(reasons);
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      handleActiveInactiveStatus = (status, id) => {
        APIService.patch(`admin/rejected-reason-status/${id}`, { status })
          .then((response) => {
            if (response && response.data.statusCode === 200) {
              toast.success("Updated Successfully");
            }
          })
          .catch((error) => {
            toast.error("Something went wrong.");
          });
      };

    React.useEffect(() => {
      getRejectedReasons();
    }, []);

    return (
      <>
        <div className="rider-listing">
          <div className="d-flex justify-content-between">
            <span className="title">Decline Reason - Driver</span>
            <button className="btn btn-primary mb-1" onClick={handleShow}>
              {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
            </button>
          </div>
          <CancelRideReasonDriverListing
            cancelReasons={cancelReasons}
            handleShow={handleShow}
            handleShowUpdate={handleShowUpdate}
            handleShowDelete={handleShowDelete}
            updateTableData={updateTableData}
            handleActiveInactiveStatus={handleActiveInactiveStatus}
            isLoading={useSelector((state) => state.isGridLoader)}
          />
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Formik
            initialValues={updateData}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateCancelReason}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      Add Reason
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        id="reason"
                        type="text"
                        name="reason"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Reason"
                      />
                      <label htmlFor="reason">Reason English</label>
                      <ErrorMessage
                        name="reason"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="form-group form-label-group">
                      <Field
                        id="reasonArabic"
                        type="text"
                        name="reasonArabic"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Reason Arabic"
                      />
                      <label htmlFor="reasonArabic">Reason Arabic</label>
                      <ErrorMessage
                        name="reasonArabic"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Status"
                        // isClearable
                        name="status"
                        styles={colourStyles}
                        id="status"
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("status", e.value);
                            setSelectedStatus(e);
                          }
                        }}
                        value={selectedStatus}
                        options={statusOptions}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      className="btn-save save-button mb-3 border-radius-15"
                      type="submit"
                      onClick={() => setSaveNmore(false)}
                    >
                      Add
                    </Button>
                    <Button
                      type="submit"
                      variant="secondary"
                      className="btn-save-add save-button mb-3 border-radius-15"
                      onClick={() => setSaveNmore(true)}
                    >
                      Save & Add more
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
          <Formik
            initialValues={updateData}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateCancelReason}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      Update Reason
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        id="reason"
                        type="text"
                        name="reason"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Reason"
                      />
                      <label htmlFor="reason">Reason English</label>
                      <ErrorMessage
                        name="reason"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="form-group form-label-group">
                      <Field
                        id="reasonArabic"
                        type="text"
                        name="reasonArabic"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Reason Arabic"
                      />
                      <label htmlFor="reasonArabic">Reason Arabic</label>
                      <ErrorMessage
                        name="reasonArabic"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Status"
                        // isClearable
                        name="status"
                        styles={colourStyles}
                        id="status"
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("status", e.value);
                            setSelectedStatus(e);
                          }
                        }}
                        value={selectedStatus}
                        options={statusOptions}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-save save-button mb-3 border-radius-15"
                    >
                      Update
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <DeleteModal
          clickYes={removeCancelReason}
          showDelete={showDelete}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(CancelRideReasonDriverPage));
