export const  DataConversions = (mt940String)=>{
    // const extractTag61 = (mt940String) => {
        let mtget = JSON.stringify(mt940String);
        // console.log(mtget)
        const regex = /(:61:.*?)(?=\s*:|$)/g;
        const matches = [];
    
        let match;
        while ((match = regex.exec(mtget))) {
          matches.push(match[1]);
        }
        console.log(matches)
        let Data = matches.map(a=> a.split("\\")[0])
    
       let final61Data = Data.map((message) => {
        const value_date = message.substring(3, 10);
        const entry_date = message.substring(10, 14);
        const cd_mark = message.substring(14, 16);
        const amount_end = message.indexOf('N', 16);
        const amount = message.substring(16, amount_end);
        const tr_code = message.substring(amount_end, amount_end + 4);
        const owner_ref = message.substring(amount_end + 4, amount_end + 18);
        const inst_ref = message.substring(amount_end + 18);
          return {
            value_date,
            entry_date,
            cd_mark,
            amount,
            tr_code,
            owner_ref,
            inst_ref,
          };
        });
        return final61Data
        // setFileContent(final61Data)
    //   };
}