import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Link, useParams } from "react-router-dom";
import { Slider } from "primereact/slider";
import APIService from "../../services/APIService";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { CalendarRange } from "react-bootstrap-icons";
import rideSource from "../../assest/images/ride-app-source.svg";



const PickupTripsHistory=()=> {

  
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let { id } = useParams();
  let [pickupsTripsData, setPickupsTripsData] = useState([]);
  let [filterStatus, setFilterStatus] = useState(null);
  let [createdAt, setCreatedAt] = useState(null);
  let [showFilter, setShowFilter] = useState(false);
  let [ratingFilterRange, setRatingFilterRange] = useState([0, 5]);
  let dt = useRef(null);
  let [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: { field: "createdAt", order: -1 },
      skip: 0,
      take: 25,
      totalRecords: 0,
      keyword: "",
    }),
    getStatusText = (value) => {
      let statusText;
      switch (value) {
        case 1:
          statusText = <span className="status-text-pending">Pending</span>;
          break;
        case 2:
          statusText = <span className="status-text">Accepted</span>;
          break;
        case 3:
          statusText = <span className="status-text-inactive">Rejected</span>;

          break;
        case 4:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 5:
          statusText = <span className="status-text">Arrived</span>;
          break;
        case 6:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 7:
          statusText = <span className="status-text-pending">Started</span>;
          break;
        case 8:
          statusText = <span className="status-text">Completed</span>;
          break;
        case 9:
          statusText = <span className="status-text-pending">No Driver</span>;
          break;
        case 10:
          statusText = <span className="status-text-inactive">Expired</span>;
          break;
        default:
          statusText = "-";
          break;
      }
      return statusText;
    },
    pickupsData = [{
      "Trip_ID": "2030493511",
      "pickup_location": 97.286868,
      "dropoff_location": 4.1382779,
      "captain_contact": "7870577014",
      "rating": "Male",
      "rider_contact": "8544999085",
      "date&time": "8/30/2022",
      "total_fare": 95,
      "promo_applied":'checking..',
      "status": 1,
      "source": "http://dummyimage.com/148x100.png/ff4444/ffffff",
      "rider_rating": 1,
      "driver_rating": 1
    }, {
      "Trip_ID": "6234563708",
      "pickup_location": 111.4526032,
      "dropoff_location": -8.2296365,
      "captain_contact": "2673963766",
      "rating": "Male",
      "rider_contact": "1606301993",
      "date&time": "6/18/2022",
      "total_fare": 25,
      "promo_applied": 'checking..',
      "status": 2,
      "source": "http://dummyimage.com/185x100.png/5fa2dd/ffffff",
      "rider_rating": 2,
      "driver_rating": 2
    }, {
      "Trip_ID": "8585685166",
      "pickup_location": 1.1586866,
      "dropoff_location": 47.3410322,
      "captain_contact": "1324092963",
      "rating": "Male",
      "rider_contact": "2931504866",
      "date&time": "2/10/2023",
      "total_fare": 29,
      "promo_applied": 'checking..',
      "status": 3,
      "source": "http://dummyimage.com/134x100.png/cc0000/ffffff",
      "rider_rating": 3,
      "driver_rating": 3
    }, {
      "Trip_ID": "6350185412",
      "pickup_location": 116.70345,
      "dropoff_location": 23.365556,
      "captain_contact": "0026076012",
      "rating": "Female",
      "rider_contact": "3148439481",
      "date&time": "4/19/2023",
      "total_fare": 33,
      "promo_applied":'checking..',
      "status": 4,
      "source": "http://dummyimage.com/192x100.png/dddddd/000000",
      "rider_rating": 4,
      "driver_rating": 4
    }, {
      "Trip_ID": "3393961837",
      "pickup_location": 108.739519,
      "dropoff_location": 27.062407,
      "captain_contact": "9772130882",
      "rating": "Genderfluid",
      "rider_contact": "0501448063",
      "date&time": "9/26/2022",
      "total_fare": 28,
      "promo_applied": 'checking..',
      "status": 5,
      "source": "http://dummyimage.com/115x100.png/dddddd/000000",
      "rider_rating": 5,
      "driver_rating": 5
    }, {
      "Trip_ID": "8500750642",
      "pickup_location": 2.0308233,
      "dropoff_location": 44.1675867,
      "captain_contact": "3147932583",
      "rating": "Genderqueer",
      "rider_contact": "8258825003",
      "date&time": "5/20/2022",
      "total_fare": 100,
      "promo_applied": 'checking..',
      "status": 6,
      "source": "http://dummyimage.com/213x100.png/ff4444/ffffff",
      "rider_rating": 1,
      "driver_rating": 3
    }, {
      "Trip_ID": "2713382815",
      "pickup_location": -5.9251211,
      "dropoff_location": 37.4212157,
      "captain_contact": "4145880161",
      "rating": "Bigender",
      "rider_contact": "9119105444",
      "date&time": "11/24/2022",
      "total_fare": 6,
      "promo_applied": 'checking..',
      "status": 7,
      "source": "http://dummyimage.com/147x100.png/ff4444/ffffff",
      "rider_rating": 5,
      "driver_rating": 3
    }, {
      "Trip_ID": "7586233268",
      "pickup_location": -43.6644238,
      "dropoff_location": -19.8852788,
      "captain_contact": "7955416015",
      "rating": "Female",
      "rider_contact": "1246092646",
      "date&time": "7/1/2022",
      "total_fare": 25,
      "promo_applied": 'checking..',
      "status": 8,
      "source": "http://dummyimage.com/244x100.png/dddddd/000000",
      "rider_rating": 1,
      "driver_rating": 2
    }, {
      "Trip_ID": "1485507006",
      "pickup_location": 114.057865,
      "dropoff_location": 22.543096,
      "captain_contact": "7398787340",
      "rating": "Bigender",
      "rider_contact": "3678241530",
      "date&time": "5/11/2022",
      "total_fare": 86,
      "promo_applied":'checking..',
      "status": 9,
      "source": "http://dummyimage.com/111x100.png/cc0000/ffffff",
      "rider_rating": 2,
      "driver_rating": 4
    }, {
      "Trip_ID": "6352566251",
      "pickup_location": 112.528308,
      "dropoff_location": 32.990664,
      "captain_contact": "2308497203",
      "rating": "Male",
      "rider_contact": "0604393679",
      "date&time": "5/23/2022",
      "total_fare": 59,
      "promo_applied": 'checking..',
      "status": 10,
      "source": "http://dummyimage.com/221x100.png/dddddd/000000",
      "rider_rating": 3,
      "driver_rating": 2
    }],
    getPickupTrips = () => {
      // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      setPickupsTripsData(pickupsData);

    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getPickupTrips();
    },
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setFilterStatus(null);
      setCreatedAt(null);
      setLazyParams({
        filters: {},
        sort: { field: "createdAt", order: -1 },
        skip: 0,
        take: 25,
        totalRecords: 0,
        keyword: "",
      });
      getPickupTrips();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getPickupTrips();
  }, []);

  let statuses = [
      //{ label: "Pending", value: 1 }
      { label: "Accepted By Driver", value: 2 },
      { label: "Rejected By Driver", value: 3 },
      { label: "Cancelled By Driver", value: 4 },
      { label: "Driver Arrived", value: 5 },
      { label: "Cancelled By Rider", value: 6 },
      { label: "Started", value: 7 },
      { label: "Completed", value: 8 },
      //{ label: "No Driver", value: 9 },
      //{ label: "Expired", value: 10 },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getPickupTrips();
              }}
              placeholder="Search..."
            />
          </span>
        </div>
      );
    },
    header = renderHeader();


  return (
    <div className="datatable-doc-demo mt-3">
    <div className="card">
      <DataTable
        ref={dt}
        value={pickupsTripsData}
        header={header}
        className="p-datatable-customers"
        dataKey="id"
        rowHover
        scrollable
        scrollHeight="470px"
        paginator
        emptyMessage="No Trip(s) found"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={lazyParams.totalRecords}
        lazy={true}
        first={lazyParams.skip}
        rows={lazyParams.take}
        sortField={lazyParams.sort.field}
        sortOrder={lazyParams.sort.order}
        onPage={manageTripHistory}
        onSort={manageTripHistory}
        onFilter={manageTripHistory}
        loading={useSelector((state) => state.isGridLoader)}
        loadingIcon="fa fa-spinner"
      >
        <Column
          className="text-center"
          style={{ fontSize: "12px", width: "140px" }}
          sortField="Trip_ID"
          filterField="Trip_ID"
          header="Trip ID"
          field="Trip_ID"
          body={(trip) => (
            <Link
              to={"/trip-details/2233bcc9-b9e0-40c7-a02b-8bded1e4ec12"}
              style={{ color: "rgba(19, 181, 66, 1)" }}
            >
              {trip.Trip_ID}
            </Link>
          )}
          sortable
          filter={showFilter}
          filterPlaceholder="Search by id"
        />
        {/* <Column
          className="text-center"
          style={{ fontSize: "12px", width: "180px" }}
          sortField="driverId"
          filterField="driverId"
          header="Driver ID"
          body={(trip) =>
            trip.driver && (
              <Link
                to={`/driver-details/details/${trip.driver.id}`}
                className="dataFnt-size"
              >
                {trip.driver.userId}
              </Link>
            )
          }
          sortable
          filter={showFilter}
          filterPlaceholder="Search"
        /> */}
        {/* <Column
          className="text-center"
          style={{ fontSize: "12px", width: "160px" }}
          field="overallRating"
          header="Rating"
          body={
            (rider) => {
              if (rider.overallRating == 0) {
                return "--";
              } else {
                return ratingCount(Math.round(rider.overallRating));
              }
            }
            // <Rating
            //   className="ratings pl-0 d-flex justify-content-center"
            //   fractions={2}
            //   emptySymbol="fa fa-star-o fa-2x star"
            //   fullSymbol="fa fa-star fa-2x star"
            //   initialRating={rider.overallRating}
            //   readonly
            // />
          }
          sortable
          filter={false}
        /> */}
        <Column
          filter={showFilter}
          className="text-center"
          style={{ fontSize: "12px", width: "230px" }}
          header="Date & Time"
          body={(rowData) =>
            moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")
          }
          field="createdAt"
          sortable
          filterElement={
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["createdAt"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getPickupTrips();
                      }, 500);
                    }
                  } else {
                    setCreatedAt([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getPickupTrips();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          }
        />
        <Column
          style={{ fontSize: "12px", width: "250px", color: "#000000" }}
          sortField="pickup"
          filterField="pickup"
          header="Pickup Location"
          field="pickup_location"
          //sortField="pickup"
          //filterField="pickup"

          filter={showFilter}
          filterPlaceholder="Search by pickup"
        />
        <Column
          style={{ fontSize: "12px", width: "250px", color: "#000000" }}
          header="Drop Off Location"
          field="dropoff_location"
          sortField="dropoff"
          filterField="dropoff"
          filter={showFilter}
          filterPlaceholder="Search by drop"
        />

        <Column
          className="text-center"
          style={{ fontSize: "12px", width: "180px" }}
          sortField="riderContact"
          filterField="riderContact"
          header="Rdier Contact"
          body={(trip) =>
            (
              <Link
                to={`/driver-details/details/${trip.rider_contact}`}
                className="dataFnt-size"
              >
                {trip.rider_contact}
              </Link>
            )
          }
          sortable
          filter={showFilter}
          filterPlaceholder="Search"
        />
        <Column
          className="text-center"
          style={{ fontSize: "12px", width: "180px" }}
          sortField="captainContact"
          filterField="captainContact"
          header="Captain Contact"
          body={(trip) =>
             (
              <Link
                to={`/driver-details/details/${trip.captain_contact}`}
                className="dataFnt-size"
              >
                {trip.captain_contact}
              </Link>
            )
          }
          sortable
          filter={showFilter}
          filterPlaceholder="Search"
        />

        {/* <Column
          className="text-center"
          style={{ fontSize: "12px", width: "150px" }}
          header="Total Fare"
          field="tripBaseAmount"
          body={(trip) => {
            if (trip.tripBaseAmount) {
              let total =
                trip.tripBaseAmount +
                trip.taxAmount +
                trip.waitingCharge -
                trip.promoCodeAmount;
              return " SAR " + total.toFixed(2);
            } else {
              return " SAR " + 0;
            }
          }}
          sortable
          filter={showFilter}
          filterPlaceholder="Search subtotal"
        /> */}
<Column
          className="text-center"
          headerClassName="text-right"
          style={{ fontSize: "10px", width: "150px" }}
          field="total_fare"
          header="Total Fare"
          sortable
          filter={showFilter}
          filterField="riderAmount"
          filterPlaceholder="Search by fare"
        />
        <Column
          className="text-center"
          style={{ fontSize: "10px", width: "170px" }}
          field="promo_applied"
          header="Promo Applied"
          sortable
          // body={(trip) => {
          //   return trip?.promoCode ? "Yes" : "No";
          // }}
        />

        <Column
          className="text-center"
          style={{ fontSize: "10px", width: "120px" }}
          header="Source"
          sortable
          body={(driver) => {
            return <img src={rideSource} alt="ride" className="nav_icon" />;
          }}
        />

        <Column
          className="text-center text-capitalize"
          style={{ fontSize: "12px", width: "200px" }}
          field="status"
          header="Status"
          sortable
          filter={showFilter}
          
          body={(trip) => {
            if (trip.status === 5) {
              return <span className="status-text">Completed</span>;
            }
            else if (trip.status===2){
              return <span className="status-text">pending</span>
            }
            else {
              return <span className="status-text-inactive">Cancelled</span>;
            }
          }}

          filterElement={
            <Dropdown
              value={filterStatus}
              options={statuses}
              onChange={(event) => {
                setFilterStatus(event.value);
                if (event.value) {
                  lazyParams.filters["status"] = event.value;
                } else {
                  delete lazyParams.filters["status"];
                }
                setLazyParams(lazyParams);
                setTimeout(() => {
                  getPickupTrips();
                }, 500);
              }}
              placeholder="Select Status"
              className="p-column-filter"
              showClear
            />
          }
        />

        <Column
          className="text-center"
          style={{ fontSize: "10px", width: "150px" }}
          header="Driver Rating"
          sortable
          body={(trip) => {
            return ratingCount(trip.driver_rating);
          }}
        />

        <Column
          className="text-center"
          style={{ fontSize: "10px", width: "150px" }}
          header="Rider Rating"
          sortable
          body={(trip) => {
            return ratingCount(trip.rider_rating);
          }}
        />

        <Column
          className="text-center"
          style={{ fontSize: "12px", width: "180px" }}
          sortField="Action"
          filterField="Action"
          header="Action"
          body={(trip) => {
            return <img src={trip.source} alt="ride" style={{width:'40px',height:'40px',borderRadius:'45%'}} />;
          }}
          
          sortable
          filter={showFilter}
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  </div>
  )
  }

export default PickupTripsHistory;