import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import UserDetail from "../components/Users/UserDetail";
import UserAuditLog from "../components/Users/UserAuditLog";

let User = (props) => {
  let [tab, setTab] = useState(
    props.match.params.tab ? props.match.params.tab : "details"
  );
  useEffect(() => {
    if (props.match.params.tab) {
      setTab(props.match.params.tab);
    }
  }, [props.match.params.tab]);
  return (
    <div className="rider-listing">
      <span
        onClick={() => props.history.goBack()}
        style={{ cursor: "pointer" }}
      >
        <i className="fa fa-chevron-left back-arrow"></i>
      </span>
      <span className="title">User </span>

      <Tabs
        id="controlled-tab-example"
        className="mt-30"
        activeKey={tab}
        onSelect={(tab) => {
          setTab(tab);
          props.history.push(`/user/${props.match.params.id}/${tab}`);
        }}
      >
        <Tab eventKey="details" title="User Details">
          {!tab ||
            (tab === "details" && <UserDetail user={props.match.params.id} />)}
        </Tab>
        <Tab eventKey="audit-log" title="Audit Log">
          {tab === "audit-log" && <UserAuditLog user={props.match.params.id} />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default React.memo(connect()(User));
