import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import APIService from "../services/APIService";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";

let Map = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
      <Marker position={{ lat: -34.397, lng: 150.644 }} />
    </GoogleMap>
  )),
  TrackDrivers = (props) => {
    return <Map />;
  };

export default React.memo(connect()(TrackDrivers));
