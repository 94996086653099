const CarDetailTable = [

]
const manufacturerData = [{ label: "Tata", value: "Tata" }, { label: "Hyundai", value: "Hyundai" }, { label: "Azera", value: "Azera" }, { label: "Suzuki", value: "Suzuki" }]
const modelandyearData = [{ label: "Tata H1 2023", value: "Tata H1 2023" }, { label: "Azera B3 2021", value: "Tata H1 2023" }, { label: "Suzuki S2 1988", value: 'Tata H1 2023' }]
const categoryData = [{ label: "Fancy", value: "Fancy" }, { label: "Elephant", value: "Elephant" }, { label: "Normal", value: "Fancy" }]
const OwnACarData = [
    {
        id: 1,
        manufacturer: "Hyundai",
        sequenceno: 1234567,
        modelandyear: "Hyundai H1 2023",
        captainpickup: "Captain",
        category: "Elephant",
        carcolour: "Black",
        seating: 4,
        captainId: 6832467337,
        captainname: "Jack Brown",
        status: true

    }, {
        id: 2,
        manufacturer: "Honda",
        sequenceno: 1234567,
        modelandyear: "Hyundai H1 2023",
        captainpickup: "Pickup",
        category: "Elephant",
        carcolour: "Red",
        seating: 4,
        captainId: 6832467337,
        captainname: "John Sunday",
        status: false

    }
]
const Data = [
    {
        id: 1,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 2,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 3,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 4,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 5,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 6,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: false
    }, {
        id: 7,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 8,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 9,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 10,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: true
    }, {
        id: 11,
        date: "10/12/23",
        manufacturer: "Hyundai",
        modelandyear: "Hyundai H1 2023",
        category: "Elephant",
        seating: 4,
        totalcars: 12,
        registeredcars: 3,
        available: 9,
        status: false
    }
]
const BodyColour = [
    { value: 'Black', label: 'Black' },
    { value: 'White', label: 'White' },
    { value: 'Red', label: 'Red' }
]
const ModalData = [
    { value: 'Hyundai Azera', label: 'Hyundai Azera' },
    { value: 'Honda civic', label: 'Honda civic' },
    { value: 'Toyota Corolla', label: 'Toyota Corolla' }
];
const Manufacturer = [
    { value: 'Hyundai', label: 'Hyundai' },
    { value: 'Honda', label: 'Honda' },
    { value: 'Toyota', label: 'Toyota' }
];
const Modalyear = [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 }
];
const TotalCars = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 }
];
const seating = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 }
];

const ActiveInactive = [
    { value: "Active", label: 'Active' },
    { value: 'InActive', label: 'InActive' },
];
const InventoryStatus = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Inactive' },
    { value: 3, label: 'Draft' },
];

const EngineNo = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 }
];
const RegistrationNo = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 }
];
const initialModal = {
    uploadimage: null,
    manufacturer: "",
    category_id: null,
    model_year: "",
    model: "",
    body_color: "",
    sequence_no: "",
    engine_no: "",
    registration_no: "",
    chassis_no: "",
    seating_capacity: "",
    rental_amount: "",
    inventory_status: "",
    isUpdate: false,
}

export { RegistrationNo, EngineNo, BodyColour, Data, ModalData, Manufacturer, seating, Modalyear, TotalCars, ActiveInactive, InventoryStatus, CarDetailTable, OwnACarData, manufacturerData, modelandyearData, categoryData, initialModal }