import React from "react";
import PushNotificationListing from "../components/NotificationManage/PushNotificationListing";

let NotificationPushPage = (props) => {
  const {locationData} = props
   let urlSearchParams = new URLSearchParams(locationData.location.search),
    params = Object.fromEntries(urlSearchParams.entries());
  return (
    <>
    
        <PushNotificationListing {...params} />
     
    </>
  );
};

export default React.memo(NotificationPushPage);
