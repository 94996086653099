import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import logo from "../../assest/images/riderLogo.svg";
import moment from "moment";

let OtherInfo = (props) => {
  const {captainInfo} = props
  var timeDriver =captainInfo?.driverReachedAt && captainInfo?.driverAssignedAt ? moment.utc(moment(captainInfo?.driverReachedAt
    , "HH:mm:ss").diff(moment(captainInfo?.driverAssignedAt, "HH:mm:ss"))).format("mmss") : "----"

    var timeRider =captainInfo?.driverReachedAt && captainInfo?.tripStartedAt ? moment.utc(moment(captainInfo?.tripStartedAt
      , "HH:mm:ss").diff(moment(captainInfo?.driverReachedAt, "HH:mm:ss"))).format("mmss") : "----"
    

  return (
    <>
      <Card className="d-box mtc-10">
        <h2 className="path-text mb-0">Other</h2>
        <Row className="mx-0 mt-24 inner-box">
          <Col md={12} className="px-0">
            <div className="card-mt-13">
              <div className="card-label-13">Time taken by captain</div>
              <div className="card-label-14 text-dark font-weight-bolder">{`${timeDriver[0]}${timeDriver[1]} Min ${timeDriver[2]}${timeDriver[3]} Sec `}</div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Time taken by rider</div>
              <div className="card-label-14 text-dark font-weight-bolder">{`${timeRider[0]}${timeRider[1]} Min ${timeRider[2]}${timeRider[3]} Sec `}</div>
            </div>
            {/* <div className="card-mt-13 mt-2">
              <div className="card-label-13">Captain’s source of trip</div>
              <div className="card-label-14"> Ride</div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Rider’s source of trip</div>
              <div className="card-label-14">Ride</div>
            </div> */}
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">PIN given</div>
              <div className="card-label-14 text-dark font-weight-bolder"> {captainInfo?.tripOtp ? captainInfo?.tripOtp : ""}</div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Whatsapp location</div>
              <div className="card-label-14 text-dark font-weight-bolder">Yes</div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Subscription used</div>
              <div className="card-label-14 text-dark font-weight-bolder">No</div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default React.memo(OtherInfo);
