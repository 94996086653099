import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom";
import rideSource from "../../assest/images/ride-app-source.svg";

let DriverTripHistoryTab = (props) => {
  let [tripHistory, setTripHistory] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [selectedTripHistory, setSelectedTripHistory] = useState(null),
    [filterStatus, setFilterStatus] = useState(""),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState(null),
    [filterRange, setFilterRange] = useState([0, 5]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    getStatusText = (value) => {
      let statusText;
      switch (value) {
        case 1:
          statusText = <span className="status-text-pending">Pending</span>;
          break;
        case 2:
          statusText = <span className="status-text">Accepted</span>;
          break;
        case 3:
          statusText = <span className="status-text-inactive">Rejected</span>;

          break;
        case 4:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 5:
          statusText = <span className="status-text">Arrived</span>;
          break;
        case 6:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 7:
          statusText = <span className="status-text-pending">Started</span>;
          break;
        case 8:
          statusText = <span className="status-text">Completed</span>;
          break;
        case 9:
          statusText = <span className="status-text-pending">No Driver</span>;
          break;
        case 10:
          statusText = <span className="status-text-inactive">Expired</span>;
          break;
        default:
          statusText = "-";
          break;
      }
      return statusText;
    },
    dt = useRef(null),
    getTripHistory = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post(
        "admin/captain-trip-history/" + props.driverId,
        filterParams
      )
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            let tripDataResponse = response.data.data.trips.map((trip) => {
              trip.status = getStatusText(trip.status);

              return trip;
            });

            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setTripHistory(tripDataResponse);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTripHistory();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getTripHistory();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search..."
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getTripHistory();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setFilterStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
      });
      getTripHistory();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={tripHistory}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedTripHistory}
          onSelectionChange={(e) => setSelectedTripHistory(e.value)}
          paginator
          emptyMessage="No Trip History(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          rows={lazyParams.take}
          first={lazyParams.skip}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageTripHistory}
          onSort={manageTripHistory}
          onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Trip ID"
            field="tripNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
            body={(trip) => {
              return (
                <React.Fragment>
                  <Link
                    to={`/trip-details/${trip.id}`}
                    className="dataFnt-size"
                    style={{ color: "rgba(19, 181, 66, 1)" }}
                  >
                    {trip.tripNo}
                  </Link>
                </React.Fragment>
              );
            }}
          />
         
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            filterField="createdAt"
            header="Date & Time"
            body={(trip) => moment(trip.createdAt).format("DD/MM/YYYY hh:mm A")}
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getTripHistory();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTripHistory();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            header="Pickup Location"
            filterField="pickup"
            field="pickup.address"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by pickUp"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            header="Drop Off Location"
            field="dropoff.address"
            filterField="dropoff"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by dropoff"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Rider Contact"
            field="ridercontact"
            sortable
            body={() => "NA/"}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain Contact"
            field="captaincontact"
            sortable
            body={() => "NA/"}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Fare"
            field="riderAmount"
            sortable
            body={(driver) => driver.riderAmount.toFixed(2)}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "170px" }}
            header="Promo Applied"
            sortable
            body={(trip) => {
              return trip?.promoCode ? "Yes" : "No";
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Source"
            sortable
            body={(driver) => {
              return <img src={rideSource} alt="ride" className="nav_icon" />;
            }}
          />

          <Column
            className="text-center text-capitalize"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            // body={(trip) => {
            //   if (trip.status === 8) {
            //     return <span className="status-text">Completed</span>;
            //   } else {
            //     return <span className="status-text-inactive">Cancelled</span>;
            //   }
            // }}
            filterElement={
              <Dropdown
                value={filterStatus}
                options={tripStatus}
                onChange={(event) => {
                  setFilterStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["subscriptionStatus"] = event.value;
                  } else {
                    delete lazyParams.filters["subscriptionStatus"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTripHistory();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "150px" }}
            header="Driver Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.driver?.driverReview);
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "150px" }}
            header="Rider Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.rider?.riderReview);
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Action"
            field="Action"
            sortable
            body={() => "NA/"}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(DriverTripHistoryTab));
