import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import moment from "moment";
import { barChartOptions,convertDataBasedOnRequirementForCancelledTrips } from "./linechartdata/linechartdata";

function CancelledTrips(props) {
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [cancelledGraphData,setCancelledGraphData]=useState({}),
    [statusCounts,setStatusCounts]=useState({}),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;
  useEffect(()=>{
    setStatusCounts(graphCountList
      ? graphCountList
      : props?.sendgraphTripData?.cancelledTotalStats)
  },[props?.sendgraphTripData?.cancelledTotalStats])

  useEffect(() => {
    setTimeRange(props.timeRange);
    // manageTimeRange();
  }, [props.timeRange]);

  let manageTimeRange = () => {
    // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    // setTimeRange(event.target.value);
    APIService.get("admin/dashboard/trip-stats?type=" + props.timeRange)
      .then((response) => {
        if (response.data.statusCode === 200) {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          setDateRange(response?.data?.data?.dateRange)

          setGraphTripList(
            response.data &&
              response.data.data &&
              response.data.data.cancelledGraphList
          );

          setgraphCountList(
            response.data &&
              response.data.data &&
              response.data.data.cancelledTotalStats
          );
        }
      })
      .catch((error) => {});
  };
  useEffect(()=>{
    // setLineGraphData(props?.sendgraphTripData?.graphList)
    let dataGraphList = props?.sendgraphTripData?.cancelledGraphList
    if(dataGraphList!==undefined){
      const convertedData = convertDataBasedOnRequirementForCancelledTrips(dataGraphList);
      setCancelledGraphData(convertedData);
    }
  },[props.sendgraphTripData])

  const [dateRange,setDateRange] =  useState(null);

  return (
    <div class="tab-pane fade active show" id="All">
      <div className="col-12">
        <h6 className=" graph-label left-text rounded text-capitalize">
          Trips
        </h6>
        {Object.keys(cancelledGraphData).length > 0 && (
          <Line data={cancelledGraphData} options={barChartOptions} />
        )}
        <h6 className=" graph-label-2 left-text rounded text-capitalize">
        {dateRange && `${moment(dateRange?.startDate).format('DD MMM YYYY')} - ${moment(dateRange?.endDate).format('DD MMM YYYY')}`}
        </h6>
      </div>

      <div className="col-12 graph-menu g-menu">
        <div className="main-percent line-main">
          <div className="percentage">
            <div className="green"></div>
            {/* <div className="percentext">Total cancelled</div> */}
            <div className="percentext">By Admin</div>
            <div className="percenPoint">{statusCounts?.cancelledByAdmin}</div>
          </div>

          <div className="percentage">
            <div className="red"></div>
            <div className="percentext">By riders</div>
            <div className="percenPoint">{statusCounts?.cancelledByRider}</div>
          </div>

          <div className="percentage">
            <div className="green" style={{ background: "#13A5B5" }}></div>
            <div className="percentext">By captains</div>
            <div className="percenPoint">
              {statusCounts?.cancelledByCaptain}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelledTrips;
