import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CalendarRange } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
let PickupSubscriptionTransactionsTab = (props) => {
 
  let [subscriptions, setSubscriptions] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [globalFilter, setGlobalFilter] = useState(null),
    [createdAt, setCreatedAt] = useState(null),
    [subStatus, setSubStatus] = useState(null),
    [expiryDate, setExpiryDate] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    
  
    getPickupSubscriptions = () => {
      const totalSubscriptions = [
        {   description:'SM1000',
            type: "Weekly",
            amountpaid: 10,
            promoapplied: 'yes',
            start_date: "20 Sept 2022",
            end_date: "27 Sept 2022",
            status: 'Expired'
        },
        {
          description:'SM1040',
            type: "Weekly",
            amountpaid: 10,
            promoapplied: 'yes',
            start_date: "20 Sept 2022",
            end_date: "27 Sept 2022",
            status: 'Active'
        },
        {
          description:'SM1050',
            type: "Monthly",
            amountpaid: 50,
            promoapplied: 'NA',
            start_date: "20 Sept 2022",
            end_date: "27 Sept 2022",
            status: 'Active'
        },
        {   
          description:'SM1020',
            type: "Monthly",
            amountpaid: 50,
            promoapplied: 'yes',
            start_date: "20 Sept 2022",
            end_date: "27 Sept 2022",
            status: 'Expired'
        },
        {
            description:'SM1001',
            type: "Weekly",
            amountpaid: 10,
            promoapplied: 'NA',
            start_date: "20 Sept 2022",
            end_date: "27 Sept 2022",
            status: 'Active'
        },
      ];
            setSubscriptions(totalSubscriptions);
          
    },
    manageActiveSubscription = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getPickupSubscriptions();
    },
    subscriptionStatus = [
      { value: 1, label: "Active" },
      { value: 2, label: "Overdue" },
      { value: 3, label: "Expired" },
      { value: 4, label: "Cancelled" },
      { value: 5, label: "Pending OR Failed" },
    ];

  useEffect(() => {
    getPickupSubscriptions();
  }, []);
  let renderHeader = () => {
      return (
        <div className="table-header">
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  {/* <i className="fa fa-filter"></i>{" "} */}
                  <span className="span-filter">Filters</span>
                  {/* <i className="fa fa-caret-up ml-100"></i> */}
                </>
              ) : (
                <>
                  <i className="fa fa-filter"></i>{" "}
                  {/* <span className="span-filter">Filters</span> */}
                  {/* <i className="fa fa-caret-down ml-100"></i> */}
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              value={globalFilter}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      dt.current.reset();
      setCreatedAt(null);
      setExpiryDate(null);
    },
    onCreatedDateChange = (event) => {
      dt.current.filter(
        event.value ? event.value : null,
        "createdAt",
        "custom"
      );
      setCreatedAt(new Date(moment(event.value).format("DD/MM/YYYY")));
    },
    onExpiredDateChange = (event) => {
      dt.current.filter(event.value ? event.value : null, "endDate", "custom");
      setExpiryDate(new Date(moment(event.value).format("DD/MM/YYYY")));
    },
    filterDate = (value, filter) => {
      return value &&
        filter &&
        moment(value).format("DD/MM/YYYY") ===
          moment(filter).format("DD/MM/YYYY")
        ? true
        : false;
    };

    const renderDescription=(rowData)=>{
       return(
        <>
        <Link to="#" className="text-center text-success">{rowData.description}</Link>
        </>
       )
    };
  return (
    <div className="datatable-doc-demo ">
      <h2 className="box-title">Subscription History </h2>
      <div className="card mt-3">
        <DataTable
          ref={dt}
          value={subscriptions}
          // header={header}
          className="p-datatable-customers"
          dataKey="id"
          globalFilter={globalFilter}
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Subscription(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={subscriptions.length}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          onPage={manageActiveSubscription}
          onSort={manageActiveSubscription}
          onFilter={manageActiveSubscription}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
                    <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Description"
            field="description"
            body={renderDescription}
            sortField="packageName"
            filterField="package.packageName"
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by package"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Type"
            field="type"
            sortField="packageName"
            filterField="package.packageName"
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by package"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Amount Paid"
            field="amountpaid"
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by days"
          />

            <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Promo Applied"
            field="promoapplied"
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by days"
          />


          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="createdAt"
            header="Start Date"
            body={(subscription) =>
              moment(subscription.createdAt).format("DD/MM/YYYY HH:MM A")
            }
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={filterDate}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  value={createdAt}
                  onChange={onCreatedDateChange}
                  placeholder="Search date"
                  dateFormat="dd/mm/yy"
                  className="p-column-filter w-100"
                  showButtonBar
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "220px" }}
            field="endDate"
            body={(subscr) =>
              moment(subscr.endDate).format("DD/MM/YYYY hh:mm A")
            }
            header="End Date"
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={filterDate}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  value={expiryDate}
                  onChange={onExpiredDateChange}
                  placeholder="Search date"
                  dateFormat="dd/mm/yy"
                  className="p-column-filter w-100"
                  showButtonBar
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="status"
            header="Status"
            body={(rowData) =>
              (
                <span
                  style={{
                    color:
                      rowData.status === "Active"
                        ? "#13B542"
                        : rowData.status === "Expired"
                        ? "#EF4242"
                        : "#000000",
                  }}
                >
                  {rowData.status}
                </span>
              ) || ""
            }
            filter={showFilter}
            filterFunction={(value, filter) => value === filter}
            // filterElement={
            //   <Dropdown
            //     value={subStatus}
            //     options={subscriptionStatus}
            //     onChange={(event) => {
            //       setSubStatus(event.value);
            //       dt.current.filter(
            //         event.value ? event.value : null,
            //         "status",
            //         "custom"
            //       );
            //     }}
            //     placeholder="Select Subscription Status"
            //     className="p-column-filter"
            //     showClear
            //   />
            // }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(PickupSubscriptionTransactionsTab));
