import React from "react";
import ReportsDeclineRequestByCaptainListing from "../components/Reports/ReportsDeclineRequestByCaptainListing";

let ReportsDeclineRequestByCaptain = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Declined Trips by Driver Report</span>
        <ReportsDeclineRequestByCaptainListing />
      </div>
    </>
  );
};

export default React.memo(ReportsDeclineRequestByCaptain);
