import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import Registration from "../components/adjust/registration";
import Transaction from "../components/adjust/transaction";
import TripRequest from "../components/adjust/tripRequest";
import MarketingSubscription from "../components/adjust/marketingSubscription";

const AdjustTabLayout = (props) => {
  const [selectTab, setSelectedTab] = useState("registration");

  useEffect(() => {
    setSelectedTab(props.match.params.tab || "registration");
    props.history.push("/adjust/registration/");
  }, []);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    props.history.push(`/adjust/${tab}/`);
  };

  const tabsData = [
    { key: "registration", title: "Registration", component: <Registration dispatch={props?.dispatch} /> },
    { key: "transaction", title: "Transaction", component: <Transaction dispatch={props?.dispatch} /> },
    { key: "trips-request", title: "Trips Request", component: <TripRequest dispatch={props?.dispatch} /> },
    { key: "marketing-subscription", title: "Marketing & subscription", component: <MarketingSubscription dispatch={props?.dispatch} /> },
  ];

  return (
    <>
      <div className="rider-listing">
        <span className="title ml-2">Adjust</span>
      </div>
      <Tabs
        id="controlled-tab-example"
        className="mt-40"
        style={{ background: "#FFFFFF", borderRadius: 6, color: "#828282", margin: 30 }}
        activeKey={selectTab}
        onSelect={handleTabSelect}
      >
        {tabsData.map(({ key, title, component }) => (
          <Tab
            key={key}
            eventKey={key}
            title={<span className={selectTab === key ? "font-weight-bold" : ""}>{title}</span>}
          >
            {selectTab === key && component}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default React.memo(connect()(AdjustTabLayout));
