import React from "react";
import DriverRatingListing from "../components/Rating/DriverRatingListing";

let RatingDrivers = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Drivers Rating</span>
        <DriverRatingListing />
      </div>
    </>
  );
};

export default React.memo(RatingDrivers);
