import { Col, Row } from "react-bootstrap";

const TotalRiders = ({ totalRides, totalRidesCancelled, ridesCancelledByRiders, ridesCancelledByDrivers }) => {
  return (
    <Row>
      <Col md={12}>
        <h2 className="box-title">
          Total Rides{" "}
          <span style={{ color: "#828282", fontWeight: "600" }}>
            (Latest 24 Hours)
          </span>{" "}
          :<span className="text-success font-weight-bold"> {totalRides} </span>
        </h2>
      </Col>
      <Col md={12}>
        <div className="inner-box mt-3">
          <div className="row">
            <div className="col-lg-4 ">
              <div className="flex-sb">
                <div className="trips-title-label">
                  Total Rides Cancelled
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {totalRidesCancelled}
                </div>
              </div>
            </div>
            <div className="col-lg-4 border-custom ">
              <div className="flex-sb">
                <div className="trips-title-label">
                  Rides Cancelled By Riders{" "}
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {ridesCancelledByRiders}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="flex-sb">
                <div className="trips-title-label">
                  {" "}
                  Rides Cancelled By Drivers{" "}
                </div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {ridesCancelledByDrivers}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default TotalRiders