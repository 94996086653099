import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Calendar4 } from "react-bootstrap-icons";
import { useParams, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { Button as PrimeButton } from "primereact/button";
import APIService from "../../services/APIService";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../Common/DeleteModal";
import { toast } from "react-toastify";

let CustomizedFareTab = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/fare-conf/customized/')),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState(null),
    [customized, setCustomized] = useState([]),
    [show, setShow] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [fromDate, setFromDate] = useState(null),
    [toDate, setToDate] = useState(null),
    [saveNmore, setSaveNmore] = useState(false),
    [updateData, setUpdateData] = useState({
      title: "",
      multiplyRate: 0,
      fromDate: "",
      toDate: "",
    }),
    handleClose = () => setShow(false),
    handleShow = () => {
      setUpdateData({
        title: "",
        multiplyRate: 0,
        fromDate: "",
        toDate: "",
      });
      setShow(true);
    },
    validationSchema = Yup.object().shape({
      title: Yup.string().required("Please enter Title"),
      fromDate: Yup.date().nullable().required("Select a From Date"),
      toDate: Yup.date().nullable().required("Select a To Date"),
      multiplyRate: Yup.number()
        .required("Please enter Multiply Rate")
        .max(10, "Multiply Rate not greater than 9.9"),
    }),
    renderHeader = () => {
      return (
        <div className="table-header">
          <span>
            <button className="btn btn-primary show_filter_btn" onClick={() => toggleFilter()}>
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />

                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z" fill="#828282" />
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
              }}
            />
          </span>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
            className="btn btn-primary float-right mb-1 ml-12" onClick={handleShow} >
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </button>}
        </div>
      );
    },
    urlParams = useParams(),
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      getCustomizedFare();
    },
    getCustomizedFare = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/customized-charge-list/" + urlParams.id)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.length;
            setLazyParams(lazyParams);
            setCustomized(response.data.data);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    removeCustomized = () => {
      setShowDelete(false);
      APIService.delete("admin/customized-charge/" + updateData.id).then(
        (response) => {
          getCustomizedFare();
          if (response.status === 200) {
            toast.success("Fare configuration deleted successfully");
          }
        }
      );
    },
    createOrUpdateConf = (formValues, formProps) => {
      formValues.city = urlParams.id;
      formValues.multiplyRate = Number(formValues.multiplyRate);
      if (!updateData.id) {
        APIService.post("admin/customized-charge", formValues)
          .then((response) => {
            getCustomizedFare();
            if (response.data && response.data.statusCode === 200) {
              toast.success("Fare configuration created successfully");
            }
            setUpdateData({
              title: "",
              multiplyRate: 0,
              fromDate: "",
              toDate: "",
            });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            setUpdateData({
              title: "",
              multiplyRate: 0,
              fromDate: "",
              toDate: "",
            });
            if (!saveNmore) {
              handleClose();
            }
          });
      } else {
        handleClose();
        APIService.put(
          "admin/customized-charge/" + updateData.id,
          formValues
        ).then((response) => {
          if (response.data) {
            if (response.data.statusCode === 200) {
              toast.success("Fare configuration updated successfully");
              getCustomizedFare();
            } else {
              toast.error("Failed to update Fare configuration");
            }
            handleClose();
          }
        });
      }
    },
    filterDate = (value, filter) => {
      return value &&
        filter &&
        moment(value).format("DD/MM/YYYY") ===
        moment(filter).format("DD/MM/YYYY")
        ? true
        : false;
    };

  useEffect(() => {
    getCustomizedFare();
  }, []);
  return (
    <>
      {/* <div className="d-flex justify-content-end"> */}
      {/* <button className="btn btn-primary my-3" onClick={handleShow}>
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </button> */}
      {/* </div> */}
      <div className="rider-listing">
        <div className="card">
          <DataTable
            ref={dt}
            value={customized}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Customized Fare(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            lazy={true}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              headerClassName="text-center"
              style={{ fontSize: "12px", width: "200px" }}
              header="Title"
              field="title"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by title"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "150px" }}
              header="Year"
              field="year"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by year"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "230px" }}
              header="Created On"
              field="createdAt"
              body={(customized) =>
                moment(customized.createdAt).format("DD/MM/YYYY hh:mm A")
              }
              sortable
              filter={false}
              filterMatchMode="custom"
              filterFunction={filterDate}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    className="mr-4 mb-2 w-100"
                    placeholder="Select Created At"
                    dateFormat="dd/mm/yy"
                    value={createdAt}
                    hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      dt.current.filter(e.value, "fromDate", "custom");
                      setCreatedAt(e.value);
                    }}
                  />
                </div>
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Applicable From"
              field="fromDate"
              body={(customized) =>
                moment(customized.fromDate).format("DD/MM/YYYY hh:mm A")
              }
              sortable
              filter={false}
              filterMatchMode="custom"
              filterFunction={filterDate}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select Application From"
                    dateFormat="dd/mm/yy"
                    value={createdAt}
                    onChange={(e) => {
                      dt.current.filter(e.value, "fromDate", "custom");
                      setFromDate(e.value);
                    }}
                  />
                </div>
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Applicable To"
              field="toDate"
              body={(customized) =>
                moment(customized.toDate).format("DD/MM/YYYY hh:mm A")
              }
              sortable
              filter={showFilter}
              filterMatchMode="custom"
              filterFunction={filterDate}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    className="mr-4 mb-2 w-100"
                    placeholder="Select Application To"
                    dateFormat="dd/mm/yy"
                    value={toDate}
                    hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      dt.current.filter(e.value, "toDate", "custom");
                      setToDate(e.value);
                    }}
                  />
                </div>
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "150px" }}
              header="Fare Multiply"
              field="multiplyRate"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by fare multiply"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "140px" }}
              header="Action"
              headerClassName="text-center"
              body={(rowData) => (
                <div className="button p-table-button">
                  {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && <PrimeButton
                    tooltip="Edit"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => {
                      setShow(true);
                      setUpdateData(rowData);
                    }}
                  >
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                        fill="#03053D"
                      />
                    </svg>
                  </PrimeButton>}
                  {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <PrimeButton
                    tooltip="Delete"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => {
                      setUpdateData(rowData);
                      setShowDelete(true);
                    }}
                  >
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                        fill="#03053D"
                      />
                    </svg>
                  </PrimeButton>}
                  {!currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && !currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <span>No permission</span>}
                </div>
              )}
            />
          </DataTable>
        </div>
      </div>
      <Modal
        className="modal-customized"
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        backdrop="static" keyboard={false}
      >
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateConf}
        >
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    {!updateData.id ? "Add" : "Update"} Customized Fare
                  </Modal.Title>
                  <div className="divclose" onClick={handleClose}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>

                <Modal.Body className="mt-24">
                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="title"
                          type="text"
                          name="title"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Title"
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="multiplyRate"
                          type="text"
                          name="multiplyRate"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Fare Multiply"
                        />
                        <label htmlFor="multiplyRate">Fare Multiply</label>
                        <ErrorMessage
                          name="multiplyRate"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                        <span
                          className="info__icon"
                          data-bs-toggle="tooltip"
                          title="Tooltip on top"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#d3d9df"
                            className="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 11-2 0 1 1 0 012 0z"></path>
                          </svg>
                        </span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="position-relative form-group form-label-group">
                        <Calendar
                          appendTo={null}
                          placeholder="Applicable From"
                          maxDate={values.toDate && new Date(values.toDate)}
                          onBlur={() => {
                            handleBlur({ target: { name: "fromDate" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("fromDate", e.value);
                            }
                          }}
                          value={
                            updateData.fromDate &&
                            new Date(updateData.fromDate)
                          }
                          name="fromDate"
                          dateFormat="dd/mm/yy"
                          className="p-column-filter"
                        />
                        <span className="input-box-icon">
                          <Calendar4 />
                        </span>
                        <ErrorMessage
                          name="fromDate"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="position-relative form-group form-label-group">
                        <Calendar
                          appendTo={null}
                          placeholder="Applicable To"
                          dateFormat="dd/mm/yy"
                          className="p-column-filter"
                          name="toDate"
                          minDate={
                            values.fromDate && new Date(values.fromDate)
                          }
                          value={
                            updateData.toDate && new Date(updateData.toDate)
                          }
                          onBlur={() => {
                            handleBlur({ target: { name: "toDate" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("toDate", e.value);
                            }
                          }}
                        />
                        <span className="input-box-icon">
                          <Calendar4 />
                        </span>
                        <ErrorMessage
                          name="toDate"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {!updateData.id && (
                        <Button
                          variant="secondary"
                          className="btn-save save-button"
                          onClick={() => setSaveNmore(true)}
                        >
                          Save & Add more
                        </Button>
                      )}
                    </Col>
                    <Col md={6}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-save save-button"
                        onClick={() => setSaveNmore(false)}
                      >
                        {!updateData.id ? "Add" : "Update"}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <DeleteModal
        showDelete={showDelete}
        clickYes={removeCustomized}
        handleCloseDelete={() => setShowDelete(false)}
      />
    </>
  );
};

export default React.memo(connect()(withRouter(CustomizedFareTab)));
