import React from "react";
import Card from "react-bootstrap/Card";
import arrow from "./images/arrow.svg";
import downArrow from "./images/downArrow.svg";
import redgraph from "./images/redgraph.svg";
import graph from "./images/graph.svg";

function SingalCard(props) {
  const { data } = props;
  return (
    <>
    

      <div className="col-xl-4 col-lg-6 col-md-6 mb-2">
        <div className="reg-box">
          <div className="d-flex justify-content-between align-items-center  gap">
            <div className="content-area">
              <div className="d-flex align-items-center">
                <div>
                  <Card
                    className="title-section"
                    style={{ backgroundColor: `${props.profilebgColor}` }}
                  >
                    <img src={props.profile} />
                  </Card>
                </div>
                <div className="ml-12">
                  <p className="label-light">{props?.ProfileName}</p>
                  <div className="flex-data ">
                    <p className="label-heading mb-0">{data?.count}</p>
                    {data?.status == "up" ? (
                      <span
                        className="badge-green"
                        style={{
                          color: "#13B542",
                          backgroundColor: "rgba(19, 181, 66, 0.1)",
                        }}
                      >
                        <img src={arrow} />
                        <span>{data?.percentage}%</span>
                      </span>
                    ) : (
                      <span
                        className="badge-green "
                        style={{
                          color: "#EF4242",
                          backgroundColor: "rgba(239, 66, 66, 0.12)",
                        }}
                      >
                        <img src={downArrow} />
                        <span>{data?.percentage}%</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="chart-area">
            {data?.status == "up" ?  <img src={graph} alt="graph image svg" /> :  <img src={redgraph} alt="graph image svg" /> }
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingalCard;
