import React from "react";
import ReportsDriversEarningListing from "../components/Reports/ReportsDriversEarningListing";

let ReportsDriverEarning = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Drivers Earning Report</span>
        <ReportsDriversEarningListing />
      </div>
    </>
  );
};

export default React.memo(ReportsDriverEarning);
