import React, { useState, useEffect } from "react";
import APIService from "../../services/APIService";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";

let RadiusSettingDetailPage = () => {
  let [tripSettingData, setTripSettingData] = React.useState([]),
    [updateData, setUpdateData] = useState(null),
    [changeValue, setChangeValue] = useState(""),
    [show, setShow] = useState(false),
    handleClose = () => setShow(false),
    handleShow = (rowData) => {
      setUpdateData(rowData);
      setChangeValue(rowData.value);
      setShow(true);
    };

  useEffect(() => {
    APIService.get("admin/settings").then((response) => {
      if (response.data && response.data.statusCode === 200) {
        setTripSettingData(response.data.data);
      }
    });
  }, []);

  let updateSetting = () => {
    let details = {
      value: changeValue,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    APIService.patch("admin/setting/" + updateData.name, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      formBody,
    }).then((response) => {
      if (response.data && response.data.statusCode === 200) {
        setTripSettingData(response.data.data);
      }
    });
  };

  return (
    <>
      <div className="rider-listing">
        <span className="title">Radius Setting</span>
        <Card className="mt-3">
          <Row>
            <Col md={12} className="p-3 ml-3">
              {tripSettingData &&
                tripSettingData
                  .filter(
                    (tripData) => tripData.name === "TRIP_DRIVER_SEARCH_RADIUS"
                  )
                  .map((tripData) => (
                    <div className="pt-3">
                      <Row>
                        <Col md={4}>{tripData.name}</Col>
                        <Col md={4}>{tripData.value}</Col>
                        <Col md={4}>
                          <div className="button p-table-button">
                            <Button
                              variant="light"
                              tooltip="Edit"
                              tooltipOptions={{ position: "bottom" }}
                              onClick={() => handleShow(tripData)}
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                                  fill="#03053D"
                                />
                              </svg>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
            </Col>
          </Row>
        </Card>
      </div>

      <Modal show={show} size="" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {" "}
            Update Radius Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-header-footer">
          <form>
            <div className="form-group form-label-group">
              <input
                id="couponTitle"
                type="text"
                name="couponTitle"
                className="shadow-none form-control modal-input br-10"
                placeholder="Trip Title"
                value={
                  updateData &&
                  updateData.name
                    .replace("_", " ")
                    .replace("_", " ")
                    .replace("_", " ")
                }
                readOnly
                style={{ background: "#BABBC0" }}
              />
              {/* <label htmlFor="couponTitle">Trip Title</label> */}
            </div>
            <div className="form-group form-label-group">
              <input
                id="tripValue"
                type="number"
                name="radiusValue"
                onChange={(e) => setChangeValue(e.target.value)}
                className="shadow-none form-control modal-input br-10"
                value={changeValue}
                placeholder="Trip Value"
              />
              <label htmlFor="tripValue">Radius Value</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="padding-header-footer">
          <Button
            variant="primary"
            className="btn-save save-button mb-3 border-radius-15"
            onClick={updateSetting}
          >
            Update
          </Button>

          <Button
            variant="light"
            className="btn-cancel save-button mb-3 border-radius-15"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(RadiusSettingDetailPage);
