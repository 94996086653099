import React from "react";
import { Doughnut } from "react-chartjs-2";

function PromoCard({
  backgroundColor,
  title,
  amount,
  totalCodes,
  data,
  centerText,
  barText,
}) {
  let windowWidth = window.innerWidth;
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `${centerText}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mb-3">
        <div className="chart align-items-center text-center">
          <Doughnut
            type="doughnut"
            data={data}
            plugins={plugins}
            options={chartOptions}
          />
        </div>

        <div className="info-section mb-2">
          <div className="row">
            <div className="container-fluid">
              <div className="main-percent line-main">
                <div className="percentage cc-percent flex-wrap">
                  <div className="d-flex align-items-center text-nowrap">
                    <div
                      className="green"
                      style={{ background: `${backgroundColor}` }}
                    ></div>
                    <div className="percentext flex-grow-1">{title}</div>
                  </div>
                  <div>
                    <div className="percenPoint">
                      SAR. {amount ? amount : 0}
                    </div>
                  </div>
                </div>

                <div className="percentage cc-percent flex-wrap">
                  <div className="d-flex align-items-center text-nowrap">
                    <div
                      className="red"
                      style={{ background: "#F1F1F1" }}
                    ></div>
                    <div className="percentext flex-grow-1 unuse">
                      Total unutilised promo
                    </div>
                  </div>
                  <div>
                    <div className="percenPoint text-nowrap">
                      {totalCodes ? totalCodes : 0} {totalCodes > 1 ? 'Codes' : 'Code'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          <u className="view-btn text-success text-right ">{barText}</u>
        </p>
      </div>
    </>
  );
}

export default PromoCard;
