import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import APIService from "../../services/APIService";
import { Button } from "primereact/button";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import DeleteModal from "../Common/DeleteModal";

let PromoDetail = (props) => {
  const [tab, setTab] = useState("promo-detail"),
    [globalFilter, setGlobalFilter] = useState({
      userId: '',
      createdAt: [],
      updatedAt: []
    }),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [allPromoDetails, setAllPromoDetails] = useState([]),
    [promoDetails, setPromoDetails] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [load, setLoad] = useState(false),
    [selected, setSelected] = useState({}),
    codeName = props.location?.state?.codeName,
    codeId = props.match.params.id,
    dt = useRef(null),
    handleCloseDelete = () => setShowDelete(false),
    getPromoDetail = () => {
      APIService.get(`/admin/promo-code/${codeId}`).then((response) => {
        if (response?.status === 200) {
          setAllPromoDetails(response.data?.data ?? [])
          setPromoDetails(response.data?.data ?? [])
        }
      }).catch((e) => console.log(e));
    },
    removePromo = () => {
      APIService.delete(`/admin/log/promo-code/${selected.id}`).then((response) => {
        if (response?.status === 200) {
          getPromoDetail()
        }
      }).catch((e) => console.log(e));
      setShowDelete(false)
    },
    minusPromo = (promo) => {
      setLoad(true)
      if (promo.useCount > 1) {
        APIService.put(`/admin/revert/promo-code`, {
          promoCode: codeName,
          userId: promo.userId
        }).then((response) => {
          if (response?.status === 200) {
            getPromoDetail()
            setLoad(false)
          }
        }).catch((e) => {
          console.log(e)
          setLoad(false)
        });
      }
      else {
        setLoad(false)
      }
    },
    handleSearch = (e) => {
      let searchValue = e.target.value
      const result = allPromoDetails.filter(item => {
        return Object.values(item).some(val => val !== undefined && val !== null && val.toString().toLowerCase().includes(searchValue.toLowerCase()));
      });
      setPromoDetails(result)
    },
    renderHeader = () => {
      return (
        <>
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />

                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>
            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search"
                onInput={handleSearch}
              />
            </span>
          </div>

        </>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      dt.current.reset();
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          {/* <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              console.log(rowData)
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button> */}
          <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              setSelected(rowData)
              setShowDelete(true)
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#03053D"
              />
            </svg>
          </Button>
        </div>
      );
    },
    filterRows = () => {
      let data = allPromoDetails
      const isEmpty = (val) => {
        return val.toString() === ""
      }
      if (!isEmpty(globalFilter.userId)) {
        data = data.filter(row => row.userId.includes(globalFilter.userId))
      }
      if (globalFilter.createdAt.length > 0) {
        if (globalFilter.createdAt[1] !== null) {
          let date = globalFilter.createdAt
          let startDate = moment(date[0]).format("YYYY-MM-DD")
          let endDate = moment(date[1]).format("YYYY-MM-DD")
          data = data.filter(row => {
            const rowDate = row?.createdAt?.split("T")[0];
            return startDate <= rowDate && rowDate <= endDate;
          })
        }
        else {
          let date = globalFilter.createdAt
          let startDate = moment(date[0]).format("YYYY-MM-DD")
          data = data.filter(row => {
            const rowDate = row?.createdAt?.split("T")[0];
            return startDate === rowDate;
          })
        }
      }
      if (globalFilter.updatedAt.length > 0) {
        if (globalFilter.updatedAt[1] !== null) {
          let date = globalFilter.updatedAt
          let startDate = moment(date[0]).format("YYYY-MM-DD")
          let endDate = moment(date[1]).format("YYYY-MM-DD")
          data = data.filter(row => {
            const rowDate = row?.updatedAt?.split("T")[0];
            return startDate <= rowDate && rowDate <= endDate;
          })
        }
        else {
          let date = globalFilter.updatedAt
          let startDate = moment(date[0]).format("YYYY-MM-DD")
          data = data.filter(row => {
            const rowDate = row?.updatedAt?.split("T")[0];
            return startDate === rowDate;
          })
        }
      }
      setPromoDetails(data)
    }
  useEffect(() => {
    getPromoDetail()
  }, [])
  useEffect(() => {
    const isEmpty = Object.values(globalFilter).every(value => value.toString() === '')
    if (!isEmpty) {
      filterRows()
    }
    else {
      setPromoDetails(allPromoDetails)
    }
  }, [JSON.stringify(globalFilter)])

  return (
    <div className="rider-listing">
      <span className="title">{codeName + " "} Code Detail</span>

      {/* <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
        // onSelect={(tab) => {
        //   setTab(tab);
        //   props.history.push(`/markeeting/${tab}`);
        // }}
        > */}

      {/* {permissions.find(a => a.includes('/markeeting/promotions')) &&
          <Tab eventKey="promotions" title="Promotion Codes">
            {tab === "promotions" && <PromotionalCoupon />}
          </Tab>}  */}
      {/* <Tab eventKey="promo-detail" title="Promotion Codes Detail">
          {tab === "promo-detail" && */}
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={promoDetails}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Detail Found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[10, 25, 50]}
            // first={lazyParams.skip}
            rows={25}
            // sortField={lazyParams.sort.field}
            // sortOrder={lazyParams.sort.order}
            // onPage={manageDriverEarning}
            // onSort={manageDriverEarning}
            // onFilter={manageDriverEarning} 
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "200px" }}
              sortField="userId"
              filterField="userId"
              header="User ID"
              field="userId"
              sortable
              filter={showFilter}
              filterElement={
                <InputText value={globalFilter.userId} onChange={(e) => setGlobalFilter({ ...globalFilter, userId: e.target.value })} placeholder="Search by id" />
              }
              filterMatchMode="contains"
              filterPlaceholder="User Id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Created At"
              field="createdAt"
              sortable
              body={(promo) => moment(promo.createdAt).format("DD/MM/YYYY")}
              filter={showFilter}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select date range"
                    dateFormat="dd/mm/yy"
                    value={globalFilter.createdAt}
                    // hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      if (e.value) {
                        setGlobalFilter({ ...globalFilter, createdAt: e.value })
                        if (e.value[0] && e.value[1]) {

                        }
                      } else {
                        setGlobalFilter({ ...globalFilter, createdAt: [] })
                      }
                    }}
                    selectionMode="range"
                    showButtonBar
                    readOnlyInput
                    showIcon
                    icon={<CalendarRange />}
                  />
                </div>
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Updated At"
              field="updatedAt"
              sortable
              body={(promo) => moment(promo.updatedAt).format("DD/MM/YYYY")}
              filter={showFilter}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select date range"
                    dateFormat="dd/mm/yy"
                    value={globalFilter.updatedAt}
                    // hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      if (e.value) {
                        setGlobalFilter({ ...globalFilter, updatedAt: e.value })
                        if (e.value[0] && e.value[1]) {

                        }
                      } else {
                        setGlobalFilter({ ...globalFilter, updatedAt: [] })
                      }
                    }}
                    selectionMode="range"
                    showButtonBar
                    readOnlyInput
                    showIcon
                    icon={<CalendarRange />}
                  />
                </div>
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "120px" }}
              header="Amount"
              field="amount"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "120px" }}
              header="Use Count"
              field="useCount"
              body={(promo) => <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <p style={{ height: '6px', width: '30px' }}>{promo.useCount}</p>
                <Button style={{
                  padding: 0,
                  height: '20px',
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                  disabled={load}
                  onClick={() => minusPromo(promo)}
                >-</Button>
              </div>}
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              field="action"
              header="Action"
              body={actionTemplate}
            />
          </DataTable>
        </div>
      </div>
      {/* }
        </Tab>

      </Tabs> */}
      <DeleteModal
        showDelete={showDelete}
        clickYes={removePromo}
        handleCloseDelete={handleCloseDelete}
      />
    </div>
  );
};

export default React.memo(PromoDetail);