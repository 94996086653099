import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import { connect } from "react-redux";
import calender from "../../assest/images/calender.svg";
import redCard from "../../assest/images/red-card.svg";
import greenCard from "../../assest/images/green-card.png";
import { logDOM } from "@testing-library/react";
import { TabView, TabPanel } from "primereact/tabview";
// import AverageEarning from "./AverageEarning";

let barChartOptions = {
  responsive: true,
  type: "line",
  barPercentage: 5,
  borderRadius: 5,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};
let PickupEarning = (props) => {
 
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphTripList, setGraphTripList] = useState([]),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;




  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

  useEffect(() => {
    const data = {
        labels: [23, 24, 78, 36, 27, 23, 12],
        datasets: [
          {
            data: [25, 54, 75, 96, 17, 28, 49],
    
            backgroundColor: "rgba(75,192,192,0.2)",
          },
          {
            label: "Second dataset",
            data: [53, 95, 25, 21, 34, 56],
            fill: false,
            borderColor: "#742774",
          },
          {
            label: "Second dataset",
            data: [32, 11, 75, 91, 94, 26],
            fill: false,
            borderColor: "#742774",
          },
        ],
      };

      
     

      setGraphTripList({
        labels: data.labels,
        datasets: data.datasets,
      });
    },[]);

  let manageTimeRange = (event) => {
    // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setTimeRange(event.target.value);
  
  };  
  

  return (
    <>
    <div className="mt-24">
            <Line data={graphTripList} options={barChartOptions} />
          </div>
          <div
            className="bg-light"
            style={{
              borderRadius: "6px",
              padding: "16px",
              // marginTop: "30px",
            }}
          >
            <div className=" d-flex justify-content-between flex-wrap">
             
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#13B542" }}
                    ></div>
                    <div class="trips-title-label mr-4">Avg. Earning</div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    13,000 SAR.
                  </div>
                </div>
             

             
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#F1F1F1" }}
                    ></div>
                    <div class="trips-title-label mr-4">Max Earning </div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    4,328 SAR.
                  </div>
                </div>
             

            
                <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-2">
                  <div className="d-flex align-items-center">
                    <div
                      class="green-box mr-3"
                      style={{ backgroundColor: "#F1F1F1" }}
                    ></div>
                    <div class="trips-title-label mr-4">Min Earning</div>
                  </div>

                  <div
                    class="trips-count text-dark font-weight-bolder"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    3,453 SAR.
                  </div>
                </div>
            
            </div>
          </div>
    </>
  );
};
export default PickupEarning;
