import React, { useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import { connect, useSelector } from "react-redux";
import DeleteModal from "../components/Common/DeleteModal";
import VehicleTypeLising from "../components/VehicleTypeList/VehicleTypeLising";
import APIService from "../services/APIService";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#ffffff"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};

let statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

let VehicleListingPage = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [vehicleTypes, setVehicleTypes] = React.useState([]),
    [previewImageData, setPreviewImageData] = useState(null),
    [showModal, setShowModal] = useState(false),
    [showModalUpdate, setShowModalUpdate] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [selectedStatus, setSelectedStatus] = useState({
      value: true,
      label: "Active",
    }),
    [updateData, setUpdateData] = useState({
      name: "",
      nameArabic: "",
      description: "",
      descriptionArabic: "",
      baseFare: "",
      passengerCostPerKm: "",
      costPerMin: "",
      noOfSeats: "",
      order: "",
      status: true,
    }),
    [vehicleTypePhoto, setVehicleTypePhoto] = useState(null),
    [vehicleTypePhotoName, setVehicleTypePhotoName] = useState(null),
    handleClose = () => {
      setShowModal(false);
      setVehicleTypePhoto(null);
      setVehicleTypePhotoName(null);
      setPreviewImageData(null);
    },
    handleShow = () => {
      setSelectedStatus({ value: true, label: "Active" });
      setUpdateData({
        name: "",
        nameArabic: "",
        description: "",
        descriptionArabic: "",
        baseFare: "",
        passengerCostPerKm: "",
        costPerMin: "",
        noOfSeats: "",
        status: true,
        order: "",
      });
      setShowModal(true);
    },
    handleCloseUpdate = () => {
      setShowModalUpdate(false);
      setVehicleTypePhoto(null);
      setVehicleTypePhotoName(null);
      setPreviewImageData(null);
    },
    handleShowUpdate = () => setShowModalUpdate(true),
    handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setUpdateData(rowData);
      setShowDelete(true);
    },
    [saveNmore, setSaveNmore] = useState(false),
    updateTableData = (rowData) => {
      let vehicleType = { ...rowData };
      vehicleType.status = rowData.status === "Active" ? true : false;
      setUpdateData(vehicleType);
      setSelectedStatus(
        statusOptions.find((sts) => {
          return sts.value === vehicleType.status;
        })
      );
    },
    getVehicleType = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/cab-type/all")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
           
            setVehicleTypes(
              response.data.data
                .map((vType) => {
                  vType.status = vType.status ? "Active" : "Inactive";
                  return vType;
                })
                .sort((vType, vType2) => {
                  return new Date(vType2.createdAt) - new Date(vType.createdAt);
                })
            );
            props.dispatch({
              type: "TOGGLE_GRID_LOADER",
              payload: false,
            });
          }
        })
        .catch((error) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
        });
    },
    removeCabType = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.delete("admin/cab-type/" + updateData.id)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            getVehicleType();
            toast.success("Vehicle Category deleted successfully");
          } else {
            toast.error("Failed to delete Vehicle Category");
          }
          setShowDelete(false);
        })
        .catch((error) => {
          setShowDelete(false);
        });
    },
    createOrUpdateVehicleType = (vehicleTypeInfo, props) => {
      vehicleTypeInfo.status = Number(selectedStatus.value);

      let formData = new FormData();
      Object.keys(vehicleTypeInfo).forEach((key) => {
        formData.append(key, vehicleTypeInfo[key]);
      });
      if (vehicleTypePhoto) {
        formData.append("categoryIcon", vehicleTypePhoto);
      }
      if (!updateData || !updateData.id) {
        APIService.post("admin/cab-type", formData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Category created successfully");
              getVehicleType();
            } else {
              toast.error("Failed to create Vehicle Category");
            }
            props.resetForm();
            setVehicleTypePhotoName(null);
            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            setVehicleTypePhotoName(null);
            props.resetForm();
            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
            console.log(error);
          });
      } else {
        if (!vehicleTypePhoto) {
          formData.delete("categoryIcon");
        }
        APIService.patch("admin/cab-type/" + updateData.id, formData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Category updated successfully");
              getVehicleType();
            } else {
              toast.error("Failed to update Vehicle Category");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            handleCloseUpdate();
            console.log(error);
          });
      }
    },
    validationRules = {
      name: Yup.string().required("Please enter Vehicle Category Name"),
      nameArabic: Yup.string().required(
        "Please enter Vehicle Category Name Arabic"
      ),
      description: Yup.string().required("Please enter Short Description"),
      descriptionArabic: Yup.string().required(
        "Please enter Short Description Arabic"
      ),
      passengerBaseFare: Yup.string()
        .min(0, "Base Fare Charge is greater than 0")
        .test(
          "Is positive?",
          "Base Fare must be greater than or equal to 0",
          (value) => value >= 0
        )
        .matches(
          /^[0-9]*(\.[0-9]{0,2})?$/,
          "Base Fare should be upto 2 decimal points"
        ),
      passengerCostPerKm: Yup.string()
        .required("Please enter Per KM Charge")
        .min(0, "Per KM Charge is greater than 0")
        .test(
          "Is positive?",
          "Per KM Charge must be greater than or equal to 0",
          (value) => value >= 0
        )
        .matches(
          /^[0-9]*(\.[0-9]{0,2})?$/,
          "Per KM Charge should be upto 2 decimal points"
        ),
      passengerCostPerMin: Yup.string()
        .min(0, "Per Min Charge is greater than 0")
        .test(
          "Is positive?",
          "Per Min Charge must be greater than or equal to 0",
          (value) => value >= 0
        )
        .matches(
          /^[0-9]*(\.[0-9]{0,2})?$/,
          "Per Min Charge should be upto 2 decimal points"
        ),
      waitChargePerMin: Yup.string()
        .min(0, "Per Min Charge is greater than 0")
        .test(
          "Is positive?",
          "Per Min Charge must be greater than or equal to 0",
          (value) => value >= 0
        )
        .matches(
          /^[0-9]*(\.[0-9]{0,2})?$/,
          "Per Min Charge should be upto 2 decimal points"
        ),

      noOfSeats: Yup.number()
        .integer()
        .required("Please enter Total Seat")
        .max(9, "Max is 9")
        .min(0, "Total Seat greater than 0"),
      status: Yup.string().required("Select Status"),
    },
    validationSchema = Yup.object().shape(validationRules);

  React.useEffect(() => getVehicleType(), []);

  return (
    <>
      {/* <div className="rider-listing"> */}
        {/* <span className="title pl-2">Vehicle Categories</span> */}
        {/* <button
          className="btn btn-primary float-right mb-1 ml-12"
          onClick={handleShow}
        >
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </button> */}
        <VehicleTypeLising
          vehicleTypes={vehicleTypes}
          handleShowUpdate={handleShowUpdate}
          updateTableData={updateTableData}
          handleShowDelete={handleShowDelete}
          isLoading={useSelector((state) => state.isGridLoader)}
          handleShow={handleShow}
        />
      {/* </div> */}

      <Modal
        show={showModal}
        onHide={handleClose}
        className="modalWidth"
        centered
        backdrop="static" keyboard={false}
        size="lg"
      >
        <Formik
          initialValues={updateData}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateVehicleType}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                 <Modal.Header>
                    <Modal.Title className="modal-title">
                    Add Vehicle Categories
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header> 
                <Modal.Body className="mt-24">
                  <div className="form-group mb-2">
                    <div className="form-file-upload form-label-group mb-2">
                      <div className="form-file-name">
                        {vehicleTypePhotoName}
                      </div>
                      <div className="form-file-icon">
                        <span style={{ color: "#13B542", textDecoration: "underline", cursor: "pointer", fontWeight: "500" }}>Upload</span>  
                   
                      </div>
                      <input
                        id="fileupload"
                        className="form-control modal-input br-10"
                        placeholder="name"
                        type="file"
                        accept="image/*"
                        name="fileupload"
                        onChange={(e) => {
                          if (e.target.files) {
                            setVehicleTypePhoto(e.target.files[0]);
                            setVehicleTypePhotoName(e.target.files[0]?.name);
                          }
                        }}
                      />
                      <label for="fileupload">Vehicle Category Photo</label>
                      <ErrorMessage
                        name="fileupload"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </div>
                  <div className="info__text">
                    <p className="mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="#13b542"
                        className="bi bi-info-circle mr-2 mb-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 11-2 0 1 1 0 012 0z"></path>
                      </svg>
                      Image dimension should be 390 &#10006; 216.
                    </p>
                  </div>

                  <h6 className="modal-sub-title mt-3 mb-4 pl-0">
                    Vehicle Category Details
                  </h6>
                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Vehicle Category Name"
                          maxLength="20"
                        />
                        <label htmlFor="name">Vehicle Category Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="nameArabic"
                          type="text"
                          name="nameArabic"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Vehicle Category Name (Arabic)"
                          maxLength="20"
                        />
                        <label htmlFor="nameArabic">
                          Vehicle Category Name (Arabic)
                        </label>
                        <ErrorMessage
                          name="nameArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          type="textarea"
                          id="description"
                          name="description"
                          rows="10"
                          className="shadow-none form-control br-10 "
                          placeholder="Short Description"
                          maxLength="100"
                        />
                        <label htmlFor="description">Short Description</label>
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          type="textarea"
                          id="descriptionArabic"
                          name="descriptionArabic"
                          rows="10"
                          className="shadow-none form-control br-10 "
                          placeholder="Short Description (Arabic)"
                          maxLength="100"
                        />
                        <label htmlFor="descriptionArabic">
                          Short Description (Arabic)
                        </label>
                        <ErrorMessage
                          name="descriptionArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerBaseFare"
                          type="number"
                          name="passengerBaseFare"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Base Fare"
                        />
                        <label htmlFor="passengerBaseFare">Base Fare</label>
                        <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px',
                            width: '45px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          SAR. 
                        </div>
                        <ErrorMessage
                          name="passengerBaseFare"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="noOfSeats"
                          type="number"
                          name="noOfSeats"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Total Seats"
                          min="0"
                          max="9"
                        />
                        <label htmlFor="noOfSeats">Total Seats</label>
                        <ErrorMessage
                          name="noOfSeats"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerCostPerKm"
                          type="number"
                          name="passengerCostPerKm"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Km Charges"
                        />
                        <label htmlFor="passengerCostPerKm">
                          Per Km Charges
                        </label>
                        <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px',
                            width: '45px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          SAR. 
                        </div>
                        <ErrorMessage
                          name="passengerCostPerKm"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4">
                        <Select
                          className="height-50"
                          placeholder="Select Status"
                          // isClearable
                          name="status"
                          value={selectedStatus}
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({
                              target: {
                                name: "status",
                              },
                            });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("status", e.value);
                              setSelectedStatus(e);
                            }
                          }}
                          options={statusOptions}
                        />
                        
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerCostPerMin"
                          type="number"
                          name="passengerCostPerMin"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Min Charges"
                        />
                        <label htmlFor="passengerCostPerMin">
                          Per Min Charges
                        </label>
                        <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px',
                            width: '45px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          SAR. 
                        </div>
                        <ErrorMessage
                          name="passengerCostPerMin"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                     
                    </Col>
                    <Col md={6}>
                    <div className="form-group form-label-group">
                        <Field
                          id="waitChargePerMin"
                          type="number"
                          name="waitChargePerMin"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Min Charges"
                        />
                        <label htmlFor="waitChargePerMin">
                          Waiting Charge Per Min
                        </label>
                        <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px',
                            width: '45px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          SAR. 
                        </div>
                        <ErrorMessage
                          name="waitChargePerMin"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6} className="mt-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="btn-save save-button "
                        onClick={() => setSaveNmore(true)}
                      >
                        Save & Add more
                      </Button> 
                      </Col>
                    <Col md={6} className="mt-2">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-save save-button "
                        onClick={() => setSaveNmore(false)}
                      >
                        Add
                      </Button> 
                    </Col>
                 
                  </Row>
                
              
                </Modal.Body>
                {/* <Modal.Footer className="padding-header-footer">
                  
                </Modal.Footer> */}
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        show={showModalUpdate}
        className="modalWidth"
        onHide={handleCloseUpdate}
        centered
        backdrop="static" keyboard={false}
        size="lg"
      >
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={Yup.object().shape({
            ...validationRules,
            order: Yup.number().required("Please enter Order"),
          })}
          onSubmit={createOrUpdateVehicleType}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
               
                <Modal.Header>
                    <Modal.Title className="modal-title">
                    Update Vehicle Categories
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true" onClick={handleCloseUpdate}>&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                <Modal.Body className="mt-24">
                  <div className="preview__img__main d-flex justify-content-center pb-4">
                    <img
                      src={
                        previewImageData
                          ? previewImageData
                          : updateData.categoryIconUrl
                          ? updateData.categoryIconUrl
                          : "showcase/demo/images/flag_placeholder.png"
                      }
                      onError={(e) =>
                        (e.target.src = previewImageData
                          ? previewImageData
                          : updateData.categoryIconUrl
                          ? updateData.categoryIconUrl
                          : process.env.PUBLIC_URL + `/images/car.png`)
                      }
                      alt={updateData.name}
                      className=""
                      style={{
                        width: "auto",
                        height: "100px",
                        // borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <div className="form-file-upload form-label-group mb-2">
                      <div className="form-file-name">
                        {vehicleTypePhotoName}
                      </div>
                      <div className="form-file-icon">
                        <span style={{ color: "#13B542", textDecoration: "underline", cursor: "pointer", fontWeight: "500" }}>Upload</span>  
                   
                      </div>
                      <input
                        id="fileupload"
                        className="form-control modal-input br-10"
                        placeholder="name"
                        type="file"
                        accept="image/*"
                        name="fileupload"
                        onChange={(e) => {
                          if (e.target.files) {
                            var reader = new FileReader();
                            reader.onloadend = function (e) {
                              setPreviewImageData(e.target.result);
                            };
                            reader.readAsDataURL(e.target.files[0]);
                            setVehicleTypePhoto(e.target.files[0]);
                            setVehicleTypePhotoName(e.target.files[0]?.name);
                          }
                        }}
                      />
                      <label for="fileupload">Vehicle Category Photo</label>
                      <ErrorMessage
                        name="fileupload"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </div>

                  <div className=" info__text">
                    <p className=" mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="#13b542"
                        className="bi bi-info-circle mr-2 mb-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 11-2 0 1 1 0 012 0z"></path>
                      </svg>
                      Image dimension should be 390 &#10006; 216.
                    </p>
                  </div>

                  <h6 className="modal-sub-title mt-3 mb-4 pl-0">
                    Vehicle Category Details
                  </h6>

                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Vehicle Category Name"
                        />
                        <label htmlFor="name">Vehicle Category Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="nameArabic"
                          type="text"
                          name="nameArabic"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Vehicle Category Name"
                        />
                        <label htmlFor="nameArabic">
                          Vehicle Category Name (Arabic)
                        </label>
                        <ErrorMessage
                          name="nameArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          type="textarea"
                          id="description"
                          name="description"
                          rows="10"
                          className="shadow-none form-control br-10 "
                          placeholder="Short Description"
                        />
                        <label htmlFor="description">Short Description</label>
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          type="textarea"
                          id="descriptionArabic"
                          name="descriptionArabic"
                          rows="10"
                          className="shadow-none form-control br-10 "
                          placeholder="Short Description Arabic"
                        />
                        <label htmlFor="descriptionArabic">
                          Short Description (Arabic)
                        </label>
                        <ErrorMessage
                          name="descriptionArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerBaseFare"
                          type="number"
                          name="passengerBaseFare"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Base Fare"
                        />
                        <label htmlFor="passengerBaseFare">Base Fare</label>
                        <span
                          style={{
                            fontSize: "15px",
                            position: "absolute",
                            right: "15px",
                            top: "33%",
                            fontWeight: "bold",
                            color: "#969BAB",
                          }}
                        >
                          {" "}
                          SAR{" "}
                        </span>
                        <ErrorMessage
                          name="passengerBaseFare"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="noOfSeats"
                          type="number"
                          name="noOfSeats"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Total Seats"
                        />
                        <label htmlFor="noOfSeats">Total Seats</label>
                        <ErrorMessage
                          name="noOfSeats"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerCostPerKm"
                          type="number"
                          name="passengerCostPerKm"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Km Charges"
                        />
                        <label htmlFor="passengerCostPerKm">
                          Per Km Charges
                        </label>
                        <span
                          style={{
                            fontSize: "15px",
                            position: "absolute",
                            right: "15px",
                            top: "33%",
                            fontWeight: "bold",
                            color: "#969BAB",
                          }}
                        >
                          {" "}
                          SAR{" "}
                        </span>
                        <ErrorMessage
                          name="passengerCostPerKm"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                      <div className="form-group form-label-group">
                        <Field
                          id="passengerCostPerMin"
                          type="number"
                          name="passengerCostPerMin"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Min Charges"
                        />
                        <label htmlFor="passengerCostPerMin">
                          Per Min Charges
                        </label>
                        <span
                          style={{
                            fontSize: "15px",
                            position: "absolute",
                            right: "15px",
                            top: "33%",
                            fontWeight: "bold",
                            color: "#969BAB",
                          }}
                        >
                          {" "}
                          SAR{" "}
                        </span>
                        <ErrorMessage
                          name="passengerCostPerMin"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="order"
                          type="number"
                          name="order"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Order"
                        />
                        <label htmlFor="order">Order</label>
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                      <div className="mb-4">
                        <Select
                          className="height-50"
                          placeholder="Select Status"
                          // isClearable
                          value={selectedStatus}
                          name="status"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({
                              target: {
                                name: "status",
                              },
                            });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("status", e.value);
                              setSelectedStatus(e);
                            }
                          }}
                          options={statusOptions}
                        />
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="waitChargePerMin"
                          type="number"
                          name="waitChargePerMin"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Per Min Charges"
                        />
                        <label htmlFor="waitChargePerMin">
                          Waiting Charge Per Min
                        </label>
                        <span
                          style={{
                            fontSize: "15px",
                            position: "absolute",
                            right: "15px",
                            top: "33%",
                            fontWeight: "bold",
                            color: "#969BAB",
                          }}
                        >
                          {" "}
                          SAR{" "}
                        </span>
                        <ErrorMessage
                          name="waitChargePerMin"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                
                  </Row>
  
                  <Row>
                  <Col md={6}>
                    
                    <Button
                      variant="light"
                      className="btn-cancel save-button"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-save save-button"
                    >
                      Update
                    </Button>
                   
                  </Col>
                  </Row>
                </Modal.Body>
                {/* <Row>
                  <Modal.Footer className="padding-header-footer">
                    
                  </Modal.Footer>
                </Row> */}
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <DeleteModal
        clickYes={removeCabType}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
};
export default React.memo(connect()(VehicleListingPage));
