import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { CalendarRange } from "react-bootstrap-icons";
import Rating from "react-rating";
import APIService from "../../services/APIService";
import saveAs from "save-as";
import moment from "moment";

let ReportsDriversListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [drivers, setDrivers] = useState(null),
    [createdAt, setCreatedAt] = useState([]),
    [driverMode, setDriverMode] = useState(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [selectedDrivers, setSelectedDrivers] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    carLicenceTypes = ["Private", "Public Transport", "Taxi"],
    driverModeOptions = [
      { label: "On", value: true },
      { label: "Off", value: false },
    ],
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "driverName",
        order: 1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/reports/captains", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setDrivers(response.data.data.captains);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    exportRiders = () => {
      let drivers = [
        "Driver Id",
        "Driver Full Name",
        "Car Plate Number",
        "Car Sequence Number",
        "Car Category Type",
        "Driver Type",
        "Overall Rating",
        "Driver Mode",
        "Total Trips",
        "Status",
      ];
      drivers = drivers.join(",") + "\r\n";
      if (selectedDrivers.length) {
        selectedDrivers.forEach((driver) => {
          drivers +=
            [
              "'" + driver.driverNationalId,
              driver.driverName,
              driver.carPlateNo,
              "'" + driver.carSequenceNo,
              driver.cab.name,
              carLicenceTypes[driver.carLicenceType - 1],
              driver.overallRating,
              driver.drivingModes[0].drivingMode === 1 ? "On" : "Off",
              driver.totalTrips,
              driver.approved ? "Active" : "Inactive",
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([drivers], {
            type: "text/csv;charset=utf-8",
          }),
          "Driver-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 100000000000;

        APIService.post("admin/reports/captains", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });

              response.data.data.captains.forEach((driver) => {
                drivers +=
                  [
                    "'" + driver.driverNationalId,
                    driver.driverName,
                    driver.carPlateNo,
                    "'" + driver.carSequenceNo,
                    driver.cab.name,
                    carLicenceTypes[driver.carLicenceType - 1],
                    driver.overallRating,
                    driver.drivingModes[0].drivingMode === 1 ? "On" : "Off",
                    driver.totalTrips,
                    driver.approved ? "Active" : "Inactive",
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([drivers], {
                  type: "text/csv;charset=utf-8",
                }),
                "Driver-Reports.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            console.log("error", error);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

  useEffect(() => {
    getDrivers();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={createdAt}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters["createdAt"] = [
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).format("YYYY-MM-DD"),
                      ];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getDrivers();
                      }, 500);
                    }
                  } else {
                    setCreatedAt([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getDrivers();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1 d-flex">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportRiders}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
      });
      getDrivers();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={drivers}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedDrivers(e.value)}
          selection={selectedDrivers}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={Number(lazyParams.take)}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Photo"
            body={(driver) => {
              return (
                <React.Fragment>
                  <img
                    className="p-column-listing-photo"
                    src={
                      driver.profileImage
                        ? driver.profileImage
                        : process.env.PUBLIC_URL + `/images/user.png`
                    }
                    width="50"
                    height="50"
                    alt="driver"
                  />
                </React.Fragment>
              );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Driver Id"
            field="driverNationalId"
            filter={showFilter}
            body={(rider) => (
              <Link to={"/driver-details/details/" + rider.id}>
                {rider.driverNationalId}
              </Link>
            )}
            sortable
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="driverName"
            header="Driver Full Name"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Name"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "190px" }}
            header="Car Plate Number"
            field="carPlateNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Car number"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Car Sequence Number"
            field="carSequenceNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Car sequence number"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Car Category Type"
            filterField="cabName"
            sortField="cabName"
            field="cab.name"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Car type"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Type"
            field="carLicenceType"
            body={(driver) => carLicenceTypes[driver.carLicenceType - 1]}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by type"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="overallRating"
            header="Overall Rating"
            body={(rider) => (
              <Rating
                className="ratings pl-0 d-flex justify-content-center"
                fractions={2}
                emptySymbol="fa fa-star-o fa-2x star"
                fullSymbol="fa fa-star fa-2x star"
                initialRating={rider.overallRating}
                readonly
              />
            )}
            sortable
            filter={false}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver Mode"
            field="driverModeSwitch"
            body={(driver) => (driver.driverModeSwitch ? "On" : "Off")}
            sortable
            filter={showFilter}
            filterPlaceholder="Search Mode"
            filterElement={
              <Dropdown
                value={driverMode}
                options={driverModeOptions}
                onChange={(event) => {
                  if (event.value) {
                    lazyParams.filters["driverModeOptionswitch"] = true;
                  } else if (event.value === false) {
                    lazyParams.filters["driverModeOptionswitch"] = false;
                  } else {
                    delete lazyParams.filters["driverModeOptionswitch"];
                  }
                  setDriverMode(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDrivers();
                  }, 500);
                }}
                placeholder="Select Mode"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Trips"
            field="totalTrips"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Subtotal"
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="approved"
            header="Status"
            body={(driver) => (driver.approved ? "Active" : "Inactive")}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  if (event.value || event.value === false) {
                    lazyParams.filters["approved"] = event.value;
                  } else {
                    delete lazyParams.filters["approved"];
                  }
                  setSelectedStatus(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDrivers();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsDriversListing));
