import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import APIService from "../../services/APIService";
import saveAs from "save-as";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import DeleteModal from "../Common/DeleteModal";

let EmergencyTeamsListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [emergencyRequests, setEmergencyRequests] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [createdAt, setCreatedAt] = useState([]),
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [selectedRows, setSelectedRows] = React.useState([]),
    [showDelete, setShowDelete] = useState(false),
    handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setShowDelete(true);
    },
    dt = useRef(null),
    statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "",
        order: "",
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getEmergencyRequests = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/emergency-admin")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.results.length;
            setLazyParams(lazyParams);
            setEmergencyRequests(response.data.data.results);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    removeDispatcher = () => {
      setShowDelete(false);
    },
    exportEmergencyTeams = () => {
      let teams = [
        "Name",
        "Email",
        "Trips Created",
        "Request Resolved",
        "Status",
      ];
      teams = teams.join(",") + "\r\n";
      if (emergencyRequests) {
        emergencyRequests.forEach((team) => {
          teams +=
            [
              team.fullName,
              team.email,
              "'" + team.emergencyTrips,
              "'" + team.requestsResolved,
              team.status ? "Active" : "Inactive",
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([teams], {
            type: "text/csv;charset=utf-8",
          }),
          "Emergency-Request.csv"
        );
      } else {
        toast.error("Something went wrong");
      }
    };

  useEffect(() => {
    getEmergencyRequests();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <span className="d-flex">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={exportEmergencyTeams}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getEmergencyRequests();
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          <Button
            tooltip="Edit (WIP)"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              // handleShowUpdate();
              // updateTableData(rowData);
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button>
          <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              handleShowDelete();
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#03053D"
              />
            </svg>
          </Button>
        </div>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={emergencyRequests}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          globalFilter={globalFilter}
          paginator
          rows={25}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
          emptyMessage="No Team(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          scrollable
          scrollHeight="470px"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          selection={selectedRows}
          selectionMode="checkbox"
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Name"
            field="fullName"
            filter={showFilter}
            body={(rowData) => {
              return (
                <React.Fragment>
                  <Link
                    to={`/emergency-team-detail/details/${rowData.id}`}
                    className="dataFnt-size"
                  >
                    <span className="dataFnt-size">{rowData.fullName}</span>
                  </Link>
                </React.Fragment>
              );
            }}
            sortable
            filterPlaceholder="Search by name"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="email"
            header="Email"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by email"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="emergencyTrips"
            header="Trips Created"
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="requestsResolved"
            header="Request Resolved"
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="status"
            header="Status"
            body={(request) => (request.status ? "Active" : "Inactive")}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  if (event.value || event.value === false) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setSelectedStatus(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getEmergencyRequests();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          />
        </DataTable>
      </div>

      <DeleteModal
        showDelete={showDelete}
        clickYes={removeDispatcher}
        handleCloseDelete={handleCloseDelete}
      />
    </div>
  );
};

export default React.memo(connect()(EmergencyTeamsListing));
