import React from "react";
import ReportsDriversListing from "../components/Reports/ReportsDriversListing";

let ReportsDriverPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Drivers Report</span>
        <ReportsDriversListing />
      </div>
    </>
  );
};

export default React.memo(ReportsDriverPage);
