import { Col, Row } from "react-bootstrap";

const TotalOnlineRiders = ({ totalOnlineRiders, onTripRiders, waitingForRide, offlineRiders }) => {
  return (
    <Row>
      <Col md={12}>
        <h2 className="box-title">
          Total Online Riders :
          <span className="text-success font-weight-bold">{totalOnlineRiders}</span>
        </h2>
      </Col>
      <Col md={12}>
        <div className="inner-box mt-3">
          <div className="row">
            <div className="col-lg-4 ">
              <div className="flex-sb">
                <div className="trips-title-label">On trip riders</div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {onTripRiders}
                </div>
              </div>
            </div>
            <div className="col-lg-4 border-custom ">
              <div className="flex-sb">
                <div className="trips-title-label">Waiting for ride</div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {waitingForRide}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="flex-sb">
                <div className="trips-title-label">Offline riders</div>
                <div className="trips-count" style={{ color: "#13B542" }}>
                  {offlineRiders}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default TotalOnlineRiders