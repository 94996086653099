// import React, {useState} from 'react'
// import {Button, Modal} from 'react-bootstrap'
// import "../components/modal/Modal.css"
// import { compose, withProps } from "recompose";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";
// // const MyMapComponent = compose(

// //   // const [location, setLocation] = useState({ lat: -34.397, lng: 150.644 });

  
// // withProps({
// //   googleMapURL:
// //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg&libraries=geometry,drawing,places",
// //   loadingElement: <div style={{ height: `100%` }} />,
// //   containerElement: <div style={{ height: `400px` }} />,
// //   mapElement: <div style={{ height: `100%` }} />
// // }),
// // withScriptjs,
// // withGoogleMap
// // )(props => (
// // <GoogleMap
// //   onClick={ev => {
// //     console.log("latitide = ", ev.latLng.lat());
// //     console.log("longitude = ", ev.latLng.lng());
// //   //   setLocation({lat:ev.latLng.lat(),lng: ev.latLng.lng()})
// //   }}
// //   defaultZoom={10}
// //   defaultCenter={{ lat: 23.885942, lng: 45.079163 }}
// // >
// //   {props.isMarkerShown && (
// //     <Marker
// //       position={{ lat: 23.885942, lng: 45.079163 }}
// //       onClick={props.onMarkerClick}
// //     />
// //   )}
// // </GoogleMap>
// // ));

// // class MyFancyComponent extends React.PureComponent {
// //   state = {
// //     isMarkerShown: false
// //   };

// //   componentDidMount() {
// //     this.delayedShowMarker();
// //   }

// //   delayedShowMarker = () => {
// //     setTimeout(() => {
// //       this.setState({ isMarkerShown: true });
// //     }, 3000);
// //   };

// //   handleMarkerClick = () => {
// //     this.setState({ isMarkerShown: false });
// //     this.delayedShowMarker();
// //   };

// //   render() {
// //     return (
// //       <MyMapComponent
// //         isMarkerShown={this.state.isMarkerShown}
// //         onMarkerClick={this.handleMarkerClick}
// //       />
// //     );
// //   }
// // }

// // function MyVerticallyCenteredModal(props) {
// //   return (
// //     <Modal
// //       {...props}
// //       size="lg"
// //       aria-labelledby="contained-modal-title-vcenter"
// //       centered
// //     >
// //       <Modal.Header closeButton>
// //         <Modal.Title id="contained-modal-title-vcenter">
// //           <h4>Create New High Zone</h4>
// //         </Modal.Title>
// //       </Modal.Header>
// //       <Modal.Body>
// //         <p>
// //           <MyMapComponent/>
// //         </p>
// //       </Modal.Body>
// //       <Modal.Footer>
// //         <Button onClick={props.onHide}>Close</Button>
// //       </Modal.Footer>
// //     </Modal>
// //   );
// // }
// const HighDemandZone = (props) => {
//     const [modalOpen, setModalOpen] = useState(false);
//     const [modalShow, setModalShow] = useState(false);

    
//   return (
//     <div>
// {/* <MyFancyComponent />
// <Button id="popover-btn" variant="primary" onClick={() => setModalShow(true)}>
//         + Add
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//        */}
//   </div>
//   )
// }

// export default HighDemandZone




// import React from "react";
// import { compose, withProps } from "recompose";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";
// import { Button} from "react-bootstrap";
// import '../components/modal/Modal.css'
// import APIService from "../services/APIService";


// const MyMapComponent = compose(

//     // const [location, setLocation] = useState({ lat: 23.885942, lng: 45.079163 });

    
//   withProps({
//     googleMapURL:
//     "https://maps.googleapis.com/maps/api/js?key=AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//     mapElement: <div style={{ height: `100%` }} />
//   }),
//   withScriptjs,
//   withGoogleMap
// )(props => (
//   <GoogleMap
//     onClick={ev => {
//       console.log("runme");
//       console.log("run-lat", ev.latLng.lat());
//       console.log("run-long", ev.latLng.lng());
//     //   setLocation({lat:ev.latLng.lat(),lng: ev.latLng.lng()})
//     }}
//     defaultZoom={10}
//     defaultCenter={{ lat: 23.885942, lng: 45.079163 }}
//   >
//     {props.isMarkerShown && (
//       <Marker
//         position={{ lat: 23.885942, lng: 45.079163 }}
//         onClick={props.onMarkerClick}
//       />
//     )}
//   </GoogleMap>
// ));

// class HighDemandZone extends React.PureComponent {
//   state = {
//     isMarkerShown: false
//   };

//   componentDidMount() {
//     this.delayedShowMarker();
//   }

//   delayedShowMarker = () => {
//     setTimeout(() => {
//       this.setState({ isMarkerShown: true });
//     }, 3000);
//   };

//   handleMarkerClick = () => {
//     this.setState({ isMarkerShown: false });
//     this.delayedShowMarker();
//   };


//   render() {
//     const createZone = () =>{
//       //  APIService.post("admin/high-demand-zone")
//       alert("Create Zone Successfully")
//     }
//     return (
//       <>
//       <MyMapComponent
//         isMarkerShown={this.state.isMarkerShown}
//         onMarkerClick={this.handleMarkerClick}
//       />
//      <Button id="create-zone-btn" onClick={()=>createZone()}>Create Zone</Button>
//      </>
//     );
//   }
// }
// export default HighDemandZone;

// import React, { Component } from 'react';
// import Map from '../components/modal/Map.js';
// import { Table } from 'react-bootstrap';
// import APIService from '../services/APIService';


// class HighDemandZone extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {posts:[]};
// 	  }
// 	componentDidMount() {
// 		APIService.post('/admin/high-demand-zone').then(resp=>console.log(resp))
// 		// fetch('API').then(resp=>resp.json())
// 		.then(resp=>this.setState({posts:resp})).catch((error)=>{
// 			console.log(error.message)
// 		})
// 	  }
// 	render() {
// 		return(
// 			// <div style={{ margin: '100px' }}>

// 			<div>
// 				<Map
// 					google={this.props.google}
// 					center={{lat: 23.885942, lng: 45.079163}}
// 					height='400px'
// 					zoom={15}
// 				/>

// <div className='hdz-table'>
	
// 				<Table striped bordered hover id="hzt">
//       <thead>
//         <tr>
//           <th>Address</th>
//           <th>Latitude</th>
//           <th>Longitude</th>
// 		  <th>Action</th>
//         </tr>
//       </thead>
//       <tbody>
// 		{/* {
// this.state.posts.map((post)=>{
// 	return(
// 		<tr key={post.id}>
//           <td>{post.address}</td>
//           <td>{post.latitude}</td>
//           <td>{post.longitute}</td>
// 		  <button style={{width:"100%"}}>Delete</button>
//         </tr>
// 	)
// })
// 		} */}
        
       
//       </tbody>
//     </Table>
// 				</div>

// 				{/* <div>
// 				{this.state.posts.map(post=><div key={post.id}>{post.address}.{post.latitude}</div>)}
// 				</div> */}

// 			</div>
			
			
// 		);
// 	}
// }

// export default HighDemandZone;

import React, { useState, useEffect } from 'react';
import Map from '../components/modal/Map.js';
import { Table } from 'react-bootstrap';
import APIService from '../services/APIService';
import { toast } from "react-toastify";

const HighDemandZone = (props) => {
	const [data, setData] = useState(null);
  
    const Removefunction = (id) => {
        if (window.confirm('Do you want to delete ?')) {
            APIService.delete("/admin/high-demand-zone/" + id).then((res) => {
                toast.success("Delete data successfully");
                window.location.reload()
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }
  

    useEffect(() => {
        APIService.post("/admin/high-demand-zone").then((res) => {
            setData(res.data.data);
            // console.log(res.data.data)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

  return (
	<div>
			<div>
				<Map
					google={props.google}
					center={{lat: 23.885942, lng: 45.079163}}
					height='400px'
					zoom={15}
				/>
			</div>
			<div className='hdz-table'>
	
 				<Table striped bordered hover id="hzt">
       <thead>
         <tr>
           <th>Address</th>
           <th>Latitude</th>
          <th>Longitude</th>
 		  <th>Action</th>
         </tr>
       </thead>
      <tbody>
 		 {
data &&
data.zones.map((item, index)=>{
	return(
		<tr key={index}>
          <td>{item.address}</td>
          <td>{item.latitude}</td>
          <td>{item.longitude}</td>
		  <button style={{width:"100%"}} className={'text-danger'} onClick={() => { Removefunction(item.id) }}>Delete</button>
        </tr>
	)
})
		} 
        
       </tbody>
	</Table>
</div>
			</div>
			
		);
}

export default HighDemandZone;


