import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, Card } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { StylesConfig } from 'react-select';
import { Link, useParams } from "react-router-dom";
import APIService from "../services/APIService";
import { toast } from "react-toastify";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#212529"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled
        ? 'white'
        : isSelected
          ? "#28a745"
          : "#28a745",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },

};
let RolePage = (props) => {
  let params = useParams(),
    statuses = [
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ],
    [role, setRole] = useState({
      title: "",
      status: "",
      capabilites: [],
    }),
    [status, setStatus] = useState(),
    [saveNmore, setSaveNMore] = useState(false),
    [capabilities, setCapabilities] = useState([]),
    [rawPermissions, setRawPermissions] = useState([]),
    [moduleTypes, setModuleTypes] = useState([]),
    validationSchema = Yup.object().shape({
      title: Yup.string().required("Please enter your title"),
      status: Yup.string().required("Select Status"),
    }),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/role/')),
    getCapibilities = () => {
      APIService.get("admin/capabilities/all").then((response) => {
        if (response.status === 200) {
          let capabilities = [],
            moduleType = {},
            permissions = [],
            arrIndex = 0,
            count = Math.ceil(response.data.data.length / 4);
          for (let i = 0; i < response.data.data.length; i++) {
            capabilities[arrIndex] = !capabilities[arrIndex]
              ? []
              : capabilities[arrIndex];
            capabilities[arrIndex].push(response.data.data[i]);
            if ((i + 1) % count === 0) {
              ++arrIndex;
            }
            if (response.data.data[i].permissions.length) {
              permissions = [
                ...permissions,
                ...response.data.data[i].permissions.map((k) => k.accessCode),
              ];
            }

            moduleType[response.data.data[i].moduleType] = { capabilities: [] };
          }
          for (let i = 0; i < response.data.data.length; i++) {
            moduleType[response.data.data[i].moduleType].capabilities.push(
              response.data.data[i]
            );
          }

          setModuleTypes(moduleType);
          setCapabilities(capabilities);
          setRawPermissions(permissions);
        }
      });
    },
    manageAllSelection = (selection) => {
      if (selection) {
        role.capabilites = rawPermissions;
      } else {
        role.capabilites = [];
      }
      setRole(role);
    },
    selectPermission = (permission) => {
      let index = role.capabilites.indexOf(permission.accessCode);
      if (index === -1) {
        role.capabilites.push(permission.accessCode);
      } else {
        role.capabilites.splice(index, 1);
      }
      setRole(role);
      setCapabilities([...capabilities]);
    },
    selectModule = (event, category) => {
      let categoryPermissions = category.permissions.map(
        (cap) => cap.accessCode
      );
      if (event.target.checked) {
        role.capabilites = [...role.capabilites, ...categoryPermissions];
        role.capabilites = [...new Set(role.capabilites)];
      } else {
        role.capabilites = role.capabilites.filter((cap) => {
          return categoryPermissions.indexOf(cap) === -1;
        });
      }
      setRole(role);
      setCapabilities([...capabilities]);
    },
    createOrUpdateRole = (formField) => {
      role.title = formField.title;
      role.status = formField.status;
      if (params.id) {
        APIService.patch("admin/role/" + params.id, role)
          .then((response) => {
            if (response.status === 200) {
              props.history.push("../roles");
              toast.success("Role updated successfully");
            }
          })
          .catch((error) => toast.error("Failed to update Role"));
      } else {
        APIService.post("admin/role", role)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Role created successfully");
              if (!saveNmore) {
                props.history.push("../roles");
              } else {
                window.location.reload(false);
              }
            }
          })
          .catch((error) => toast.error("Failed to create Role"));
      }
    },
    isModuleChecked = (category) => {
      let categoryPermissions = category.permissions.map(
        (cap) => cap.accessCode
      ),
        capabilites = role.capabilites.filter((cap) => {
          return categoryPermissions.indexOf(cap) > -1;
        });
      return capabilites.length === categoryPermissions.length;
    };

  useEffect(() => {
    if (params.id) {
      APIService.get("admin/role/" + params.id).then((response) => {
        if (response.status === 200) {
          let role = response.data.data;
          role.isLoad = true;
          setRole(role);
          setStatus(
            statuses.find((sts) => sts.value === response.data.data.status)
          );
        }
      });
    } else {
      role.isLoad = true;
      setRole(role);
    }
    getCapibilities();
  }, []);

  return (
    <>
      <div className="rider-listing">
        {role.isLoad && (
          <Formik
            initialValues={{ title: role.title, status: role.status }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateRole}
          >
            {({ setFieldValue, handleBlur, errors }) => {
              return (
                <Form>
                  {/* <div className="mb-2">
                    <Link to="/roles" title="Back">
                      <i className="fa fa-chevron-left back-arrow"></i>
                    </Link>
                    <span className="title">Roles</span>
                  </div> */}
                  <Row>
                    <Col
                      sm={12}
                      md={6}
                      className="mb-3 d-flex align-items-center justify-content-start"
                    >
                      <Field
                        type="text"
                        className="role__input form-control mr-2"
                        placeholder="Role Name"
                        name="title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      className="mb-3 d-flex align-items-center justify-content-start justify-content-md-end role"
                    >
                      <h5 className="mb-0 pr-2">Status:</h5>
                      <Select
                        className="height-30 text-center width-25 text-center align-items-center"
                        placeholder="Select"
                        name="status"
                        styles={colourStyles}
                        value={status}
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("status", e.value);
                            setStatus(e);
                          }
                        }}
                        options={statuses}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback text-danger display-contents "
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-right">
                      <Link
                        to="#"
                        className="text-decoration-none"
                        onClick={() => manageAllSelection(true)}
                      >
                        <span className="text-muted">Select All</span>
                      </Link>{" "}
                      /{" "}
                      <Link
                        to="#"
                        className="text-decoration-none"
                        onClick={() => manageAllSelection(false)}
                      >
                        <span className="text-muted">Deselect All</span>
                      </Link>
                    </Col>
                  </Row>

                  {Object.keys(moduleTypes).length
                    ? Object.keys(moduleTypes).map((type) => (
                      <Card className="mt-2" key={type}>
                        <Card.Header>
                          <h5 className=" font-weight-bold ">
                            {type === "1"
                              ? "Transportation Module"
                              : type === "2"
                                ? "Chat Module"
                                : null}
                          </h5>
                        </Card.Header>
                        <Card.Body className="pt-0">
                          <div className="row">
                            {moduleTypes[type].capabilities.map((cap) => (
                              <Col
                                md={3}
                                lg={3}
                                className="px-0"
                                key={cap.id}
                              >
                                <div
                                  className="p-3 border-bottom m-1"
                                  key={cap.id}
                                >
                                  <div className="title__check form-check">
                                    <Field
                                      type="checkbox"
                                      className="my-1 mr-sm-2 chk1 form-check-input"
                                      id={cap.id}
                                      key={"module-check-" + cap.id}
                                      onChange={(event) =>
                                        selectModule(event, cap)
                                      }
                                      checked={
                                        role &&
                                        role.capabilites.length &&
                                        isModuleChecked(cap)
                                      }
                                    />
                                    <label className="form-check-label">
                                      {cap.categoryName}
                                    </label>
                                  </div>
                                  <div className="subtitle__check pl-3">
                                    {cap.permissions.map((permission) => {
                                      return (
                                        <div
                                          className="form-check"
                                          key={permission.accessCode}
                                        >
                                          <Field
                                            key={permission.accessCode}
                                            type="checkbox"
                                            className="my-1 mr-sm-2 chk form-check-input"
                                            id={permission.accessCode}
                                            custom
                                            value={permission.accessCode}
                                            checked={
                                              role.capabilites.indexOf(
                                                permission.accessCode
                                              ) > -1
                                            }
                                            onChange={(event) =>
                                              selectPermission(permission)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={permission.accessCode}
                                          >
                                            {permission.accessName}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </div>
                        </Card.Body>
                      </Card>
                    ))
                    : null}
                  <Row className="mt-4">
                    <Col md={12} className="text-right">
                      {params.id ? (
                        currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') &&
                        <Button
                          variant="primary"
                          className="mr-3"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') &&
                        <>
                          <Button
                            variant="success"
                            className="mr-3"
                            type="submit"
                            onClick={() => setSaveNMore(true)}
                          >
                            Save & Add More
                          </Button>
                          <Button
                            variant="primary"
                            className="mr-3"
                            type="submit"
                            onClick={() => setSaveNMore(false)}
                          >
                            Add
                          </Button>
                        </>
                      )}

                      <Link className="btn btn-secondary" to="/admin/roles">
                        Cancel
                      </Link>
                    </Col>
                  </Row>
                  {/*                  
                  <div className="mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className=" font-weight-bold ">
                          Transportation Module
                        </h5>
                      </div>
                      <div>
                        <Link
                          to="#"
                          className="text-decoration-none"
                          onClick={() => manageAllSelection(true)}
                        >
                          <span className="text-muted">Select All</span>
                        </Link>{" "}
                        /{" "}
                        <Link
                          to="#"
                          className="text-decoration-none"
                          onClick={() => manageAllSelection(false)}
                        >
                          <span className="text-muted">Deselect All</span>
                        </Link>
                      </div>
                    </div>

                    <Container fluid>
                      <Row className="bg-white border mt-4">
                        {capabilities.map((capability) => {
                          return (
                            <Col md={3} lg={3} className="px-0">
                              {capability.map((cap) => {
                                return (
                                  <div
                                    className="p-3 border-bottom"
                                    key={cap.id}
                                  >
                                    <div className="title__check form-check">
                                      <Field
                                        type="checkbox"
                                        className="my-1 mr-sm-2 chk1 form-check-input"
                                        id={cap.id}
                                        key={"module-check-" + cap.id}
                                        onChange={(event) =>
                                          selectModule(event, cap)
                                        }
                                        checked={
                                          role &&
                                          role.capabilites.length &&
                                          isModuleChecked(cap)
                                        }
                                      />
                                      <label className="form-check-label">
                                        {cap.categoryName}
                                      </label>
                                    </div>
                                    <div className="subtitle__check pl-3">
                                      {cap.permissions.map((permission) => {
                                        return (
                                          <div className="form-check">
                                            <Field
                                              key={permission.accessCode}
                                              type="checkbox"
                                              className="my-1 mr-sm-2 chk form-check-input"
                                              id={permission.accessCode}
                                              custom
                                              value={permission.accessCode}
                                              checked={
                                                role.capabilites.indexOf(
                                                  permission.accessCode
                                                ) > -1
                                              }
                                              onChange={(event) =>
                                                selectPermission(permission)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={permission.accessCode}
                                            >
                                              {permission.accessName}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )
                              })}
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                     <div className=" my-4 d-flex flex-wrap align-items-center justify-content-end">
                      {params.id ? (
                        <Button
                          variant="primary"
                          className="mr-3"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="success"
                            className="mr-3"
                            type="submit"
                            onClick={() => setSaveNMore(true)}
                          >
                            Save & Add More
                          </Button>
                          <Button
                            variant="primary"
                            className="mr-3"
                            type="submit"
                            onClick={() => setSaveNMore(false)}
                          >
                            Add
                          </Button>
                        </>
                      )}

                      <Link className="btn btn-secondary" to="/roles">
                        Cancel
                      </Link>
                    </div> 
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default React.memo(RolePage);
