import React from 'react'
import cardIcon from './images/cardIcon.svg';
import AppleStore from './images/AppleStore.svg';
import AnalyticCard from './AnalyticCard';
import arrow from './images/arrow.svg';
import Playstorebtn from './images/Playstorebtn.svg';
import downArrow from './images/downArrow.svg';
import profilepic from './images/profilepic.svg';

function Analytics() {
  return (
    <>

      <section className='rounded p-4 bg-white'>

        <div className="d-flex justify-content-between text-center align-items-center mb-3">
          <h2 className="box-title">Analytics</h2>
          <img src={cardIcon} alt="card icon" />

        </div>

        <div className="icon align-items-center mb-3">
          <img src={AppleStore} alt="Apple store logo" />

          <span className='light-text ml-2 text-capitalize'>Apple app store</span>
        </div>


        <div style={{ borderBottom: '1px solid #f7f7f7', marginBottom: '24px' }}>
          <div className="row">
            <AnalyticCard
              ProfileName='App Downloads'
              Points='23,820'
              percentage='135'
              arrow={arrow}
              percentTextColor='#13B542'
              percentBgColor='rgba(19, 181, 66, 0.1)'
            />

            <AnalyticCard
              ProfileName='App Installed'
              Points='22,923'
              percentage='132'
              arrow={arrow}
              percentTextColor='#13B542'
              percentBgColor='rgba(19, 181, 66, 0.1)'
            />

            <AnalyticCard
              ProfileName='App crashed'
              Points='324'
              percentage='23'
              arrow={downArrow}
              percentTextColor='#EF4242'
              percentBgColor='#FDE8E8'
            />

            <AnalyticCard
              ProfileName='App Uninstalled'
              Points='492'
              percentage='96'
              arrow={arrow}
              percentTextColor='#13B542'
              percentBgColor='rgba(19, 181, 66, 0.1)'
            />
          </div>
        </div>

        <div className="icon align-items-center d-flex mb-3">
          <div className='playStore'>

            <img src={Playstorebtn} alt="Apple store logo" />
          </div>
          <span className='light-text ml-2 text-capitalize'>Google play store</span>
        </div>


        <div className="row">
          <AnalyticCard
            ProfileName='App Downloads'
            Points='57,901'
            percentage='75'
            arrow={arrow}
            percentTextColor='#13B542'
            percentBgColor='rgba(19, 181, 66, 0.1)'
          />

          <AnalyticCard
            ProfileName='App Installed'
            Points='55,232'
            percentage='70'
            arrow={arrow}
            percentTextColor='#13B542'
            percentBgColor='rgba(19, 181, 66, 0.1)'
          />

          <AnalyticCard
            ProfileName='App crashed'
            Points='233'
            percentage='23'
            arrow={downArrow}
            percentTextColor='#EF4242'
            percentBgColor='#FDE8E8'
          />

          <AnalyticCard
            ProfileName='App Uninstalled'
            Points='1,292'
            percentage='13'
            arrow={downArrow}
            percentTextColor='#EF4242'
            percentBgColor='#FDE8E8'
          />
        </div>






      </section>

    </>
  )
}

export default Analytics