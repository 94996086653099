import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Line } from "react-chartjs-2";
import droparrow from "./images/droparrow.svg";
import APIService from "../../services/APIService";
import { TabView, TabPanel } from "primereact/tabview";
import CancelledTrips from "./CancelledTrips";
import Timezone from "./Timezone";
import Calendar from "./Calendar";
import moment from 'moment';
import { barChartOptions, convertDataBasedOnRequirement } from "./linechartdata/linechartdata";

function Linechart(props) {
  const { topCardTrips } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphCountList, setgraphCountList] = useState(null),
    [lineGraphData, setLineGraphData] = useState({}),
    [updatedData,setUpdatedData]=useState(null),
    [timeRange, setTimeRange] = useState("week")
  const [dateRange, setDateRange] = useState(null);
  const [statusCounts,setStatusCounts]=useState({});
    

  const getTimeRange = (range) => {
    APIService.get(`admin/dashboard/trip-stats?type=custom&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUpdatedData(response?.data?.data)
          setDateRange(response?.data?.data?.dateRange)
            if(response?.data?.data?.graphList){
              const convertToFormat = convertDataBasedOnRequirement(response?.data?.data?.graphList);
              setLineGraphData(convertToFormat);
            }
            setStatusCounts(response?.data?.data?.statusCounts);
          setgraphCountList(
            response.data &&
            response.data.data &&
            response.data.data.percentaged
          )
        }
      })
      .catch((error) => {
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });
  }

  useEffect(() => {
    let dataGraphList = props?.sendgraphTripData?.graphList
    if (dataGraphList !== undefined) {
      const convertedData = convertDataBasedOnRequirement(dataGraphList);
      console.log(convertedData)
      setLineGraphData(convertedData);
    }
  }, [props.sendgraphTripData])

  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

  let manageTimeRange = (event) => {
    console.log(event)
    setTimeRange(event.target.value);
    APIService.get("admin/dashboard/trip-stats?type=" + event.target.value)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUpdatedData(response?.data?.data)
          setDateRange(response?.data?.data?.dateRange)
            if(response?.data?.data?.graphList){
              const convertToFormat = convertDataBasedOnRequirement(response?.data?.data?.graphList);
              setLineGraphData(convertToFormat);
            }
            setStatusCounts(response?.data?.data?.statusCounts);
          setgraphCountList(
            response.data &&
            response.data.data &&
            response.data.data.percentaged
          )
        }
      })
      .catch((error) => {
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });
  };
  useEffect(()=>{
    setStatusCounts(graphCountList
      ? graphCountList
      : props?.sendgraphTripData?.statusCounts)
  },[props?.sendgraphTripData?.statusCounts])


  return (
    <div className=" containre-fluid cards-section line-section h-100">
      <div className="card-nav card-nv2 mb-16">
        <h2 className="box-title">Total Trips: {topCardTrips?.count}</h2>
        <div>
          <div className="dropdown">
            <div className="selectbox">
              <select
                className="select outline-none border-0 "
                style={{ width: "fit-content" }}
              >
                <option value="day">I own a car</option>
              </select>
            </div>

            <div className="selectbox align-items-center d-inline-block float-right">
              <select
                style={{ width: "fit-content" }}
                value={timeRange}
                className="select outline-none border-0 "
                onChange={manageTimeRange}
              >
                <option value="day">Today</option>
                <option value="week">Last 7 Days</option>
                <option value="month">By Week</option>
                <option value="year">By Month</option>
              </select>
            </div>

            <Calendar handleTimeRange={getTimeRange} />
          </div>
        </div>
      </div>

      {/* section heading */}

      <TabView
        className="mt-2"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="All">
          {" "}
          <div className="tab-pane fade active show" id="All">
            <div className="col-12">
              <h6 className=" graph-label left-text rounded text-capitalize">
                Trips
              </h6>
              {Object.keys(lineGraphData).length > 0 && (
                <Line data={lineGraphData} options={barChartOptions} />
              )}
              <h6 className=" graph-label-2 left-text rounded text-capitalize">
                {dateRange && `${moment(dateRange?.startDate).format('DD MMM YYYY')} - ${moment(dateRange?.endDate).format('DD MMM YYYY')}`}
              </h6>
            </div>

            <div className="col-12 graph-menu g-menu">
              <div className="main-percent line-main">
                <div className="percentage">
                  <div className="green"></div>
                  <div className="percentext">Completed:</div>
                  <div className="percenPoint">{statusCounts?.completed}</div>
                </div>

                <div className="percentage">
                  <div className="red"></div>
                  <div className="percentext">Cancelled :</div>
                  <div className="percenPoint">{statusCounts?.cancelled}</div>
                </div>

                <div className="percentage">
                  <div
                    className="green"
                    style={{ background: "#13A5B5" }}
                  ></div>
                  <div className="percentext">Unmatched:</div>
                  <div className="percenPoint">{statusCounts?.unmatched}</div>
                </div>
              </div>
            </div>


          </div>
        </TabPanel>
        <TabPanel header="Cancelled">
          <CancelledTrips
            cancelledTripData={topCardTrips}
            sendgraphTripData={updatedData ?? props.sendgraphTripData}
            timeRange={props?.timeRange}
          />
        </TabPanel>
        <TabPanel header="Time Zone">
          <Timezone
            cancelledTripData={topCardTrips}
            sendgraphTripData={updatedData ?? props.sendgraphTripData}
            timeRange={props?.timeRange}
          />
        </TabPanel>
      </TabView>
    </div>
  );
}

export default Linechart;
