import React, { useState, useEffect } from "react";
//import "./invoice.css";
import logoRide from "../../assest/images/ride-logo.png";
import { Link, useParams } from "react-router-dom";
import APIService from "../../services/APIService";
import moment from "moment";
import QRCode from "react-qr-code";
// import {html2PDF} from 'jspdf-html2canvas';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const InvoiceData = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const back = "#FFFFFF";
  const fore = "#000000";
  const size = 70;
  const { id } = useParams();
  const downloadPdfDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL(logoRide);
      // const pdf = new jsPDF();
      // var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var pdf = new jsPDF("0", "mm", "a4"); //orientation: landscape
      // var pdfWidth = pdf.internal.pageSize.getWidth();
      // var pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 10, 10, 200, 200);
      pdf.save("download.pdf");
    });
  };
  let locationRider = () => {
    APIService.get(`trips/invoice/${id}`)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          setInvoiceData(response.data.data);

          
        }
      })
      .catch(
        (err) => console.log(err)
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false })
      );
  };
  useEffect(() => {
    if (id) {
      locationRider();
     
    }
  }, []);

  return (
    <>
      <div className="invoice bg-black">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td style={{ textAlign: "right", paddingTop: "20px" }}>
              <button className="btn btn-download" onClick={downloadPdfDocument}>
                Download PDF
              </button>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td height="10" style={{ height: "10px" }}>
              &nbsp;
            </td>
          </tr>
        </table>
      </div>
      <div className="invoice" id="divToPrint" style={{ width: "100%" }}>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td
              width="30%"
              height="10"
              style={{ height: "10px", padding: "0", background: "#00FF00" }}
            >
              &nbsp;
            </td>
            <td
              width="70%"
              height="10"
              style={{ height: "10px", padding: "0", background: " #000000" }}
            >
              &nbsp;
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td style={{ padding: "15px" }}>
              <table
                bgcolor="#ffffff"
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <tbody>
                  <tr>
                    <td>
                      <div id="logo">
                        <img src={logoRide} alt="ride" />
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "right" }}>
                      <h2 className="name">SIMPLIFIED TAX INVOICE</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td height="15" style={{ height: "15px" }}>
                    &nbsp;
                  </td>
                </tr>
              </table>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td
                    width="30%"
                    height="1"
                    style={{
                      height: "10px",
                      padding: "0",
                      background: "#00FF00",
                    }}
                  >
                    &nbsp;
                  </td>
                  <td
                    width="70%"
                    height="1"
                    style={{
                      height: "10px",
                      padding: "0",
                      background: " #000000",
                    }}
                  >
                    &nbsp;
                  </td>
                </tr>
              </table>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td height="15" style={{ height: "15px" }}>
                    &nbsp;
                  </td>
                </tr>
              </table>
              <div className="table" id="no-border">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="50%">
                      <div className="ride-left">Supplier Name</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">Ride</div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div className="ride-left">Address</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">
                      Al Nakheel District, Alhousn Street Riyadh, 13325 Saudi Arabia
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div className="ride-left">VAT Number</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">310945353400003</div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div className="ride-left">Invoice Number</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">{invoiceData?.invoiceNo}</div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div className="ride-left">Invoice Issuance Date</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">
                        {moment(invoiceData?.createdAt).format("YYYY/MM/DD")}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div className="ride-left">Customer Name</div>
                    </td>
                    <td width="50%">
                      <div className="ride-right">
                        {invoiceData?.rider
                          ? `${invoiceData?.rider?.firstName} ${invoiceData?.rider?.lastName}`
                          : "--"}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td height="10" style={{ height: "10px" }}>
                    &nbsp;
                  </td>
                </tr>
              </table>
              <div className="table-main">
                <table
                  className="roundedCorners"
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <thead>
                    <tr>
                      <th className="no">Nature of Service</th>
                      <th className="no">Unit Price</th>
                      <th className="no">Quantity</th>
                      <th className="no">
                        Item Subtotal <br />{" "}
                        <span className="light">(Excluding VAT)</span>
                      </th>
                      <th className="no">
                        Item Subtotal <br />{" "}
                        <span className="light">(Including VAT)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>The fare for the ride</td>
                      <td> SAR {invoiceData?.tripBaseAmount}</td>
                      <td>1</td>
                      <td> SAR {invoiceData?.tripBaseAmount}</td>
                      <td>
                        SAR{" "}
                        {(
                          invoiceData?.tripBaseAmount +
                          (invoiceData?.tripBaseAmount / 100) *
                            invoiceData?.taxPercentage
                        ).toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Ride hailing surcharge</td>
                      <td>
                        SAR{" "}
                        {(
                          invoiceData?.waslFee + invoiceData?.processingFee
                        ).toFixed(2)}
                      </td>
                      <td>1</td>
                      <td>
                        SAR{" "}
                        {(
                          invoiceData?.waslFee + invoiceData?.processingFee
                        ).toFixed(2)}
                      </td>
                      <td>
                        SAR{" "}
                        {(
                          ((invoiceData?.waslFee + invoiceData?.processingFee) /
                            100) *
                            invoiceData?.taxPercentage +
                          invoiceData?.taxAmount
                        ).toFixed(2)}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td height="20" style={{ height: "20px" }}>
                    &nbsp;
                  </td>
                </tr>
              </table>
              <div className="table-main">
                <table
                  className="roundedCorners"
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <thead>
                    <tr>
                      <th className="no">Total Amount</th>
                      <th className="no"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total (Excluding VAT)</td>
                      <td className="ride-right" style={{ textAlign: "center" }}>
                        SAR{" "}
                        {(
                          invoiceData?.riderAmount +
                          invoiceData?.promoCodeAmount -
                          invoiceData?.taxAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td className="ride-right" style={{ textAlign: "center" }}>
                        -SAR {invoiceData?.promoCodeAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Taxable Amount (Excluding VAT)</td>
                      <td className="ride-right" style={{ textAlign: "center" }}>
                        SAR{" "}
                        {(
                          invoiceData?.riderAmount - invoiceData?.taxAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total VAT</td>
                      <td className="ride-right" style={{ textAlign: "center" }}>
                        SAR {invoiceData?.taxAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Amount Due</td>
                      <td className="ride-right" style={{ textAlign: "center" }}>
                        SAR {invoiceData?.riderAmount.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td height="20" style={{ height: "20px" }}>
                    &nbsp;
                  </td>
                </tr>
              </table>
              <table
                bgcolor="#ffffff"
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <tbody>
                  <tr>
                    <td width="73" style={{ padding: "0px" }}>
                      <div className="qr-code">
                        {invoiceData?.zatcaQR ? (
                          <QRCode
                            value={invoiceData?.zatcaQR}
                            bgColor={back}
                            fgColor={fore}
                            size={size === "" ? 0 : size}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td width="10" style={{ width: "10px" }}>
                      &nbsp;
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "left" }}>
                      <h4 className="notes">
                        This is a system generated document, hence there is no
                        need for stamp or signatures
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>

        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td
              width="70%"
              height="10"
              style={{ height: "10px", padding: "0", background: " #000000" }}
            >
              &nbsp;
            </td>
            <td
              width="30%"
              height="10"
              style={{ height: "10px", padding: "0", background: "#00FF00" }}
            >
              &nbsp;
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default InvoiceData;
