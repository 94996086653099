import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Spendings from "../components/Riders/Spendings";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

import PickupDetailsTab from "../components/Pickups/PickupDetailsTab";
import PickupTripsHistory from "../components/Pickups/PickupTripHistory";
import PickupTicketHistory from "../components/Pickups/PickupTicketHistory";

let PickupListingTableDetailPage = (props) => {
    const [tab, setTab] = useState(null);
   const permissions = JSON.parse(atob(localStorage.getItem("access")));
  
    
    let { id } = useParams();

  useEffect(() => {
    setTab("details");
  }, []);

  return (
    <>
      <div className="rider-listing">
        <Link to="/pickups" title="Back">
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Pickups</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/pickup-details/${tab}/${id}`);
          }}
        >
          {permissions.some(a => a.includes('/pickup-details/details')) && <Tab eventKey="details" title="Pickup Details">
            {tab === "details" && (
              <PickupDetailsTab/>
            )}
          </Tab>}

          {permissions.some(a => a.includes('/pickup-details/trip-history')) &&<Tab eventKey="trip-history" title="Trip Details">
            {tab === "trip-history" && <PickupTripsHistory />}
          </Tab>}
        
          {permissions.some(a => a.includes('/pickup-details/topup-history')) &&<Tab eventKey="topup-history" title="Ticket History">
            {tab === "topup-history" && (
              <PickupTicketHistory/>
            )}
          </Tab>
}

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(connect()(PickupListingTableDetailPage));
