import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, HeatmapLayer, InfoWindow, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import Geocode from "react-geocode";

//src
import RedcarIcon from '../../assest/images/Red.png';
import YellowcarIcon from '../../assest/images/Peach.png';
import GreencarIcon from '../../assest/images/Green.png';
import PurplecarIcon from '../../assest/images/purple.png';
import APIService, { googleMapApiKey } from '../../services/APIService';
import "../../sass/heatmap.scss"
import { Link } from 'react-router-dom';

const containerStyle = {
    height: '100vh',
    width: '100%',
    position: 'relative'
},
    colourStyles = {
        // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                width: '150px',
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? "#fff"
                        : isFocused
                            ? "white"
                            : undefined,
                color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : "white"
                        : undefined,
                },
            };
        },
    },

    clustererOptions = {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        gridSize: 50,
        minimumClusterSize: 2,
        maxZoom: 15,
        styles: [{
            textColor: "white",
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            height: 53,
            width: 53
        }
        ]
    },
    gradient = [
        'rgba(255, 200, 200, 0)',
        'rgba(255, 0, 0, 1)',
    ],
    libraries = ['visualization', 'geometry', 'places']
let google, intervalId;

const filterData = (type, data) => {
    switch (type) {
        case "all":
            return data
        case "own-a-car":
            return data
        case "ontrip-captains":
            return data.filter(a => a.color === "green")
        case "free-online-drivers":
            return data.filter(a => a.color === undefined)
        default:
            return data
    }
}

const GoogleHeatMap = ({ type }) => {
    const [map, setMap] = useState(null),
        [driverlist, setDriverList] = useState([]),
        [heatData, setHeatData] = useState([]),
        [load, setLoad] = useState(false),
        [isHovering, setIsHovering] = useState(false),
        [center, setCenter] = useState({ lat: 30.543777, lng: 70.011922 }),
        [zoom, setZoom] = useState(4),
        [selectedCountry, setSelectedCountry] = useState(),
        [selectedState, setSelectedState] = useState(),
        [selectedCity, setSelectedCity] = useState(),
        [geocoder, setGeocoder] = useState(null),
        [tripDetail, setTripdetail] = useState(null),
        mapRef = useRef(null);
    Geocode.setApiKey("AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg");
    Geocode.enableDebug();
    useEffect(async () => {
        let reponse = await generateData(center, 3400)
        setDriverList(filterData(type, reponse))
    }, [])
    useEffect(() => {
        handleCenterOnLoad()
    }, [map])
    useEffect(() => {
        setSelectedState(null)
        setSelectedCity(null)
        if (selectedCountry) {
            // setZoom(4)
            let newCenter = { lat: parseFloat(selectedCountry.latitude), lng: parseFloat(selectedCountry.longitude) }
            map.panTo(newCenter)
            getCountryBounds(selectedCountry.value)
                .then((countryBounds) => {
                    // Set the zoom level to fit the country bounds
                    map.fitBounds(countryBounds);
                })
                .catch((error) => {
                    console.error('Error retrieving country bounds:', error);
                    // Handle the error as needed
                });
            setTimeout(() => {
                setCenter(newCenter)
            }, 500);
            // setZoom(5)
            // map.setZoom(5)
            setTimeout(() => {
                handleCenterOnDrag()
            }, 1000);
        }
    }, [selectedCountry])
    useEffect(() => {
        if (selectedState) {
            let newCenter = { lat: parseFloat(selectedState.latitude), lng: parseFloat(selectedState.longitude) }
            map.panTo(newCenter)
            getCountryBounds(selectedState.value)
                .then((countryBounds) => {
                    // Set the zoom level to fit the country bounds
                    map.fitBounds(countryBounds);
                })
                .catch((error) => {
                    console.error('Error retrieving country bounds:', error);
                    // Handle the error as needed
                });
            setTimeout(() => {
                setCenter(newCenter)
            }, 500);
            setTimeout(() => {
                handleCenterOnDrag()
            }, 1000);
        }
    }, [selectedState])
    useEffect(() => {
        if (selectedCity) {
            let newCenter = { lat: parseFloat(selectedCity.latitude), lng: parseFloat(selectedCity.longitude) }
            map.panTo(newCenter)
            getCountryBounds(selectedCity.value)
                .then((countryBounds) => {
                    // Set the zoom level to fit the country bounds
                    map.fitBounds(countryBounds);
                })
                .catch((error) => {
                    console.error('Error retrieving country bounds:', error);
                    // Handle the error as needed
                });
            setTimeout(() => {
                setCenter(newCenter)
            }, 500);

            setTimeout(() => {
                handleCenterOnDrag()
            }, 1000);
        }
    }, [selectedCity])
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapApiKey,
        libraries,
    });
    if (isLoaded && !google) {
        google = window.google;
    }
    setTimeout(() => {
        setLoad(true)
    }, 200);
    const getCountryBounds = (country) => {
        return new Promise((resolve, reject) => {
            // Perform the geocoding request to retrieve the country's geocode data
            geocoder.geocode({ address: country }, (results, status) => {
                if (status === 'OK' && results.length > 0) {
                    // Extract the bounding box coordinates from the geocode results
                    const bounds = results[0].geometry.viewport;

                    // Return the bounding box coordinates as an object with north, south, east, and west properties
                    resolve(bounds.toJSON());
                } else {
                    reject(new Error('Failed to retrieve country bounds'));
                }
            });
        });
    };
    const generateData = async (center, radius) => {
        try {
            let data = []
            let response = await APIService.get(`/admin/driverlist-heatmap?latitude=${center.lat}&longitude=${center.lng}&spread=${radius}`)
            let responseData = response?.data?.data?.capTripInfo ?? []
            responseData.map(lt =>
                data.push({
                    ...lt, lat: lt.latitude, lng: lt.longitude, icon: lt.color === 'green' ? GreencarIcon : lt.color === "yellow" ? YellowcarIcon : PurplecarIcon
                })
            )
            return data
        }
        catch (e) {
            console.log(e)
            return []
        }
    },
        generateHeatMapData = async (center, radius) => {
            try {
                let response = await APIService.get(`/admin/heatmap?latitude=${center.lat}&longitude=${center.lng}&spread=${radius}`)
                let responseData = response?.data?.data?.highDemandZoneResponse
                return responseData
            }
            catch (e) { console.log(e) }
        },
        getTripDetail = async (d) => {
            setTripdetail(null)
            try {
                if (d.tripNo) {
                    let response = await APIService.get(`/admin/trips/${d.tripId}`)
                    let responseData = response?.data?.data?.trip
                    responseData.extra = d
                    responseData.tripCheck = true
                    let addressResponse = await Geocode.fromLatLng(d.latitude, d.longitude)
                    responseData.addressLine = addressResponse?.results[0]?.formatted_address
                    setTripdetail(responseData)
                }
                else setTripdetail({ tripCheck: false, message: "No Trip", extra: d })
            }
            catch (e) { console.log(e) }
        }
    const onLoad = async (mapInstance) => {
        await setMap(mapInstance);
        mapRef.current = mapInstance;
        let newData = []
        let heat = await generateHeatMapData(center, 3400)
        heat && heat.map((d, i) =>
            newData.push({
                location: new google.maps.LatLng(d.lat, d.lng), weight: d.weight
            }))
        setHeatData(newData)
        const geocoderInstance = new window.google.maps.Geocoder();
        setGeocoder(geocoderInstance);
    },
        onUnmount = () => {
            setMap(null);
            clearInterval(intervalId)
        };

    // Define a function to run the code inside the interval
    const runIntervalCode = async () => {
        const newPos = mapRef.current.getCenter().toJSON();
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const radius = google.maps.geometry.spherical.computeDistanceBetween(newPos, ne);
        let reponse = await generateData(newPos, radius / 1000)
        setDriverList(filterData(type, reponse))
        let newData = []
        let heat = await generateHeatMapData(newPos, radius / 1000)
        heat && heat.map((d, i) =>
            newData.push({
                location: new google.maps.LatLng(d.lat, d.lng), weight: d.weight
            }))
        setHeatData(newData)
    },
        handleCenterOnDrag = () => {
            if (!mapRef.current) return;

            // Clear the previous interval if it exists
            if (intervalId) clearInterval(intervalId);
            // Run the code inside the interval after 3 seconds on initial call
            setTimeout(runIntervalCode, 3000);

            // Set a new interval to run the code every 10 seconds
            intervalId = setInterval(runIntervalCode, 13000);
        },
        handleCenterOnZoom = () => {
            if (!mapRef.current) return;
            if (intervalId) clearInterval(intervalId);
            setTimeout(runIntervalCode, 3000);
            intervalId = setInterval(runIntervalCode, 13000);
        },
        handleCenterOnLoad = () => {
            if (!mapRef.current) return;
            if (intervalId) clearInterval(intervalId);
            intervalId = setInterval(runIntervalCode, 10000);
        }

    const countries = Country.getAllCountries(),
        updatedCountries = countries.map((country) => ({
            label: country.name,
            value: country.name,
            ...country,
        })),
        updateStates = (stateId) =>
            State.getStatesOfCountry(stateId).map((city) => ({
                label: city.name,
                value: city.name,
                ...city,
            })),
        updatedCities = (stateId) =>
            City.getCitiesOfCountry(stateId).map((city) => ({
                label: city.name,
                value: city.name,
                ...city,
            }));
    if (!isLoaded) return null;
    return (
        <div id="map" style={containerStyle}>
            {isLoaded && load &&
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}

                    onLoad={onLoad}
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => {
                        setTripdetail(null)
                        setIsHovering(false)
                    }}
                    onDragEnd={handleCenterOnDrag}
                    onZoomChanged={handleCenterOnZoom}
                    onUnmount={onUnmount}
                    options={{
                        minZoom: 2,
                        restriction: {
                            latLngBounds: {
                                north: 85,
                                south: -85,
                                west: -180,
                                east: 180,
                            },
                        },
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        styles: [
                            {
                                elementType: "labels",
                                stylers: [{ visibility: "off" }],
                            },
                            {
                                stylers: [{ saturation: -50, gamma: 0.7 }]
                            }
                        ],
                    }}
                >
                    <MarkerClusterer options={clustererOptions}>
                        {(clusterer) => driverlist.map((d, i) => (
                            <Marker key={i} position={{ lat: d.lat, lng: d.lng }}
                                clusterer={clusterer}
                                onMouseOver={() => {
                                    getTripDetail(d)
                                }}
                                icon={{

                                    url: d.icon,

                                    anchor: new google.maps.Point(16, 36),

                                    scaledSize: new google.maps.Size(37, 37)

                                }} />
                        ))}
                    </MarkerClusterer>
                    <HeatmapLayer key='heatmap' options={{ radius: 50, opacity: isHovering ? 1 : 0, gradient }} data={heatData} />
                    {tripDetail ?
                        tripDetail.tripCheck ? <InfoWindow
                            position={{ lat: tripDetail.extra.lat, lng: tripDetail.extra.lng }}
                            onCloseClick={() => {
                                setTripdetail(null);
                            }}
                        >
                            <div className='info-window'>
                                <div className='info-column'>
                                    <div className='row'>
                                        <p className='label'>Rider name</p>
                                        <p className='value'><Link
                                            to={"/rider-details/details/" + tripDetail.extra.captainId}
                                            target='_blank'
                                            style={{ color: "#13B542", outline: 'none' }}
                                        >{tripDetail?.rider?.fullName}</Link></p>
                                    </div>
                                    <div className='row'>
                                        <p className='label'>Pickup location</p>
                                        <p className='value' style={{ width: '230px' }}>{tripDetail?.addresses[0]?.address}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='label'>Current location</p>
                                        <p className='value' style={{ width: '250px' }}>{tripDetail?.addressLine}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='label'>Est. time to reach</p>
                                        <p className='value'>{tripDetail?.estimatedTripTime + " mins"}</p>
                                    </div>
                                </div>
                                <div className='info-column'>
                                    <div className='row'>
                                        <p className='label'>Captain name</p>
                                        <p className='value'> <Link
                                            to={"/driver-details/details/" + tripDetail.extra.captainId}
                                            target='_blank'
                                            style={{ color: "#13B542", outline: 'none' }}>{tripDetail?.driver?.fullName}</Link> </p>
                                    </div>
                                    <div className='row'>
                                        <p className='label'>Dropoff location</p>
                                        <p className='value' style={{ width: '230px' }}>{tripDetail?.addresses[1]?.address}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='label'>Car plate number</p>
                                        <p className='value'>{tripDetail?.driver?.carPlateNo}</p>
                                    </div>
                                    <div className='row'>
                                        <button className="btn btn-success float-right mb-1 ml-12" >
                                            <Link to={"/trip-details/" + tripDetail.extra.tripId}
                                                target='_blank'>
                                                Track Car
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow> : <InfoWindow
                            position={{ lat: tripDetail.extra.lat, lng: tripDetail.extra.lng }}
                            onCloseClick={() => {
                                setTripdetail(null);
                            }}
                            options={{ maxWidth: '60% !important' }}
                        >
                            <div className='info-window'>
                                <div className='info-column'>
                                    <div className='row'>
                                        <p className='value'>
                                            <span style={{ fontSize: '14px', width: '50px' }}>Driver ID: </span>
                                            <Link
                                                to={"/driver-details/details/" + tripDetail.extra.captainId}
                                                target='_blank'
                                                style={{ color: "#13B542", outline: 'none' }}
                                            >{tripDetail?.extra?.driverId}</Link>
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <p className='value'>
                                            <span style={{ fontSize: '14px', width: '50px' }}>Captain name: </span>
                                            {tripDetail?.extra?.driverName}</p>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                        : null}
                </GoogleMap>}
            <div className='heatmap-select-container'>
                <Select
                    className="width-150 br-10 bg-color-select"
                    placeholder="Country"
                    name="country"
                    styles={colourStyles}
                    onChange={setSelectedCountry}
                    options={updatedCountries}
                    value={selectedCountry}
                />
                <Select
                    className="br-10 bg-color-select state-select"
                    placeholder="State/Province"
                    name="state"
                    styles={colourStyles}
                    options={updateStates(
                        selectedCountry && selectedCountry.label ? selectedCountry.isoCode : null
                    )}
                    onChange={setSelectedState}
                    value={selectedState}
                />

                <Select
                    className="br-10 bg-color-select city-select"
                    placeholder="City"
                    name="city"
                    styles={colourStyles}
                    options={updatedCities(
                        selectedCountry && selectedCountry.label ? selectedCountry.isoCode : null
                    )}
                    onChange={setSelectedCity}
                    value={selectedCity}
                />
            </div>
        </div>
    );
};

export default GoogleHeatMap;