import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReadNotifications from "../components/Notifications/ReadNotifications";
import UnreadNotifications from "../components/Notifications/UnreadNotifications";
import "../sass/Notifications.sass";

let Notifications = () => {
  let [isSelectAll, setIsSelectAll] = useState(false);
  return (
    <div className="notification mt-4">
      <Row>
        <Col>
          <div className="title">Notifications</div>
        </Col>
        <Col md="auto">
          <div className="search__box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#D9D4EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 20a9 9 0 100-18 9 9 0 000 18zM22 22l-4-4"
              ></path>
            </svg>
            <input className="search__input" type="text" placeholder="Search" />
          </div>
          <Button
            variant="primary"
            className="btn-mark-read border-radius-15"
            // onClick={handleCloseRole}
          >
            Mark all as read
          </Button>
        </Col>
      </Row>
      <div style={{ backgroundColor: "#fff" }}>
        <Card className="mt-3">
          <div className="top-bar">
            <Row className="justify-content-between">
              <Col xs="auto">
                <span style={{ fontSize: "14px" }}>All notifications</span>
              </Col>
              <Col xs="auto">
                <Form>
                  <Form.Group controlId="formBasicCheckbox" className="mb-0">
                    <label className="mb-0 mr-2" style={{ fontSize: "14px" }}>
                      Select all
                    </label>
                    <Form.Check
                      defaultChecked={isSelectAll}
                      onChange={() => setIsSelectAll(!isSelectAll)}
                      type="checkbox"
                      className="d-inline-block"
                      style={{ marginBottom: "-2px" }}
                      // label="Select all"
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Card>
        <UnreadNotifications
          id={1}
          // isSelectAll={isSelectAll}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Dictumst sit phasellus aliquam, aliquet aliquam. Dolor quam
                  molestie urna netus eros, commodo rhoncus. Mauris ornare arcu
                  in sed arcu nullam nibh."
        />
        <UnreadNotifications
          id={2}
          // isSelectAll={isSelectAll}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst sit phasellus."
        />
        <UnreadNotifications
          id={3}
          // isSelectAll={isSelectAll}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Dictumst sit phasellus aliquam, aliquet aliquam. Dolor quam
                  molestie urna netus eros, commodo rhoncus. Mauris ornare arcu
                  in sed arcu nullam nibh."
        />
        <ReadNotifications
          id={1}
          // isSelectAll={isSelectAll}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Dictumst sit phasellus aliquam, aliquet aliquam. Dolor quam
                  molestie urna netus eros, commodo rhoncus. Mauris ornare arcu
                  in sed arcu nullam nibh."
        />
        <ReadNotifications
          id={2}
          // isSelectAll={isSelectAll}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Dictumst sit phasellus aliquam, aliquet aliquam. Dolor quam
                  molestie urna netus eros, commodo rhoncus. Mauris ornare arcu
                  in sed arcu nullam nibh."
        />
      </div>
    </div>
  );
};

export default React.memo(Notifications);
