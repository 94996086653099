import React from "react";
import RiderListingTable from "../components/Riders/RiderListingTable";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import APIService from "../services/APIService";
import PickupListingTable from "../components/Pickups/PickupListingTable";
const PickupListing = (props) => {
  

  
  return (
    <>
      <div className="rider-listing ">
        <div className="d-flex justify-content-between">
          <span className="title">Pickups</span>
        </div>
        <PickupListingTable/>
      </div>
    </>
  );
};

export default React.memo(PickupListing);
