import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import store from "../../store";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";

const storeState = store.getState();
const Google_Map_Api_Key = storeState.apiKey;

let Map = compose(
    withProps({
      googleMapURL:
        `https://maps.googleapis.com/maps/api/js?key=${Google_Map_Api_Key}&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
      options: {
        styles: [{ stylers: [{ saturation: 30 }, { gamma: 0.9 }] }],
      },
    }),
    withScriptjs,
    withGoogleMap
  )((props) => {
    const { lat, long } = props;
    return (
      <GoogleMap defaultZoom={14} defaultCenter={{ lat: lat, lng: long }}>
        <Marker position={{ lat: lat, lng: long }} />
      </GoogleMap>
    );
  }),
  TrackRider = (props) => {
    const { lat, long } = props;
    return <Map lat={lat} long={long} />;
  };

export default React.memo(connect()(TrackRider));
