import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom";

let Spendings = (props) => {
  let [tripHistory, setTripHistory] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [selectedTripHistory, setSelectedTripHistory] = useState(null),
    [filterStatus, setFilterStatus] = useState(""),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState(null),
    [filterRange, setFilterRange] = useState([0, 5]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    dt = useRef(null),
    getTripHistory = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post(
        "admin/captain-trip-history/" + props.driverId,
        filterParams
      )
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            const filteredHomes = response.data.data.trips.filter(
              (x) => x.status === 8
            );
            setTripHistory(filteredHomes);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTripHistory();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getTripHistory();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z" fill="#828282"/>
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getTripHistory();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setFilterStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
      });
      getTripHistory();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={tripHistory}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedTripHistory}
          onSelectionChange={(e) => setSelectedTripHistory(e.value)}
          paginator
          emptyMessage="No Trip History(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          rows={lazyParams.take}
          first={lazyParams.skip}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageTripHistory}
          onSort={manageTripHistory}
          onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            sortField="riderId"
            filterField="riderId"
            header="User ID"
            body={(trip) => trip.rider && trip.rider.userId}
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Amount"
            field="tripBaseAmount"
            body={(trip) => {
              return trip?.riderAmount;
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search subtotal"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Currency"
            field="tripBaseAmount"
            body={(trip) => {
              return "SAR";
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search subtotal"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            sortField="status"
            filterField="status"
            header="Status"
            // body={(trip) => {
            //   return statuses.find((sts) => sts.value === trip.status).label;
            // }}
            body={(trip) => {
              if (trip.status === 8) {
                return <span className="status-text">Completed</span>;
              } else {
                return <span className="status-text-inactive">Cancelled</span>;
              }
            }}
            sortable
            filter={showFilter}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(Spendings));
