import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import APIService from "../services/APIService";
import Select, { StylesConfig } from 'react-select';
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../sass/NotificationsManage.sass";
import { compose } from "redux";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled
        ? 'white'
        : isSelected
        ? "#28a745"
        : "#28a745",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },

};
let statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ],
  NotificationPushEditPage = (props) => {
    let { id } = useParams(),
      urlSearchParams = new URLSearchParams(props.location.search),
      queryParams = Object.fromEntries(urlSearchParams.entries()),
      [isBtnDisable, setIsBtnDisable] = useState(false),
      [selectedStatus, setSelectedStatus] = useState(null),
      [logStatus, setLogStatus] = useState(null),
      [receiverStatus, setReceiverStatus] = useState(null),
      [template, setTemplate] = useState(null),
      validationSchema = Yup.object().shape({
        templateName: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Template name")
          .max(100, "Max 100 character are allowed."),
        templateNameArabic: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Arabic Template name")
          .max(100, "Max 100 character are allowed."),
        title: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Title")
          .max(100, "Max 100 character are allowed."),
        titleArabic: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Arabic Title")
          .max(100, "Max 100 character are allowed."),
        message: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Content")
          .max(300, "Max 300 character are allowed."),
        messageArabic: Yup.string()
          .trim("Please remove whitespaces")
          .strict(false)
          .required("Please enter Arabic Content")
          .max(300, "Max 300 character are allowed."),
        status: Yup.string().required("Select Status"),
        logStatus: Yup.string().required("Select Log Status"),
      }),
      getNotifications = () => {
        props.dispatch({ type: "TOGGLE_LOADER", payload: true });
        APIService.get(`admin/templates/push/${id}`)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              let responseData = response.data.data;
              for (let tKey in responseData) {
                responseData[tKey] =
                  responseData[tKey] === null ? "" : responseData[tKey];
              }
              setSelectedStatus(
                statusOptions.find(
                  (status) => status.value === responseData.status
                )
              );

              setLogStatus(
                statusOptions.find(
                  (status) => status.value === responseData.logStatus
                )
              );
              if (responseData && responseData.receiver) {
                let NOTIFY_RECEIVER = ["Rider", "Driver", "Admin", "Other"],
                  receiverData =
                    NOTIFY_RECEIVER[responseData && responseData.receiver - 1];
                setReceiverStatus(receiverData);
              }
              setTemplate(responseData);
              props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            props.history.push(
              queryParams.module
                ? "/notification-push?module=" + queryParams.module
                : "/notification-push"
            );
          });
      };

    useEffect(() => {
      getNotifications();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let updateNotification = (formField) => {
      setIsBtnDisable(true);
      APIService.patch(`admin/templates/push/${id}`, formField)
        .then((response) => {
          setIsBtnDisable(false);
          if (response && response.data.statusCode === 200) {
            toast.success("Updated Successfully");
            setTimeout(() => {
              props.history.push(
                queryParams.module
                  ? "/notification-push?module=" + queryParams.module
                  : "/notification-push"
              );
            }, 500);
          }
        })
        .catch((error) => {
          setIsBtnDisable(false);
        });
    };

    return (
      <div className="rider-listing notification-manage">
        <Link
          to={
            queryParams.module
              ? "/notification-push?module=" + queryParams.module
              : "/notification-push"
          }
          title="Back"
        >
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Update Push Notification</span>

        {template && (
          <Formik
            enableReinitialize
            initialValues={{ ...template }}
            validationSchema={validationSchema}
            onSubmit={updateNotification}
          >
            {({ setFieldValue, handleBlur, handleChange }) => {
              return (
                <Form>
                  <Card className="notification-card">
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Template Name
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              className="shadow-none form-control input-box-text"
                              type="text"
                              placeholder="templateName"
                              name="templateName"
                              id="templateName"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="templateName"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Template Name
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              className="shadow-none form-control input-box-text"
                              type="text"
                              placeholder="templateNameArabic"
                              name="templateNameArabic"
                              id="templateNameArabic"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="templateNameArabic"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Title
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              className="shadow-none form-control input-box-text"
                              type="text"
                              placeholder="title"
                              name="title"
                              id="title"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Title
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              className="shadow-none form-control input-box-text"
                              type="text"
                              placeholder="titleArabic"
                              name="titleArabic"
                              id="titleArabic"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="titleArabic"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Content{" "}
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              component="textarea"
                              rows={8}
                              className="shadow-none form-control input-box-text"
                              type="textarea"
                              placeholder="message"
                              name="message"
                              id="message"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Content
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              component="textarea"
                              wrap="soft"
                              rows="8"
                              className="shadow-none form-control input-box-text"
                              type="textarea"
                              placeholder="messageArabic"
                              name="messageArabic"
                              id="messageArabic"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="messageArabic"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Receiver
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              disabled
                              className="shadow-none form-control input-box-text disable-input"
                              type="text"
                              placeholder="Receiver"
                              name="receiverStatus"
                              id="receiverStatus"
                              autoComplete="off"
                              value={receiverStatus}
                            />
                            <ErrorMessage
                              name="receiverStatus"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Status
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-4">
                            <Select
                              className="height-50 br-10"
                              placeholder="Select Status"
                              // isClearable
                              name="status"
                              styles={colourStyles}
                              id="status"
                              onBlur={() => {
                                handleBlur({ target: { name: "status" } });
                              }}
                              onChange={(e) => {
                                if (e) {
                                  setFieldValue("status", e.value);
                                  setSelectedStatus(e);
                                }
                              }}
                              value={selectedStatus}
                              options={statusOptions}
                            />
                            {(!selectedStatus || !selectedStatus?.value) && (
                              <ErrorMessage
                                name="status"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            )}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Log Status
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-4">
                            <Select
                              className="height-50 br-10"
                              placeholder="Select Status"
                              name="logStatus"
                              styles={colourStyles}
                              id="logStatus"
                              onBlur={() => {
                                handleBlur({ target: { name: "logStatus" } });
                              }}
                              onChange={(e) => {
                                if (e) {
                                  setFieldValue("logStatus", e.value);
                                  setLogStatus(e);
                                }
                              }}
                              value={logStatus}
                              options={statusOptions}
                            />
                          </div>
                          <ErrorMessage
                            name="logStatus"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Button
                      disabled={isBtnDisable}
                      variant="primary"
                      type="submit"
                      className="save-button btn-save border-radius-12 mt-5 ml-auto mr-auto"
                      style={{ width: "200px", textAlign: "center" }}
                    >
                      <span className="btn-text">Save Changes</span>
                    </Button>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  };

export default React.memo(
  compose(withRouter, connect())(NotificationPushEditPage)
);
