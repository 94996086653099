import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import AuditLog from "./AuditLog";
import Controls from "./Controls";
import OtherSettings from "./OtherSettings";
import VehicleAssignmentLising from "./VehicleAssignmentListing"
// import VehicleListingPage from "./VehicleMakerListingPage"
import VehicleUnAssignedLising from "./UnAssignedVehicleListing"


let VehiclesListing = (props) => {
    const { propsData} = props
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (propsData.match.params.tab) {
        setTab(propsData.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = atob(localStorage.getItem("access"));

  useEffect(() => {
    getActiveTab();
  }, []);
  
  return (
    <>
     

        <Tabs
          id="controlled-tab-example"
          className="mt-30 d-flex"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            propsData.history.push(`/master-control/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

         
            
           {
            <Tab eventKey="category-assignment" title="All Cars">
              {tab === "category-assignment" && < VehicleAssignmentLising />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
           {
            <Tab eventKey="unassigned-category" title="Unassigned Cars">
              {tab === "unassigned-category" && <VehicleUnAssignedLising  />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          
        
        </Tabs>
     
    </>
  );
};

export default React.memo(VehiclesListing);
