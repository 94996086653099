import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";

let FareConfigationListing = (props) => {
  let [vehicleTypes, setVehicleTypes] = React.useState([]),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {
        day: "",
        vehicleType: "",
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [fareConfigurations, setFareConfigurations] = useState(null),
    getVehicleType = () => {
      APIService.get("admin/cab-type/all").then((response) => {
        if (response.data && response.data.statusCode === 200) {
          setVehicleTypes(
            response.data.data.sort((vType, vType2) => {
              return new Date(vType2.createdAt) - new Date(vType.createdAt);
            })
          );
        }
      });
    },
    getFareConfigurations = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/cab-charge-cities")
        .then((response) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.length;
            setLazyParams({ ...lazyParams });
            setFareConfigurations(response.data.data);
          }
        })
        .catch((error) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
        });
    },
    [addConf, setAddConf] = useState(false),
    [countries, setCountries] = useState([]),
    [cities, setCities] = useState([]),
    [fareConf, setFareConf] = useState({
      cabId: "",
      countryId: "",
      cityId: "",
      passengerBaseFare: 0,
      passengerCostPerMin: 0,
      passengerCostPerKm: 0,
      day: "",
    }),
    [saveNmore, setSaveNmore] = useState(false),
    [dropdowns, setDropdowns] = useState({
      cab: null,
      city: null,
      country: null,
      day: null,
    }),
    createFareConf = (formValues, formProps) => {
      formValues["day"] = 1;
      APIService.post("admin/cab-charge", formValues)
        .then((response) => {
          if (response?.status === 201) {
            toast.success("Fare configuration created successfully");
            getFareConfigurations();
          }
          formProps.resetForm();
          setDropdowns({
            cab: null,
            city: null,
            country: null,
            day: null,
          });
          if (!saveNmore) {
            setAddConf(false);
          }
        })
        .catch((error) => {
          formProps.resetForm();
          setDropdowns({
            cab: null,
            city: null,
            country: null,
            day: null,
          });
        });
    },
    manageAddConf = () => {
      setAddConf(true);
      getCountries();
      setFareConf({
        cabId: "",
        countryId: "",
        cityId: "",
        passengerBaseFare: 0,
        passengerCostPerMin: 0,
        passengerCostPerKm: 0,
        day: "",
      });
    },
    getCountries = () => {
      if (!countries.length) {
        APIService.get("/master/countries").then((response) => {
          if (response.data && response.data.statusCode === 200) {
            setCountries(
              response.data.data.map((country) => {
                return {
                  value: country.id,
                  label: country.name,
                };
              })
            );
          }
        });
      }
    },
    getCities = (country) => {
      if (country) {
        APIService.get("/master/cities?country=" + country).then((response) => {
          if (response.data && response.data.statusCode === 200) {
            setCities(
              response.data.data.map((city) => {
                return { value: city.id, label: city.name };
              })
            );
          }
        });
      } else {
        setCities([]);
      }
    },
    renderHeader = () => {
      return (
        <div className="table-header">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              className="w-100"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
              }}
            />
          </span>
          {/* <span className="title">Subscription Package</span> */}

        </div>


      );
    },
    header = renderHeader();

  useEffect(() => {
    getVehicleType();
    getFareConfigurations();
  }, []);

  return (
    <div className="datatable-doc-demo mt-3 fare_configation">
      <div className="card">
        <DataTable
          ref={dt}
          value={fareConfigurations}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          editMode="row"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Fare Configuration(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          rows={25}
          totalRecords={lazyParams.totalRecords}
          globalFilter={lazyParams?.keyword}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="ID"
            field="entryNo"
            body={(fareConf) => (
              permissions.find(a => a.includes('/fare-conf/standard')) || permissions.find(a => a.includes('/fare-conf/customized')) ? <Link
                to={
                  "/fare-conf/standard/" +
                  fareConf?.id +
                  "?cid=" +
                  fareConf?.country?.id
                }
                style={{ color: '#13B542', fontWeight: '500' }}
              >
                {fareConf.entryNo}
              </Link>
                : <span className="" style={{ color: '#13B542', fontWeight: '500' }}>
                  {fareConf.entryNo}
                </span>
            )}
            sortable
            filter={false}
            filterPlaceholder="Search by id"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Country"
            field="country.name"
            sortable
            filter={false}
            filterPlaceholder="Search by Country"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="City"
            field="name"
            sortable
            filter={false}
            filterPlaceholder="Search by City"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(withRouter(FareConfigationListing)));
