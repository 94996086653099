import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import APIService from "../../services/APIService";
import GoogleHeatMap from "./GoogleHeatMap";
import TotalOnlineCaptains from "./Component/TotalOnlineCaptains";
import TotalOnlineRiders from "./Component/TotalOnlineRiders";
import TotalRiders from "./Component/TotalRiders";

const HeatMapDisplay = ({ type }) => {
  const [locationData, setLocationData] = useState([]),
    [captainsData, setCaptainsData] = useState({}),
    [ridersData, setRidersData] = useState({}),
    [totalRidesData, setTotalRidesData] = useState({}),
    [loading, setLoading] = useState(true)
  let locations = async () => {
    await APIService.get("/admin/driverlist-heatmap").then((response) => {
      if (response?.status === 200) {
        let res = response.data?.data?.capTripInfo
        let green = 0, yellow = 0, count = res.length
        res.map(a => a?.color === 'green' ? green = green + 1 : a?.color === "yellow" ? yellow = yellow + 1 : '')
        setCaptainsData({ green, yellow, count })
      }
    }).catch((e) => console.log(e));
    await APIService.get("/admin/heatmap/trip-stats").then((response) => {
      if (response?.status === 200) {
        setTotalRidesData(response.data?.data)
      }
    }).catch((e) => console.log(e));
    // await APIService.get("admin/heatmap/captain-stats").then((response) => {
    //   if (response?.status === 200) {
    //     setCaptainsData(response.data?.data)
    //   }
    // }).catch((e) => console.log(e));
    // await APIService.get("admin/dashboard/wasl-approved-count?type=year").then((response) => {
    //   if (response?.status === 200) {
    //     setCaptainsData(response.data?.data)
    //   }
    // }).catch((e) => console.log(e));
    await APIService.get("admin/riderdeatils-heatmap")
      .then((response) => {
        if (response?.data && response?.data?.statusCode === 200) {
          setRidersData(response.data.data);
        }
      })
      .catch((e) => console.log(e));
    await APIService.get("admin/get-all-active-users-locations")
      .then((response) => {
        if (response?.data && response?.data?.statusCode === 200) {
          setLocationData(response.data.data);
        }
      })
      .catch((e) => console.log(e));
    setLoading(false)
  };
  useEffect(() => {
    locations();
    // setInterval(() => locationRider(), 10000);
  }, []);
  return (
    <section>
      <div className="d-box mt-3">
        <Row>
          <Col md={12}>
            <GoogleHeatMap type={type} />
          </Col>
        </Row>
      </div>
      {!loading && <><div className="d-box mt-3">
        <TotalOnlineCaptains totalOnlineCaptains={captainsData?.count ?? "-"} completed={captainsData?.green ?? "-"} waitingCaptains={captainsData?.yellow ?? "-"} issuesOnTrip={0} />
        {/* <TotalOnlineCaptains totalOnlineCaptains={captainsData?.totalOnline} completed={captainsData?.graphList?.onRides} waitingCaptains={captainsData?.graphList?.online} issuesOnTrip={captainsData?.tripIssues && captainsData?.tripIssues[0]?.rowTotal} /> */}
      </div>
        <div className="d-box mt-3">
          <TotalOnlineRiders totalOnlineRiders={ridersData?.active ?? "-"} onTripRiders={ridersData?.totalRidersOnTrip ?? "-"} waitingForRide={ridersData?.totalRidersWaitingForTrip ?? "-"} offlineRiders={ridersData?.inActive ?? "-"} />
        </div>
        <div className="d-box mt-3">
          {/* <TotalRiders totalRides={totalRidesData?.totalTrips} totalRidesCancelled={totalRidesData?.totalTripsCancelled} ridesCancelledByRiders={totalRidesData?.cancelledByRider} ridesCancelledByDrivers={totalRidesData?.cancelledByDriver} /> */}
          <TotalRiders totalRides={totalRidesData?.total ?? "-"} totalRidesCancelled={totalRidesData?.total ?? "-"} ridesCancelledByRiders={totalRidesData['0']?.cancelByRider ?? "-"} ridesCancelledByDrivers={totalRidesData['0']?.cancelByDriver ?? "-"} />
        </div></>}
    </section >
  );
};

export default HeatMapDisplay;
