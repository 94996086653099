import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import saveAs from "save-as";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";

let ReportsTransactionsListing = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [transactions, setTransactions] = useState(null),
    [selectedTransactions, setSelectedTransactions] = useState([]),
    [filterStatus, setFilterStatus] = useState(""),
    [dateRange, setDateRange] = useState(null),
    [createdAt, setCreatedAt] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: { field: "createdAt", order: -1 },
      skip: 0,
      take: 25,
      totalRecords: 0,
      keyword: "",
    }),
    getTransactions = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/user-transactions", filterParams)
        .then((response) => {
          if (response.status === 201) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            let status = ["Active", "Overdue", "Expired"];
            setTransactions(
              response.data.data.transactions.map((transaction) => {
                transaction.driverID = transaction.driver.id;
                transaction.status =
                  status[Number(transaction.subscription.status) - 1];
                return {
                  ...transaction.subscription,
                  ...transaction.driver,
                  ...transaction,
                };
              })
            );
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    onTrasactionChange = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters) {
        if (Object.keys(event.filters).length > 0) {
          lazyParams.take = event.rows ? event.rows : lazyParams.take;
          lazyParams.skip = 0;
          let filters = { ...lazyParams.filters };
          for (let filter in event.filters) {
            if (event.filters[filter].value) {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
          lazyParams.filters = { ...filters, ...lazyParams.filters };
        } else if (!Object.keys(event.filters).length) {
          lazyParams.filters = {};
        }
      }
      setLazyParams(lazyParams);
      getTransactions();
    },
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setSelectedTransactions([]);
      setFilterStatus("");
      setCreatedAt(null);
      setLazyParams({
        filters: {},
        sort: { field: "createdAt", order: "desc" },
        skip: 0,
        take: 25,
        totalRecords: 0,
        keyword: "",
      });
      getTransactions();
    },
    exportTransactions = () => {
      let exportParams = { ...lazyParams },
        transactions = [
          "Transaction Id",
          "Date & Time",
          "Driver Id",
          "Driver Name",
          "Subscription Package",
          "Price",
          "Remaining Days",
          "Status",
        ],
        status = ["Active", "Overdue", "Expired"];
      transactions = transactions.join(",") + "\r\n";

      if (selectedTransactions.length) {
        selectedTransactions.forEach((elTransaction) => {
          transactions +=
            [
              "'" + elTransaction.transactionId + "",
              " " +
                moment(elTransaction.subscription.startDate).format(
                  "DD/MM/YYYY"
                ) +
                " ",
              "'" + elTransaction.senderId + "",
              elTransaction.driver.driverName,
              elTransaction.subscription.package.packageName,
              elTransaction.transactionAmount,
              elTransaction.subscription.remainingDays,
              status[elTransaction.subscription.status - 1],
            ].join(",") + "\r\n";
        });
        let transBlob = new Blob([transactions], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(transBlob, "TransactionsReport.csv");
      } else {
        exportParams.skip = 0;
        exportParams.take = 1000000000;
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/user-transactions", exportParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.transactions.forEach((elTransaction) => {
                transactions +=
                  [
                    "'" + elTransaction.transactionId + "",
                    " " +
                      moment(elTransaction.subscription.startDate).format(
                        "DD/MM/YYYY"
                      ) +
                      " ",
                    "'" + elTransaction.senderId + "",
                    elTransaction.driver.driverName,
                    elTransaction.subscription.package.packageName,
                    elTransaction.transactionAmount,
                    elTransaction.subscription.remainingDays,
                    status[elTransaction.subscription.status - 1],
                  ].join(",") + "\r\n";
              });
              let transBlob = new Blob([transactions], {
                type: "text/csv;charset=utf-8",
              });

              saveAs(transBlob, "TransactionsReport.csv");
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    };
  useEffect(() => {
    getTransactions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="m-1" style={{ maxWidth: "230px", width: "100%" }}>
            <div className="d-inline-flex position-relative w-100">
              <Calendar
                appendTo={null}
                id="range"
                className="mr-4 mb-2 w-100"
                placeholder="Select date range"
                dateFormat="dd/mm/yy"
                value={dateRange}
                hideOnDateTimeSelect={true}
                onChange={(e) => {
                  if (e.value) {
                    setDateRange(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters = {
                        ...lazyParams.filters,
                        createdAt: [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ],
                      };
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTransactions();
                      }, 500);
                    }
                  } else {
                    setDateRange([]);
                    delete lazyParams.filters["createdAt"];
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getTransactions();
                    }, 500);
                  }
                }}
                selectionMode="range"
                showButtonBar
                readOnlyInput
              />
              <span className="input-box-daterange-icon">
                <CalendarRange />
              </span>
            </div>
          </span>
          <span className="m-1">
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={toggleFilter}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn m-1"
              onClick={exportTransactions}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader();
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={transactions}
          header={header}
          className="p-datatable-customers"
          dataKey="transactionId"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          selection={selectedTransactions}
          onSelectionChange={(e) => setSelectedTransactions(e.value)}
          first={lazyParams.skip}
          rows={lazyParams.take}
          lazy={true}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          totalRecords={lazyParams.totalRecords}
          emptyMessage="No Transaction(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPage={onTrasactionChange}
          onSort={onTrasactionChange}
          onFilter={onTrasactionChange}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Transaction Id"
            field="transactionId"
            filterField="transactionId"
            sortable
          />

          <Column
            filter={false}
            className="text-center"
            style={{ fontSize: "12px", width: "210px" }}
            header="Date & Time"
            field="createdAt"
            filterField="createdAt"
            sortable
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    setCreatedAt(e.value);
                    if (e.value[0] && e.value[1]) {
                      lazyParams.filters = {
                        ...lazyParams.filters,
                        createdAt: [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ],
                      };
                      setTimeout(() => {
                        getTransactions();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
            body={(transaction) =>
              moment(transaction.createdAt).format("DD/MM/YYYY hh:mm A")
            }
          />

          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            sortable
            header="Driver Id"
            field="senderId"
          />

          <Column
            filter={showFilter}
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Driver Name"
            sortable
            field="driverName"
          />
          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "210px" }}
            sortable
            filterField="package.packageName"
            header="Subscription Package"
            field="package.packageName"
          />
          <Column
            filter={showFilter}
            className="text-right"
            headerClassName="text-right"
            style={{ fontSize: "12px", width: "100px" }}
            sortField="transactionAmount"
            header="Price"
            body={(transaction) => transaction.transactionAmount.toFixed(2)}
            field="transactionAmount"
            sortable
          />

          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            filterField="activity"
            sortable
            header="Remaining Days"
            field="remainingDays"
          />

          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="status"
            header="Status"
            sortable
            filterPlaceholder="Status"
            filterElement={
              <select
                value={filterStatus}
                className="form-control"
                placeholder="status"
                onChange={(event) => {
                  event.preventDefault();
                  setFilterStatus(event.target.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    status: Number(event.target.value),
                  };
                  setTimeout(() => {
                    getTransactions();
                  }, 500);
                }}
              >
                <option value="">Please Select</option>
                {[
                  { label: "Active", value: 1 },
                  { label: "Overdue", value: 2 },
                  { label: "Expired", value: 3 },
                ].map((elStatus) => {
                  return (
                    <option value={elStatus.value} key={elStatus.value}>
                      {elStatus.label}
                    </option>
                  );
                })}
              </select>
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(ReportsTransactionsListing));
