import React from "react";
import ReportsTripCancelledSummaryListing from "../components/Reports/ReportsTripCancelledSummaryListing";

let ReportsTripCancelledSummary = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Cancelled Trips by Driver Report</span>
        <ReportsTripCancelledSummaryListing />
      </div>
    </>
  );
};

export default React.memo(ReportsTripCancelledSummary);
