import React, { useState, useEffect } from "react";
import Analytics from "../components/Dashboard_2/Analytics";
import Cards from "../components/Dashboard_2/cards";
import Cashflow from "../components/Dashboard_2/cashFlow";
import CustomerCare from "../components/Dashboard_2/customerCare";
import APIService from "../services/APIService";
import Linechart from "../components/Dashboard_2/linechart";
import Navbar from "../components/Dashboard_2/navbarr";
import PromoCode from "../components/Dashboard_2/promoCode";
import Raiting from "../components/Dashboard_2/Raiting";
import Subsciption from "../components/Dashboard_2/Subsciption";
import TotalCaptains from "../components/Dashboard_2/totalCaptains";
import TotalRider from "../components/Dashboard_2/totalRider";
import { toast } from "react-toastify";

function Dashboard2(props) {
  let [sendgraphTripList, setSendGraphTripList] = useState({}),
    [topCard, setTopCard] = useState(null),
    [timeRange, setTimeRange] = useState("year"),
    [genderCard, setGenderCard] = useState(null),
    [activeRiderCard, setActiveRiderCard] = useState(null),
    [activeDriverCard, setActiveDriverCard] = useState(null),
    [driverEarningCard, setDriverEarningCard] = useState(null),
    [cancelSummaryCard, setCancelSummaryCard] = useState(null),
    [subscriptionEarningsCard, setSubscriptionEarningsCard] = useState(null),
    [activeDriversPercentage, setActiveDriversPercentage] = useState(null),
    [activeRidersPercentage, setActiveRidersPercentage] = useState(null),
    [cashFlow, setCashFlow] = useState(null),
    [waslApprovedCount, setWaslApprovedCount] = useState(null),
    [RiderReviewsStats, setRiderReviewsStats] = useState(null),
    [DriverReviewsStats, setDriverReviewsStats] = useState(null),
    [riderPromoStats, setRiderPromoStats] = useState(null),
    [driverPromoStats, setDriverPromoStats] = useState(null),
    [riderSubscriptionStats, setRiderSubscriptionStats] = useState(null),
    [driverSubscriptionStats, setDriverSubscriptionStats] = useState(null),
    [isRefreshData, setIsRefreshData] = useState(false),
    topCardDashboard = () => {
      // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      APIService.get("admin/dashboard?type=year")
        .then((response) => {
          if (response.data.statusCode === 200) {
            let topCard =
              response.data &&
              response.data.data &&
              response.data.data.topStats;
            let tripChart =
              response.data &&
              response.data.data &&
              response.data.data.tripStats;
            // let genderData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.genderStats;
            let activeRiderData =
              response.data &&
              response.data.data &&
              response.data.data.activeRiders;
            let activeDriverData =
              response.data &&
              response.data.data &&
              response.data.data.activeDrivers;

            // let driverEarningData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.driverEarnings;
            // let cancelSummaryData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.cancelSummary;
            // let subscriptionEarningsData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.subscriptionEarnings;
            let activeRiderPercentage =
              response.data &&
              response.data.data &&
              response.data.data.activeRidersPercentage;
            let waslApprovedCount = 
              response.data &&
              response.data.data &&
              response.data.data.waslApprovedCount;
            let activeDriverPercentage =
              response.data &&
              response.data.data &&
              response.data.data.activeDriversPercentage;
            let cashFlowData =
              response.data &&
              response.data.data &&
              response.data.data.cashFlow;
            let waslApprovedData =
              response.data &&
              response.data.data &&
              response.data.data.waslApprovedCount;
            let RiderReviewsStatsData =
              response.data &&
              response.data.data &&
              response.data.data.RiderReviewsStats;
            let DriverReviewsStatsData =
              response.data &&
              response.data.data &&
              response.data.data.DriverReviewsStats;
            let RiderPromoStatsData =
              response.data &&
              response.data.data &&
              response.data.data.riderPromoStats;
            let DriverPromoStatsData =
              response.data &&
              response.data.data &&
              response.data.data.captainPromoStats;
            let RiderSubscriptionStatsData =
              response.data &&
              response.data.data &&
              response.data.data.riderSubscriptionStats;
            let DriverSubscriptionStatsData =
              response.data &&
              response.data.data &&
              response.data.data.captainSubscriptionStats;
            setDriverSubscriptionStats(DriverSubscriptionStatsData);
            setRiderSubscriptionStats(RiderSubscriptionStatsData);
            setDriverPromoStats(DriverPromoStatsData);
            setRiderPromoStats(RiderPromoStatsData);
            setTopCard(topCard);
            setSendGraphTripList(tripChart);
            // setGenderCard(genderData);
            setActiveRiderCard(activeRiderData);
            setActiveDriverCard(activeDriverData);
            setActiveDriversPercentage(activeDriverPercentage);
            setActiveRidersPercentage({activeRiderPercentage,waslApprovedCount});
            setCashFlow(cashFlowData);
            setWaslApprovedCount(waslApprovedData);
            setRiderReviewsStats(RiderReviewsStatsData);
            setDriverReviewsStats(DriverReviewsStatsData);
            // setDriverEarningCard(driverEarningData);
            // setCancelSummaryCard(cancelSummaryData);
            // setSubscriptionEarningsCard(subscriptionEarningsData);

            // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          }
        })
        .catch((error) => {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        });
    };
  const refreshData = (isRefresh) => {
    if (isRefresh) {
      toast.success("Data Refresh Successfully");
      topCardDashboard();
    }
  };

  useEffect(() => {
    topCardDashboard();
  }, []);
  return (
    <>
      {/* <h1>This is my dash boad 2</h1> */}

      <div className="main__screen container-fluid">
        <div className="col-12">
          <div className="row">
            <Navbar refreshData={refreshData} />
          </div>
          <div className="col-12 bottomSpacer">
            <div className="row">
              <Cards topCard={topCard} />
            </div>
          </div>

          {/* Rider sections */}
          <div className="bottomSpacer">
            <div className="row no-gutters flex-lg-wrap flex-xl-nowrap">
              <div className=" cards col-xl-4 col-lg-12 col-md-12 col-12 mb-lg-0 mb-4">
                <TotalRider
                  activeRidersPercentage={activeRidersPercentage}
                  topCardRider={topCard?.rider}
                />
              </div>
              <div className="cards col-xl-8 col-lg-12 col-md-12 col-12">
                <TotalCaptains
                  activeDriversPercentage={activeDriversPercentage}
                  waslApprovedCount={waslApprovedCount}
                  topCardDriver={topCard?.driver}
                />
              </div>
            </div>
          </div>

          {/* section line charts */}
          <div className="bottomSpacer">
            <div className="row no-gutters flex-lg-wrap flex-xl-nowrap">
              <div className="col-xl-8 col-lg-12 mb-lg-0 mb-4">
                <Linechart
                  sendgraphTripData={sendgraphTripList}
                  topCardTrips={topCard?.trip}
                  timeRange={timeRange}
                />
              </div>
              <div className="col-xl-4 col-lg-12">
                <CustomerCare />
              </div>
            </div>
          </div>

          {/* section cashflow */}
          <div className=" bottomSpacer">
            <div className="row no-gutters flex-lg-wrap flex-xl-nowrap">
              <div className="col-xl-8 col-lg-12 col-md-12 col-12 mb-lg-0 mb-4">
                <Cashflow cashFlow={cashFlow} />
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                <Raiting
                  RiderReviewsStats={RiderReviewsStats}
                  DriverReviewsStats={DriverReviewsStats}
                />
              </div>
            </div>
          </div>

          {/* section PromoCode */}
          <div className="bottomSpacer">
            <div className="row">
              <div className="container-fluid">
                <PromoCode
                  riderPromoStats={riderPromoStats}
                  driverPromoStats={driverPromoStats}
                />
              </div>
            </div>
          </div>

          {/* Subscription seciton  */}
          <div className="bottomSpacer">
            <div className="row">
              <div className="container-fluid">
                <Subsciption
                  riderSubscriptionStats={riderSubscriptionStats}
                  driverSubscriptionStats={driverSubscriptionStats}
                />
              </div>
            </div>
          </div>

          {/* Analytics section      */}
          {/* <div className="bottomSpacer">
            <div className="row">
              <div className="col-lg-12">
                <Analytics />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard2;
