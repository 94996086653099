import React from "react";
import EmergencyTeamsListing from "../components/Emergency/EmergencyTeamsListing";

const EmergencyTeamsPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Emergency Team</span>
        <EmergencyTeamsListing />
      </div>
    </>
  );
};

export default React.memo(EmergencyTeamsPage);
