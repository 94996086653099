import React, { useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import APIService from "../services/APIService";
import { toast } from "react-toastify";
import Country from "../components/Country/Country";
import { useEffect } from "react";

let CountryPage = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showCountry, setShowCountry] = useState(false),
    [countries, setCountries] = useState(null),
    [updateData, setUpdateData] = useState({
      name: "",
    }),
    handleClose = () => {
      setShowCountry(false);
      setUpdateData({ name: "" });
    },
    handleShow = () => {
      setShowCountry(true);
    },
    validationSchema = Yup.object().shape({
      name: Yup.string().required("Please enter name"),
    }),
    updateTableData = (rowData) => {
      let country = { ...rowData };
      setUpdateData(country);
    },
    createOrUpdateCountry = (countryInfo, formProps) => {
      if (!updateData || !updateData.id) {
        APIService.post("admin/countries", countryInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              getCountries();
              toast.success("Country created successfully");
            } else {
              toast.error("Failed to create Country");
            }
            formProps.resetForm();
            setUpdateData({ name: "" });
            handleClose();
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            formProps.resetForm();
            setUpdateData({ name: "" });
            handleClose();
          });
      } else {
        APIService.patch("admin/countries/" + updateData.id, countryInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              getCountries();
              toast.success("Country updated successfully");
            } else {
              toast.error("Failed to update Country");
            }
            setUpdateData({ name: "" });
            handleClose();
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            setUpdateData({ name: "" });
            handleClose();
          });
      }
    },
    getCountries = useCallback(() => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get(`admin/countries`)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            setCountries(response.data.data);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    }, [props]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <>
      <div className="rider-listing">
        <span className="title">Country </span>
        <button
          className="btn btn-primary float-right mb-1"
          onClick={handleShow}
        >
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </button>
        <Country
          isLoading={useSelector((state) => state.isGridLoader)}
          handleShow={handleShow}
          updateTableData={updateTableData}
          countries={countries}
        />
      </div>

      <Modal show={showCountry} size="" onHide={handleClose} centered>
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateCountry}
        >
          {() => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    {updateData?.id ? "Update" : "Add New"} Country
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="name"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="name"
                    />
                    <label htmlFor="fullName">Country Name</label>
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save save-button mb-3 border-radius-15"
                  >
                    {updateData?.id ? "Update" : "Add"}
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(connect()(CountryPage));
