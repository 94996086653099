import React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import "./country.css";
import * as Yup from "yup";

class CountryInput extends React.Component {
  state = { phone: "" };

  handleOnChange = (value) => {
    value === undefined ? <div></div> : this.handleValidate(value);
    this.props.setMoblNo(value);
  };
  handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);
    this.setState({ isValid });
  };

  render() {
    this.props.setOTPValidation(this.state.isValid);
    return (
      <>
        <PhoneInput
          className="d-flex"
          international
          defaultCountry="SA"
          name="MobileNo"
          required
          countryCallingCodeEditable={false}
          value={this.state.value}
          onChange={this.handleOnChange}
        />

        {this.state.isValid ? (
          ""
        ) : (
          <div className="invalid-feedback text-danger">
            phone number is incomplete
          </div>
        )}
      </>
    );
  }
}
export default CountryInput;
