import React from "react";
import ReportsTransactionsListing from "../components/Reports/ReportsTransactionsListing";

let ReportsTransactionsPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Transactions Report</span>
        <ReportsTransactionsListing />
      </div>
    </>
  );
};

export default React.memo(ReportsTransactionsPage);
