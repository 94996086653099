import React, { useState, useCallback } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import { connect, useSelector } from "react-redux";
import DeleteModal from "../components/Common/DeleteModal";
import VehicleModelListing from "../components/VehicleModelList/VehicleModelListing";
import APIService from "../services/APIService";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let VehicleListingPage = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [vehicleModels, setVehicleModels] = useState([]),
    [vehicleMakers, setVehicleMakers] = useState([]),
    [selectedMaker, setSelectedMaker] = useState(null),
    [showModal, setShowModal] = useState(false),
    [showModalUpdate, setShowModalUpdate] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [updateData, setUpdateData] = useState({
      model: "",
      modelEnglish: "",
      status: 1,
      makerId: "",
    }),
    handleClose = () => {
      setShowModal(false);
    },
    handleShow = () => {
      setUpdateData({
        model: "",
        modelEnglish: "",
        status: 1,
      });
      setShowModal(true);
    },
    handleCloseUpdate = () => {
      setShowModalUpdate(false);
    },
    handleShowUpdate = () => setShowModalUpdate(true),
    handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setUpdateData(rowData);
      setShowDelete(true);
    },
    [saveNmore, setSaveNmore] = useState(false),
    updateTableData = (rowData) => {
      let vehicleDetails = { ...rowData };
      setUpdateData(vehicleDetails);
      setSelectedMaker(
        vehicleMakers.find((maker) => {
          return maker.value === vehicleDetails.makerId;
        })
      );
    },
    getVehicleModels = useCallback(() => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/vehicle-model/all")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            setVehicleModels(response.data.data.models);
            setVehicleMakers(
              response.data.data.makers.map((maker) => {
                return { value: maker.id, label: maker.makerEnglish };
              })
            );
            props.dispatch({
              type: "TOGGLE_GRID_LOADER",
              payload: false,
            });
          }
        })
        .catch((error) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
        });
    }, [props]),
    removeVehicleModel = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.delete("admin/vehicle-model/" + updateData.id)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            getVehicleModels();
            toast.success("Vehicle Model deleted successfully");
          } else {
            toast.error("Failed to delete Vehicle Model");
          }
          setShowDelete(false);
        })
        .catch((error) => {
          setShowDelete(false);
        });
    },
    createOrUpdateVehicleModel = (vehicleTypeInfo, props) => {
      if (!updateData || !updateData.id) {
        APIService.post("admin/vehicle-model", vehicleTypeInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Model created successfully");
              getVehicleModels();
            } else {
              toast.error("Failed to create Vehicle Model");
            }
            props.resetForm();
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.resetForm();
            if (!saveNmore) {
              handleClose();
            }
            console.log(error);
          });
      } else {
        APIService.patch(
          "admin/vehicle-model/" + updateData.id,
          vehicleTypeInfo
        )
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Model updated successfully");
              getVehicleModels();
            } else {
              toast.error("Failed to update Vehicle Model");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            handleCloseUpdate();
            console.log(error);
          });
      }
    },
    validationRules = {
      modelEnglish: Yup.string().required("Please enter Vehicle Model Name"),
      model: Yup.string().required("Please enter Vehicle Model Name Arabic"),
      makerId: Yup.string().required("Select Maker"),
    },
    validationSchema = Yup.object().shape(validationRules);

  React.useEffect(() => getVehicleModels(), [getVehicleModels]);
  return (
    <>
      <div className="rider-listing">
        <span className="title pl-2">Vehicle Models</span>
        <button
          className="btn btn-primary float-right mb-1"
          onClick={handleShow}
        >
          {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
        </button>
        <VehicleModelListing
          vehicleModels={vehicleModels}
          handleShowUpdate={handleShowUpdate}
          updateTableData={updateTableData}
          handleShowDelete={handleShowDelete}
          isLoading={useSelector((state) => state.isGridLoader)}
        />
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Formik
          initialValues={updateData}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateVehicleModel}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    Add Vehicle Model
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="mb-3">
                    <Select
                      className="height-50"
                      placeholder="Select Maker"
                      // isClearable
                      name="makerId"
                      value={selectedMaker}
                      styles={colourStyles}
                      onBlur={() => {
                        handleBlur({
                          target: {
                            name: "makerId",
                          },
                        });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("makerId", e.value);
                          setSelectedMaker(e);
                        }
                      }}
                      options={vehicleMakers}
                    />
                    <ErrorMessage
                      name="makerId"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="modelEnglish"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Model Name"
                      maxLength="20"
                    />
                    <label htmlFor="name">Vehicle Model Name (English)</label>
                    <ErrorMessage
                      name="modelEnglish"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>

                  <div className="form-group form-label-group">
                    <Field
                      id="nameArabic"
                      type="text"
                      name="model"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Model Name (Arabic)"
                      maxLength="20"
                    />
                    <label htmlFor="nameArabic">
                      Vehicle Model Name (Arabic)
                    </label>
                    <ErrorMessage
                      name="model"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save save-button mb_20 border-radius-15"
                    onClick={() => setSaveNmore(false)}
                  >
                    Add
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="btn-save-add save-button mb_20 border-radius-15"
                    onClick={() => setSaveNmore(true)}
                  >
                    Save & Add more
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb_20 border-radius-15"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal show={showModalUpdate} onHide={handleCloseUpdate} centered>
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={Yup.object().shape({
            ...validationRules,
          })}
          onSubmit={createOrUpdateVehicleModel}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    Update Vehicle Model
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="mb-4">
                    <Select
                      className="height-50"
                      placeholder="Select Maker"
                      // isClearable
                      name="makerId"
                      value={selectedMaker}
                      styles={colourStyles}
                      onBlur={() => {
                        handleBlur({
                          target: {
                            name: "status",
                          },
                        });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("makerId", e.value);
                          setSelectedMaker(e);
                        }
                      }}
                      options={vehicleMakers}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>

                  <div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="modelEnglish"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Model Name"
                    />
                    <label htmlFor="name">Vehicle Model Name (English)</label>
                    <ErrorMessage
                      name="modelEnglish"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>

                  <div className="form-group form-label-group">
                    <Field
                      id="nameArabic"
                      type="text"
                      name="model"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Model Name"
                    />
                    <label htmlFor="nameArabic">
                      Vehicle Model Name (Arabic)
                    </label>
                    <ErrorMessage
                      name="model"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Row>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-save save-button mb-3 border-radius-15"
                    >
                      Update
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <DeleteModal
        clickYes={removeVehicleModel}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
};
export default React.memo(connect()(VehicleListingPage));
