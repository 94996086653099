import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DispatcherDetail from "../components/Dispatch/DispatcherDetail";
import DispatchDetailTripTab from "../components/Dispatch/DispatchDetailTripTab";

let DispatchDetailTabsLayout = () => {
  return (
    <>
      <div className="rider-listing">
        <Link to="/dispatchers" title="Back">
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Dispatcher</span>
        <DispatcherDetail />
        <DispatchDetailTripTab />
      </div>
    </>
  );
};

export default React.memo(connect()(DispatchDetailTabsLayout));
