import React, { useState, useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";

let RideCancelledSummary = () => {
  let windowWidth = window.innerWidth;

  const cancelledTripList = [
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 3,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Active"
    // },
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 3,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Active"
    // },
    // {
    //     type: "Monthly",
    //     total_rides: 50,
    //     available_rides: 45,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Expired"
    // },
    // {
    //     type: "Monthly",
    //     total_rides: 50,
    //     available_rides: 10,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Used"
    // },
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 30,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Used"
    // },
  ];

  // [cancelledTripList, setCancelledTripList] = useState([]),
  // [timeRange, setTimeRange] = useState("week"),
  // getCancelledTrip = () => {
  //   let cancelTripData = !props.cancelSummaryData.cancelSummaryCard
  //     ? props.cancelSummaryData
  //     : props.cancelSummaryData.cancelSummaryCard;
  //   setCancelledTripList(cancelTripData);
  // },
  // manageTimeRange = (event) => {
  //   props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
  //   setTimeRange(event.target.value);
  //   APIService.get(
  //     "admin/dashboard/cancel-summary?type=" +
  //       event.target.value +
  //       "&cancelAction=both&region=riyadh"
  //   )
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
  //         setCancelledTripList(response.data && response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
  //     });
  // };

  // useEffect(() => {
  //   setTimeRange(props.timeRange);
  // }, [props.timeRange]);
  // useEffect(() => {
  //   getCancelledTrip();
  // }, [props.cancelSummaryData]);

  return (
    <>
      <h2 className="box-title">Subscription History</h2>
      <div className="cancelled-summary mt-24 mb-4">
        <DataTable
          value={cancelledTripList}
          scrollable
          scrollHeight="470px"
          // scrollHeight ="230px"
          emptyMessage="No Subscription History"
          // loading={useSelector((state) => state.isGridLoader)}
          // loadingIcon="fa fa-spinner"
        >
          <Column
            style={{ width: "90px" }}
            field="type"
            header="Type"
            body={(rowData) => rowData.type || ""}
          ></Column>
          <Column
            style={{ width: "110px", textAlign: "center" }}
            field="total_rides"
            header="Total Rides"
            body={(rowData) => rowData.total_rides || ""}
          ></Column>
          <Column
            style={{ width: "130px", textAlign: "center" }}
            field="available_rides"
            header="Available Rides"
            body={(rowData) => rowData.available_rides || ""}
          ></Column>
          <Column
            style={{ width: "110px" }}
            field="start_date"
            header="Start Date"
            body={(rowData) => rowData.start_date || ""}
          ></Column>
          <Column
            style={{ width: "110px" }}
            field="end_date"
            header="End Date"
            body={(rowData) => rowData.end_date || ""}
          ></Column>
          <Column
            style={{ width: "90px" }}
            field="status"
            header="Status"
            body={(rowData) =>
              (
                <span
                  style={{
                    color:
                      rowData.status === "Active"
                        ? "#13B542"
                        : rowData.status === "Expired"
                        ? "#EF4242"
                        : "#000000",
                  }}
                >
                  {rowData.status}
                </span>
              ) || ""
            }
          ></Column>
        </DataTable>
      </div>
    </>
  );
};
export default React.memo(connect()(RideCancelledSummary));
