import React, { useState, useEffect, useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import { InputText } from "primereact/inputtext";
import moment from "moment";
let PaymentHistory = (props) => {
  const { riderView } = props;
  const [paymentHistory, setPaymentHistory] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [createdAt, setCreatedAt] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    [selectedTripHistory, setSelectedTripHistory] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {
        entityType: 3,
        userId: riderView?.userId,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    });
  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <i className="fa fa-filter"></i>{" "}
                  <span className="span-filter">Filters</span>
                  <i className="fa fa-caret-up ml-100"></i>
                </>
              ) : (
                <>
                  <i className="fa fa-filter"></i>{" "}
                  <span className="span-filter">Filters</span>
                  <i className="fa fa-caret-down ml-100"></i>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getTripHistory();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      // setFilterStatus(null);
      setLazyParams({
        filters: {
          entityType: 3,
          userId: riderView?.userId,
        },
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
      });
      // getTripHistory();
    };

  let topUpsDetail = () => {
    // let filterParams = JSON.parse(JSON.stringify(lazyParams));
    // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    // APIService.post("admin/user-transactions", filterParams)
    //   .then((response) => {
    //     props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });

    //     var result = response.data.data.transactions;

    //     setPaymentHistory(result);
    //   })
    //   .catch(() =>
    //     props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
    //   );
    // axios
    //   .get(
    //     `https://dev-panel.ride.sa/api/gettopuphistory?customerId=${riderView?.userId}`
    //   )
    //   .then(
    //     (response) => {
    //       // var result = response?.data;
    //       // setPaymentHistory(response.data);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    // );
    // let lazyParams = {
    //   userid: rideViewData?.driverNationalId,
    //   sequenceNumber: rideViewData?.carSequenceNo,
    // };
    // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    // // let filterParams = JSON.parse(JSON.stringify(lazyParams));
    // // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    // axios
    //   .get(
    //     `https://dev-panel.ride.sa/api/gettopuphistory?customerId=${riderView?.userId}`
    //   )
    //   .then((response) => {
    //     if (response.data && response.data.statusCode === 200) {
    //       
    //       // setVehicleDetail(response.data.data);
    //       // lazyParams.totalRecords = response.data.data.totalCount;
    //       // setLazyParams(lazyParams);
    //       // setUpcomingSchedules(.trips);
    //       props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    //     }
    //   })
    //   .catch((error) => {
    //     props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    //   });
  };
  const cancelledTripList = [
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 3,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Active"
    // },
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 3,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Active"
    // },
    // {
    //     type: "Monthly",
    //     total_rides: 50,
    //     available_rides: 45,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Expired"
    // },
    // {
    //     type: "Monthly",
    //     total_rides: 50,
    //     available_rides: 10,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Used"
    // },
    // {
    //     type: "Weekly",
    //     total_rides: 10,
    //     available_rides: 30,
    //     start_date: "20 Sept 2022",
    //     end_date: "27 Sept 2022",
    //     status: "Used"
    // },
  ];

  useEffect(() => {
    topUpsDetail();
  }, [riderView]);

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <DataTable
          ref={dt}
          value={paymentHistory}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          // selection={selectedTripHistory}
          // onSelectionChange={(e) => setSelectedTripHistory(e.value)}
          // paginator
          emptyMessage="No Ticket History(s) found"
          // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          // rowsPerPageOptions={[5, 10, 25, 50]}
          // totalRecords={lazyParams.totalRecords}
          // lazy={true}
          // rows={lazyParams.take}
          // first={lazyParams.skip}
          // sortField={lazyParams.sort.field}
          // sortOrder={Number(lazyParams.sort.order)}
          //  onPage={topUpsDetail}
          // onSort={manageTripHistory}
          // onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Transaction Id"
            field="transactionId"
            sortable
            filter={false}
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            header="Date & Time"
            body={(earning) =>
              moment(earning.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="transactionAmount"
            header="Amount"
            // body={(driver) => {
            //   return (
            //     driver.rider && (
            //       <Link
            //         to={`/rider-details/details/${driver.rider.id}`}
            //         className="dataFnt-size"
            //       >
            //         {driver.rider?.userId}
            //       </Link>
            //     )
            //   );
            // }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />

          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            header="Service Tax"
            field="taxAmount"
            body={(driver) =>
              driver.taxAmount ? driver.taxAmount.toFixed(2) : "0.00"
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Search by amount"
          /> */}

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="receiverTax"
            header="Tax Amount"
            // body={(driver) => {
            //   return (
            //     driver.rider && (
            //       <Link
            //         to={`/rider-details/details/${driver.rider.id}`}
            //         className="dataFnt-size"
            //       >
            //         {driver.rider?.userId}
            //       </Link>
            //     )
            //   );
            // }}
            sortable
            
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="receiverFee"
            header="Card Fee"
            // body={(driver) => {
            //   return (
            //     driver.rider && (
            //       <Link
            //         to={`/rider-details/details/${driver.rider.id}`}
            //         className="dataFnt-size"
            //       >
            //         {driver.rider?.userId}
            //       </Link>
            //     )
            //   );
            // }}
            sortable
           
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Credit Amount"
            field="creditAmount"
            sortable
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="transactionAmount"
            header="Account No"
            body={(driver) => {
              return <span>68200033071001</span>;
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />
        </DataTable>
      </div>
    </>
  );
};
export default React.memo(connect()(PaymentHistory));
