import React from "react";
import ReportsVehicleRegistrationsListing from "../components/Reports/ReportsVehicleRegistrationsListing";

let ReportsVehicleRegistrationPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Vehicle Registrations</span>
        <ReportsVehicleRegistrationsListing />
      </div>
    </>
  );
};

export default React.memo(ReportsVehicleRegistrationPage);
