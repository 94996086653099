import React from "react";
import DriverRegistrationListing from "../components/DriverRegistration/DriverRegistrationListing";
const DriverRegistrationPage = (props) => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Captain Registration</span>
        <DriverRegistrationListing />
      </div>
    </>
  );
};

export default React.memo(DriverRegistrationPage);
