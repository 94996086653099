import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store";
import App from './App';
import reportWebVitals from './reportWebVitals';


import "primereact/resources/themes/saga-green/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
