import React, { useState, useRef } from "react";
import { Calendar as CalendarIcon, StarFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Map from "../Emergency/MapCreateTrip";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import APIService from "../../services/APIService";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { Calendar } from "primereact/calendar";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import moment from "moment";
import NoUserImage from "../../assest/images/no-user.png";

import "react-sliding-side-panel/src/index.css";

let googleMapsApiKey = "AIzaSyBIj2uM9ahOB-MuD0os0pLHoKq_FfaPMc4";

let CreateTripUI = (props) => {
  let [activeIndex, setActiveIndex] = useState(0);
  let toast = useRef(null);
  let items = [
    {
      label: "Rider",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "First Step",
          detail: event.item.label,
        });
      },
    },
    {
      label: "Location",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Seat Selection",
          detail: event.item.label,
        });
      },
    },
    {
      label: "Driver",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Pay with CC",
          detail: event.item.label,
        });
      },
    },
    {
      label: "Confirm",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Last Step",
          detail: event.item.label,
        });
      },
    },
  ];
  let [openPanel, setOpenPanel] = useState(false),
    [currentStep, setCurrentStep] = useState(1),
    [riderOptions, setRiderOptions] = useState([]),
    [selectedRider, setSelectedRider] = useState(null),
    [selectedRiderDetails, setSelectedRiderDetails] = useState(null),
    [timeInMinutes] = useState(2),
    [activeGenerateOTP, setActiveGenerateOTP] = useState(false),
    [actualOtp] = useState("1234"),
    [otpValue, setOtpValue] = useState(null),
    [isOtpVerified, setIsOtpVerified] = useState(false),
    [pickUp, setPickUp] = useState(null),
    [pickUpLatLng, setPickUpLatLng] = useState(null),
    [dropOff, setDropOff] = useState(null),
    [dropOffLatLng, setDropOffLatLng] = useState(null),
    [showCancel, setShowCancel] = useState(false),
    [carTypes, setCarTypes] = useState([]),
    [selectedCarType, setselectedCarType] = useState(null),
    [promoCode, setPromoCode] = useState(null),
    [scheduleType, setScheduleType] = useState("1"),
    [selectedSchedule, setSelectedSchedule] = useState(null),
    [showSelectedScheduleError, setShowSelectedScheduleError] = useState(null),
    [driversList, setDriversList] = useState([]),
    [selectedDriver, setSelectedDriver] = useState(null),
    [estimatedTripCostResponse, setEstimatedTripCostResponse] = useState(null),
    [showMapUI, setShowMapUI] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    [isContinueTripCreate, setIsContinueTripCreate] = useState(null),
    [windowId, setWindowId] = useState(null),
    {
      places,
      loadingElement,
      containerElement,
      mapElement,
      defaultCenter,
      defaultZoom,
    } = props,
    searchRider = (event) => {
      setTimeout(() => {
        getRiderList(event.query);
      }, 250);
    },
    getRiderList = (keyword) => {
      if (keyword) {
        APIService.get("admin/search-riders?keyword=" + keyword)
          .then((response) => {
            setRiderOptions(response.data.data);
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },
    getRiderDetails = (id) => {
      if (id) {
        APIService.get("admin/rider-details/" + id)
          .then((response) => {
            setSelectedRiderDetails(response.data.data);
            setActiveGenerateOTP(false);
            setIsOtpVerified(false);
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },
    getAllCarTypes = () => {
      APIService.get("admin/cab-type/all")
        .then((response) => {
          setCarTypes(response.data.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleChangePickUp = async (value) => {
      let results = await geocodeByAddress(value.label),
        latLng = await getLatLng(results[0]);
      setPickUpLatLng(latLng);
      setPickUp(value);
    },
    handleChangeDropOff = async (value) => {
      let results = await geocodeByAddress(value.label),
        latLng = await getLatLng(results[0]);
      setDropOffLatLng(latLng);
      setDropOff(value);
    },
    getDriversList = () => {
      // For checking condition of selected schedule without date selection
      if (scheduleType === "2" && !selectedSchedule) {
        setShowSelectedScheduleError(true);
        return;
      }
      let requestBody = {
        riderId: selectedRiderDetails?.userId,
        addresses: [
          {
            addressType: 1,
            address: pickUp?.label,
            // cityNameInArabic: "الرياض",
            latitude: pickUpLatLng?.lat,
            longitude: pickUpLatLng?.lng,
          },
          {
            addressType: 2,
            address: dropOff?.label,
            // cityNameInArabic: "الرياض",
            latitude: dropOffLatLng?.lat,
            longitude: dropOffLatLng?.lng,
          },
        ],
        cabId: selectedCarType || "",
        promoCode: promoCode || "",
      };
      APIService.post("admin/search-drivers", requestBody)
        .then((response) => {
          setDriversList(response.data.data);
          setCurrentStep(currentStep + 1);
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    getEstimatedTripCost = () => {
      let requestBody = {
        riderId: selectedRiderDetails?.userId,
        driverId: selectedDriver,
        addresses: [
          {
            addressType: 1,
            address: pickUp?.label,
            latitude: pickUpLatLng?.lat,
            longitude: pickUpLatLng?.lng,
          },
          {
            addressType: 2,
            address: dropOff?.label,
            latitude: dropOffLatLng?.lat,
            longitude: dropOffLatLng?.lng,
          },
        ],
        cabId:
          driversList.find((driver) => driver.id === selectedDriver).cabId ||
          "",
        promoCode: promoCode || "",
      };
      APIService.post("admin/estimate-trip-cost", requestBody)
        .then((response) => {
          setEstimatedTripCostResponse(response.data.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
    };

  let confirmAndBook = () => {
    let requestBody = {
        riderId: selectedRiderDetails?.userId,
        driverId: selectedDriver,
        addresses: [
          {
            addressType: 1,
            address: pickUp?.label,
            cityNameInArabic: "الرياض",
            latitude: pickUpLatLng?.lat,
            longitude: pickUpLatLng?.lng,
          },
          {
            addressType: 2,
            address: dropOff?.label,
            cityNameInArabic: "الرياض",
            latitude: dropOffLatLng?.lat,
            longitude: dropOffLatLng?.lng,
          },
        ],
        cabId:
          driversList.find((driver) => driver.id === selectedDriver).cabId ||
          "",
        promoCode: promoCode || "",
      },
      url = "create-trip-now";
    if (scheduleType === "2") {
      requestBody.riderScheduledAt = selectedSchedule;
      url = "create-trip-schedule";
    }

    APIService.post("admin/" + url, requestBody)
      .then((response) => {
        setIsContinueTripCreate(false);
        setOpenPanel(false);
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          toast.success("Trip Created Successfully.");
        }
        setTimeout(() => {
          props.history.push("/dispatchers-trip");
        }, 2000);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  const [myTimer, setMyTimer] = useState(null);
  const startTimer = (duration) => {
    let timer = duration,
      minutes,
      seconds;

    let tempTimer = setInterval(myClock, 1000);
    setMyTimer(tempTimer);
    function myClock() {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      document.getElementById(
        "timer"
      ).innerHTML = `Otp expires in ${minutes}:${seconds}`;

      if (--timer < 0) {
        setActiveGenerateOTP(false);
        clearInterval(tempTimer);
      }
    }
  };

  const generateOtp = () => {
    setActiveGenerateOTP(true);
    var timeInSeconds = 60 * timeInMinutes;
    startTimer(timeInSeconds);

  };

  const verifyOTP = () => {
    if (actualOtp === otpValue) {
      clearInterval(myTimer);
      setIsOtpVerified(true);
    }
  };

  let mapShow = () => {
    setShowMapUI(!showMapUI);
  };
 
  let closeAndReset = () => {
    setOpenPanel(false);
    setCurrentStep(1);
    setRiderOptions(null);
    setSelectedRider(null);
    setSelectedRiderDetails(null);
    setActiveGenerateOTP(false);
    setIsOtpVerified(false);
    setPickUp(null);
    setPickUpLatLng(null);
    setDropOff(null);
    setDropOffLatLng(null);
    setCarTypes(null);
    setselectedCarType(null);
    setPromoCode(null);
    setScheduleType(null);
    setSelectedSchedule(null);
    setDriversList(null);
    setSelectedDriver(null);
    setEstimatedTripCostResponse(null);
    setIsContinueTripCreate(null);
  };

  const setInfoWindow = (id) => {
    setIsOpen((isOpen = !isOpen));
    setWindowId(id);
    setSelectedDriver(id);
  };
  
  let riderListTemplate = (rider) => {
    return (
      <>
        <div className="select-rider row mt-1 p-2">
          <div className="col-2">
            <img
              alt="user"
              className="p-column-listing-photo"
              style={{ width: "50px", height: "50px" }}
              src={rider.profileImage}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
            />
          </div>
          <div className="col-10">
            <span>
              {rider.fullName} (# {rider.userId})
            </span>
            <br />
            <span>Mobile:{rider.mobileNo}</span>
          </div>
        </div>
      </>
    );
  };

  let getPanelStepElement = () => {
    let panelBodyElement;
    if (currentStep === 1) {
      panelBodyElement = (
        <>
          <div className="rider-items br-15">
            <Row>
              <Col md={6} style={{ minHeight: "255px" }}>
                <Card className="card-4 h-100">
                  <div className="card-header">
                    <h5 className="m-0">Search for Rider</h5>
                  </div>
                  <div className="card-body">
                    <AutoComplete
                      placeholder="Search for Rider Id, Name or Mobile Number"
                      value={selectedRider}
                      suggestions={riderOptions}
                      completeMethod={searchRider}
                      field="fullName"
                      itemTemplate={riderListTemplate}
                      onChange={(e) => {
                        setSelectedRider(e.value);
                        getRiderDetails(e.value.id);
                      }}
                    />
                  </div>
                </Card>
              </Col>
              <Col md={6} style={{ minHeight: "255px" }}>
                <Card className="card-4 h-100">
                  <div className="card-header">
                    <h5 className="m-0">Rider Details</h5>
                  </div>
                  <div className="card-body">
                    {selectedRiderDetails ? (
                      <>
                        <div className="row mx-n1 pb-2">
                          <div className="col-4 px-1 item-label">Rider Id</div>
                          <div className="col-8 px-1 item-value">
                            :&nbsp;
                            {selectedRiderDetails?.userId
                              ? "#" + selectedRiderDetails?.userId
                              : "-"}
                          </div>
                        </div>
                        <div className="row mx-n1 pb-2">
                          <div className="col-4 px-1 item-label">
                            Rider Name
                          </div>
                          <div className="col-8 px-1 item-value">
                            :&nbsp;
                            {selectedRiderDetails?.fullName
                              ? selectedRiderDetails?.fullName
                              : "-"}
                          </div>
                        </div>
                        <div className="row mx-n1 pb-2">
                          <div className="col-4 px-1 item-label">
                            Mobile Number
                          </div>
                          <div className="col-8 px-1 item-value">
                            :&nbsp;
                            {selectedRiderDetails?.mobileNo
                              ? selectedRiderDetails?.mobileNo + " "
                              : "-"}
                            <button
                              type="button"
                              className={`btn ${
                                isOtpVerified ? "btn-success" : "btn-primary"
                              } btn-sm ml-4`}
                              disabled={activeGenerateOTP}
                              onClick={() => generateOtp()}
                            >
                              {isOtpVerified ? (
                                <i className="fa fa-check"></i>
                              ) : null}
                              {isOtpVerified ? " Verified" : "Generate OTP"}
                            </button>
                          </div>
                        </div>
                        {activeGenerateOTP && !isOtpVerified ? (
                          <div className="row mx-n1 pb-2">
                            <div className="col-4 px-1 item-label"></div>
                            <div className="col-8 px-1 rider-items">
                              <div className="items-otp">
                                <div className="item-otp-wrap">
                                  <div className="item-otp-input">
                                    <input
                                      type="text"
                                      name="otp"
                                      value={otpValue}
                                      className="form-otp"
                                      maxlength="4"
                                      onChange={(e) =>
                                        setOtpValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={() => verifyOTP()}
                                  disabled={otpValue ? false : true}
                                >
                                  Verify
                                </button>
                                <div className="item-timer" id="timer"></div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="row mx-n1 pb-2">
                          <div className="col-4 px-1 item-label">Ratings</div>
                          <div className="col-8 px-1 item-value">
                            :&nbsp;
                            {(selectedRiderDetails?.overallRating).toFixed(1) +
                              " "}
                            <span className="star-icon">
                              <StarFill />
                            </span>
                          </div>
                        </div>
                        <div className="row mx-n1 pb-2">
                          <div className="col-4 px-1 item-label">
                            Wallet Balance
                          </div>
                          <div className="col-8 px-1 item-value">
                            {/* TODO: Need to change this key as of now we don't have proper key */}
                            :&nbsp;
                            {selectedRiderDetails?.wallet
                              ? "SAR" + selectedRiderDetails?.wallet.toFixed(2)
                              : "-"}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <div>
                          <img
                            className="rounded"
                            src={NoUserImage}
                            style={{ width: "128px", height: "128px" }}
                            alt="no user"
                          />
                        </div>
                        <div>No rider details, please search for rider</div>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    } else if (currentStep === 2) {
      panelBodyElement = (
        <div className="rider-items br-15 mb-3">
          <Row>
            <Col md={12} style={{ minHeight: "255px" }}>
              <Card className="card-4 h-100">
                <div className="card-header">
                  <h5 className="m-0">Trip Details</h5>
                </div>
                <div className="card-body">
                  <div className="trip-items">
                    <div className="item-pick">
                      <GooglePlacesAutocomplete
                        apiKey={googleMapsApiKey}
                        className="form-control"
                        selectProps={{
                          pickUp,
                          onChange: handleChangePickUp,
                          value: pickUp,
                          placeholder: "Type Pick-up Location",
                        }}
                      />
                    </div>
                    <div className="item-drop mb-2">
                      <GooglePlacesAutocomplete
                        apiKey={googleMapsApiKey}
                        className="form-control"
                        selectProps={{
                          dropOff,
                          onChange: handleChangeDropOff,
                          value: dropOff,
                          placeholder: "Type Drop-off Location",
                        }}
                      />
                    </div>
                    <div className="row mx-n1">
                      <div
                        className={`col-12 col-sm-${
                          scheduleType === "2" ? "3" : "4"
                        } px-1`}
                      >
                        <div className="mb-2">
                          <select
                            className="form-select"
                            value={selectedCarType}
                            onChange={(e) => setselectedCarType(e.target.value)}
                          >
                            <option value="">Select Car Type</option>
                            {carTypes &&
                              carTypes.map((type) => {
                                return (
                                  <option value={type.id} key={type.id}>
                                    {type.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-${
                          scheduleType === "2" ? "3" : "4"
                        } px-1`}
                      >
                        <div className="mb-2">
                          <select
                            className="form-select"
                            onChange={(e) => setScheduleType(e.target.value)}
                            value={scheduleType}
                          >
                            <option value="1">Now</option>
                            <option value="2">Schedule</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-${
                          scheduleType === "2" ? "3" : "4"
                        } px-1`}
                      >
                        <div className="mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Promo Code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                          />
                        </div>
                      </div>
                      {scheduleType === "2" ? (
                        <div className="col-12 col-sm-3 px-1">
                          <div className="mb-2 form-control-icon">
                            <Calendar
                              appendTo={null}
                              id="scheduleDateTime"
                              className="form-control p-inputtext-datepicker"
                              placeholder="Date & Time"
                              hourFormat="12"
                              dateFormat="dd/mm/yy"
                              minDate={moment().add(30, "m").toDate()}
                              value={selectedSchedule}
                              onChange={(e) => {
                                setSelectedSchedule(e.value);
                                setShowSelectedScheduleError(false);
                              }}
                              showTime
                            />
                            <CalendarIcon />
                          </div>
                          {showSelectedScheduleError ? (
                            <div className="text-danger">
                              Please select date
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else if (currentStep === 3) {
      panelBodyElement = (
        <>
          {showMapUI === true ? (
            <div className="rider-items br-15 h-100">
              <div className="item-title pb-2">
                Closest Driver's
                {driversList?.length ? (
                  <Link
                    className="float-right cursor-pointer"
                    onClick={() => {
                      setOpenPanel(false);
                      setIsContinueTripCreate(true);
                      mapShow();
                    }}
                  >
                    Show on map
                  </Link>
                ) : null}
              </div>
              {driversList?.length ? (
                driversList.map((driver, index) => {
                  return (
                    <div className="card p-1" style={{ width: "22rem" }}>
                      <div className="card-header d-flex justify-content-between p-0 mb-1">
                        <h5 className="m-0 p-0">Driver Details</h5>
                        <span
                          className="cd-items p-0 border-0"
                          onChange={(event) => {
                            setSelectedDriver(event.target.value);
                          }}
                        >
                          <label
                            className={
                              "item-radio " +
                              (driver?.driverModeSwitch
                                ? driver?.driverRideStatus
                                  ? "available"
                                  : "onRide"
                                : "offline")
                            }
                            for={driver?.id}
                          >
                            <input
                              type="radio"
                              name="status"
                              id={driver?.id}
                              value={driver?.id}
                              checked={selectedDriver === driver?.id}
                            />
                            <span className="radio-label">
                              {driver?.driverModeSwitch
                                ? driver?.driverRideStatus
                                  ? "Available"
                                  : "OnRide"
                                : "Offline"}
                            </span>
                          </label>
                        </span>
                      </div>
                      <div className="card-body p-0 d-flex align-items-center">
                        <div className="image">
                          <img
                            src={
                              driver?.profileImage
                                ? driver?.profileImage
                                : process.env.PUBLIC_URL + `/images/user.png`
                            }
                            onError={(e) =>
                              (e.target.src =
                                process.env.PUBLIC_URL + `/images/user.png`)
                            }
                            className="profileimg"
                            alt="driver"
                          />{" "}
                        </div>
                        <div className="ml-3 w-100">
                          <h5 className="mb-0 mt-0">{driver?.driverName}</h5>
                          <span>#{driver?.externalId}</span>
                          <div
                            className="p-2 mt-1 d-flex justify-content-between rounded"
                            style={{ background: "#f2f5f8", color: "#000" }}
                          >
                            <div className="d-flex flex-column">
                              <span className="text-muted">Moble Number</span>
                              <span>{driver?.mobileNo}</span>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="text-muted">Rating</span>
                              <span>
                                {driver?.driverRating.toFixed(1) + " "}
                                <span className="star-icon">
                                  <StarFill />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="button mt-1 d-flex flex-row align-items-center">
                            {driver?.distance.toFixed(1)} km away from pick up
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No Drivers Found</div>
              )}
              
            </div>
          ) : (
            <>
              <div className="listShow" onClick={() => mapShow()}>
                List View
              </div>
              <div className="bg-white mt-2 br-15 px-3">
                <div className="mt-4">
                  <Card className="card-map">
                    <Map
                      googleMapURL={
                        "https://maps.googleapis.com/maps/api/js?key=" +
                        googleMapsApiKey +
                        "&libraries=geometry,drawing,places"
                      }
                      isOpen={isOpen}
                      setIsOpen={setInfoWindow}
                      windowId={windowId}
                      markers={driversList}
                      loadingElement={
                        loadingElement || <div style={{ height: `100%` }} />
                      }
                      containerElement={
                        containerElement || <div style={{ height: "70vh" }} />
                      }
                      mapElement={
                        mapElement || <div style={{ height: `100%` }} />
                      }
                      defaultCenter={
                        defaultCenter || { lat: 24.7136, lng: 46.6753 }
                      }
                      defaultZoom={defaultZoom || 11}
                    />
                  </Card>
                </div>
              </div>
            </>
          )}
        </>
      );
    } else if (currentStep === 4) {
      panelBodyElement = (
        <>
          <div className="rider-items br-15 mb-3">
            <Row>
              <Col md={4} style={{ minHeight: "255px" }} className="pr-2">
                <Card className="card-4 h-100">
                  <div className="card-header p-2">
                    <h5 className="m-0">Rider Details</h5>
                  </div>
                  <div className="card-body d-flex  justify-content-center align-items-cente">
                    <div className="w-100">
                      <div className="row mb-1">
                        <div className="col-5 p-1 ">Rider Id</div>
                        <div className="col-7 p-1">
                          {selectedRiderDetails?.userId
                            ? "#" + selectedRiderDetails?.userId
                            : "-"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-5 p-1">Rider Name</div>
                        <div className="col-7 p-1">
                          {selectedRiderDetails?.fullName
                            ? selectedRiderDetails?.fullName
                            : "-"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-5 p-1">Mobile Number</div>
                        <div className="col-7 p-1">
                          {selectedRiderDetails?.mobileNo
                            ? selectedRiderDetails?.mobileNo
                            : "-"}
                          <button
                            type="button"
                            className="btn btn-success btn-sm ml-4 badge-pill"
                            disabled={true}
                          >
                            <i className="fa fa-check"></i> Verified
                          </button>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-5 p-1">Ratings</div>
                        <div className="col-7 p-1">
                          {(selectedRiderDetails?.overallRating).toFixed(1) +
                            " "}
                          <span className="star-icon">
                            <StarFill />
                          </span>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-5 p-1">Wallet Balance</div>
                        <div className="col-7 p-1">
                          {/* TODO: Need to change this key as of now we don't have proper key */}
                          {selectedRiderDetails?.wallet
                            ? selectedRiderDetails?.wallet.toFixed(2) + " SAR"
                            : " -"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={5} style={{ minHeight: "255px" }} className="pr-2 pl-0">
                <Card className="card-4 h-100">
                  <div className="card-header p-2">
                    <h5 className="m-0">Trip Details</h5>
                  </div>
                  <div className="card-body d-flex  justify-content-center align-items-center">
                    <div className="w-100">
                      <div className="row mb-1">
                        <div className="col-3 p-1">Pick Up</div>
                        <div className="col-9 p-1">{pickUp?.label}</div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-3 p-1">Drop Off</div>
                        <div className="col-9 p-1">{dropOff?.label}</div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-3 p-1">Car Type</div>
                        <div className="col-9 p-1">
                          {" "}
                          {selectedCarType
                            ? carTypes.find(
                                (type) => type.id === selectedCarType
                              ).name
                            : "-"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-3 p-1">Book As</div>
                        <div className="col-9 p-1">
                          {scheduleType === "1" ? "Now" : "Schedule"}
                        </div>
                      </div>
                      {scheduleType === "2" ? (
                        <div className="row mb-1">
                          <div className="col-3 p-1">Date & Time</div>
                          <div className="col-9 p-1">
                            {scheduleType !== "1"
                              ? moment(selectedSchedule).format(
                                  "MMM DD, YYYY | hh:mm A"
                                )
                              : "-"}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={3} style={{ minHeight: "255px" }} className="pl-0">
                <Card className="card-4 h-100">
                  <div className="card-header p-2">
                    <h5 className="m-0">Estimated Cost</h5>
                  </div>
                  <div className="card-body d-flex  justify-content-center align-items-center">
                    <div className="w-100">
                      <div className="row mb-1">
                        <div className="col-6 p-1">Total Km's</div>
                        <div className="col-6 p-1">
                          {estimatedTripCostResponse?.tripDistance
                            ? estimatedTripCostResponse?.tripDistance.toFixed(
                                1
                              ) + " Km's"
                            : " -"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6 p-1">Sub Total</div>
                        <div className="col-6 p-1">
                          {estimatedTripCostResponse?.tripBaseAmount
                            ? estimatedTripCostResponse?.tripBaseAmount.toFixed(
                                2
                              ) + " SAR"
                            : " -"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6 p-1">Coupon</div>
                        <div className="col-6 p-1">
                          {" "}
                          {estimatedTripCostResponse?.couponAmount
                            ? // || estimatedTripCostResponse?.couponAmount === 0

                              estimatedTripCostResponse?.couponAmount.toFixed(
                                2
                              ) + " SAR"
                            : " -"}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6 p-1">Service Tax</div>
                        <div className="col-6 p-1">
                          {" "}
                          {estimatedTripCostResponse?.serviceTaxAmount
                            ? estimatedTripCostResponse?.serviceTaxAmount.toFixed(
                                2
                              ) + " SAR"
                            : "-"}
                        </div>
                      </div>
                      <div className="row mb-1 text-light bg-secondary rounded">
                        <div className="col-6 p-2">Total</div>
                        <div className="col-6 p-2">
                          {estimatedTripCostResponse?.finalTotal
                            ? estimatedTripCostResponse?.finalTotal.toFixed(2) +
                              " SAR"
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    return panelBodyElement;
  };

  let getFooterButtons = () => {
    let panelBodyElement;
    if (currentStep === 1) {
      panelBodyElement = (
        <div className="col-2">
          <button
            className="btn btn-primary w-100"
            disabled={!isOtpVerified}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              getAllCarTypes();
              setActiveIndex(1);
            }}
          >
            Next
          </button>
        </div>
      );
    } else if (currentStep === 2) {
      panelBodyElement = (
        <>
          <div className="col-2">
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                setCurrentStep(currentStep - 1);
                setActiveIndex(0);
              }}
            >
              Previous
            </button>
          </div>
          <div className="col-2 nxt-btn">
            <button
              className="btn btn-primary w-100"
              disabled={!pickUp || !dropOff}
              onClick={() => {
                getDriversList();
                setActiveIndex(2);
              }}
            >
              Search Drivers
            </button>
          </div>
        </>
      );
    } else if (currentStep === 3) {
      panelBodyElement = (
        <>
          <div className="col-2">
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                setCurrentStep(currentStep - 1);
                setActiveIndex(1);
              }}
            >
              Previous
            </button>
          </div>
          <div className="col-2 nxt-btn">
            <button
              className="btn btn-primary w-100"
              disabled={!selectedDriver}
              onClick={() => {
                getEstimatedTripCost();
                setCurrentStep(currentStep + 1);
                setActiveIndex(3);
              }}
            >
              Next
            </button>
          </div>
        </>
      );
    } else if (currentStep === 4) {
      panelBodyElement = (
        <>
          <div className="col-3">
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                setCurrentStep(currentStep - 1);
                setActiveIndex(2);
              }}
            >
              Previous
            </button>
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary w-100 nxt-btn"
              onClick={() => {
                confirmAndBook();
              }}
            >
              Confirm & Book Now
            </button>
          </div>
        </>
      );
    }
    return panelBodyElement;
  };

  return (
    <div className="rider-listing">
      <div className="mb-4">
        <span>
          <Link to="/dispatchers-trip">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Create Trip</span>
        </span>
      </div>
      <div className="steps-demo">
        <Toast ref={toast}></Toast>

        <Card>
          <div className="pt-3">
            <Steps
              model={items}
              activeIndex={activeIndex}
              onSelect={(e) => setActiveIndex(e.index)}
              readOnly={false}
            />
          </div>
        </Card>
        <div className="mt-3">
          <Card>
            
            <div className="custom-side-panel-header">
              <h3 className="pl-3 pt-3">
                <span
                  className="custom-side-panel-close-btn"
                  onClick={() => {
                    let confirmation = window.confirm(
                      "This action will discard all changes. Are you sure?"
                    );
                    if (confirmation) {
                      closeAndReset();
                    }
                  }}
                >
                  X
                </span>
              </h3>
            </div>
            <div>
              <div className="col-12 px-2 p-3">{getPanelStepElement()}</div>
            </div>
            <div className="custom-side-panel-footer-1">
              <div className="row mb-2 mr-0 ml-0 justify-content-center">
                {getFooterButtons()}
              </div>
            </div>
            {/* </SlidingPanel> */}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CreateTripUI);
