import React, { useState } from "react";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function TotalCaptains(props) {
  const { activeDriversPercentage, waslApprovedCount, topCardDriver } = props;
  const [captainData, setCaptainData] = useState({})
  const [waslData, setWaslData] = useState({})
  const getTimeRange = (range) => {
    try {
      APIService.get(`admin/dashboard/wasl-approved-count?type=custom&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
        .then(response => {
          if (response.status == 200) {
            let data = response?.data?.data
            // setCaptainData(data)
            setWaslData(data)
          }
        })
    } catch (e) { console.log(e) }
  }
  return (
    <>
      <div className=" col-12 cards-section section-captain h-100">
        <div
          className="card-nav zoom-80"
          style={{ marginBottom: "18px", height: "auto" }}
        >
          <div>
            <ul className="nav nav-pills subscription-tabs mb-1">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="pill" href="#captains">
                  Captains
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#pickups">
                  Pickups
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="dropdown justify-content-start flex-wrap gap-1">
              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" }}
                >
                  <option value="day">I own a car</option>
                </select>
              </div>

              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" }}
                >
                  <option value="day">Riyadh</option>
                </select>
              </div>

              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" }}
                >
                  <option value="day">Orignal</option>
                </select>
              </div>
              <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" }}
                >
                  <option value="day">Male</option>
                </select>
              </div>
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>


        <section className="tab-pane fade active show" id="captains">
          <div className="row">
            <div className=" col-lg-6 col-md-12 col-12">
              <h2 className="box-title box-heading">Total Captains:{(waslData?.approved === undefined ? waslApprovedCount?.approved : waslData.approved)}</h2>
              {/* <h2 className="box-title box-heading">Total Captains:{captainData?.count ? (captainData.count.active + captainData.count.inActive): topCardDriver?.count}</h2> */}
              <div className="card bg-light p-3">
                <div>
                  <div className="graphs-section">
                    <div id="circle-1">
                      <span>{waslData?.waslOnline === undefined ? waslApprovedCount?.waslOnline : waslData?.waslOnline}</span>
                      {/* <span>{captainData?(captainData.Percentage?.activePercentage ? captainData.Percentage.activePercentage:0) : activeDriversPercentage?.Percentage?.activePercentage}</span> */}
                      <div>
                        {/* (waslData?.approved===undefined ?  waslApprovedCount?.approved : waslData.approved) */}
                        <div id="circle-2">{waslData?.waslOffline === undefined ? ( waslApprovedCount?.approved-waslApprovedCount?.waslOnline || "") : waslData?.waslOffline}</div>
                        {/* <div id="circle-2">{captainData?(captainData.Percentage?.inActivePercentage ? captainData.Percentage.inActivePercentage:0)  : activeDriversPercentage?.Percentage?.inActivePercentage}</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex-cb mt-32">
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig"></span>
                      <span className="fig-name">Online</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig bg-red"></span>
                      <span className="fig-name">Offline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* seccond card */}
            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="box-title box-heading">Total WASL :{waslData?.total === undefined ? waslApprovedCount?.total : waslData?.total}</h2>
              <div className="card bg-light p-3">
                <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-1">
                      <span>{(waslData?.approved === undefined ? waslApprovedCount?.approved : waslData.approved)}</span>

                      <div id="circle-3">{(waslData?.pending === undefined ? waslApprovedCount?.pending : waslData.pending)}</div>
                      <div id="circle-4">{(waslData?.rejected === undefined ? waslApprovedCount?.rejected : waslData.rejected)}</div>
                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig"></span>
                      <span className="fig-name">Approved</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig bg-orange"></span>
                      <span className="fig-name">Pending</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig bg-red"></span>
                      <span className="fig-name">Rejected</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="tab-pane fade coming-soon" id="pickups">
          Coming soon
        </div>
      </div>
    </>
  );
}

export default TotalCaptains;
