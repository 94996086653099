import React from "react";
import ReportsRiderListing from "../components/Reports/ReportsRiderListing";

let ReportsRiderPage = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Riders Report</span>
        <ReportsRiderListing />
      </div>
    </>
  );
};

export default React.memo(ReportsRiderPage);
