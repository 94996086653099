import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { _baseURL, secret_key } from "../../services/APIService";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
function CustomerCare() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [riderTickets, setRiderTickets] = useState(null);
 const currentDate =  moment().format('YYYY-MM-DD');

  const [driverTickets, setDriverTickets] = useState(null);
  const [riderDataset,setRiderDataset] =useState( {
    datasets: [
      {
        data: [riderTickets?.RESOLVED,riderTickets?.PENDING,riderTickets?.ASSIGNED],
        backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        borderWidth: 0,
      },
    ],
  });
  const [driverDataset,setDriverDataset] =useState( {
    datasets: [
      {
        data: [driverTickets?.RESOLVED,driverTickets?.PENDING,driverTickets?.ASSIGNED],
        backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        borderWidth: 0,
      },
    ],
  });
  const getTimeRange=(range)=>{
    const {sDate , eDate } = range;

    const startDate = moment(sDate , "DD/MM/YYYY").format('YYYY-MM-DD');
    const endDate = moment(eDate , "DD/MM/YYYY").format('YYYY-MM-DD');
    if(activeIndex == 0){
    APIService.get(`${_baseURL}/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=${startDate}&to_date=${endDate}&type=1`)
    .then(res => setRiderTickets(res.data.data))
    .catch(error => console.error(error))
    }
    if(activeIndex == 1){
    APIService.get(`${_baseURL}/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=${startDate}&to_date=${endDate}&type=2`)
    .then(res => setDriverTickets(res.data.data))
    .catch(error => console.error(error))
    }

    
  }
  const  windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [10],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };
  // const secret_key = "xBJbW0S1OIFJl9bsjsdZ";
  const _getAllTicketsURL =
  `${_baseURL}/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=2022-10-29&to_date=${currentDate}&type=1`
   
  let _getAllTickets = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify({ secret_key: secret_key }),
    };
    try{
    const response = await fetch(_getAllTicketsURL, requestOptions);
    let final_data = await response.json();
    setRiderTickets(final_data.data);
    }catch(error){
      console.log(error)
    }
 
  };
  const _getAllTicketsDriverURL =_baseURL + `/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=2022-10-29&to_date=${currentDate}&type=2`;
  let _getAllTicketsDriver = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
   
    };
    try{
    const response = await fetch(_getAllTicketsDriverURL, requestOptions);
    let final_data = await response.json();
    setDriverTickets(final_data.data);
    }catch(error){
    console.log(error)
  }

  };
  

  const options = {
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        fontWeight: "bold",
        bodyFontSize: "30",
        borderWidth: 1,
        padding: 12,
        yAlign: "bottom",

        callbacks: {
          label: function () {
            var label = "101";
            return label;
          },

          beforeLabel: function () {
            var beforelabel = "Open Tickets";
            return beforelabel;
          },
          afterLabel: function () {
            var afterlabel = "View All";
            return afterlabel;
          },
          labelTextColor: function (context) {
            return "#828282";
          },
        },
      },
    },
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Tickets",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  useEffect(() => {
    _getAllTickets();
    _getAllTicketsDriver();
  }, []);
  useEffect(() => {
    setRiderDataset( {
      datasets: [
        {
          data: [riderTickets?.RESOLVED,riderTickets?.PENDING,riderTickets?.ASSIGNED],
          backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          borderWidth: 0,
        },
      ],
    })
    setDriverDataset( {
      datasets: [
        {
          data: [driverTickets?.RESOLVED,driverTickets?.PENDING,driverTickets?.ASSIGNED],
          backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          borderWidth: 0,
        },
      ],
    })
  }, [riderTickets,driverTickets]);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 className="box-title text-wrap">Customer Care Tickets</h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header=" Riders">
            {" "}
            {!riderTickets ||
            riderTickets?.ASSIGNED +
              riderTickets?.PENDING +
              riderTickets?.RESOLVED ==
              0 ? (
              <Doughnut
                data={emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
              
            ) : (
              <Doughnut
                type="doughnut"
                width={null}
                height={windowWidth > 992 ? 150 : null}
                data={riderDataset}
                plugins={plugins}
                options={chartOptions}
              />
            )}
            <div className="info-section">
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main">
                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="green"></div>
                        <div className="percentext flex-grow-1">Open</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {riderTickets ? riderTickets?.PENDING : 0}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="red"></div>
                        <div className="percentext flex-grow-1">Closed</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {riderTickets ? riderTickets?.RESOLVED : 0}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div
                          className="green"
                          style={{ background: "#FFB065" }}
                        ></div>
                        <div className="percentext flex-grow-1">Progress</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {riderTickets ? riderTickets?.ASSIGNED : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </TabPanel>
          <TabPanel header=" Captains">
          {!driverTickets ||
            driverTickets?.ASSIGNED +
            driverTickets?.PENDING +
            driverTickets?.RESOLVED ==
              0 ? (
              <Doughnut
                data={emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
            ) : (
              <Doughnut
                type="doughnut"
                width={null}
                height={windowWidth > 992 ? 150 : null}
                data={driverDataset}
                plugins={plugins}
                options={chartOptions}
              />
            )}

            <div className="info-section">
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main">
                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="green"></div>
                        <div className="percentext flex-grow-1">Open</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {driverTickets ? driverTickets?.PENDING : 0}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="red"></div>
                        <div className="percentext flex-grow-1">Closed</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {driverTickets ? driverTickets?.RESOLVED : 0}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div
                          className="green"
                          style={{ background: "#FFB065" }}
                        ></div>
                        <div className="percentext flex-grow-1">Progress</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {driverTickets ? driverTickets?.ASSIGNED : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
             
            </div>

            <div className="main-percent rPercen justify-content-center"></div>
          </TabPanel>
          <TabPanel header=" CS Users">Coming Soon</TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default CustomerCare;
