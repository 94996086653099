import React from "react";
import EmailNotificationListing from "../components/NotificationManage/EmailNotificationListing";

const NotificationEmailPage = () => {
  return (
    <>
      
        <EmailNotificationListing />
    
    </>
  );
};

export default React.memo(NotificationEmailPage);
