import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import Admin from "./Admin";
import RolePage from "./RolePage";
import RolesPage from "./RolesPage";
import Category from "./Category";
import CategoyPermissions from "./CategoyPermissions";

let UpdateProfile = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);

  return (
    <>
      <div className="rider-listing ">
        <span className="title">Sub Admins</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/admin/${tab}`);
          }}
        >
          {permissions.find(a => a.includes('/admin/user')) &&
            <Tab eventKey="user" title="User">
              {tab === "user" && <Admin />}
            </Tab>
          }
          {permissions.find(a => a.includes('/admin/role')) &&
            <Tab eventKey="role" title="Role">
              {tab === "role" && <RolePage />}
            </Tab>
          }
          {permissions.find(a => a.includes('/admin/roles')) &&
            <Tab eventKey="roles" title="Roles">
              {tab === "roles" && <RolesPage />}
            </Tab>
          }
          {permissions.find(a => a.includes('/admin/category')) &&
            <Tab eventKey="category" title="Categories">
              {tab === "category" && <Category />}
            </Tab>
          }
          {/* {
            permissions.map(route => {
              let routeName = route.split('/')[2]
              return <Tab eventKey={routeName} title={routeName} >
                {tab === "user" && <Admin />}
                {tab === "role" && <RolePage />}
                {tab === "roles" && <RolesPage />}
                {tab === "Category" && <Admin />}
              </Tab>
            })
          } */}
          {/* {permissions.find(a => a.includes('/admin/user')) &&
            <Tab eventKey="user" title="User">
              {tab === "user" && <TripSettingDetailPage tripSettings={tripSettings} />}
            </Tab>
          } */}
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(UpdateProfile);
