import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import SubscriptionsPackageListingTable from "../components/Subscriptions/SubscriptionsPackageListingTable";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";
import { connect, useSelector } from "react-redux";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let statusOptions = [
    { value: 0, label: "Inactive" },
    { value: 1, label: "Active" },
  ],
  durationOptions = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Yearly" },
  ],
  SubscriptionsPackage = (props) => {
    let [windowWidth] = useState(useSelector((state) => state.windowSize)),
      [data, setData] = useState(null),
      [show, setShow] = useState(false),
      [showUpdate, setShowUpdate] = useState(false),
      [showDelete, setShowDelete] = useState(false),
      [saveNmore, setSaveNmore] = useState(false),
      [selectedStatus, setSelectedStatus] = useState({
        value: 1,
        label: "Active",
      }),
      [selectedDuration, setSelectedDuration] = useState({
        value: 1,
        label: "Monthly",
      }),
      [updateData, setUpdateData] = useState(null),
      handleClose = () => setShow(false),
      handleShow = () => {
        setSelectedStatus({ value: 1, label: "Active" });
        setUpdateData({
          packageName: "",
          planType: 1,
          basePrice: "",
          status: 1,
        });
        setShow(true);
      },
      handleCloseUpdate = () => {
        setShowUpdate(false);
        setSelectedDuration({
          value: 1,
          label: "Monthly",
        });
      },
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rowData) => {
        setUpdateData(rowData);
        setShowDelete(true);
      };

    useEffect(() => {
      getSubscriptionPackages();
    }, []);

    let createOrUpdateSubscription = (formInfo, props) => {
      formInfo.status = Number(selectedStatus.value);
      formInfo.planType = Number(selectedDuration.value);
      if (!updateData || !updateData.id) {
        APIService.post("admin/subscription", formInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Subscription Package created successfully");
              getSubscriptionPackages();
            } else {
              toast.error("Failed to create Subscription Package");
            }
            props.resetForm();
            setSelectedStatus({
              value: 1,
              label: "Active",
            });
            setSelectedDuration({
              value: 1,
              label: "Monthly",
            });
            setUpdateData({
              packageName: "",
              planType: 1,
              basePrice: "",
              status: 1,
            });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            props.resetForm();
            setSelectedStatus({
              value: 1,
              label: "Active",
            });
            setSelectedDuration({
              value: 1,
              label: "Monthly",
            });
            setUpdateData({
              packageName: "",
              planType: 1,
              basePrice: "",
              status: 1,
            });
            if (!saveNmore) {
              handleClose();
            }
          });
      } else {
        APIService.patch("admin/subscription/" + updateData.id, formInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Subscription Package updated successfully");
              getSubscriptionPackages();
            } else {
              toast.error("Failed to update Subscription Package");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            handleCloseUpdate();
          });
      }
    };

    let validationSchema = Yup.object().shape({
        packageName: Yup.string().required("Please enter title"),
        planType: Yup.string().required("Please select duration"),
        basePrice: Yup.number().required("Please enter price"),
        status: Yup.string().required("Select Status"),
      }),
      updateTableData = (rowData) => {
        setSelectedStatus({
          value: rowData.status.toLowerCase() === "active" ? 1 : 0,
          label: rowData.status,
        });
        setSelectedDuration({
          value: rowData.planType.toLowerCase() === "monthly" ? 1 : 2,
          label: rowData.planType,
        });
        setUpdateData(rowData);
        setShowUpdate(true);
      },
      getSubscriptionPackages = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/subscription")
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              let packages = response.data.data
                .map((subPackage) => {
                  subPackage.status = subPackage.status ? "Active" : "Inactive";
                  subPackage.planType =
                    subPackage.planType === 1 ? "Monthly" : "Yearly";
                  return subPackage;
                })
                .sort((subPackage, subPackage2) => {
                  return (
                    new Date(subPackage2.createdAt) -
                    new Date(subPackage.createdAt)
                  );
                });
              setData(packages);
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch(() => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      removeData = () => {
        handleCloseDelete();
        APIService.delete("admin/subscription/" + updateData.id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              getSubscriptionPackages();
              toast.success("Subscription Package deleted successfully");
            }
          })
          .catch(() => {
            toast.error("Failed to delete Subscription Package");
          });
      };

    return (
      <>
        <div className="rider-listing">
          <span className="title">Subscription Package</span>
          <button
            className="btn btn-primary float-right mb-1"
            onClick={handleShow}
          >
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </button>
          <SubscriptionsPackageListingTable
            data={data}
            handleShow={handleShow}
            handleShowUpdate={handleShowUpdate}
            handleShowDelete={handleShowDelete}
            updateTableData={updateTableData}
            isLoading={useSelector((state) => state.isGridLoader)}
          />
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Formik
            initialValues={updateData}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateSubscription}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      Add Subscription Package
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        id="packageName"
                        type="text"
                        name="packageName"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="packageName"
                      />
                      <label htmlFor="packageName">Package Title</label>
                      <ErrorMessage
                        name="packageName"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Package Duration"
                        name="planType"
                        styles={colourStyles}
                        id="planType"
                        onBlur={() => {
                          handleBlur({ target: { name: "planType" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("planType", e.value);
                            setSelectedDuration(e);
                          }
                        }}
                        value={selectedDuration}
                        options={durationOptions}
                      />
                      {(!selectedDuration || !selectedDuration?.value) && (
                        <ErrorMessage
                          name="planType"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      )}
                    </div>
                    <div className="form-group form-label-group">
                      <Field
                        id="basePrice"
                        type="number"
                        name="basePrice"
                        className="shadow-none form-control modal-input br-10 "
                        placeholder="Package Amount"
                      />
                      <label htmlFor="basePrice">Package Price</label>
                      <ErrorMessage
                        name="basePrice"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Status"
                        name="status"
                        styles={colourStyles}
                        id="status"
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("status", e.value);
                            setSelectedStatus(e);
                          }
                        }}
                        value={selectedStatus}
                        options={statusOptions}
                      />
                      {(!selectedStatus || !selectedStatus?.value) && (
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-save save-button mb-3 border-radius-15"
                      onClick={() => setSaveNmore(false)}
                    >
                      Add
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="btn-save-add save-button mb-3 border-radius-15"
                      onClick={() => setSaveNmore(true)}
                    >
                      Save & Add more
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateSubscription}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      Update Subscription Package
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        id="packageName"
                        type="text"
                        name="packageName"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="packageName"
                      />
                      <label htmlFor="packageName">Package Title</label>
                      <ErrorMessage
                        name="packageName"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Package Duration"
                        name="planType"
                        styles={colourStyles}
                        id="planType"
                        onBlur={() => {
                          handleBlur({ target: { name: "planType" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("planType", e.value);
                            setSelectedDuration(e);
                          }
                        }}
                        value={selectedDuration}
                        options={durationOptions}
                      />
                      {(!selectedDuration || !selectedDuration?.value) && (
                        <ErrorMessage
                          name="planType"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      )}
                    </div>

                    <div className="form-group form-label-group">
                      <Field
                        id="basePrice"
                        type="number"
                        name="basePrice"
                        className="shadow-none form-control modal-input br-10 "
                        placeholder="Package Amount"
                      />
                      <label htmlFor="basePrice">Package Price</label>
                      <ErrorMessage
                        name="basePrice"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select Status"
                        name="status"
                        styles={colourStyles}
                        id="status"
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setFieldValue("status", e.value);
                            setSelectedStatus(e);
                          }
                        }}
                        value={selectedStatus}
                        options={statusOptions}
                      />
                      {(!selectedStatus || !selectedStatus?.value) && (
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      className="btn-save save-button mb-3 border-radius-15"
                      type="submit"
                    >
                      Update
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <DeleteModal
          showDelete={showDelete}
          clickYes={removeData}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(SubscriptionsPackage));
