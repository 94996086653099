export const barChartOptions = {
    responsive: true,
    type: "line",
    barPercentage: 5,
    borderRadius: 5,
    plugins: {
      legend: {
        display: false,
      },
    },
  
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    tooltips: {
      enabled: true, // Enable tooltips
      mode: 'nearest', // Show tooltip for the nearest data point
      intersect: false,
    },
  };

  export const convertDataBasedOnRequirement = (originalData)=>{
    
    const lineChartData = {
      labels: [],
      datasets: [
        {
          label: "Completed",
          data: [],
          borderColor: "green",
          backgroundColor: "green",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Cancelled",
          data: [],
          borderColor: "red",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
          lineTension: 0.4,
          pointRadius:2

        },
        {
          label: "Unmatched",
          data: [],
          borderColor: "rgb(19, 165, 181)",
          backgroundColor: "rgb(19, 165, 181)",
          lineTension: 0.4,
          pointRadius:2
        }
      ]
    };
    
    originalData.forEach(item => {
      lineChartData.labels.push(item.key);
      item.data.forEach(dataItem => {
        switch (dataItem.key) {
          case "completed":
            lineChartData.datasets[0].data.push(dataItem.value);
            break;
          case "cancelled":
            lineChartData.datasets[1].data.push(dataItem.value);
            break;
          case "unmatched":
            lineChartData.datasets[2].data.push(dataItem.value);
            break;
        }
      });
    });
    return lineChartData;
  }
  export const convertDataBasedOnRequirementForCancelledTrips = (originalData)=>{
    
    const lineChartData = {
      labels: [],
      datasets: [
        {
          label: "Cancelled By Rider",
          data: [],
          borderColor: "red",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Cancelled By Admin",
          data: [],
          borderColor: "green",
          backgroundColor: "rgba(0, 0, 255, 0.2)",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Cancelled By Captain",
          data: [],
          borderColor: "rgb(19, 165, 181)",
          backgroundColor: "rgb(19, 165, 181)",
          lineTension: 0.4,
          pointRadius:2
        }
      ]
    };
    
    originalData.forEach(item => {
      lineChartData.labels.push(item.key);
      const riderData = item.data.find(dataItem => dataItem.key === "cancelledByRider");
      const adminData = item.data.find(dataItem => dataItem.key === "cancelledByAdmin");
      const captainData = item.data.find(dataItem => dataItem.key === "cancelledByCaptain");
      lineChartData.datasets[0].data.push(riderData ? riderData.value : 0);
      lineChartData.datasets[1].data.push(adminData ? adminData.value : 0);
      lineChartData.datasets[2].data.push(captainData ? captainData.value : 0);
    });
    
    return lineChartData
  }

  export const convertDataBasedOnRequirementForTimeZone = (originalData)=>{
    
    const lineChartData = {
      labels: [],
      datasets: [
        {
          label: "Morning",
          data: [],
          borderColor: "green",
          backgroundColor: "green",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Afternoon",
          data: [],
          borderColor: "red",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Evening",
          data: [],
          borderColor: "rgb(19, 165, 181)",
          backgroundColor: "rgb(19, 165, 181)",
          lineTension: 0.4,
          pointRadius:2
        },
        {
          label: "Night",
          data: [],
          borderColor: "purple",
          backgroundColor: "purple",
          lineTension: 0.4,
          pointRadius:2
        }
      ]
    };
    
    originalData.forEach(item => {
      lineChartData.labels.push(item.key);
      const morningData = item.data.find(dataItem => dataItem.key === "morning");
      const afternoonData = item.data.find(dataItem => dataItem.key === "afternoon");
      const eveningData = item.data.find(dataItem => dataItem.key === "evening");
      const nightData = item.data.find(dataItem => dataItem.key === "night");
      lineChartData.datasets[0].data.push(morningData ? morningData.value : 0);
      lineChartData.datasets[1].data.push(afternoonData ? afternoonData.value : 0);
      lineChartData.datasets[2].data.push(eveningData ? eveningData.value : 0);
      lineChartData.datasets[3].data.push(nightData ? nightData.value : 0);
    });
    return lineChartData;
  }
  export const convertDataForLineChartAPPUsage = (data) => {
    const labels = data.map((item) => item.key);
    const values = data.map((item) => item.value);
  
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Value",
          data: values,
          fill: false,
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
  
    return chartData;
  };
  
  export const EmptyData = {
    labels:[],
    datasets:[]
  }