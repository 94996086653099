import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import AuditLog from "../components/Settings/AuditLog";
import Controls from "../components/Settings/Controls";
import OtherSettings from "../components/Settings/OtherSettings";
import TripSettings from "../components/Settings/TripSettings";
import VehicleAssignmentLising from "../components/Settings/VehicleAssignmentListing"
import TripSettingDetailPage from "../components/TripSetting/TripSettingDetailPage";
import VehicleListingPage from "./VehicleMakerListingPage"
import SLAConfig from "../../src/pages/SLAConfig"
import HighDemandZone from "./HighDemandZone";
import VehicleUnAssignedLising from "../components/Settings/UnAssignedVehicleListing"
import VehiclesListing from "../components/Settings/VehiclesListing";

let tripSettings = [
  "RIDE-A-RIDE-STATUS",
  "DRIVER_DECLINE_TIME_LIMIT",
  "DRIVER_END_TRIP_RANGE",
  "EHSAN_DONATION_FEE",
  "DRIVER_WAITING_TIME_LIMIT",
  "NOTIFY_TIME_SCHEDULED_TRIP",
  "RECENT_ADDRESSES_LIMIT",
  "RIDER_CANCEL_TIME_LIMIT",
  "SCHEDULE_TRIP_MAX_DAYS",
  "SCHEDULE_TRIP_TIME_RANGE",
  "SCHEDULED_TRIP_MIN_RANGE",
  "TRIP_DRIVER_SEARCH_LIMIT",
  "TRIP_DRIVER_SEARCH_RADIUS",
  "TRIP_TAX_PERCENTAGE",
  "CHANGE_DESTINATION_LIMIT_FOR_RIDER",
  "RIDER_TRIPS_LIMIT_WITHOUT_KYC",
  "ADMIN_RIDE_NOTIFICATION_EMAIL",
  "ADMIN_TRANSACTION_NOTIFY_EMAIL",
  "ADMIN_RIDE_NOTIFICATION_EMAIL",
  "DRIVER_DECLINE_TIME_LIMIT",
  "EMAIL_NOTIFICATION_FROM_EMAIL",
  "EMAIL_NOTIFICATION_FROM_NAME",
  "RECENT_ADDRESSES_LIMIT",
  "SCHEDULED_TRIP_MIN_TIME",
  "SUPPORT_TOLL_FREE_NO",
  "MAX_TRIP_REQUESTS_LIMIT_FOR_CANCELLED_TRIP",
  "MAX_DRIVERS_REQUEST_LIMIT_FOR_CANCELLED_TRIP",
  "MAX_TRIP_REQUESTS_LIMIT_FOR_REJECTED_TRIP",
  "MOT_AMOUNT",
]
let Settings = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);
  console.log('p', permissions)

  return (
    <>
      <div className="rider-listing ">
        <span className="title">Master Controls</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/master-control/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

          {permissions.find(a => a.includes('/master-control/trip-settings')) &&
            <Tab eventKey="trip-settings" title="Trip Settings">
              {tab === "trip-settings" && <TripSettingDetailPage tripSettings={tripSettings} />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/vehicle-makers')) &&
            <Tab eventKey="vehicle-makers" title="Vehicle Makers">
              {tab === "vehicle-makers" && < VehicleListingPage />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/category-assignment')) &&
            <Tab eventKey="category-assignment" title="Car Assignment">
              {tab === "category-assignment" && <VehiclesListing propsData={props} />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {
            // <Tab eventKey="unassigned-category" title="unAssigned Vehicles">
            //   {tab === "unassigned-category" && <VehicleUnAssignedLising  />}
            //   {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            // </Tab>
          }
          {permissions.find(a => a.includes('/master-control/high-zone')) &&
            <Tab eventKey="high-zone" title="High Zone">
              {tab === "high-zone" && < HighDemandZone />}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/sla-config')) &&
            <Tab eventKey="sla-config" title="SLA Config">
              {tab === "sla-config" && <SLAConfig />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Settings);
