import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link, useParams } from "react-router-dom";

let DispatchDetailTripTab = (props) => {
  let [dispatcherTrips, setDispatcherTrips] = useState([]),
    [selectedTripHistory, setSelectedTripHistory] = useState(null),
    [status, setStatus] = useState(""),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    params = useParams(),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    dt = useRef(null),
    getDispatcherTrips = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/dispatchers-trips/" + params.id, filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setDispatcherTrips(response.data.data.trips);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDispatcherTrips = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDispatcherTrips();
    };

  useEffect(() => {
    getDispatcherTrips();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getDispatcherTrips();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {showFilter ? "Hide Filter" : "Show Filter"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
      });
      getDispatcherTrips();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={dispatcherTrips}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedTripHistory}
          onSelectionChange={(e) => setSelectedTripHistory(e.value)}
          paginator
          emptyMessage="No Dispatcher Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          rows={lazyParams.take}
          first={lazyParams.skip}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageDispatcherTrips}
          onSort={manageDispatcherTrips}
          onFilter={manageDispatcherTrips}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Trip Id"
            field="tripNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
            body={(trip) => (
              <React.Fragment>
                <Link to={`/trip-details/${trip.id}`} className="dataFnt-size">
                  {trip.tripNo}
                </Link>
              </React.Fragment>
            )}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            filterField="createdAt"
            header="Date & Time"
            body={(trip) => moment(trip.createdAt).format("DD/MM/YYYY hh:mm A")}
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getDispatcherTrips();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getDispatcherTrips();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            header="Pickup"
            filterField="pickup"
            sortField="pickup"
            field="pickup.address"
            body={(trip) => trip.pickup?.address}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by pickUp"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            header="Dropoff"
            field="dropOff.address"
            filterField="dropoff"
            sortField="dropoff"
            sortable
            body={(trip) => trip.dropOff?.address}
            filter={showFilter}
            filterPlaceholder="Search by dropoff"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Rider Id"
            field="trip.rider.userId"
            filterField="riderId"
            sortField="riderId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
            body={(trip) => {
              return (
                <Link
                  to={`/rider-details/details/${trip.rider?.id}`}
                  className="dataFnt-size"
                >
                  {trip.rider?.userId}
                </Link>
              );
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Driver Id"
            filterField="driverId"
            field="trip.driver.userId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
            body={(trip) => {
              return (
                <Link
                  to={`/rider-details/details/${trip.driverId}`}
                  className="dataFnt-size"
                >
                  {trip.driver?.userId}
                </Link>
              );
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Fare"
            field="tripBaseAmount"
            sortable
            body={(driver) => driver.tripBaseAmount?.toFixed(2)}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
          <Column
            className="text-center text-capitalize"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            body={(trip) =>
              tripStatus.find((trp) => trp.value === trip.status)?.label
            }
            filterElement={
              <Dropdown
                value={status}
                options={tripStatus}
                onChange={(event) => {
                  setStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDispatcherTrips();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(DispatchDetailTripTab));
