import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import SubscriptionsPackage from "../components/Subscriptions/SubscriptionsPackage";
import ExpiredSubscriptionsListingTable from "../components/Subscriptions/ExpiredSubscriptionsListingTable";
import DriverEarnings from "../components/accountings/DriverEarnings";
import Main from "../components/accountings/Main";
import DriverSubscription from "../components/accountings/DriverSubscription";
import Tax from "../components/accountings/Tax";
import Topups from "../components/accountings/Topups";
import Fees from "../components/accountings/Fees";
import APIService from "../services/APIService";
import { useDispatch } from 'react-redux';
import Ehsaan from "../components/accountings/Ehsaan";
let Accounting = (props) => {
  const dispatch = useDispatch();
  let [tab, setTab] = useState("activeSub"),
    [balance, setBalance] = useState(null),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));
  let getBalance = () => {

    dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    APIService.get("/admin/alinma-accounts/balance")
      .then((response) => {

        if (response.data && response.data.statusCode === 200) {
          setBalance(response.data.data)


          dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        }
      })
      .catch((error) => {
        dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      });
  }
  useEffect(() => {
    getActiveTab();
  }, []);
  useEffect(() => {
    getBalance();
  }, [tab, props]);
  let RiDETopup = `RiDE TopUp (${balance?.main?.currentBal} SAR)`
  let RiderTopup = `Rider-TopUp (${balance?.topup?.currentBal} SAR)`
  let vatTitle = `VAT (${balance?.vat?.currentBal} SAR)`
  let feeTitle = `Fees (${balance?.fee?.currentBal} SAR)`
  let topupTitle = `Driver withdrawal (${balance?.rideTopup?.currentBal} SAR)`
  let subscriptionTitle = `Subscriptions (${balance?.subscription
    ?.currentBal} SAR)`

  return (
    <>
      <div className="rider-listing">
        <span className="title">Accounting</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/accounting/${tab}`);
          }}
        >
          {permissions.find(a => a.includes('/accounting/topups-accounting')) &&
            <Tab eventKey="topups-accounting" title={RiDETopup}>
              {tab === "topups-accounting" && <Topups />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/earnings')) &&
            <Tab eventKey="earnings" title={RiderTopup}>
              {tab === "earnings" && <DriverEarnings />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/vat-accounting')) &&
            <Tab eventKey="vat-accounting" title={vatTitle}>
              {tab === "vat-accounting" && <Tax />}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/fees')) &&
            <Tab eventKey="fees" title={feeTitle}>
              {tab === "fees" && <Fees />}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/accountancy')) &&
            <Tab eventKey="accountancy" title={topupTitle}>
              {tab === "accountancy" && <Main />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/subscription-accounting')) &&
            <Tab eventKey="subscription-accounting" title={subscriptionTitle}>
              {tab === "subscription-accounting" && <DriverSubscription />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/ehsaan')) &&
            <Tab eventKey="ehsaan" title="Ehsaan">
              {tab === "ehsaan" && <Ehsaan />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Accounting);
