import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import saveAs from "save-as";
import APIService from "../services/APIService";
import DeleteModal from "../components/Common/DeleteModal";
import { Link } from "react-router-dom";
import { Button as PrimeButton } from "primereact/button";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select, { StylesConfig } from "react-select";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let DispatchListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [lastAccessAt, setLastAccessAt] = useState(null),
    [createdAt, setCreatedAt] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [showUpdate, setShowUpdate] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [dispatchers, setDispatchers] = React.useState([]),
    [status, setStatus] = useState(null),
    [selectedDispatchers, setSelectedDispatchers] = React.useState([]),
    [roles, setRoles] = useState([]),
    [updateData, setUpdateData] = useState({
      email: "",
      fullName: "",
      status: true,
    }),
    validationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Please enter email in proper format e.g. johndoes@hotmail.com")
        .required("Please enter your registered email"),
      fullName: Yup.string().required("Please enter name"),
      status: Yup.string().required("Select Status"),
    }),
    handleCloseUpdate = () => setShowUpdate(false),
    handleShowUpdate = () => setShowUpdate(true),
    handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setUpdateData(rowData);
      setShowDelete(true);
    },
    dt = useRef(null),
    statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ],
    getDispatchers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/dispatcher-admin")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            setDispatchers(response.data.data.results);
          }
        })
        .catch((error) =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    removeDispatcher = () => {
      setShowDelete(false);
      APIService.delete("admin/subadmin/" + updateData.id)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            toast.success("Dispatcher deleted successfully");
            getDispatchers();
          }
        })
        .catch(() => toast.error("Failed to delete Dispatcher"));
    },
    updateTableData = (rowData) => {
      let dispatcher = { ...rowData };
      dispatcher.status
        ? setStatus({ value: true, label: "Active" })
        : setStatus({ value: false, label: "Inactive" });
      setUpdateData(dispatcher);
    },
    exportDispatchers = () => {
      let dispatcher = [
        "Dispatcher Name",
        "Email",
        "Trip Created",
        "Last Access Date",
        "Status",
      ];
      dispatcher = dispatcher.join(",") + "\r\n";
      if (selectedDispatchers.length) {
        selectedDispatchers.forEach((dispatch) => {
          dispatcher +=
            [
              dispatch.fullName,
              dispatch.email,
              " " + moment(dispatch.createdAt).format("DD/MM/YYYY hh:mm A"),
              ,
              dispatch.lastAccessedAt
                ? " " +
                  moment(dispatch.lastAccessedAt).format("DD/MM/YYYY hh:mm A")
                : "",
              dispatch.status ? "Active" : "Inactive",
            ].join(",") + "\r\n";
        });
      } else {
        dispatchers.forEach((dispatch) => {
          dispatcher +=
            [
              dispatch.fullName,
              dispatch.email,
              " " + moment(dispatch.createdAt).format("DD/MM/YYYY hh:mm A"),
              dispatch.lastAccessedAt
                ? " " +
                  moment(dispatch.lastAccessedAt).format("DD/MM/YYYY hh:mm A")
                : "",
              dispatch.status ? "Active" : "Inactive",
            ].join(",") + "\r\n";
        });
      }
      saveAs(
        new Blob([dispatcher], {
          type: "text/csv;charset=utf-8",
        }),
        "Dispatchers-Reports.csv"
      );
    },
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={exportDispatchers}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLastAccessAt(null);
      setStatus(null);
    },
    createOrUpdateDispatch = (dispatcherInfo) => {
      handleCloseUpdate(false);
      dispatcherInfo.status = status.value;
      dispatcherInfo.role = roles.find(
        (role) => role.label === "Dispatcher Admin"
      ).value;
      APIService.patch("admin/subadmin/" + updateData.id, dispatcherInfo)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success("Dispatcher updated successfully");
            getDispatchers();
          } else {
            toast.error("Failed to update Dispatcher");
          }
          handleCloseUpdate();
        })
        .catch((error) => {
          handleCloseUpdate();
        });
    },
    getRoles = () => {
      APIService.get("admin/role").then((response) => {
        if (response.status === 200) {
          let roles = response.data.data.map((role) => {
            return {
              value: role.id,
              label: role.title,
            };
          });
          setRoles(roles);
        }
      });
    };
  useEffect(() => {
    getDispatchers();
    getRoles();
  }, []);
  let actionTemplate = (rowData) => {
    return (
      <div className="button p-table-button">
        <Link
          className="p-button p-component"
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
          to={`/dispatchers-details/${rowData.id}`}
        >
          <svg
            width="23"
            height="18"
            viewBox="0 0 23 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5002 0C16.8922 0 21.3782 3.88 22.3192 9C21.3792 14.12 16.8922 18 11.5002 18C6.10815 18 1.62215 14.12 0.681152 9C1.62115 3.88 6.10815 0 11.5002 0ZM11.5002 16C13.5396 15.9996 15.5185 15.3068 17.113 14.0352C18.7075 12.7635 19.8231 10.9883 20.2772 9C19.8214 7.0133 18.7051 5.24 17.1108 3.97003C15.5165 2.70005 13.5385 2.00853 11.5002 2.00853C9.46185 2.00853 7.48384 2.70005 5.88953 3.97003C4.29521 5.24 3.17892 7.0133 2.72315 9C3.17725 10.9883 4.29283 12.7635 5.8873 14.0352C7.48177 15.3068 9.46068 15.9996 11.5002 16ZM11.5002 13.5C10.3067 13.5 9.16209 13.0259 8.31817 12.182C7.47426 11.3381 7.00015 10.1935 7.00015 9C7.00015 7.80653 7.47426 6.66193 8.31817 5.81802C9.16209 4.97411 10.3067 4.5 11.5002 4.5C12.6936 4.5 13.8382 4.97411 14.6821 5.81802C15.526 6.66193 16.0002 7.80653 16.0002 9C16.0002 10.1935 15.526 11.3381 14.6821 12.182C13.8382 13.0259 12.6936 13.5 11.5002 13.5ZM11.5002 11.5C12.1632 11.5 12.7991 11.2366 13.2679 10.7678C13.7368 10.2989 14.0002 9.66304 14.0002 9C14.0002 8.33696 13.7368 7.70107 13.2679 7.23223C12.7991 6.76339 12.1632 6.5 11.5002 6.5C10.8371 6.5 10.2012 6.76339 9.73239 7.23223C9.26355 7.70107 9.00015 8.33696 9.00015 9C9.00015 9.66304 9.26355 10.2989 9.73239 10.7678C10.2012 11.2366 10.8371 11.5 11.5002 11.5Z"
              fill="#03053D"
            />
          </svg>
        </Link>
        <PrimeButton
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            handleShowUpdate();
            updateTableData(rowData);
          }}
        >
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
              fill="#03053D"
            />
          </svg>
        </PrimeButton>
        <PrimeButton
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            handleShowDelete(rowData);
          }}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
              fill="#03053D"
            />
          </svg>
        </PrimeButton>
      </div>
    );
  };

  return (
    <div className="rider-listing">
      <div>
        <span className="title">Dispatcher List</span>

        <div className="datatable-doc-demo mt-3">
          <div className="card">
            <DataTable
              ref={dt}
              value={dispatchers}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              scrollable
              scrollHeight="470px"
              paginator
              rows={25}
              emptyMessage="No Dispatcher(s) found"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10, 25, 50]}
              totalRecords={dispatchers.length}
              onSelectionChange={(e) => setSelectedDispatchers(e.value)}
              selection={selectedDispatchers}
              selectionMode="checkbox"
              loading={useSelector((state) => state.isGridLoader)}
              loadingIcon="fa fa-spinner"
              globalFilter={globalFilter}
            >
              <Column selectionMode="multiple" style={{ width: "3em" }} />
              <Column
                className="text-left"
                headerClassName="text-left"
                style={{ fontSize: "12px", width: "200px" }}
                field="fullName"
                header="Dispatcher name"
                filter={showFilter}
                sortable
                body={(rowData) => {
                  return (
                    <Link
                      to={`/dispatchers-details/${rowData.id}`}
                      className="dataFnt-size"
                    >
                      <span className="dataFnt-size">{rowData.fullName}</span>
                    </Link>
                  );
                }}
              />
              <Column
                className="text-left"
                headerClassName="text-left"
                style={{ fontSize: "12px", width: "250px" }}
                header="Email"
                field="email"
                sortable
                filter={showFilter}
                filterMatchMode="contains"
                filterPlaceholder="Search by email"
              />
              <Column
                className="text-center"
                headerClassName="text-center"
                style={{ fontSize: "12px", width: "200px" }}
                field="createdAt"
                body={(dispatcher) =>
                  moment(dispatcher.createdAt).format("DD/MM/YYYY hh:mm A")
                }
                header="Trips Created"
                sortable
                filter={showFilter}
                filterPlaceholder="Search by Trips created"
                filterMatchMode="custom"
                filterFunction={(value, filter) => {
                  return value &&
                    filter &&
                    moment(value).format("DD/MM/YYYY") ===
                      moment(filter).format("DD/MM/YYYY")
                    ? true
                    : false;
                }}
                filterElement={
                  <Calendar
                    appendTo={null}
                    value={createdAt}
                    onChange={(event) => {
                      setCreatedAt(event.value);
                      dt.current.filter(event.value, "createdAt", "custom");
                    }}
                    placeholder="Search Date"
                    dateFormat="dd-mm-yy"
                    className="p-column-filter"
                  />
                }
              />
              <Column
                className="text-center"
                style={{ fontSize: "12px", width: "200px" }}
                sortable
                header="Last Access Date"
                field="lastAccessedAt"
                filter={showFilter}
                body={(dispatcher) =>
                  dispatcher.lastAccessedAt
                    ? moment(dispatcher.lastAccessedAt).format(
                        "DD/MM/YYYY hh:mm A"
                      )
                    : ""
                }
                filterMatchMode="custom"
                filterFunction={(value, filter) =>
                  value &&
                  filter &&
                  moment(value).format("DD/MM/YYYY") ===
                    moment(filter).format("DD/MM/YYYY")
                    ? true
                    : false
                }
                filterElement={
                  <Calendar
                    appendTo={null}
                    value={lastAccessAt}
                    onChange={(event) => {
                      setLastAccessAt(event.value);
                      dt.current.filter(
                        event.value,
                        "lastAccessedAt",
                        "custom"
                      );
                    }}
                    placeholder="Search Date"
                    dateFormat="dd-mm-yy"
                    className="p-column-filter"
                  />
                }
              />
              <Column
                className="text-center"
                headerClassName="text-center"
                style={{ fontSize: "12px", width: "180px" }}
                field="status"
                header="Status"
                body={(dispatcher) =>
                  dispatcher.status ? "Active" : "Inactive"
                }
                sortable
                filter={showFilter}
                filterElement={
                  <Dropdown
                    value={status}
                    options={statusOptions}
                    onChange={(event) => {
                      dt.filter(event.value, "status", "equals");
                      setStatus(event.value);
                    }}
                    showClear
                    placeholder="Select Status"
                    className="p-column-filter"
                  />
                }
              />
              <Column
                className="text-center"
                style={{ fontSize: "12px", width: "200px" }}
                header="Action"
                headerClassName="text-center"
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </div>

      <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateDispatch}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    Update Dispatcher
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="fullName"
                      type="text"
                      name="fullName"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Dispatcher Name"
                    />
                    <label htmlFor="fullName">Dispatcher Name</label>
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group">
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      className="shadow-none form-control modal-input br-10 "
                      placeholder="email"
                      disabled
                    />
                    <label htmlFor="email">Email</label>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      className="height-50 br-10"
                      placeholder="Select status"
                      // isClearable
                      name="status"
                      styles={colourStyles}
                      id="status"
                      value={status}
                      onBlur={() => {
                        handleBlur({ target: { name: "status" } });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setStatus(e);
                          setFieldValue("status", e.value);
                        }
                      }}
                      options={statusOptions}
                    />
                    {!status ||
                      (!status?.value && (
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      ))}
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    className="btn-save save-button mb-3 border-radius-15"
                    type="submit"
                  >
                    Update
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleCloseUpdate}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <DeleteModal
        showDelete={showDelete}
        clickYes={removeDispatcher}
        handleCloseDelete={handleCloseDelete}
      />
    </div>
  );
};

export default React.memo(connect()(DispatchListing));
