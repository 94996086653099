import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import APIService from "../services/APIService";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../sass/NotificationsManage.sass";
import Select, { StylesConfig } from 'react-select';
import { Editor } from "primereact/editor";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled
        ? 'white'
        : isSelected
        ? "#28a745"
        : "#28a745",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },

};
let statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ],
  NotificationEmailEditPage = (props) => {
    let { id } = useParams(),
      [isBtnDisable, setIsBtnDisable] = useState(false),
      [receiverStatus, setReceiverStatus] = useState(null),
      [logStatus, setLogStatus] = useState(null),
      [template, setTemplate] = useState(null),
      getTemplates = () => {
        if (id) {
          props.dispatch({ type: "TOGGLE_LOADER", payload: true });
          APIService.get("admin/templates/email/" + id).then((response) => {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            if (response.status === 200) {
              let template = response.data.data;

              if (template.dataKeys !== "") {
                template.dataKeys = template.dataKeys;
              }
              for (let tKey in template) {
                if (template[tKey] === null) {
                  template[tKey] = "";
                }
              }
              if (!template.bodyArabic) {
                template.bodyArabicPlainText = "";
              } else {
                template.bodyArabicPlainText = convertHtmlToText(
                  template.bodyArabic
                );
              }
              if (!template.body) {
                template.bodyPlainText = "";
              } else {
                template.bodyPlainText = convertHtmlToText(template.body);
              }
              setTemplate(template);
              setSelectedStatus(
                statusOptions.find((sts) => sts.value === template.status)
              );
              setLogStatus(
                statusOptions.find(
                  (status) => status.value === template.logStatus
                )
              );
              if (template && template.receiver) {
                let NOTIFY_RECEIVER = ["Rider", "Driver", "Admin", "Other"];
                let receiverData =
                  NOTIFY_RECEIVER[template && template.receiver - 1];
                setReceiverStatus(receiverData);
              }
              setContentEnglish(template.body);
              setContentArabic(template.bodyArabic);
            }
          });
        } else {
          props.history.push("../notification-emails");
        }
      },
      convertHtmlToText = (str) => {
        return str
          .toString()
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "");
      },
      updateTemplate = (formValue) => {
        let updateData = { ...formValue };
        if (updateData.address.trim() === "") {
          delete updateData.address;
        }
        setIsBtnDisable(true);
        APIService.patch("admin/templates/email/" + id, updateData)
          .then((response) => {
            setIsBtnDisable(false);
            if (response.status === 200) {
              toast.success("Updated Successfully");
            }
          })
          .catch((error) => {
            setIsBtnDisable(false);
          });
      },
      validationSchema = Yup.object().shape({
        templateName: Yup.string()
          .required("Please enter Template name")
          .max(50, "Max 50 character are allowed."),
        templateNameArabic: Yup.string()
          .required("Please enter Arabic Template name")
          .max(50, "Max 50 character are allowed."),
        subject: Yup.string()
          .required("Please enter Subject")
          .max(50, "Max 50 character are allowed."),
        subjectArabic: Yup.string()
          .required("Please enter Arabic Subject")
          .max(50, "Max 50 character are allowed."),
        // bodyPlainText: Yup.string()
        //   .required("Please enter Content")
        //   .max(500, "Max 500 character are allowed."),
        // bodyArabicPlainText: Yup.string()
        //   .required("Please enter Arabic Content")
        //   .max(500, "Max 500 character are allowed."),
        status: Yup.string().required("Select Status"),
        logStatus: Yup.string().required("Select Log Status"),
      }),
      [selectedStatus, setSelectedStatus] = useState(null),
      [contentEnglish, setContentEnglish] = useState(""),
      [contentArabic, setContentArabic] = useState("");
    useEffect(() => {
      getTemplates();
    }, []);
    return (
      <div className="rider-listing notification-manage">
        <Link to="/notification-emails" title="Back">
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Update Email Notification</span>

        {template && (
          <Formik
            initialValues={{ ...template }}
            validationSchema={validationSchema}
            onSubmit={updateTemplate}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Card className="notification-card">
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Template Name
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              id="templateName"
                              type="text"
                              name="templateName"
                              className="shadow-none form-control input-box-text"
                            />
                            <ErrorMessage
                              name="templateName"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Template Name
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              id="templateNameArabic"
                              type="text"
                              name="templateNameArabic"
                              className="shadow-none form-control input-box-text"
                            />
                            <ErrorMessage
                              name="templateNameArabic"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Email Subject
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              id="subject"
                              type="text"
                              name="subject"
                              className="shadow-none form-control input-box-text"
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Email Subject
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              id="subjectArabic"
                              type="text"
                              name="subjectArabic"
                              className="shadow-none form-control input-box-text"
                            />
                            <ErrorMessage
                              name="subjectArabic"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Content
                            <img
                              alt="English"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-gb"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Editor
                              id="emailBody"
                              style={{ height: "320px" }}
                              value={contentEnglish}
                              name="bodyPlainText"
                              onTextChange={(e) => {
                                setFieldValue(
                                  "bodyPlainText",
                                  e.textValue.trim()
                                );
                                setFieldValue("body", e.htmlValue);
                                setContentEnglish(e.htmlValue);
                                handleBlur({
                                  target: { name: "bodyPlainText" },
                                });
                              }}
                            />

                            <ErrorMessage
                              name="bodyPlainText"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Content
                            <img
                              alt="Arabic"
                              src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                              className="flag flag-sa"
                              width={30}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Editor
                              style={{ height: "320px" }}
                              value={contentArabic}
                              name="bodyArabicPlainText"
                              onTextChange={(e) => {
                                setFieldValue(
                                  "bodyArabicPlainText",
                                  e.textValue.trim()
                                );
                                setFieldValue("bodyArabic", e.htmlValue);
                                setContentArabic(e.htmlValue);
                                handleBlur({
                                  target: { name: "bodyArabicPlainText" },
                                });
                              }}
                            />
                            <ErrorMessage
                              name="bodyArabicPlainText"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Receiver
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group form-label-group position-relative">
                            <Field
                              id="receiver"
                              type="text"
                              name="receiver"
                              disabled
                              className="shadow-none disable-input form-control input-box-text"
                              value={receiverStatus}
                            />
                            <ErrorMessage
                              name="receiver"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Status
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-4">
                            <Select
                              className="height-50 br-10"
                              placeholder="Select status"
                              name="status"
                              styles={colourStyles}
                              id="status"
                              onBlur={() => {
                                handleBlur({ target: { name: "status" } });
                              }}
                              onChange={(e) => {
                                if (e) {
                                  setFieldValue("status", e.value);
                                  setSelectedStatus(e);
                                }
                              }}
                              value={selectedStatus}
                              options={statusOptions}
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row className="px-2 mb-3 text-md-left">
                          <div className="notification-heading-text">
                            Log Status
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-4">
                            <Select
                              className="height-50 br-10"
                              placeholder="Select Status"
                              name="logStatus"
                              styles={colourStyles}
                              id="logStatus"
                              onBlur={() => {
                                handleBlur({ target: { name: "logStatus" } });
                              }}
                              onChange={(e) => {
                                if (e) {
                                  setFieldValue("logStatus", e.value);
                                  setLogStatus(e);
                                }
                              }}
                              value={logStatus}
                              options={statusOptions}
                            />
                          </div>
                          <ErrorMessage
                            name="logStatus"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Button
                      disabled={isBtnDisable}
                      variant="primary"
                      type="submit"
                      className="save-button btn-save border-radius-12 mt-5 ml-auto mr-auto"
                      style={{ width: "200px", textAlign: "center" }}
                    >
                      <span className="btn-text">Save Changes</span>
                    </Button>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  };

export default React.memo(connect()(NotificationEmailEditPage));
