import { connect, useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import React, { useEffect, useRef, useState } from "react";
import APIService from "../../services/APIService";
import { ListBox } from "primereact/listbox";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Input } from "@mui/material";

let FareSettingTab = (props) => {
  let [day, setDay] = useState(new Date().getDay()),
    [vehicleTypes, setVehicleTypes] = React.useState([]),
    [originalRows, setOriginalRows] = useState({}),
    [searchField,setSearchField]=useState([]),
    dt = useRef(null),
    urlParams = useParams(),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "cab.name",
        order: 1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [fareConfigations, setFareConfigations] = useState([]),
    [allFareConfigations, setAllFareConfigations] = useState([]),
    [filteredFareConfigations,setFilteredFareConfigations]=useState([]),
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={searchField}
              onChange={(e) => {
                let value = e.target.value
                setSearchField(value)
                handleSearchField(value) 
                // lazyParams = {
                //   ...lazyParams,
                //   skip: 0,
                //   take: 25,
                //   keyword: e.target.value,
                // };
                // setLazyParams(lazyParams);
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    getVehicleType = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/cab-type/all")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let vehicleTypes = response.data.data.filter((vt) => vt.status);
            setVehicleTypes(vehicleTypes);
            getCabCharges(vehicleTypes);
          }
        })
        .catch((error) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
        });
    },
    onEditorValueChange = (productKey, props, value) => {
      let updatedConf = [...props.value];
      updatedConf[props.rowIndex][props.field] = parseFloat(value);
      setFareConfigations(updatedConf);
    },
    onRowEditInit = (event) => {
      originalRows[event.index] = { ...fareConfigations[event.index] };
      setOriginalRows(originalRows);
    },
    onRowEditCancel = (event) => {
      let rawProducts = [...fareConfigations];
      rawProducts[event.index] = originalRows[event.index];
      delete originalRows[event.index];
      setOriginalRows(originalRows);
      setFareConfigations(rawProducts);
    },
    days = [
      { label: "Sunday", value: 0 },
      { label: "Monday", value: 1 },
      { label: "Tuesday", value: 2 },
      { label: "Wednesday", value: 3 },
      { label: "Thursday", value: 4 },
      { label: "Friday", value: 5 },
      { label: "Saturday", value: 6 },
    ],
    priceEditor = (productKey, props) => {
      return (
        <Input
          type="number"
          value={props.rowData[props.field]}
          onChange={(e) => onEditorValueChange(productKey, props, e.target.value)}
          locale="en-US"
          min={0}
          max={999}
        />
      );
    },
    getCabCharges = (vehicleTypes) => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/cab-charge-detail/" + urlParams.id)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data && response.data.statusCode === 200) {
            setAllFareConfigations(response.data.data);
            let cabs = response.data.data.filter((cc) => cc.day === day),
              existingCabTypes = cabs.map((cc) => cc.cab.id),
              remainingCabTypes = vehicleTypes.filter(
                (vt) => existingCabTypes.indexOf(vt.id) === -1
              );
            setFareConfigations([
              ...cabs,
              ...remainingCabTypes.map((rct) => {
                return {
                  day: day,
                  passengerBaseFare: rct.passengerBaseFare,
                  passengerCostPerMin: rct.passengerCostPerMin,
                  passengerCostPerKm: rct.passengerCostPerKm,
                  cab: {
                    id: rct.id,
                    name: rct.name,
                  },
                };
              }),
            ]);
            setFilteredFareConfigations([
              ...cabs,
              ...remainingCabTypes.map((rct) => {
                return {
                  day: day,
                  passengerBaseFare: rct.passengerBaseFare,
                  passengerCostPerMin: rct.passengerCostPerMin,
                  passengerCostPerKm: rct.passengerCostPerKm,
                  cab: {
                    id: rct.id,
                    name: rct.name,
                  },
                };
              }),
            ])
          }
        })
        .catch((error) =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDay = (e) => {
      setDay(e.value);
      let cabs = allFareConfigations.filter((cc) => cc.day === e.value),
        existingCabTypes = cabs.map((cc) => cc.cab.id),
        remainingCabTypes = vehicleTypes.filter(
          (vt) => existingCabTypes.indexOf(vt.id) === -1
        );

      setFareConfigations([
        ...cabs,
        ...remainingCabTypes.map((rct) => {
          return {
            day: day,
            passengerBaseFare: rct.passengerBaseFare,
            passengerCostPerMin: rct.passengerCostPerMin,
            passengerCostPerKm: rct.passengerCostPerKm,
            cab: {
              id: rct.id,
              name: rct.name,
            },
          };
        }),
      ]);
      setFilteredFareConfigations([
        ...cabs,
        ...remainingCabTypes.map((rct) => {
          return {
            day: day,
            passengerBaseFare: rct.passengerBaseFare,
            passengerCostPerMin: rct.passengerCostPerMin,
            passengerCostPerKm: rct.passengerCostPerKm,
            cab: {
              id: rct.id,
              name: rct.name,
            },
          };
        }),
      ])
    },
    createOrUpdateConf = (event) => {
      let conf = event.data;
      conf.cabId = conf.cab.id;
      conf.city = urlParams.id;
      conf.country = new URLSearchParams(window.location.search).get("cid");
      conf.day = day;
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      if (conf.id) {
        APIService.put("admin/cab-charge/" + conf.id, conf)
          .then((response) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            if (response.data && response.data.statusCode === 200) {
              toast.success("Fare configuration updated successfully");
            }
            getCabCharges(vehicleTypes);
          })
          .catch((error) => {
            getCabCharges(vehicleTypes);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      } else {
        APIService.post("admin/cab-charge", conf)
          .then((response) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            if (response.data && response.data.statusCode === 200) {
              toast.success("Fare configuration created successfully");
            }
            getCabCharges(vehicleTypes);
          })
          .catch((error) => {
            getCabCharges(vehicleTypes);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    };

    const handleSearchField = (searchValue) => {
      const result = filteredFareConfigations.filter((item) => {
        return searchNested(item, searchValue);
      });
      setFareConfigations(result);
    };
    
    function searchNested(item, searchValue) {
      if (typeof item !== 'object' || item === null) {
        return false; // Exclude non-object values from the search
      }
    
      const propertiesToSearch = [
        'passengerBaseFare',
        'passengerCostPerMin',
        'passengerCostPerKm',
        'cab.name',
      ];
    
      return propertiesToSearch.some((property) => {
        const nestedValue = getProperty(item, property);
        if (nestedValue !== undefined && nestedValue !== null) {
          const stringValue = nestedValue.toString().toLowerCase();
          return stringValue.includes(searchValue.toLowerCase());
        }
        return false;
      });
    }
    
    function getProperty(obj, property) {
      const keys = property.split('.');
      return keys.reduce((value, key) => (value && value[key] !== undefined) ? value[key] : undefined, obj);
    }
    
  useEffect(() => {
    getVehicleType();
  }, []);
  return (
    <div className=" row">
      <div className="mt-3 col-12 col-lg-3 pr-lg-0">
        <ListBox value={day} options={days} onChange={manageDay} />
      </div>

      <div className={`datatable-doc-demo mt-3 col-12 col-lg-9 `}>
        <div className="card">
          <DataTable
            ref={dt}
            value={fareConfigations}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Fare Configuration(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
            editMode="row"
            onRowEditInit={onRowEditInit}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={createOrUpdateConf}
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "180px" }}
              header="Vehicle Category"
              field="cab.name"
              sortable
              filter={false}
              filterPlaceholder="Search by Vehicle Type"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "160px" }}
              header="Base Fare (SAR)"
              field="passengerBaseFare"
              sortable
              filter={false}
              filterPlaceholder="Search by base fare"
              editor={(props) => priceEditor("baseFare", props)}
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Charge/KM (SAR)"
              field="passengerCostPerKm"
              sortable
              filter={false}
              filterPlaceholder="Search by charge/km"
              editor={(props) => priceEditor("chargeperKM", props)}
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "175px" }}
              header="Charge/Min (SAR)"
              field="passengerCostPerMin"
              sortable
              filter={false}
              filterPlaceholder="Search by charge/min"
              editor={(props) => priceEditor("chargeperMin", props)}
            />
            <Column
              rowEditor
              className="text-center"
              style={{ fontSize: "12px", width: "100px" }}
              header="Action"
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default React.memo(connect()(FareSettingTab));
