import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import { connect, useSelector } from "react-redux";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";

import APIService from "../../services/APIService";

let DriverRatingListing = (props) => {
  let [ratings, setRatings] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [globalFilter, setGlobalFilter] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState([]),
    [status, setStatus] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    tripStatus = [
      { label: "Pending", value: 1 },
      { label: "Accepted by driver", value: 2 },
      { label: "Rejected by driver", value: 3 },
      { label: "Cancelled by driver", value: 4 },
      { label: "Driver arrived", value: 5 },
      { label: "Cancelled by rider", value: 6 },
      { label: "Started", value: 7 },
      { label: "Completed", value: 8 },
      { label: "No driver", value: 9 },
      { label: "Expired", value: 10 },
    ],
    getDriverRatings = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/captains-rating", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setRatings(response.data.data.trips);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDriverRating = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDriverRatings();
    };

  useEffect(() => {
    getDriverRatings();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getDriverRatings();
    },
    actionTemplate = (rating) => {
      return (
        <div className="button p-table-button">
          <Link
            tooltip="View"
            tooltipOptions={{ position: "bottom" }}
            className="p-button p-component"
            to={"../trip-details/" + rating.id}
          >
            <svg
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5002 0C16.8922 0 21.3782 3.88 22.3192 9C21.3792 14.12 16.8922 18 11.5002 18C6.10815 18 1.62215 14.12 0.681152 9C1.62115 3.88 6.10815 0 11.5002 0ZM11.5002 16C13.5396 15.9996 15.5185 15.3068 17.113 14.0352C18.7075 12.7635 19.8231 10.9883 20.2772 9C19.8214 7.0133 18.7051 5.24 17.1108 3.97003C15.5165 2.70005 13.5385 2.00853 11.5002 2.00853C9.46185 2.00853 7.48384 2.70005 5.88953 3.97003C4.29521 5.24 3.17892 7.0133 2.72315 9C3.17725 10.9883 4.29283 12.7635 5.8873 14.0352C7.48177 15.3068 9.46068 15.9996 11.5002 16ZM11.5002 13.5C10.3067 13.5 9.16209 13.0259 8.31817 12.182C7.47426 11.3381 7.00015 10.1935 7.00015 9C7.00015 7.80653 7.47426 6.66193 8.31817 5.81802C9.16209 4.97411 10.3067 4.5 11.5002 4.5C12.6936 4.5 13.8382 4.97411 14.6821 5.81802C15.526 6.66193 16.0002 7.80653 16.0002 9C16.0002 10.1935 15.526 11.3381 14.6821 12.182C13.8382 13.0259 12.6936 13.5 11.5002 13.5ZM11.5002 11.5C12.1632 11.5 12.7991 11.2366 13.2679 10.7678C13.7368 10.2989 14.0002 9.66304 14.0002 9C14.0002 8.33696 13.7368 7.70107 13.2679 7.23223C12.7991 6.76339 12.1632 6.5 11.5002 6.5C10.8371 6.5 10.2012 6.76339 9.73239 7.23223C9.26355 7.70107 9.00015 8.33696 9.00015 9C9.00015 9.66304 9.26355 10.2989 9.73239 10.7678C10.2012 11.2366 10.8371 11.5 11.5002 11.5Z"
                fill="#03053D"
              />
            </svg>
          </Link>
        </div>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={ratings}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          paginator
          emptyMessage="No Driver Rating(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageDriverRating}
          onSort={manageDriverRating}
          onFilter={manageDriverRating}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Trip Id"
            body={(trip) => {
              return (
                <Link
                  to={"../trip-details/" + trip.id}
                  className="dataFnt-size"
                >
                  {trip.tripNo}
                </Link>
              );
            }}
            sortable
            field="tripNo"
            sortField="tripNo"
            filter={showFilter}
            filterPlaceholder="Search by id"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & time"
            field="createdAt"
            sortField="createdAt"
            sortable
            body={(rating) =>
              moment(rating.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            filter={showFilter}
            filterMatchMode="custom"
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getDriverRatings();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getDriverRatings();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            field="pickup.address"
            sortField="pickup"
            filterField="pickup"
            header="Pick Up"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by pickup"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            field="dropoff.address"
            sortField="dropoff"
            filterField="dropoff"
            header="Drop off"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by dropOff"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Driver name"
            field="driver.fullName"
            sortField="driverName"
            filterField="driverName"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by driver"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Rider name"
            field="rider.fullName"
            sortField="riderName"
            filterField="riderName"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            header="Rating"
            body={(rating) =>
              rating.overallRating > 3 ? "🙂 Good" : "😞 Bad"
              // <Rating
              //   className="ratings pl-0 d-flex justify-content-center"
              //   fractions={2}
              //   emptySymbol="fa fa-star-o fa-2x star"
              //   fullSymbol="fa fa-star fa-2x star"
              //   initialRating={rating.driverReview}
              //   readonly
              // />
            }
            sortable
            field="driverReview"
            filter={false}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="status"
            sortField="status"
            filterField="status"
            header="Status"
            sortable
            body={(rating) =>
              tripStatus.find((sts) => sts.value === rating.status)?.label
            }
            filter={showFilter}
            filterElement={
              <Dropdown
                value={status}
                options={tripStatus}
                onChange={(event) => {
                  setStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }

                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDriverRatings();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(DriverRatingListing));
