/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "react-google-maps";

class MarkerMap extends React.Component {
  state = {
    directions: null,
    error: null,
  };

  componentDidMount() {
    const { places, travelMode } = this.props;

    const waypoints =
      places &&
      places.map((p) => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: true,
      }));
    const origin = waypoints && waypoints.shift().location;
    const destination = waypoints && waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>;
    }
    return (
      this.state.directions && (
        <DirectionsRenderer directions={this.state.directions} />
      )
    );
  }
}

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
    >
      {props &&
        props.markers &&
        props.markers.map((marker, index) => {
          const position = { lat: marker.latitude, lng: marker.longitude };
          return (
            <Marker
              key={"marker-" + index}
              position={position}
              onClick={() => props.setIsOpen(marker.id)}
            >
              {props.isOpen && props.windowId === marker.id && (
                <InfoWindow>
                  <span>
                    {marker.driverName}- {marker.mobileNo}
                  </span>
                </InfoWindow>
              )}
            </Marker>
          );
        })}

      {/* <MapDirectionsRenderer
        places={props.markers}
        travelMode={google.maps.TravelMode.DRIVING}
      /> */}
    </GoogleMap>
  ))
);

export default Map;
