import React, { useState } from "react";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import logo from "../../assest/images/riderLogo.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select, { StylesConfig } from "react-select";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import { _baseURL,secret_key } from "../../services/APIService";


let RiderInfo = (props) => {
  const { riderInfo, userStatusRider } = props;
  const _saveTicketURL = _baseURL + "/api/v1/ticket/save";
  const colourStyles: StylesConfig<ColourOption, true> = {
    // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#fff"
          : isFocused
          ? "white"
          : undefined,
        color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "white"
            : undefined,
        },
      };
    },
  };
  let ticketType = [
      { value: 1, label: "Ride Cancellation" },
      { value: 2, label: "Subscription Issue" },
      { value: 3, label: "Payment Not Received" },
    ],
    [showTicket, setShowTicket] = useState(false);
  const [submitting, isSubmitting] = useState(false);

  let assignType = [
    { value: 1, label: "Rider" },
    // { value: 2, label: "Driver" },
  ];
  let [ticketSelectionType, setSelectionType] = useState({
    value: 1,
    label: "Rider",
  });
  let handleCloseTicket = () => setShowTicket(false);
  let handleShowTicket = () => {
    setShowTicket(true);
  };
  return (
    <>
      <div className="  mt-3 d-box">
        <div style={{ display: "flex" }}>
          <h2 className="box-title mb-0">Rider</h2>
          <button className="btn btn-success ml-3" onClick={handleShowTicket}>
            Create Ticket
          </button>
        </div>
        <Row className="mt-24 mx-0">
          <Col xl={12} className="px-0">
            <div className="d-flex align-items-center">
              <div>
                {riderInfo?.profileImage ? (
                  <img
                    src={`${riderInfo?.profileImage}`}
                    alt="ride"
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={riderAvtSec}
                    alt="ride"
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
              <div>
                <div className="title-text-detail">
                  <div className="detail-list-1-name">{`${riderInfo?.firstName} ${riderInfo?.lastName}`}</div>
                  <div className="d-flex flex-wrap mt-1 align-items-baseline">
                    <div className="detail-list-1-id ">
                      {/* {`#${rideViewData?.userId}`}#{riderInfo?.userId} */}
                      <Link
                        to={"/rider-details/details/" + riderInfo?.id}
                        className="dataFnt-size"
                        // style={{ color: "rgba(19, 181, 66, 1)" }}
                      >
                        <span
                          className="dataFnt-size"
                          // style={{ color: "#13B542" }}
                        >
                          #{riderInfo?.userId}
                        </span>
                      </Link>
                    </div>
                    <div>
                      {userStatusRider?.onlineStatus ? (
                        <span className="status-text">Online</span>
                      ) : (
                        <span className="status-text-inactive">Offline</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mx-0 mt-24 inner-box">
          <Col md={12} className="px-0">
            <div className="card-mt-13 ">
              <div className="card-label-13">Rating</div>
              <div className="card-label-14 text-dark font-weight-bolder">
                {riderInfo?.overallRating > 3 ? "🙂 Good" : "😞 Bad"}
              </div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">ID</div>
              <div className="card-label-14 text-dark font-weight-bolder">
              {riderInfo?.userId  ? riderInfo?.userId : "--"}
              </div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">DOB</div>
              <div className="card-label-14 text-dark font-weight-bolder">
                --
              </div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Loyalty points</div>
              <div className="card-label-14 text-dark font-weight-bolder">
                --
              </div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Is captain?</div>
              <div className="card-label-14 text-success font-weight-bolder">
                --
              </div>
            </div>
            <div className="card-mt-13 mt-2">
              <div className="card-label-13">Is pickup captain?</div>
              <div className="card-label-14 text-dark font-weight-bolder">
                --
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        className="Create-Ticket"
        show={showTicket}
        onHide={handleCloseTicket}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={{
            ticket_type: "",
            name: "",
            phone: "",
            comment: "",
            assign_type: "",
          }}
          onSubmit={async (values) => {
            isSubmitting(true);
            let dept_id = values.ticket_type;
            let description = values.comment;
            let name = riderInfo?.fullName;
            let phone = riderInfo?.userId;
            let assign_type = ticketSelectionType.value;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                category_id: dept_id,
                description: description,
                secret_key: secret_key,
                name: name,
                contact_no: phone,
                type: assign_type,
              }),
            };
            const response = await fetch(_saveTicketURL, requestOptions);
            let final_data = await response.json();
            if (final_data.success === true)
              toast.success("Ticket created successfully!");
            else toast.error("Opsss! Ticket not created.");
            isSubmitting(false);
            handleCloseTicket();
          }}
        >
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Create New Ticket
                  </Modal.Title>
                  <div className="divclose" onClick={handleCloseTicket}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Ticket Type"
                          name="ticket_type"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "ticket_type" } });
                          }}
                          onChange={async (selectedOption) =>
                            await setFieldValue(
                              "ticket_type",
                              selectedOption.value
                            )
                          }
                          options={ticketType}
                        />
                        <label>Ticket Type</label>
                        <ErrorMessage
                          name="ticket_type"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Ticket Type"
                          name="assign_type"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "assign_type" } });
                          }}
                          // onChange={async (selectedOption) =>
                          //   await setFieldValue(
                          //     "assign_type",
                          //     selectedOption.value
                          //   )
                          // }
                          disabled
                          // options={assignType}
                          // setFieldValue()
                          value={ticketSelectionType}
                        />
                        <label>User Type</label>
                        <ErrorMessage
                          name="assign_type"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="text"
                          name="name"
                          value={riderInfo?.fullName}
                          disabled
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Name"
                        />
                        <label htmlFor="content">Name</label>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="number"
                          name="phone"
                          value={riderInfo?.userId}
                          disabled
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Phone"
                        />
                        <label htmlFor="content">User Id</label>
                        <ErrorMessage
                          name="Phone"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="text"
                          as="textarea"
                          name="comment"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Name"
                        />
                        <label htmlFor="content">Comment</label>
                        <ErrorMessage
                          name="comment"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Button
                        disabled={submitting}
                        variant="primary"
                        type="submit"
                        className="btn-save btn-block"
                      >
                        {submitting && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        Create Ticket
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(RiderInfo);
