/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  DirectionsRenderer,
} from "react-google-maps";

class MapDirectionsRenderer extends React.Component {
  state = {
    directions: null,
    error: null,
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let { places, travelMode } = this.props;

    let waypoints =
      places &&
      places.map((p) => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: false,
      }));
    let origin = waypoints && waypoints.shift().location;
    let destination = waypoints && waypoints.pop().location;
    let ways =
      waypoints.length <= 25
        ? waypoints
        : [
            waypoints[0],
            ...waypoints.slice(2, 25),
            waypoints[waypoints.length - 1],
          ];

    let directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: ways,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          // window.location.pathname = "/trips";
          this.setState({ error: result });
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <h1
          style={{
            zIndex: 1000,
            fontSize: "20px",
            marginTop: "-400px",
            position: "absolute",
            color: "rgb(239, 66, 66)",
          }}
        >
          {this.state?.error?.status}
        </h1>
      );
    }
    return (
      this.state.directions && (
        <DirectionsRenderer directions={this.state.directions} />
      )
    );
  }
}

let Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
      options={{
        styles: [{ stylers: [{ saturation: 30 }, { gamma: 0.9 }] }],
      }}
    >
      <MapDirectionsRenderer
        places={props.markers}
        travelMode={google.maps.TravelMode.DRIVING}
      />
    </GoogleMap>
  ))
);

export default React.memo(Map);
