import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { BoxArrowLeft, Person } from "react-bootstrap-icons";
import { useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { EyeFill, EyeSlashFill, PencilSquare } from "react-bootstrap-icons";
import Logoicon from "../UI/Logoicon";
import logo from "../../assest/images/ride.svg";
import notify from "../../assest/images/notify.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";
import "../../sass/Profile.sass";

let Footer = ({ isOpen, openSidebar, history, dispatch }) => {
  const [show, setShow] = useState(false);
  let email = localStorage.getItem("email"),
    role = localStorage.getItem("role"),
    nameFormValidation = Yup.object().shape({
      fullName: Yup.string().required("Please enter name"),
    }),
    [fullName, setFullname] = useState(localStorage.getItem("fullName")),
    [imgData, setImageData] = useState(localStorage.getItem("profileImage")),
    [reloadKey, setReloadKey] = useState(null),
    [showPassword, setShowPassword] = useState(false),
    [updatePassword,setUpdatePassword]=useState(false),
    [showPasswordModal, setShowPasswordModal] = useState(false),
    [showOldPassword, setShowOldPassword] = useState(false),
    [showNewPassword, setShowNewPassword] = useState(false),
    [showConfirmPassword, setShowConfirmPassword] = useState(false),
    validationPasswordSchema = Yup.object().shape({
      oldPassword: Yup.string()
        .required("Please enter your old password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters"),
      newPassword: Yup.string()
        .required("Please enter your password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        .matches(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
          "atleast one capital, one number and one special symbol"
        ),
      confirmPassword: Yup.string()
        .required("Please enter your confirm password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        // .matches(
        //   /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
        //   "Min 8 and Max 15 character with at least (one capital, one special symbol and one number"
        // )
        .when("newPassword", {
          is: (password) => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Both password need to be the same"
          ),
        }),
    }),
    handleClosePassword = () => {
      setShowPasswordModal(false);
      setShowPassword(false);
    },
    handleShowPassword = () => {
      setShow(false);
      setShowPasswordModal(true);
    },
    handleCloseUpdatedPassword = () => {
      setUpdatePassword(false);
      
    },
    handleUpdatedPassword = () => {
      setShow(false);
      setShowPasswordModal(true);
    },
    updateName = (updateInfo) => {
      APIService.put("admin/update-profile/", { fullName: updateInfo.fullName })
        .then((response) => {
          handleClose();
          if (response.data && response.data.statusCode === 200) {
            localStorage.setItem("fullName", response.data.data.fullName);
            dispatch({
              type: "USER_PROFILE",
              payload: {
                fullName: response.data.data.fullName,
                profileImage: localStorage.getItem("profileImage"),
              },
            });
            setFullname(response.data.data.fullName);
            toast.success("Name changed successfully");
          }
        })
        .catch(() => {
          handleClose();
        });
    },
    updateProfileImage = (e) => {
      dispatch({ type: "TOGGLE_LOADER", payload: true });
      let formData = new FormData();
      formData.append("profileImage", e.target.files[0]);
      APIService.put("admin/update-picture", formData).then((response) => {
      
        if (response.data && response.data.statusCode === 200) {
          dispatch({ type: "TOGGLE_LOADER", payload: false });
          let profileImageUrl = response.data.data.profileImageUrl;
          setImageData(profileImageUrl);
          // window.location.reload();
          dispatch({
            type: "USER_PROFILE",
            payload: {
              fullName: response.data.data.fullName,
              profileImage: profileImageUrl,
            },
          });
          localStorage.setItem("profileImage", profileImageUrl);
          toast.success(response.data.message);
        }
      });
    },
    changePassword = (passwordForm) => {
      APIService.put("admin/change-password", passwordForm)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message);
            setUpdatePassword(true)
            
          }
          setShowPasswordModal(false);
        })
        .catch((error) => {
          setShowPasswordModal(false);
        });
    };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <footer>
      <nav className={isOpen ? "navbar nav__fixed" : "navbar"}>
        <div className="left">
          <div
            className="d-flex align-items-center ml-15 pointer"
            onClick={handleShow}
          >
            <div className="user-photo-sidebar">
              {imgData ? (
                <img
                  className="img-fluid"
                  src={
                    imgData
                      ? imgData
                      : process.env.PUBLIC_URL + `/images/user.png`
                  }
                  alt="profile_photo"
                  onError={(e) =>
                    (e.target.src = imgData
                      ? imgData
                      : process.env.PUBLIC_URL + `/images/user.png`)
                  }
                />
                
              ) : null}

            </div>
            <div className="user-info-sidebar ml-12">
              <h3 className="user-name">{fullName}</h3>
              <p className="user-email">{email}</p>
            </div>
          </div>

          <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            {/* <Formik
                  initialValues={{ ...updateData }}
                  validationSchema={validationSchema}
                  onSubmit={createOrUpdateAdmin}
                > */}
            <Modal.Header>
              <Modal.Title className="modal-title">Profile</Modal.Title>
              <div className="divclose" onClick={handleClose}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className="mt-24">
              <Row>
                <Col xl={12} className="text-center">
                  <div className="p-0">
                    <img
                      className="img-fluid"
                      src="/images/background-modal.jpg"
                      alt=""
                    />
                  </div>
                  {imgData ? (
                    <div className="position-relative">
                      <img
                        className="user-modal-img"
                        src={
                          imgData
                            ? imgData
                            : process.env.PUBLIC_URL + `/images/user.png`
                        }
                        alt="profile_photo"
                        onError={(e) =>
                          (e.target.src = imgData
                            ? imgData
                            : process.env.PUBLIC_URL + `/images/user.png`)
                        }
                      />
                      <div
                        className="position-absolute bottom-0"
                        style={{ right: "39%" }}
                      >
                        <input type="file" name="file" accept="image/*" onChange={(e)=>updateProfileImage(e)}/>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="15"
                            cy="15"
                            r="13.5"
                            fill="#13B542"
                            stroke="white"
                            stroke-width="3"
                          />
                          <path
                            d="M20.7137 12.2795C20.7142 12.2042 20.6998 12.1297 20.6713 12.0601C20.6429 11.9905 20.601 11.9271 20.548 11.8737L18.1252 9.45088C18.0718 9.39792 18.0084 9.35602 17.9388 9.32758C17.8692 9.29915 17.7947 9.28473 17.7195 9.28517C17.6442 9.28473 17.5697 9.29915 17.5001 9.32758C17.4305 9.35602 17.3671 9.39792 17.3137 9.45088L15.6966 11.068L9.45088 17.3137C9.39792 17.3671 9.35602 17.4305 9.32758 17.5001C9.29915 17.5697 9.28473 17.6442 9.28517 17.7195V20.1423C9.28517 20.2939 9.34537 20.4392 9.45253 20.5464C9.5597 20.6535 9.70504 20.7137 9.85659 20.7137H12.2795C12.3594 20.7181 12.4394 20.7056 12.5142 20.677C12.589 20.6485 12.657 20.6045 12.7137 20.548L18.9252 14.3023L20.548 12.7137C20.6002 12.6584 20.6427 12.5946 20.6737 12.5252C20.6792 12.4796 20.6792 12.4336 20.6737 12.388C20.6764 12.3614 20.6764 12.3346 20.6737 12.308L20.7137 12.2795ZM12.0452 19.5709H10.428V17.9537L16.1023 12.2795L17.7195 13.8966L12.0452 19.5709ZM18.5252 13.0909L16.908 11.4737L17.7195 10.668L19.3309 12.2795L18.5252 13.0909Z"
                            fill="#F4F4F4"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : null}
                  <h2 className="username-modal mt-2">{fullName}</h2>
                  <p className="useremail-modal">{email}</p>
                </Col>
                <Col xl={12} className="my-32">
                  <button
                    className="btn btn-block btn-theme"
                    onClick={() => {
                      localStorage.clear();
                      history.push("/login");
                    }}
                  >
                    Log Out
                  </button>
                  <button
                    className="btn btn-block btn-color-theme"
                    onClick={handleShowPassword}
                  >
                    Change Password
                  </button>
                </Col>
              </Row>
            </Modal.Body>

            {/* </Formik> */}
          </Modal>
          <Modal show={showPasswordModal} onHide={handleClosePassword} centered  backdrop="static" keyboard={false} className="modal-height">
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationPasswordSchema}
              onSubmit={changePassword}
            >
              {() => {
                return (
                  <Form>
                     <Modal.Header>
              <Modal.Title className="modal-title">Update Password</Modal.Title>
              <div className="divclose" onClick={handleClosePassword}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </Modal.Header>
                    
                    <Modal.Body className="mt-24">
                      <Row>
                        <Col xl={12}> 
                      <div className="form-group form-label-group">
                        <Field
                          id="oldPassword"
                          type={showOldPassword ? "text" : "password"}
                          name="oldPassword"
                          className="shadow-none form-control modal-input new_password"
                          placeholder="Old Password"
                        />
                        <label htmlFor="oldPassword">Old Password</label>
                        {showOldPassword ? (
                          <EyeFill
                            className="eye-position"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          />
                        ) : (
                          <EyeSlashFill
                            className="eye-position"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          />
                        )}
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                        </Col>
                           <Col xl={12}>
                        <div className="form-group form-label-group">
                        <Field
                          id="newPassword"
                          type={showNewPassword ? "text" : "password"}
                          name="newPassword"
                          className="shadow-none form-control modal-input"
                          placeholder="New Password"
                        />
                        <label htmlFor="newPassword">New Password</label>
                        {showNewPassword ? (
                          <EyeFill
                            className="eye-position"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        ) : (
                          <EyeSlashFill
                            className="eye-position"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        )}
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                          </Col>
                          <Col xl={12}>
   
                          <div className="form-group form-label-group">
                        <Field
                          id="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          className="shadow-none form-control modal-input"
                          placeholder="Confirm New Password"
                        />
                        <label htmlFor="confirmPassword">
                          Confirm New Password
                        </label>
                        {showConfirmPassword ? (
                          <EyeFill
                            className="eye-position"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        ) : (
                          <EyeSlashFill
                            className="eye-position"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        )}
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                          </Col>
                          <Col xl={12} className="mm-top">
                              <button
                         value="Submit"
                         className="btn btn-block btn-success btn-lg ">
                        Update
                      </button>
                          </Col>
                      </Row> 
                    </Modal.Body> 
                  </Form>
                );
              }}
            </Formik>
          </Modal> 
          <Modal show={updatePassword} onHide={handleCloseUpdatedPassword} centered  backdrop="static" keyboard={false} className="modal-height modal-vc">
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationPasswordSchema}
              onSubmit={changePassword}
            >
              {() => {
                return (
                  <Form>
                <Modal.Header>
                    <Modal.Title className="modal-title"> </Modal.Title>
                    <div className="divclose" onClick={handleCloseUpdatedPassword}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                </Modal.Header>
                    
                    <Modal.Body className="customheight" style={{ height : "492px !important", minHeight: "492px !important", display : 'flex' , alignItems : "center" , justifyContent : "center" }} >
                      <Row>
                        <Col xl={12} className="text-center my-auto custom-success"> 
                        <svg className="custom-svg" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                          <g stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <path className="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                            <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                          </g>
                        </svg>
                            <h2 className="success-message mt-24">
                            Password updated <br /> 
                             successfully.
                            </h2>
                            <Button
                            variant="primary"
                            className="btn-save btn-block"
                            // onClick={handleClick}
                          >
                            Try Again
                          </Button>
                        </Col>  
                      </Row> 
                    </Modal.Body> 
                  </Form>
                );
              }}
            </Formik>
          </Modal>
         
        </div>
        <div className="right">
        
        </div>
      </nav>
    </footer>
  );
};

export default React.memo(connect()(withRouter(Footer)));
