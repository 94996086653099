import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import SubscriptionsPackage from "../components/Subscriptions/SubscriptionsPackage";
import ExpiredSubscriptionsListingTable from "../components/Subscriptions/ExpiredSubscriptionsListingTable";

let Subscriptions = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);
  return (
    <>
      <div className="rider-listing">
        <span className="title">Subscriptions</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/subscriptions/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}

          {
            permissions.find(a => a.includes('/subscriptions/own-a-car')) &&
            <Tab eventKey="own-a-car" title="Own a car">
              {tab === "own-a-car" && <SubscriptionsPackage />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/subscriptions/ride-a-ride')) &&
            <Tab eventKey="ride-a-ride" title="Ride a ride">
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/subscriptions/Rider')) &&
            <Tab eventKey="Rider" title="Rider">
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Subscriptions);
