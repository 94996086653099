import React from "react";
import ReportsTripCancelledByRiderListing from "../components/Reports/ReportsTripCancelledByRiderListing";

let ReportsTripCancelledByRider = () => {
  return (
    <>
      <div className="rider-listing">
        <span className="title">Cancelled Trips by Rider Report</span>
        <ReportsTripCancelledByRiderListing />
      </div>
    </>
  );
};

export default React.memo(ReportsTripCancelledByRider);
