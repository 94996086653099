import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from 'react-select';
import Map from "./Map";
import APIService from "../../services/APIService";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled
        ? 'white'
        : isSelected
        ? "#28a745"
        : "#28a745",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },

};
let EmergencyRequestDetail = () => {
  let [tripStatus, setTripStatus] = useState(null),
    [show, setShow] = useState(false),
    [status, setStatus] = useState(null),
    tripStatuses = [
      { value: 1, label: "Ongoing" },
      { value: 2, label: "Stop & Completed" },
      { value: 3, label: "Assigned" },
    ],
    statusOptions = [
      { label: "Open", value: 1 },
      { label: "Resolved", value: 2 },
    ],
    googleMapsApiKey = "AIzaSyBIj2uM9ahOB-MuD0os0pLHoKq_FfaPMc4",
    handleShow = () => setShow(true),
    handleClose = () => setShow(false),
    urlParams = useParams(),
    [request, setRequest] = useState(null),
    validationSchema = Yup.object().shape({
      remarks: Yup.string().required("Please enter Cancel Reason"),
    }),
    updateRequest = (formFields) => {
      formFields.tripStatus = status.value;
      formFields.issueStatus = tripStatus.value;
      APIService.patch(
        "admin/update-emergency-request/" + urlParams.id,
        formFields
      ).then((response) => {
        handleClose();
        if (response.status === 200) {
          toast.success(response.data.data.message);
        }
      });
    },
    getEmergencyRequest = () => {
      APIService.get("admin/emergency-request/" + urlParams.id).then(
        (response) => {
          if (response.status === 200) {
            setRequest(response.data.data.request);
            setTripStatus(
              tripStatuses.find(
                (ts) => ts.value === response.data.data.request.tripStatus
              )
            );
            setStatus(
              statusOptions.find(
                (ts) => ts.value === response.data.data.request.issueStatus
              )
            );
          }
        }
      );
    };
  React.useEffect(() => {
    getEmergencyRequest();
  }, []);
  return (
    <>
      {request && (
        <div className="create-emergency">
          <Link to="/emergency-request" title="Back">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Emergency Request Details</span>
          <Card className="mt-3">
            <Row>
              <h6 className="title-head">Rider & Trip Detail</h6>
              <Col md={6} className="pl-5 pr-4 mb-3">
                <Row className="mt-4">
                  <Col md={2}>
                    <label className="profilelabel">Trip Id:</label>
                    <label className="trip-txt float-left ">
                      <b>#{request.trip?.tripNo}</b>
                    </label>
                  </Col>
                  {request.trip.rider?.userId && (
                    <Col md={5} className="mrg-left-10">
                      <div>
                        <label className="profilelabel">Rider:</label>
                      </div>
                      <div>
                        <label className="trip-txt ">
                          <b>#{request.trip.rider.userId} </b>
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt ">
                          <b> {request.trip.rider.fullName}</b>
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt ">
                          <b>{request.trip.rider.mobileNo}</b>
                        </label>
                      </div>
                    </Col>
                  )}
                  {request.trip.driver?.driverNationalId && (
                    <Col md={4}>
                      <div>
                        <label className="profilelabel">Driver:</label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>#{request.trip.driver.driverNationalId}</b>
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>
                            {request.trip.driver.firstName +
                              " " +
                              request.trip.driver.lastName}
                          </b>
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>{request.trip.driver.mobileNo}</b>
                        </label>
                      </div>
                    </Col>
                  )}
                </Row>

                <Row className="mt-4">
                  <Col md={8}>
                    <Row>
                      <div>
                        <label className="profilelabel">
                          Current Location:
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>{request.location}</b>
                        </label>
                      </div>
                    </Row>
                    <Row>
                      <div>
                        <label className="profilelabel">
                          Emergency Request Location:
                        </label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>{request.trip?.pickup?.address}</b>
                        </label>
                      </div>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <div>
                        <label className="profilelabel">Resolved By:</label>
                      </div>
                      <div>
                        <label className="trip-txt float-left ">
                          <b>{request.resolvedBy}</b>
                        </label>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="pdlr-5-mb-3">
                <Row className="mt-4">
                  <Col md={6}>
                    <div>
                      <label className="profilelabel">Emergency Reason:</label>
                    </div>
                    <div>
                      <label className="trip-txt float-left ">
                        <b>{request.reason}</b>
                      </label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div>
                      <label className="profilelabel">Trip Route:</label>
                    </div>
                    <Row>
                      <div className="disp-flex">
                        <div>
                          <div className="sizeLenght mt-2"></div>
                          <div className="size-height"></div>
                          <div className="sizeLenght"></div>
                        </div>
                        <Col md={12}>
                          <div>
                            <label className="trip-txt fnt-size-9 float-left ">
                              {request.trip.pickup?.address}
                            </label>
                          </div>
                          <br />
                          <div>
                            <label className="trip-txt fnt-size-9 float-left ">
                              {request.trip.dropoff?.address}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Row>
                      <div className="floatRight">
                        <label className="profilelabel">Trip Status:</label>
                      </div>
                      <div className="floatRight">
                        <Select
                          className="height-10 br-10"
                          placeholder="Select status"
                          name="status"
                          styles={colourStyles}
                          id="status"
                          onChange={(e) => {
                            if (e) {
                              setTripStatus(e);
                            }
                          }}
                          value={tripStatus}
                          options={tripStatuses}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <div className="floatRight">
                        <label className="profilelabel">Issue Status:</label>
                      </div>
                      <div className="floatRight">
                        <Select
                          className="height-10 br-10"
                          placeholder="Select status"
                          name="status"
                          styles={colourStyles}
                          id="status"
                          onChange={(e) => {
                            if (e) {
                              setStatus(e);
                            }
                          }}
                          value={status}
                          options={statusOptions}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Row>
                    <div className=" my-4 mrpd-12 d-flex flex-wrap align-items-center justify-content-end">
                      <Button
                        variant="primary"
                        className="mr-3"
                        onClick={handleShow}
                      >
                        UPDATE
                      </Button>
                      <Link
                        to="/emergency-request"
                        className="mrt-0 btn btn-secondary"
                      >
                        CANCEL
                      </Link>
                    </div>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Card>
          <div className="mt-">
            <Card className="card-map">
              <Map
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  googleMapsApiKey +
                  "&libraries=geometry,drawing,places"
                }
                markers={[request.trip.pickup, request.trip.dropoff]}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: "80vh" }} />}
                mapElement={<div style={{ height: `100%` }} />}
                defaultCenter={{
                  lat: request.trip.pickup?.latitude,
                  lng: request.trip.pickup?.longitude,
                }}
                defaultZoom={11}
              />
            </Card>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Formik
          initialValues={{ remarks: "" }}
          validationSchema={validationSchema}
          onSubmit={updateRequest}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">
                Please provide the valuable reason to resolved the issue
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="padding-header-footer">
              <div className="form-group form-label-group">
                <Field
                  name="remarks"
                  type="textarea"
                  rows="10"
                  cols="10"
                  className="shadow-none form-control modal-input br-10"
                  placeholder="Enter Reason..."
                  id="remarks"
                />
                <label htmlFor="remarks">Enter Reason...</label>
                <ErrorMessage
                  name="remarks"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="padding-header-footer">
              <Button
                variant="primary"
                type="submit"
                className="btn-save save-button mb-3 border-radius-15"
              >
                Done
              </Button>

              <Button
                variant="secondary"
                className="btn-cancel save-button mb-3 border-radius-15"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(connect()(EmergencyRequestDetail));
