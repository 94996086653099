import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import APIService from "../../services/APIService";

let SMSNotificationListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [notificationData, setNotificationData] = useState(null);
  let [selectedCustomers, setSelectedCustomers] = useState(null);
  let [globalFilter, setGlobalFilter] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/notification/notification-sms/')),
    updatePermission = currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update'),
    dt = useRef(null),
    [receiver, setReceiver] = useState(null),
    receivers = ["Rider", "Driver", "Admin", "Other"],
    [receiversFilter, setReceiversFilter] = useState([]),
    getNotifications = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get(`admin/templates/sms`)
        .then((response) => {
          let receiversData = [];
          if (response.data && response.data.statusCode === 200) {
            let notificationData = response.data.data.map((notification) => {
              receiversData.push(receivers[notification.receiver - 1]);
              notification.status = notification.status ? "Active" : "Inactive";
              notification.receiver = receivers[notification.receiver - 1];
              return notification;
            });
            receiversData.length &&
              receiversData.sort(function (a, b) {
                return receivers.indexOf(a) - receivers.indexOf(b);
              });
            let unique = receiversData.filter(
              (item, i, ar) => ar.indexOf(item) === i
            );
            setReceiversFilter([...unique, ...receiversFilter]);
            setNotificationData(notificationData);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    };

  useEffect(() => {
    getNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let statuses = ["Active", "Inactive"];

  let onStatusChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatus(e.value);
  };

  let statusFilter = (
    <Dropdown
      value={selectedStatus}
      options={statuses}
      onChange={onStatusChange}
      placeholder="Status"
      className="p-column-filter"
      showClear
    />
  );

  let renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </span>
        <span>
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              <i className="fa fa-filter"></i>
            ) : showFilter ? (
              "Hide Filter"
            ) : (
              "Show Filter"
            )}
          </button>
        </span>
      </div>
    );
  };

  let header = renderHeader();

  let toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  let actionTemplate = (rowData) => {
    return (
      <div className="button p-table-button">
        {updatePermission ? <Button
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
          onClick={() =>
            props.history.push(`/notification-sms-edit/${rowData.id}`)
          }
        >
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
              fill="#03053D"
            />
          </svg>
        </Button> : <span>No Permission</span>}
      </div>
    );
  };

  let updateDataAPIcall = (e, id) => {
    APIService.patch(`admin/templates/sms-status/${id}`, {
      status: e.target.checked,
    })
      .then((response) => {
        if (response && response.data.statusCode === 200) {
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      });
  };

  let activeActionTemplate = (rowData) => {
    return (
      <div>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`customSwitch${rowData.id}`}
            defaultChecked={rowData.status === "Active" ? true : false}
            onChange={(e) => updateDataAPIcall(e, rowData.id)}
            disabled={!updatePermission}
          />
          <label
            className="custom-control-label"
            style={{ cursor: "pointer" }}
            for={`customSwitch${rowData.id}`}
          ></label>
        </div>
      </div>
    );
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={notificationData}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          paginator
          rows={25}
          emptyMessage="No Notification(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            style={{ fontSize: "12px", width: "270px" }}
            header="Template Name"
            field="templateName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by title"
          />
          <Column
            style={{ fontSize: "12px", width: "120px" }}
            header="Receiver"
            field="receiver"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by receiver"
            filterElement={
              <Dropdown
                value={receiver}
                options={receiversFilter}
                onChange={(e) => {
                  dt.current.filter(e.value, "receiver", "equals");
                  setReceiver(e.value);
                }}
                placeholder="Select a Receiver"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Status"
            field="status"
            sortable
            filter={showFilter}
            filterElement={statusFilter}
            body={activeActionTemplate}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
            filterPlaceholder="Minimum"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(
  compose(withRouter, connect())(SMSNotificationListing)
);
