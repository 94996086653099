import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import APIService from "../../services/APIService";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Rating from "react-rating";
import "../../sass/Profile.sass";
import { InputSwitch } from "primereact/inputswitch";
let UserDetail = (props) => {
  let [status, setStatus] = useState(false),
    [user, setUser] = useState(null),
    validationSchema = Yup.object().shape({
      reason: Yup.string()
        .trim("Please remove whitespaces")
        .strict(false)
        .required("Please enter reason")
        .nullable(),
    }),
    getUserDetail = () => {
      APIService.get("admin/chat-user-detail/" + props.user).then(
        (response) => {
          if (response.data && response.data.statusCode === 200) {
            setUser(response.data.data);
          }
        }
      );
    },
    inactiveUser = (formValues) => {
      formValues["status"] = user.status === 1 ? 0 : 1;
      APIService.patch("admin/chat-user-status/" + props.user, formValues).then(
        (response) => {
          if (response.data && response.data.statusCode === 200) {
            toast.success("User updated successfully");
            getUserDetail();
            setStatus(false);
          }
        }
      );
    };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      {user && (
        <Row className="mt-3 driver-details px-3 row-flex">
          <Col xl={9} className="px-0">
            <Card className="py-3 h-100">
              <Row className="px-3">
                <Col md={6} className="border-right">
                  <Row>
                    <Col md={4}>
                      <div className="txtcenter">
                        <img
                          src={user.profileImage}
                          className="profileimg"
                          alt="profile"
                        />
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="mrtl-5 txtcenter">
                        <h5 className="fnt-name">{user.firstName}</h5>
                        <div className="disp-flex align-items-center mrl-40">
                          {user.status === 0 ? (
                            <div className="text-danger">
                              <div className="active-fnt">Inactive</div>
                            </div>
                          ) : (
                            <div className="items-inactive">
                              <div className="active-fnt">Active</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="flex-column flex-md-row">
                    <Row className="px-3">
                      <Col
                        md={4}
                        className="px-3 d-flex flex-column justify-content-center align-items-center align-items-lg-start"
                      >
                        <span className="heading-rating">{user.rating}</span>
                        <Rating
                          className="ratings pl-0"
                          fractions={2}
                          emptySymbol="fa fa-star-o fa-lg star"
                          fullSymbol="fa fa-star fa-lg star"
                          initialRating={user.rating}
                          readonly
                        />
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col md={6} className="pl-5 pr-5">
                  <Row className="mt-4">
                    <Col md={5} className="px-0">
                      <label className="profilelabel">Full Name</label>
                    </Col>
                    <Col md={7}>
                      <label className="profiletxt">
                        {user.firstName + " " + user.lastName}
                      </label>
                    </Col>
                    <div className="bdr-top"></div>
                  </Row>
                  <Row className="mt-4">
                    <Col md={5} className="px-0">
                      <label className="profilelabel">Mobile No.</label>
                    </Col>
                    <Col md={7}>
                      <label className="profiletxt">{user.mobileNo}</label>
                    </Col>
                    <div className="bdr-top"></div>
                  </Row>
                  <Row className="mt-4">
                    <Col md={5} className="px-0">
                      <label className="profilelabel">Email ID</label>
                    </Col>
                    <Col md={7}>
                      <label className="profiletxt">{user.emailId}</label>
                    </Col>
                    <div className="bdr-top"></div>
                  </Row>
                  <Row className="mt-4">
                    <Col md={5} className="px-0">
                      <label className="profilelabel">Status</label>
                    </Col>
                    <Col md={7}>
                      <label className="profiletxt">
                        <InputSwitch
                          checked={!user.status ? false : true}
                          onChange={(e) => setStatus(true)}
                        />
                      </label>
                    </Col>
                    <div className="bdr-top"></div>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xl={3} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
            <Card className="py-3 total__trip__card h-100">
              <Card.Title>
                <h6>
                  Stats{" "}
                  <b>
                    {user.totalChat +
                      user.totalGroup +
                      user.totalLocation +
                      user.totalMedia +
                      user.totalVideo}
                  </b>
                </h6>
              </Card.Title>
              <Card.Body className="p-0">
                <div className="py-3 px-2 d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <circle cx="20" cy="20" r="20" fill="#F9FDFB"></circle>
                      <path
                        fill="#03053D"
                        stroke="#0C9453"
                        strokeWidth="1.4"
                        d="M17.505 23.667l.495.495.495-.495 8.697-8.698.425.424L18 25.01l-5.374-5.374.424-.424 4.455 4.455z"
                      ></path>
                    </svg>
                    <p className="mb-0 ml-2 trip__text">In Groups </p>
                  </div>
                  <p className="mb-0 count__text">{user.totalGroup}</p>
                </div>
                <div className="py-3 px-2 d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <circle cx="20" cy="20" r="20" fill="#F9FDFB"></circle>
                      <path
                        fill="#03053D"
                        stroke="#0C9453"
                        strokeWidth="1.4"
                        d="M17.505 23.667l.495.495.495-.495 8.697-8.698.425.424L18 25.01l-5.374-5.374.424-.424 4.455 4.455z"
                      ></path>
                    </svg>
                    <p className="mb-0 ml-2 trip__text">Total Chat </p>
                  </div>
                  <p className="mb-0 count__text">{user.totalChat}</p>
                </div>
                <div className="py-3 px-2 d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <circle cx="20" cy="20" r="20" fill="#F9FDFB"></circle>
                      <path
                        fill="#03053D"
                        stroke="#0C9453"
                        strokeWidth="1.4"
                        d="M17.505 23.667l.495.495.495-.495 8.697-8.698.425.424L18 25.01l-5.374-5.374.424-.424 4.455 4.455z"
                      ></path>
                    </svg>
                    <p className="mb-0 ml-2 trip__text">Total Audio/Video </p>
                  </div>
                  <p className="mb-0 count__text">{user.totalVideo}</p>
                </div>
                <div className="py-3 px-2 d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <circle cx="20" cy="20" r="20" fill="#F9FDFB"></circle>
                      <path
                        fill="#03053D"
                        stroke="#0C9453"
                        strokeWidth="1.4"
                        d="M17.505 23.667l.495.495.495-.495 8.697-8.698.425.424L18 25.01l-5.374-5.374.424-.424 4.455 4.455z"
                      ></path>
                    </svg>
                    <p className="mb-0 ml-2 trip__text">Total Media </p>
                  </div>
                  <p className="mb-0 count__text">{user.totalMedia}</p>
                </div>
                <div className="py-3 px-2 d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <circle cx="20" cy="20" r="20" fill="#F9FDFB"></circle>
                      <path
                        fill="#03053D"
                        stroke="#0C9453"
                        strokeWidth="1.4"
                        d="M17.505 23.667l.495.495.495-.495 8.697-8.698.425.424L18 25.01l-5.374-5.374.424-.424 4.455 4.455z"
                      ></path>
                    </svg>
                    <p className="mb-0 ml-2 trip__text">
                      Total Location Shared{" "}
                    </p>
                  </div>
                  <p className="mb-0 count__text">
                    <p className="mb-0 count__text">{user.totalLocation}</p>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Modal show={status} onHide={() => setStatus(false)} centered>
            <Formik
              initialValues={{
                reason: "",
              }}
              validationSchema={validationSchema}
              onSubmit={inactiveUser}
            >
              {() => {
                return (
                  <Form>
                    <Modal.Header closeButton>
                      <Modal.Title className="title">
                        Are you sure you want to{" "}
                        {user.status ? "block" : "unblock"} this user?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding-header-footer">
                      {status ? (
                        <div className="form-group form-label-group mt-1">
                          <Field
                            id="reason"
                            type="text"
                            name="reason"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Enter Reason"
                          />
                          <label htmlFor="reason">Enter Reason</label>
                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      ) : null}
                    </Modal.Body>
                    <Modal.Footer className="padding-header-footer mb-3">
                      <Button
                        variant="primary"
                        type="submit"
                        className="save-button btn-save border-radius-12"
                      >
                        <span className="btn-text">
                          {user.status ? "Block" : "Unblock"}{" "}
                        </span>
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        </Row>
      )}
    </>
  );
};

export default React.memo(connect()(UserDetail));
