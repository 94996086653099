import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap"
import EmailNotificationListing from "../components/PromoNotificationDetail/EmailNotificationListing";
import PushNotificationListing from "../components/PromoNotificationDetail/PushNotificationListing";
import SMSNotificationListing from "../components/PromoNotificationDetail/SMSNotificationListing";
import { Link } from "react-router-dom";

const NotificationList = (props) => {
  let loc = useLocation()
  console.log(loc)
  const { state } = loc
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);
  return (
    <div className="rider-listing">
      <span style={{ cursor: "pointer" }}>
        {
          <Link
            to={"/markeeting/notifications"}
            className="dataFnt-size"
            style={{ color: "rgba(19, 181, 66, 1)" }}
          >
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
        }
      </span>
      <span className="title">Notification Detail</span>

      <Tabs
        id="controlled-tab-example"
        className="mt-30"
        activeKey={tab}
        onSelect={(tab) => {
          setTab(tab);
          props.history.push({ pathname: `/notification-detail/${tab}`, state: state });
        }}
      >
        <Tab eventKey="push" title="Push">
          {tab === "push" && <PushNotificationListing id={state} />}
        </Tab>
        <Tab eventKey="email" title="Email">
          {tab === "email" && <EmailNotificationListing id={state} />}
        </Tab>
        <Tab eventKey="sms" title="SMS">
          {tab === "sms" && <SMSNotificationListing id={state} />}
        </Tab>
      </Tabs>
    </div>
  )
}

export default NotificationList
