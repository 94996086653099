// EmptyTripsChart.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";

let PickupEmptyTripsChart = (props) => {
  const { captainData } = props;

  let [totalTrips, setTotalTrips] = useState([]),
    [totalActiveDriver, setTotalActiveDriver] = useState(""),
    windowWidth = window.innerWidth,
    chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      type: "doughnut",
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      cutout: windowWidth > 992 ? 105 : 105,
      pointStyle: "circle",

      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
          position: windowWidth > 992 ? "bottom" : "bottom",
          labels: {
            boxWidth: 100,
            usePointStyle: true,
            fontSize: 60,
          },
        },
      },
    };
  useEffect(() => {
    activeDriverChart();
  }, [props.tripsData]);

  let activeDriverChart = () => {
    let driverData = JSON.parse(
      JSON.stringify(
        !props.tripsData.activeDriverCard
          ? props.tripsData
          : props.tripsData.activeDriverCard
      )
        .split("onRides")
        .join("onride")
    );
    setTotalTrips(driverData.graphList);
    setTotalActiveDriver(driverData.total);
  };

  useEffect(() => {
    if (totalTrips.length > 0) {
      let key = [];
      let value = [];
      for (let item of totalTrips) {
        key.push(item && item.key);
        value.push(item && item.value);
      }
      setTotalTrips({
        labels: key,
        datasets: [
          {
            label: "Drivers",
            data: value,
            backgroundColor: ["rgba(241, 241, 241, 1)"],
            hoverOffset: 4,
          },
        ],
      });
    }
  });

  return (
    <>
      <Card className="drivers h-100 d-box">
        <div className="head d-lg-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <div>
            <h2 className="box-title">
              Total Trips: &nbsp;
              <span
                style={{
                  color: "#000000",
                  fontWeight: 600,
                  fontFamily: "'Manrope', sans-serif !important",
                }}
              >
               0
              </span>
            </h2>
          </div>
        </div>
        <Card.Body className="p-0 mt-24">
          <Doughnut
            data={totalTrips}
            width={null}
            height={windowWidth > 992 ? 150 : null}
            options={chartOptions}
          />

          {/* <div className="view-report">
            <Link to="/reports-drivers">View Report</Link>
          </div> */}
        </Card.Body>
        <div className="mt-24 d-flex align-items-baseline justify-content-between flex-wrap">
        <div style={{ display: "flex" }}>
            <div className="green-box"></div>
            <div className="ml-2">
              <div className="trips-title-label">Completed</div>
              <div className="trips-count ml-3" style={{ color: "#000" }}>
                0
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="red-box"></div>
            <div className="ml-2">
              <div className="trips-title-label-cancelled">Cancelled</div>
              <div className="trips-count" style={{ color: "#000" }}>
                {" "}
                0
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="yellow-box"></div>
            <div className="ml-2">
              <div className="trips-title-label-declined">Declined</div>
              <div className="trips-count" style={{ color: "#000" }}>
                {" "}
                0
              </div>
            </div>
          </div>
       
        </div>
      </Card>
    </>
  );
};

export default React.memo(connect()(PickupEmptyTripsChart));
