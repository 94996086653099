import React, { useState, useEffect, useRef } from "react";
import { CalendarRange } from "react-bootstrap-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import moment from "moment";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
let ActiveSubscriptionsListingTable = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [startDate, setStartDate] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    [activeSubscriptions, setActiveSubscriptions] = useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "startDate",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    getActiveSubscriptions = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      delete filterParams["totalRecords"];
      APIService.post("admin/active-subscriptions", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setActiveSubscriptions(response.data.data.subscriptions);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageActiveSubscription = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getActiveSubscriptions();
    };
 
  const template = {
    layout:
      "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ",
  };

  useEffect(() => {
    getActiveSubscriptions();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getActiveSubscriptions();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setStartDate([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "startDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
      });
    };

  return (
  

    <div className="d-box-subscription mt-3">
      <div className="table-header">
        <span className="toggle-filter">
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
            showFilter ? (
              <>
                <i className="fa fa-filter"></i>{" "}
                <span className="span-filter">Filters</span>
                <i className="fa fa-caret-up ml-100"></i>
              </>
            ) : (
              <>
                <i className="fa fa-filter"></i>{" "}
                <span className="span-filter">Filters</span>
                <i className="fa fa-caret-down ml-100"></i>
              </>
            )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            value={lazyParams.keyword}
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              getActiveSubscriptions();
            }}
          />
        </span>
      </div>
      <div className="row mt-3">
        <div className="col-12 ">
          {/* <!-- Nav pills --> */}
          <ul className="nav nav-pills subscription-tabs">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="pill" href="#all">
                All
              </a>
            </li>
           
          </ul>
          <div className="tab-content  ">
            <div className="tab-pane tab-pane-custom active" id="all">
              <div className="row  mx-0">
                {activeSubscriptions
                  ? activeSubscriptions.map((item, index) => (
                      <div className="col-lg-6" key={index}>
                        <div className="card-custom">
                          <div className="row row-grid">
                            <div className="col-6 col-lg-3">
                              <p className="sub-heading"> Sr. No. </p>
                              <p className="sub-stats">{index + 1} </p>
                            </div>
                            <div className="col-6 col-lg-3">
                              <p className="sub-heading"> Description </p>
                              <p className="sub-stats">-- </p>
                            </div>
                            <div className="col-6 col-lg-3">
                              <p className="sub-heading"> Amount </p>
                              <p className="sub-stats">
                                {" "}
                                {item?.subscription.subscriptionAmount} SAR.
                              </p>
                            </div>
                            <div className="col-6 col-lg-3 ">
                              <p className="sub-heading"> Subs. Validity </p>
                              <p className="sub-stats">
                                {" "}
                                {item.subscription.package.packageName}
                                
                              </p>
                            </div>
                            <div className="col-6 col-lg-3 ">
                              <p className="sub-heading"> Total KMs </p>
                              <p className="sub-stats"> -- </p>
                            </div>
                            <div className="col-6 col-lg-3 ">
                              <p className="sub-heading"> Start date </p>
                              <p className="sub-stats">
                                {" "}
                                {item.subscription.startDate
                                  ? moment(item.subscription.startDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "--"}
                              </p>
                            </div>
                            <div className="col-6 col-lg-3 ">
                              <p className="sub-heading"> End date </p>
                              <p className="sub-stats">
                                {item.subscription.endDate
                                  ? moment(item.subscription.endDate).format(
                                      "DD/MM/YYYY"
                                    )
                                    
                                  : "--"}
                              </p>
                            </div>
                            <div className="col-6 col-lg-3 ">
                              <p className="sub-heading"> Status </p>
                              <a href="#" className="badge badge-success">
                                {" "}
                                Active{" "}
                              </a>
                            </div>
                          </div>
                          <div className="line-break"></div>
                          <div className="row justify-content-between">
                            <div className="col-md-7">
                              <p className="sub-heading"> Promo/Discount</p>
                              <p className="sub-stats">
                                {" "}
                                --
                                {/* 10% of Ride fare maximum for 3 rides{" "} */}
                              </p>
                            </div>
                            <div className="col-md-5 text-md-right my-auto">
                              <Link to="#" className="link-success">
                                View Subscribers
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : "No subscription found"}

              </div>
            </div>
            <div className="tab-pane tab-pane-custom fade" id="active">
              Active
            </div>
            <div className="tab-pane tab-pane-custom fade" id="inactive">
              Inactive
            </div>
            <div className="tab-pane tab-pane-custom fade" id="pending">
              Pending
            </div>
            <div className="tab-pane tab-pane-custom fade" id="approval">
              Approval
            </div>
            <div className="tab-pane tab-pane-custom fade" id="review">
              Review
            </div>
          </div>
        </div>
      </div>

      <Paginator
        template={template}
        first={lazyParams.skip}
        rows={lazyParams.take}
        totalRecords={lazyParams.totalRecords}
        rowsPerPageOptions={[5,10, 25, 50]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        //  onPageChange={manageActiveSubscription}
      ></Paginator>
    </div>
  );
};

export default React.memo(connect()(ActiveSubscriptionsListingTable));
