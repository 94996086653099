import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";

let UserAuditLog = (props) => {
  let [statusLogs, setStatusLogs] = useState([]),
    [createdAt, setCreatedAt] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    getUserLogs = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/chat-user-audit-log/" + props.user, filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 201) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setStatusLogs(response.data.data.results);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageUserLogs = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getUserLogs();
    };

  useEffect(() => {
    getUserLogs();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getUserLogs();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader();

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={statusLogs}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Log(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageUserLogs}
          onSort={manageUserLogs}
          onFilter={manageUserLogs}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            sortable
            body={(log) => moment(log.createdAt).format("DD/MM/YYYY hh:mm A")}
            filter={false}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Old Status"
            field="oldValues.status"
            sortable
            body={(log) => (log.oldValues?.status ? "Unblocked" : "Blocked")}
            sortField="oldValue"
            filterField="oldValue"
            filter={false}
            filterPlaceholder="Search using old value"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="New Status"
            field="newValues.status"
            body={(log) => (log.newValues?.status ? "Unblocked" : "Blocked")}
            sortable
            filter={false}
            filterField="newValue"
            filterPlaceholder="Search using new values"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Reason"
            field="newValues.reason"
            body={(log) => log.newValues?.reason || "N/A"}
            sortable
            filter={false}
            filterField="reason"
            filterPlaceholder="Search by reason"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(UserAuditLog));
