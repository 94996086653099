import React, { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { EyeFill, EyeSlashFill, PencilSquare } from "react-bootstrap-icons";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "../sass/Profile.sass";

let Profile = (props) => {
  let email = localStorage.getItem("email"),
    role = localStorage.getItem("role"),
    nameFormValidation = Yup.object().shape({
      fullName: Yup.string().required("Please enter name"),
    }),
    [fullName, setFullname] = useState(localStorage.getItem("fullName")),
    [imgData, setImageData] = useState(localStorage.getItem("profileImage")),
    [reloadKey, setReloadKey] = useState(null),
    [show, setShow] = useState(false),
    [showPassword, setShowPassword] = useState(false),
    [showPasswordModal, setShowPasswordModal] = useState(false),
    [showOldPassword, setShowOldPassword] = useState(false),
    [showNewPassword, setShowNewPassword] = useState(false),
    [showConfirmPassword, setShowConfirmPassword] = useState(false),
    validationPasswordSchema = Yup.object().shape({
      oldPassword: Yup.string()
        .required("Please enter your old password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Min 8 and Max 15 character with at least (one capital, one special symbol and one number"
        ),
      newPassword: Yup.string()
        .required("Please enter your password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Min 8 and Max 15 character with at least (one capital, one special symbol and one number"
        ),
      confirmPassword: Yup.string()
        .required("Please enter your confirm password")
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password not more than 15 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Min 8 and Max 15 character with at least (one capital, one special symbol and one number"
        )
        .when("newPassword", {
          is: (password) => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Both password need to be the same"
          ),
        }),
    }),
    handleClose = () => setShow(false),
    handleShow = () => setShow(true),
    handleClosePassword = () => {
      setShowPasswordModal(false);
      setShowPassword(false);
    },
    handleShowPassword = () => setShowPasswordModal(true),
    updateName = (updateInfo) => {
      APIService.put("admin/update-profile/", { fullName: updateInfo.fullName })
        .then((response) => {
          handleClose();
          if (response.data && response.data.statusCode === 200) {
            localStorage.setItem("fullName", response.data.data.fullName);
            props.dispatch({
              type: "USER_PROFILE",
              payload: {
                fullName: response.data.data.fullName,
                profileImage: localStorage.getItem("profileImage"),
              },
            });
            setFullname(response.data.data.fullName);
            toast.success("Name changed successfully");
          }
        })
        .catch(() => {
          handleClose();
        });
    },
    updateProfileImage = (e) => {
      props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      let formData = new FormData();
      formData.append("profileImage", e.target.files[0]);
      APIService.put("admin/update-picture", formData).then((response) => {
        if (response.data && response.data.statusCode === 200) {
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          let profileImageUrl = response.data.data.profileImageUrl;
          setImageData(profileImageUrl);
          // window.location.reload();
          props.dispatch({
            type: "USER_PROFILE",
            payload: {
              fullName: response.data.data.fullName,
              profileImage: profileImageUrl,
            },
          });
          localStorage.setItem("profileImage", profileImageUrl);
          toast.success(response.data.message);
        }
      });
    },
    changePassword = (passwordForm) => {
      APIService.put("admin/change-password", passwordForm)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message);
          }
          setShowPasswordModal(false);
        })
        .catch((error) => {
          setShowPasswordModal(false);
        });
    };

  return (
    <div className="p-4">
      <p className="profile-title mb-30">My Profile</p>
      <Row className="mt-4 driver-details px-3 row-flex">
        <Col md={8} className="px-0">
          <Card className="py-3 h-100">
            <Row className="px-3">
              <Col
                md={5}
                className="border-right  d-flex justify-content-center align-items-center flex-column"
              >
                <div>
                  {imgData ? (
                    <img
                      className="avatar mx-auto"
                      src={
                        imgData
                          ? imgData
                          : process.env.PUBLIC_URL + `/images/user.png`
                      }
                      alt="profile_photo"
                      onError={(e) =>
                        (e.target.src = imgData
                          ? imgData
                          : process.env.PUBLIC_URL + `/images/user.png`)
                      }
                    />
                  ) : null}
                </div>
                <div className="fileUpload mb-5">
                  <input
                    type="file"
                    className="upload"
                    accept="image/*"
                    onChange={(e) => updateProfileImage(e)}
                  />
                  <span>Upload</span>
                </div>
              </Col>
              <Col md={7}>
                <div>
                  <Row className="px-2 mb-3 text-md-left">
                    <div className="heading-text">Full Name</div>
                  </Row>
                  <Row>
                    <div className="form-group form-label-group">
                      <input
                        disabled
                        id="fullName"
                        type="text"
                        name="fullName"
                        className="fullName__input shadow-none form-control profile-input-width input-box-text"
                        value={fullName}
                      />
                      <span
                        className="input-box-icon input-edit-icon"
                        onClick={handleShow}
                      >
                        <PencilSquare />
                      </span>
                    </div>
                  </Row>
                  <Row className="px-2 mb-3 text-md-left">
                    <div className="heading-text">Email</div>
                  </Row>
                  <Row>
                    <div className="form-group form-label-group">
                      <input
                        disabled
                        id="fullName"
                        type="text"
                        name="fullName"
                        className="shadow-none form-control profile-input-width input-box-text"
                        value={email}
                      />
                    </div>
                  </Row>
                  <Row className="px-2 mb-3 text-md-left">
                    <div className="heading-text">Role</div>
                  </Row>
                  <Row>
                    <div className="form-group form-label-group">
                      <input
                        disabled
                        id="fullName"
                        type="text"
                        name="fullName"
                        className="shadow-none form-control profile-input-width input-box-text"
                        value={role}
                      />
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4} className="px-0 pl-0 pl-xl-2 mt-xl-0">
          <Card className="py-3 total__trip__card h-100">
            <Row className="px-2 mb-3 text-md-left">
              <div className="heading-text">Password</div>
            </Row>
            <Row>
              <div className="form-group form-label-group">
                <input
                  disabled
                  id="fullName"
                  type="text"
                  name="fullName"
                  // className="shadow-none form-control"
                  className="pass__input shadow-none form-control profile-input-width"
                  value="***********"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group form-label-group">
                <Button
                  variant="primary"
                  className="save-button btn-save border-radius-12 m-0"
                  style={{ maxWidth: "382px" }}
                  onClick={handleShowPassword}
                >
                  <span className="btn-text">Change Password</span>
                </Button>
              </div>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* **************************Modals**************************************** */}

      <Modal show={show} onHide={handleClose} centered>
        <Formik
          initialValues={{
            fullName: localStorage.getItem("fullName") || "",
          }}
          validationSchema={nameFormValidation}
          onSubmit={updateName}
        >
          {() => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="title">Edit Full Name</Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="fullName"
                      type="text"
                      name="fullName"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="name"
                    />
                    <label htmlFor="fullName">Full Name</label>
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer mb-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="save-button btn-save border-radius-12"
                  >
                    <span className="btn-text">Save Changes</span>
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal show={showPasswordModal} onHide={handleClosePassword} centered>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationPasswordSchema}
          onSubmit={changePassword}
        >
          {() => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="title">Edit Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group mb-5">
                    <Field
                      id="oldPassword"
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      className="shadow-none form-control modal-input new_password"
                      placeholder="Old Password"
                    />
                    <label htmlFor="oldPassword">Old Password</label>
                    {showOldPassword ? (
                      <EyeFill
                        className="eye-position"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      />
                    ) : (
                      <EyeSlashFill
                        className="eye-position"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      />
                    )}
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group mb-5">
                    <Field
                      id="newPassword"
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      className="shadow-none form-control modal-input"
                      placeholder="New Password"
                    />
                    <label htmlFor="newPassword">New Password</label>
                    {showNewPassword ? (
                      <EyeFill
                        className="eye-position"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    ) : (
                      <EyeSlashFill
                        className="eye-position"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    )}
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group">
                    <Field
                      id="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      className="shadow-none form-control modal-input"
                      placeholder="Confirm New Password"
                    />
                    <label htmlFor="confirmPassword">
                      Confirm New Password
                    </label>
                    {showConfirmPassword ? (
                      <EyeFill
                        className="eye-position"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    ) : (
                      <EyeSlashFill
                        className="eye-position"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer mb-3">
                  <button
                    value="Submit"
                    className="save-button btn-save btn-primary border-radius-12 btn-text"
                  >
                    Change Password
                  </button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default React.memo(connect()(Profile));
