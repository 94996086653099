import React, { useState, useEffect } from "react";
import cardIcon from "../Dashboard_2/images/cardIcon.svg"
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import APIService from "../../services/APIService";

let PickupTotalTrips = () => {

 const currentDate =  moment().format('YYYY-MM-DD');
  const [totalTrips,setTotalTrips] =useState( {});
  
 
  const  windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [10],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };

  // const plugins = [
  //   {
  //     beforeDraw: function (chart) {
  //       var width = chart.width,
  //         height = chart.height,
  //         ctx = chart.ctx;
  //       ctx.restore();
  //       var fontSize = (height / 380).toFixed(2);
  //       ctx.font = fontSize + "em sans-serif";
  //       ctx.textBaseline = "top";
  //       var text = "Total Trips",
  //         textX = Math.round((width - ctx.measureText(text).width) / 2),
  //         textY = height / 2;
  //       ctx.fillStyle = "#828282";
  //       ctx.fillText(text, textX, textY);
  //       ctx.save();
  //     },
  //   },
  // ];

  useEffect(() => {

    setTotalTrips( {
      datasets: [
        {
          data: [174,202,131],
          backgroundColor: ["#EB3D3D", "#13B542", "#EDD62A"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#EDD62A"],
          borderWidth: 0,
        },
      ],
    })
    
  }, []);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 class="box-title text-wrap">Total Trips: 12</h2>
        </div>
    
            {" "}
          
              <Doughnut
                type="doughnut"
                data={totalTrips?totalTrips:emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                // plugins={plugins}
              />
              
            
           
<div className="mt-24 d-flex align-items-baseline justify-content-between flex-wrap">
          <div style={{ display: "flex" }}>
            <div className="green-box"></div>
            <div className="ml-2">
              <div className="trips-title-label">Completed</div>
              <div className="trips-count ml-3" style={{ color: "#000" }}>
               09
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="red-box"></div>
            <div className="ml-2">
              <div className="trips-title-label-cancelled">Cancelled</div>
              <div className="trips-count" style={{ color: "#000" }}>
                {" "}
                03
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="yellow-box"></div>
            <div className="ml-2">
              <div className="trips-title-label-declined">Declined</div>
              <div className="trips-count" style={{ color: "#000" }}>
                {" "}
                03
              </div>
            </div>
          </div>
          {/* <div>
             <img src = {redCard} alt = "ride" />
              <span className="topup-tx-card">spent</span>
             </div> */}
        </div>

           
      </div>
    </>
  );
};

export default PickupTotalTrips;
