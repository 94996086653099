import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import APIService from "../../services/APIService";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import { saveAs } from "save-as";

let AuditLogListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [auditLogs, setAuditLogs] = useState([]),
    [selectedCategory, setSelectedCategory] = useState(null),
    [selectedAuditLog, setSelectedAuditLog] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState([]),
    dt = useRef(null),
    categoryOptions = [
      { value: 1, label: "Trip" },
      { value: 2, label: "Tax" },
      { value: 3, label: "Fee" },
      { value: 4, label: "Email" },
      { value: 5, label: "Company" },
      { value: 6, label: "Other" },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getAuditLog = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/audit-log/settings", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            let logs = response.data.data.results;
            logs.map((log) => {
              log.subSetting = log.newValues.name.split("_").join(" ");
              return log;
            });
            setAuditLogs(logs);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageAuditLog = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          lazyParams.filters[filter] = event.filters[filter].value;
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getAuditLog();
    };

  useEffect(() => {
    getAuditLog();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let exportAuditLogs = () => {
      let logFields = [
        "Date & Time",
        "Setting",
        "Sub Setting",
        "Old Value",
        "New Value",
      ];
      logFields = logFields.join(",") + "\r\n";

      if (selectedAuditLog.length) {
        selectedAuditLog.forEach((log) => {
          log.subSetting = log.newValues.name.split("_").join(" ");
          logFields +=
            [
              " " + moment(log.createdAt).format("DD/MM/YYYY") + " ",
              categoryOptions.find((co) => co.value === log.newValues.category)
                .label,
              log.subSetting.slice(0, 1) +
                "" +
                log.subSetting.toLowerCase().slice(1, log.subSetting.length),
              log.oldValues.value,
              log.newValues.value,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([logFields], {
            type: "text/csv;charset=utf-8",
          }),
          "Audit-Logs.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.take = 100000000000;
        filterParams.skip = 0;

        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/audit-log/settings", filterParams)
          .then((response) => {
            if (response.status === 201) {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              response.data.data.results.forEach((log) => {
                log.subSetting = log.newValues.name.split("_").join(" ");
                logFields +=
                  [
                    " " + moment(log.createdAt).format("DD/MM/YYYY") + " ",
                    categoryOptions.find(
                      (co) => co.value === log.newValues.category
                    ).label,
                    log.subSetting.slice(0, 1) +
                      "" +
                      log.subSetting
                        .toLowerCase()
                        .slice(1, log.subSetting.length),
                    log.oldValues.value,
                    log.newValues.value,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([logFields], {
                  type: "text/csv;charset=utf-8",
                }),
                "Audit-Logs.csv"
              );
            } else {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      }
    },
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getAuditLog();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={() => exportAuditLogs()}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setSelectedAuditLog([]);
      getAuditLog();
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={auditLogs}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Log(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          selection={selectedAuditLog}
          onSelectionChange={(e) => setSelectedAuditLog(e.value)}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageAuditLog}
          onSort={manageAuditLog}
          onFilter={manageAuditLog}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column selectionMode="multiple" style={{ width: "2em" }} />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <span className="dataFnt-size">
                    {rowData.createdAt &&
                      moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </span>
                </React.Fragment>
              );
            }}
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getAuditLog();
                        }, 500);
                      }
                    } else {
                      delete lazyParams.filters["createdAt"];
                      setCreatedAt([]);
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getAuditLog();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Setting"
            body={(rowData) => {
              return categoryOptions.find(
                (co) => co.value === rowData.newValues.category
              ).label;
            }}
            sortable
            filter={showFilter}
            filterField="setting"
            filterElement={
              <Dropdown
                value={selectedCategory}
                options={categoryOptions}
                onChange={(event) => {
                  setSelectedCategory(event.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    setting: event.value,
                  };
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getAuditLog();
                  }, 500);
                }}
                placeholder="Select"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            header="Sub Setting"
            field="subSetting"
            body={(log) =>
              log.subSetting.slice(0, 1) +
              "" +
              log.subSetting.toLowerCase().slice(1, log.subSetting.length)
            }
            sortable
            filter={showFilter}
            filterField="subSetting"
            filterPlaceholder="Search"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Old Value"
            sortField="oldValue"
            filterField="oldValue"
            field="oldValues.value"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by old value"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="New Value"
            field="newValues.value"
            sortField="newValue"
            filterField="newValue"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by new value"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(AuditLogListing));
