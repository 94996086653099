import React, { useState } from "react";
import Navbar from "./components/Layout/Navbar";
import SideBar from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";
const Base = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Navbar isOpen={isOpen} openSidebar={openSidebar} />
      <div className="main">
        <SideBar isOpen={isOpen} openSidebar={openSidebar} />
        <div className={isOpen ? "main-wrapper-full" : "main-wrapper"}>
          {props.children}
        </div>
      </div>
      <Footer isOpen={isOpen} openSidebar={openSidebar} />
    </>
  );
};

export default Base;
