import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import DriverTripHistoryTab from "../components/Drivers/DriverTripHistoryTab";
import DriverSubscriptionTransactionsTab from "../components/Drivers/DriverSubscriptionTransactionsTab";
import DriverDetailsTab from "../components/Drivers/DriverDetailsTab";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import DriverEarningsTab from "../components/Drivers/DriverEarningsTab";
import PaymentHistory from "../components/Drivers/PaymentHistory";
import Spendings from "../components/Drivers/Spendings";
import TicketHistory from "../components/Drivers/TicketHistory";

let DriverListingTableDetailPage = (props) => {
  let [selectTab, setSelectedTab] = useState(null),
    history = useHistory(),
    [driver, setDriver] = useState(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    pathname = props.location.pathname,
    getDriver = () => {
      if (props.match.params.tab) {
        setSelectedTab(props.match.params.tab);
      } else {
        setSelectedTab("details");
      }
      // if (props.match.params.tab === "details") {
        props.dispatch({ type: "TOGGLE_LOADER", payload: true });
        APIService.get("admin/captain/" + props.match.params.driver)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setDriver(response.data.data);
              props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          });
      // }
    };
  // console.log(permissions, pathname, selectTab)
  useEffect(() => {
    if (props.match.params.tab) {
      setSelectedTab(props.match.params.tab);
    }
  }, [props.match.params.tab]);
  useEffect(() => {
    getDriver();
  }, []);
  return (
    <>
      <div className="rider-listing">
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          <i className="fa fa-chevron-left back-arrow"></i>
        </span>
        <span className="title">Captains</span>



        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
            props.history.push(
              `/driver-details/${tab}/${props.match.params.driver}`
            );
          }}
        >

          {permissions.some(a => a.includes('/driver-details/details')) && <Tab eventKey="details" title="Captain Details">
            {selectTab === "details" && (
              <DriverDetailsTab
                driver={driver}
                dispatch={props?.dispatch}
                driverId={props.match.params.driver}
              />
            )}
          </Tab>}

          {permissions.some(a => a.includes('/driver-details/trip-history')) && <Tab eventKey="trip-history" title="Trip Details">
            {selectTab === "trip-history" && (
              <DriverTripHistoryTab driverId={props.match.params.driver} />
            )}
          </Tab>}
          {/* <Tab
            eventKey="subscription-transactions"
            title="Subscription Transactions"
          >
            {selectTab === "subscription-transactions" && (
              <DriverSubscriptionTransactionsTab
                driverId={props.match.params.driver}
              />
            )}
          </Tab> */}
          {/* <Tab eventKey="driver-earning" title="Drivers Earning">
            {selectTab === "driver-earning" && (
              <DriverEarningsTab driverId={props.match.params.driver} />
            )}
          </Tab>
          <Tab eventKey="driver-status-logs" title="Drivers Status Logs">
            {selectTab === "driver-status-logs" && (
              <DriverBlockUnblockLogsTab driverId={props.match.params.driver} />
            )}
          </Tab> */}
          {permissions.some(a => a.includes('/driver-details/payment-history')) && <Tab eventKey="payment-history" title="ticket History">
            {selectTab === "payment-history" && (
              <TicketHistory riderView={driver} />
            )}
          </Tab>}

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(connect()(DriverListingTableDetailPage));
