import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom";

const dummyData = [
  {
    tripId: "TP001",
    createdAt: "12/02/2020 01:30",
    riderName: "Rahul",
    reason: "Reason 1",
    status: 1,
    issueStatus: true,
  },
  {
    tripId: "TP002",
    createdAt: "01/02/2019 05:45",
    riderName: "Raj",
    reason: "Reason 2",
    status: 2,
    issueStatus: false,
  },
  {
    tripId: "TP001",
    createdAt: "05/04/2021 07:15",
    riderName: "John",
    reason: "Reason 3",
    status: 3,
    issueStatus: true,
  },
];

let EmergencyRequestDetailTripTab = (props) => {
  let [tripHistory, setTripHistory] = useState(dummyData),
    [selectedTripHistory, setSelectedTripHistory] = useState(null),
    [filterStatus, setFilterStatus] = useState(""),
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [createdAt, setCreatedAt] = useState(null),
    [filterRange, setFilterRange] = useState([0, 5]),
    statusOptions = [
      { label: "Open", value: true },
      { label: "Resolve", value: false },
    ],
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    dt = useRef(null),
    getTripHistory = () => {
      //   let filterParams = JSON.parse(JSON.stringify(lazyParams));
      //   filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      //   props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      //   APIService.post(
      //     "admin/dispatch-trip-history/" + props.driverId,
      //     filterParams
      //   )
      //     .then((response) => {
      //       props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      //       if (response.data.statusCode === 200) {
      //         lazyParams.totalRecords = response.data.data.trips.length;
      //         setLazyParams(lazyParams);
      //         setTripHistory(response.data.data.trips);
      //       }
      //     })
      //     .catch(() =>
      //       props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
      //     );
    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTripHistory();
    };

  useEffect(() => {
    // getTripHistory();
  }, []);

  let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getTripHistory();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {showFilter ? "Hide Filter" : "Show Filter"}
            </button>
          </span>
        </div>
      );
    },
    ratingFilterTemplate = (
      <>
        <div className="mb-2 ">
          Rating Range: {filterRange[0]} - {filterRange[1]}
        </div>
        <Slider
          value={filterRange}
          onChange={(e) => {
            e.originalEvent.preventDefault();
            e.originalEvent.stopPropagation();
            setFilterRange(e.value);
          }}
          step={0.5}
          min={0}
          max={5}
          range
        />
      </>
    ),
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setFilterStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        totalRecords: 0,
      });
      getTripHistory();
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          <Link
            tooltip="View"
            tooltipOptions={{ position: "bottom" }}
            className="p-button p-component"
            to={"../trip-details/" + rowData.id}
          >
            <svg
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5002 0C16.8922 0 21.3782 3.88 22.3192 9C21.3792 14.12 16.8922 18 11.5002 18C6.10815 18 1.62215 14.12 0.681152 9C1.62115 3.88 6.10815 0 11.5002 0ZM11.5002 16C13.5396 15.9996 15.5185 15.3068 17.113 14.0352C18.7075 12.7635 19.8231 10.9883 20.2772 9C19.8214 7.0133 18.7051 5.24 17.1108 3.97003C15.5165 2.70005 13.5385 2.00853 11.5002 2.00853C9.46185 2.00853 7.48384 2.70005 5.88953 3.97003C4.29521 5.24 3.17892 7.0133 2.72315 9C3.17725 10.9883 4.29283 12.7635 5.8873 14.0352C7.48177 15.3068 9.46068 15.9996 11.5002 16ZM11.5002 13.5C10.3067 13.5 9.16209 13.0259 8.31817 12.182C7.47426 11.3381 7.00015 10.1935 7.00015 9C7.00015 7.80653 7.47426 6.66193 8.31817 5.81802C9.16209 4.97411 10.3067 4.5 11.5002 4.5C12.6936 4.5 13.8382 4.97411 14.6821 5.81802C15.526 6.66193 16.0002 7.80653 16.0002 9C16.0002 10.1935 15.526 11.3381 14.6821 12.182C13.8382 13.0259 12.6936 13.5 11.5002 13.5ZM11.5002 11.5C12.1632 11.5 12.7991 11.2366 13.2679 10.7678C13.7368 10.2989 14.0002 9.66304 14.0002 9C14.0002 8.33696 13.7368 7.70107 13.2679 7.23223C12.7991 6.76339 12.1632 6.5 11.5002 6.5C10.8371 6.5 10.2012 6.76339 9.73239 7.23223C9.26355 7.70107 9.00015 8.33696 9.00015 9C9.00015 9.66304 9.26355 10.2989 9.73239 10.7678C10.2012 11.2366 10.8371 11.5 11.5002 11.5Z"
                fill="#03053D"
              />
            </svg>
          </Link>
        </div>
      );
    };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={tripHistory}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          selection={selectedTripHistory}
          onSelectionChange={(e) => setSelectedTripHistory(e.value)}
          paginator
          emptyMessage="No Trip History(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          // totalRecords={lazyParams.totalRecords}
          totalRecords={3}
          lazy={true}
          rows={lazyParams.take}
          first={lazyParams.skip}
          sortField={lazyParams.sort.field}
          sortOrder={Number(lazyParams.sort.order)}
          onPage={manageTripHistory}
          onSort={manageTripHistory}
          onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Trip Id"
            field="tripId"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
          
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            filterField="createdAt"
            header="Date & Time"
            body={(trip) => {
              return moment(trip.createdAt).format("DD/MM/YYYY hh:mm A");
            }}
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getTripHistory();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTripHistory();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Rider Name"
            filterField="riderName"
            field="riderName"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by rider"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Reason"
            filterField="reason"
            field="reason"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by reason"
          />

          <Column
            className="text-center text-capitalize"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Trip Status"
            sortable
            filter={showFilter}
            body={(trip) => {
              return tripStatus.find((trp) => trp.value === trip.status).label;
            }}
            filterElement={
              <Dropdown
                value={filterStatus}
                options={tripStatus}
                onChange={(event) => {
                  setFilterStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["subscriptionStatus"] = event.value;
                  } else {
                    delete lazyParams.filters["subscriptionStatus"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTripHistory();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="issueStatus"
            header="Issue Status"
            body={(request) => {
              return request.issueStatus ? "Open" : "Resolve";
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  if (event.value || event.value === false) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setSelectedStatus(event.value);
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    // getEmergencyRequests();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(EmergencyRequestDetailTripTab));
