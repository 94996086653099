import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import APIService from "../../services/APIService";
import rideSource from "../../assest/images/ride-app-source.svg";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { CSVLink } from "react-csv";
import IConstants from "../../IConstants";


let PromoCodes = (props) => {
  const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };
  useEffect(() => {
    setDrivers(IConstants?.PROMO_CODES_DATA);
  }, []);
  let [selectedStatus, setSelectedStatus] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [fillRider, setFillRider] = useState([]),
    [subStatus, setSubStatus] = useState(null),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [drivers, setDrivers] = useState([]),
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    subStatusOptions = [
      {
        label: "Active",
        value: 1,
      },
      {
        label: "Overdue",
        value: 2,
      },
      {
        label: "Expired",
        value: 3,
      },
    ],
    headers = [
      { label: "ID", key: "id" },
      { label: "User", key: "user" },
      { label: "Name", key: "name" },
      { label: "Mobile No.", key: "mobileNo" },
      { label: "Data & Time", key: "createdAt" },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up  "></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getDrivers();
              }}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename="Captains.csv"
            target="_blank"
            ref={CSVdownloadRef}
          />
          <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={() => downloadCsvFile()}
          >
            Export
          </button>
          {/* <button
            className="btn btn-primary float-right mb-1 ml-12"
         
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
          </button> */}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      //   getDrivers();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    // getDrivers();
  }, []);

  // get captain table data in csv
  const downloadCsvFile = () => {
    APIService.post("admin/captains").then((response) => {
      if (response.data && response.data.statusCode === 200) {
        const Response = response.data.data.captains;
        var filterResponse = Response.map((item) => {
          return (
            item.approved === true
              ? (item.userStatus = "Active")
              : item.userStatus == false
              ? (item.userStatus = "Inactive")
              : "Locked",
            item.carLicenceType == 1 ? (item.carLicenceType = "Ride") : "Ride",
            (item.createdAt = moment(item.createdAt).format(
              "DD/MM/YYYY - hh:mm A"
            ))
            // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''
          );
        });

        setDownloadCsv(Response);

        setTimeout(() => {
          CSVdownloadRef.current.link.click();
        }, 500);
      }
    });
  };

 

  return (
    <>
      <div className="rider-listing">
        <div className=" justify-content-between">
          <Link to="/adjust/marketing-subscription/" title="Back">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Adjust <span className="text-secondary">(Promocodes)</span></span>
          <div className="table-header"></div>
        </div>
        <div className="datatable-doc-demo mt-3">
          <div className="card">
            <DataTable
              ref={dt}
              value={drivers}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              scrollable
              scrollHeight="470px"
              paginator
              emptyMessage="No Data found"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
              rowsPerPageOptions={[10, 25, 50]}
              totalRecords={lazyParams.totalRecords}
              lazy={true}
              first={lazyParams.skip}
              rows={lazyParams.take}
              sortField={lazyParams.sort.field}
              sortOrder={lazyParams.sort.order}
              loading={useSelector((state) => state.isGridLoader)}
              loadingIcon="fa fa-spinner"
            >
              <Column
                className="text-center"
                style={{ fontSize: "12px", width: "250px" }}
                header="Name"
                field="name"
                body={(driver) => {
                  return (
                    <div className="text-left">
                      <Link
                        to={`/promocodes-detail/${driver?.name}`}
                        style={{ color: "#13B542" }}
                      >
                        {driver.name}
                      </Link>
                    </div>
                  );
                }}
                sortable
                filter={showFilter}
                filterMatchMode="startsWith"
                filterPlaceholder="Search by name"
              />

              <Column
                className="text-left"
                headerClassName="text-left"
                style={{ fontSize: "12px", width: "180px" }}
                header="No. Of Times Used"
                field="noOfTimesUsed"
                sortable
                filter={showFilter}
                filterMatchMode="custom"
                filterFunction={customFunction}
                filterPlaceholder="Search by use"
              />

              <Column
                className="text-left"
                headerClassName="text-left"
                style={{ fontSize: "12px", width: "180px" }}
                header="Success"
                field="success"
                sortable
                filter={showFilter}
                filterMatchMode="custom"
                filterFunction={customFunction}
                filterPlaceholder="Search by success"
              />

              <Column
                className="text-left"
                style={{ fontSize: "12px", width: "180px" }}
                header="failed"
                field="failed"
                sortable
                filter={showFilter}
                filterPlaceholder="Search by failed"
              />
            </DataTable>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default React.memo(connect()(withRouter(PromoCodes)));
