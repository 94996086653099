import React, { useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import { ProgressSpinner } from 'primereact/progressspinner';

import "react-toastify/dist/ReactToastify.css";
import "../src/assest/css/flags.css"
import "./App.sass";
import APIService from "./services/APIService";

function App() {
  const [load, setLoad] = useState(false),
    nonAuthenticatedPages = [
      "login",
      "forgot-password",
      "reset-password",
      "new-password",
      "checkout",
      "trip-invoice",
      "trip-arabic-invoice",
      "trip-both-invoices"
    ],
    isFirstLogin = localStorage.getItem("isFirstLogin"),
    isFirstLogin_allow = (isFirstLogin || isFirstLogin == 'true') ? true : false
  useLayoutEffect(() => {
    const runApi = async () => {
      await APIService.get("admin/check-token")
      setLoad(true)
    }
    if (isFirstLogin_allow) setLoad(true)
    else if (nonAuthenticatedPages.indexOf(window.location.pathname.split("/")[1]) === -1) {
      runApi()
    }
    else {
      setLoad(true)
    }
  }, [])
  return (
    <>
      {load ? <BrowserRouter>
        <Routes />
      </BrowserRouter>
        : <div className="card flex justify-content-center " style={{ height: '100vh' }}>
          <ProgressSpinner />
        </div>
      }
    </>
  );
}

export default App;
