import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import IConstants from "../../IConstants";
import { Link, useLocation, useParams } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Modal } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { _baseURL } from "../../services/APIService";
import { toast } from "react-toastify";
import APIService from "../../services/APIService";
import { secret_key } from "../../services/APIService";
import e from "cors";
import { Ticket } from "react-bootstrap-icons";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#fff"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};

let _formatTime = (value) => {
  const date = new Date(value);
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString();
  return `${dateString} - ${timeString}`;
};
let _getTicketCategory = (category_id) => {
  if (category_id === 1) return "Ride Cancellation";
  if (category_id === 2) return "Subscription Issue";
  if (category_id === 3) return "Payment Not Received";
};
let _getTicketType = (ticket_type) => {
  if (ticket_type == 1) return "Rider";
  if (ticket_type == 2) return "Driver";
};
let _statusOfTicket = (status) => {
  let div;
  switch (status) {
    case IConstants.TICKET_PENDING:
      div = <span className="status-text-pending">PENDING</span>;
      break;
    case IConstants.TICKET_REJECTED:
      div = <span className="status-text-inactive">REJECTED</span>;
      break;
    case IConstants.TICKET_ASSIGNED:
      div = <span className="status-text">ASSIGNED</span>;
      break;
    case IConstants.TICKET_RESOLVED:
      div = <span className="status-text">RESOLVED</span>;
      break;
    case IConstants.TICKET_INVALID:
      div = <span className="status-text-pending">REVERT</span>;
      break;
    case IConstants.TICKET_REVERT:
      div = <span className="status-text-inactive">INVALID</span>;
      break;
    default:
      // div = <span className="status-text-inactive">INVALID</span>;
      break;
  }
  return div;
};

// const ticketDetailssURL = _baseURL + "/api/v1/ticket/ticket-detail";
const _assingUserTicket =
  _baseURL + "/api/v1/ticket/assign-user?secret_key" + secret_key;
const _getAllTicketsURL = _baseURL + "/api/v1/ticket/all";
const _allDepartmentsURL = _baseURL + "/api/v1/department/all";
const _assingUserToTicket = `${_baseURL}/api/v1/ticket/update-ticket?secret_key=${secret_key}`;
const _changeStatus = `${_baseURL}/api/v1/ticket/update-status`;
const _unAssignUserFromTicket = `${_baseURL}/api/v1/ticket/unassign-ticket?secret_key=${secret_key}`;

let statusOptions = [
  { value: IConstants.TICKET_PENDING, label: "PENDING" },
  { value: IConstants.TICKET_ASSIGNED, label: "ASSIGNED" },
  { value: IConstants.TICKET_REJECTED, label: "REJECTED" },
  { value: IConstants.TICKET_RESOLVED, label: "RESOLVED" },
  { value: IConstants.TICKET_REVERT, label: "INVALID" },
  { value: IConstants.TICKET_INVALID, label: "REVERT" },
];
let statusOptionsSpecific = [
  { value: IConstants.TICKET_PENDING, label: "PENDING" },
  { value: IConstants.TICKET_ASSIGNED, label: "ASSIGNED" },
  { value: IConstants.TICKET_REVERT, label: "INVALID" },
  { value: IConstants.TICKET_INVALID, label: "REVERT" },
];
const TicketDetail = (props) => {
  // const [getTicketDetail, _setTicketDetail] = useState(false);
  const [ticketData, setTicketData] = useState();
  const [statusValue, setStatusValue] = useState();
  const [commentValue, setCommentValue] = useState();
  const [ticket_id, setTicktId] = useState(null);
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [show, setShow] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [departments, setDepartments] = useState([]);
  const [statusValueModal, setStatusValueModal] = useState(null);
  const [depart, setDepart] = useState();
  const [department, setDepartment] = useState();
  const [assigneeName, setAssigneeName] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [assigneeId, setAssigneeId] = useState();
  const [assigneeNumber, setAssigneeNumber] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [subDepartments, setSubDepartments] = useState([]);
  const [showEmployee, setShowEmployee] = useState();
  // const [ticketList, setTicketList] = useState(false);
  const [userMessage, setUserMessage] = useState();
  const [employeeCopy, setEmployeeCopy] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [disButton, setDisButton] = useState(true);
  const [selectedAssignUser, setSelectedAssignUser] = useState(null);
  const [hideTicketSection, setHideTicketSection] = useState(false);
  // const [assignTicket, setAssignTicket] = useState(false);
  const [commetFeild, setCommetFeild] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [closeTicket, setCloseTicket] = useState(false);
  const [userComments, setUserComments] = useState([]);
  const [submitting, isSubmitting] = useState(false);
  const [ticketDetails, setTicketDetails] = useState();
  const [lazyParams, setLazyParams] = useState({
    filters: {},
    sort: {
      field: "creationDate",
      order: "desc",
    },
    take: 10,
    skip: 0,
    keyword: "",
    totalRecords: 0,
  });
  const { id } = useParams();
  // const location = useLocation();
  // const ticketDetails = location.state;
  // {{SERVER_URL}}/api/v1/ticket/ticket-detail/309?secret_key={{SECRET_KEY}}
  const fetchTicketData = useCallback(async () => {
    try{
    const response = await fetch(
      `${_baseURL}/api/v1/ticket/ticket-detail/${id}?secret_key=${secret_key}`
    );
    const result = await response.json();
    // console.log(result)
    if (result?.data?.status_id == 3 || result?.data?.status_id == 4) {
      setCloseTicket(true);
    }
    setTicketDetails(result?.data);
  }catch(e){console.log(e)}
  }, [props.match.params.id]);
  useEffect(() => {
    fetchTicketData();
  }, [fetchTicketData]);
  // console.log(ticketDetails)
  // console.log(ticket);
  // let _getTicket = async () => {
  //   setTicktId(id);
  //   APIService.get(ticketDetailssURL, {
  //     data: {
  //       secret_key: secret_key,
  //       id: ticket_id,
  //     },
  //   })
  //     .then((response) => {

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };

  // useEffect(()=>{
  //     const location = useLocation();
  //     const Data = location.state;
  //     setTicketDetails(Data)
  // },[])

  useEffect(() => {
    if (userComments?.length >= 0) {
      setHideTicketSection(true);
    } else {
      setHideTicketSection(false);
    }
  }, [userComments?.length]);

  const handleClose = () => {
    setShow(false);
    getDepartmentValue(2000);
    getSubDepartmentEmploye(2000);
  };
  useEffect(() => {
    getChatHistory(id);
  }, [submitting]);
  const handleShow = () => {
    setTicketId(id);
    setShow(true);
  };

  function getDepartmentValue(e) {
    setDepart(e.value);
    _getSubDepartment(e.value);
  }

  function getSubDepartmentEmploye(e) {
    setDepartment(e.value);
    _getAllEmployees(e.value);
  }

  function getSelectionValue(e) {
    // console.log(e.value.name)
    setDisButton(false);
    setAssigneeNumber(e.value.id);
    setAssigneeName(e.value.name);
    // setAssigneeId(e.value.id);
  }
  let _getSubDepartment = async (id) => {
    const _subDepartmentsURL =
      _baseURL + `/api/v1/department/${id}/subdepartments`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_subDepartmentsURL, requestOptions);
    let final_data = await response.json();

    if (final_data.success) {
      let subDeparts = final_data.data.map((subDepartmentData) => {
        let tmp = {};
        tmp.value = subDepartmentData.id;
        tmp.label = subDepartmentData.name;
        return tmp;
      });
      setSubDepartments(subDeparts);
    }
  };

  let _getAssignTicket = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_allDepartmentsURL, requestOptions);
    let final_data = await response.json();

    var data = final_data.data.map((departmentData) => {
      var tmp = {};
      tmp.value = departmentData.id;
      tmp.label = departmentData.name;
      return tmp;
    });
    setDepartments(data);
  };

  let _getAllEmployees = async (id) => {
    const _getAllEmployeeURL =
      _baseURL + `/api/v1/subdepartment/${id}/employees`;
    // const _getAllEmployeeURL = `${_baseURL}s${secret_key}`;
    const requestOptions = {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_getAllEmployeeURL, requestOptions);
    let final_data = await response.json();
    console.log(final_data)
    setEmployeeCopy(final_data.data);
    return setShowEmployee(final_data.data);
  };

  const assignTicket = ticketDetails?.last_ticket_assignment?.assigned_to?.id;

  let _getAllTickets = async () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    let filterParams = JSON.parse(JSON.stringify(lazyParams));
    filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    delete filterParams["totalRecords"];
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ secret_key: secret_key }),
    };
    const response = await fetch(_getAllTicketsURL, requestOptions);
    let final_data = await response.json();
    if (final_data.data) {
      lazyParams.totalRecords = final_data.data.length;
      setLazyParams(lazyParams);
      const sortedData = final_data.data.reverse();

      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    } else props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
  };

  const getChatHistory = async (ticketId) => {
    const _chatTicketHistory = `${_baseURL}/api/v1/chat/show-ticket-chats?secret_key=${secret_key}&ticket_id=${ticketId}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const response = await fetch(_chatTicketHistory, requestOptions);
    let final_data = await response.json();

    setUserComments(final_data.data);
  };
  const getUserResponse = async (e) => {
    e.preventDefault();
    isSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },

      body: JSON.stringify({
        user_id: inputValue ? assigneeNumber : assignTicket,
        secret_key: secret_key,
        ticket_id: id,
        status_id: statusValue.value,
        description: commentValue,
        email: employeeEmail,
      }),
    };
    getChatHistory(id);
    let assignedTrue = await ticketAssign();
    if (assignedTrue) {
      try {
        const response = await fetch(_assingUserToTicket, requestOptions);
        let final_data = await response.json();

        // setUserComments(final_data.data);
        if (final_data.success === true)
          toast.success("Ticket Assigned Successfully!");
        else toast.error("Ticket Not Assigned. Something went wrong");
        if (statusValue.value === 3 || statusValue.value === 4) {
          setCloseTicket(true);
          setStatusValue("");
        } else {
          setCloseTicket(false);
          setStatusValue("");
        }
        isSubmitting(false);
        setInputValue("");
        setCommentValue();
        setStatusValue(null);
      } catch (error) {
        console.error(error);
      }
    } else {
      setStatusValue(null);
      setAssigneeName(null);
      toast.error("Something Went Wrong !!!");
    }
    fetchTicketData();
  };
  const AssignToSearchModal = (e) => {
    // console.log(e)
    setInputVal(e.target.value);
    // const filteredUser = [...showEmployee]
    const filteredUser = JSON.parse(JSON.stringify(showEmployee));
    let value = e.target.value.toLowerCase();
    if (filteredUser) {
      const filterUser = filteredUser.filter((user) =>
        user.name.toLowerCase().includes(value)
      );
      if (e.target.value == "") {
        setEmployeeCopy(filteredUser);
      } else {
        setEmployeeCopy(filterUser);
      }
    }
    // const filteredUser = [...showEmployee]
    // let value = e.target.value.toLowerCase();
    // const filterUser = filteredUser.filter((user)=>{
    //   return(
    //     user.name.toLowerCase().includes(value)
    //   )
    // })
    // if(e.target.value==""){
    //   setEmployeeCopy(filteredUser)
    // }else{
    //   setEmployeeCopy(filterUser)
    // }
  };

  useEffect(() => {
    if (ticketDetails?.status_id === 4 || ticketDetails?.status_id === 3) {
      setCloseTicket(true);
    }
    let email = localStorage.getItem("email");
    let userId = localStorage.getItem("userID");
    setEmployeeId(userId);
    setEmployeeEmail(email);
    // _getTicket();
  }, []);

  // Ticket Closing button
  const TicketResolved = async (resolvedText, statusID) => {
    // console.log(assigneeNumber);
    // console.log(assignTicket)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        // user_id: inputValue ? assigneeNumber : assignTicket,
        // user_id:employeeId,
        secret_key: secret_key,
        ticket_id: id,
        status_id: statusID,
        description: resolvedText,
        email: employeeEmail,
      }),
    };

    getChatHistory(id);
    // const response = await fetch(_assingUserToTicket, requestOptions);
    const response = await fetch(_changeStatus, requestOptions);
    let final_data = await response.json();
    isSubmitting(false);
    fetchTicketData();
    if (final_data.success === true) toast.success(resolvedText);
    else toast.error("Ops! something went wrong.");
  };
  const unAssignTicket = async (ticket_id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        ticket_id,
      }),
    };
    const response = await fetch(_unAssignUserFromTicket, requestOptions);
    response.status === 200 && fetchTicketData();
  };

  function closeTicketSection() {
    closeTicket ? isSubmitting(false) : isSubmitting(false);
    if (closeTicket) {
      isSubmitting(true);
      const resolved = "Ticket is re-open successfully";
      setCloseTicket(false);
      TicketResolved(resolved, 2);
    } else {
      isSubmitting(true);
      const resolved = "Ticket Close and Issue resolved successfully";
      TicketResolved(resolved, 4);
      unAssignTicket(id);
      setCloseTicket(true);
    }
    // closeTicket ?TicketResolved(resolved) setCloseTicket(false) : setCloseTicket(true)
    // isSubmitting(false);
  }

  // assign ticket to user
  const ticketAssign = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        user_id: assigneeNumber,
        secret_key: secret_key,
        ticket_id: ticketId,
      }),
    };
    const response = await fetch(_assingUserTicket, requestOptions);
    let final_data = await response.json();
    return final_data.success ? true : false;
  };

  // get user comment

  const postUserResponse = async (userQuery) => {
    isSubmitting(true);

    const _userResponse =
      _baseURL +
      `/api/v1/chat/store?ticket_id=85&user_id=3&secret_key=${secret_key}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        secret_key: secret_key,
        ticket_id: id,
        description: userQuery,
        email: employeeEmail,
      }),
    };
    ticketAssign();
    const response = await fetch(_userResponse, requestOptions);
    let final_data = await response.json();
    if (final_data.success === true) {
      toast.success("Comment posted  successfully!");
    } else toast.error("Ops! comment not posted.");

    isSubmitting(false);
  };
  function validateComment(comment) {
    const maxLength = 300;
    const isWhitespaceOnly = comment.replace(/\s/g, "").length === 0;
    const isValid =
      comment.trim().length > 0 &&
      comment.length <= maxLength &&
      !isWhitespaceOnly;
    return isValid;
  }
  const _EmployeeRole = (rowData)=>{
    return (rowData?.roles[0]?.name || "--")
  }

  return (
    <div>
      <div className="rider-listing tickets-page">
        <span style={{ cursor: "pointer" }}>
          {
            <Link
              to={"/tickets"}
              className="dataFnt-size"
              style={{ color: "rgba(19, 181, 66, 1)" }}
            >
              <i className="fa fa-chevron-left back-arrow"></i>
            </Link>
          }
        </span>
        <span className="title">Tickets</span>
        <div className="d-box mt-3">
          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-baseline">
                  <h2 className="box-title">Ticket Details</h2>
                  <span className="ticket-number">
                    #AB-0010{ticketDetails?.id}00
                  </span>
                  {_statusOfTicket(
                    ticketDetails
                      ? ticketDetails?.status_id
                      : ticketDetails?.status_id
                  )}
                </div>
                {ticketDetails?.status_id !== 3 &&
                  ticketDetails?.status_id !== 4 && (
                    <div>
                      {" "}
                      <Button
                        className="btn btn-modal-success"
                        onClick={() => {
                          setShowStatus(true);
                        }}
                      >
                        Change Status
                      </Button>{" "}
                    </div>
                  )}
                {/* <span className="status-text">
                            Closed
                          </span>  */}
                {/* <span className="status-text-pending">
                              Pending
                          </span>                */}
              </div>
              <div className="inner-box mt-3">
                <Row>
                  <Col lg={4}>
                    {/* <h5 style={{fontWeight:"bold"}}>CREATOR DETAILS</h5> */}
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Created by</p>
                      <p className="ticket-text">
                        {ticketDetails?.created_by?.name}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Creator ID</p>
                      <p className="ticket-text text-success">
                        #{ticketDetails?.created_by?.id}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Creator Email</p>
                      <p className="ticket-text text-success">
                        {ticketDetails?.created_by?.email}
                      </p>
                    </div>

                    {/* <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Ticket type</p>
                      <p className="ticket-text">
                        {_getTicketCategory(ticketDetails.category_id)}
                        ----                        
                      </p>
                    </div> */}
                  </Col>
                  <Col lg={4} className="border-custom">

                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Issue type</p>
                      <p className="ticket-text">
                        {ticketDetails?.category?.title}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Assigned To</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {ticketDetails?.last_ticket_assignment?.assigned_to
                          ?.name || "Not Assigned Yet"}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Date & Time</p>
                      <p className="ticket-text">
                        {_formatTime(ticketDetails?.created_at)}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Description</p>
                      <p className="ticket-text">
                        {ticketDetails?.description}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Created for</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {_getTicketType(ticketDetails?.type)}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Customer Name</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {ticketDetails?.customer?.name}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">CMS User_ID</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {ticketDetails?.customer?.id}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Ride User_ID</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {ticketDetails?.customer?.ride_customer_id || "-"}
                      </p>
                    </div>
                    <div className="flex-ticket flex-margin">
                      <p className="ticket-heading">Contact no</p>
                      <p className="ticket-text">
                        {/* {ticketDetails.created_by.name ?? "-"} */}
                        {ticketDetails?.customer?.contact_no}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {/* ----------
            History Section  when in progress
            -------------*/}
        {
          <div className="d-box mt-3">
            <Row>
              <Col md={12}>
                <h2 className="box-title">History</h2>

                {hideTicketSection == true
                  ? userComments?.map((comments) => {
                      return (
                        <div className="inner-box mt-3">
                          <Row>
                            <Col lg={4}>
                              <div className="flex-ticket flex-margin">
                                <p className="ticket-heading">Comment By</p>
                                <p className="ticket-text">
                                  {`${comments.user?.name} | ${comments?.user?.email}`} 
                                </p>
                              </div>

                              <div className="flex-ticket flex-margin">
                                <p className="ticket-heading">comment</p>
                                <p className="ticket-text font-weight-bold">
                                  {comments.description}
                                </p>
                              </div>
                            </Col>
                            <Col lg={4} className="border-custom">
                              <div className="flex-ticket flex-margin">
                                <p className="ticket-heading">Assignee ID</p>
                                <p className="ticket-text text-success">
                                  #
                                  {
                                    // ticketDetails.last_ticket_assignment
                                    //   .assigned_to
                                    comments.id
                                  }
                                </p>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="flex-ticket flex-margin">
                                <p className="ticket-heading">Date & Time</p>
                                <p className="ticket-text">
                                  {_formatTime(comments.updated_at)}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                  : ""}
              </Col>
            </Row>

            {hideTicketSection ? (
              <div className="d-box mt-3">
                {closeTicket == false ? (
                  <Formik>
                    <Form>
                      <Row>
                        <Col md={12} className="text-right mt-3">
                          <Col md={12}>
                            <div className=" flex-margin mt-1 mb-5">
                              <h2 className="box-title mb-3 text-left">
                                Reply
                              </h2>
                              <Formik>
                                <Form>
                                  <div className="form-group form-label-group pb-0 mb-0 w-25">
                                    <Field
                                      type="text"
                                      as="textarea"
                                      className="shadow-none form-control modal-input br-10"
                                      placeholder="Name"
                                      maxLength={300}
                                      value={userMessage}
                                      onChange={(e) => {
                                        validateComment(e.target.value)
                                          ? setUserMessage(e.target.value)
                                          : setUserMessage("");
                                      }}
                                    />
                                    <label htmlFor="content">Comment</label>
                                  </div>

                                  <Col md={12}>
                                    {userMessage ? (
                                      <Button
                                        className="btn btn-modal-success"
                                        onClick={() => {
                                          postUserResponse(userMessage);
                                          setUserMessage("");
                                        }}
                                      >
                                        Post Comment
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn btn-modal-success"
                                        disabled
                                      >
                                        Post Comment
                                      </Button>
                                    )}
                                  </Col>
                                </Form>
                              </Formik>
                            </div>
                          </Col>
                        </Col>

                        <Col lg={4}>
                          <div className="form-group form-label-group pb-0 mb-0 position-relative">
                            <Field
                              type="search"
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                              value={inputValue}
                              readOnly="true"
                            />
                            <Button
                              className="btn btn-success dropdown-toggle position-absolute top-0 end-0 h-100"
                              style={{ height: "98%" }}
                              onClick={() => {
                                handleShow();
                                setDisButton(true);
                                _getAssignTicket();
                              }}
                            >
                              Assign To
                            </Button>
                            <label htmlFor="search">Assign To</label>
                          </div>
                          <Modal
                            className="Create-Ticket"
                            show={showStatus}
                            onHide={() => setShowStatus(false)}
                            centered
                            backdrop="static"
                            keyboard={false}
                          >
                            <Formik
                            initialValues={
                              {status: 0}
                            }
                              onSubmit={async () => {
                                isSubmitting(true);
                                const requestOptions = {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    "X-Requested-With": "XMLHttpRequest",
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                  body: JSON.stringify({
                                    secret_key,
                                    status_id: statusValueModal?.value,
                                    email: employeeEmail,
                                    ticket_id: id,
                                  }),
                                };
                                const response = await fetch(
                                  _changeStatus,
                                  requestOptions
                                );
                                if (response?.status === 200) {
                                  toast.success("Status Changed Successfully");
                                  fetchTicketData();
                                }
                                isSubmitting(false);
                                setShowStatus(false);
                                setStatusValue(null);
                              }}
                            >
                              {({ handleBlur, setFieldValue, values }) => {
                                return (
                                  <Form>
                                    <Modal.Header>
                                      <Modal.Title className="modal-title">
                                        Change Ticket Status
                                      </Modal.Title>
                                      <div
                                        className="divclose"
                                        onClick={() => setShowStatus(false)}
                                      >
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                    </Modal.Header>
                                    <Modal.Body className="mt-24">
                                      <Row>
                                        <Col lg={12}>
                                          <div className="mb-3 form-label-group ">
                                            <Select
                                              className="height-50 br-10 bg-color-select"
                                              placeholder="Status"
                                              name="status"
                                              styles={colourStyles}
                                              options={statusOptionsSpecific}
                                              value={statusValueModal}
                                              onChange={(e) =>
                                                setStatusValueModal(e)
                                              }
                                            />
                                            <label>Status</label>
                                            <ErrorMessage
                                              name="status"
                                              component="div"
                                              className="invalid-feedback text-danger"
                                            />
                                          </div>
                                        </Col>
                                        <Col md={12}>
                                          <button
                                            disabled={submitting}
                                            type="submit"
                                            className="btn btn-success btn-block btn-lg"
                                          >
                                            {submitting && (
                                              <span className="spinner-grow spinner-grow-sm"></span>
                                            )}
                                            Change Status
                                          </button>
                                        </Col>
                                      </Row>
                                    </Modal.Body>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </Modal>

                          <Modal show={show} onHide={handleClose} centered>
                            <Formik
                              initialValues={{
                                department_id: 1,
                              }}
                              onSubmit={async (values) => {
                                isSubmitting(true);

                                handleClose();

                                setCommetFeild(true);
                                setInputValue(assigneeName);
                                isSubmitting(false);
                              }}
                            >
                              {({ handleBlur, setFieldValue }) => {
                                return (
                                  <Form>
                                    <Modal.Header>
                                      <Modal.Title className="modal-title">
                                        Assign To
                                      </Modal.Title>
                                      <div
                                        className="divclose"
                                        onClick={handleClose}
                                      >
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                    </Modal.Header>
                                    <Modal.Body className="mt-3">
                                      <Row className="justify-content-center">
                                        <Col
                                          md={12}
                                          className="d-flex justify-content-between align-items-center my-2 px-1 no-gutters"
                                        >
                                          <Col md={6} className="mr-1">
                                            <div className="mb-3 form-label-group ">
                                              <Select
                                                // value={departments}
                                                className="height-50 br-10 bg-color-select"
                                                options={departments}
                                                onChange={(e) =>
                                                  getDepartmentValue(e)
                                                }
                                                placeholder="Department"
                                                style={{ width: "100%" }}
                                              />
                                              <label>Department</label>
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="mb-3 form-label-group ">
                                              <Select
                                                // value={subDepartments}
                                                className="height-50 br-10 bg-color-select"
                                                options={subDepartments}
                                                onChange={(e) =>
                                                  getSubDepartmentEmploye(e)
                                                }
                                                placeholder="Sub-Department"
                                                style={{ width: "100%" }}
                                              />
                                              <label>Sub-Department</label>
                                            </div>
                                          </Col>
                                        </Col>

                                        <Col md={12} className="px-1 mb-2">
                                          <div>
                                            <InputText
                                              type="search"
                                              placeholder="Search..."
                                              className="input-modal"
                                              value={inputVal}
                                              onChange={AssignToSearchModal}
                                              // onInput={(e) => {
                                              //   lazyParams = {
                                              //     ...lazyParams,
                                              //     skip: 0,
                                              //     take: 10,
                                              //     keyword: e.target.value,
                                              //   };
                                              //   setLazyParams(lazyParams);
                                              //   _getAllTickets();
                                              // }}
                                              style={{ minWidth: "100%" }}
                                            />
                                          </div>
                                        </Col>
                                        <Col md={12} className="px-1">
                                          <DataTable
                                            className="datatables-modal dt-custom"
                                            value={employeeCopy}
                                            selectionMode="single"
                                            selection={selectedAssignUser}
                                            onSelectionChange={(e) =>
                                              getSelectionValue(e)
                                            }
                                            dataKey="id"
                                            responsiveLayout="scroll"
                                          >
                                            <Column
                                              selectionMode="single"
                                              headerStyle={{ width: "3em" }}
                                              className="modal-table-header"
                                            ></Column>
                                            <Column
                                              field="id"
                                              header="ID"
                                            ></Column>
                                            <Column
                                              field="name"
                                              header="Name"
                                            ></Column>
                                            < Column
                                            // field="roles.name"
                                            body={_EmployeeRole}
                                            header="Role"
                                            ></Column>
                                          </DataTable>
                                        </Col>
                                        <Col md={9}>
                                          <Button
                                            disabled={disButton}
                                            type="submit"
                                            className="btn btn-success btn-block btn-lg"
                                          >
                                            {submitting && (
                                              <span className="spinner-grow spinner-grow-sm"></span>
                                            )}
                                            Assign
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Modal.Body>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </Modal>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3 form-label-group ">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Status"
                              name="status"
                              styles={colourStyles}
                              options={statusOptions}
                              value={statusValue}
                              onChange={(e) => setStatusValue(e)}
                            />
                            <label>Status</label>
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form-group form-label-group pb-0 mb-0">
                            {commetFeild ||
                            (statusValue !== undefined && assigneeName) ? (
                              <Field
                                type="text"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Comment"
                                maxLength={300}
                                value={commentValue}
                                onChange={(e) =>
                                  validateComment(e.target.value)
                                    ? setCommentValue(e.target.value)
                                    : setCommentValue("")
                                }
                              />
                            ) : (
                              <Field
                                type="text"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="Comment"
                                disabled
                              />
                            )}

                            <label htmlFor="comment">Comment</label>
                          </div>
                        </Col>
                        <Col md={12} className="text-right mt-3">
                          {commentValue && assigneeName ? (
                            <button
                              className="btn btn-modal-success"
                              onClick={(e) => {
                                setCommentValue("");
                                getUserResponse(e);
                              }}
                            >
                              Submit
                            </button>
                          ) : (
                            <Button className="btn btn-modal-success" disabled>
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Formik>
                ) : (
                  ""
                )}

                <div className="d-box mt-3">
                  <Row>
                    <Col md={12} className="text-center">
                      <Button
                        // disabled={ticketDetails?.last_ticket_assignment?.assigned_to?.name ? false : true}
                        className="btn btn-modal-success"
                        onClick={() => closeTicketSection()}
                      >
                        {/* {console.log(closeTicket)} */}
                        {closeTicket
                          ? "re-Open Ticket"
                          : "Review & Closed The Ticket"}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default TicketDetail;
