import React from "react";
import { Dropdown } from "react-bootstrap";
import { BoxArrowLeft, Person } from "react-bootstrap-icons";
import { useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Logoicon from "../UI/Logoicon";
import logo from "../../assest/images/ride.svg";
import notify from "../../assest/images/notify.png"
let Navbar = ({ isOpen, openSidebar, history }) => {
  let location = useLocation(),
    userInfo = useSelector((state) => state.user);
    const style = {
       width : "100px",
       height: "50px",
      // Adding media query..
      '@media (min-width:300px)': {
        width : "1000px",
        height: "500px",
      },
    };
  return (
    <header>
      <nav className={isOpen ? "navbar nav__fixed" : "navbar"}>
        <div className="left">
          <div className="logo">
            <button className="humburger d-lg-none" onClick={openSidebar}>
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 01.708 0L8 7.293l2.646-2.647a.5.5 0 01.708.708L8.707 8l2.647 2.646a.5.5 0 01-.708.708L8 8.707l-2.646 2.647a.5.5 0 01-.708-.708L7.293 8 4.646 5.354a.5.5 0 010-.708z"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                  ></path>
                </svg>
              )}
            </button>
            <Link to="/dashboard">
            <div className="logo-dash">
            <img src = {logo} alt = "ride" className="nav_icon" />
            </div>
          
      
              {/* <Logoicon /> */}
              {/* <svg
                className="logo__name"
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="26"
                fill="none"
                viewBox="0 0 56 26"
              >
                <path
                  fill="#4F4F4F"
                  d="M.862 2.458v18.117h.217a2.265 2.265 0 002.268-2.268V.215h-.242A2.255 2.255 0 00.862 2.458zM12.682 5.112C8.316 5.112 4.77 8.658 4.77 13c0 4.366 3.546 7.888 7.888 7.888 4.342 0 7.912-3.522 7.912-7.888 0-4.342-3.522-7.888-7.888-7.888zm0 13.46a5.55 5.55 0 11.002-11.098 5.55 5.55 0 01-.002 11.098zM29.858 5.112c-4.367 0-7.913 3.546-7.913 7.888 0 4.366 3.546 7.888 7.888 7.888 4.342 0 7.888-3.546 7.888-7.888.025-4.342-3.497-7.888-7.863-7.888zm0 13.46a5.55 5.55 0 11.001-11.098 5.55 5.55 0 01-.002 11.098zM47.25 5.112c-4.294 0-7.792 3.425-7.888 7.695v12.978h.145a2.203 2.203 0 002.195-2.195v-4.945a7.881 7.881 0 005.548 2.267c4.366 0 7.888-3.546 7.888-7.888 0-4.366-3.546-7.912-7.888-7.912zm0 13.46a5.549 5.549 0 11.002-11.098 5.549 5.549 0 01-.002 11.098z"
                ></path>
              </svg> */}
            </Link>
          </div>
          {/* <div className="search__box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#D9D4EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 20a9 9 0 100-18 9 9 0 000 18zM22 22l-4-4"
              ></path>
            </svg>
            <input className="search__input" type="text" placeholder="Search" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="#A0A3BD"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l12 12M1 13L13 1"
              ></path>
            </svg>
          </div> */}
        </div>
        <div className="right">
          {/* {location.pathname === "/notifications" ? (
            <Link to="/notifications">
              <svg
                width="25"
                height="25"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" fill="white" />
                <path
                  d="M7.02571 12.9999C7.02406 11.8139 7.25715 10.6393 7.71157 9.54386C8.16598 8.44838 8.83272 7.45367 9.67336 6.61707C10.514 5.78047 11.5119 5.11852 12.6096 4.66938C13.7072 4.22025 14.8829 3.99281 16.0689 4.00017C21.0176 4.03696 24.9759 8.15034 24.9759 13.1129V13.9999C24.9759 18.4769 25.9126 21.0748 26.7376 22.4947C26.8264 22.6464 26.8737 22.8189 26.8747 22.9947C26.8756 23.1705 26.8302 23.3435 26.7429 23.4962C26.6557 23.6488 26.5297 23.7758 26.3778 23.8642C26.2258 23.9527 26.0532 23.9995 25.8774 23.9999H6.12329C5.94744 23.9995 5.77482 23.9526 5.62284 23.8642C5.47087 23.7757 5.34491 23.6487 5.25767 23.496C5.17044 23.3434 5.12502 23.1704 5.12599 22.9945C5.12696 22.8187 5.17429 22.6462 5.26321 22.4945C6.08862 21.0745 7.02571 18.4766 7.02571 13.9999L7.02571 12.9999Z"
                  stroke="#4271DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
                  stroke="#4271DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="8" cy="6" r="4" fill="#FF6725" />
              </svg>
            </Link>
          ) : (
            <Link to="/notifications">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="none"
                viewBox="0 0 32 32"
              >
                <path
                  stroke="#4F4F4F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7.025 13a8.988 8.988 0 019.044-9c4.948.037 8.907 4.15 8.907 9.113V14c0 4.477.936 7.075 1.761 8.495a1.001 1.001 0 01-.86 1.505H6.123a.999.999 0 01-.86-1.505c.825-1.42 1.762-4.018 1.762-8.495v-1zM12 24v1a4 4 0 108 0v-1"
                ></path>
                <circle cx="8" cy="6" r="4" fill="#FF6725"></circle>
              </svg>
            </Link>
          )} */}
          {/* <div className="d-flex align-items-center">
                <img
                  className="avatar"
                  src={notify}
                  alt="notification"
                  style ={{width:"30px",height:"30px"}}
                  
                />
                
              </div> */}
          {/* <Dropdown className="user-dropdown">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <div className="d-flex align-items-center">
                <img
                  className="avatar"
                  src={userInfo.profileImage}
                  alt="User"
                  onError={(e) =>
                    (e.target.src = userInfo?.profileImage
                      ? userInfo.profileImage
                      : process.env.PUBLIC_URL + `/images/user.png`)
                  }
                />
                <p className="user__name mb-0">{userInfo.fullName}</p>
              </div>
              <svg
                className="icon"
                width="17"
                height="10"
                viewBox="0 0 17 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 1.25L8.5 8.75L1 1.25"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push("/profile")}>
                <Person />
                View Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  localStorage.clear();
                  history.push("/login");
                }}
              >
                <BoxArrowLeft />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </nav>
    </header>
    
  );
};

export default React.memo(connect()(withRouter(Navbar)));
