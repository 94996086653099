import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import moment from "moment";
import { CalendarRange } from "react-bootstrap-icons";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import CancelTrip from "./CancelTrip";
import rideSource from "../../assest/images/ride-app-source.svg";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OtpInput from 'react-otp-input';

let ratingCount = (value) => {
  let statusText;
  switch (value) {
    case 1:
      statusText = "😣 Poor";
      break;
    case 2:
      statusText = "😔 Bad";
      break;
    case 3:
      statusText = "🙂 Normal";
      break;
    case 4:
      statusText = "😀 Good";
      break;
    case 5:
      statusText = "😊 Excellent";
      break;
    default:
      statusText = "-";
      break;
  }
  return statusText;
};
let RidesListingTable = (props) => {
  let [showCancelTrip, setShowCancelTrip] = useState(false),

    cancelRideHandleShow = () => {
      setShowCancelTrip(true);
    };
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    currentUserRole = localStorage.getItem('role'),
    [createdAt, setCreatedAt] = useState([]),
    [isCalendarOpen, setIsCalendarOpen] = useState(false),
    [trips, setTrips] = useState([]),
    [cancelTrip, setCancelTrip] = useState(null),
    [otp, setOtp] = useState(''),
    [showOtpModal, setShowOtpModal] = useState(false),
    [selectedTrip, setSelectedTrip] = useState(null),
    [endTrip, setEndTrip] = useState(null),
    cancelRideHandleClose = () => setCancelTrip(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    statusOptions = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted by Driver" },
      { value: 3, label: "Rejected by Driver" },
      { value: 4, label: "Cancelled by Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled by Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
      { value: 11, label: "Cancelled by Admin" },
      { value: 12, label: "Booking Cancelled" },
      { value: 13, label: "Stop and Assigned" },
      { value: 14, label: "Stop and Completed" }
    ],
    tripStatusAction = [6, 8, 10, 11, 12, 14],
    [selectedStatus, setSelectedStatus] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    getStatusText = (value) => {
      let statusText;
      switch (value) {
        case 1:
          statusText = <span className="status-text-pending">Pending</span>;
          break;
        case 2:
          statusText = <span className="status-text">Accepted by Driver</span>;
          break;
        case 3:
          statusText = <span className="status-text-inactive">Rejected by Driver</span>;
          break;
        case 4:
          statusText = <span className="status-text-inactive">Cancelled by Driver</span>;
          break;
        case 5:
          statusText = <span className="status-text">Driver Arrived</span>;
          break;
        case 6:
          statusText = <span className="status-text-inactive">Cancelled by Rider</span>;
          break;
        case 7:
          statusText = <span className="status-text-pending">Started</span>;
          break;
        case 8:
          statusText = <span className="status-text">Completed</span>;
          break;
        case 9:
          statusText = <span className="status-text-pending">No Driver</span>;
          break;
        case 10:
          statusText = <span className="status-text-inactive">Expired</span>;
          break;
        case 11:
          statusText = <span className="status-text-inactive">Cancelled by Admin</span>;
          break;
        case 12:
          statusText = <span className="status-text-inactive">Booking Cancelled</span>;
          break;
        case 13:
          statusText = <span className="status-text">Stop and Assigned</span>;
          break;
        case 14:
          statusText = <span className="status-text">Stop and Completed</span>;
          break;
        default:
          statusText = "-";
          break;
      }
      return statusText;
    },
    getTrips = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post(`admin/trips`, filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let tripDataResponse = response.data.data.trips.map((trip) => {
              trip.addressDetail = trip.dropoff
              trip.statusCode = trip.status;
              trip.status = getStatusText(trip.status);
              trip.tripType = trip.tripType ? "Now" : "Scheduled";
              trip.pickup = trip.pickup.address;
              trip.dropoff = trip.dropoff.address;
              trip.riderID = trip.rider.userId;
              trip.driverID = trip.driver.userId || "";
              return trip;
            });
            setTrips(tripDataResponse);
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageTrips = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          lazyParams.filters[filter] = event.filters[filter].value;
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getTrips();
    };

  useEffect(() => {
    getTrips();
  }, []);

  let renderHeader = () => {
    return (
      <div className="table-header">
        <span className="toggle-filter">
          <button
            className="btn btn-primary show_filter_btn btnShowHide"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            className="inputwdth"
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              getTrips();
            }}
          />
        </span>
      </div>
    );
  },
    dateBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <span className="p-column-title">Date Time of booking</span>
          <span className="dataFnt-size">
            {moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")}
          </span>
        </React.Fragment>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setTimeout(() => {
        getTrips();
      }, 1500);
    },
    redirectIdTemplate = (rowData) => {
      return (
        <React.Fragment>
          {permissions.find(a => a.includes('/trip-details')) ? <Link
            to={`/trip-details/${rowData.id}`}
            className="dataFnt-size"
            style={{ color: "rgba(19, 181, 66, 1)" }}
          >
            {rowData.tripNo}
          </Link> : <span className="dataFnt-size">
            {rowData.tripNo}
          </span>}
        </React.Fragment>
      );
    };
  let calendarRef = useRef(null)
  const handleCalendarHide = () => setIsCalendarOpen(false)
  const handleDriverAction = ({ byDriver, trip, option }) => {
    if (option === 0) setCancelTrip({ byDriver, ...trip })
    else if (option === 1) {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.patch(`admin/driver-reached-at-pickup-point/` + trip.id, {
        driverId: trip.driverID
      })
        .then((response) => {
          if (response.status === 200) {
            getTrips()
          }
        })
        .catch((error) => props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false }));
    }
    else if (option === 2) {
      if (selectedTrip === null) {
        setSelectedTrip(trip)
        setShowOtpModal(true)
      }
      else {
        setShowOtpModal(false)
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.patch(`admin/started/` + trip.id, {
          tripOtp: parseInt(otp),
          driverId: trip.driverID
        })
          .then((response) => {
            if (response.status === 200) {
              setSelectedTrip(null)
              getTrips()
            }
          })
          .catch((error) => props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false }));
      }
    }
    else if (option === 3) {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.patch(`admin/completed/` + trip.id, {
        driverId: trip.driverID,
        address: trip.addressDetail.address,
        latitude: trip.addressDetail.latitude,
        longitude: trip.addressDetail.longitude,
      })
        .then((response) => {
          if (response.status === 200) {
            getTrips()
          }
        })
        .catch((error) => props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false }));
    }
  }

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={trips}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="900px"
          paginator
          emptyMessage="No Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageTrips}
          onSort={manageTrips}
          onFilter={manageTrips}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Trip Id"
            body={redirectIdTemplate}
            sortable
            filter={showFilter}
            filterField="tripNo"
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date Time of Booking"
            body={dateBodyTemplate}
            sortable
            field="createdAt"
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getTrips();
                        }, 500);
                      }
                    } else {
                      delete lazyParams.filters["createdAt"];
                      setCreatedAt([]);
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getTrips();
                      }, 500);
                    }
                  }}
                  showOnFocus={isCalendarOpen}
                  onHide={handleCalendarHide}
                  ref={calendarRef}
                  onFocus={() => setTimeout(() => {
                    calendarRef.current.showOverlay()
                  }, 100)}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            field="pickup"
            header="Pick Up"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by pickup"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            header="Drop Off"
            field="dropoff"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by dropoff"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "170px" }}
            field="riderMobileNo"
            header="Rider Contact"
            sortable
            body={(trip) => trip.rider?.mobileNo}
            filter={showFilter}
            filterPlaceholder="Search by rider Mobile Number"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "170px" }}
            field="driverMobileNo"
            header="Driver Contact"
            body={(trip) => trip.driver?.mobileNo}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by driver Mobile Number"
          />

          <Column
            className="text-center"
            headerClassName="text-right"
            style={{ fontSize: "10px", width: "120px" }}
            field="riderAmount"
            header="Total Fare"
            body={(trip) => trip.riderAmount?.toFixed(2)}
            sortable
            filter={showFilter}
            filterField="riderAmount"
            filterPlaceholder="Search by fare"
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "170px" }}
            header="Promo Applied"
            sortable
            body={(trip) => {
              return trip?.promoCode ? "Yes" : "No"
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Source"
            sortable
            body={(driver) => {
              return <img src={rideSource} alt="ride" className="nav_icon" />;
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  lazyParams.filters = {
                    ...lazyParams.filters,
                    status: event.value,
                  };
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getTrips();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Driver Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.driver?.driverReview)
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Rider Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.rider?.riderReview)
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "180px" }}
            header=" Captain Action"
            body={(trip) => {
              return trip.statusCode === 2
                ? <Dropdown
                  // value={selectedStatus}
                  options={[
                    { value: 1, label: 'Reached' },
                    { value: 0, label: 'Cancel' },
                  ]}
                  onChange={(event) => handleDriverAction({
                    byDriver: true,
                    trip,
                    option: event.value
                  })}
                  placeholder="Apply Action"
                  className="p-column-filter"
                  showClear
                />
                :
                trip.statusCode === 5
                  ? <Dropdown
                    // value={selectedStatus}
                    options={[
                      { value: 2, label: 'Start Trip' },
                      { value: 0, label: 'Cancel' },
                    ]}
                    onChange={(event) =>
                      handleDriverAction({
                        byDriver: true,
                        trip,
                        option: event.value
                      })}
                    placeholder="Apply Action"
                    className="p-column-filter"
                    showClear
                  />
                  : trip.statusCode === 7
                    ? <Dropdown
                      // value={selectedStatus}
                      options={[
                        { value: 3, label: 'End Trip' },
                        // { value: 0, label: 'Cancel' },
                      ]}
                      onChange={(event) => handleDriverAction({
                        byDriver: true,
                        trip,
                        option: event.value
                      })}
                      placeholder="Apply Action"
                      className="p-column-filter"
                      showClear
                    />
                    : (
                      "N/A"
                    );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Rider Action"
            body={(trip) => {
              return [1, 2, 5].some(a => a === trip.statusCode)
                ? <Button
                  className="cancelButton"
                  style={{ color: "red !important" }}
                  onClick={() => setCancelTrip({ ...trip, byDriver: false })}
                >
                  Cancel
                </Button>
                : (
                  "N/A"
                );
            }}
          />
        </DataTable>
      </div>

      {cancelTrip && (
        <CancelTrip
          manageTrip={setCancelTrip}
          cancelTrip={cancelTrip}
          // endTrip={endTrip}
          cancelRideHandleClose={cancelRideHandleClose}
          refreshTrips={() => getTrips()}
        />
      )}
      <Modal show={showOtpModal} centered onHide={() => {
        setSelectedTrip(null)
        setShowOtpModal(false)
      }}>
        <Modal.Header>
          <Modal.Title className="modal-title">
            Enter the Otp
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={() =>
            Yup.object().shape({
              otp: otp.length < 4 && Yup.string().required("Otp is Required")
            })
          }
          onSubmit={(v) => {
            handleDriverAction({
              byDriver: true,
              trip: selectedTrip,
              option: 2
            })
          }}
        >
          {() => {
            return (
              <Form>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  name="otp"
                  numInputs={4}
                  renderSeparator={<span style={{ color: 'white' }}> --- </span>}
                  inputStyle={{
                    height: '2em',
                    width: '2em',
                  }}
                  containerStyle={{
                    justifyContent: 'center',
                    padding: '10px'
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                <ErrorMessage
                  name="otp"
                  component="div"
                  className="invalid-feedback text-danger"
                  style={{ textAlign: 'center', marginBottom: '10px' }}
                />
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save"
                    style={{
                      width: '82px',
                      height: '30px'
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>

      </Modal>

    </div>
  );
};

export default React.memo(connect()(RidesListingTable));
