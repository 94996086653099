import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import APIService from "../services/APIService";
import { CSVLink } from "react-csv";

let Category = (props) => {
  const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };
  const categoryId = props.match.params.id
  const category = props.history.location?.state?.name
  let [selectedStatus, setSelectedStatus] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [accessName, setAcessName] = useState(""),
    [accessCode, setAcessCode] = useState(""),
    [fillRider, setFillRider] = useState([]),
    [currentEdit, setCurrentEdit] = useState(),
    [show, setShow] = useState(false),
    [showUpdate, setShowUpdate] = useState(false),
    [subStatus, setSubStatus] = useState(null),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    allPermissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = allPermissions.filter(a => a.includes('/category-permissions/')),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [permissions, setPermissions] = useState([]),
    getDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.get("admin/capabilities/all")
        .then((res) => {
          if (res.data.statusCode == 200) {
            let allPermissions = res.data.data.filter(a => a.id === categoryId)
            setPermissions(allPermissions[0]?.permissions)
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        }).catch(e => console.log(e))
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    subStatusOptions = [
      {
        label: "Active",
        value: 1,
      },
      {
        label: "Overdue",
        value: 2,
      },
      {
        label: "Expired",
        value: 3,
      },
    ],
    headers = [
      { label: 'ID', key: 'externalId' },
      { label: 'Full Name', key: 'driverName' },
      { label: 'Service', key: 'Service' },
      { label: 'Rating', key: 'overallRating' },
      { label: 'Loyailty Points', key: 'LoyaltyPoints' },
      { label: 'Moblie No', key: 'mobileNo' },
      { label: 'Data & Time', key: 'createdAt' },
      { label: 'Total Trips', key: 'totalTrips' },
      { label: 'KYC', key: 'KYC' },
      { label: 'Status', key: 'userStatus' }
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getDrivers();
              }}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename='Captains.csv'
            target='_blank'
            ref={CSVdownloadRef}
          />
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button className="btn btn-primary float-right mb-1 ml-12" onClick={() => setShow(true)}>
            ADD
          </button>}
          {/* <button
            className="btn btn-primary float-right mb-1 ml-12"

          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
          </button> */}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getDrivers();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getDrivers();
  }, []);
  // get captain table data in csv
  const downloadCsvFile = () => {
    APIService.post("admin/captains")
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {


          const Response = response.data.data.captains;
          var filterResponse = Response.map((item) => {
            return (
              item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
              item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
              item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
              // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''



            )
          })


          setDownloadCsv(Response);

          setTimeout(() => {

            CSVdownloadRef.current.link.click();
          }, 500)

        }
      })
  }

  const handleClose = () => {
    setShow(false)
    setShowUpdate(false)
  }
  const createPermission = () => {
    let obj = {
      categoryId,
      category,
      accessName,
      accessCode,
      sequence: 1
    }
    APIService.post("/admin/permission", obj)
      .then((res) => {
        getDrivers()
        setShow(false)
      }).catch(e => console.log(e))
  }
  const updatePermission = () => {
    let obj = {
      category,
      accessName,
      accessCode,
      sequence: 1
    }
    APIService.get('/admin/permission')
      .then(res => {
        let getCurrentPermission = res.data?.data.filter(a => a.accessCode === currentEdit.accessCode)
        APIService.patch(`/admin/permission/${getCurrentPermission[0].id}`, obj)
          .then(res => {
            getDrivers()
            setShowUpdate(false)
          })
      })


  }
  const deleteCategory = (rowData) => {
    APIService.get('/admin/permission')
      .then(res => {
        let getCurrentPermission = res.data?.data.filter(a => a.accessCode === rowData.accessCode)
        console.log('id', getCurrentPermission[0])
        APIService.delete(`/admin/permission/${getCurrentPermission[0].id}`)
          .then((res) => {
            toast.success("Category Deleted Successfully")
            getDrivers()
          }).catch(e => console.log(e))
      })

  }
  const actionTemplate = (rowData) => {
    return (
      <div className="button p-table-button">
        {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && <Button
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            setCurrentEdit(rowData)
            setAcessName(rowData.accessName)
            setAcessCode(rowData.accessCode)
            setShowUpdate(true)
          }}
        >
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
              fill="#03053D"
            />
          </svg>
        </Button>}
        {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <Button
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            deleteCategory(rowData);
          }}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
              fill="#03053D"
            />
          </svg>
        </Button>}
        {!currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && !currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'delete') && <span>No permission</span>}
      </div>
    );
  };


  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={permissions}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="Access Code"
            field="accessCode"
            // body={(category) => {
            //   console.log(category)
            //   return (
            //     <div className="text-left">
            //       <Link
            //         to={"/category-permissions/" + category.accessCode}
            //         className="dataFnt-size"
            //         style={{ color: "rgba(19, 181, 66, 1)" }}
            //       >

            //         <span className="dataFnt-size" style={{ color: "#13B542" }}>
            //           {category.accessCode}
            //         </span>
            //       </Link>
            //     </div>
            //   );
            // }}
            sortable
            filter={showFilter}
            filterMatchMode="startsWith"
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Access Name"
            field="accessName"
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={customFunction}
            filterPlaceholder="Search by name"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
            filterPlaceholder="Minimum"
          />

        </DataTable>
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header>
            <Modal.Title className="modal-title">
              Add New Permission
            </Modal.Title>
            <div className="divclose" onClick={handleClose}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="mt-24">
            <Row className="row">
              <Col md={6}>
                <div className="form-group form-label-group">
                  <InputText
                    type="text"
                    name="accessname"
                    className="shadow-none form-control modal-input br-10"
                    placeholder="Access Name"
                    onChange={(e) => {
                      setAcessName(e.target.value)
                    }}
                    value={accessName}
                  />
                  <label htmlFor="accessname">Acess Name</label>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group form-label-group">
                  <InputText
                    type="text"
                    name="accesscode"
                    className="shadow-none form-control modal-input br-10"
                    placeholder="acess code"
                    onChange={(e) => {
                      setAcessCode(e.target.value)
                    }}
                    value={accessCode}
                  />
                  <label htmlFor="accesscode">Acess Code</label>
                </div>
              </Col>

              <Col md={12} className="d-flex">
                <Col md={6}>
                  <Button
                    variant="success"
                    className="btn-save save-button mb-3 btn  btn-block"
                    // type="submit"
                    onClick={createPermission}
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showUpdate} onHide={handleClose} centered size="lg">
          <Modal.Header>
            <Modal.Title className="modal-title">
              Update User
            </Modal.Title>
            <div className="divclose" onClick={handleClose}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="mt-24">
            <Row className="row">
              <Col md={6}>
                <div className="form-group form-label-group">
                  <InputText
                    type="text"
                    name="accessname"
                    className="shadow-none form-control modal-input br-10"
                    placeholder="Access Name"
                    onChange={(e) => {
                      setAcessName(e.target.value)
                    }}
                    value={accessName}
                  />
                  <label htmlFor="accessname">Acess Name</label>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group form-label-group">
                  <InputText
                    type="text"
                    name="accesscode"
                    className="shadow-none form-control modal-input br-10"
                    placeholder="acess code"
                    onChange={(e) => {
                      setAcessCode(e.target.value)
                    }}
                    value={accessCode}
                  />
                  <label htmlFor="accesscode">Acess Code</label>
                </div>
              </Col>

              <Col md={12} className="d-flex">
                <Col md={6}>
                  <Button
                    variant="success"
                    className="btn-save save-button mb-3 btn  btn-block"
                    // type="submit"
                    onClick={updatePermission}
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default React.memo(connect()(withRouter(Category)));
