import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import VehicelPlaceHolder from "../assest/images/vehicel.png";
import { Button, Card, Col, Row } from "react-bootstrap";
import APIService from "../services/APIService";
import Select, { StylesConfig } from 'react-select';
import { useParams } from "react-router";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../sass/NotificationsManage.sass";
import { Link } from "react-router-dom";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled
        ? 'white'
        : isSelected
        ? "#28a745"
        : "#28a745",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },

};
let VehicleEditPage = (props) => {
  let { id } = useParams(),
    [vehicleType, setVehicleType] = useState(null),
    [vehicle, setVehicle] = useState(null),
    [vehicleTypeArabic, setVehicleTypeArabic] = useState(null),
    [vehicleTypes, setVehicleTypes] = useState([]),
    [vehicleTypesArabic, setVehicleTypesArabic] = React.useState([]),
    [vehiclePhoto, setVehiclePhoto] = useState(null),
    [vehicleTypePhotoName, setVehicleTypePhotoName] = useState(null),
    validationSchema = Yup.object().shape({}),
    getVehicleType = () => {
      props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      APIService.get("admin/cab-type/all")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let vehicleTypesEng = response.data.data.map((vehicleType) => {
                return {
                  value: vehicleType.id,
                  label: vehicleType.name,
                };
              }),
              vehicleTypeArabic = response.data.data.map((vehicleType) => {
                return {
                  value: vehicleType.id,
                  label: vehicleType.nameArabic,
                };
              });
            APIService.get("admin/vehicle/" + id)
              .then((response) => {
                if (response.status === 200) {
                  props.dispatch({ type: "TOGGLE_LOADER", payload: false });
                  let vehicle = response.data.data;
                  vehicle.vehicleType = vehicle?.cab?.id || null;
                  setVehicleTypes(vehicleTypesEng);
                  setVehicleTypesArabic(vehicleTypeArabic);
                  setVehicle(vehicle);
                  setVehicleType(
                    vehicleTypesEng.find(
                      (vt) => vt.value === vehicle.vehicleType
                    )
                  );
                  setVehicleTypeArabic(
                    vehicleTypeArabic.find(
                      (vt) => vt.value === vehicle.vehicleType
                    )
                  );
                }
              })
              .catch((error) => {
                props.dispatch({ type: "TOGGLE_LOADER", payload: false });
              });
          }
        })
        .catch(() => props.dispatch({ type: "TOGGLE_LOADER", payload: false }));
    },
    updateVehicle = (vehicle, formProps) => {
      vehicle["cab"] = vehicle["vehicleType"];
      // delete vehicle["lkVehicleClass"];
      let formData = new FormData();
      Object.keys(vehicle).forEach((key) => {
        formData.append(key, vehicle[key]);
      });
      if (vehiclePhoto) {
        formData.append("vehicleImage", vehiclePhoto);
      }
      APIService.patch("admin/vehicle/" + id, formData)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            toast.success("Vehicle update successfully");
          }
        })
        .catch(() => props.dispatch({ type: "TOGGLE_LOADER", payload: false }));
    };

  useEffect(() => {
    getVehicleType();
  }, []);

  return (
    <div className="rider-listing notification-manage">
      <Row className="row justify-content-between">
        <div className="col-7">
          <Link to="/vehicles" title="Back">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Update Vehicle</span>
        </div>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{ ...vehicle }}
        validationSchema={validationSchema}
        onSubmit={updateVehicle}
      >
        {({ setFieldValue, errors, handleBlur }) => {
          return (
            <Form>
              <Card className="notification-card">
                <div className="preview__img__main d-flex justify-content-center pb-4">
                  <img
                    src={
                      vehicle?.vehicleImageUrl
                        ? vehicle.vehicleImageUrl
                        : VehicelPlaceHolder
                    }
                    onError={(e) =>
                      (e.target.src =
                        process.env.PUBLIC_URL + `/images/car.png`)
                    }
                    alt={"english.vehicleImage"}
                    className="p-column-listing-photo"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="form-group">
                  <div className="form-file-upload form-label-group">
                    <div className="form-file-name">{vehicleTypePhotoName}</div>
                    <div className="form-file-icon">
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM11 6V13H9V6H4L10 0L16 6H11Z"
                          fill="#03053D"
                        />
                      </svg>
                    </div>
                    <input
                      id="fileupload"
                      className="form-control modal-input br-10"
                      placeholder="name"
                      type="file"
                      accept="image/*"
                      name="fileupload"
                   
                      onChange={(e) => {
                        if (e.target.files) {
                          setVehiclePhoto(e.target.files[0]);
                          setVehicleTypePhotoName(e.target.files[0]?.name);
                        }
                      }}
                    />
                    <label htmlFor="fileupload">Vehicle Photo</label>
                    <ErrorMessage
                      name="fileupload"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text"    >
                        Select Vehicle Category
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-4" >
                        <Select
                          className="height-50 br-10"
                          placeholder="Select Vehicle Category"
                          name="vehicleType"
                          styles={colourStyles}
                          id="vehicleType"
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("vehicleType", e.value);
                              setVehicleType(e);
                              setVehicleTypeArabic(
                                vehicleTypesArabic.find(
                                  (vt) => vt.value === e.value
                                )
                              );
                            }
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "vehicleType" } });
                          }}
                          value={vehicleType}
                          options={vehicleTypes}
                        />
                        <ErrorMessage
                          name="vehicleType"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Select Vehicle Category
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-4" >
                        <Select
                          className="height-50 br-10"
                          placeholder="Select Vehicle Category"
                          name="vehicleTypeArabic"
                          id="vehicleTypeArabic"
                          styles={colourStyles}
                          value={vehicleTypeArabic}
                          options={vehicleTypesArabic}
                          isDisabled={true}
                        />
                        <ErrorMessage
                          name="vehicleTypeArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Major Color
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Major Color"
                          name="majorColorEnglish"
                          id="majorColorEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="majorColorEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Major Color
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="majorColor"
                          name="majorColor"
                          id="majorColor"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="majorColor"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Maker
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Major Color"
                          name="vehicleMakerEnglish"
                          id="vehicleMakerEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleMakerEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Maker
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="majorColor"
                          name="vehicleMaker"
                          id="vehicleMaker"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleMaker"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Model
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Model"
                          name="vehicleModelEnglish"
                          id="vehicleModelEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleModelEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Model
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Model"
                          name="vehicleModel"
                          id="vehicleModelArabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleModelArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-save save-button mb-3 border-radius-15"
                      style={{ width: "200px" }}
                    >
                      <span className="btn-text">Save Changes</span>
                    </Button>

                    <Link
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15 ml-3"
                      style={{ width: "200px" }}
                      to={"../vehicles"}
                    >
                      <Button
                        variant="light"
                        style={{ width: "200px" }}
                        className="btn-cancel save-button mb-3 border-radius-15"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Row>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(connect()(VehicleEditPage));
