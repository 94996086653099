import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { CalendarRange, CheckCircle } from "react-bootstrap-icons";
import PhoneInput from "react-phone-number-input";
import { connect, useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import APIService from "../../services/APIService";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#ffffff"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};

let DriverRegistrationListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [createdAt, setCreatedAt] = useState([]),
    [show, setShow] = useState(false),
    [tIdFromRes, setTIdFromRes] = useState(""),
    [mobileNo, setMobileNo] = useState(""),
    [userId, setUserId] = useState(""),
    [sessionToken, setSessionToken] = useState(""),
    [step, setStep] = useState(1),
    [hasOtp, setHasOtp] = useState(false),
    [showFilter, setShowFilter] = useState(false),
    [submitting, isSubmitting] = useState(false),
    [drivers, setDrivers] = React.useState([]),
    [carInfo, setCarInfo] = useState(null),
    [minutes, setMinutes] = useState(0),
    [seconds, setSeconds] = useState(0),
    [sendotp, setSendotp] = useState(true),
    [subscriptionPlans, setSubscriptionPlans] = useState(null),
    [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState(null),
    [selectedDrivers, setSelectedDrivers] = React.useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    userToken=localStorage.getItem('token'),
    getRegisterDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/notify-users", filterParams)
        .then((respose) => {
          if (respose.status === 201) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = respose.data.data.totalCount;
            setLazyParams(lazyParams);
            setDrivers(respose.data.data.registrations);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    dt = useRef(null),
    renderHeader = () => {
      return (
        <>
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => {}}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up"></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
              </button>
            </span>

            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search..."
                value={lazyParams.keyword}
                onInput={(e) => {
                  lazyParams = {
                    ...lazyParams,
                    skip: 0,
                    take: 25,
                    keyword: e.target.value,
                  };
                  // setLazyParams(lazyParams);
                  // getRiders();
                }}
              />
            </span>
            {/* <CSVLink
              data={[]}
              headers={{}}
              filename='Rdiers.csv'
              target='_blank'
              ref={null}
            /> */}
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              // onClick={() => exportCSV(false)}
              onClick={() => {}}
            >
              Export
            </button>
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={() => {
                setShow(true);
              }}
            >
              {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
            </button>
          </div>
        </>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getRegisterDrivers();
    },
    manageDriverRegistration = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getRegisterDrivers();
    };

  useEffect(() => {
    getRegisterDrivers();
  }, []);

  useEffect(() => {
    getRegisterDrivers();
  }, [mobileNo]);
  let ticketType = [
    { value: 1, label: "Captain" },
    { value: 8, label: "Rider" },
  ];
  let service = [{ value: 1, label: "Own a car" }];
  const showModalContent = (value) => {
    let modalContent;
    switch (value) {
      case 1:
        modalContent = modalOneData();
        break;
      case 2:
        modalContent = modalTwoData();
        break;
      case 3:
        modalContent = modalThreeData();
        break;
      case 4:
        modalContent = modalFourData();
        break;
      case 5:
        modalContent = modalFiveData();
        break;
      default:
        modalContent = "";
        break;
    }
    return modalContent;
  };

  const registerUser =async()=>{
    const _registerUserURL = "http://uat-api.ride.sa/" + `admin/update/captains?userId=${userId}`;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          "authorization":`Bearer ${userToken}`

        },
      };

      const response = await fetch(_registerUserURL, requestOptions);
      let final_data = await response.json();    
  }  

  const validationSchema = Yup.object().shape({
    userId: Yup.number().required("Please enter user id"),
    liecenseExpiry: Yup.string()
      .nullable()
      .required("Enter liecense expiry date"),
    driver: Yup.string().nullable().required("Select a driver"),
    service: Yup.string().nullable().required("Select a service"),
    mobileNo: Yup.number().nullable().required("Please enter mobile number"),
    otp: hasOtp && Yup.string().nullable().required("Please enter otp"),
  });
  const validationSchemaForSquenceNumber = Yup.object().shape({
    sequenceNumber: Yup.number().required("Please enter sequence number"),
  });

  const sendOtp = async (values) => {
    if (!hasOtp && step == 1) {
      const _getOtpURL = "http://uat-api.ride.sa/" + "sendotp";
      isSubmitting(true);
      setMinutes(1);
      setSeconds(0);
      let mobileNo = values.mobileNo.slice(1);
      let reason = 2;
      let userid = values.userId;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          mobileNo: mobileNo,
          reason: reason,
          userid: userid,
        }),
      };

      const response = await fetch(_getOtpURL, requestOptions);
      let final_data = await response.json();
      if (final_data?.data?.tId) {
        setTIdFromRes(final_data?.data?.tId);
        setUserId(userid);
        localStorage.setItem("sessionToken", final_data?.data?.tId);
        setHasOtp(true);
        isSubmitting(false);
      } else {
        isSubmitting(false);
        toast.error(final_data?.message);
        return;
      }
    }
  };

  const firstStepSubmition = async (values) => {
    if (!hasOtp && step == 1) {
      sendOtp(values);
    } else if (hasOtp && step == 1) {
      const _verifyOtpURL = "http://uat-api.ride.sa/" + "verifyotp";
      isSubmitting(true);
      let mobileNo = values.mobileNo.slice(1);
      let otp = values.otp;
      let tId = tIdFromRes;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          mobileNo: mobileNo,
          otp: String(otp),
          tId: tId,
        }),
      };

      const response = await fetch(_verifyOtpURL, requestOptions);
      let final_data = await response.json();
      if (final_data?.data) {
        setHasOtp(true);
        setSessionToken(final_data?.data?.token);
        isSubmitting(false);
        setStep(2);
      } else {
        isSubmitting(false);
        return;
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const carPlateNumber =
    carInfo?.plateNumber +
    "-" +
    carInfo?.plateText1English +
    carInfo?.plateText2English +
    carInfo?.plateText3English;

 

  const modalOneData = () => {
    return (
      <Formik
        initialValues={{
          userId: "",
          liecenseExpiry: "",
          driver: "",
          service: "",
          mobileNo: "",
          driver: "",
          otp: hasOtp ? "" : "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          firstStepSubmition(values);
        }}
      >
        {({ handleBlur, setFieldValue, values }) => {
          return (
            <Form>
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Add New Captain
                </Modal.Title>
                <div
                  className="divclose"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mt-24">
                <Row>
                  <Col md={12}>
                    <div className="mb-3 form-group form-label-group ">
                      <Field
                        id="userId"
                        type="number"
                        name="userId"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="6196290179"
                      />
                      <label>ID Number</label>
                      <ErrorMessage
                        name="userId"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3 form-group form-label-group ">
                      <Field
                        id="liecenseExpiry"
                        type="text"
                        name="liecenseExpiry"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="28 - 08 - 2025"
                      />
                      <label>License Expiry Date</label>
                      <ErrorMessage
                        name="liecenseExpiry"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3 form-group  form-label-group ">
                      <PhoneInput
                        countryCallingCodeEditable={false}
                        className="d-flex"
                        international
                        defaultCountry="SA"
                        name="mobileNo"
                        required
                        // value={mobleNo}
                        onChange={async (value) =>
                          await setFieldValue("mobileNo", value)
                        }
                      />
                      <label></label>
                      <ErrorMessage
                        name="mobileNo"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3 form-label-group ">
                      <Select
                        className="height-50 br-10 bg-color-select"
                        placeholder="Select"
                        name="driver"
                        styles={colourStyles}
                        isSearchable={false}
                        onBlur={() => {
                          handleBlur({ target: { name: "driver" } });
                        }}
                        onChange={async (selectedOption) =>
                          await setFieldValue("driver", selectedOption.value)
                        }
                        options={ticketType}
                      />
                      <label>Driver</label>
                      <ErrorMessage
                        name="driver"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3 form-label-group ">
                      <Select
                        className="height-50 br-10 bg-color-select"
                        placeholder="Select"
                        name="service"
                        isSearchable={false}
                        styles={colourStyles}
                        onBlur={() => {
                          handleBlur({ target: { name: "service" } });
                        }}
                        onChange={async (selectedOption) =>
                          await setFieldValue("service", selectedOption.value)
                        }
                        options={service}
                      />
                      <label>Service</label>
                      <ErrorMessage
                        name="service"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  {hasOtp && (
                    <Col md={12}>
                      <div className="mb-3 form-group form-label-group ">
                        <Field
                          id="content"
                          type="number"
                          name="otp"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="6196290179"
                        />
                        <label>OTP</label>
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={12}>
                    <div className="form-group form-label-group">
                      Didn’t receive the OTP?{" "}
                      <span
                        onClick={() => {}}
                        disabled={!sendotp}
                        style={{ cursor: "pointer" }}
                        className="text-success font-weight-bold"
                      >
                        {" "}
                        Resend OTP
                      </span>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    {(seconds > 0 || minutes > 0) && !hasOtp ? (
                      <span className="ml-2 font-weight-bold text-dark">
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                        {setSendotp(false)}
                      </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col md={12}>
                    <Button
                      disabled={submitting}
                      variant="primary"
                      type="submit"
                      className="btn-save btn-block"
                    >
                      {submitting && (
                        <span className="spinner-grow spinner-grow-sm"></span>
                      )}
                      {hasOtp ? "Verify OTP & Proceed" : "Send OTP"}
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const modalTwoData = () => {
    return (
      <Formik
        initialValues={{
          sequenceNumber: "",
        }}
        validationSchema={validationSchemaForSquenceNumber}
        onSubmit={async (values) => {
          if (step == 2) {
            const _getCarInfoURL =
              "http://uat-api.ride.sa/" + "car-info-by-sequence";

            isSubmitting(true);
            let sequenceNumber = String(values.sequenceNumber);
            let userid = String(userId);

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "*",
                sessionId: sessionToken,
              },
              body: JSON.stringify({
                sequenceNumber: sequenceNumber,
                userid: userid,
              }),
            };
            const response = await fetch(_getCarInfoURL, requestOptions);
            let final_data = await response.json();
            if (final_data?.data) {
              setCarInfo(final_data?.data);
              setStep(3);
              setHasOtp(true);
              isSubmitting(false);
            } else {
              isSubmitting(false);
              toast.error(final_data?.message);

              return;
            }
          }
        }}
      >
        {({ handleBlur, setFieldValue, values }) => {
          return (
            <Form>
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Add Car Sequence No.{" "}
                </Modal.Title>
                <div
                  className="divclose"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mt-24">
                <Row>
                  <Col md={12}>
                    <div className="mb-3 form-group form-label-group ">
                      <Field
                        id="sequenceNumber"
                        type="number"
                        name="sequenceNumber"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="6196290179"
                      />
                      <label>Sequence No.</label>
                      <ErrorMessage
                        name="sequenceNumber"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex rounded bg-light my-3 p-2">
                      <div>
                        <img src="/images/black-car.svg" />
                      </div>
                      <div className="d-flex flex-column w-75 ml-2  ">
                        <span className="font-weight-bold">
                          How to find car sequence number for your car
                        </span>
                        <span className="text-success font-weight-bold">
                          {" "}
                          Learn More
                        </span>
                      </div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <Button
                      disabled={submitting}
                      variant="primary"
                      type="submit"
                      className="btn-save btn-block"
                    >
                      {submitting && (
                        <span className="spinner-grow spinner-grow-sm"></span>
                      )}
                      Done
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const onThirdStepSubmit = async () => {
    const _getSubscription = "http://uat-api.ride.sa/" + "subscription";

    isSubmitting(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
        sessionId: sessionToken,
      },
    };
    const response = await fetch(_getSubscription, requestOptions);
    let final_data = await response.json();
    if (final_data) {
      setSubscriptionPlans(final_data?.data);
      isSubmitting(false);
      setStep(4);
    } else {
      isSubmitting(false);
    }
  };

  const modalThreeData = () => {
    return (
      <>
        <Modal.Header>
          <Modal.Title className="modal-title">Confirm Details </Modal.Title>
          <div
            className="divclose"
            onClick={() => {
              setShow(false);
            }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-24">
          <Row>
            <Col md={12}>
              <div className="mb-3 form-group form-label-group text-center ">
                <img />
              </div>
              <div className="mb-3 form-group form-label-group text-center">
                {carInfo?.vehicleMaker || ""} ({carInfo?.modelYear || ""})
              </div>
              <div className="mb-3 form-group form-label-group text-center">
                {carInfo?.ownerId || ""}
                {carInfo?.vehicleCapacity && (
                  <span>
                    {" "}
                    <img src="/images/user.svg" />4 seats
                  </span>
                )}
              </div>
              <div className="mb-3 form-group form-label-group text-center ">
                {carInfo?.plateNumber &&
                  carInfo?.plateNumber +
                    " " +
                    carInfo?.plateText1English +
                    carInfo?.plateText2English +
                    carInfo?.plateText3English}{" "}
              </div>
            </Col>

            <Col md={12}>
              <Button
                // disabled={submitting}
                variant="primary"
                type="submit"
                className="btn-save btn-block"
                onClick={onThirdStepSubmit}
              >
                {submitting && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </>
    );
  };

  const modalFiveData = () => {
    return (
      <Formik
        initialValues={{
          ticket_type: "",
          name: "",
          phone: "",
          comment: "",
          assign_type: "",
        }}
        // onSubmit={async (values) => {
        //   isSubmitting(true);
        //   let dept_id = values.ticket_type;
        //   let description = values.comment;
        //   let name = `${driverDetail?.CitizenDLInfo?.englishFirstName?._text} ${driverDetail?.CitizenDLInfo?.englishSecondName?._text}  ${driverDetail?.CitizenDLInfo?.englishLastName?._text}`;
        //   let phone = driver?.mobileNo;
        //   let assign_type = ticketSelectionType.value;
        //   const requestOptions = {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       "X-Requested-With": "XMLHttpRequest",
        //       "Access-Control-Allow-Origin": "*",
        //     },
        //     body: JSON.stringify({
        //       category_id: dept_id,
        //       description: description,
        //       secret_key: secret_key,
        //       name: name,
        //       contact_no: phone,
        //       type: assign_type,
        //     }),
        //   };
        //   const response = await fetch(_saveTicketURL, requestOptions);
        //   let final_data = await response.json();
        //   if (final_data.success === true)
        //     toast.success("Ticket created successfully!");
        //   else toast.error("Opsss! Ticket not created.");
        //   isSubmitting(false);
        //   handleCloseTicket();
        // }}
      >
        {({ handleBlur, setFieldValue, values }) => {
          return (
            <Form>
              <Modal.Header>
                <div
                  className="divclose"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mt-24 d-flex justify-content-center">
                <Row>
                  <Col md={12}>
                    <div className="mb-3 form-group form-label-group text-center ">
                      <CheckCircle size={80} color="#13B542" />
                    </div>
                    <div className="font-weight-bold text-center">
                      New captain is added successfully
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const modalFourData = () => {
    return (
      <Formik
        onSubmit={async (values) => {
          const _onBoardDriverURL =
            "http://uat-api.ride.sa/" + "captains/become-captain";
          isSubmitting(true);
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              "Access-Control-Allow-Origin": "*",
              sessionId: sessionToken,
            },
            body: JSON.stringify({
              subscriptionId: currentSubscriptionPlan,
              autoRenewal: false,
              driverNationalId: String(userId),
              carPlateNo: carPlateNumber,
              carSequenceNo: String(carInfo?.carSequenceNo),
              carLicenceType: 1,
              cab: "Toyota",
              drivingModes: [
                {
                  drivingMode: 1,
                },
              ],
              acceptTC: false,
            }),
          };
          const response = await fetch(_onBoardDriverURL, requestOptions);
          registerUser();
          let final_data = await response.json();
          if (final_data.success === true) {
            toast.success("Driver registered successfully!");
            setStep(5);
            isSubmitting(false);
          } else {
            toast.error("Driver already registered with this national ID");
            isSubmitting(false);
          }
        }}
      >
        {({ handleBlur, setFieldValue, values }) => {
          return (
            <Form>
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Choose subscription{" "}
                </Modal.Title>
                <div
                  className="divclose"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mt-24">
                <Row>
                  {subscriptionPlans?.map((item, idx) => {
                    return (
                      <Col
                        md={12}
                        key={idx}
                        onClick={() => {
                          setCurrentSubscriptionPlan(item?.id);
                        }}
                        className="my-2"
                      >
                        <div
                          className={
                            currentSubscriptionPlan == item?.id
                              ? "rounded-2 shadow-lg flex-column h-100 w-100 d-flex"
                              : "rounded-2 flex-column shadow h-100 w-100 d-flex"
                          }
                        >
                          <div className="p-2 rounded-top text-white bg-dark">
                            {item?.packageName || "-"}
                          </div>
                          <div className="d-flex flex-column text-center p-2 rounded-bottom">
                            <span className="text-dark font-weight-bold mb-2">
                              {item?.finalPrice &&
                                `${item?.finalPrice} SAR / month`}
                            </span>
                            <span className="text-secondary my-1">
                              {item?.packageDescription || "-"}{" "}
                            </span>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  <Col md={12}>
                    <Button
                      disabled={submitting}
                      variant="primary"
                      type="submit"
                      className="btn-save btn-block"
                    >
                      {submitting && (
                        <span className="spinner-grow spinner-grow-sm"></span>
                      )}
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={drivers}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="700px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedDrivers(e.value)}
          lazy={true}
          totalRecords={lazyParams.totalRecords}
          selection={selectedDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDriverRegistration}
          onSort={manageDriverRegistration}
          onFilter={manageDriverRegistration}
        >
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            field="inquiryId"
            header="Inquiry Id"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Id"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            field="fullName"
            header="Full Name"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by full name"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            sortable
            header="City"
            field="city"
            filter={showFilter}
            filterField="city"
            filterMatchMode="contains"
            filterPlaceholder="Search by city"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Mobile Number"
            field="mobileNo"
            body={(driver) =>
              driver.mobileNo?.slice(4, driver.mobileNo?.length)
            }
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by mobile Number"
          />
          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "250px" }}
            sortField="email"
            header="Email"
            field="email"
            sortable
            filter={showFilter}
            filterField="email"
            filterMatchMode="contains"
            filterPlaceholder="Search by email"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Inquiry Date"
            field="createdAt"
            sortable
            body={(driver) => (
              <React.Fragment>
                <span className="dataFnt-size">
                  <span className="p-column-title">Inquiry Date</span>
                  {moment(driver.createdAt).format("DD/MM/YYYY hh:mm A")}
                </span>
              </React.Fragment>
            )}
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getRegisterDrivers();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getRegisterDrivers();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
        </DataTable>
      </div>
      <Modal
        dialogClassName="delete-modal-width"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <div> {showModalContent(step)}</div>
      </Modal>
    </div>
  );
};

export default React.memo(connect()(DriverRegistrationListing));
