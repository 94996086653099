import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import SubscriptionsPackage from "../components/Subscriptions/SubscriptionsPackage";
import ExpiredSubscriptionsListingTable from "../components/Subscriptions/ExpiredSubscriptionsListingTable";
import DriverEarnings from "../components/accountings/DriverEarnings";
import Main from "../components/accountings/Main";
import DriverSubscription from "../components/accountings/DriverSubscription";
import Tax from "../components/accountings/Tax";
import Topups from "../components/accountings/Topups";
import Fees from "../components/accountings/Fees";
import NotificationPushPage from "./NotificationPushPage";
import NotificationSMSPage from "./NotificationSMSPage";
import NotificationEmailPage from "./NotificationEmailPage";

let RideNotification = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <span className="title">Notifications</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/notification/${tab}`);
          }}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}


          {permissions.find(a => a.includes('/notification/notification-push')) &&
            <Tab eventKey="notification-push" title="Push">
              {tab === "notification-push" && <NotificationPushPage locationData={props} />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/notification/notification-sms')) &&
            <Tab eventKey="notification-sms" title="SMS">
              {tab === "notification-sms" && <NotificationSMSPage />}
              {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
            </Tab>
          }
          {permissions.find(a => a.includes('/notification/notification-emails')) &&
            <Tab eventKey="notification-emails" title="Email">
              {tab === "notification-emails" && <NotificationEmailPage />}
            </Tab>
          }

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(RideNotification);
