import React from 'react';

let NoAccess = () => {
    return (
        <div
            className='text-center  d-flex flex-column align-items-center justify-content-center'
            style={{ height: '75vh' }}>
            <img
                className='mb-3'
                width='150px'
                height='150px'
                src={process.env.PUBLIC_URL + '/images/icon-close.svg'}
                alt='close'
            />
            <h1>Access Denied</h1>
        </div>
    );
};
export default React.memo(NoAccess);
