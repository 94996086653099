import React, { useState, useEffect,useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import { InputText } from "primereact/inputtext";
import moment from "moment";
let PaymentHistory = (props) => {
  const { riderView } = props;
  const [paymentHistory, setPaymentHistory] = useState([]),
  [windowWidth] = useState(useSelector((state) => state.windowSize)),
  [createdAt, setCreatedAt] = useState(null),
  [showFilter, setShowFilter] = useState(false),
  dt = useRef(null),
  [selectedTripHistory, setSelectedTripHistory] = useState(null),
  [lazyParams, setLazyParams] = useState({

    filters: {},
    sort: {
      field: "createdAt",
      order: -1,
    },
    take: 25,
    skip: 0,
    keyword: "",
    totalRecords: 0,
  })
  let renderHeader = () => {
    return (
      <div className="table-header">
        <span className="toggle-filter">
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
            showFilter ? (
              <>
                 <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
              </>
            ) : (
              <>
                   <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z" fill="#828282"/>
                  </svg>
              </>
            )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            value={lazyParams.keyword}
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              // getTripHistory();
            }}
          />
        </span>
      </div>
    );
  },
  header = renderHeader(),
  toggleFilter = () => {
    setShowFilter(!showFilter);
    setCreatedAt([]);
    // setFilterStatus(null);
    setLazyParams({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      totalRecords: 0,
    });
    // getTripHistory();
  };

  
  let topUpsDetail = () => {
  
  };
  

  useEffect(() => {
    topUpsDetail();
  }, [riderView]);

  return (
    <>
      <div className="datatable-doc-demo mt-3">
      <DataTable
          ref={dt}
          value={paymentHistory}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
           scrollable
           scrollHeight="470px"
         
          emptyMessage="No Ticket History(s) found"
     
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
        
          <Column
            style={{ width: "110px", textAlign: "center" }}
            field="profileId"
            header="User ID"
             body={(rowData) => rowData.userId || ""}
          ></Column>
           <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <span className="dataFnt-size">
                    {rowData.createdAt &&
                      moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </span>
                </React.Fragment>
              );
            }}
           
            
          />
          <Column
            style={{ width: "130px", textAlign: "center" }}
            field="cart_amount"
            header="Amount"
           body={(rowData) => rowData.cart_amount || ""}
          ></Column>
          <Column
            style={{ width: "110px" }}
            field="cart_currency"
            header="Currency"
             body={(rowData) => rowData.cart_currency || ""}
          ></Column>
          <Column
            className="text-center text-capitalize"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            // body={(trip) => {
            //   return statuses.find((sts) => sts.value === trip.status).label;
            // }}
            body={(rowData) => {
              if (rowData.payment_status == 2) {
                return <span className="status-text">Success</span>;
              } else if(rowData.payment_status  == 3) {
                return <span className="status-text-inactive">Failed</span>;
              }else{
                return <span className="status-text-pending">Pending</span>;
              }
            }}

           
          />
        </DataTable>
      </div>
    </>
  );
};
export default React.memo(connect()(PaymentHistory));
