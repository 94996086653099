import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CancelreasonReasonListing from "../components/CancelRideReason/CancelRideReasonListing";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

let CancelRideReasonRiderPage = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [cancelReasons, setCancelReasons] = useState([]);
  let [show, setShow] = useState(false);
  let [showUpdate, setShowUpdate] = useState(false);
  let [showDelete, setShowDelete] = useState(false);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let [updateData, setUpdateData] = useState({
      reason: "",
      reasonArabic: "",
      status: true,
    }),
    [saveNmore, setSaveNmore] = useState(false);

  let handleClose = () => setShow(false);
  let handleShow = () => {
    setShow(true);
    setSelectedStatus({ value: true, label: "Active" });
    setUpdateData({
      reason: "",
      reasonArabic: "",
      status: true,
    });
  };
  let handleCloseUpdate = () => setShowUpdate(false);
  let handleShowUpdate = () => setShowUpdate(true);
  let handleCloseDelete = () => setShowDelete(false);
  let handleShowDelete = (rowData) => {
    setUpdateData(rowData);
    setShowDelete(true);
  };

  let updateTableData = (rowData) => {
      setUpdateData(rowData);
      setSelectedStatus({
        value: rowData.status === "Active" ? true : false,
        label: rowData.status,
      });
    },
    createOrUpdateCancelReason = (reason, props) => {
      reason.status = Number(selectedStatus.value);
      reason.reasonType = 3;
      if (!updateData || !updateData.id) {
        APIService.post("admin/rejected-reason", reason)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Reason created successfully");
              getRejectedReasons();
            } else {
              toast.error("Failed to create reason");
            }
            props.resetForm();
            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.resetForm();
            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
            console.log(error);
          });
      } else {
        APIService.patch("admin/rejected-reason/" + updateData.id, reason)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Reason updated successfully");
              getRejectedReasons();
            } else {
              toast.error("Failed to update reason");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            handleCloseUpdate();
          });
      }
    },
    removeCancelReason = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.delete("admin/rejected-reason/" + updateData.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Reason deleted successfully");
            getRejectedReasons();
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
      handleCloseDelete();
    },
    validationSchema = Yup.object().shape({
      reason: Yup.string().required("Please enter reason").nullable(),
      reasonArabic: Yup.string().required("Please enter reason").nullable(),
      status: Yup.string().required("Select Status"),
    }),
    getRejectedReasons = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/rejected-reason/type/3")
        .then((response) => {
          if (response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            let reasons = response.data.data.map((reason) => {
              reason.status = reason.status ? "Active" : "Inactive";
              return reason;
            });
            setCancelReasons(reasons);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    };

  React.useEffect(() => {
    getRejectedReasons();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <div className="d-flex justify-content-between">
          <span className="title">Cancel Reason - Rider </span>
          <button className="btn btn-primary mb-1" onClick={handleShow}>
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </button>
        </div>

        <CancelreasonReasonListing
          cancelReasons={cancelReasons}
          handleShow={handleShow}
          handleShowUpdate={handleShowUpdate}
          handleShowDelete={handleShowDelete}
          updateTableData={updateTableData}
          isLoading={useSelector((state) => state.isGridLoader)}
        />
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Formik
          initialValues={updateData}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateCancelReason}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">Add Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="reason"
                      type="text"
                      name="reason"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Reason"
                    />
                    <label htmlFor="reason">Reason English</label>
                    <ErrorMessage
                      name="reason"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group">
                    <Field
                      id="reasonArabic"
                      type="text"
                      name="reasonArabic"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Reason"
                    />
                    <label htmlFor="reasonArabic">Reason Arabic</label>
                    <ErrorMessage
                      name="reasonArabic"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      className="height-50 br-10"
                      placeholder="Select Status"
                      // isClearable
                      name="status"
                      styles={colourStyles}
                      id="status"
                      onBlur={() => {
                        handleBlur({ target: { name: "status" } });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("status", e.value);
                          setSelectedStatus(e);
                        }
                      }}
                      value={selectedStatus}
                      options={statusOptions}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    className="btn-save save-button mb-3 border-radius-15"
                    type="submit"
                    onClick={() => setSaveNmore(false)}
                  >
                    Add
                  </Button>
                  <Button
                    type="submit"
                    variant="secondary"
                    className="btn-save-add save-button mb-3 border-radius-15"
                    onClick={() => setSaveNmore(true)}
                  >
                    Save & Add more
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
        <Formik
          initialValues={updateData}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateCancelReason}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    Update Reason
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="padding-header-footer">
                  <div className="form-group form-label-group">
                    <Field
                      id="reason"
                      type="text"
                      name="reason"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Reason"
                    />
                    <label htmlFor="reason">Reason English</label>
                    <ErrorMessage
                      name="reason"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="form-group form-label-group">
                    <Field
                      id="reasonArabic"
                      type="text"
                      name="reasonArabic"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Reason"
                    />
                    <label htmlFor="reasonArabic">Reason Arabic</label>
                    <ErrorMessage
                      name="reasonArabic"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      className="height-50 br-10"
                      placeholder="Select Status"
                      // isClearable
                      name="status"
                      styles={colourStyles}
                      id="status"
                      onBlur={() => {
                        handleBlur({ target: { name: "status" } });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("status", e.value);
                          setSelectedStatus(e);
                        }
                      }}
                      value={selectedStatus}
                      options={statusOptions}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save save-button mb-3 border-radius-15"
                  >
                    Update
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleCloseUpdate}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <DeleteModal
        clickYes={removeCancelReason}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
};

export default React.memo(connect()(CancelRideReasonRiderPage));
