import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import APIService from "../../services/APIService";
import { useDispatch } from "react-redux";


function Transaction(props) {
  let windowWidth = window.innerWidth;
  const [adjustData, setAdjustData] = useState(null);
  const [currentFilterCard, setCurrentFilterCard] = useState('');
  const [adjustFilterData, setAdjustFilterData] = useState({});
  const [show,setShow]=useState(false);

  const [loading, setLoading] = useState(false);
  const apiUrl =
  `http://uat-api.ride.sa/admin/get-adjust-data`;
  const [date, setdate] = useState('this_month');
  // const dispatch = useDispatch()
  // console.log(dispatch)

  useEffect(() => {
    getAdjustmoduleData();

  }, []);
  useEffect(() => {
    getAdjustmoduleFilterData(currentFilterCard);
  }, [currentFilterCard,date]);
  // Set up the request headers
  const headers = {
    "Authorization": "Bearer vb5mPMy-91kxwzxdQRvM",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // Define the report dimensions and metrics
  const dimensions = "channel";
  const metrics =
  "installs,attribution_impressions,ctr,attribution_clicks,limit_ad_tracking_installs,limit_ad_tracking_install_rate,click_conversion_rate,reattributions,sessions,daus,waus,maus,apply promo_events,apply promo failure_events,apply promo success_events,bank transaction failure_events,bank transaction for subscription event_events,bank transaction success_events,car confirmation failed_events,car confirmation success_events,car not found_events,car selection failed_events,car selection success_events,card saving_events,card skipped_events,confirm pickup_events,confirm ride_events,driver signup_events,general revenue_events,iban added_events,iban added failed_events,iban added success_events,kyc attempts_events,kyc attempts failed_events,kyc attempts success_events,login sessions_events,otp failure_events,otp for payment_events,otp success_events,process after car selection_events,ride acceptance failed_events,ride acceptance success_events,ride cancelled (after confirmation)_events,ride cancelled (before confirmation)_events,rides accepted_events,select car_events,send unifornic otp_events,sequence added_events,sequence failed_events,sequence number count_events,sequence success_events,sign up event_events,subscribe package failed_events,subscribe package success_events,subscription package unsubscribed_events,subscription packages count_events,subscription transaction failed_events,subscription transaction success_events,tamm otp failure_events,tamm otp success_events,topup failed_events,topup success_events,unifonic otp failed_events,unifonic otp verified_events,wasl approval_events,wasl rejection_events";

  // Set the report period and app name
  const date_period = date;
  const app_name = "RiDE";
  const channel = "all";

  // Define the request parameters
  const params = {
    dimensions,
    metrics,
    date_period,
    App: app_name,
    channel,
    authToken: "Bearer u_-sZWyibxDAV6ThbpXW",
    apiUrl : "https://dash.adjust.com/control-center/reports-service/report"
  };
  
  const getTimeRange = async(range,cardName) => {
    setCurrentFilterCard(cardName);
    const statsObj = {
      fromDate: range.sDate,
      toDate: range.eDate,
    };
    const formattedDate=`${moment(statsObj?.fromDate,"DD/MM/YYYY").format("YYYY-MM-DD")}:${moment(statsObj?.toDate,"DD/MM/YYYY").format("YYYY-MM-DD")}`;
    setdate(formattedDate);

  };
  // Make the API request
  const getAdjustmoduleData = async() => {
    props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setLoading(true);
    await APIService
      .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          setAdjustData(response?.data?.data);
          setAdjustFilterData(response?.data?.data?.totals);
          setLoading(false);
        }
        props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        setShow(true)
      })
      .catch((error) => {
        console.error(error);
        props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        setShow(true)
      });
  };
  const getAdjustmoduleFilterData = async(cardName) => {
    props.dispatch({ type: "TOGGLE_LOADER", payload: true })
    setShow(false)
    setLoading(true);
    await APIService
    .post('/admin/get-adjust-data',params ,{headers:{headers}})
      .then(async (response) => {
        if (response) {
          if(cardName== 'topup'){
            setAdjustFilterData({...adjustFilterData,"topup failure_events":Number(`${response?.data?.data?.totals["topup failure_events"]}`),"topup success_events":Number(`${response?.data?.data?.totals["topup success_events"]}`)});
          }
          else if(cardName== 'cardSaving'){
            setAdjustFilterData({...adjustFilterData,"card saving_events":Number(`${response?.data?.data?.totals["card saving_events"]}`),"card skipped_events":Number(`${response?.data?.data?.totals["card skipped_events"]}`)});
          }
          else if(cardName== 'iban'){
            setAdjustFilterData({...adjustFilterData,"iban added success_events":Number(`${response?.data?.data?.totals["iban added success_events"]}`),"iban added failed_events":Number(`${response?.data?.data?.totals["iban added failed_events"]}`)});
          }
          else if(cardName== 'bankTrans'){
            setAdjustFilterData({...adjustFilterData,"bank transaction success_events":Number(`${response?.data?.data?.totals["bank transaction success_events"]}`),"bank transaction failed_events":Number(`${response?.data?.data?.totals["bank transaction failed_events"]}`)});
          }
          else if(cardName== 'subsTrans'){
            setAdjustFilterData({...adjustFilterData,"subscription transaction success_events":Number(`${response?.data?.data?.totals["subscription transaction success_events"]}`),"subscription transaction failed_events":Number(`${response?.data?.data?.totals["subscription transaction failed_events"]}`)});
          }
          setLoading(false);
          setShow(true)
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        }
      })
      .catch((error) => {
        console.error(error);
    props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });
  };

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth < 1200 ? 45 : 90,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: loading ? false : true,
      },
      legend: {
        display: true,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 200,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  const chartDataForIBAN = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? 0.1 : adjustFilterData["iban added failed_events"],
          loading ? 0 : adjustFilterData["iban added success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["iban added success_events"] < 1 && adjustFilterData["iban added failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };
  const chartDataForBankTransaction = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? 0.1 : adjustFilterData["bank transaction failure_events"],
          loading ? 0 : adjustFilterData["bank transaction success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["bank transaction success_events"] < 1 && adjustFilterData["bank transaction failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };

  const chartDataForCardSaving = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? 0.1 : adjustFilterData["card skipped_events"],
          loading ? 0 : adjustFilterData["card saving_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["card saving_events"] < 1 && adjustFilterData["card skipped_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Saved `, `Skipped `],
  };

  const chartDataForSubscriptionTransaction = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? 0.1 : adjustFilterData["subscription transaction failed_events"],
          loading ? 0 : adjustFilterData["subscription transaction success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["subscription transaction success_events"] < 1 && adjustFilterData["subscription transaction failed_events"] < 1)   ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };

const chartDataForAutoTopUp = {
    datasets: [
      {
        data: [
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? 0.1 : adjustFilterData["topup failed_events"],
          loading ? 0 : adjustFilterData["topup success_events"],
        ],
        backgroundColor: [
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#EB3D3D",
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#13B542",
          loading || (adjustFilterData["topup success_events"] < 1 && adjustFilterData["topup failed_events"] < 1)  ? "#E5E5E5" : "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
    // labels: [`Failed `, `Success `],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `Total : ${loading ? 0 : chart?.data?.datasets[0]?.data[0] == 0.1 ? 0 :chart?.data?.datasets[0]?.data[0] + chart?.data?.datasets[0]?.data[1]}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  const successAndFailedPercentage = (success , failure ,type)=>{
    let total = Number(success) + Number(failure);
    switch(type){
      case "success":
        let successPercentage = (success*100)/total;
        if(!successPercentage){return `0 %`}
        return `${Math.round(successPercentage.toFixed(2))} %`
        // return `${successPercentage.toFixed(2)} %`
      case "failure":
        let failedPercentage = (failure*100)/total;
        if(!failedPercentage){return `0 %`}
        // return `${failedPercentage.toFixed(2)} %`
        return `${Math.round(failedPercentage.toFixed(2))} %`
      default:
        return ""
    }
  }
  return (
    <>
    {show && (
      <div className="row m-4 justify-content-between">
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Topup</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'topup')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForAutoTopUp}
                plugins={plugins}
                options={chartOptions}
              />        <div className="flex-cb mt-32 pb-3">
              <div className="d-flex align-items-center ">
                <span className="green-box"></span>
                <span className="percentext">Success: 
                {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
                <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["topup success_events"],adjustFilterData["topup failed_events"],"success")}</span>
                </span>
              </div>
              <div className="d-flex align-items-center ">
                <span className="green-box  bg-red"></span>
                <span className="percentext">Failed:
                {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
                <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["topup success_events"],adjustFilterData["topup failed_events"],"failure")}</span>
  
                </span>
              </div>
          </div>
            </div>{" "}
          </div>
        </div>{" "}
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Card Saving</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'cardSaving')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForCardSaving}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["card saving_events"],adjustFilterData["card skipped_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["card saving_events"],adjustFilterData["card skipped_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>{" "}
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">IBAN</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'iban')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForIBAN}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["iban added success_events"],adjustFilterData["iban added failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["iban added success_events"],adjustFilterData["iban added failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>{" "}
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Subs. Bank Transaction</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'bankTrans')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForBankTransaction}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["bank transaction success_events"],adjustFilterData["bank transaction failure_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["bank transaction success_events"],adjustFilterData["bank transaction failure_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>{" "}
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10 bg-white p-4 h-100 rounded">
            <div className=" d-flex justify-content-between text-center align-items-center position-relative mb-3">
              <h2 class="box-title">Subscription Transactions</h2>
              <Calendar handleTimeRange={(range)=> getTimeRange(range,'subsTrans')} />
            </div>
            <div>
              <Doughnut
                type="doughnut"
                data={chartDataForSubscriptionTransaction}
                plugins={plugins}
                options={chartOptions}
              />
                      <div className="flex-cb mt-32 pb-3">
            <div className="d-flex align-items-center ">
              <span className="green-box"></span>
              <span className="percentext">Success: 
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp success_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["subscription transaction success_events"],adjustFilterData["subscription transaction failed_events"],"success")}</span>
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <span className="green-box  bg-red"></span>
              <span className="percentext">Failed:
              {/* <span className="percenPoint">{loading ? "" : adjustFilterData["otp failure_events"]}</span> */}
              <span className="percenPoint">{loading ? "" : successAndFailedPercentage(adjustFilterData["subscription transaction success_events"],adjustFilterData["subscription transaction failed_events"],"failure")}</span>

              </span>
            </div>
        </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-4 col-md-3 col-12 p-1   rounded  ">
          <div className="col-1g-10  p-4 h-100 rounded"></div>
        </div>
      </div>
    )}
    </>
  );
}
export default Transaction;
