import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Link, useParams } from "react-router-dom";
import { Slider } from "primereact/slider";
import APIService from "../../services/APIService";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { CalendarRange } from "react-bootstrap-icons";
import rideSource from "../../assest/images/ride-app-source.svg";


let TripHistoryTab = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let { id } = useParams();
  let [ridersTripsData, setRidersTripsData] = useState([]);
  let [filterStatus, setFilterStatus] = useState(null);
  let [createdAt, setCreatedAt] = useState(null);
  let [showFilter, setShowFilter] = useState(false);
  let [ratingFilterRange, setRatingFilterRange] = useState([0, 5]);
  let dt = useRef(null);
  let [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: { field: "createdAt", order: -1 },
      skip: 0,
      take: 25,
      totalRecords: 0,
      keyword: "",
    }),
    getStatusText = (value) => {
      let statusText;
      switch (value) {
        case 1:
          statusText = <span className="status-text-pending">Pending</span>;
          break;
        case 2:
          statusText = <span className="status-text">Accepted</span>;
          break;
        case 3:
          statusText = <span className="status-text-inactive">Rejected</span>;

          break;
        case 4:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 5:
          statusText = <span className="status-text">Arrived</span>;
          break;
        case 6:
          statusText = <span className="status-text-inactive">Cancelled</span>;
          break;
        case 7:
          statusText = <span className="status-text-pending">Started</span>;
          break;
        case 8:
          statusText = <span className="status-text">Completed</span>;
          break;
        case 9:
          statusText = <span className="status-text-pending">No Driver</span>;
          break;
        case 10:
          statusText = <span className="status-text-inactive">Expired</span>;
          break;
        default:
          statusText = "-";
          break;
      }
      return statusText;
    },
    getRiderTrips = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post(`admin/rider-trip-history/${id}`, filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let tripDataResponse = response.data.data.trips.map((trip) => {
              trip.status = getStatusText(trip.status);

              return trip;
            });

            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setRidersTripsData(tripDataResponse);
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageTripHistory = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getRiderTrips();
    },
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setFilterStatus(null);
      setCreatedAt(null);
      setLazyParams({
        filters: {},
        sort: { field: "createdAt", order: -1 },
        skip: 0,
        take: 25,
        totalRecords: 0,
        keyword: "",
      });
      getRiderTrips();
    };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  useEffect(() => {
    getRiderTrips();
  }, []);

  let statuses = [
      //{ label: "Pending", value: 1 }
      { label: "Accepted By Driver", value: 2 },
      { label: "Rejected By Driver", value: 3 },
      { label: "Cancelled By Driver", value: 4 },
      { label: "Driver Arrived", value: 5 },
      { label: "Cancelled By Rider", value: 6 },
      { label: "Started", value: 7 },
      { label: "Completed", value: 8 },
      //{ label: "No Driver", value: 9 },
      //{ label: "Expired", value: 10 },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getRiderTrips();
              }}
              placeholder="Search..."
            />
          </span>
        </div>
      );
    },
    header = renderHeader();

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={ridersTripsData}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Trip(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageTripHistory}
          onSort={manageTripHistory}
          onFilter={manageTripHistory}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            sortField="id"
            filterField="id"
            header="Trip ID"
            field="id"
            body={(trip) => (
              <Link
                to={"../../trip-details/" + trip.id}
                style={{ color: "rgba(19, 181, 66, 1)" }}
              >
                {trip.tripNo}
              </Link>
            )}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by id"
          />
          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortField="driverId"
            filterField="driverId"
            header="Driver ID"
            body={(trip) =>
              trip.driver && (
                <Link
                  to={`/driver-details/details/${trip.driver.id}`}
                  className="dataFnt-size"
                >
                  {trip.driver.userId}
                </Link>
              )
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          /> */}
          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="overallRating"
            header="Rating"
            body={
              (rider) => {
                if (rider.overallRating == 0) {
                  return "--";
                } else {
                  return ratingCount(Math.round(rider.overallRating));
                }
              }
              // <Rating
              //   className="ratings pl-0 d-flex justify-content-center"
              //   fractions={2}
              //   emptySymbol="fa fa-star-o fa-2x star"
              //   fullSymbol="fa fa-star fa-2x star"
              //   initialRating={rider.overallRating}
              //   readonly
              // />
            }
            sortable
            filter={false}
          /> */}
          <Column
            filter={showFilter}
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            body={(rowData) =>
              moment(rowData.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            field="createdAt"
            sortable
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  appendTo={null}
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getRiderTrips();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getRiderTrips();
                      }, 500);
                    }
                  }}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />
          <Column
            style={{ fontSize: "12px", width: "250px", color: "#000000" }}
            sortField="pickup"
            filterField="pickup"
            header="Pickup Location"
            field="pickup.address"
            //sortField="pickup"
            //filterField="pickup"

            filter={showFilter}
            filterPlaceholder="Search by pickup"
          />
          <Column
            style={{ fontSize: "12px", width: "250px", color: "#000000" }}
            header="Drop Off Location"
            field="dropoff.address"
            sortField="dropoff"
            filterField="dropoff"
            filter={showFilter}
            filterPlaceholder="Search by drop"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortField="riderContact"
            filterField="riderContact"
            header="Rdier Contact"
            body={(trip) =>
              trip.driver && (
                <Link
                  to={`/driver-details/details/${trip.driver.id}`}
                  className="dataFnt-size"
                >
                  {trip.driver.userId}
                </Link>
              )
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortField="captainContact"
            filterField="captainContact"
            header="Captain Contact"
            body={(trip) =>
              trip.driver && (
                <Link
                  to={`/driver-details/details/${trip.driver.id}`}
                  className="dataFnt-size"
                >
                  {trip.driver.userId}
                </Link>
              )
            }
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />

          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Total Fare"
            field="tripBaseAmount"
            body={(trip) => {
              if (trip.tripBaseAmount) {
                let total =
                  trip.tripBaseAmount +
                  trip.taxAmount +
                  trip.waitingCharge -
                  trip.promoCodeAmount;
                return " SAR " + total.toFixed(2);
              } else {
                return " SAR " + 0;
              }
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search subtotal"
          /> */}
 <Column
            className="text-center"
            headerClassName="text-right"
            style={{ fontSize: "10px", width: "120px" }}
            field="riderAmount"
            header="Total Fare"
            body={(trip) => trip.riderAmount?.toFixed(2)}
            sortable
            filter={showFilter}
            filterField="riderAmount"
            filterPlaceholder="Search by fare"
          />
          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "170px" }}
            header="Promo Applied"
            sortable
            body={(trip) => {
              return trip?.promoCode ? "Yes" : "No";
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "120px" }}
            header="Source"
            sortable
            body={(driver) => {
              return <img src={rideSource} alt="ride" className="nav_icon" />;
            }}
          />

          <Column
            className="text-center text-capitalize"
            style={{ fontSize: "12px", width: "200px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            // body={(trip) => {
            //   return statuses.find((sts) => sts.value === trip.status).label;
            // }}
            // body={(trip) => {
            //   if (trip.status === 8) {
            //     return <span className="status-text">Completed</span>;
            //   } else {
            //     return <span className="status-text-inactive">Cancelled</span>;
            //   }
            // }}

            filterElement={
              <Dropdown
                value={filterStatus}
                options={statuses}
                onChange={(event) => {
                  setFilterStatus(event.value);
                  if (event.value) {
                    lazyParams.filters["status"] = event.value;
                  } else {
                    delete lazyParams.filters["status"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getRiderTrips();
                  }, 500);
                }}
                placeholder="Select Status"
                className="p-column-filter"
                showClear
              />
            }
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "150px" }}
            header="Driver Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.driver?.driverReview);
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "10px", width: "150px" }}
            header="Rider Rating"
            sortable
            body={(trip) => {
              return ratingCount(trip?.rider?.riderReview);
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            sortField="Action"
            filterField="Action"
            header="Action"
            // body={(trip) =>
            //   trip.driver && (
            //     // <Link
            //     //   to={`/driver-details/details/${trip.driver.id}`}
            //     //   className="dataFnt-size"
            //     // >
            //     //   {trip.driver.userId}
            //     // </Link>
            //   )
            // }
            sortable
            filter={showFilter}
            filterPlaceholder="Search"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(TripHistoryTab));
