import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import saveAs from "save-as";
import APIService from "../services/APIService";
import { Link } from "react-router-dom";

let DispatchTripListing = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [createdAt, setCreatedAt] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    [dispatcherTrips, setDispatcherTrips] = React.useState([]),
    [status, setStatus] = useState(null),
    [selectedDispatchers, setSelectedDispatchers] = React.useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    dt = useRef(null),
    tripStatus = [
      { value: 1, label: "Pending" },
      { value: 2, label: "Accepted By Driver" },
      { value: 3, label: "Rejected By Driver" },
      { value: 4, label: "Cancelled By Driver" },
      { value: 5, label: "Driver Arrived" },
      { value: 6, label: "Cancelled By Rider" },
      { value: 7, label: "Started" },
      { value: 8, label: "Completed" },
      { value: 9, label: "No Driver" },
      { value: 10, label: "Expired" },
    ],
    exportDispatchers = () => {
      let dispatchers = [
        "Trip Id",
        "Date & Time",
        "Pick Up",
        "Drop Off",
        "Rider ID",
        "Driver ID",
        "Status",
      ];
      dispatchers = dispatchers.join(",") + "\r\n";
      if (selectedDispatchers.length) {
        selectedDispatchers.forEach((dispatcher) => {
          dispatchers +=
            [
              dispatcher.tripNo,
              "'" + moment(dispatcher.createdAt).format("DD/MM/YYYY hh:mm A"),
              dispatcher.pickup.address,
              dispatcher.dropoff.address,
              dispatcher.rider.userId,
              dispatcher.driver.userId,
              tripStatus.find((sts) => sts.value === dispatcher.status).label,
            ].join(",") + "\r\n";
        });
        saveAs(
          new Blob([dispatchers], {
            type: "text/csv;charset=utf-8",
          }),
          "Dispatcher-Trips-Reports.csv"
        );
      } else {
        let filterParams = JSON.parse(JSON.stringify(lazyParams));
        filterParams.sort.order =
          filterParams.sort.order === -1 ? "desc" : "asc";
        filterParams.skip = 0;
        filterParams.take = 100000000000;

        APIService.post("admin/dispatchers-trips/all", filterParams)
          .then((response) => {
            if (response.status === 201) {
              response.data.data.trips.forEach((dispatcher) => {
                dispatchers +=
                  [
                    dispatcher.tripNo,
                    "'" +
                      moment(dispatcher.createdAt).format("DD/MM/YYYY hh:mm A"),
                    dispatcher.pickup.address,
                    dispatcher.dropoff.address,
                    dispatcher.rider.userId,
                    dispatcher.driver.userId,
                    tripStatus.find((sts) => sts.value === dispatcher.status)
                      .label,
                  ].join(",") + "\r\n";
              });
              saveAs(
                new Blob([dispatchers], {
                  type: "text/csv;charset=utf-8",
                }),
                "Dispatcher-Trip-Reports.csv"
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                setTimeout(() => {
                  getDispatcherTrips();
                }, 500);
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
            <button
              className="btn btn-primary show_filter_btn ml-3"
              onClick={exportDispatchers}
            >
              {windowWidth ? <i className="fa fa-download"></i> : "Export"}
            </button>
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getDispatcherTrips();
    },
    getDispatcherTrips = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/dispatchers-trips/all", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            setDispatcherTrips(response.data.data.trips);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDispatcherTrips = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDispatcherTrips();
    };
  React.useEffect(() => {
    getDispatcherTrips();
  }, []);
  return (
    <div className="rider-listing">
      <span className="title">Dispatcher Trip List</span>
      <Link to="/create-trip">
        <button className="btn btn-primary float-right mb-1">
          {windowWidth ? <i className="fa fa-plus"></i> : "Add Trip"}
        </button>
      </Link>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={dispatcherTrips}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Trip(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            onSelectionChange={(e) => setSelectedDispatchers(e.value)}
            selection={selectedDispatchers}
            lazy={true}
            totalRecords={lazyParams.totalRecords}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={manageDispatcherTrips}
            onSort={manageDispatcherTrips}
            onFilter={manageDispatcherTrips}
          >
            <Column selectionMode="multiple" style={{ width: "3em" }} />
            <Column
              className="text-center"
              headerClassName="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              field="tripNo"
              header="Trip ID"
              filter={showFilter}
              body={(trip) => (
                <Link to={`/trip-details/${trip.id}`} className="dataFnt-size">
                  <span className="dataFnt-size">{trip.tripNo}</span>
                </Link>
              )}
              sortable
            />

            <Column
              className="text-center"
              headerClassName="text-center"
              style={{ fontSize: "12px", width: "230px" }}
              header="Date & Time"
              field="createdAt"
              body={(trip) =>
                moment(trip.createdAt).format("DD/MM/YYYY hh:mm A")
              }
              sortable
              filter={showFilter}
              filterElement={
                <div className="d-inline-flex position-relative w-100">
                  <Calendar
                    appendTo={null}
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select date range"
                    dateFormat="dd/mm/yy"
                    value={createdAt}
                    hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      if (e.value) {
                        setCreatedAt(e.value);
                        if (e.value[0] && e.value[1]) {
                          lazyParams.filters["createdAt"] = [
                            moment(e.value[0]).format("YYYY-MM-DD"),
                            moment(e.value[1]).format("YYYY-MM-DD"),
                          ];

                          setLazyParams(lazyParams);
                          setTimeout(() => {
                            getDispatcherTrips();
                          }, 500);
                        }
                      } else {
                        setCreatedAt([]);
                        delete lazyParams.filters["createdAt"];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getDispatcherTrips();
                        }, 500);
                      }
                    }}
                    selectionMode="range"
                    showButtonBar
                    readOnlyInput
                  />
                  <span className="input-box-daterange-icon">
                    <CalendarRange />
                  </span>
                </div>
              }
            />
            <Column
              className="text-left"
              headerClassName="text-left"
              style={{ fontSize: "12px", width: "250px" }}
              field="pickup.address"
              sortField="pickup"
              filterField="pickup"
              header="Pick Up"
              filter={showFilter}
              filterPlaceholder="Search by pickup"
              sortable
            />
            <Column
              className="text-left"
              style={{ fontSize: "12px", width: "200px" }}
              sortable
              header="Drop off"
              field="dropoff.address"
              filter={showFilter}
              filterField="dropoff"
              sortField="dropoff"
              filterPlaceholder="Search by dropoff"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              sortable
              header="Rider ID"
              field="rider.id"
              body={(trip) => (
                <Link
                  to={`/rider-details/details/${trip.rider?.id}`}
                  className="dataFnt-size"
                >
                  <span className="dataFnt-size">{trip.rider?.userId}</span>
                </Link>
              )}
              filter={showFilter}
              filterField="riderId"
              sortField="riderId"
              filterPlaceholder="Search by rider id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "130px" }}
              sortable
              header="Driver ID"
              field="driver.userId"
              body={(trip) => (
                <Link
                  to={`/driver-details/details/${trip.driver?.id}`}
                  className="dataFnt-size"
                >
                  <span className="dataFnt-size">{trip.driver?.userId}</span>
                </Link>
              )}
              filter={showFilter}
              filterField="driverId"
              sortField="driverId"
              filterPlaceholder="Search by driver id"
            />

            <Column
              className="text-center text-capitalize"
              style={{ fontSize: "12px", width: "200px" }}
              field="status"
              header="Status"
              sortable
              filter={showFilter}
              body={(trip) =>
                tripStatus.find((trp) => trp.value === trip.status)?.label
              }
              filterElement={
                <Dropdown
                  value={status}
                  options={tripStatus}
                  onChange={(event) => {
                    setStatus(event.value);
                    if (event.value) {
                      lazyParams.filters["status"] = event.value;
                    } else {
                      delete lazyParams.filters["status"];
                    }
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getDispatcherTrips();
                    }, 500);
                  }}
                  placeholder="Select Status"
                  className="p-column-filter"
                  showClear
                />
              }
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default React.memo(connect()(DispatchTripListing));
