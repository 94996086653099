import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import { connect } from "react-redux";
import calender from "../../assest/images/calender.svg";
import redCard from "../../assest/images/red-card.svg";
import greenCard from "../../assest/images/green-card.png";
import { logDOM } from "@testing-library/react";
import { TabView, TabPanel } from "primereact/tabview";
import { EmptyData } from "../Dashboard_2/linechartdata/linechartdata";

let barChartOptions = {
  responsive: true,
  type: "line",
  barPercentage: 5,
  borderRadius: 5,
  plugins: {
    legend: {
      display: false,
    },
  },
  // scales: {
  //   x: {
  //     display: true,
  //     gridLines: {
  //       display: false,
  //     },
  //   },
  //   y: {
  //     display: true,
  //     gridLines: {
  //       display: false,
  //     },
  //   },
  // },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};
let AverageEarning = (props) => {
  const { riderId } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [summaryList, setSummaryList] = useState([]),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;

  let statusCounts = graphCountList
    ? graphCountList
    : props?.sendgraphTripData?.percentaged;
  useEffect(() => {
    tripSummaryList();
  }, [props.sendgraphTripData]);

  let tripSummaryList = () => {
    setGraphTripList(graphData && graphData?.graphList);
    let chartData = [
        graphData?.percentaged?.completed,
        graphData?.percentaged?.cancelled,
        graphData?.percentaged?.unmatched,
        graphData?.percentaged?.scheduled,
      ],
      labels = [
        "Done " + graphData?.percentaged.completed + "%",
        "Cancelled " + graphData?.percentaged?.cancelled + "%",
        "Unmatched " + graphData?.percentaged?.unmatched + "%",
      ];
  };

  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

  useEffect(() => {
    if (props.timeRange === "day") {
      if (graphTripList && graphTripList.length === 0) {
        setGraphTripList({
          labels: [],
          datasets: [],
        });
      }
    }

    if (graphTripList && graphTripList.length > 0) {
      let key = [];
      let objArr = [
        {
          label: "Amount",
          value: 0,
          data: [],
          backgroundColor: "rgba(19, 181, 66, 1)",
          borderColor: "rgba(19, 181, 66, 1)",
          borderWidth: 3,
          lineTension: 0.3,
          pointRadius: 0,
          pointHitRadius: 30,
          pointHoverRadius: 7,

          hoverBackgroundColor: "rgba(19, 181, 66, 1)",
          hoverBorderColor: "rgba(19, 181, 66, 1)",
        },
      ];

      for (let arrItem of objArr) {
        for (let item of graphTripList) {
          for (let itemData of item.data) {
            if (itemData.key === arrItem.label) {
              arrItem.data.push(itemData.value);
            }
          }
        }
      }

      for (let itemLabel of graphTripList) {
        key.push(itemLabel && itemLabel.key);
      }

      setGraphTripList({
        labels: key,
        datasets: objArr,
      });
    }
  });

  let manageTimeRange = (event) => {
    // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
    setTimeRange(event.target.value);
    APIService.post("/admin/dashboard/get-avg-earn-history/" + riderId, {
      type: event.target.value,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });

          setGraphTripList(
            response.data && response.data.data && response.data.data.graphList
          );
          setSummaryList(
            response.data && response.data.data && response.data.data
          );
          setgraphCountList(
            response.data &&
              response.data.data &&
              response.data.data.percentaged
          );
          let chartData = [
              response.data.data.percentaged.completed,
              response.data.data.percentaged.cancelled,
              response.data.data.percentaged.unmatched,
              response.data.data.percentaged.scheduled,
            ],
            labels = [
              "Done " +
                (!response.data.data.percentaged.completed
                  ? 0
                  : response.data.data.percentaged.completed) +
                "%",
              "Cancelled " +
                (!response.data.data.percentaged.cancelled
                  ? 0
                  : response.data.data.percentaged.cancelled) +
                "%",
              "Unmatched " +
                (!response.data.data.percentaged.unmatched
                  ? 0
                  : response.data.data.percentaged.unmatched) +
                "%",
            ];
        }
      })
      .catch((error) => {
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });

    let key = [];
    let objArr = [
      {
        label: "Amount",
        value: 0,
        data: [],
        backgroundColor: "rgba(19, 181, 66, 1)",
        borderColor: "rgba(19, 181, 66, 1)",
        //borderWidth: 4,
        borderWidth: 3,
        lineTension: 0.3,
        pointRadius: 0,
        pointHitRadius: 30,
        pointHoverRadius: 7,
        hoverBackgroundColor: "rgba(19, 181, 66, 1)",
        hoverBorderColor: "rgba(19, 181, 66, 1)",
      },
    ];

    if (event.target.value === "day") {
      if (graphTripList && graphTripList.length === 0) {
        setGraphTripList({
          labels: [],
          datasets: [],
        });
      }
    }

    if (graphTripList && graphTripList.length > 0) {
      for (let arrItem of objArr) {
        for (let item of graphTripList) {
          for (let itemData of item.data) {
            if (itemData.key === arrItem.label) {
              arrItem.data.push(itemData.value);
            }
          }
        }
      }
      for (let itemLabel of graphTripList) {
        key.push(itemLabel && itemLabel.key);
      }
    }
    setGraphTripList({
      labels: key,
      datasets: objArr,
    });
  };
  let topupData = () => {
    // if (riderId) {
    APIService.post("/admin/dashboard/get-avg-earn-history/" + riderId, {
      type: "week",
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });

          setGraphTripList(
            response.data && response.data.data && response.data.data.graphList
          );
          setSummaryList(
            response.data && response.data.data && response.data.data
          );
          setgraphCountList(
            response.data &&
              response.data.data &&
              response.data.data.percentaged
          );
          let chartData = [
              response.data.data.percentaged.completed,
              response.data.data.percentaged.cancelled,
              response.data.data.percentaged.unmatched,
              response.data.data.percentaged.scheduled,
            ],
            labels = [
              "Done " +
                (!response.data.data.percentaged.completed
                  ? 0
                  : response.data.data.percentaged.completed) +
                "%",
              "Cancelled " +
                (!response.data.data.percentaged.cancelled
                  ? 0
                  : response.data.data.percentaged.cancelled) +
                "%",
              "Unmatched " +
                (!response.data.data.percentaged.unmatched
                  ? 0
                  : response.data.data.percentaged.unmatched) +
                "%",
            ];
        }
      })
      .catch((error) => {
        // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
      });

    let key = [];
    let objArr = [
      {
        label: "Amount",
        value: 0,
        data: [],
        backgroundColor: "rgba(19, 181, 66, 1)",
        borderColor: "rgba(19, 181, 66, 1)",
        //borderWidth: 4,
        borderWidth: 3,
        lineTension: 0.3,
        pointRadius: 0,
        pointHitRadius: 30,
        pointHoverRadius: 7,
        hoverBackgroundColor: "rgba(19, 181, 66, 1)",
        hoverBorderColor: "rgba(19, 181, 66, 1)",
      },
    ];



    if (graphTripList && graphTripList.length > 0) {
      for (let arrItem of objArr) {
        for (let item of graphTripList) {
          for (let itemData of item.data) {
            if (itemData.key === arrItem.label) {
              arrItem.data.push(itemData.value);
            }
          }
        }
      }
      for (let itemLabel of graphTripList) {
        key.push(itemLabel && itemLabel.key);
      }
    }
    setGraphTripList({
      labels: key,
      datasets: objArr,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      topupData();
    }, 500);
  }, [riderId]);
  const data = {
    labels: [23, 24, 25, 26, 27, 28, 29],
    datasets: [
      {
        data: [23, 24, 25, 26, 27, 28, 29],

        backgroundColor: "rgba(75,192,192,0.2)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774",
      },
      {
        label: "Second dataset",
        data: [12, 21, 25, 51, 34, 26],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };
  return (
    <>  
    <div className="mt-24">
    {/* <Line data={graphTripList!==undefined && graphTripList.length >0 ? graphTripList : EmptyData} options={barChartOptions} /> */}
    <Line data={(!(Array.isArray(graphTripList)) && graphTripList!==undefined) ? graphTripList : EmptyData} options={barChartOptions} />

  </div>
  <div
    className="bg-light"
    style={{
      borderRadius: "6px",
      padding: "16px",
      // marginTop: "30px",
    }}
  >
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              class="green-box mr-3"
              style={{ backgroundColor: "#13B542" }}
            ></div>
            <div class="trips-title-label mr-4">Avg. Earning</div>
          </div>

          <div
            class="trips-count text-dark font-weight-bolder"
            style={{ color: "rgb(0, 0, 0)" }}
          >
            {summaryList?.totalAmount?.toFixed(2)} SAR.
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              class="green-box mr-3"
              style={{ backgroundColor: "#F1F1F1" }}
            ></div>
            <div class="trips-title-label mr-4">Max Earning </div>
          </div>

          <div
            class="trips-count text-dark font-weight-bolder"
            style={{ color: "rgb(0, 0, 0)" }}
          >
            {summaryList?.maxAmount?.toFixed(2)} SAR.
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              class="green-box mr-3"
              style={{ backgroundColor: "#F1F1F1" }}
            ></div>
            <div class="trips-title-label mr-4">Min Earning</div>
          </div>

          <div
            class="trips-count text-dark font-weight-bolder"
            style={{ color: "rgb(0, 0, 0)" }}
          >
            {summaryList?.minAmount?.toFixed(2)} SAR.
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  );
};
export default AverageEarning;
