import React from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";

let EmergencyTeamDetailTab = (props) => {
  return (
    <div className="driver-details">
      <Card className="mt-3">
        <Row>
          <Col md={6} className="pl-5 pr-5 mb-3">
            <Row className="mt-4">
              <Col md="auto" className="px-0">
                <label className="profilelabel">Emergency name:</label>
              </Col>
              <Col md={7}>
                <label className="profiletxt float-left">Neel Patel</label>
              </Col>
              {/* <div className="bdr-top"></div> */}
            </Row>
            <Row className="mt-4">
              <Col md="auto" className="px-0">
                <label className="profilelabel">Email:</label>
              </Col>
              <Col md={7}>
                <label className="profiletxt float-left">neel@email.com</label>
              </Col>
              {/* <div className="bdr-top"></div> */}
            </Row>
          </Col>
          <Col md={6} className="pl-5 pr-5 mb-3">
            <Row className="mt-4">
              <Col md="auto" className="px-0">
                <label className="profilelabel">Stats:</label>
              </Col>
              <Col md={7}>
                <label className="profiletxt float-left">Resolved</label>
              </Col>
              {/* <div className="bdr-top"></div> */}
            </Row>
            <Row className="mt-4">
              <Col md="auto" className="px-0">
                <label className="profilelabel">Status:</label>
              </Col>
              <Col md={7}>
                <label className="profiletxt float-left">Inactive</label>
              </Col>
              {/* <div className="bdr-top"></div> */}
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default React.memo(connect()(EmergencyTeamDetailTab));
