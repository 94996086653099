import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tooltip } from "primereact/tooltip";
import { Card, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import SubAdmin from "../components/Admin/SubAdmin";
import DeleteModal from "../components/Common/DeleteModal";
import APIService from "../services/APIService";
import "bootstrap/dist/css/bootstrap.min.css";
import { _baseURL } from "../services/APIService";
import axios from "axios";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#ffffff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
export const secret_key = process.env.REACT_APP_SECRET_KEY;
const _saveEmployee = _baseURL + "/api/v1/employee/save?secret_key=" + secret_key;
const _deleteEmployee = _baseURL + "/api/v1/employee/delete?secret_key=" + secret_key;
// http://127.0.0.1:8000/api/v1/employee/delete?secret_key=3hexlUdxifqV8Ga*!40fY&employee_id=78
// http://127.0.0.1:8000/api/v1/employee/save?secret_key=3hexlUdxifqV8Ga*!40fY&name=new mailto:emp&email=v4@gmail.com&country=pakistan&status=0&department_id=5
let countryOptions = [{ value: "Saudi Arabia", label: "Saudi Arabia" }];
let statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
],
  Admin = (props) => {
    let [admins, setAdmins] = useState([]),
      [show, setShow] = useState(false),
      [showUpdate, setShowUpdate] = useState(false),
      [showDelete, setShowDelete] = useState(false),
      [saveNmore, setSaveNmore] = useState(false),
      [selectedRole, setSelectedRole] = useState(null),
      [selectedStatus, setSelectedStatus] = useState(null),
      [selectedCountry, setSelectedCountry] = useState(null),
      [roles, setRoles] = useState([]),
      [savedUser, setSavedUser] = useState([]),
      [adminName, setAdminName] = useState(''),
      [adminEmail, setAdminEmail] = useState(''),
      [updateData, setUpdateData] = useState({
        email: "",
        fullName: "",
        status: true,
        role: "",
      }),
      [windowWidth] = useState(useSelector((state) => state.windowSize)),
      handleClose = () => {
        setShow(false)
        setAdminName("")
        setAdminEmail("")
        getRoles()
      },
      handleShow = () => {
        setSelectedStatus({ value: true, label: "Active" });
        setSelectedRole(null);
        setSelectedCountry(null);
        setUpdateData({ email: "", fullName: "", status: true, role: "" });
        setShow(true);
      },
      saveEmployee = async (dep_id) => {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            name: adminName,
            secret_key,
            company_id: 1,
            email: adminEmail,
            subdepartment_id: 5,
            // department_id:5,
            country: selectedCountry?.value,
            status: selectedStatus?.value,
            id: dep_id
          }),
        };
        const response = await fetch(_saveEmployee, requestOptions);
        let final_data = await response.json();
        console.log(final_data)
      },
      deleteEmployee = async (employee_email) => {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            employee_email: "m.faheem@mytm.pk"
          }),
        };
        const response = await fetch(_deleteEmployee, requestOptions);
        let final_data = await response.json();
        console.log(final_data)
      },
      handleCloseUpdate = () => setShowUpdate(false),
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rowData) => {
        setUpdateData(rowData);
        setSelectedStatus({ value: true, label: "Active" });
        setShowDelete(true);
      },
      compareId = (userId) => {
        if (userId == "d74ae344-001d-11ec-9a03-0242ac130003") { return "Dispatcher Admin" }
        if (userId == "d74ae222-001d-11ec-9a03-0242ac130003") { return "Emergency Admin" }
        if (userId == "d74ae40c-001d-11ec-9a03-0242ac130003") { return "Sub Admin" }
        if (userId == "d74adb1a-001d-11ec-9a03-0242ac130003") { return "Admin" }
        if (userId == "d74adb1a-001d-11ec-9a03-0242ac130004") { return "Admin" }
      },
      compareStatus = (stats) => {
        if (stats) return "Active";
        if (!stats) return "InActive";
      },
      createOrUpdateAdmin = () => {
        let onlyUser = {
          fullName: adminName,
          email: adminEmail,
          role: selectedRole?.value,
          country: selectedCountry,
          status: selectedStatus?.value
        }
        // savedUser
        if (!updateData || !updateData.id) {
          APIService.post("admin/subadmin", (savedUser.length == 0) ? onlyUser : savedUser[0])
            .then((response) => {
              console.log(response)
              if (response.data.statusCode === 200) {
                saveEmployee(response?.data?.data?.id)
                // console.log("admin created successtfully");
                setShow(false);
                toast.success("Admin created successfully");
                getAdmins();
              } else {
                toast.error("Failed to create Admin");
              }
              // formProps.resetForm();
              setSelectedRole(null);
              setSelectedCountry(null);
              setSelectedStatus({ value: 1, label: "Active" });
              setUpdateData({ email: "", fullName: "", status: 1, role: "" });
              if (!saveNmore) {
                handleClose();
              }
            })
            .catch((error) => {
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              // formProps.resetForm();
              setSelectedRole(null);
              setSelectedCountry(null);
              setSelectedStatus({ value: 1, label: "Active" });
              setUpdateData({ email: "", fullName: "", status: 1, role: "" });
              if (!saveNmore) {
                handleClose();
              }
            });
        } else {
          APIService.patch("admin/subadmin/" + updateData.id, savedUser[0])
            .then((response) => {
              if (response.data.statusCode === 200) {
                toast.success("Admin updated successfully");
                getAdmins();
              } else {
                toast.error("Failed to update Admin");
              }
              handleCloseUpdate();
            })
            .catch((error) => {
              handleCloseUpdate();
            });
        }
      },
      validationSchema = Yup.object().shape({
        email: Yup.string()
          .email(
            "Please enter email in proper format e.g. johndoes@hotmail.com"
          )
          .required("Please enter your registered email"),
        fullName: Yup.string().required("Please enter name"),
        role: Yup.string().nullable().required("Select Role"),
        status: Yup.string().required("Select Status"),
      }),
      updateTableData = (rowData) => {
        let admin = { ...rowData };
        admin.status = admin.status === "Active" ? true : false;
        setSelectedStatus(
          statusOptions.find((sts) => sts.value === admin.status)
        );
        setSelectedRole(roles.find((rl) => rl.value === admin.roleId));
        admin.role = admin.roleId;
        setUpdateData(admin);
      },
      getAdmins = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.post("admin/subadmin/list", {
          limit: 100,
          offset: 0,
          sortBy: "fullName",
          sortType: "asc",
        })
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              let admins = response.data.data.results
                .map((admin) => {
                  admin.status = admin.status ? "Active" : "Inactive";
                  admin.roleName = admin.role?.title || "-";
                  admin.roleId = admin.role?.id || "-";
                  return admin;
                })
                .sort((admin, admin2) => {
                  return new Date(admin2.createdAt) - new Date(admin.createdAt);
                });
              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
              setAdmins(admins);
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      removeAdmin = async () => {
        APIService.delete("admin/subadmin/" + updateData.id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              deleteEmployee(updateData.email)
              toast.success("User deleted successfully");
              setShowDelete(false);
              getAdmins();
            }
          })
          .catch(() => {
            // toast.error("Failed to delete Admin");
          });
      },
      getRoles = (e) => {
        let selectedValue = e?.value;
        APIService.get("admin/role").then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            let roles = response.data.data
              .filter((role) => role.status)
              .map((role) => {
                if (selectedValue == role.id) {
                  role.title = `${role.title} ✔`;
                }
                return {
                  value: role.id,
                  label: role.title
                  // label: (
                  //   <div className="d-flex justify-content-between">
                  //     <span>{`${role.title}`}</span>
                  //     <span>
                  //       <svg
                  //         width="18"
                  //         height="18"
                  //         viewBox="0 0 18 18"
                  //         fill="none"
                  //         xmlns="http://www.w3.org/2000/svg"
                  //       >
                  //         <path
                  //           d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391Z"
                  //           fill={selectedValue == role.id ? "green" : "#828282"}
                  //         />
                  //         <path
                  //           d="M8.15625 5.90625C8.15625 6.13003 8.24514 6.34464 8.40338 6.50287C8.56161 6.66111 8.77622 6.75 9 6.75C9.22378 6.75 9.43839 6.66111 9.59662 6.50287C9.75485 6.34464 9.84375 6.13003 9.84375 5.90625C9.84375 5.68247 9.75485 5.46786 9.59662 5.30963C9.43839 5.15139 9.22378 5.0625 9 5.0625C8.77622 5.0625 8.56161 5.15139 8.40338 5.30963C8.24514 5.46786 8.15625 5.68247 8.15625 5.90625ZM9.42188 7.875H8.57812C8.50078 7.875 8.4375 7.93828 8.4375 8.01562V12.7969C8.4375 12.8742 8.50078 12.9375 8.57812 12.9375H9.42188C9.49922 12.9375 9.5625 12.8742 9.5625 12.7969V8.01562C9.5625 7.93828 9.49922 7.875 9.42188 7.875Z"
                  //           fill={selectedValue == role.id ? "green" : "#828282"}
                  //         />
                  //       </svg>
                  //     </span>
                  //   </div>
                  // ),
                };
              });
            setRoles(roles);
          }
        });
      };
    const emptyStates = () => {
      setAdminName("");
      setAdminEmail("");
      setSelectedRole(null);
      setSelectedCountry(null);
      getRoles();
    }

    useEffect(() => {
      getAdmins();
      getRoles();
    }, []);

    const saveAndAddMore = () => {
      setSaveNmore(true);
      setSavedUser([...savedUser, {
        fullName: adminName,
        email: adminEmail,
        role: selectedRole.value,
        country: selectedCountry.value,
        status: selectedStatus.value
      }])
      emptyStates();

    }

    const removeFun = (index) => {
      const filteredUsers = savedUser.filter((user, idx) => {
        return idx !== index
      })
      setSavedUser(filteredUsers)
    }

    return (
      <>
        {/* <div className="rider-listing"> */}
        {/* <span className="title">Sub Admins </span> */}

        <SubAdmin
          isLoading={useSelector((state) => state.isGridLoader)}
          admins={admins}
          handleShow={handleShow}
          handleShowUpdate={handleShowUpdate}
          handleShowDelete={handleShowDelete}
          updateTableData={updateTableData}
          roles={roles}
        />
        {/* </div> */}

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateAdmin}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add New User
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row className="row">
                      {(savedUser.length !== 0) && <div>
                        <Col md={12}>
                          <h3 className="modal-sub-heading">Saved User</h3>
                        </Col>
                        {savedUser.map((user, index) => {
                          console.log(user)
                          return (
                            <Col md={12} className="user-block-height">
                              <div className="user-block">
                                <div className="d-flex justify-content-between block-container">
                                  <div>
                                    <p className="user-block-heading">Name</p>
                                    <p className="user-block-content">{user.fullName}</p>
                                  </div>
                                  <div>
                                    <p className="user-block-heading">
                                      Email Address
                                    </p>
                                    <p className="user-block-content">
                                      {user.email}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="user-block-heading">Role</p>
                                    <p className="user-block-content">
                                      {compareId(user.role)}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="user-block-heading">Country</p>
                                    <p className="user-block-content">{user.country}</p>
                                  </div>
                                  <div>
                                    <p className="user-block-heading">Status</p>
                                    <p className="user-block-content">{compareStatus(user.status)}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="text-right w-100">
                                <div onClick={() => removeFun(index)} style={{ color: "red", cursor: "pointer" }} className="text-decoration-underline">Remove</div>
                              </div>
                            </Col>
                          )
                        })}

                      </div>}
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="fullName"
                            type="text"
                            name="fullName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="name"
                            value={adminName}
                            onChange={(e) => {
                              setAdminName(e.target.value)
                              setFieldValue("fullName", e.target?.value)
                            }}
                          />
                          <label htmlFor="fullName">Name</label>
                          <ErrorMessage
                            name="fullName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="email"
                            type="email"
                            name="email"
                            maxlength="60"
                            className="shadow-none form-control modal-input br-10 "
                            placeholder="email"
                            value={adminEmail}
                            onChange={(e) => {
                              setAdminEmail(e.target.value);
                              setFieldValue("email", e.target?.value)
                            }
                            }
                          />
                          <label htmlFor="email">Email</label>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Role"
                            name="role"
                            styles={colourStyles}
                            onBlur={() => {
                              handleBlur({ target: { name: "role" } });
                            }}
                            onChange={(e) => {
                              console.log("ee", e)
                              let selectLabel = e.label
                              // let selectLabel =
                              //   e.label.props.children[0].props.children.replace(
                              //     "✔",
                              //     ""
                              //   );
                              if (e) {
                                console.log(e)
                                setFieldValue("role", e.value);
                                setSelectedRole({
                                  label: selectLabel,
                                  value: e.value,
                                });
                                getRoles(e);
                              }
                            }}
                            options={roles}
                            value={selectedRole}
                          />
                          <label htmlFor="">Role</label>
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Country"
                            // isClearable
                            name="country"
                            styles={colourStyles}
                            id="country"
                            onBlur={() => {
                              handleBlur({ target: { name: "country" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("country", e.value);
                                setSelectedCountry(e);
                              }
                            }}
                            value={selectedCountry}
                            options={countryOptions}
                          />
                          <label htmlFor="">Country</label>
                          {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Status"
                            // isClearable
                            name="status"
                            styles={colourStyles}
                            id="status"
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            value={selectedStatus}
                            options={statusOptions}
                          />
                          <label htmlFor="">Status</label>
                          {(!selectedStatus || !selectedStatus?.value) && (
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={12} className="d-flex">
                        <Col md={6}>
                          <Button
                            variant="success"
                            // type="submit"
                            className="btn-save save-button mb-3 btn btn-block"
                            onClick={saveAndAddMore}
                          >
                            Save & Add more
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button
                            variant="success"
                            className="btn-save save-button mb-3 btn  btn-block"
                            // type="submit"
                            onClick={createOrUpdateAdmin}
                          >
                            Save
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateAdmin}
          >
            {({ handleBlur, setFieldValue, errors }) => {
              return (
                <Form>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                      Update Sub-Admin
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="padding-header-footer">
                    <div className="form-group form-label-group">
                      <Field
                        id="fullName"
                        type="text"
                        name="fullName"
                        className="shadow-none form-control modal-input br-10"
                        placeholder="Sub Admin Name"
                      />
                      <label htmlFor="fullName">Sub Admin Name</label>
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="form-group form-label-group">
                      <Field
                        id="email"
                        type="email"
                        name="email"
                        className="shadow-none form-control modal-input br-10 "
                        placeholder="email"
                        disabled
                      />
                      <label htmlFor="email">Email</label>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select role"
                        value={selectedRole}
                        name="role"
                        styles={colourStyles}
                        id="role"
                        onBlur={() => {
                          handleBlur({ target: { name: "role" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setSelectedRole(e);
                            setFieldValue("role", e.value);
                          }
                        }}
                        options={roles}
                      />
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        className="height-50 br-10"
                        placeholder="Select status"
                        // isClearable
                        name="status"
                        id="status"
                        styles={colourStyles}
                        value={selectedStatus}
                        onBlur={() => {
                          handleBlur({ target: { name: "status" } });
                        }}
                        onChange={(e) => {
                          if (e) {
                            setSelectedStatus(e);
                            setFieldValue("status", e.value);
                          }
                        }}
                        options={statusOptions}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="padding-header-footer">
                    <Button
                      variant="primary"
                      className="btn-save save-button mb-3 border-radius-15"
                      type="submit"
                    >
                      Update
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <DeleteModal
          showDelete={showDelete}
          clickYes={removeAdmin}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(Admin));
