import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import APIService from "../services/APIService";
import { Calendar } from "primereact/calendar";
import { useParams } from "react-router";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../sass/NotificationsManage.sass";
import { Link } from "react-router-dom";
import moment from "moment";

let VehicleRegistration = (props) => {
  let { id } = useParams(),
    [vehicle, setVehicle] = useState({}),
    validationSchema = Yup.object().shape({
      // majorColorEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Major Color English"),
      // vehicleModelEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Vehicle Model English"),
      // vehicleMakerEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Vehicle Maker English"),
      // ownerNameEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Owner Name English"),
      // plateText1English: Yup.string()
      //   .nullable()
      //   .required("Please enter Plate Text 1 English"),
      // plateText2English: Yup.string()
      //   .nullable()
      //   .required("Please enter Plate Text 2 English"),
      // plateText3English: Yup.string()
      //   .nullable()
      //   .required("Please enter Plate Text 3 English"),
      // regplaceEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Registration Place English"),
      // bodyTypeEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Body Type English"),
      // licenseExpiryDateEnglish: Yup.string()
      //   .nullable()
      //   .required("Please enter Licence Expiry Date English"),
    }),
    getVehicle = () => {
      props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      APIService.get("admin/car-info/" + id)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            setVehicle(response.data.data);
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        });
    },
    updateVehicle = (vehicle) => {
      vehicle.licenseExpiryDateEnglish = moment(
        vehicle.licenseExpiryDateEnglish
      ).format("YYYY-MM-DD");
      APIService.patch("admin/car-info/" + id, vehicle)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            toast.success("Vehicle update successfully");
          }
        })
        .catch(() => {
          toast.error("Failed to Vehicle Update");
          props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        });
    };

  useEffect(() => {
    getVehicle();
  }, []);

  return (
    <div className="rider-listing notification-manage">
      <Row className="row justify-content-between">
        <div className="col-7">
          <Link to="/reports-vehicle-registration" title="Back">
            <i className="fa fa-chevron-left back-arrow"></i>
          </Link>
          <span className="title">Update Vehicle</span>
        </div>
      </Row>

      <Formik
        enableReinitialize
        initialValues={{ ...vehicle }}
        validationSchema={validationSchema}
        onSubmit={updateVehicle}
      >
        {({ handleBlur, setFieldValue }) => {
          return (
            <Form>
              <Card className="notification-card">
                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Major Color
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Major Color"
                          name="majorColorEnglish"
                          id="majorColorEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="majorColorEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Major Color
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="majorColor"
                          name="majorColor"
                          id="majorColor"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="majorColor"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Model
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Model"
                          name="vehicleModelEnglish"
                          id="vehicleModelEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleModelEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Model
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Model"
                          name="vehicleModel"
                          id="vehicleModelArabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleModelArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Maker
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Maker"
                          name="vehicleMakerEnglish"
                          id="vehicleMakerEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleMakerEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Maker
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Maker"
                          name="vehicleMaker"
                          id="vehicleModelArabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleModelArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Owner
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Owner"
                          name="ownerNameEnglish"
                          id="ownerNameEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="ownerNameEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Vehicle Owner
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Vehicle Owner"
                          name="ownerName"
                          id="vehicleOwnerArabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="vehicleOwnerArabic"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 1
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 1"
                          name="plateText1English"
                          id="plateText1English"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText1English"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 1
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 1"
                          name="plateText1"
                          id="plateText1Arabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText1"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 2
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 2"
                          name="plateText2English"
                          id="plateText2English"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText2English"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 2
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 2"
                          name="plateText2"
                          id="plateText2Arabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText2"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 3
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 3"
                          name="plateText3English"
                          id="plateText3English"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText3English"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Plate Text 3
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Plate Text 3"
                          name="plateText3"
                          id="plateText3Arabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="plateText3"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Registraion Place
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Registraion Place"
                          name="regplaceEnglish"
                          id="regplaceEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="regplaceEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Registraion Place
                        <img
                          alt="Arabic"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-sa"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          disabled
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Registraion Place"
                          name="regplace"
                          id="regplaceArabic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="regplace"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        Body Type Place
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          className="shadow-none form-control input-box-text"
                          type="text"
                          placeholder="Body Type Place"
                          name="bodyTypeEnglish"
                          id="bodyTypeEnglish"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="bodyTypeEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="px-2 mb-3 text-md-left">
                      <div className="notification-heading-text">
                        License Expiry Date
                        <img
                          alt="English"
                          src="https://primefaces.org/primereact/showcase/showcase/demo/images/flag_placeholder.png"
                          className="flag flag-gb"
                          width={30}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group form-label-group position-relative">
                        <Calendar
                          appendTo={null}
                          minDate={new Date()}
                          value={
                            vehicle?.licenseExpiryDateEnglish
                              ? new Date(vehicle.licenseExpiryDateEnglish)
                              : ""
                          }
                          name="licenseExpiryDateEnglish"
                          placeholder="License Expiry Date"
                          dateFormat="dd-mm-yy"
                          className="p-column-filter form-control"
                          onBlur={() => {
                            handleBlur({
                              target: { name: "licenseExpiryDateEnglish" },
                            });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue(
                                "licenseExpiryDateEnglish",
                                e.value
                              );
                            } else {
                              setFieldValue("licenseExpiryDateEnglish", "");
                            }
                          }}
                        />
                        <ErrorMessage
                          name="licenseExpiryDateEnglish"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-save save-button mb-3 border-radius-15"
                      style={{ width: "200px" }}
                    >
                      <span className="btn-text">Save Changes</span>
                    </Button>

                    <Link
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15 ml-3"
                      style={{ width: "200px" }}
                      to={"../reports-vehicle-registration"}
                    >
                      <Button
                        variant="light"
                        style={{ width: "200px" }}
                        className="btn-cancel save-button mb-3 border-radius-15"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Row>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(connect()(VehicleRegistration));
